import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import CitiLogo from '@/assets/payment/citi-logo.png'

const Slogan = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0 0.5rem;
  color: #1c1c1c;
  font-size: 0.8rem;
  font-weight: 400;
`

const Image = styled.img`
  object-fit: contain;
  height: 37px;
  margin-bottom: 0.3rem;
  margin-top: -0.3rem;
`

const PowerbyCiti = () => {
  const intl = useIntl()

  return (
    <Slogan>
      {intl.formatMessage(
        { id: 'common.poweredbyciti' },
        {
          citi: (
            <Image
              src={CitiLogo}
              style={{
                marginRight: '0.3rem',
                marginLeft: '0.3rem',
                marginBottom: '0.4rem',
              }}
              alt="citi"
            />
          ),
        }
      )}
    </Slogan>
  )
}

export default PowerbyCiti
