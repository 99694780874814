import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-logo-bordered.svg'
import MilesPrograms from '@/constants/MilesPrograms'

import Icon from '../Icon'

const Container = styled.div`
  display: flex;
  align-items: center;

  & svg path:not([fill='none']) {
    fill: #b6b6b6;
  }

  & svg path {
    stroke: #b6b6b6;
  }
`

const Text = styled.div`
  color: #b6b6b6;
  font-size: 0.6rem;
  font-weight: 700;
  margin-left: 3px;
`

const MilesRateLabel = ({ program, rate = 1 }) => {
  const targetProgram = MilesPrograms.find((p) => p.key === program)

  return (
    <Container>
      <Icon
        renderImage={() => <DivitMilesSvg />}
        width="0.88rem"
        height="0.88rem"
      />
      <Text>1 = </Text>
      <Icon
        renderImage={() => <targetProgram.Icon />}
        width="0.88rem"
        height="0.88rem"
      />
      <Text>{rate}</Text>
    </Container>
  )
}

export default MilesRateLabel
