import { call, put } from 'redux-saga/effects'

import { fetchDivitMiles } from '@/redux/divitMiles/divitMilesSaga'
import * as giftCardSagas from '@/redux/giftCard/giftCardSaga'
import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as checkoutGiftCardActions } from './checkoutGiftCardSlice'

export function* getProducts() {
  try {
    yield put(checkoutGiftCardActions.getProductsStart())
    const data = yield giftCardSagas.fetchGiftCardProducts()
    yield put(checkoutGiftCardActions.getProductsSuccess(data))
  } catch (e) {
    yield put(checkoutGiftCardActions.getProductsFailed(getError(e)))
  }
}

export function* createOrder({ payload }) {
  try {
    const { pin, products = [] } = payload
    yield put(checkoutGiftCardActions.createOrderStart())
    const orderItems = products.reduce((t, p) => {
      t.push({
        itemType: 'giftcard',
        itemData: {
          productID: p.productID,
          productTitle: p.productTitle,
          qty: p.qty,
          unitPrice: parseFloat(
            parseFloat(p.faceValue.amount / 100).toFixed(2)
          ),
          subtotal: parseFloat(
            parseFloat((p.faceValue.amount * p.qty) / 100).toFixed(2)
          ),
        },
      })
      return t
    }, [])
    const total = products.reduce((t, p) => {
      t += parseInt(p.faceValue.amount * p.qty, 10)
      return t
    }, 0)
    const { data } = yield call(() =>
      DivitAPI.post('/giftcard/profiles/orders', {
        pin,
        totalAmount: {
          amount: total,
          currency: 'HKD',
        },
        orderItems,
      })
    )
    yield fetchDivitMiles()
    yield put(checkoutGiftCardActions.createOrderSuccess(data.data))
  } catch (e) {
    yield put(checkoutGiftCardActions.createOrderFailed(getError(e)))
  }
}

export function* getOrder({ payload }) {
  try {
    const { orderId } = payload
    yield put(checkoutGiftCardActions.getOrderStart())
    const { data } = yield call(() =>
      DivitAPI.get(`/giftcard/profiles/orders/${orderId}`)
    )
    yield put(checkoutGiftCardActions.getOrderSuccess(data.data))
  } catch (e) {
    yield put(checkoutGiftCardActions.getOrderFailed(getError(e)))
  }
}

export default 0
