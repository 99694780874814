export const ENV = process.env.REACT_APP_ENV

export const isProdEnv = ENV === 'prod'

export const isLocalEnv = ENV === 'local'

// production and sandbox is not for development purposes
export const isDevEnv = ['prod', 'sandbox'].indexOf(ENV) < 0

export const isTestEnv = !isProdEnv

export default 0
