import React from 'react'

const MoreInfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#D7DADE"
      d="M10.505 19.23a9.23 9.23 0 1 0 0-18.46 9.23 9.23 0 0 0 0 18.46Z"
    />
    <path fill="#fff" d="M9.48 8.76h1.025v5.343h1.025" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.48 8.76h1.025v5.343h1.025"
    />
    <path
      fill="#fff"
      d="M10.12 6.923a1.154 1.154 0 1 0 0-2.308 1.154 1.154 0 0 0 0 2.308Z"
    />
    <path
      fill="#fff"
      stroke="#fff"
      d="M10.12 6.538a.77.77 0 1 0 0-1.538.77.77 0 0 0 0 1.538Z"
    />
  </svg>
)
export default MoreInfoIcon
