import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as FailIconSvg } from '@/assets/common/fail.svg'
import CountDownLabel from '@/components/CountDownLabel'
import Icon from '@/components/Icon'
import CenteredContent from '@/components/layout/CenteredContent'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import { ActionButtons, PrimaryButton } from '@/components/ui/Shared'
import { tt } from '@/locales/format'
import * as OrderUtils from '@/utils/OrderPaynow'

const FullWidthPaddingContent = styled(PaddingContent).attrs({
  isFullWidth: true,
})`
  max-width: 600px;
  margin: 0 auto;
`

const FailTitle = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const FailDesc = styled.div`
  font-size: 0.77rem;
  text-align: center;
  max-width: 20.8rem;
  margin: 0 auto;
`

const FieldTitle = styled.div`
  text-align: center;
  font-size: 0.67rem;
  font-weight: 700;
`

const FieldValue = styled.div`
  text-align: center;
  font-size: 0.67rem;
`

const CountDownContainer = styled.div`
  text-align: center;
`

const TIMER_SUCCESS_IN_SEC = 5

const PaynowFailScreen = ({
  order,
  error,
  isAutoRedirect,
  onRetry,
  onNext,
}) => {
  const intl = useIntl()
  const history = useHistory()

  const onClickBackMerchant = () => {
    if (OrderUtils.isRedirectFailureNotAvailable(order)) {
      history.push('/')
    } else {
      window.location = order.webhookFailure
    }
  }
  const isExpired = OrderUtils.isExpired(order)
  const isCancelled = OrderUtils.isCancelled(order)

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <CenteredContent>
          <FullWidthPaddingContent>
            <Icon
              renderImage={() => <FailIconSvg />}
              width="1.741rem"
              height="1.508rem"
            />
            <Spacer height="0.889rem" />
            {!(isExpired || isCancelled) && (
              <FailTitle>{tt(intl, 'payment.failed')}</FailTitle>
            )}
            {isExpired && (
              <FailTitle>{tt(intl, 'payment.order.expired')}</FailTitle>
            )}
            {isCancelled && (
              <FailTitle>{tt(intl, 'payment.order.cancelled')}</FailTitle>
            )}
            <Spacer height="0.889rem" />
            <FailDesc>
              <span>{tt(intl, 'payment.notprocess')}</span>
              <Spacer height="0.889rem" />
              {!(isExpired || isCancelled) && (
                <span>{tt(intl, 'common.tryagain')}</span>
              )}
              {(isExpired || isCancelled) && (
                <span>{tt(intl, 'payment.order.check')}</span>
              )}
            </FailDesc>
            <Spacer height="1.778rem" />
            {isAutoRedirect && (
              <>
                <FailDesc style={{ fontSize: '0.66rem' }}>
                  <span>{tt(intl, 'common.autoredirect')}</span>
                </FailDesc>
                <Spacer height="8px" />
                <CountDownContainer>
                  <CountDownLabel
                    timeoutInSec={TIMER_SUCCESS_IN_SEC}
                    onTimeout={onClickBackMerchant}
                  />
                </CountDownContainer>
              </>
            )}
            <Spacer height="1.778rem" />
            {order?.merchantRef && (
              <>
                <FieldTitle>{tt(intl, 'order.merchantreference')}:</FieldTitle>
                <FieldValue>{order.merchantRef}</FieldValue>
                <Spacer height="0.889rem" />
              </>
            )}
            {error?.code && (
              <>
                <FieldTitle>{tt(intl, 'common.error')}:</FieldTitle>
                <FieldValue>{error.code}</FieldValue>
              </>
            )}
          </FullWidthPaddingContent>
        </CenteredContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <FullWidthPaddingContent>
          <ActionButtons>
            <PrimaryButton onClick={onNext || onRetry || onClickBackMerchant}>
              {onRetry ? tt(intl, 'common.retry') : tt(intl, 'common.continue')}
            </PrimaryButton>
          </ActionButtons>
        </FullWidthPaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default PaynowFailScreen
