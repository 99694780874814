import React, { createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const AuthContext = createContext({
  login: () => {},
  loginWithSocialProvider: () => {},
  logout: () => {},
  token: null,
  isLoggedIn: false,
  loading: false,
  error: null,
})

const AuthProvider = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, error, authUserID, role } = useSelector(
    (state) => state.auth
  )

  const login = (credential) => {
    const payload = { ...credential }
    dispatch({ type: 'login', payload })
  }

  const loginWithSocialProvider = (credential) => {
    dispatch({ type: 'loginWithSocialProvider', payload: credential })
  }

  const logout = () => {
    dispatch({ type: 'logout' })
    setTimeout(() => history.replace('/'), 500)
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        loginWithSocialProvider,
        logout,
        authUserID,
        role,
        isLoggedIn: !!authUserID,
        loading,
        error,
      }}
      {...props}
    />
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
