import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import PaynowProcessOrderList from '@/components/paynow/PaynowProcessOrderList'
import Spacer from '@/components/Spacer'
import EmptyPlaceholder from '@/components/ui/EmptyPlaceholder'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { payNowProcessOrderSelector } from '@/redux/order/orderSelector'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'

const Container = styled(PaddingContent)`
  background-color: #fafafa;
`

const HistoryPaynowProcessing = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initPaynowOrders' },
  })

  const { cancelOrder } = useSelector((s) => s.paynow)

  const orders = useSelector(payNowProcessOrderSelector)

  useUnmount(() => dispatch({ type: 'paynow/reset' }))

  useEffect(() => {
    if (cancelOrder.isSuccess) {
      dispatch(
        ToastActions.addToast({
          message: tt(intl, 'paynow.order.cancel.success'),
        })
      )
      retry()
    }
  }, [cancelOrder])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onPay = (order) => {
    history.push(`/paynow/${order.orderID}`)
  }

  const onCancel = async (order) => {
    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'paynow.order.cancel.ask'),
        okText: tt(intl, 'paynow.order.cancel.confirm'),
      })
    )
    if (isOk) {
      dispatch({
        type: 'paynow/cancelOrder',
        payload: { orderId: order.orderID },
      })
    }
  }

  return (
    <Container hasPadding>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>
        {tt(intl, 'paynow.status.inprocess')}
      </Title>
      <Spacer height="1.778rem" />
      {orders.length === 0 && <EmptyPlaceholder />}
      <PaynowProcessOrderList
        orders={orders}
        onPay={onPay}
        onCancel={onCancel}
      />
      <Spacer height="4rem" />
    </Container>
  )
}

export default HistoryPaynowProcessing
