import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 0.625rem;
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;

  > span {
    margin-left: 0.3125rem;
  }
`

const ShowMore = ({ onClick, isShowMore }) => {
  const intl = useIntl()

  return (
    <Container onClick={onClick}>
      <FontAwesomeIcon
        icon={faChevronDown}
        transform={{ rotate: isShowMore ? 180 : 0 }}
      />
      {isShowMore && <span>{intl.formatMessage({ id: 'show.less' })}</span>}
      {!isShowMore && <span>{intl.formatMessage({ id: 'show.more' })}</span>}
    </Container>
  )
}

ShowMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  isShowMore: PropTypes.bool.isRequired,
}

export default ShowMore
