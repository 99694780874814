import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import PurchaseItems from '@/components/PurchaseItems'
import Spacer from '@/components/Spacer'
import { MERCHANT_ICONS } from '@/constants/merchants'
import * as Order from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

import ReceiptSchedule from '../ReceiptSchedule'
import {
  Container,
  DivitFee,
  FPSDivitLogo,
  IconMoreInfo,
  PromotionPrice,
  PromotionWording,
  PurchaseInfoContainer,
  PurchaseInfoItem,
  PurchaseInfoTotalContainer,
  PurchaseInfoValue,
  PurchasePromotionHeader,
  PurchaseSummaryBody,
} from './PurchaseSummaryShort.styled'

const DivitFeeTooltip = styled(ReactTooltip)`
  &.type-dark.place-top {
    width: 120px;
    border-radius: 5px;
    font-size: 0.625rem;
  }
`

const PurchaseSummaryShort = ({ title, order }) => {
  const [showSchedule, setShowSchedule] = useState(true)
  const intl = useIntl()

  if (!order) return <></>

  const totalAmount = Order.getTotalOutstandingAmount(order)

  return (
    <Container>
      <PurchasePromotionHeader>
        <FPSDivitLogo alt="" src={MERCHANT_ICONS.FPS_BY_DIVIT} />
        <Spacer height="1rem" />
        <PromotionWording>
          {intl.formatMessage({ id: 'paylater.header.content' })}
        </PromotionWording>
        <Spacer height="1.5rem" />
        <PromotionPrice>
          {FormattedPrice(totalAmount.currency, totalAmount.amount / 3)}/
          {intl.formatMessage({ id: 'paylater.landing.month' })}
        </PromotionPrice>
        <Spacer height="1.5rem" />
      </PurchasePromotionHeader>
      <PurchaseItems items={order.orderItems} />

      <PurchaseSummaryBody>
        <PurchaseInfoContainer>
          <PurchaseInfoItem>
            {intl.formatMessage({ id: 'paylater.landing.purchase' })}
          </PurchaseInfoItem>
          <PurchaseInfoValue>
            {FormattedPrice(
              order?.totalAmount?.currency,
              order?.totalAmount?.amount
            )}
          </PurchaseInfoValue>
          {order?.serviceFee?.amount > 0 && (
            <>
              <DivitFee>
                {intl.formatMessage({ id: 'paylater.landing.divitfee' })}{' '}
                <IconMoreInfo data-tip="divit-fee" data-for="divit-fee" />
              </DivitFee>
              <DivitFeeTooltip
                id="divit-fee"
                place="top"
                type="dark"
                effect="float"
              >
                {intl.formatMessage({ id: 'receipt.serivce.fee.tooltips' })}
              </DivitFeeTooltip>
              <PurchaseInfoValue>
                {FormattedPrice(
                  order?.serviceFee?.currency,
                  order?.serviceFee?.amount
                )}
              </PurchaseInfoValue>
            </>
          )}
        </PurchaseInfoContainer>
        {order?.serviceFee?.amount > 0 && (
          <>
            <Spacer height="1.5rem" />
            <PurchaseInfoTotalContainer showSchedule={showSchedule}>
              <PurchaseInfoItem>
                {intl.formatMessage({ id: 'payment.ordertotal' })}
              </PurchaseInfoItem>
              <PurchaseInfoValue>
                {FormattedPrice(totalAmount.currency, totalAmount.amount)}
              </PurchaseInfoValue>
            </PurchaseInfoTotalContainer>
          </>
        )}
        <ReceiptSchedule
          order={order}
          showSchedule={showSchedule}
          setShowSchedule={setShowSchedule}
        />
      </PurchaseSummaryBody>
    </Container>
  )
}

export default PurchaseSummaryShort
