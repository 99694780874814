import QueryString from 'query-string'

import en from './en.json'
import enOld from './en_old.json'
import zhCN from './zh-cn.json'
import zhCNOld from './zh-cn_old.json'
import zhHK from './zh-hk.json'
import zhHKold from './zh-hk_old.json'

const messages = {
  en: { ...enOld, ...en },
  'zh-hk': { ...zhHKold, ...zhHK },
  'zh-cn': { ...zhCNOld, ...zhCN },
}

const any = (arr, s) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (s === arr[i]) {
      return true
    }
  }
  return false
}

export const convertToLocale = (locale = '') => {
  const l = locale.toLowerCase()
  if (any(['zh-cn', 'zh_cn', 'cn', 'zh-hans'], l)) {
    return 'zh-cn'
  }
  if ((any(['hk', 'tw'], l)) || l.indexOf('zh') === 0) {
    return 'zh-hk'
  }
  if (l.indexOf('en') === 0) {
    return 'en'
  }
  return ''
}

export const getQueryStringLanguage = () => {
  const { lang } = QueryString.parse(document.location.search)
  return lang || ''
}

export const getBrowserLanguage = () =>
  window.navigator.useLanguage || window.navigator.language

export default messages
