import { push } from 'connected-react-router'
import { all, call, put, select } from 'redux-saga/effects'

import { fetchConfig } from '@/redux/app/appSaga'
import { setupAccount } from '@/redux/auth/authActions'
import { fetchCampaignMissions } from '@/redux/campaign/campaignSaga'
import { fetchOrderActivities } from '@/redux/divitMiles/activities'
import {
  fetchDivitMiles,
  fetchDivitMilesByOrder,
  fetchDivitMilesMembership,
  fetchDivitMilesTransactions,
  fetchDivitTickets,
} from '@/redux/divitMiles/divitMilesSaga'
import { fetchGiftCardProducts } from '@/redux/giftCard/giftCardSaga'
import * as lookupSagas from '@/redux/lookup/lookupSaga'
import { fetchAllPayments } from '@/redux/order/orderActions'
import {
  fetchPaylaterActiveOrders,
  fetchPaynowNewOrders,
} from '@/redux/order/orderSaga'
import { profileSelector } from '@/redux/profile/profileSelector'
import DivitAPI from '@/services/api'
import * as localStore from '@/utils/localStore'

import {
  fetchEDDAAccounts,
  fetchGiftCardBrands,
  fetchGiftCards,
  fetchPaymentAccount,
  fetchProfileSettings,
} from '../profile/profileSaga'
import { getBrands, getProductCategories } from '../shop/shopSaga'
import { getErrorMessage } from '../utils/error'
import { initThenAll } from '../utils/saga'
import { actions as pageInitActions } from './pageInitSlice'

// TODO: now grouped all page init into one, should be separate into page
// page initialize order, profile, then get it from order / profile

function* checkJWTValid() {
  if (!localStore.getCustomerID() || !localStore.isRoleAvailable()) {
    yield put({ type: 'logout', payload: { isNormalLogout: false } })
    yield put(push('/'))
    throw new Error('forbidden')
  }
}

function* fetchOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`profiles/orders/${orderId}`)
  )
  yield put({ type: 'order/getOrderSuccess', payload: data.data })
}

export function* fetchPayments({ refTypes, keyword, page, pageSize }) {
  const data = yield call(() =>
    fetchAllPayments({
      refTypes,
      keyword,
      page,
      pageSize,
    })
  )
  yield put({
    type: 'order/getAllPaymentsSuccess',
    payload: { total: data.count, data: data.data },
  })
  return data
}

function* fetchProfile() {
  const { data } = yield call(async () => DivitAPI.get('profiles'))
  yield put({ type: 'profile/getProfileSuccess', payload: data.data })
}

function* fetchPurchaseStatus() {
  const { data } = yield call(async () => DivitAPI.get('profiles/spend/status'))
  yield put({ type: 'profile/getPurchaseStatusSuccess', payload: data.data })
}

function* fetchPaynowFastOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`paynow/fast/orders/${orderId}`)
  )
  yield put({ type: 'order/getOrderSuccess', payload: data.data })
}

function* fetchPaynowOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`paynow/profiles/orders/${orderId}`)
  )
  yield put({ type: 'order/getOrderSuccess', payload: data.data })
}

function* fetchPaymentDetails({ transactionId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`reporting/transactions/${transactionId}`)
  )
  yield put({ type: 'order/getPaymentSuccess', payload: data.data[0] })
}

const defaultInitActions = () => [fetchConfig(), fetchProfile()]

function* initThenAllWith(pss) {
  yield initThenAll(defaultInitActions(), pss)
}

export function* initApp() {
  try {
    yield put(pageInitActions.initAppStart())
    yield initThenAllWith([
      checkJWTValid(),
      fetchProfileSettings(),
      lookupSagas.fetchLookups(),
      fetchDivitMiles(),
    ])
    const { customerID } = yield select(profileSelector)
    yield fetchCampaignMissions({ customerID })
    yield put(pageInitActions.initAppSuccess())
    yield put(setupAccount({ isPrompt: true, isRedirect: false }))
  } catch (e) {
    yield put(pageInitActions.initAppFailed(getErrorMessage(e)))
  }
}

export function* initAppAgain() {
  try {
    yield put(pageInitActions.initAppAgainStart())
    yield initThenAllWith([
      checkJWTValid(),
      fetchProfile(),
      lookupSagas.fetchLookups(),
      fetchDivitMiles(),
    ])
    const { customerID } = yield select(profileSelector)
    yield fetchCampaignMissions({ customerID })
    yield put(pageInitActions.initAppAgainSuccess())
    yield put(setupAccount({ isPrompt: true, isRedirect: false }))
  } catch (e) {
    yield put(pageInitActions.initAppAgainFailed(getErrorMessage(e)))
  }
}

export function* initConfig() {
  yield fetchConfig()
}

export function* initAllOrders({ payload }) {
  const { initPageSize } = payload
  try {
    yield put(pageInitActions.initAllOrdersStart())
    yield initThenAllWith([
      fetchPaylaterActiveOrders(),
      fetchPayments({ pageSize: initPageSize }),
    ])
    yield put(pageInitActions.initAllOrdersSuccess())
  } catch (e) {
    yield put(pageInitActions.initAllOrdersFailed(getErrorMessage(e)))
  }
}

export function* initPaynowOrders() {
  try {
    yield put(pageInitActions.initPaynowOrdersStart())
    yield initThenAllWith([fetchPaynowNewOrders()])
    yield put(pageInitActions.initPaynowOrdersSuccess())
  } catch (e) {
    yield put(pageInitActions.initPaynowOrdersFailed(getErrorMessage(e)))
  }
}

export function* initOrder({ payload }) {
  try {
    const { orderId } = payload
    // yield lookupSagas.fetchLookups()
    yield put(pageInitActions.initOrderStart())
    yield initThenAllWith([
      fetchOrder({ orderId }),
      fetchDivitMilesByOrder({ orderId }),
      fetchOrderActivities({ orderId }),
    ])

    const { partnerid, totalAmount } = yield select((s) => s.order?.order)
    yield lookupSagas.fetchMerchantRateCurrencyToDVM(
      partnerid,
      totalAmount?.currency
    )
    yield put(pageInitActions.initOrderSuccess())
  } catch (e) {
    yield put(pageInitActions.initOrderFailed(getErrorMessage(e)))
  }
}

export function* initPaynowFastOrder({ payload }) {
  try {
    const { orderId } = payload
    yield put(pageInitActions.initPaynowFastOrderStart())
    yield fetchPaynowFastOrder({ orderId })
    yield put(pageInitActions.initPaynowFastOrderSuccess())
  } catch (e) {
    yield put(pageInitActions.initPaynowFastOrderFailed(getErrorMessage(e)))
  }
}

export function* initPaynowOrder({ payload }) {
  try {
    const { orderId } = payload
    yield put(pageInitActions.initPaynowOrderStart())
    yield lookupSagas.fetchLookups()
    yield initThenAllWith([
      fetchPaynowOrder({ orderId }),
      fetchDivitMiles(),
      fetchDivitMilesByOrder({ orderId }),
      fetchOrderActivities({ orderId }),
      fetchEDDAAccounts(),
    ])
    const { merchantID, currency } = yield select((s) => s.order?.order)
    yield lookupSagas.fetchMerchantRateCurrencyToDVM(merchantID, currency)
    yield put(pageInitActions.initPaynowOrderSuccess())
  } catch (e) {
    yield put(pageInitActions.initPaynowOrderFailed(getErrorMessage(e)))
  }
}

export function* initProfileMain() {
  try {
    yield put(pageInitActions.initProfileMainStart())
    yield initThenAllWith([
      fetchPaylaterActiveOrders(),
      fetchPaynowNewOrders(),
      fetchDivitMiles(),
      fetchDivitTickets(),
      fetchDivitMilesTransactions({ type: 'all' }),
      fetchDivitMilesTransactions({ type: 'earn' }),
      fetchDivitMilesTransactions({ type: 'spend' }),
      fetchDivitMilesMembership(),
      fetchEDDAAccounts(),
      fetchPaylaterActiveOrders(),
      fetchPayments({}),
    ])
    const { customerID } = yield select(profileSelector)
    yield fetchCampaignMissions({ customerID })
    yield put(pageInitActions.initProfileMainSuccess())
  } catch (e) {
    yield put(pageInitActions.initProfileMainFailed(getErrorMessage(e)))
  }
}

export function* initProfileGiftCardBrands() {
  try {
    yield put(pageInitActions.initProfileGiftCardBrandsStart())
    yield initThenAllWith([fetchGiftCardBrands()])
    yield put(pageInitActions.initProfileGiftCardBrandsSuccess())
  } catch (e) {
    yield put(
      pageInitActions.initProfileGiftCardBrandsFailed(getErrorMessage(e))
    )
  }
}

export function* initProfileGiftCards({ payload }) {
  try {
    const { brandCode, active } = payload
    yield put(pageInitActions.initProfileGiftCardsStart())
    yield initThenAllWith([
      fetchGiftCardBrands(),
      fetchGiftCards({ brandCode, active }),
    ])
    yield put(pageInitActions.initProfileGiftCardsSuccess())
  } catch (e) {
    yield put(pageInitActions.initProfileGiftCardsFailed(getErrorMessage(e)))
  }
}

export function* initProfile() {
  // TODO: duplicated GET profile when auto login
  try {
    yield put(pageInitActions.initProfileStart())
    yield initThenAllWith([])
    yield put(pageInitActions.initProfileSuccess())
  } catch (e) {
    yield put(pageInitActions.initProfileFailed(getErrorMessage(e)))
  }
}

export function* initProfileEDDA({ payload }) {
  try {
    yield put(pageInitActions.initProfileEDDAStart())
    const { orderId } = payload || {}
    const fetchOrderFn = orderId ? fetchPaynowOrder : () => {}
    yield initThenAllWith([
      fetchPaymentAccount(),
      fetchEDDAAccounts(),
      fetchOrderFn({ orderId }),
    ])
    yield put(pageInitActions.initProfileEDDASuccess())
  } catch (e) {
    yield put(pageInitActions.initProfileEDDAFailed(getErrorMessage(e)))
  }
}

export function* initDivitMilesProfile() {
  try {
    yield put(pageInitActions.initDivitMilesProfileStart())
    yield initThenAllWith([fetchDivitMiles(), fetchDivitMilesMembership()])
    yield put(pageInitActions.initDivitMilesProfileSuccess())
  } catch (e) {
    yield put(pageInitActions.initDivitMilesProfileFailed(getErrorMessage(e)))
  }
}

export function* initDivitMilesHistory() {
  try {
    yield put(pageInitActions.initDivitMilesHistoryStart())
    yield initThenAllWith([
      fetchDivitMilesTransactions({ type: 'all' }),
      fetchDivitMilesTransactions({ type: 'earn' }),
      fetchDivitMilesTransactions({ type: 'spend' }),
    ])
    yield put(pageInitActions.initDivitMilesHistorySuccess())
  } catch (e) {
    yield put(pageInitActions.initDivitMilesHistoryFailed(getErrorMessage(e)))
  }
}

export function* initDivitMilesSpend() {
  try {
    yield put(pageInitActions.initDivitMilesSpendStart())
    yield initThenAllWith([fetchDivitMiles(), fetchGiftCardProducts()])
    yield put(pageInitActions.initDivitMilesSpendSuccess())
  } catch (e) {
    yield put(pageInitActions.initDivitMilesSpendFailed(getErrorMessage(e)))
  }
}

export function* initCheckoutOrder({ payload }) {
  try {
    const { orderId } = payload
    yield put(pageInitActions.initCheckoutOrderStart())
    yield initThenAllWith([
      fetchPurchaseStatus(),
      fetchOrder({ orderId }),
      fetchPaylaterActiveOrders(),
    ])
    yield put(pageInitActions.initCheckoutOrderSuccess())
  } catch (e) {
    yield put(pageInitActions.initCheckoutOrderFailed(getErrorMessage(e)))
  }
}

export function* initCheckoutProfile({ payload }) {
  try {
    const { orderId } = payload
    yield put(pageInitActions.initCheckoutProfileStart())
    yield initThenAllWith([fetchPurchaseStatus(), fetchOrder({ orderId })])
    yield put(pageInitActions.initCheckoutProfileSuccess())
  } catch (e) {
    yield put(pageInitActions.initCheckoutProfileFailed(getErrorMessage(e)))
  }
}

export function* initPaymentFinished({ payload }) {
  try {
    const { orderId, instalmentId } = payload
    yield put(pageInitActions.initPaymentFinishedStart())
    yield initThenAllWith([
      fetchOrder({ orderId }),
      fetchDivitMilesByOrder({ orderId }),
      fetchOrderActivities({ orderId, instalmentId }),
    ])
    yield put(pageInitActions.initPaymentFinishedSuccess())
  } catch (e) {
    yield put(pageInitActions.initPaymentFinishedFailed(getErrorMessage(e)))
  }
}

export function* initPaymentDetails({ payload }) {
  try {
    const { transactionId } = payload
    yield put(pageInitActions.initPaymentDetailsStart())
    yield initThenAllWith([fetchPaymentDetails({ transactionId })])
    const { payment } = yield select((s) => s.order)
    if (payment.refType === 'paynow-order') {
      const orderId = payment.refID
      yield all([
        fetchPaynowOrder({ orderId }),
        fetchDivitMilesByOrder({ orderId }),
        fetchOrderActivities({ orderId }),
      ])
    } else if (payment.refType === 'paylater-order') {
      const orderId = payment.refID
      yield all([
        fetchOrder({ orderId }),
        fetchDivitMilesByOrder({ orderId }),
        fetchOrderActivities({ orderId }),
      ])
    }
    yield put(pageInitActions.initPaymentDetailsSuccess())
  } catch (e) {
    yield put(pageInitActions.initPaymentDetailsFailed(getErrorMessage(e)))
  }
}

export function* initOffers() {
  try {
    yield put(pageInitActions.initOffersStart())
    yield initThenAllWith([fetchPaylaterActiveOrders(), fetchPayments({})])
    yield put(pageInitActions.initOffersSuccess())
  } catch (e) {
    yield put(pageInitActions.initOffersFailed(getErrorMessage(e)))
  }
}

export function* initShop() {
  try {
    yield put(pageInitActions.initShopStart())
    yield initThenAllWith([getProductCategories(), getBrands()])
    yield put(pageInitActions.initShopSuccess())
  } catch (e) {
    yield put(pageInitActions.initShopFailed(getErrorMessage(e)))
  }
}
