import React from 'react'
import styled from 'styled-components'

import Icon from '@/components/Icon'
import * as BankUtils from '@/utils/Bank'

const BankIcon = styled(Icon)`
  background-color: white;
  border: 1px solid rgba(28, 28, 28, 0.2);
`

const BankLogo = ({ bankCode, width, height, ...props }) => {
  const Logo = BankUtils.getBankLogo(bankCode)

  const renderLogo = () =>
    Logo ? (
      <img src={Logo} alt="bank logo" />
    ) : (
      <div style={{ width, height }} />
    )

  return (
    <BankIcon
      renderImage={() => renderLogo()}
      width={width}
      height={height}
      isCircle
      {...props}
    />
  )
}

export default BankLogo
