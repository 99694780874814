import React, { useState } from 'react'

import MaskedInput from './MaskedInput'

const HKIDInput = ({ onValueChange, ...props }) => {
  const [alphabetLength, setAlphaBetLength] = useState(0)

  return (
    <MaskedInput
      mask={alphabetLength === 1 ? 'a999999(*)' : 'ab999999(*)'}
      maskChar=""
      formatChars={{
        9: '[0-9]',
        a: '[A-Za-z]',
        b: '[A-Za-z]{0,1}',
        '*': '[A-Za-z0-9]',
      }}
      alwaysShowMask
      beforeMaskedValueChange={(newState) => {
        let { value } = newState
        value = value.toUpperCase()
        if (/^[A-Z]\d/.test(value)) {
          setAlphaBetLength(1)
        } else {
          setAlphaBetLength(0)
        }
        return { ...newState, value }
      }}
      placeholder="A123456(0)"
      onChange={(e) => {
        const { value } = e.target
        const v = value.replace('(', '').replace(')', '')
        onValueChange?.(v)
      }}
      {...props}
    />
  )
}

export default HKIDInput
