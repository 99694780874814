import { useMemoizedFn } from 'ahooks'
import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

import { ReactComponent as CopyIconSvg } from '@/assets/common/upload.svg'

import InputError from './InputError'
import { Input } from './InputField'

const Field = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Title = styled.div`
  margin-bottom: 0.44rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const Upload = styled(CopyIconSvg)`
  position: absolute;
  right: 1rem;
  width: 1.887rem;
  height: 1.887rem;
`

const HiddenFileInput = styled.input`
  display: none;
`

const InputStyled = styled(Input)`
  font-size: 0.667rem;
  padding-right: 1.887rem;
`

const UploadFileInputField = React.forwardRef(
  (
    { style, className, title, hasError, error, disabled, accept, ...props },
    ref
  ) => {
    const inputRef = useRef(null)
    const displayRef = useRef(null)
    const { onChange, ...restProps } = props

    const onClickField = useMemoizedFn((e) => {
      inputRef.current?.click()
      if (isMobile) inputRef.current?.click()
    })
    const onSelectFile = useMemoizedFn((e) => {
      if (e.currentTarget?.files)
        Array.prototype.forEach.call(e.currentTarget?.files, (f) => {
          if (f) {
            displayRef.current.value +=
              (displayRef.current.value ? ', ' : '') + f?.name || ''
          }
        })
      onChange({
        type: 'file',
        target: e.currentTarget,
        value: e.currentTarget?.files,
      })
    })

    return (
      <Field style={style} className={className} disabled={disabled}>
        <Title>{title}</Title>
        <Wrapper>
          <InputStyled
            ref={displayRef}
            disabled
            placeholder={props.placeholder}
          />
          <HiddenFileInput
            type="file"
            ref={(node) => {
              ref?.(node)
              inputRef.current = node
            }}
            {...restProps}
            accept={accept}
            onChange={onSelectFile}
          />
          <Upload onClick={onClickField} />
        </Wrapper>
        <InputError error={error} />
      </Field>
    )
  }
)

export default UploadFileInputField
