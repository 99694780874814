import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import MilesSlider from '@/components/miles/MilesSlider2'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import { EDDA_MIN_DAILY_TRANSACTION_AMOUNT_HKD } from '@/constants/edda'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { ActionButtons, MainButton } from '@/pages/profile/Shared'
import { goBack } from '@/redux/app/appActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import {
  eDDAAccountSelector,
  eDDAActivatedSelector,
} from '@/redux/profile/profileSelector'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'
import { FormattedPrice } from '@/utils/Price'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ItemTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
`

const ItemValue = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const zero = {
  currency: 'HKD',
  amount: 0,
}

const EDDASettings = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const isUserEDDAActivated = useSelector(eDDAActivatedSelector)
  const eDDAAccount = useSelector(eDDAAccountSelector)
  const { updateEDDAAccount } = useSelector((s) => s.updateProfile)
  const { userMaxLimit = zero, authLimitAmount } = eDDAAccount || {}

  const [dailyLimit, setDailyLimit] = useState(zero)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileEDDA' },
  })

  useUnmount(() => dispatch({ type: 'updateProfile/reset' }))

  useEffect(() => {
    if (!isUserEDDAActivated) {
      history.replace('/profile')
    }
  }, [])

  useEffect(() => {
    setDailyLimit(userMaxLimit)
  }, [userMaxLimit])

  useUpdateEffect(async () => {
    if (updateEDDAAccount.isSuccess) {
      dispatch(ToastActions.addToast({ message: tt(intl, 'profile.updated') }))
    }
    if (updateEDDAAccount.isError) {
      setDailyLimit(userMaxLimit)
      await dispatch(
        promptConfirmModal({
          title: tt(intl, 'common.aiyah'),
          content: tt(intl, 'edda.dailytransactionlimit.set.failure'),
          closable: false,
        })
      )
    }
  }, [updateEDDAAccount])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onClickSave = () => {
    dispatch({
      type: 'updateProfile/updateEDDAAccount',
      payload: { maxLimit: dailyLimit.amount / 100 },
    })
  }

  if (!eDDAAccount) return null

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title onClickBack={onClickBack}>{tt(intl, 'edda.settings')}</Title>
          <Spacer height="2.222rem" />
          <Row>
            <ItemTitle>{tt(intl, 'edda.dailytransactionlimit')}</ItemTitle>
            <ItemValue>
              {FormattedPrice(dailyLimit.currency, dailyLimit.amount, false)}
            </ItemValue>
          </Row>
          <Spacer height="1.111rem" />
          <MilesSlider
            min={EDDA_MIN_DAILY_TRANSACTION_AMOUNT_HKD}
            max={authLimitAmount.amount / 100}
            value={parseInt(dailyLimit.amount / 100, 10)}
            onChange={(v) => {
              setDailyLimit({
                currency: dailyLimit.currency,
                amount: v * 100,
              })
            }}
          />
          <Spacer height="1.333rem" />
          <Row>
            <ItemValue>
              {FormattedPrice(
                dailyLimit.currency,
                EDDA_MIN_DAILY_TRANSACTION_AMOUNT_HKD * 100,
                false
              )}
            </ItemValue>
            <ItemValue>
              {FormattedPrice(
                authLimitAmount.currency,
                authLimitAmount.amount,
                false
              )}
            </ItemValue>
          </Row>
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <MainButton onClick={onClickSave}>
              {tt(intl, 'common.save')}
            </MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default EDDASettings
