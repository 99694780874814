import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ReactComponent as ActivateIconSvg } from '@/assets/eDDA/activate-icon.svg'
import BottomSheet from '@/components/BottomSheet'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import { KYC_TYPE } from '@/constants/kyc'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import { tt } from '@/locales/format'
import {
  ActionButtons,
  CancelButton,
  MainButton,
  SubTitle,
  Title,
} from '@/pages/profile/Shared'
import { push } from '@/redux/app/appActions'
import { mobile } from '@/utils/Styles'

const SubTitleStyled = styled(SubTitle)`
  max-width: 420px;

  ${mobile(css`
    max-width: 300px;
  `)};
`

const ActivateLaterButton = styled(CancelButton)`
  border: 0;
`

const EDDAActivation = ({ callback, onClose }) => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  useUnmount(() => dispatch({ type: 'setupEDDA/reset' }))

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileEDDA' },
  })

  const doActivate = () => {
    sessionStorage.removeItem('register_edda')
    // assume user has paid fps, so go activate after kyc
    history.push('/profile/kyc', {
      isWait: true,
      requestKycType: KYC_TYPE.PARTIAL,
      nextPage: '/edda/setup?activate=true',
    })
  }

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'edda.activate')}</Title>
          <Spacer height="0.889rem" />
          <SubTitleStyled>{tt(intl, 'edda.activate.why')}</SubTitleStyled>
          <Spacer height="2.222rem" />
          <Icon
            renderImage={() => <ActivateIconSvg />}
            width="11rem"
            height="6.28rem"
          />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <MainButton onClick={doActivate}>
              {tt(intl, 'edda.activate.now')}
            </MainButton>
          </ActionButtons>
          <Spacer height="0.889rem" />
          <ActivateLaterButton onClick={callback || onClose}>
            {tt(intl, 'edda.activate.later')}
          </ActivateLaterButton>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export const EDDAActivationModal = ({ isOpen, onClose }) => (
  <BottomSheet top open={isOpen} onDismiss={onClose}>
    <EDDAActivation onClose={onClose} />
  </BottomSheet>
)

export const EDDAActivationGlobalModal = () => {
  const dispatch = useDispatch()
  const { isOpen, props, callback } = useSelector(
    (s) => s.app.eDDAActivationModal
  )

  const onDismiss = () => {
    dispatch({
      type: 'app/showeDDAActivationModal',
      payload: { isOpen: false },
    })
  }

  return (
    <BottomSheet top open={isOpen} closeable={false} onDismiss={onDismiss}>
      <EDDAActivation {...props} callback={callback} />
    </BottomSheet>
  )
}

export const EDDAActivationPage = () => {
  const { state } = useLocation()
  const { isSignup } = state || {}
  const dispatch = useDispatch()

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfile' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    sessionStorage.removeItem('register_edda')
    if (isSignup) {
      dispatch(push('/signup/welcome'))
    } else {
      dispatch({ type: 'redirectReturnUrlOrHome' })
    }
  }

  return <EDDAActivation onClose={onClickBack} />
}

export default EDDAActivation
