/* eslint-disable */
const chineseMap = {
  'zh-cn': [
    ' ',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
    '十',
    '百',
    '千',
  ],
  'zh-hk': [
    ' ',
    '十',
    '百',
    '千',
    '萬',
    '十',
    '百',
    '千',
    '億',
    '十',
    '百',
    '千',
  ],
}

const chineseNum = {
  'zh-cn': ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'],
  'zh-hk': ['零', '壹', '貳', '叁', '肆', '伍', '陸', '柒', '捌', '玖'],
}

function convertZh(number, lang) {
  if (number === 0) {
    return chineseNum[lang][0]
  }

  if (number === 10) {
    return chineseMap[lang][1]
  }

  const listNum = []
  let n = number
  while (n > 0) {
    listNum.push(parseInt(n % 10, 10))
    n = parseInt(n / 10, 10)
  }

  let result = ''
  for (let i = listNum.length - 1; i >= 0; i -= 1) {
    result += chineseNum[lang][listNum[i]] + chineseMap[lang][i]
  }

  while (true) {
    let r = result
    r = r.replace('零万', '万')
    r = r.replace('零萬', '萬')
    r = r.replace('零亿', '亿')
    r = r.replace('零億', '億')
    r = r.replace('零十', '零')
    r = r.replace('零百', '零')
    r = r.replace('零千', '零')
    r = r.replace('零零', '零')
    r = r.replace('零 ', '')

    if (r === result) {
      break
    } else {
      result = r
    }
  }

  return result.trim()
}

export default convertZh
