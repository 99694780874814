import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import MilesDesc from '@/components/miles/MilesDesc'
import MilesInput2 from '@/components/miles/MilesInput2'
import MilesSlider2 from '@/components/miles/MilesSlider2'
import Spacer from '@/components/Spacer'
import AmountInput2 from '@/components/ui/AmountInput2'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { ERROR_CODE_FPS_UNAVAILABLE } from '@/constants/errors'
import usePage from '@/hooks/usePage'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import PaynowFpsUnavailableScreen from '@/pages/paynow/PaynowFpsUnavailableScreen'
// import PaynowMilesConvert from '@/pages/paynow/PaynowMilesConvertMore'
import PaynowMilesDetails from '@/pages/paynow/PaynowMilesDetails'
import { goBack } from '@/redux/app/appActions'
import { getMerchantRateCurrencyToDVMSelector } from '@/redux/lookup/lookupSelector'
import { startRequestPinFlow } from '@/redux/pin/pinActions'
import { divitMilesSelector } from '@/redux/profile/profileSelector'
import * as DivitMiles from '@/utils/DivitMiles'
import { div, minus, mul } from '@/utils/math'
import * as OrderPaynow from '@/utils/OrderPaynow'
import * as PaymentUtils from '@/utils/Payment'

const Container = styled(PaddingContent)`
  background-color: #fafafa;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const useMilesBurn = (miles) => {
  const [milesToBurn, setMilesToBurn] = useState(miles)
  const [milesToBurnTemp, setMilesToBurnTemp] = useState(miles)

  return {
    milesToBurn,
    milesToBurnTemp,
    setMilesToBurn: (v) => {
      setMilesToBurn(Math.ceil(v))
      setMilesToBurnTemp(v)
    },
  }
}

const MilesSpendContainer = styled.div`
  display: flex;
  align-items: center;
`

const MilesPlusText = styled.div`
  font-size: 1.333rem;
`

const PaynowBurn = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { checkoutMiles = 0 } = state || {}
  const { orderId } = useParams()

  const { linkOrder, burnMiles, generateFpsLink } = useSelector((s) => s.paynow)
  const { order, orderMiles, isFromFastPaynow } = useSelector((s) => s.order)
  const { profile } = useSelector((s) => s.profile)
  const divitMiles = useSelector(divitMilesSelector)

  const { milesToBurn, milesToBurnTemp, setMilesToBurn } = useMilesBurn(0)
  const [prevMilesBurned, setPrevMilesBurned] = useState(0)
  const [inited, setInited] = useState(false)
  const [errorMessage, updateErrorMessage] = useState('')
  const [hasBurned, setHasBurned] = useState(false)
  const [instalmentOutStandingAmount, setInstalmentOutStandingAmount] =
    useState()

  const { retry, isInitError, isInitLoading } = usePage({
    // skipInit: true,
    initAction: { type: 'pageInit/initPaynowOrder', payload: { orderId } },
  })

  const rateCurrencyToDVM = useSelector(getMerchantRateCurrencyToDVMSelector)

  useEffect(() => {
    if (order) {
      setInstalmentOutStandingAmount(
        OrderPaynow.getOutstandingAmount(
          order.instalments[0],
          order.financialTransactions,
          true
        )
      )
    }
  }, [order])

  useEffect(() => {
    if (profile) {
      dispatch({ type: 'paynow/linkOrder', payload: { orderId } })
    }
  }, [profile])

  // link order only if come from fast paynow click burn
  useEffect(() => {
    const isTransferedOrder =
      sessionStorage.getItem('transfered_order') === orderId
    if (!isTransferedOrder && isFromFastPaynow) {
      dispatch({ type: 'paynow/linkOrder', payload: { orderId } })
      dispatch({ type: 'order/setIsFromFastPaynow', payload: false })
    } else {
      sessionStorage.setItem('transfered_order', orderId)
      setInited(true)
      retry()
    }

    return () => dispatch({ type: 'paynow/reset' })
  }, [])

  // then get other details after link order
  useUpdateEffect(async () => {
    if (linkOrder.isSuccess) {
      // maybe transfered from fast paynow
      sessionStorage.setItem('transfered_order', orderId)

      // initialize
      setInited(true)
      retry()
    }
  }, [linkOrder])

  // initialize miles to burn
  useEffect(() => {
    if (order) {
      const getMilesBurned = OrderPaynow.getMilesBurned(order)
      setMilesToBurn(getMilesBurned || checkoutMiles)
      setHasBurned(OrderPaynow.hasBurned(order.financialTransactions))
    }
  }, [order])

  useUpdateEffect(async () => {
    if (burnMiles.isSuccess) {
      const { pin, isSuccess } = await dispatch(startRequestPinFlow())
      if (isSuccess) {
        const instalmentId = order.instalments[0].instalmentID
        const paymentMethod = PaymentUtils.getDefaultPaymentMethodWithCurrency(
          order.currency
        )
        dispatch({
          type: 'paynow/generateFpsLink',
          payload: {
            pin,
            orderId,
            instalmentId,
            isBurn: true,
            paymentMethod,
          },
        })
      } else {
        dispatch({
          type: 'paynow/resetBurnMiles',
          payload: { orderId, miles: prevMilesBurned },
        })
      }
    }
    if (burnMiles.isError) {
      updateErrorMessage('miles burn failed')
      setPrevMilesBurned(order.milesBurned)
    }
  }, [burnMiles])

  useUpdateEffect(async () => {
    if (generateFpsLink.isSuccess) {
      dispatch({
        type: 'paynow/cacheFpsData',
        payload: generateFpsLink.data,
      })
      const defaultPaymentMethod =
        PaymentUtils.getDefaultPaymentMethodWithCurrency(
          PaymentUtils.getFpsDetails(generateFpsLink.data)?.paidCurrency
        )
      setTimeout(
        () => history.push(`/pay/${defaultPaymentMethod}/paynow/${orderId}`),
        500
      )
    }
    if (generateFpsLink.isError) {
      updateErrorMessage('miles burn failed')
      dispatch({
        type: 'paynow/resetBurnMiles',
        payload: { orderId, miles: prevMilesBurned },
      })
    }
  }, [generateFpsLink])

  if (linkOrder.isLoading) return <LoadingScreen />

  if (linkOrder.isError)
    return (
      <Container>
        <ErrorMessageBox
          errorMessage={intl.formatMessage({ id: 'error.action.link.order' })}
        />
      </Container>
    )

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  // fps maintenance screen
  if (
    generateFpsLink.isError &&
    generateFpsLink.error?.code === ERROR_CODE_FPS_UNAVAILABLE
  ) {
    const { periodEnd = 0 } = generateFpsLink.error?.data || {}
    return <PaynowFpsUnavailableScreen order={order} periodEnd={periodEnd} />
  }

  if (!inited) return <LoadingScreen />
  if (!order) return <LoadingScreen />
  if (order.orderID !== orderId) return <></>
  const { merchantID, currency } = order

  const onClickApplyBurn = () => {
    updateErrorMessage('')

    setPrevMilesBurned(order.milesBurned)

    dispatch({
      type: 'paynow/burnMiles',
      payload: { orderId, miles: Math.ceil(milesToBurn) },
    })
  }

  const onChangeMilesAmount = (value) => {
    const v = parseInt(value, 10)
    setMilesToBurn(v)
  }

  const onChangeMilesSlider = (value) => {
    setMilesToBurn(value)
  }

  const onChangeAmount = (value) => {
    const remain = minus(instalmentOutStandingAmount.amount / 100, value)
    const miles = div(remain, rateCurrencyToDVM.buy)
    setMilesToBurn(Math.ceil(miles))
  }

  const onClickBack = () => {
    if (orderId) {
      const paymentMethod =
        sessionStorage.getItem('paymentMethod') ||
        PaymentUtils.getDefaultPaymentMethodWithCurrency(order.currency)
      history.replace(`/pay/${paymentMethod}/paynow/${orderId}`)
    } else dispatch(goBack())
  }

  // cannot burn when underpaid, so we only cares order total
  const { orderAmount } = order

  const milesReserved = DivitMiles.getTotalReservedMilesInOrder(orderMiles)
  const userMilesBalance = divitMiles.balance + milesReserved
  const orderMilesMax = div(
    instalmentOutStandingAmount.amount,
    rateCurrencyToDVM.buy
  )
  const milesToBurnMax = Math.min(orderMilesMax, userMilesBalance)

  const amountToSave = mul(mul(milesToBurnTemp, 100), rateCurrencyToDVM.buy)
  const orderRemainAmountMin = minus(
    instalmentOutStandingAmount.amount,
    mul(milesToBurnMax, rateCurrencyToDVM.buy)
  )
  const orderRemainAmount = minus(
    instalmentOutStandingAmount.amount,
    amountToSave
  )

  const isEnableMilesInput = userMilesBalance > 0
  const isLoading = burnMiles.isLoading || generateFpsLink.isLoading

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>{tt(intl, 'spend.spendmiles')}</Title>
      <Spacer height="0.889rem" />
      {isEnableMilesInput ? (
        <SubTitle>{tt(intl, 'spend.chooseamount')}</SubTitle>
      ) : (
        <SubTitle>{tt(intl, 'spend.nodivitmiles')}</SubTitle>
      )}
      {isEnableMilesInput && (
        <>
          <Spacer height="0.889rem" />
          <MilesSpendContainer>
            <AmountInput2
              currency={instalmentOutStandingAmount.currency}
              min={orderRemainAmountMin / 100}
              max={instalmentOutStandingAmount.amount / 100}
              value={orderRemainAmount / 100}
              onChange={onChangeAmount}
            />
            <Spacer width="0.556rem" />
            <MilesPlusText>+</MilesPlusText>
            <Spacer width="0.556rem" />
            <MilesInput2
              max={milesToBurnMax / 100}
              value={milesToBurn}
              onChange={onChangeMilesAmount}
            />
          </MilesSpendContainer>
          <Spacer height="0.889rem" />
          <MilesSlider2
            defaultValue={milesToBurn}
            max={Math.floor(milesToBurnMax / 100)}
            value={milesToBurn}
            onChange={onChangeMilesSlider}
          />
          <Spacer height="1.333rem" />
          <MilesDesc
            currency={currency}
            buyRate={rateCurrencyToDVM.buy}
            totalMiles={userMilesBalance}
          />
          <Spacer height="1.333rem" />
          <PaynowMilesDetails
            orderAmount={instalmentOutStandingAmount}
            milesBurned={milesToBurn}
            merchantID={merchantID}
            currency={currency}
          />
        </>
      )}
      {/* <Spacer height="1.778rem" />
      <PaynowMilesConvert /> */}
      <Spacer height="1.111rem" />
      <ErrorMessageLine errorMessage={errorMessage} />
      <Spacer height="1.111rem" />
      <ActionButtons>
        <SecondaryButton onClick={onClickBack}>
          {tt(intl, 'common.back')}
        </SecondaryButton>
        {isEnableMilesInput && (
          <PrimaryButton onClick={onClickApplyBurn} disabled={hasBurned}>
            {tt(intl, 'common.apply')}
          </PrimaryButton>
        )}
      </ActionButtons>
      <Spacer height="2.222rem" />
      <LoadingModal loading={isLoading} />
    </Container>
  )
}

export default PaynowBurn
