import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import MiddleBorderPng from '@/assets/common/middle-border.png'
import MiddleBorderRedPng from '@/assets/common/middle-border-red.png'
import Box from '@/components/Box'
import CheckoutSteps from '@/components/CheckoutSteps'
import { Button, H2 } from '@/components/Divit'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import InstalmentsInfoCollapsable from '@/components/InstalmentsInfoCollapsable'
import LoadingScreen from '@/components/LoadingScreen'
import NormalMessageBox from '@/components/NormalMessageBox'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import * as Instalments from '@/utils/Instalments'
import { getPartnerName } from '@/utils/Partner'
import { IsPaylatering } from '@/utils/Route'

// if first payment under paid, fail
// if other payment under paid, success

const Container = styled.div`
  margin-top: 1.25rem;
`

const TitleH2 = styled(H2)`
  margin: 1.25rem 0;
`

const SuccessBox = styled(Box)`
  background-color: ${({ theme }) => theme.message.success};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem calc(100% / 12) 1rem;
  width: calc(100% * 10 / 12);
  border: 0.5px solid ${({ theme }) => theme.border};
  border-bottom: 0;
  border-radius: 10px 10px 0 0;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    padding: 1.25rem calc(100% / 12) 0.75rem;
  }
`

const FailureBox = styled(Box)`
  background-color: ${({ theme }) => theme.message.error};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem calc(100% / 12) 1rem;
  width: calc(100% * 10 / 12);
  border: 0.5px solid ${({ theme }) => theme.border};
  border-bottom: 0;
  border-radius: 10px 10px 0 0;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    padding: 1.25rem calc(100% / 12) 0.75rem;
  }
`

const MiddleBorder = styled.img`
  display: block;
  width: 100%;
`

const MessageTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`

const Message = styled.div`
  font-size: 0.875rem;
  text-align: center;
`

const InstalmentsBox = styled(Box)`
  font-size: 0.75rem;
  width: calc(100% / 12 * 10 - 2px);
  padding-left: calc(100% / 12);
  padding-right: calc(100% / 12);
  margin-bottom: 1.25rem;
  border: 0.5px solid ${({ theme }) => theme.border};
  border-top: 0;
  border-radius: 0 0 10px 10px;
`

const ReturnButtonContainer = styled.div`
  text-align: center;
`

const PaymentUnderPay = ({ isEmbedded }) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const isPaylatering = IsPaylatering(pathname)
  const { orderId, instalmentId } = useParams()

  const { order, orderMiles } = useSelector((state) => state.order)

  const { retry, isInitLoading, isInitError } = usePage({
    skipInit: isEmbedded,
    initAction: { type: 'pageInit/initPaymentFinished', payload: { orderId } },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  if (order.orderid !== orderId) return <></>

  const instalment = order.instalments.find(
    (item) => item.instalmentID === instalmentId
  )

  const paymentStatus = Instalments.getPaymentStatus(instalment)
  const isUnderPay =
    Instalments.isUnderPay(instalment) ||
    Instalments.getTotalOutstandingAmount(instalment).amount > 0
  if (paymentStatus !== 'processing' && !isUnderPay) {
    return (
      <NormalMessageBox
        buttonText={intl.formatMessage({ id: 'button.retry' })}
        onClick={retry}
      >
        {intl.formatMessage({ id: 'error.order.status.invalid' })}
      </NormalMessageBox>
    )
  }

  const isFirstPayment = instalment.series === 1
  const partner = getPartnerName(order.partnerid)

  const goWebhookFailure = (e) => {
    e.preventDefault()

    window.location.href = order.webhook_failure
  }

  return (
    <Container>
      {isPaylatering && <CheckoutSteps index={3} />}
      <TitleH2>{intl.formatMessage({ id: `payment.underpay.title` })}</TitleH2>
      {isFirstPayment && (
        <>
          <FailureBox>
            <MessageTitle>
              {intl.formatMessage({ id: `payment.confirm.underpay.title` })}
            </MessageTitle>
            <Message>
              {intl.formatMessage({ id: `payment.confirm.underpay.message` })}
            </Message>
          </FailureBox>
          <MiddleBorder src={MiddleBorderRedPng} alt="" />
        </>
      )}
      {!isFirstPayment && (
        <>
          <SuccessBox>
            <MessageTitle>
              {intl.formatMessage({ id: `payment.confirm.underpay.title` })}
            </MessageTitle>
            <Message>
              {intl.formatMessage({ id: `payment.confirm.underpay.message` })}
            </Message>
          </SuccessBox>
          <MiddleBorder src={MiddleBorderPng} alt="" />
        </>
      )}
      <InstalmentsBox>
        <InstalmentsInfoCollapsable
          order={order}
          orderMiles={orderMiles}
          showActive={false}
          showTotal
          showError
        />
      </InstalmentsBox>
      {isPaylatering && isFirstPayment && order.webhook_failure && (
        <ReturnButtonContainer>
          <Button type="stretch" onClick={goWebhookFailure}>
            {partner
              ? intl.formatMessage({ id: 'back.to.partner' }, { partner })
              : intl.formatMessage({ id: 'back.back' })}
          </Button>
        </ReturnButtonContainer>
      )}
      <Spacer />
    </Container>
  )
}

PaymentUnderPay.propTypes = {
  isEmbedded: PropTypes.bool,
}

PaymentUnderPay.defaultProps = {
  isEmbedded: false,
}

export default PaymentUnderPay
