import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import * as Order from '@/utils/Order'

export function* redirectScheduleReview({ payload }) {
  const { order } = payload

  if (Order.isRefund(order)) {
    yield put(push(`/schedule/review/refund/${order.orderid}`))
  } else {
    yield put(push(`/schedule/review/${order.orderid}`))
  }
}

export default null
