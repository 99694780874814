import React from 'react'
import styled from 'styled-components'

import HKAirlinePng from '@/assets/airline/hkairline.png'
import { ReactComponent as HKExpressSvg } from '@/assets/airline/hkexpress.svg'
import { ReactComponent as HKExpressMiniSvg } from '@/assets/airline/hkexpress-mini.svg'
import { ReactComponent as YouAirSvg } from '@/assets/airline/youair.svg'
import { ReactComponent as YouMallSvg } from '@/assets/airline/youmall.svg'
import DivitSvg from '@/assets/divit.svg'
import THEMES from '@/constants/Themes'
import { EN, ZH_CN, ZH_HK } from '@/contexts/localeContext'
import colors from '@/themes/colors'

// [TO-DO] when create partner, create an asset file on bucket to store partner id and logo
const PARTNER_ID = ((env) => {
  if (env === 'demo') {
    return {
      HKEXPRESS: '71d5d07d-b7e7-4d4b-ab71-ec3042ce7c2c',
      ASIAMILES: '',
      YOUAIR: 'ac733f4c-51fe-4a29-9554-1001caa813fd',
      YOUMALL: '4303c954-afbf-463c-ae37-bdcff2ac106a',
    }
  }
  if (env === 'sandbox') {
    return {
      HKEXPRESS: '9fa0a07e-8baa-11eb-8f7e-1e00ea0e132e',
      ASIAMILES: '',
      YOUAIR: '2954a672-8ba5-11eb-8f7e-1e00ea0e132e',
      YOUMALL: '4303c954-afbf-463c-ae37-bdcff2ac106a',
    }
  }
  if (env === 'prod') {
    return {
      HKEXPRESS: '247fe861-dd60-11eb-8ad9-73a0ec74b956',
      ASIAMILES: '',
      YOUAIR: '',
      YOUMALL: '',
    }
  }
  // dev
  return {
    // TODO: Uncomment for testing
    // HKEXPRESS: 'e8c0ef94-7b14-11eb-aa12-1e00ea0e132d',
    HKEXPRESS: 'ee8f64c0-3afd-478e-a613-b50a5ce07723',
    ASIAMILES: '',
    YOUAIR: 'e73606d2-7b14-11eb-aa12-1e00ea0e132d',
    YOUMALL: '4303c954-afbf-463c-ae37-bdcff2ac106a',
  }
})(process.env.REACT_APP_ENV)

export const AIRLINE_URL = {
  HKEXPRESS: {
    [ZH_HK]: 'https://www.hkexpress.com/zh-hk/',
    [ZH_CN]: 'https://www.hkexpress.com/zh-hk/',
    [EN]: 'https://www.hkexpress.com/en-hk/',
  },
  ASIAMILES: {
    [ZH_HK]: 'https://www.asiamiles.com/zh/home.html',
    [ZH_CN]: 'https://www.asiamiles.com/sc/home.html',
    [EN]: 'https://www.asiamiles.com/en/home.html',
  },
  YOUAIR: {
    [ZH_HK]: 'https://youair.divit.dev/',
    [ZH_CN]: 'https://youair.divit.dev/',
    [EN]: 'https://youair.divit.dev/',
  },
  YOUMALL: {
    [ZH_HK]: 'https://youmall.divit.dev/',
    [ZH_CN]: 'https://youmall.divit.dev/',
    [EN]: 'https://youmall.divit.dev/',
  },
}

const YouAirLogo = styled(YouAirSvg)`
  width: 30%;
  height: auto;
`

const HKExpressLogo = styled(HKExpressSvg)`
  width: 40%;
  height: auto;
  margin-left: -4px;
`

const HKExpressMiniLogo = styled(HKExpressMiniSvg)`
  width: 40%;
  height: auto;
  margin-left: -4px;
`

const YouMallLogo = styled(YouMallSvg)`
  width: 30%;
  height: auto;
`

const DivitLogo = styled.img`
  width: 28%;
  height: auto;
`

const HKAirlineLogo = styled.div`
  height: 24px;
  width: 103.902px;
  margin-left: -12px;
`

DivitLogo.defaultProps = {
  src: DivitSvg,
}

export const AirlinePrimaryColor = (carrierCode) => {
  const { divitLightBlue, hkexpressPurple, cathayGreen, hongkongAirline } = colors
  switch (carrierCode) {
    case 'YA':
      return divitLightBlue
    case 'UO':
      return hkexpressPurple
    case 'CX':
      return cathayGreen
    case 'HX':
      return hongkongAirline
    default:
      return divitLightBlue
  }
}

export const AirlineLogo = (carrierCode) => {
  switch (carrierCode) {
    case 'YA':
      return <YouAirLogo />
    case 'UO':
      return <HKExpressLogo />
    case 'HX':
      return (
        <HKAirlineLogo>
          <img src={HKAirlinePng} alt="" />
        </HKAirlineLogo>
)
    default:
      return <DivitLogo />
  }
}

export const AirlineLogoByParnterId = (partnerId) => {
  switch (partnerId) {
    case PARTNER_ID.HKEXPRESS:
      return <HKExpressLogo />
    case PARTNER_ID.YOUAIR:
      return <YouAirLogo />
    case PARTNER_ID.YOUMALL:
      return <YouMallLogo />
    default:
      return null
  }
}

export const AirlineMiniLogoByParnterId = (partnerId) => {
  switch (partnerId) {
    case PARTNER_ID.HKEXPRESS:
      return <HKExpressMiniLogo />
    case PARTNER_ID.YOUAIR:
      return <YouAirLogo />
    default:
      return null
  }
}

export const AirlineThemeByParnterId = (partnerId) => {
  switch (partnerId) {
    case PARTNER_ID.HKEXPRESS:
      return THEMES.HKEXPRESS
    case PARTNER_ID.YOUAIR:
      return null
    default:
      return null
  }
}

export const AirlineUrlByPartnerId = (partnerId, locale) => {
  switch (partnerId) {
    case PARTNER_ID.HKEXPRESS:
      return AIRLINE_URL.HKEXPRESS[locale]
    case PARTNER_ID.YOUAIR:
      return AIRLINE_URL.YOUAIR[locale]
    default:
      return ''
  }
}

export const AirlineNameByPartnerId = (intl, partnerId) => {
  switch (partnerId) {
    case PARTNER_ID.HKEXPRESS:
      return intl.formatMessage({ id: 'header.partner.hkexpress' })
    case PARTNER_ID.YOUAIR:
      return intl.formatMessage({ id: 'header.partner.youair' })
    case PARTNER_ID.YOUMALL:
      return intl.formatMessage({ id: 'header.partner.youmall' })
    default:
      return ''
  }
}
