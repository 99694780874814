import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'

export function* fetchProfileAdditional({ payload }) {
  try {
    yield put({ type: 'profileAdditional/getProfileAdditionalStart' })
    const { instalmentID } = payload
    const { data } = yield call(async () =>
      DivitAPI.get('profiles/additional', { params: { instalmentID } })
    )
    yield put({
      type: 'profileAdditional/getProfileAdditionalSuccess',
      payload: data.data,
    })
  } catch (e) {
    yield put({
      type: 'profileAdditional/getProfileAdditionalFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* patchProfileAdditional({ payload }) {
  try {
    yield put({ type: 'profileAdditional/patchProfileAdditionalStart' })
    const { data } = yield call(async () =>
      DivitAPI.patch('profiles/additional', payload)
    )
    yield put({
      type: 'profileAdditional/getProfileAdditionalSuccess',
      payload: data.data,
    })
    yield put({ type: 'profileAdditional/patchProfileAdditionalSuccess' })
  } catch (e) {
    yield put({
      type: 'profileAdditional/patchProfileAdditionalFailed',
      payload: getErrorMessage(e),
    })
  }
}
