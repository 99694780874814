import { getLocation, push } from 'connected-react-router'

import { t } from '@/locales/format'
import DivitAPI from '@/services/api'
import { IsPaylatering } from '@/utils/Route'

import { promptConfirmModal } from '../modal/modalActions'
import { eDDAInitedSelector, profileSelector } from '../profile/profileSelector'

const getEDDALimit = async ({ customerID }) => {
  const { data } = await DivitAPI.get(
    `/payment/account/edda/${customerID}/limit`
  )
  return data.data
}

const showEDDAExceedDailyLimitPrompt = () => async (dispatch) => {
  const ret = await dispatch(
    promptConfirmModal({
      title: t('payment.failed'),
      content: t('paynow.eddaexceedlimit'),
      okText: t('common.ok'),
      closable: false,
    })
  )
  return ret
}

export const checkEDDALimit =
  ({ amount }) =>
  async (dispatch, getState) => {
    const { profile } = getState()
    const eDDALimit = await getEDDALimit({ customerID: profile.customerID })
    const { userMaxLimit } = eDDALimit
    if (userMaxLimit.amount < amount) {
      await dispatch(showEDDAExceedDailyLimitPrompt())
      return false
    }
    return true
  }

export const payForPaylater =
  ({ orderId, instalmentId }) =>
  (dispatch, getState) => {
    const state = getState()
    const isUserEDDAInited = eDDAInitedSelector(state)

    let url = ''
    if (isUserEDDAInited) {
      url = `/paylater/payment/${orderId}/${instalmentId}/review`
    } else if (!isUserEDDAInited) {
      url = `/pay/fps/paylater/${orderId}/${instalmentId}`
    }

    dispatch(push(url))
  }

export default 0
