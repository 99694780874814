import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const SCORE_MAP = {
  1: {
    title: 'profile.password.weak',
    color: '#FF0000',
  },
  2: {
    title: 'profile.password.pass',
    color: '#FFCC33',
  },
  3: {
    title: 'profile.password.great',
    color: '#048BF1',
  },
  4: {
    title: 'profile.password.perfect',
    color: '#13CC93',
  },
}

const Container = styled.div`
  width: 100%;
`

const Progress = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #f0f0f0;

  & > div {
    width: ${({ value }) => `${(value / 4) * 100}%`};
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`

const ProgressBar = styled.div`
  position: absolute;
  /* width: 100%; */
  height: 100%;
  border-radius: 2px;
`

const Text = styled.div`
  font-size: 0.667rem;
  margin-top: 8px;
`

const PasswordStrengthMeter = ({ score }) => {
  const intl = useIntl()

  const scoreObj = useMemo(() => {
    const s = Math.min(Math.max(1, score), 4)
    return {
      value: s,
      ...SCORE_MAP[s],
    }
  }, [score])

  return (
    <Container>
      <Progress value={scoreObj.value} backgroundColor={scoreObj.color}>
        <ProgressBar />
      </Progress>
      <Text>{intl.formatMessage({ id: scoreObj.title })}</Text>
    </Container>
  )
}

export default PasswordStrengthMeter
