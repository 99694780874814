import React from 'react'
import styled from 'styled-components'

const Line = styled.div`
  margin: 8px 0;
`

export const formatLineBreak = ({ text = '', sep = '\n', to = <br /> }) =>
  text.split(sep).map((item, idx) => (
    <Line key={idx}>
      {item}
      {to}
    </Line>
  ))

export default 0
