import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'

import PaddingContent from '@/components/layout/PaddingContent'
import VerifiedStatus from '@/components/setupAccount/VerifiedStatus'
import DOBInput from '@/components/ui/DOBInput'
import { KYC_STATUS, KYC_TYPE } from '@/constants/kyc'
import { profileSelector } from '@/redux/profile/profileSelector'
import DivitAPI from '@/services/api'
import { getKycByType } from '@/utils/kyc'

const Container = styled(PaddingContent)`
  padding-top: 2rem;
`

const Content = styled.div`
  position: relative;
  box-sizing: border-box;
`

const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const LabelContainer = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 25%;
  }
`

const InputContainer = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 75%;
  }
`

const InputSelectContainer = styled.div`
  width: 100%;
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  opacity: ${({ isDisabled }) => isDisabled && '0.4'};

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 75%;
  }
`

const ColumnForm = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    flex-wrap: nowrap;
  }
`

const CustomInput = styled.input`
  border: 1px solid;
  width: 100%;
`

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 700;
  padding-top: 3px;

  color: ${({ error, theme }) => (error ? theme.message.error : 'unset')};
`

const SubmissionInput = styled.input`
  border: 1px dotted black;
  cursor: pointer;
  background: #ffc20d;
`

const SkipKyc = () => {
  const { profile } = useSelector((state) => state.profile)

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    DivitAPI.get(`/admin/debug/nationalid`).then((res) => {
      setValue('nationalID', res?.data?.data?.hkid || undefined)
    })
  }, [])

  useEffect(() => {
    if (profile) {
      // form values > profile > empty
      const formValues = getValues()
      if (profile.kyc) {
        setValue(
          'idFirstName',
          formValues.firstName || profile.kyc.firstName || ''
        )
        setValue(
          'idLastName',
          formValues.lastName || profile.kyc.lastName || ''
        )
      }
      const dobs = (profile?.DOB || '').split('-')
      setValue('DOBDay', formValues.DOBDay || dobs[2] || '')
      setValue('DOBMonth', formValues.DOBMonth || dobs[1] || '')
      setValue('DOBYear', formValues.DOBYear || dobs[0] || '')
      setValue(
        'kycStatus',
        getKycByType(profile.kyc, KYC_TYPE.PARTIAL)?.status
          ? {
              label: getKycByType(
                profile.kyc,
                KYC_TYPE.PARTIAL
              )?.status.toLowerCase(),
              value: getKycByType(profile.kyc, KYC_TYPE.PARTIAL)?.status,
            }
          : undefined
      )
    }
  }, [profile])

  const DOBDay = watch('DOBDay')
  const DOBMonth = watch('DOBMonth')
  const DOBYear = watch('DOBYear')

  const validDateOrEmpty = (momentDate, format) =>
    momentDate.isValid() ? momentDate.format(format) : ''

  const formDob = `${DOBDay} / ${DOBMonth} / ${DOBYear}`

  const checkIsValidHKID = () =>
    !!profile?.nationalID &&
    getKycByType(profile.kyc, KYC_TYPE.PARTIAL)?.status === KYC_STATUS.GREEN

  const isValidHKID = checkIsValidHKID()

  const dispatch = useDispatch()

  const checkVerify = (isVerified) => {
    if (isVerified) {
      return 'verified'
    }
    return 'unverified'
  }

  const { skipKyc } = useSelector((s) => s.auth)

  const history = useHistory()
  const { state } = useLocation()

  useEffect(() => {
    if (skipKyc && skipKyc?.isSuccess && state?.nextPage) {
      history.push(state.nextPage)
    }
  }, [skipKyc, state])

  return (
    <Container>
      <Content>
        <CustomForm
          onSubmit={handleSubmit((data) => {
            dispatch({
              type: 'kyc/skipKyc',
              payload: {
                email: profile?.email,
                kycType: 'partial',
                kycStatus: profile.email.includes('@divit.com.hk')
                  ? data?.kycStatus
                    ? data?.kycStatus.value
                    : undefined
                  : 'GREEN',
                idFirstName: data.idFirstName,
                idLastName: data.idLastName,
                nationalID: data.nationalID || '',
                dob: profile?.email.includes('@divit.com.hk')
                  ? `${data.DOBYear}-${data.DOBMonth}-${data.DOBDay}`
                  : '1980-01-01',
              },
            })
          })}
        >
          <ColumnForm>
            <LabelContainer>
              <Label htmlFor="idFirstName">first name</Label>
            </LabelContainer>
            <InputContainer>
              <Controller
                name="idFirstName"
                control={control}
                render={({ field }) => <CustomInput {...field} />}
              />
            </InputContainer>
          </ColumnForm>
          <ColumnForm>
            <LabelContainer>
              <Label htmlFor="idLastName">last name</Label>
            </LabelContainer>
            <InputContainer>
              <Controller
                name="idLastName"
                control={control}
                render={({ field }) => <CustomInput {...field} />}
              />
            </InputContainer>
          </ColumnForm>
          <ColumnForm>
            <LabelContainer>
              <Label htmlFor="nationalID">national ID</Label>
            </LabelContainer>
            <InputContainer>
              <Controller
                name="nationalID"
                control={control}
                disabled
                render={({ field }) => <CustomInput {...field} />}
              />
            </InputContainer>
          </ColumnForm>
          <ColumnForm>
            <LabelContainer>
              <Label htmlFor="kycStatus">kyc status</Label>
            </LabelContainer>
            <InputSelectContainer
              isDisabled={!profile.email.includes('@divit.com.hk')}
            >
              {/* <VerifiedStatus
                status={
                  profile.email.includes('@divit.com.hk')
                    ? checkVerify(isValidHKID)
                    : 'GREEN'
                }
              /> */}
              <Controller
                name="kycStatus"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isSearchable={false}
                    defaultValue={
                      !profile.email.includes('@divit.com.hk') && {
                        label: 'green',
                        value: 'GREEN',
                      }
                    }
                    cacheOptions
                    options={[
                      {
                        label: 'green',
                        value: 'GREEN',
                      },
                      {
                        label: 'red',
                        value: 'RED',
                      },
                      {
                        label: 'black',
                        value: 'BLACK',
                      },
                    ]}
                  />
                )}
              />
            </InputSelectContainer>
          </ColumnForm>
          {profile.email.includes('@divit.com.hk') && (
            <ColumnForm>
              <LabelContainer>
                <Label htmlFor="dob">dob</Label>
              </LabelContainer>
              <InputContainer>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <DOBInput
                      value={formDob}
                      onValueChange={(v) => {
                        const [day, month, year] = v
                        setValue('DOBDay', day)
                        setValue('DOBMonth', month)
                        setValue('DOBYear', year)
                        const DOBValue =
                          (day &&
                            month &&
                            year &&
                            validDateOrEmpty(
                              moment(`${day}-${month}-${year}`, 'DD-MM-YYYY'),
                              'YYYY-MM-DD'
                            )) ||
                          ''
                        if (DOBValue !== '') {
                          clearErrors('DOBDay')
                          clearErrors('DOBMonth')
                          clearErrors('DOBYear')
                        }
                      }}
                      error={errors.DOBDay || errors.DOBMonth || errors.DOBYear}
                    />
                  )}
                />
              </InputContainer>
            </ColumnForm>
          )}

          <SubmissionInput type="submit" />
        </CustomForm>
      </Content>
    </Container>
  )
}

export default SkipKyc
