import moment from 'moment'
import { createSelector } from 'reselect'

import { Feature, FEATURE_EDDA_ENABLED } from '@/constants/features'
import { joinAddress } from '@/utils/Address'
import * as DivitMiles from '@/utils/DivitMiles'
import * as EDDAUtils from '@/utils/eDDA'
import { canBypassKyc, getLatestKycType } from '@/utils/kyc'

export const storeSelector = (s) => s.profile

export const profileSelector = createSelector(
  storeSelector,
  (s) => s.profile || {}
)

export const identitySelector = createSelector(
  storeSelector,
  (s) => s.identity || {}
)

export const profileShippingAddressesSelector = createSelector(
  profileSelector,
  (profile) => profile.address || []
)

export const profileShippingAddressOptionsSelector = createSelector(
  profileSelector,
  (profile) => {
    const isProfileAddressExist =
      !!(profile.floor || profile.building) &&
      !!profile.district &&
      !!profile.region
    const profileAddress = {
      ID: -1,
      contactFirstname: profile.firstName,
      contactLastname: profile.lastName,
      address1: joinAddress([profile.floor, profile.building]),
      address2: profile.street,
      district: profile.district,
      region: profile.region,
      contactNumber: profile.tel,
    }
    const addresses = isProfileAddressExist
      ? [profileAddress].concat(profile.address)
      : (profile.address || []).slice()
    addresses.sort((a, b) => (a.isDefault && !b.isDefault ? -1 : 1))
    return addresses
  }
)

export const profileShippingAddressDefaultOptionSelector = createSelector(
  profileShippingAddressOptionsSelector,
  (options) => options?.[0]
)

export const profileSettingsSelector = createSelector(
  storeSelector,
  (s) => s.settings || {}
)

export const profileIdSelector = createSelector(profileSelector, (s) => {
  if (s.email_validated && s.email) {
    return s.email
  }
  if (s.tel_validated && s.tel) {
    return s.tel
  }
  return ''
})

export const sessionSelector = createSelector(
  storeSelector,
  profileSelector,
  (data, profile) => {
    const { token, lastLoginTs } = data
    return {
      token,
      lastLoginTs,
      hasPin: profile.hasUserPin,
      hasPassword: !profile.systemGenPwd,
      hasEmail: profile.email_validated,
      hasTel: profile.tel_validated,
    }
  }
)

export const isProfileCompletedSelector = createSelector(
  profileSelector,
  (profile) =>
    canBypassKyc(profile.kyc, getLatestKycType(profile.kyc)) &&
    profile.firstName &&
    profile.lastName &&
    profile.nationalID &&
    profile.DOB &&
    profile.email_validated &&
    profile.tel_validated
)

export const socialMediaLinkageSelector = createSelector(
  profileSelector,
  (profile) => {
    const { socialMediaLinkage } = profile
    return (socialMediaLinkage || []).reduce((obj, link) => {
      const { provider } = link
      obj[provider] = link
      return obj
    }, {})
  }
)

export const contactPreferenceSelector = createSelector(
  profileSelector,
  (profile) => profile.contact_pref || {}
)

export const paymentAccountSelector = createSelector(
  storeSelector,
  (profile) => profile.paymentAccount
)

export const eDDAInitedSelector = createSelector(
  profileSelector,
  (profile) => ['requesting', 'active'].indexOf(profile.eddaStatus) >= 0
)

export const eDDAActivatedSelector = createSelector(
  profileSelector,
  (profile) => ['active'].indexOf(profile.eddaStatus) >= 0
)

export const eDDAAccountSelector = createSelector(storeSelector, (profile) => {
  const { eDDAs = [] } = profile
  const ret = eDDAs.slice()
  ret.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  const record = ret?.[0]
  return record // && !EDDAUtils.isVoided(record) ? record : undefined
})

export const eDDAAvailableAccountSelector = createSelector(
  storeSelector,
  (profile) => {
    const { eDDAs = [] } = profile
    const ret = eDDAs.filter((edda) => EDDAUtils.isActivated(edda))
    ret.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
    return ret?.[0]
  }
)
export const divitTicketSelector = createSelector(storeSelector, (s) => ({
  tickets: s.divitTickets,
}))

export const divitMilesSelector = createSelector(storeSelector, (s) => ({
  balance: DivitMiles.getBalance(s.divitMiles),
  reserved: DivitMiles.getReserved(s.divitMiles),
  convertibleBalance: DivitMiles.getConvertibleBalance(s.divitMiles),
  convertedBalance: DivitMiles.getConvertedBalance(s.divitMiles),
  pendingBalance: DivitMiles.getPendingBalance(s.divitMiles),
  memberships: s.divitMilesMemberships,
}))

export const divitMilesHistorySelector = createSelector(storeSelector, (s) => ({
  all: DivitMiles.getAllTransactions(s.divitMilesTranscations.all.data || []),
  allTotal: s.divitMilesTranscations.all.total,
  earn: DivitMiles.getAllTransactions(s.divitMilesTranscations.earn.data || []),
  earnTotal: s.divitMilesTranscations.earn.total,
  spend: DivitMiles.getAllTransactions(
    s.divitMilesTranscations.spend.data || []
  ),
  spendTotal: s.divitMilesTranscations.spend.total,
}))

export const rewardTicketSelector = createSelector(storeSelector, (s) => ({
  tickets: s.divitTickets,
}))

export const campaignSelector = createSelector(storeSelector, (s) => ({
  missions: s.campaignMissions,
}))

export const milesLinkageSelector = createSelector(storeSelector, (s) => ({
  milesLinkage: s.milesLinkage,
}))

export const giftCardBrandsSelector = createSelector(
  storeSelector,
  (s) => s.giftCardBrands || []
)

export const giftCardActiveBrandsSelector = createSelector(
  giftCardBrandsSelector,
  (brands) => brands.filter((b) => b.numOfActiveCards > 0)
)

export const giftCardInactiveBrandsSelector = createSelector(
  giftCardBrandsSelector,
  (brands) => brands.filter((b) => b.numOfInactiveCards > 0)
)

export const giftCardsSelector = createSelector(
  storeSelector,
  (s) => s.giftCards
)

export const availableGiftCardsSelector = (includedCardIds = []) =>
  createSelector(giftCardsSelector, (giftCards = []) =>
    giftCards.filter(
      (card) =>
        (card.status === 'active' && !card.markedUsed) ||
        includedCardIds.indexOf(card.giftcardID) >= 0
    )
  )

export const usedGiftCardsSelector = (includedCardIds = []) =>
  createSelector(giftCardsSelector, (giftCards = []) =>
    giftCards.filter((card) => {
      const isExpired =
        card.expiredAt > 0 && moment.unix(card.expiredAt).isBefore(moment())
      return (
        (card.status === 'active' && (isExpired || card.markedUsed)) ||
        includedCardIds.indexOf(card.giftcardID) >= 0
      )
    })
  )

//
// experimental features
export const isFeatureEDDAEnabledSelector = createSelector(
  profileSelector,
  (profile) =>
    ((profile?.email || '').indexOf('@divit.com.hk') > 0 &&
      FEATURE_EDDA_ENABLED === Feature.Experimental) ||
    FEATURE_EDDA_ENABLED === Feature.Enabled
)
