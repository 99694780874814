import { createSelector } from 'reselect'

import * as GiftCard from '@/utils/GiftCard'

// selectors

export const giftCardsSelector = (s) => s.giftCard

export const productGroupsSelector = createSelector(
  giftCardsSelector,
  (giftCard) => {
    const { products } = giftCard
    const filteredProducts = products.filter((s) => s.status === 'active')
    return GiftCard.groupProductsByBrand(filteredProducts)
  }
)
