import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { tt } from '@/locales/format'
import { ProfileEditMobileModal } from '@/pages/profile/ProfileEditMobile'
import { verifyIdentity } from '@/redux/pin/pinActions'
import PhoneParser from '@/utils/Phone'

import MobileSetupStepModal from './MobileSetupStepModal'
import VerifiedStatus from './VerifiedStatus'

const Container = styled.div`
  display: flex;
  padding: 1.2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eff0f1;
  cursor: pointer;
`

const Content = styled.div`
  width: 70%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 80%;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const SubTitle = styled.div`
  font-size: 16px;
  margin-top: 0.5rem;
`

const MobileVerify = ({ profile }) => {
  const intl = useIntl()
  const [isShowEditMobileModal, setIsShowEditMobileModal] = useState(false)

  const checkVerify = (tel) => {
    if (tel) {
      return 'verified'
    }
    return 'unverified'
  }

  return (
    <>
      <Container onClick={() => setIsShowEditMobileModal(true)}>
        <Content>
          <Title>{tt(intl, 'common.mobile')}</Title>
          {profile && profile.tel && (
            <SubTitle>{PhoneParser(profile.tel).formatted}</SubTitle>
          )}
        </Content>
        <VerifiedStatus status={checkVerify(profile.tel_validated)} />
      </Container>
      <MobileSetupStepModal
        isOpen={isShowEditMobileModal}
        onClose={() => setIsShowEditMobileModal(false)}
        profile={profile}
      />
    </>
  )
}

export default MobileVerify
