import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  products: [],
}

const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    getProductsSuccess: (state, action) => {
      state.products = action.payload
    },
  },
})

export const { actions } = giftCardSlice

export default giftCardSlice.reducer
