import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { tt } from '@/locales/format'
import * as Order from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

import Spacer from './Spacer'
import ProgressBar from './ui/ProgressBar'

const Container = styled.div`
  font-size: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 16px;
`
export const AvailableSpendContainer = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  margin: 0;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin: 0;
  }
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

const RemainingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RemainingText = styled.div`
  font-size: 1rem;
`

const RemainingAmount = styled.div`
  font-size: 1rem;
`

const InstalmentProgress = ({ profile }) => {
  const intl = useIntl()

  const precentage = parseInt(
    (profile.spend.availableSpend.amount /
      (profile.spend.availableSpend.amount +
        profile.spend.spentAmount.amount)) *
      100,
    10
  )

  return (
    <>
      <ProgressBar precentage={precentage} height="1.25rem" />
      <Spacer height="1rem" />
      <RemainingRow>
        <RemainingText>{tt(intl, 'payment.remainingamount')}</RemainingText>
        <RemainingAmount>
          {FormattedPrice(
            profile.spend.availableSpend.currency,
            profile.spend.availableSpend.amount
          )}
        </RemainingAmount>
      </RemainingRow>
    </>
  )
}

const AvailableSpend = ({ profile }) => {
  const intl = useIntl()

  if (!profile) return <></>

  return (
    <Container>
      <AvailableSpendContainer>
        <Title>{intl.formatMessage({ id: 'paylater.spend.available' })}</Title>
        <Spacer height="1rem" />
        <InstalmentProgress profile={profile} />
      </AvailableSpendContainer>
    </Container>
  )
}

export default AvailableSpend
