import { call, put } from 'redux-saga/effects'

import { fetchOrderActivities } from '@/redux/divitMiles/activities'
import { actions as lookupActions } from '@/redux/lookup/lookupSlice'
import DivitAPI from '@/services/api'

import { checkFpsIsAvailable } from '../profile/profileSaga'
import { getError } from '../utils/error'
import { actions as paynowFastFpsActions } from './paynowFastFpsSlice'

// paynow fast api
// every api need to use Fast Api instance

export function* fetchServices() {
  const { data } = yield call(async () => DivitAPI.get('/service/status'))
  yield put(lookupActions.getServicesSuccess(data.data))
}

export function* fetchMerchant(merchantID) {
  const { data: merchant } = yield call(async () =>
    DivitAPI.get(`/merchants/v2/${merchantID}`)
  )
  yield put(lookupActions.getMerchantListSuccess([merchant.data]))
}

export function* resumeOrder({ payload }) {
  try {
    yield put(paynowFastFpsActions.resumeOrderStart())
    const { token } = payload
    const { data } = yield call(() =>
      DivitAPI.post('/paynow/orders/fast/resume', { token })
    )
    yield put(paynowFastFpsActions.resumeOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowFastFpsActions.resumeOrderFailed(getError(e)))
  }
}

export function* fetchDivitMilesByOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`miles/profiles/books/transactions/${orderId}`)
  )
  yield put({ type: 'order/getOrderMilesSuccess', payload: data.data })

  return data
}

export function* fetchOrder({ payload }) {
  try {
    yield put(paynowFastFpsActions.fetchOrderStart())
    const { orderId, promoCode } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`paynow/fast/orders/${orderId}`)
    )
    const order = data.data
    yield put({ type: 'order/getOrderSuccess', payload: order })
    if (promoCode) {
      yield call(() =>
        DivitAPI.patch(`paynow/fast/orders/${orderId}/promo`, { promoCode })
      )
    }
    yield fetchMerchant(order.merchantID)
    if (data.data.status.toLowerCase() === 'completed') {
      yield fetchDivitMilesByOrder({ orderId })
      yield fetchOrderActivities({ orderId })
    }
    yield put(paynowFastFpsActions.fetchOrderSuccess(order))
  } catch (e) {
    yield put(paynowFastFpsActions.fetchOrderFailed(getError(e)))
  }
}

export function* generateFpsLink({ payload }) {
  try {
    yield put(paynowFastFpsActions.generateFpsLinkStart())
    yield fetchServices()
    yield checkFpsIsAvailable()
    const { orderId, instalmentId, instrumentCode } = payload
    const { data } = yield call(() =>
      DivitAPI.post('paynow/payments', {
        orderID: orderId,
        instalmentID: instalmentId,
        instrumentCode,
      })
    )
    yield put(paynowFastFpsActions.generateFpsLinkSuccess(data.data))
  } catch (e) {
    yield put(paynowFastFpsActions.generateFpsLinkFailed(getError(e)))
  }
}

export function* fpsRefreshOrder({ payload }) {
  try {
    yield put(paynowFastFpsActions.refreshOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`paynow/fast/orders/${orderId}`)
    )
    if (data.data.status.toLowerCase() === 'completed') {
      yield fetchDivitMilesByOrder({ orderId })
      yield fetchOrderActivities({ orderId })
    }
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowFastFpsActions.refreshOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowFastFpsActions.refreshOrderFailed(getError(e)))
  }
}

export default null
