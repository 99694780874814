import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    // global loading
    loading: false,
    // force update count
    forceUpdate: 0,
    // side bar
    isShowSideBar: false,
    // routes history
    isFirstRendering: true,
    routes: [],
    // global modals
    editEmailModal: { isOpen: false, props: null, callback: () => {} },
    editMobileModal: { isOpen: false, props: null, callback: () => {} },
    // configs
    configs: {},
    // countryList
    countryList: {},
  },
  reducers: {
    startLoading: (state) => {
      state.loading = true
    },
    endLoading: (state) => {
      state.loading = false
    },
    showSideBar: (state) => {
      state.isShowSideBar = true
    },
    hideSideBar: (state) => {
      state.isShowSideBar = false
    },
    forceUpdate: (state) => {
      state.forceUpdate += 1
    },
    updateRoute: (state, action) => {
      const route = action.payload
      state.isFirstRendering = route.isFirstRendering
      if (route.isFirstRendering) {
        state.routes = [route.location]
      } else if (route.action === 'REPLACE') {
        state.routes[state.routes.length - 1] = route.location
      } else if (route.action === 'PUSH') {
        state.routes.push(route.location)
      } else if (route.action === 'POP') {
        state.routes.pop()
        state.routes = state.routes || []
      }
    },
    showEditEmailModal: (state, action) => {
      const { isOpen, props, callback } = action.payload
      if (!isOpen) {
        state.editEmailModal = { isOpen, props: null, callback: false }
      } else {
        state.editEmailModal = { isOpen, props, callback }
      }
    },
    showEditMobileModal: (state, action) => {
      const { isOpen, props, callback } = action.payload
      if (!isOpen) {
        state.editMobileModal = { isOpen, props: null, callback: false }
      } else {
        state.editMobileModal = { isOpen, props, callback }
      }
    },
    fetchConfigSuccess: (state, action) => {
      state.configs = action.payload
    },
    fetchMiscConfigSuccess: (state, action) => {
      state.miscconfigs = action.payload?.data
    },
    fetchCountryListSuccess: (state, action) => {
      state.countryList = action.payload
    },
  },
})

export const { actions } = appSlice

export default appSlice.reducer
