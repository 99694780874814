import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import { tt } from '@/locales/format'
import {
  Block,
  BlockItem,
  BlockItemTitle,
  BlockItemValue,
} from '@/pages/paynow/Shared'
import { getMerchantRateCurrencyToDVMSelector } from '@/redux/lookup/lookupSelector'
import { FormattedPercentage, FormattedPrice } from '@/utils/Price'

const ItemTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemValue = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
`

const ItemSavedTitle = styled(ItemTitle)`
  color: #979797;
`

const ItemSavedValue = styled(ItemValue)`
  color: #979797;
`

const Wrapper = styled(Block)`
  background-color: transparent;
  border-radius: 0px;
  box-shadow: none;
`

const PaynowMilesDetails = ({
  merchantID,
  currency,
  orderAmount,
  milesBurned,
}) => {
  const intl = useIntl()
  const rateCurrencyToDVM = useSelector(getMerchantRateCurrencyToDVMSelector)

  const amountToSave = milesBurned * 100 * rateCurrencyToDVM.buy
  const amountToSavePercentage = (amountToSave / orderAmount.amount) * 100
  const amountToSpend = orderAmount.amount - amountToSave

  return (
    <Wrapper>
      <BlockItem>
        <BlockItemTitle>{tt(intl, 'payment.orderostotal')}</BlockItemTitle>
        <BlockItemValue>
          {FormattedPrice(currency, orderAmount.amount)}
        </BlockItemValue>
      </BlockItem>
      <BlockItem>
        <BlockItemTitle>{tt(intl, 'payment.youpay')}</BlockItemTitle>
        <BlockItemValue>
          <MilesAndAmountValue
            fontSize="0.889rem"
            currency={currency}
            amount={amountToSpend}
            miles={milesBurned}
          />
        </BlockItemValue>
      </BlockItem>
      {milesBurned > 0 && (
        <BlockItem>
          <ItemSavedTitle>{tt(intl, 'payment.yousave')}</ItemSavedTitle>
          <ItemSavedValue>
            {FormattedPrice(currency, amountToSave)}
            {` (${FormattedPercentage(amountToSavePercentage)}%)`}
          </ItemSavedValue>
        </BlockItem>
      )}
    </Wrapper>
  )
}

export default PaynowMilesDetails
