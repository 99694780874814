import { useSelector } from 'react-redux'

export const Features = {
  REFERRAL: 'REFERRAL',
  LUCKYDRAW: 'LUCKYDRAW',
  GAME: 'GAME',
  EDDA: 'EDDA',
  SIGNUP500BONUS: 'SIGNUP500BONUS',
}

export const useFeature = () => {
  const { configs } = useSelector((state) => state.app)

  const featureEnabled = (featureName) => {
    if (!configs?.features?.[featureName]) return false
    const enabled =
      configs.features[featureName] === 'enabled' ||
      (configs.features[featureName] !== 'disabled' &&
        sessionStorage
          .getItem('features')
          .indexOf(configs.features[featureName]) >= 0)

    return enabled
  }

  const getConfigObj = (objName) => configs?.[objName]

  return {
    configs,
    getConfigObj,
    featureEnabled,
  }
}
