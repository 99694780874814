import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import DivitShopBgPng from '@/assets/offers/divit-shop-bg.png'
import { ReactComponent as HKExpressSvg } from '@/assets/offers/hkexpress.svg'
import Icon from '@/components/Icon'
import RatioContent from '@/components/layout/RatioContent'
import Spacer from '@/components/Spacer'
import { useLocale } from '@/contexts/localeContext'
import { tt } from '@/locales/format'
import { AIRLINE_URL } from '@/utils/Airline'

const BannerContainer = styled.div`
  width: 100%;
  text-align: center;
`

const Banner = styled(RatioContent)`
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 0.44rem;
  background-color: ${(p) => p.backgroundColor};
  ${({ isBordered }) =>
    isBordered &&
    `
    border: 1px solid rgba(28, 28, 28, 0.1); 
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
    `}
`

const BannerTitle = styled.div`
  text-align: center;
  font-size: 0.77rem;
`
const BannerItem = ({
  title,
  Component,
  Img,
  imageWidth,
  imageHeight,
  href,
  backgroundColor,
  isBordered,
}) => (
  <BannerContainer>
    <a target="_blank" rel="noreferrer" href={href}>
      <Banner
        ratio="36%"
        isCentered
        backgroundColor={backgroundColor}
        isBordered={isBordered}
      >
        {Component ? (
          <Component />
        ) : (
          <Icon
            width={imageWidth}
            height={imageHeight}
            renderImage={() => <Img />}
          />
        )}
      </Banner>
    </a>
    <BannerTitle>{title}</BannerTitle>
  </BannerContainer>
)

const DivitShopItem = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${DivitShopBgPng});
  background-size: 100%;
  border-radius: 8px;
`

const OfferBanners = () => {
  const intl = useIntl()
  const { currentLocale } = useLocale()

  const BANNERS = [
    {
      title: tt(intl, 'miles.hkd2for1'),
      Component: DivitShopItem,
      href: 'https://shop.divit.com.hk',
      isBordered: false,
    },
    {
      title: tt(intl, 'offer.hkexpress.title'),
      Img: HKExpressSvg,
      imageWidth: '6rem',
      imageHeight: '2.18rem',
      href: AIRLINE_URL.HKEXPRESS[currentLocale],
      backgroundColor: '#6E2D91',
      isBordered: false,
    },
  ]

  return BANNERS.map((banner) => (
    <>
      <BannerItem {...banner} />
      <Spacer height="1.33rem" />
    </>
  ))
}

export default OfferBanners
