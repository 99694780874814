import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import { arrayToQs } from '@/utils/request'

export function* fetchDivitMilesPreviewClaim({ token, accessToken }) {
  const { data } = yield call(async () =>
    DivitAPI.get('miles/preview/claim', {
      headers: {
        'X-Divit-Data': `token=${token}${
          accessToken ? `&accessToken=${accessToken}` : ''
        }`,
      },
    })
  )

  return data
}

export function* claimDivitMiles({ token }) {
  const { data } = yield call(async () =>
    DivitAPI.post('miles/profiles/claim', { token })
  )

  return data
}

export function* fetchDivitMiles() {
  const { data } = yield call(async () => DivitAPI.get('miles/profiles/books'))

  yield put({
    type: 'profile/getDivitMilesSuccess',
    payload: data.data,
  })

  return data
}

const API_TXN_TYPES = {
  all: [
    'earn',
    'cancelEarn',
    'claim',
    'convertIn',
    'burn',
    'cancelBurn',
    'convertOut',
    'transfer',
  ],
  earn: ['earn', 'cancelEarn', 'claim', 'convertIn'],
  spend: ['burn', 'cancelBurn', 'convertOut'],
}

export const fetchDivitMilesTransactionsAsync = async ({
  type,
  page = 0,
  pageSize = 25,
}) => {
  const txnTypes = API_TXN_TYPES[type] || API_TXN_TYPES.all

  const result = await DivitAPI.get(
    `miles/profiles/books/transactions?${arrayToQs(
      'txnTypes',
      txnTypes
    )}&pageSize=${pageSize}&page=${page}`
  )

  return result
}

export function* fetchDivitMilesTransactions({
  type,
  page = 0,
  pageSize = 25,
}) {
  const { data } = yield call(() =>
    fetchDivitMilesTransactionsAsync({ type, page, pageSize })
  )

  yield put({
    type: 'profile/getDivitMilesTransactionsSuccess',
    payload: {
      type,
      data: data.data,
      total: data.count,
    },
  })

  return data
}

export function* fetchDivitMilesMembership() {
  const { data } = yield call(async () =>
    DivitAPI.get(`miles/profiles/membership`)
  )
  yield put({
    type: 'profile/getDivitMilesMembershipSuccess',
    payload: data.data,
  })

  return data
}

export function* addDivitMilesMembership(params) {
  const { data } = yield call(async () =>
    DivitAPI.post(`miles/profiles/membership`, params)
  )

  yield fetchDivitMilesMembership()

  return data
}

export function* convertDivitMiles(params) {
  const { data } = yield call(async () =>
    DivitAPI.post(`miles/profiles/convert`, params)
  )

  yield fetchDivitMiles()

  return data
}

export function* fetchDivitMilesByOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`miles/profiles/books/transactions/${orderId}`)
  )
  yield put({ type: 'order/getOrderMilesSuccess', payload: data.data })

  return data
}

export function* fetchDivitMilesByInstalment({ orderId, instalmentId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`miles/profiles/books/transactions/${orderId}/${instalmentId}`)
  )
  yield put({ type: 'order/getOrderMilesSuccess', payload: data.data })

  return data
}

export function* fetchDivitTickets() {
  const { data } = yield call(async () =>
    DivitAPI.get('miles/profiles/tickets')
  )

  yield put({
    type: 'profile/getDivitTicketsSuccess',
    payload: data.data,
  })

  return data
}
