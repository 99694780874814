import React from 'react'
import { useIntl } from 'react-intl'

import { tt } from '@/locales/format'

import MaskedInput from './MaskedInput'

const DOBInput = ({ onValueChange, ...props }) => {
  const intl = useIntl()

  const dayText = tt(intl, 'signup.profile.dob[0].placeholder')
  const monthText = tt(intl, 'signup.profile.dob[1].placeholder')
  const yearText = tt(intl, 'signup.profile.dob[2].placeholder')

  return (
    <MaskedInput
      mask="99 / 99 / 9999"
      maskChar=""
      placeholder={`${dayText} / ${monthText} / ${yearText}`}
      onChange={(e) => {
        const { value } = e.target
        const v = value.replace(/ /g, '').split('/')
        onValueChange?.(v)
      }}
      {...props}
    />
  )
}

export default DOBInput
