import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as OverdueAlertSvg } from '@/assets/paylater/overdue-alert.svg'
import Icon from '@/components/Icon'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import * as InstalmentUtils from '@/utils/Instalments'
import * as OrderUtils from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div``

const List = styled.div``

const Group = styled.div`
  margin-bottom: 1rem;
`

const GroupMonthTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 0.778rem;
  font-weight: 600;
`

const Item = styled.div`
  font-size: 0.778rem;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid #f0f0f0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom: 1px solid #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;

  &:last-child {
    padding-bottom: 1rem;
  }
`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 70%;

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    max-width: 45%;
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.sm}px`}) {
    max-width: 55%;
  }
`

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 70%;

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    max-width: 50%;
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.sm}px`}) {
    max-width: 58%;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const OrderMerchaneLogo = styled.div``

const OrderInstalment = styled.div`
  font-size: 0.555rem;
`

const OrderMerchantName = styled.div`
  color: #000;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & span:last-child {
    margin-right: 0px;
  }
`

const NoneBox = styled.div`
  height: 1rem;
`

const PayButton = styled(Button)`
  font-weight: 700;
  min-width: unset;
  height: auto;
  background-color: #1c1c1c;
  color: white;
  font-size: 0.555rem;
  padding: 0.444rem 0.666rem;
`

const Line = styled.div`
  display: block;
  width: 90%;
  height: 1px;
  margin-top: 1rem;
  background-color: ${(p) => p.backgroundColor || '#f0f0f0'};
  margin-left: auto;
  margin-right: auto;
`

const PaylaterOutstandingOrderList = ({ orders, onPay, onClickItem }) => {
  const intl = useIntl()

  const onClickPay = (order) => (e) => {
    e.stopPropagation()
    onPay(order)
  }

  const groups = orders.reduce((obj, txn) => {
    const instalment = InstalmentUtils.getOutstandingInstalment(txn.instalments)
    const isOverdue = OrderUtils.isOverdue(txn)
    const date = `${moment(instalment.dueDate * 1000).format('YYYY-MM-DD')} ${
      isOverdue ? 'true' : 'false'
    }`
    obj[date] = obj[date] || []
    obj[date].push(txn)
    return obj
  }, {})

  const formatGroup = (group) => {
    const today = moment().format('YYYY-MM-DD')
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')
    switch (group) {
      case today:
        return `${tt(intl, 'payment.due')} ${tt(intl, 'schedule.today')}`

      case yesterday:
        return `${tt(intl, 'payment.due')} ${tt(
          intl,
          'landing.historyList.yesterday'
        )}`

      default:
        return `${tt(intl, 'payment.due')} ${moment(group).format(
          'DD MMMM,YYYY'
        )}`
    }
  }

  return (
    <Container>
      <List>
        {Object.keys(groups).map((group) => (
          <Group key={group}>
            <GroupTitle>
              <GroupMonthTitle>
                {formatGroup(`${group.split(' ')[0]}`)}
              </GroupMonthTitle>
              {group.split(' ')[1] === 'true' && (
                <Icon
                  renderImage={() => <OverdueAlertSvg />}
                  wrapperWidth="1rem"
                  wrapperHeight="0.87rem"
                  width="1rem"
                  height="0.87rem"
                />
              )}
            </GroupTitle>
            <Spacer height="0.778rem" />
            <List>
              {groups[group].map((order, index) => {
                const instalment = InstalmentUtils.getOutstandingInstalment(
                  order.instalments
                )
                const outstandingAmount =
                  InstalmentUtils.getTotalOutstandingAmount(instalment)

                return (
                  <Item key={order.orderid} onClick={() => onClickItem(order)}>
                    <ItemContent>
                      <LeftContent>
                        <OrderMerchaneLogo>
                          <MerchantLogo
                            merchantID={order.partnerid}
                            width="2.722rem"
                            height="2.722rem"
                          />
                        </OrderMerchaneLogo>
                        <Spacer width="0.444rem" />
                        <Left>
                          <OrderInstalment>
                            {intl.formatMessage({
                              id: `instalment.${instalment.series}`,
                            })}
                          </OrderInstalment>
                          <Spacer height="0.222rem" />
                          <OrderMerchantName>
                            <MerchantName merchantID={order.partnerid} />
                          </OrderMerchantName>
                          <Spacer height="0.222rem" />
                          <NoneBox> </NoneBox>
                        </Left>
                      </LeftContent>

                      <Right>
                        <PayButton onClick={onClickPay(order)}>
                          {tt(intl, 'payment.pay')}{' '}
                          {FormattedPrice(
                            outstandingAmount.currency,
                            outstandingAmount.amount
                          )}
                        </PayButton>
                      </Right>
                    </ItemContent>
                    {groups[group].length > 1 &&
                      index !== groups[group].length - 1 && <Line />}
                  </Item>
                )
              })}
            </List>
          </Group>
        ))}
      </List>
    </Container>
  )
}

export default PaylaterOutstandingOrderList
