import styled from 'styled-components'

export const ListItemRow = styled.div`
  display: flex;
  align-items: center;
`

export const ListItemText = styled.div`
  flex: 1;
  font-size: 0.887rem;
  font-weight: 500;
`

export const BulletPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.778rem;
  height: 1.778rem;
  background-color: #fffaea;
  color: #ffcc33;
  border-radius: 50%;
  font-size: 1.111rem;
  font-weight: 700;
`
