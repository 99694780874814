import jwtDecode from 'jwt-decode'
import moment from 'moment'

export const decode = (token) => ({
  header: jwtDecode(token, { header: true }),
  payload: jwtDecode(token),
})

export const getExpire = (token) => {
  const { payload } = decode(token)
  return moment(payload.exp * 1000)
    .utc()
    .format()
}

export default null
