import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as CancelledSvg } from '@/assets/common/cancelled.svg'
import Icon from '@/components/Icon'
import * as Instalments from '@/utils/Instalments'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 22%;
  position: relative;
`

const Step = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  line-height: 1;
  color: white;
  background-color: ${({ theme, active, checked, error }) => {
    if (checked) return theme.message.success
    if (error) return theme.message.error
    if (active) return theme.primary
    return theme.border
  }};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 0.6666rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    font-size: 0.625rem;
  }
`

const DottedLine = styled.div`
  position: absolute;
  margin-top: 0.4375rem;
  border-bottom: 2px dashed #ccc;
  width: 100%;
  ${({ position }) => position === 'left' && 'left: 0;'}
  ${({ position }) => position === 'right' && 'right: 0;'}
`

const Tick = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCheck,
}))`
  font-size: 0.5rem;
`

const Error = styled(FontAwesomeIcon).attrs(() => ({
  icon: faExclamation,
}))`
  font-size: 0.375rem;
`

const Steps = ({ order }) => {
  const { instalments } = order

  const outstandingInstalment = Instalments.getOutstandingInstalment(
    instalments
  )

  return (
    <Container>
      {instalments.map((instalment, i) => {
        const paid = Instalments.isPaid(instalment)
        const unpaid = instalment.status.toLowerCase() === 'unpaid'
        const isCancelled = Instalments.isCancelled(instalment)
        const due = moment().isAfter(moment.unix(instalment.dueDate), 'd')
        const checked = paid
        const error = due && unpaid
        const active = outstandingInstalment?.series === instalment.series

        const renderStep = () => {
          if (checked) {
            return <Tick />
          }
          if (isCancelled) {
            return (
              <Icon
                renderImage={() => <CancelledSvg />}
                width="1rem"
                height="1rem"
              />
            )
          }
          if (error) {
            return <Error />
          }

          return <span>{instalment.series}</span>
        }

        return (
          <Step key={i} checked={checked} error={error} active={active}>
            {renderStep()}
          </Step>
        )
      })}
      <DottedLine />
    </Container>
  )
}

Steps.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object,
}

Steps.defaultProps = {
  order: null,
}

export default Steps
