export const Feature = {
  Enabled: 'enabled',
  Experimental: 'experimental',
  Disabled: 'disabled',
}

export const FEATURE_EDDA_ENABLED = Feature.Experimental
export const FEATURE_500_BONUS_MILES_ENABLED = Feature.Disabled

export default 0
