import { put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { actions as shopActions } from './shopSlice'

export const getProductCategoriesApi = async () => {
  const data = await DivitAPI.get('/misc/shop/categories')
  return data.data
}

export const getBrandsApi = async () => {
  const data = await DivitAPI.get('/misc/shop/brands')
  return data.data
}

export const getBrandCategoriesApi = async () => {
  const data = await DivitAPI.get('/misc/shop/brands/categories')
  return data.data
}

const findParentCategory = (categories, category) => {
  const c = categories.find((x) => x.id === category.parent)
  if (c && c.parent !== 0) {
    return findParentCategory(categories, c)
  }
  return c
}

const groupCategoriesByParent = (categories) => {
  const root = categories.filter((d) => d.parent === 0)
  return categories.reduce(
    (r, c) => {
      const isRoot = root.find((x) => x.id === c.id)
      if (!isRoot) {
        const parentCategory = findParentCategory(categories, c)
        if (parentCategory) {
          const rc = r.find((x) => x.id === parentCategory.id)
          rc.children.push(c)
        }
      }
      return r
    },
    root.map((r) => ({ ...r, children: [] }))
  )
}

export function* getProductCategories() {
  const data = yield getProductCategoriesApi()
  const categories = data.filter((d) => d.slug !== 'uncategorized')
  const groupedCategories = groupCategoriesByParent(categories)
  yield put(shopActions.getProductCategoriesSuccess(groupedCategories))
}

export function* getBrands() {
  const brandsData = yield getBrandsApi()
  const brandCategories = yield getBrandCategoriesApi()
  const brands = brandsData.map((b) => {
    const cats = brandCategories.filter(
      (c) => b.brand_category.indexOf(c.id) >= 0
    )
    return {
      ...b.acf,
      categories: cats.map((c) => ({ id: c.id, ...c.acf })),
    }
  })
  yield put(shopActions.getBrandsSuccess(brands))
}

export default 0
