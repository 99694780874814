import { useDebounceFn } from 'ahooks'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import ActivityFilterModal from '@/components/activity/ActivityFilterModal'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import OrderHistory from '@/components/OrderHistory'
import PaylaterOutstandingOrderList from '@/components/paylater/PaylaterOutstandingOrderList'
import PaylaterOverdueNotice from '@/components/paylater/PaylaterOverdueNotice'
import Spacer from '@/components/Spacer'
import SearchInput from '@/components/ui/SearchInput'
import { Row } from '@/components/ui/Shared'
import usePage from '@/hooks/usePage'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { fetchPaymentsNextPage } from '@/redux/order/orderActions'
import {
  overdueOrdersSelector,
  paylaterActiveOrdersShortSelector,
  paymentsSelector,
} from '@/redux/order/orderSelector'
import * as Instalments from '@/utils/Instalments'
import * as OrderPaynow from '@/utils/OrderPaynow'

import { Button } from './Auth/shared/style'

const Container = styled(PaddingContent)``

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
`

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 1.111rem;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ViewAllButton = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`

const FilterButton = styled(Button)`
  min-width: unset;
  height: 2.222rem;
  font-size: 0.778rem;
  background-color: white;
  padding: 0rem 1.333rem;
  border: 1px solid #dedede;
  border-radius: 35px;
`

const History = () => {
  const intl = useIntl()
  const history = useHistory()
  const { state } = useLocation()

  const dispatch = useDispatch()

  const [keyword, setKeyword] = useState('')
  const [filtered, setFiltered] = useState([])
  const [isShowFilterModal, setIsShowFilterModal] = useState(false)

  const overdueOrders = useSelector(overdueOrdersSelector)
  const payments = useSelector(paymentsSelector)
  const paylaterActiveOrders = useSelector(paylaterActiveOrdersShortSelector)
  const hasUpcomingPayment = paylaterActiveOrders.length > 0
  const hasOverdueOrder = overdueOrders.length > 0
  const overdueOrder = hasOverdueOrder ? overdueOrders[0] : null

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: {
      type: 'pageInit/initAllOrders',
      payload: { initPageSize: state?.stateSize || 25 },
    },
  })

  const { run: runSearch } = useDebounceFn((newKeyword) => {
    setKeyword(newKeyword)
    const refTypes = filtered.map((f) => f.value)
    dispatch({
      type: 'fetchAllPayments',
      payload: {
        refTypes,
        keyword: newKeyword,
      },
    })
  })

  useUpdateEffect(() => {
    const refTypes = filtered.map((f) => f.value)
    dispatch({
      type: 'fetchAllPayments',
      payload: {
        refTypes,
        keyword,
      },
    })
  }, [filtered])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onChangeKeyword = (e) => {
    const { value } = e.target
    runSearch(value)
  }

  const onLoadMore = () => {
    const refTypes = filtered.map((f) => f.value)
    return dispatch(
      fetchPaymentsNextPage({
        refTypes,
        keyword: '',
      })
    )
  }

  const onClickPayment = (payment) => {
    if (payments.length > 0) {
      history.push(`/history/${payment.transactionID}`, {
        stateSize: payments.length,
        yOffset: document
          .getElementById(payment.transactionID)
          .getBoundingClientRect().top,
      })
    }
  }

  const onViewOrder = (order) => {
    if (order.instalments.length === 3) {
      history.push(`/schedule/review/${order.orderid}`)
    } else {
      history.push(`/schedule/review/${order.orderID}`)
    }
  }

  const onPay = (order) => {
    if (order.instalments.length === 3) {
      const instalment = Instalments.getOutstandingInstalment(order.instalments)
      history.push(
        `/pay/fps/paylater/${order.orderid}/${instalment.instalmentID}`
      )
    } else {
      const instalment = OrderPaynow.getInstalment(order)
      history.push(
        `/pay/fps/paynow/${order.orderID}/${instalment.instalmentID}`
      )
    }
  }

  const onClickViewAllUpcoming = () => {
    history.push('/history/paylater/active')
  }

  const showFilterModal = () => {
    setIsShowFilterModal(true)
  }

  const closeFilterModal = () => {
    setIsShowFilterModal(false)
  }

  const onFilterApply = (result) => {
    setFiltered(result)
  }

  return (
    <Container>
      <Spacer height="2.22rem" />
      <Title>{tt(intl, 'tab.activity')}</Title>
      {hasOverdueOrder && (
        <>
          <Spacer height="1.778rem" />
          <PaylaterOverdueNotice order={overdueOrder} />
        </>
      )}
      {hasUpcomingPayment && (
        <>
          <Spacer height="1.778rem" />
          <TitleRow>
            <SubTitle>{tt(intl, 'paylater.upcomingpayment')}</SubTitle>
            <ViewAllButton onClick={onClickViewAllUpcoming}>
              {tt(intl, 'common.viewall')}
            </ViewAllButton>
          </TitleRow>
          <PaylaterOutstandingOrderList
            orders={paylaterActiveOrders}
            onPay={onPay}
            onClickItem={onViewOrder}
          />
        </>
      )}
      <Spacer height="1.778rem" />
      <TitleRow>
        <SubTitle>{tt(intl, 'common.transactions')}</SubTitle>
      </TitleRow>
      <Spacer height="0.889rem" />
      <Row>
        <SearchInput onChangeKeyword={onChangeKeyword} />
        <Spacer width="0.889rem" />
        <FilterButton onClick={showFilterModal}>
          {tt(intl, 'common.filter')} {filtered.length > 0 && filtered.length}
        </FilterButton>
      </Row>
      <OrderHistory
        initTransactionId={state?.transactionID}
        position={state?.yOffset}
        payments={payments}
        isSearching={keyword || filtered.length > 0}
        onClickItem={onClickPayment}
        onLoadMore={onLoadMore}
      />
      <Spacer height="4rem" />
      <ActivityFilterModal
        isOpen={isShowFilterModal}
        filtered={filtered}
        onApply={onFilterApply}
        onClose={closeFilterModal}
      />
    </Container>
  )
}

export default History
