import PaymentSettings from '@/constants/payment'

export const getDefaultPaymentMethodWithCurrency = (currency) => {
  switch (currency) {
    case PaymentSettings.CURRENCY_HKD:
      return PaymentSettings.PAYMENT_METHOD_FPS
    case PaymentSettings.CURRENCY_THB:
      return PaymentSettings.PAYMENT_METHOD_PROMPTPAY
    case PaymentSettings.CURRENCY_SGD:
      return PaymentSettings.PAYMENT_METHOD_SGPAYNOW
    default:
  }
  return PaymentSettings.PAYMENT_METHOD_FPS
}

export const getFpsDetails = (payment) => {
  try {
    const {
      paidCurrency,
      paidAmount,
      appLink,
      webLink,
      web2AppRequestObjLink,
      effectiveDuration,
      metadata,
    } = payment
    const { FPSID, Payee, PaymentRefID, PromptPayID } = JSON.parse(metadata)
    return {
      paidCurrency,
      paidAmount,
      fpsID: FPSID || PromptPayID,
      payee: Payee,
      paymentRefID: PaymentRefID,
      appLink,
      webLink,
      web2AppRequestObjLink,
      effectiveDuration,
    }
  } catch (err) {
    console.error(err)
    return null
  }
}

export default 0
