import * as routerActions from 'connected-react-router'

import { t } from '@/locales/format'
import copy from '@/utils/copy'
import { browserShare, canBrowserShare } from '@/utils/share'

export const replace = (params) => (dispatch) =>
  dispatch(routerActions.replace(params))

export const mustReplace = (url, params) => (dispatch) => {
  dispatch(
    routerActions.replace({
      pathname: '/app/redirect',
      state: {
        redirectTo: url,
      },
      ...params,
    })
  )
}

export const push = (params) => (dispatch) =>
  dispatch(routerActions.push(params))

export const mustPush = (url, params) => (dispatch) => {
  dispatch(
    routerActions.push({
      pathname: '/app/redirect',
      state: {
        redirectTo: url,
      },
      ...params,
    })
  )
}

export const goBack = () => (dispatch, getState) => {
  // redirect to home if no routes
  // but it has refresh problems,
  // do not want paynow go to home
  // so commented below codes
  // const state = getState()
  // state.app.routes.length <= 1

  // redirect to home if can't go back
  if (window.history.length === 1) {
    dispatch(routerActions.push('/'))
  } else {
    dispatch(routerActions.goBack())
  }
}

export const copyText = ({ text }) => (dispatch) => {
  const copied = copy(text)
  if (copied) {
    dispatch({
      type: 'toast/addToast',
      payload: {
        message: t('common.copied'),
      },
    })
  }
  return Promise.resolve()
}

export const shareOrCopy = ({ text }) => (dispatch) => {
  if (canBrowserShare) {
    return browserShare({ text })
  }
  return dispatch(copyText({ text }))
}

export default 0
