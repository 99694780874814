import React from 'react'
import styled from 'styled-components'

export const Button = styled.button`
  min-width: 200px;
  width: ${({ type }) => (type === 'stretch' ? '100%' : 'auto')};
  height: 2.667rem;
  font-size: ${({ size }) => size || '1rem'};
  font-weight: 700;
  color: #1c1c1c;
  letter-spacing: 0.8px;
  border-radius: 5rem;
  border: none;
  padding: 0 1rem;
  line-height: 1em;
  white-space: nowrap;
  cursor: pointer;
  background-color: #ffcc33;

  &:disabled {
    cursor: not-allowed;
    color: #8e8e8e;
    background-color: #fee699;
  }
`

export const Textarea = styled.textarea`
  width: 100%;
  height: ${(rows) => (rows ? `cal(2.667rem * ${rows})` : '6rem')};
  border: 1px solid
    ${({ error, theme }) => (error ? theme.message.error : '#dedede')};
  border-radius: 0.44rem;
  padding: 0.5rem 1.333rem;
  font-size: 0.78rem;
  letter-spacing: 0.5px;
  outline: none;
  box-sizing: border-box;

  &:hover {
    border-color: #ccc;
  }

  &:active,
  &:focus {
    border-color: #f7ce55;
  }

  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`

export const Input = styled.input`
  width: 100%;
  height: 2.667rem;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.message.error : '#dedede')};
  border-radius: 0.44rem;
  padding: 0 0.75rem;
  font-size: 0.78rem;
  letter-spacing: 0.5px;
  outline: none;
  box-sizing: border-box;

  &:hover {
    border-color: #ccc;
  }

  &:active,
  &:focus {
    border-color: #f7ce55;
  }

  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`

export const Label = styled.label`
  font-size: 0.78rem;
  margin-bottom: 0.56rem;
  display: inline-block;

  color: ${({ error, theme }) => (error ? theme.message.error : 'unset')};
`

export const HRWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.78rem;

  > span {
    margin: 0 1.33rem;
  }
`

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #1a1a1a;
  background-size: 8px 1px;
  opacity: 20%;
`

export const Content = styled.span`
  color: #939599;
`

export const LineHR = ({ children }) => (
  <HRWrapper>
    <Line />
    <Content>{children}</Content>
    <Line />
  </HRWrapper>
)
