import { OrderPaynow } from '@divit-stem/divit-orderjs'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import InstalmentsInfo from '@/components/paynow/InstalmentsInfo'
import OrderSummary from '@/components/paynow/OrderSummary'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'

const Container = styled.div``

const OrderInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.111rem;
  box-sizing: border-box;
`

const OrderMerchantLogo = styled.div``

const OrderType = styled.div`
  font-size: 0.778rem;
`

const OrderMerchantName = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`

const OrderDate = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  color: #979797;
`

const OrderInfoHeaderContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.77rem;
`

const ScheduleReviewPaynow = ({ order, orderMiles }) => {
  const intl = useIntl()
  const { isNormal } = OrderPaynow(order)

  return (
    <Container>
      <OrderInfoHeader>
        <OrderMerchantLogo>
          <MerchantLogo
            merchantID={order.merchantID}
            width="3.5rem"
            height="3.5rem"
          />
        </OrderMerchantLogo>
        <Spacer width="0.88rem" />
        <OrderInfoHeaderContent>
          <OrderType>{tt(intl, 'payment.paynow')}</OrderType>
          <OrderMerchantName>
            <MerchantName merchantID={order.merchantID} />
          </OrderMerchantName>
          <Spacer height="2px" />
          <OrderDate>{moment(order.createdAt).format('LLL')}</OrderDate>
        </OrderInfoHeaderContent>
      </OrderInfoHeader>
      <Spacer height="1.778rem" />
      <OrderSummary order={order} orderMiles={orderMiles} />
      <Spacer height="1.778rem" />
      {!isNormal() && <InstalmentsInfo order={order} orderMiles={orderMiles} />}
    </Container>
  )
}

export default ScheduleReviewPaynow
