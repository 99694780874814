import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import InfinityScroll from '@/components/InfinityScroll'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import LoadingSpinner from '@/components/LoadingSpinner'
import MilesTransactionHistory from '@/components/miles/MilesTransactionHistory'
import SelectorTabs, { Tab } from '@/components/SelectorTabs'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { t } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import { fetchDivitMilesHistoryNextPage } from '@/redux/divitMiles/divitMilesActions'
import { divitMilesHistorySelector } from '@/redux/profile/profileSelector'
import * as Pagination from '@/utils/pagination'

const Container = styled.div`
  flex: 1;
  background-color: #fafafa;
`

const Content = styled.div`
  padding: 0.889rem 1.111rem;
  box-sizing: border-box;
  border-radius: 0.444rem;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.08);
  background-color: white;
`

const TransactionList = ({ history, hasMore, onLoadMore }) => (
  <PaddingContent>
    <Content>
      <MilesTransactionHistory history={history} />
      <InfinityScroll hasMore={hasMore} loadMore={onLoadMore}>
        <LoadingSpinner />
      </InfinityScroll>
    </Content>
  </PaddingContent>
)

const DivitMilesHistory = () => {
  const dispatch = useDispatch()

  const [txnType, setTxnType] = useState('all')

  const divitMilesHistory = useSelector(divitMilesHistorySelector)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initDivitMilesHistory' },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onChangeTab = (tab) => {
    setTxnType(tab.key)
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onLoadMore = (type) => () => {
    if (txnType === type) {
      return dispatch(fetchDivitMilesHistoryNextPage({ type }))
    }
    return Promise.resolve()
  }

  const hasMoreAll = Pagination.hasMore(
    divitMilesHistory.all,
    divitMilesHistory.allTotal
  )
  const hasMoreEarn = Pagination.hasMore(
    divitMilesHistory.earn,
    divitMilesHistory.earnTotal
  )
  const hasMoreSpend = Pagination.hasMore(
    divitMilesHistory.spend,
    divitMilesHistory.spendTotal
  )

  return (
    <Container>
      <PaddingContent>
        <Spacer height="2.222rem" />
        <Title onClickBack={onClickBack}>{t('miles.transaction')}</Title>
        <Spacer height="2.222rem" />
        <SelectorTabs
          defaultTab={txnType}
          tabs={[
            { key: 'all', label: t('common.all') },
            { key: 'earn', label: t('miles.earned') },
            { key: 'spend', label: t('miles.spent') },
          ]}
          onChange={onChangeTab}
        />
        <Spacer height="0.889rem" />
      </PaddingContent>
      <Tab isActive={txnType === 'all'}>
        <TransactionList
          history={divitMilesHistory.all}
          hasMore={hasMoreAll}
          onLoadMore={onLoadMore('all')}
        />
      </Tab>
      <Tab isActive={txnType === 'earn'}>
        <TransactionList
          history={divitMilesHistory.earn}
          hasMore={hasMoreEarn}
          onLoadMore={onLoadMore('earn')}
        />
      </Tab>
      <Tab isActive={txnType === 'spend'}>
        <TransactionList
          history={divitMilesHistory.spend}
          hasMore={hasMoreSpend}
          onLoadMore={onLoadMore('spend')}
        />
      </Tab>
      <Spacer height="4rem" />
    </Container>
  )
}

export default DivitMilesHistory
