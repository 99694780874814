import { rgba } from 'polished'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'

import Booking, { Mode } from './Booking'
import Receipt from './Receipt/Receipt'
import Spacer from './Spacer'

const Title = styled.div`
  font-size: 1.111rem;
  font-weight: 700;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100%;
  padding: 0 1.5rem;
  box-sizing: border-box;
  align-items: center;
`

const Content = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
  background-color: rgba(255, 0, 0, 0.1);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  margin: 0;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin: 0;
  }
`

const ContenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.message.error};
  font-size: 1.425rem;
  font-weight: 700;
`

const OverduePaymentContainer = styled.div`
  padding: 1.4rem;
  border-radius: 8px;
  background-color: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  margin: 0;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin: 0;
  }
`

const OverduePaymentTitle = styled.div`
  font-weight: 700;
`
const OverduePaymentSubTitle = styled.div`
  width: 100%;
`

const AvailableSpendWarningButtonGroup = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PayButton = styled(Button)`
  font-size: 1rem;
  height: 2.625rem;
  width: 100%;
`

const FreezedAccount = ({ onClickPaynow }) => {
  const intl = useIntl()

  return (
    <Container>
      <Spacer height="1rem" />
      <Content>
        <ContenHeader>
          <ModalTitle>{tt(intl, 'order.freezedAccountModal.title')}</ModalTitle>
        </ContenHeader>
        <Spacer height="1rem" />
        <OverduePaymentContainer>
          <OverduePaymentTitle>
            {tt(intl, 'order.freezedAccountModal.overdue.title')}
          </OverduePaymentTitle>
          <Spacer height="1rem" />

          <OverduePaymentSubTitle>
            {tt(intl, 'order.freezedAccountModal.overdue.subTitle')}
          </OverduePaymentSubTitle>
        </OverduePaymentContainer>
        <Spacer height="1rem" />
        <AvailableSpendWarningButtonGroup>
          <PayButton onClick={onClickPaynow}>
            {intl.formatMessage({
              id: 'button.pay.now',
            })}
          </PayButton>
        </AvailableSpendWarningButtonGroup>
      </Content>
      <Spacer height="1.5rem" />
    </Container>
  )
}

const PurchaseSummary = ({ title, order, isAccountFrozen, onClickPaynow }) => {
  const intl = useIntl()

  if (!order) return <></>

  return (
    <>
      <Booking order={order} mode={Mode.Full} showLogo />
      {isAccountFrozen && onClickPaynow && (
        <FreezedAccount onClickPaynow={onClickPaynow} />
      )}
      <Receipt order={order} />
    </>
  )
}

export default PurchaseSummary
