import 'moment-timezone'

import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as CancelledSvg } from '@/assets/common/cancelled.svg'
import { ReactComponent as RefundedSvg } from '@/assets/common/refunded.svg'
import Icon from '@/components/Icon'
import ShowMore from '@/components/ShowMore'
import { paymentInstrumentsSelector } from '@/redux/lookup/lookupSelector'
import colors from '@/themes/colors'
import { AirlineNameByPartnerId } from '@/utils/Airline'
import * as DivitMiles from '@/utils/DivitMiles'
import * as Instalments from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

import { Button, HR, StraightLine, StraightLineStrong } from './Divit'
import BonusMilesEarned from './miles/BonusMilesEarned'
import MilesEarnedRow from './miles/MilesEarnedRow'
import Spacer from './Spacer'

const InstalmentsContainer = styled.div`
  overflow: hidden;
`

const Instalment = styled.div`
  font-size: 0.75rem;
  display: flex;
  color: ${({ active, theme }) => active && `${theme.primary}`};
`

const InstalmentFull = styled.div`
  flex: 1;
  margin-top: 0.75rem;
`

const InstalmentLeft = styled.div`
  flex: 1;
  margin-top: 0.75rem;
`

const InstalmentRight = styled.div`
  flex: 5;
`

const InstalmentStraightLine = styled(StraightLine)`
  position: relative;
  left: 1rem;
`

const InstalmentStraightLineStrong = styled(StraightLineStrong)`
  position: relative;
  left: 1rem;
`

const InstalmentStep = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ theme, active, checked, error }) => {
    if (checked) return theme.message.success
    if (error) return theme.message.error
    if (active) return theme.primary
    return theme.border
  }};
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 1rem;
    line-height: 100%;
  }
`

const InstalmentInfo = styled.div`
  padding: 0.75rem 0;
`

const InstalmentInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const InstalmentNumber = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: ${({ theme, active, paid, refund, error }) =>
    (refund && colors.divitOrange) ||
    (paid && theme.message.success) ||
    (error && theme.message.error) ||
    (active && theme.primary)};
`

const InstalmentPaidDateTime = styled.div`
  font-size: 0.625rem;
`

const Amount = styled.div`
  font-weight: 700;
  text-align: right;
`

const Tick = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCheck,
}))`
  font-size: 0.75rem;
`

const Error = styled(FontAwesomeIcon).attrs(() => ({
  icon: faExclamation,
}))`
  font-size: 0.75rem;
`

const PayButton = styled(Button)`
  flex: 1;
  font-size: 0.75rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
  }
`

const PaymentBubble = ({ instalments, instalment, showActive }) => {
  // paid => green tick
  // current => purple + number
  // overdue => error
  // cancelled => cross
  // refunded => orange return
  // not paid => grey + number

  const isPaid = Instalments.isPaid(instalment)
  const outstandingInstalment = Instalments.getOutstandingInstalment(
    instalments
  )
  const isActive =
    showActive && !isPaid && outstandingInstalment?.series === instalment.series
  const overdue =
    !isPaid && moment().isAfter(moment.unix(instalment.dueDate), 'd')
  const isError = overdue
  const isCancelled = Instalments.isCancelled(instalment)
  const isRefund = Instalments.isRefund(instalment)

  const renderContent = () => {
    if (isRefund) {
      return (
        <Icon renderImage={() => <RefundedSvg />} width="2rem" height="2rem" />
      )
    }
    if (isCancelled) {
      return (
        <Icon renderImage={() => <CancelledSvg />} width="2rem" height="2rem" />
      )
    }
    if (isPaid) {
      return <Tick />
    }
    if (isError) {
      return <Error />
    }
    return <span>{instalment.series}</span>
  }

  return (
    <InstalmentStep checked={isPaid} active={isActive} error={isError}>
      {renderContent()}
    </InstalmentStep>
  )
}

const PaymentStraightLine = ({ isShow, instalments, instalment }) => {
  const nextInstalment = Instalments.getNextInstalment(instalments, instalment)
  const isNextCancelled =
    nextInstalment && Instalments.isCancelled(nextInstalment)
  const isShowStrong =
    isShow || (Instalments.isPaid(instalment) && !isNextCancelled)
  const isShowLine = isShow || instalment.series <= 2

  if (!isShowLine) {
    return null
  }

  if (isShowStrong) {
    return <InstalmentStraightLineStrong />
  }

  return <InstalmentStraightLine />
}

const PaymentTitle = ({ instalments, instalment, showActive }) => {
  // unpaid - payment 1
  // paid - payment 1 - Paid
  // under paid - payment 1 - Partially paid
  // overpaid  - payment 1 - Overpaid
  // overdue - payment 1 - Overdue
  // refund - payment 1 - Refunded
  // cancelled - payment 1 - Cancelled
  // oustanding - payment 1 - HKD 1,338 owing

  const intl = useIntl()

  const isPaid = Instalments.isPaid(instalment)
  const isRefund = Instalments.isRefund(instalment)
  const isCancelled = Instalments.isCancelled(instalment)
  const outstandingInstalment = Instalments.getOutstandingInstalment(
    instalments
  )
  const outstandingAmount = Instalments.getTotalOutstandingAmount(instalment)
  const isActive =
    showActive &&
    !isPaid &&
    !isRefund &&
    !isCancelled &&
    outstandingInstalment?.series === instalment.series
  const overdue =
    !isPaid && moment().isAfter(moment.unix(instalment.dueDate), 'd')
  const isError = overdue

  const renderStatus = () => {
    const isRequestedRefund = Instalments.hasRequestedRefund(instalment)
    if (isRequestedRefund) {
      return ` - ${intl.formatMessage({
        id: 'payment.refund.pending',
      })}`
    }
    const isUnderPay = Instalments.isUnderPay(instalment)
    if (isUnderPay) {
      return ` - ${intl.formatMessage({
        id: 'payment.partially.paid',
      })}`
    }
    const isOverPay = Instalments.isOverPay(instalment)
    if (isOverPay) {
      return ` - ${intl.formatMessage({
        id: 'payment.over.paid',
      })}`
    }
    const isOffset = Instalments.isOffset(instalment)
    if (overdue && !isUnderPay && !isOffset) {
      return ` - ${intl.formatMessage({ id: 'payment.status.overdue' })}`
    }
    if (isCancelled) {
      return ` - ${intl.formatMessage({ id: 'payment.status.cancelled' })}`
    }
    // no show -> oustanding amount left
    if (
      outstandingAmount.amount > 0 &&
      instalment.amount.amount !== outstandingAmount.amount
    ) {
      return ` - ${FormattedPrice(
        outstandingAmount.currency,
        outstandingAmount.amount
      )} ${intl.formatMessage({ id: 'payment.status.owing' })}`
    }
    if (isRefund) {
      return ` - ${intl.formatMessage({ id: 'payment.status.refunded' })}`
    }
    if (isPaid) {
      return ` - ${intl.formatMessage({ id: 'payment.status.paid' })}`
    }
    return ''
  }

  return (
    <InstalmentNumber
      paid={isPaid}
      active={isActive}
      refund={isRefund}
      error={isError}
    >
      {intl.formatMessage({ id: `schedule.payment[${instalment.series - 1}]` })}
      {renderStatus()}
    </InstalmentNumber>
  )
}

const PaymentDue = ({ instalment }) => {
  const intl = useIntl()

  const hasLateFee = instalment.lateFee?.amount > 0

  return (
    <>
      <div>
        <InstalmentInfoRow>
          <div>
            {intl.formatMessage({
              id: 'payment.amount.due',
            })}
          </div>
          <div>
            {FormattedPrice(
              instalment.amount.currency,
              instalment.amount.amount
            )}
          </div>
        </InstalmentInfoRow>
      </div>
      {hasLateFee && (
        <InstalmentInfoRow>
          <div>
            {intl.formatMessage({
              id: 'payment.latefee',
            })}
          </div>
          <div>
            {FormattedPrice(
              instalment.lateFee.currency,
              instalment.lateFee.amount
            )}
          </div>
        </InstalmentInfoRow>
      )}
      <div>
        {instalment.series !== 1 &&
          intl.formatMessage(
            { id: 'payment.reminder.date' },
            {
              date: moment.unix(instalment.reminderDate).format('LL'),
            }
          )}
      </div>
      <div>
        {intl.formatMessage(
          { id: 'payment.due.date' },
          {
            date: moment.unix(instalment.dueDate).format('LL'),
          }
        )}
      </div>
    </>
  )
}

const PaymentTransactions = ({ order, instalment }) => {
  const intl = useIntl()

  const paymentInstruments = useSelector(paymentInstrumentsSelector)

  const timezone = moment.tz.guess()
  const txns = Instalments.getAllTransactions(instalment)
  const isRequestRefund = Order.hasRequestedRefund(order)

  return txns.map((tx, i) => {
    const paidAmount = {
      currency: tx.amount.currency,
      amount: tx.amount.amount - tx.transaction_fee.amount,
    }
    const paidDate = moment(tx.createdAt).format('LL')
    const paidTime = moment(tx.createdAt).tz(timezone).format('LT')

    if (['payment', 'adjpayment'].indexOf(tx.transaction_type) >= 0) {
      const paidServiceFee = tx.transaction_fee
      const paymentInstrument = paymentInstruments.find(
        (p) => p.ID === tx.payment_instrument_id
      )
      const isShowPaymentServiceFee =
        paymentInstrument?.fee !== 0 && paidServiceFee.amount > 0

      return (
        <>
          <InstalmentInfoRow>
            <div>
              {intl.formatMessage(
                { id: 'payment.paid.through' },
                {
                  method: intl.formatMessage({
                    id: `payment.method.${paymentInstrument?.name}`,
                  }),
                }
              )}
            </div>
            <div>{FormattedPrice(paidAmount.currency, paidAmount.amount)}</div>
          </InstalmentInfoRow>
          {isShowPaymentServiceFee && (
            <InstalmentInfoRow>
              <div>
                {intl.formatMessage(
                  { id: 'payment.servicefee' },
                  {
                    fee_rate: paymentInstrument?.fee,
                    method: intl.formatMessage({
                      id: `payment.method.${paymentInstrument?.name}`,
                    }),
                  }
                )}
              </div>
              <div>
                {FormattedPrice(paidServiceFee.currency, paidServiceFee.amount)}
              </div>
            </InstalmentInfoRow>
          )}
          <InstalmentInfoRow>
            <InstalmentPaidDateTime>
              {`${paidDate} ${paidTime}`}
            </InstalmentPaidDateTime>
          </InstalmentInfoRow>
          <Spacer height="0.875rem" />
        </>
      )
    }

    if (tx.transaction_type === 'adjustment') {
      return (
        <>
          <InstalmentInfoRow>
            <div>
              {intl.formatMessage({
                id: 'payment.reallocated',
              })}
            </div>
            <div>{FormattedPrice(paidAmount.currency, -paidAmount.amount)}</div>
          </InstalmentInfoRow>
          <InstalmentInfoRow>
            <InstalmentPaidDateTime>{`${paidDate} ${paidTime}`}</InstalmentPaidDateTime>
          </InstalmentInfoRow>
          <Spacer height="0.875rem" />
        </>
      )
    }

    if (tx.transaction_type === 'refund') {
      return (
        <>
          <InstalmentInfoRow>
            <div>
              {intl.formatMessage({
                id: isRequestRefund
                  ? 'payment.refund.pending'
                  : 'payment.refund',
              })}
            </div>
            <div>{FormattedPrice(paidAmount.currency, -paidAmount.amount)}</div>
          </InstalmentInfoRow>
          <InstalmentInfoRow>
            <InstalmentPaidDateTime>{`${paidDate} ${paidTime}`}</InstalmentPaidDateTime>
          </InstalmentInfoRow>
          <Spacer height="0.875rem" />
        </>
      )
    }

    return null
  })
}

const PaymentCreditTransactions = ({ order, instalment }) => {
  const intl = useIntl()

  const txns = Instalments.getAllTransactions(instalment)
  const isRequestRefund = Order.hasRequestedRefund(order)

  return txns.map((tx, i) => {
    const paidAmount = {
      currency: tx.amount.currency,
      amount: tx.amount.amount - tx.transaction_fee.amount,
    }

    if (
      tx.transaction_type === 'noshow' ||
      tx.transaction_type === 'instalment'
    ) {
      return (
        <>
          <InstalmentInfoRow>
            <div>
              {`${AirlineNameByPartnerId(
                intl,
                order.partnerid
              )} ${intl.formatMessage({
                id: isRequestRefund
                  ? 'payment.credit.pending'
                  : 'payment.credit',
              })}`}
            </div>
            <div>{FormattedPrice(paidAmount.currency, -paidAmount.amount)}</div>
          </InstalmentInfoRow>
          <Spacer height="0.875rem" />
        </>
      )
    }

    return null
  })
}

const PaymentDetails = ({ instalment, order, orderMiles }) => {
  const intl = useIntl()

  const isUnderPay = Instalments.isUnderPay(instalment)
  const isOverPay = Instalments.isOverPay(instalment)
  const isShowServiceFee = Order.isFullRefund(order) && instalment.series === 1

  const { serviceFee } = order
  const totalUnpaidAmount = Instalments.getTotalOutstandingAmount(instalment)
  const subtotalAmount = Instalments.getSubtotalAmount(instalment)
  const totalOverPaidAmount = Instalments.getOverPaidAmount(instalment)
  const milesEarned = DivitMiles.getInstalmentMilesEarned(
    instalment,
    orderMiles
  )

  return (
    <>
      <PaymentDue instalment={instalment} />
      <Spacer height="0.875rem" />
      <PaymentCreditTransactions order={order} instalment={instalment} />
      <PaymentTransactions order={order} instalment={instalment} />
      {isUnderPay && (
        <>
          <InstalmentInfoRow style={{ color: '#CC0000' }}>
            <div>
              {intl.formatMessage({
                id: 'payment.amount.outstanding',
              })}
            </div>
            <div>
              {FormattedPrice(
                totalUnpaidAmount.currency,
                totalUnpaidAmount.amount
              )}
            </div>
          </InstalmentInfoRow>
          <Spacer height="0.875rem" />
        </>
      )}
      {isShowServiceFee && (
        <>
          <InstalmentInfoRow>
            {intl.formatMessage(
              { id: 'payment.incl.servicefee' },
              { amount: FormattedPrice(serviceFee.currency, serviceFee.amount) }
            )}
          </InstalmentInfoRow>
          <Spacer height="0.875rem" />
        </>
      )}
      <InstalmentInfoRow>
        <b>
          {intl.formatMessage(
            { id: 'payment.subtotal' },
            {
              times: instalment.series,
            }
          )}
        </b>
        <Amount>
          {FormattedPrice(subtotalAmount.currency, subtotalAmount.amount)}
        </Amount>
      </InstalmentInfoRow>
      {isOverPay && (
        <div style={{ color: '#CC0000' }}>
          <Spacer height="0.875rem" />
          <InstalmentInfoRow>
            <div>
              {intl.formatMessage({
                id: 'payment.amount.over.paid',
              })}
            </div>
            <div>
              {FormattedPrice(
                totalOverPaidAmount.currency,
                totalOverPaidAmount.amount
              )}
            </div>
          </InstalmentInfoRow>
          <InstalmentInfoRow>
            {intl.formatMessage({
              id: 'please.contact.support',
            })}
          </InstalmentInfoRow>
        </div>
      )}

      {milesEarned > 0 && (
        <>
          <Spacer height="0.875rem" />
          <InstalmentInfoRow>
            <MilesEarnedRow miles={milesEarned} />
          </InstalmentInfoRow>
        </>
      )}

      <Spacer height="0.5rem" />
    </>
  )
}

const PaymentTotal = ({ order, orderMiles }) => {
  const intl = useIntl()

  const updatedOrderAmount = Order.getUpdatedOrderAmount(order)
  const totalTransactionFee = Order.getTotalServiceFee(order)
  const totalLateFee = Instalments.getTotalLateFee(order.instalments)
  const totalMilesEarned = DivitMiles.getTotalMilesEarned(orderMiles)

  return (
    <Instalment>
      <InstalmentFull>
        <InstalmentInfoRow>
          <b>{intl.formatMessage({ id: 'receipt.total' })}</b>
          <b>
            {FormattedPrice(
              updatedOrderAmount.currency,
              updatedOrderAmount.amount
            )}
          </b>
        </InstalmentInfoRow>
        {totalLateFee.amount > 0 && (
          <InstalmentInfoRow>
            <div>{intl.formatMessage({ id: 'payment.latefee' })}</div>
            <div>
              {FormattedPrice(totalLateFee.currency, totalLateFee.amount)}
            </div>
          </InstalmentInfoRow>
        )}
        {totalTransactionFee.amount > 0 && (
          <InstalmentInfoRow>
            <div>{intl.formatMessage({ id: 'payment.servicefee.total' })}</div>
            <div>
              {FormattedPrice(
                totalTransactionFee.currency,
                totalTransactionFee.amount
              )}
            </div>
          </InstalmentInfoRow>
        )}
        {totalMilesEarned > 0 && (
          <>
            <Spacer height="0.2rem" />
            <MilesEarnedRow miles={totalMilesEarned} />
          </>
        )}
      </InstalmentFull>
    </Instalment>
  )
}

const InstalmentsInfo = ({
  order,
  orderMiles,
  isExpandable,
  showTotal,
  showActive,
}) => {
  const intl = useIntl()
  const history = useHistory()

  const defaultExpanded = !isExpandable
  const [expandables, setExpanables] = useState([
    defaultExpanded,
    defaultExpanded,
    defaultExpanded,
  ])

  if (!order) return <></>

  const onToggleExpand = (index) => {
    setExpanables((s) => {
      const ex = s.slice()
      ex[index] = !ex[index]
      return ex
    })
  }

  const onClickPay = (instalment) => {
    history.push(`/payment/${order.orderid}/${instalment.instalmentID}`)
  }

  const { instalments } = order

  // divit miles
  const bonusMilesEarned = DivitMiles.getBonusMilesEarned(orderMiles)

  return (
    <>
      <InstalmentsContainer>
        {instalments.map((instalment, i) => {
          const outstandingInstalment = Instalments.getOutstandingInstalment(
            instalments
          )
          const isOutstandingInstalment =
            Instalments.isPayable(instalment) &&
            outstandingInstalment.series === instalment.series

          return (
            <Instalment key={instalment.instalmentID}>
              <InstalmentLeft>
                <PaymentBubble
                  instalments={instalments}
                  instalment={instalment}
                  showActive={showActive}
                />
                <PaymentStraightLine
                  isShow={bonusMilesEarned > 0}
                  instalments={instalments}
                  instalment={instalment}
                />
              </InstalmentLeft>
              <InstalmentRight>
                <InstalmentInfo>
                  <PaymentTitle
                    instalments={instalments}
                    instalment={instalment}
                    showActive={showActive}
                  />
                  {expandables[i] && (
                    <PaymentDetails
                      order={order}
                      instalments={instalments}
                      instalment={instalment}
                      orderMiles={orderMiles}
                    />
                  )}
                  {isExpandable && (
                    <>
                      <Spacer height="0.3rem" />
                      <Row>
                        <ShowMore
                          isShowMore={expandables[i]}
                          onClick={() => onToggleExpand(i)}
                        />
                        {isOutstandingInstalment && (
                          <PayButton onClick={() => onClickPay(instalment)}>
                            {intl.formatMessage({ id: 'button.pay.now' })}
                          </PayButton>
                        )}
                      </Row>
                    </>
                  )}
                </InstalmentInfo>
                {i !== instalments.length - 1 && <HR />}
              </InstalmentRight>
            </Instalment>
          )
        })}
      </InstalmentsContainer>

      {bonusMilesEarned > 0 && (
        <>
          <BonusMilesEarned miles={bonusMilesEarned} />
          <Spacer height="0.5rem" />
        </>
      )}

      {showTotal && (
        <>
          <HR />
          <PaymentTotal order={order} orderMiles={orderMiles} />
        </>
      )}
    </>
  )
}

InstalmentsInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object,
  showTotal: PropTypes.bool,
  showActive: PropTypes.bool,
}

InstalmentsInfo.defaultProps = {
  order: null,
  showTotal: false,
  showActive: true,
}

export default InstalmentsInfo
