import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  paymentInstruments: [],
  rateCurrencyToDVM: [],
  merchantRateCurrencyToDVM: [],
  merchants: [],
  services: [],
}

const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    getPaymentInstrumentsSuccess: (state, action) => {
      state.paymentInstruments = action.payload
    },
    getRateCurrencyToDVMSuccess: (state, action) => {
      state.rateCurrencyToDVM = _.unionWith(
        state.rateCurrencyToDVM,
        [action.payload],
        _.isEqual
      )
    },
    getMerchantRateCurrencyToDVMSuccess: (state, action) => {
      state.merchantRateCurrencyToDVM = action.payload
    },
    getMerchantListSuccess: (state, action) => {
      state.merchants = action.payload
    },
    getServicesSuccess: (state, action) => {
      state.services = action.payload
    },
  },
})

export const { actions } = lookupSlice

export default lookupSlice.reducer
