import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import CheckBox from '@/components/ui/CheckBox'
import { formatMessage } from '@/locales/format'

const AgreementRow = styled.div`
  display: flex;
`

const AgreementCheckbox = styled(CheckBox)``

const AgreementMessage = styled.label`
  flex: 1;
  font-size: 0.667rem;
  margin-left: 0.889rem;
  cursor: pointer;
`

const RegisterAgreement = ({ checked, onClick }) => {
  const intl = useIntl()

  return (
    <AgreementRow>
      <AgreementCheckbox
        id="agreePrivacy"
        checked={checked}
        onChange={(e) => onClick(e.target.checked)}
      />
      <AgreementMessage htmlFor="agreePrivacy">
        {formatMessage(intl, 'signup.privacy.terms', {
          privacyUrl: (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.divit.com.hk/privacy"
            >
              https://www.divit.com.hk/privacy
            </a>
          ),
        })}
      </AgreementMessage>
    </AgreementRow>
  )
}

export default RegisterAgreement
