import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.message.error : '#dedede')};
  border-radius: 0.44rem;

  &:hover {
    border-color: #ccc;
  }

  &:active,
  &:focus {
    border-color: ${({ error, theme }) =>
      error ? theme.message.error : '#f7ce55;'};
  }
`

const Input = styled.input`
  flex: 1;
  height: 2.67rem;
  padding: 0 0.78rem;
  font-size: 0.78rem;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  padding-left: ${(p) => (p.hasPrefix ? '2.444rem' : '0.889rem')};
  padding-right: 0.889rem;
  box-sizing: border-box;
  background: transparent;
  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`

const InputComponent = ({
  style,
  className,
  inputStyle,
  prefix,
  error,
  ...rest
}) => (
  <Container style={style} className={className} error={error}>
    {prefix}
    <Input style={inputStyle} hasPrefix={!!prefix} {...rest} />
  </Container>
)

export default InputComponent
