import { all, call, put } from 'redux-saga/effects'

import {
  fetchDivitMiles,
  fetchDivitMilesByInstalment,
} from '@/redux/divitMiles/divitMilesSaga'
import DivitAPI from '@/services/api'

import { fetchServices } from '../lookup/lookupSaga'
import { fetchEDDAAccounts } from '../profile/profileSaga'
import { getError, getErrorMessage } from '../utils/error'
import { actions as paylaterActions } from './paylaterSlice'

export function* getProfile() {
  try {
    yield put(paylaterActions.getProfileStart())
    const { data } = yield call(async () => DivitAPI.get('profiles'))
    yield put({ type: 'profile/getProfileSuccess', payload: data.data })
    yield put(paylaterActions.getProfileSuccess(data))
  } catch (e) {
    yield put(paylaterActions.getProfileFailed(getErrorMessage(e)))
  }
}

export function* patchProfile({ payload }) {
  try {
    yield put(paylaterActions.patchProfileStart())
    const { data } = yield call(async () => DivitAPI.patch('profiles', payload))
    yield put({ type: 'profile/getProfileSuccess', payload: data.data })
    yield put(paylaterActions.patchProfileSuccess())
  } catch (e) {
    yield put(paylaterActions.patchProfileFailed(getError(e)))
  }
}

export function* previewOrder({ payload }) {
  try {
    const { orderId } = payload
    yield put(paylaterActions.previewOrderStart())
    yield fetchServices()
    const { data } = yield call(() =>
      DivitAPI.get(`orders/${orderId}/preview`, { orderId })
    )
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paylaterActions.previewOrderSuccess(data))
  } catch (e) {
    yield put(paylaterActions.previewOrderFailed(getErrorMessage(e)))
  }
}

export function* linkOrder({ payload }) {
  try {
    const { orderId, expiredAt } = payload
    yield put(paylaterActions.linkOrderStart())
    const { data } = yield call(() =>
      DivitAPI.patch(`profiles/orders/${orderId}/link`, {
        expiredAt,
      })
    )
    yield put(paylaterActions.linkOrderSuccess(data))
  } catch (e) {
    yield put(paylaterActions.linkOrderFailed(getError(e)))
  }
}

export function* patchInstalments({ payload }) {
  try {
    const { instalments } = payload
    yield put(paylaterActions.patchInstalmentsStart())
    yield all(
      instalments.map((instalment) =>
        call(() => {
          const { instalmentId, ...patch } = instalment
          return DivitAPI.patch(`/profiles/instalments/${instalmentId}`, {
            ...patch,
          })
        })
      )
    )
    yield put(paylaterActions.patchInstalmentsSuccess())
  } catch (e) {
    yield put(paylaterActions.patchInstalmentsFailed(getErrorMessage(e)))
  }
}

export function* acceptTNC({ payload }) {
  try {
    yield put(paylaterActions.acceptTNCStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`profiles/orders/${orderId}`, { acceptTNC: true })
    )
    // update redux order
    const { data: orderData } = yield call(() =>
      DivitAPI.get(`profiles/orders/${orderId}`)
    )
    yield put({ type: 'order/getOrderSuccess', payload: orderData.data })
    yield put(paylaterActions.acceptTNCSuccess(data))
  } catch (e) {
    yield put(paylaterActions.acceptTNCFailed(getErrorMessage(e)))
  }
}

export function* cancelOrder({ payload }) {
  try {
    yield put(paylaterActions.cancelOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.delete(`profiles/orders/${orderId}`)
    )
    yield put(paylaterActions.cancelOrderSuccess(data))
  } catch (e) {
    yield put(paylaterActions.cancelOrderFailed(getErrorMessage(e)))
  }
}

export function* getPaymentAccounts() {
  try {
    yield put(paylaterActions.getPaymentAccountsStart())
    const data = yield fetchEDDAAccounts()
    yield put(paylaterActions.getPaymentAccountsSuccess(data))
  } catch (e) {
    yield put(paylaterActions.getPaymentAccountsFailed(getErrorMessage(e)))
  }
}

export function* burnMiles({ payload }) {
  try {
    yield put(paylaterActions.burnMilesStart())
    const { orderId, instalmentId, miles } = payload
    const { data } = yield call(() =>
      // paylater burn
      DivitAPI.patch(`profiles/orders/${orderId}/miles`, {
        instalmentID: instalmentId,
        miles,
      })
    )
    // refresh miles balance can be spent
    yield fetchDivitMiles()
    yield fetchDivitMilesByInstalment({ orderId, instalmentId })
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paylaterActions.burnMilesSuccess(data.data))
  } catch (e) {
    yield put(paylaterActions.burnMilesFailed(getError(e)))
  }
}

export function* resetBurnMiles({ payload }) {
  try {
    yield put(paylaterActions.resetBurnMilesStart())
    const { orderId, instalmentId, miles } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`profiles/orders/${orderId}/miles`, {
        instalmentID: instalmentId,
        miles,
      })
    )
    // refresh miles balance can be spent
    yield fetchDivitMiles()
    yield fetchDivitMilesByInstalment({ orderId, instalmentId })
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paylaterActions.resetBurnMilesSuccess(data.data))
  } catch (e) {
    yield put(paylaterActions.resetBurnMilesFailed(getError(e)))
  }
}

export default 0
