import React, { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-yellow-icon.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.8rem 1rem;
  margin: 0 auto;
  border: 1px solid rgba(28, 28, 28, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
`

const Input = styled(CurrencyInput)`
  width: 100%;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 0;
  border: 0;
  text-align: right;
`

const MilesLabel = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
  white-space: nowrap;
`

const MilesInput = ({ max, min = 0, value, onChange }) => {
  const inputRef = useRef(null)
  const intl = useIntl()
  const [inputValue, setInputValue] = useState(() => String(value))

  useEffect(() => {
    setInputValue(String(value / 100))
  }, [value])

  const onClickInput = () => {
    inputRef.current.focus()
  }

  const onChangeMiles = (v) => {
    if (max < parseFloat(v)) {
      const maxValue = Math.round(max * 100) / 100
      setInputValue(String(maxValue))
      onChange(maxValue * 100)
      inputRef.current?.blur()
    } else {
      setInputValue(v)
      onChange(parseInt(v || '0', 10) * 100)
    }
  }

  const onBlur = () => {
    if (!inputValue) setInputValue('0')
    if (parseFloat(inputValue) < min) {
      const minValue = Math.round(min * 100) / 100
      setInputValue(String(minValue))
      onChange(minValue * 100)
    }
  }

  return (
    <Container onClick={onClickInput}>
      <Icon
        renderImage={() => <DivitMilesSvg />}
        width="2.2rem"
        height="2.2rem"
      />
      <Spacer width="0.8rem" />
      <MilesLabel>{tt(intl, 'miles.divitmiles')}</MilesLabel>
      <Input
        ref={inputRef}
        decimalsLimit={0}
        disableAbbreviations
        onBlur={onBlur}
        defaultValue={inputValue}
        value={inputValue}
        onValueChange={onChangeMiles}
        style={{ color: inputValue === '0' ? 'gray' : 'black' }}
      />
    </Container>
  )
}

export default MilesInput
