import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import BottomSheet from '@/components/BottomSheet'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'

import ProfileDeliveryAddressForm from './ProfileDeliveryAddressForm'
import {
  ActionButtons,
  CancelButton,
  MainButton,
  SubTitle,
  Title,
} from './Shared'

const ProfileDeliveryAddress = ({ address, onClose }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { updateProfileAddress } = useSelector((s) => s.updateProfile)

  const form = useForm()
  const { handleSubmit } = form

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  useEffect(() => {
    if (updateProfileAddress.isSuccess) {
      onClose?.()
    }
  }, [updateProfileAddress])

  const updateContact = handleSubmit((data) => {
    dispatch({
      type: 'updateProfile/updateProfileAddress',
      payload: {
        ID: address?.ID,
        ...data,
      },
    })
  })

  const onClickClose = () => {
    onClose?.()
  }

  const title = address
    ? tt(intl, 'profile.shippingaddress.edit')
    : tt(intl, 'profile.shippingaddress.add')

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title>{title}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'profile.shippingaddress.addbelow')}</SubTitle>
          <Spacer height="0.889rem" />
          <ErrorMessageLine error={updateProfileAddress.error} />
          <Spacer height="0.889rem" />
          <ProfileDeliveryAddressForm address={address} form={form} />
          <Spacer height="2.222rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <CancelButton onClick={onClickClose}>
              {tt(intl, 'common.cancel')}
            </CancelButton>
            <MainButton onClick={() => updateContact()}>
              {tt(intl, 'common.save')}
            </MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
      <LoadingModal loading={updateProfileAddress.isLoading} />
    </CSLayout.CSContainer>
  )
}

export const ProfileDeliveryAddressModal = ({ isOpen, address, onClose }) => (
  <BottomSheet top open={isOpen} onDismiss={onClose}>
    <ProfileDeliveryAddress address={address} onClose={onClose} />
  </BottomSheet>
)

export const ProfileDeliveryAddressPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfile' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  return <ProfileDeliveryAddress onClose={() => history.back()} />
}

export default ProfileDeliveryAddress
