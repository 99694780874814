import * as qs from 'query-string'
import { matchPath } from 'react-router-dom'

export const matchExactPath = (pathname, path) =>
  matchPath(pathname, { path, exact: true })

export const findRouteByPath = (routes, pathname) =>
  Object.values(routes).find((r) => matchExactPath(pathname, r.path))

export const hasHeader = (routes, pathname) => {
  const route = findRouteByPath(routes, pathname)
  return route && !route.hasNoHeader
}
export const hasFooter = (routes, pathname) => {
  const route = findRouteByPath(routes, pathname)
  return route && route.hasFooter
}
export const isFullWidth = (routes, pathname) => {
  const route = findRouteByPath(routes, pathname)
  return route && route.isFullWidth
}

export const IsLanding = (pathname) => matchExactPath(pathname, '/')

export const IsPaylatering = (pathname) =>
  [
    '/orderpreview/:orderId',
    '/order/:orderId',
    '/pay/:paymentMethod/paylater/:orderId/:instalmentId',
    '/paylater/overdue/:orderId',
    '/paylater/profile/:orderId',
    '/paylater/schedule/:orderId',
    '/paylater/summary/:orderId',
    '/paylater/payment/:orderId/:instalmentId',
    '/pay/fps/paylater/:orderId/:instalmentId',
    '/paylater/payment/:orderId/:instalmentId/review',
    '/paylater/payment/check/:orderId/:instalmentId',
    '/paylater/payme/:orderId/:instalmentId',
    '/paylater/fps/:orderId/:instalmentId',
    '/paylater/payment/status/:orderId/:instalmentId',
    '/paylater/payment/success/:orderId/:instalmentId',
    '/paylater/payment/failure/:orderId/:instalmentId',
    '/paylater/welcome',
    '/paylater/setup-account',
    '/paylater/verify-mobile',
    '/paylater/verify-email',
    '/paylater/kyc',
  ].some((item) => matchExactPath(pathname, item))

// check current is checkout process
export const isCheckoutPath = (pathname) => {
  const returnUrl = sessionStorage.getItem('return_url')
  return matchExactPath(returnUrl, '/order/:orderId') || IsPaylatering(pathname)
}

export const isByPassRedirection = (pathname) =>
  [
    '/paylater/welcome',
    '/paylater/verify-mobile',
    '/paylater/verify-email',
    '/profile/verify-mobile',
    '/profile/verify-email',
    '/edda/activation',
  ].some((item) => matchExactPath(pathname, item))

export const IsPaylateringEnd = (pathname) =>
  [
    '/paylater/payment/check/:orderId/:instalmentId',
    '/paylater/payment/status/:orderId/:instalmentId',
    '/paylater/payment/success/:orderId/:instalmentId',
    '/paylater/payment/failure/:orderId/:instalmentId',
  ].some((item) => matchExactPath(pathname, item))

export const IsRoot = (pathname) => matchExactPath(pathname, '/')
export const IsLogin = (pathname) =>
  matchExactPath(pathname, '/login') || matchExactPath(pathname, '/signin')
export const IsSignUp = (pathname) =>
  matchExactPath(pathname, '/sign-up') || matchExactPath(pathname, '/signup')
export const IsOrderPreview = (pathname) =>
  matchExactPath(pathname, '/orderpreview/:orderId')
export const IsHome = (pathname) => matchExactPath(pathname, '/home')
export const IsHistory = (pathname) =>
  matchExactPath(pathname, '/history') ||
  matchExactPath(pathname, '/history/*') ||
  matchExactPath(pathname, '/schedule/review/:id') ||
  matchExactPath(pathname, '/schedule/review/paynow/:id')
export const IsError = (pathname) => matchExactPath(pathname, '/error')
export const IsOrder = (pathname) =>
  matchExactPath(pathname, '/orderpreview/:orderId') ||
  matchExactPath(pathname, '/order/:orderId')
export const IsMilesLogin = (pathname) =>
  matchExactPath(pathname, '/signin') || matchExactPath(pathname, '/signup')
export const IsMilesHome = (pathname) =>
  matchExactPath(pathname, '/profile/miles/convert') ||
  matchExactPath(pathname, '/profile/miles')
export const IsClaimMiles = (pathname) =>
  matchExactPath(pathname, '/miles/claim/:token')

export const getPaymentCallbackUri = ({ pathname, orderId, instalmentId }) =>
  IsPaylatering(pathname)
    ? `${window.location.origin}/paylater/payment/status/${orderId}/${instalmentId}`
    : `${window.location.origin}/payment/status/${orderId}/${instalmentId}`

export const getRouteName = (route) => {
  if (!route) {
    return 'Not found'
  }
  if (route.name) {
    return route.name
  }
  if (route.path === '/') {
    return 'Root'
  }
  return route.path
    .split('/')
    .filter((r) => r[0] !== ':')
    .join(' ')
}

export const isCheckout = () => sessionStorage.getItem('is_checkout') === 'true'

export const getOrderIdParam = (pathname) => {
  const { params } =
    matchExactPath(pathname, '/orderpreview/:orderId') ||
    matchExactPath(pathname, '/order/:orderId')
  return params.orderId
}

export const isClaimMiles = () =>
  sessionStorage.getItem('is_claim_divit_miles') === 'true'

export const IsFastPaynow = (pathname) =>
  matchExactPath(pathname, '/paynow/:orderId/fast') ||
  matchExactPath(pathname, '/paynow/:orderId/resume')
// || pathname.indexOf('/pay/:paymentMethod/paynow/') >= 0

export const IsPaynowCheckout = (pathname) =>
  matchExactPath(pathname, '/pay/:paymentMethod/paynow/:orderId')

export const IsPaymentScreen = (pathname) =>
  matchExactPath(pathname, '/paynow/:orderId/fast') ||
  matchExactPath(pathname, '/paynow/:orderId/resume') ||
  pathname.indexOf('/pay/') >= 0 ||
  matchExactPath(pathname, '/pay/:paymentMethod/paynow/:orderId') ||
  [
    '/pay/:paymentMethod/paylater/:orderId/:instalmentId',
    '/fps/:orderId/:instalmentId',
  ].some((item) => matchExactPath(pathname, item))

export const IsExpressPaynow = (pathname) =>
  matchExactPath(pathname, '/paynow/:orderId/express')

export const IsSignupReferralCodeLanding = (pathname) =>
  matchExactPath(pathname, '/signup/r/:referralCode')

export const isInPaynowFlow = () => !!sessionStorage.getItem('transfered_order')

// helper

export const toUrl = (url, params = {}) => {
  const [u, q = ''] = url.split('?')
  const qss = { ...qs.parse(q), ...params }
  const hasQuery = Object.values(qss).length > 0
  const qq = hasQuery ? `?${qs.stringify(qss)}` : ''
  return u + qq
}

export const redirect = (url, params = {}) => {
  console.log('url', url)
  window.location.href = toUrl(url, params)
}
