import React from 'react'
import styled from 'styled-components'

import { Button } from '@/pages/Auth/shared/style'

import Box from './Box'

const MessageBox = styled(Box)`
  padding-left: calc(100% / 12);
  padding-right: calc(100% / 12);
  /* width: calc(100% / 12 * 10); */
  display: flex;
  align-items: flex-start;
`

const Message = styled.div`
  flex: 1;
`

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 0.5rem;
`

const ActionButton = styled(Button)`
  min-width: unset;
  height: 1.778rem;
  font-size: 0.778rem;
`

const NormalMessageBox = ({ children, buttonText, onClick }) => (
  <MessageBox onClick={onClick}>
    <Message>
      {children}
      {onClick && (
        <ButtonContainer>
          <ActionButton>{buttonText}</ActionButton>
        </ButtonContainer>
      )}
    </Message>
  </MessageBox>
)

export default NormalMessageBox
