import { call, put, select } from 'redux-saga/effects'

import { fetchOrderActivities } from '@/redux/divitMiles/activities'
import {
  fetchDivitMiles,
  fetchDivitMilesByOrder,
} from '@/redux/divitMiles/divitMilesSaga'
import * as lookupSagas from '@/redux/lookup/lookupSaga'
import DivitAPI from '@/services/api'

import { fetchLookups, fetchServices } from '../lookup/lookupSaga'
import orderActions from '../order/orderActions'
import { startRequestPinFlow } from '../pin/pinActions'
import { checkFpsIsAvailable } from '../profile/profileSaga'
import { getError } from '../utils/error'
import { putAction } from '../utils/saga'
import { actions as paynowActions } from './paynowSlice'

export function* fetchOrder({ payload }) {
  try {
    yield put(paynowActions.fetchOrderStart())
    const { orderId, promoCode } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`paynow/profiles/orders/${orderId}`)
    )
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    if (promoCode) {
      yield call(() =>
        DivitAPI.patch(`paynow/orders/${orderId}/promo`, { promoCode })
      )
    }
    yield fetchDivitMilesByOrder({ orderId })
    yield fetchOrderActivities({ orderId })
    const { merchantID, currency } = yield select((s) => s.order?.order)
    yield lookupSagas.fetchMerchantRateCurrencyToDVM(merchantID, currency)

    // for spend deatils
    yield fetchLookups()
    yield put(paynowActions.fetchOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.fetchOrderFailed(getError(e)))
  }
}

export function* linkOrder({ payload }) {
  try {
    yield put(paynowActions.linkOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`paynow/profiles/orders/${orderId}/link`)
    )
    yield call(() =>
      DivitAPI.patch(`paynow/profiles/orders/${orderId}`, { tnc: true })
    )
    yield put(paynowActions.linkOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.linkOrderFailed(getError(e)))
  }
}

export function* updateDeliveryAddress({ payload }) {
  try {
    yield put(paynowActions.updateDeliveryAddressStart())
    const { orderId, deliveryAddress } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`paynow/profiles/orders/${orderId}`, {
        tnc: true,
        ...deliveryAddress,
      })
    )
    yield put(paynowActions.updateDeliveryAddressSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.updateDeliveryAddressFailed(getError(e)))
  }
}

export function* burnMiles({ payload }) {
  try {
    yield put(paynowActions.burnMilesStart())
    const { orderId, miles } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`paynow/profiles/orders/${orderId}/miles`, { miles })
    )
    // refresh miles balance can be spent
    yield fetchDivitMiles()
    yield fetchDivitMilesByOrder({ orderId })
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowActions.burnMilesSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.burnMilesFailed(getError(e)))
  }
}

export function* resetBurnMiles({ payload }) {
  try {
    yield put(paynowActions.resetBurnMilesStart())
    const { orderId, miles } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`paynow/profiles/orders/${orderId}/miles`, { miles })
    )
    // refresh miles balance can be spent
    yield fetchDivitMiles()
    yield fetchDivitMilesByOrder({ orderId })
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowActions.resetBurnMilesSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.resetBurnMilesFailed(getError(e)))
  }
}

export function* generateFpsLink({ payload }) {
  try {
    yield put(paynowActions.generateFpsLinkStart())
    yield fetchServices()
    yield checkFpsIsAvailable()
    const { cachedFpsData } = yield select((s) => s.paynow)
    if (cachedFpsData) {
      yield put(paynowActions.generateFpsLinkSuccess(cachedFpsData))
      yield put(paynowActions.clearCachedFpsData())
    } else {
      const { pin, orderId, instalmentId, isBurn, paymentMethod } = payload
      const { data } = yield call(() =>
        DivitAPI.post('paynow/payments', {
          pin,
          orderID: orderId,
          instalmentID: instalmentId,
          instrumentCode: paymentMethod,
        })
      )
      // refresh book after applied burn
      if (isBurn) {
        yield fetchDivitMiles()
      }
      yield put(paynowActions.generateFpsLinkSuccess(data.data))
    }
  } catch (e) {
    yield put(paynowActions.generateFpsLinkFailed(getError(e)))
  }
}

export function* payByEDDA({ payload }) {
  try {
    yield put(paynowActions.payByEDDAStart())
    const { pin, orderId, instalmentId, isBurn } = payload
    const { data } = yield call(() =>
      DivitAPI.post('paynow/payments', {
        pin,
        orderID: orderId,
        instalmentID: instalmentId,
        instrumentCode: 'edda',
      })
    )
    // refresh book after applied burn
    if (isBurn) {
      yield fetchDivitMiles()
    }
    yield put(paynowActions.payByEDDASuccess(data.data))
  } catch (e) {
    yield put(paynowActions.payByEDDAFailed(getError(e)))
  }
}

export function* fpsRefreshOrder({ payload }) {
  try {
    yield put(paynowActions.refreshOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`paynow/profiles/orders/${orderId}`)
    )
    if (data.data.status.toLowerCase() === 'completed') {
      yield fetchDivitMilesByOrder({ orderId })
      yield fetchOrderActivities({ orderId })
    }
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowActions.refreshOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.refreshOrderFailed(getError(e)))
  }
}

export function* fetchCompletedOrder({ payload }) {
  try {
    yield put(paynowActions.fetchCompletedOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`paynow/profiles/orders/${orderId}`)
    )
    yield fetchDivitMilesByOrder({ orderId: data.data.orderID })
    yield fetchOrderActivities({ orderId: data.data.orderID })

    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowActions.fetchCompletedOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.fetchCompletedOrderFailed(getError(e)))
  }
}

export function* cancelOrder({ payload }) {
  try {
    yield put(paynowActions.cancelOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.delete(`paynow/profiles/orders/${orderId}`)
    )
    yield put(paynowActions.cancelOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowActions.cancelOrderFailed(getError(e)))
  }
}

export function* cancelAllOrders() {
  try {
    yield put(paynowActions.cancelAllOrdersStart())
    yield call(() => DivitAPI.delete(`paynow/profiles/orders`))
    yield put(paynowActions.cancelAllOrdersSuccess(true))
  } catch (e) {
    yield put(paynowActions.cancelAllOrdersFailed(getError(e)))
  }
}

export function* autoBurnMiles({ payload }) {
  const { orderId, miles } = payload

  try {
    yield put(paynowActions.autoBurnMilesStart())

    yield putAction({
      type: 'paynow/linkOrder',
      payload: { orderId },
      success: paynowActions.linkOrderSuccess.toString(),
      fail: paynowActions.linkOrderFailed.toString(),
      error: new Error('fail to link order'),
    })

    yield putAction({
      type: 'paynow/burnMiles',
      payload: { orderId, miles },
      success: paynowActions.burnMilesSuccess.toString(),
      fail: paynowActions.burnMilesFailed.toString(),
      error: new Error('fail to burn miles'),
    })

    const pinRequest = yield put(startRequestPinFlow())
    if (!pinRequest.isSuccess) {
      throw new Error('fail to auth pin')
    }

    const order = yield putAction({
      type: 'order/fetchOrder',
      payload: { orderId },
      success: orderActions.fetchOrderSuccess.toString(),
      fail: orderActions.fetchOrderFailed.toString(),
      error: new Error('fail to fetch order'),
    })

    yield putAction({
      type: 'paynow/generateFpsLink',
      payload: {
        pin: pinRequest.pin,
        orderId,
        instalmentId: order.instalemnts[0].instalmentID,
        paymentMethod: 'fps',
      },
      success: paynowActions.generateFpsLinkSuccess.toString(),
      fail: paynowActions.generateFpsLinkFailed.toString(),
      error: new Error('fail to generate fps'),
    })

    yield put(paynowActions.autoBurnMilesSuccess(true))
  } catch (e) {
    if (e.message === 'fail to generate fps') {
      yield putAction({
        type: 'paynow/resetBurnMiles',
        payload: { orderId, miles },
        success: paynowActions.resetBurnMilesSuccess.toString(),
        fail: paynowActions.resetBurnMilesFailed.toString(),
      })
    }
    yield put(paynowActions.autoBurnMilesFailed(getError(e)))
  }
}

export default null
