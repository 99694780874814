import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as AddressSvg } from '@/assets/paylater/enable/address.svg'
import HeroSvg from '@/assets/paylater/enable/hero.svg'
import { ReactComponent as HKIDSvg } from '@/assets/paylater/enable/hkid.svg'
import { ReactComponent as SpreadSvg } from '@/assets/paylater/enable/spread.svg'
import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  box-sizing: border-box;
  background-color: white;
`

const Content = styled.div`
  position: relative;
  box-sizing: border-box;
`

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 1.33rem;
  font-weight: 700;
  padding: 0 5rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    padding: 0 0.66rem;
  }
`

const HeroImgContainer = styled.div`
  width: 100%;
  height: 153.62px;
  text-align: center;
  background-image: url(${HeroSvg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`

const List = styled.div`
  max-width: 16rem;
  margin: 0 auto;
  padding: 0 2.8rem;
`

const SectionTitle = styled.div`
  font-size: 0.88rem;
  font-weight: 700;
`

const Item = styled.div`
  display: flex;
  align-items: center;
`

const ItemIcon = styled.div`
  width: 1.56rem;
  height: 1.56rem;

  & > svg {
    width: 100%;
    height: 100%;
  }
`

const ItemTitle = styled.div`
  flex: 1;
  font-size: 0.77rem;
  margin-left: 0.88rem;
`

const EnablePayLaterModal = ({ isOpen, onClose }) => {
  const intl = useIntl()

  return (
    <Modal isOpen={isOpen} isLock>
      <Container>
        <Content>
          <CloseButton onClick={onClose}>
            <Icon renderImage={() => <CloseSvg />} width="2rem" height="2rem" />
          </CloseButton>
          <Spacer height="3rem" />
          <Title>{tt(intl, 'paylater.enableit')}</Title>
          <Spacer height="1.66rem" />
          <HeroImgContainer />
          <Spacer height="2.38rem" />
          <List>
            <SectionTitle>{tt(intl, 'paylater.whatis')}</SectionTitle>
            <Spacer height="1rem" />
            <Item>
              <ItemIcon>
                <SpreadSvg />
              </ItemIcon>
              <ItemTitle>{tt(intl, 'paylater.spread3payment')}</ItemTitle>
            </Item>
            <Spacer height="2.33rem" />
            <SectionTitle>{tt(intl, 'profile.provide.paylater')}:</SectionTitle>
            <Spacer height="1rem" />
            <Item>
              <ItemIcon>
                <HKIDSvg />
              </ItemIcon>
              <ItemTitle>{tt(intl, 'profile.validhkid')}</ItemTitle>
            </Item>
            <Spacer height="1rem" />
            <Item>
              <ItemIcon>
                <AddressSvg />
              </ItemIcon>
              <ItemTitle>{tt(intl, 'profile.validaddress')}</ItemTitle>
            </Item>
          </List>
          <Spacer height="2.66rem" />
        </Content>
      </Container>
    </Modal>
  )
}

export default EnablePayLaterModal
