import React from 'react'
import { useIntl } from 'react-intl'

const OrderReference = ({ orderType, partnerRef }) => {
  const intl = useIntl()

  if (orderType === 'flight') {
    return intl.formatMessage(
      { id: 'booking.reference' },
      {
        reference: <span>{partnerRef}</span>,
      }
    )
  }

  return (
    <>
      {intl.formatMessage({
        id: 'order.merchantreference',
      })}
      : <span>{partnerRef}</span>
    </>
  )
}

export default OrderReference
