import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Line from '@/components/Line'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'

import { Anchor } from '../ui/Shared'

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.333rem 1.111rem;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.08);
`

const OrderNumber = styled.div`
  font-size: 0.667rem;
`

const OrderPaidAmount = styled.div``

const OrderPayText = styled.div`
  font-size: 0.7778rem;
  font-weight: 500;
`

const OrderAmount = styled(MilesValue)`
  font-weight: 600;
`

const ProductDetails = styled.div`
  display: flex;
`

const ProductQty = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const ProductCardMilesValue = styled(MilesValue)`
  font-weight: 600;
  align-self: flex-start;
`

const CardNameDetails = styled.div`
  flex: 1;
  margin: 0 1.222rem;
`

const CardName = styled.div`
  font-size: 0.778rem;
`

const ActionContainer = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  text-align: center;
`

const GiftCardSummary = ({ payment }) => {
  const intl = useIntl()
  const history = useHistory()

  const onClickViewMyGiftCards = () => {
    history.push('/profile/gift-cards')
  }

  return (
    <Container>
      <OrderNumber>
        {tt(intl, 'order.merchantreference')}:{' '}
        {`#${payment.transactionID.substring(0, 8)}`}
      </OrderNumber>
      <Spacer height="0.889rem" />
      <OrderPaidAmount>
        <OrderPayText>{tt(intl, 'payment.youspent')}</OrderPayText>
        <Spacer height="0.444rem" />
        <OrderAmount
          fontSize="1.333rem"
          miles={payment.amount.amount}
          isSigned={false}
        />
      </OrderPaidAmount>
      <Spacer height="1.333rem" />
      <Line />
      <Spacer height="1.333rem" />
      <ProductDetails>
        <ProductQty>1x</ProductQty>
        <CardNameDetails>
          <CardName>{payment.metadata?.title}</CardName>
        </CardNameDetails>
        <ProductCardMilesValue
          fontSize="0.778rem"
          miles={payment.amount.amount}
          isSigned={false}
        />
      </ProductDetails>
      <Spacer height="1.333rem" />
      <Line />
      <Spacer height="1.333rem" />
      <ActionContainer>
        <Anchor onClick={onClickViewMyGiftCards}>
          {tt(intl, 'giftcard.viewmy')}
        </Anchor>
      </ActionContainer>
    </Container>
  )
}

export default GiftCardSummary
