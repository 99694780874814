import 'moment-timezone'

// import * as OrderPaynow from '@/utils/OrderPaynow'
import { OrderPaynow } from '@divit-stem/divit-orderjs'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as CancelledSvg } from '@/assets/paylater/instalment-cancelled.svg'
import { ReactComponent as PaidSvg } from '@/assets/paylater/instalment-paid.svg'
import { Button } from '@/components/Divit'
import Icon from '@/components/Icon'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import InstalmentIcon from '@/components/paynow/InstalmentIcon'
import Spacer from '@/components/Spacer'
import ProgressBar from '@/components/ui/ProgressBar'
import { tt } from '@/locales/format'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.333rem 1.111rem;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.08);
`

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
`

const RemainingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RemainingText = styled.div`
  font-size: 0.667rem;
`

const RemainingAmount = styled.div`
  font-size: 0.667rem;
`

const InstalmentsContainer = styled.div`
  overflow: hidden;
`

const Instalment = styled.div`
  font-size: 0.75rem;
  display: flex;
  color: ${({ active, theme }) => active && `${theme.primary}`};
`

const InstalmentLeft = styled.div``

const InstalmentRight = styled.div`
  flex: 1;
  margin-left: 1.111rem;
`

const InstalmentStep = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0.55rem 0;
`

const InstalmentInfo = styled.div`
  padding: 0.889rem 0;
`

const RowLeft = styled.div`
  font-weight: 500;
`

const RowRight = styled.div`
  font-weight: 600;
`

const InstalmentTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.778rem;
  margin-bottom: 0.5rem;
  color: ${({ error }) => (error ? '#E6171A' : '#1c1c1c')};
`

const InstalmentDate = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const PayButton = styled(Button)`
  font-size: 0.6rem;
  font-weight: 700;
  background-color: #1c1c1c;
  font-weight: 700;
  padding-top: 0.33rem;
  padding-bottom: 0.33rem;
`

const PaymentBubble = ({ order }) => {
  const renderContent = () => {
    if (OrderPaynow(order).isPaid) {
      return (
        <Icon
          renderImage={() => <PaidSvg />}
          width="1.556rem"
          height="1.556rem"
        />
      )
    }
    if (OrderPaynow(order).isCancelled) {
      return (
        <Icon
          renderImage={() => <CancelledSvg />}
          width="1.556rem"
          height="1.556rem"
        />
      )
    }
    return (
      <InstalmentIcon series={1} isActive width="1.556rem" height="1.556rem" />
    )
  }

  return <InstalmentStep>{renderContent()}</InstalmentStep>
}

const PaymentDetails = ({ order, onPay }) => {
  const intl = useIntl()

  const {
    isPaid,
    isCancelled,
    getTotalPaidAmount,
    isUnderPay,
    isOverPay,
    getTotalRequestedRefundAmount,
    getTotalRefundedAmount,
    getTotalAdjustedAmount,
    getOverPaidAmount,
    getTotalOutstandingAmount,
    getLatestTransaction,
  } = OrderPaynow(order)

  const instalment = order.instalments[0]
  const totalPaidAmount = getTotalPaidAmount()
  const requestedRefundAmount = getTotalRequestedRefundAmount()
  const refundAmount = getTotalRefundedAmount()
  const adjustAmount = getTotalAdjustedAmount()
  const totalOverPaidAmount = getOverPaidAmount()
  const totalOutstandingAmount = getTotalOutstandingAmount()
  const txn = getLatestTransaction(moment)

  if (!isPaid && !isCancelled) {
    return (
      <>
        <InstalmentTitleRow>
          <RowLeft>{tt(intl, 'payment.youpay')}</RowLeft>
          <RowRight>
            <PayButton
              data-testid="instalment.pay"
              onClick={() => onPay(instalment)}
            >
              {tt(intl, 'payment.pay')}{' '}
              {FormattedPrice(
                totalOutstandingAmount.currency,
                totalOutstandingAmount.amount
              )}
            </PayButton>
          </RowRight>
        </InstalmentTitleRow>
        {isUnderPay && (
          <InstalmentTitleRow>
            <RowLeft>{tt(intl, 'payment.youpaid')}</RowLeft>
            <RowRight>
              <MilesAndAmountValue
                fontSize="0.778rem"
                currency={totalPaidAmount.currency}
                amount={totalPaidAmount.amount}
                miles={order.milesBurned}
              />
            </RowRight>
          </InstalmentTitleRow>
        )}
        <InstalmentTitleRow>
          <RowLeft>{tt(intl, 'payment.amount')}</RowLeft>
          <RowRight>
            {FormattedPrice(
              instalment.amount.currency,
              instalment.amount.amount
            )}
          </RowRight>
        </InstalmentTitleRow>
      </>
    )
  }

  if (isPaid || isCancelled) {
    return (
      <>
        <InstalmentTitleRow>
          <RowLeft>{tt(intl, 'payment.youpaid')}</RowLeft>
          <RowRight>
            <MilesAndAmountValue
              fontSize="0.778rem"
              currency={totalPaidAmount.currency}
              amount={totalPaidAmount.amount}
              miles={order.milesBurned}
            />
          </RowRight>
        </InstalmentTitleRow>

        {adjustAmount.amount > 0 && (
          <>
            <InstalmentTitleRow>
              <RowLeft>{tt(intl, 'payment.adjusted')}</RowLeft>
              <RowRight>
                {FormattedPrice(
                  adjustAmount.currency,
                  -1 * adjustAmount.amount
                )}
              </RowRight>
            </InstalmentTitleRow>
          </>
        )}

        {refundAmount.amount > 0 && (
          <>
            <InstalmentTitleRow>
              <RowLeft>{tt(intl, 'payment.refunded')}</RowLeft>
              <RowRight>
                {FormattedPrice(
                  refundAmount.currency,
                  -1 * refundAmount.amount
                )}
              </RowRight>
            </InstalmentTitleRow>
          </>
        )}

        {isOverPay && (
          <>
            <InstalmentTitleRow>
              <RowLeft>{tt(intl, 'payment.overpaid')}</RowLeft>
              <RowRight>
                {FormattedPrice(
                  totalOverPaidAmount.currency,
                  totalOverPaidAmount.amount -
                    adjustAmount.amount -
                    refundAmount.amount
                )}
              </RowRight>
            </InstalmentTitleRow>
            {totalOverPaidAmount.amount -
              adjustAmount.amount -
              refundAmount.amount -
              requestedRefundAmount.amount >
              0 && (
              <InstalmentTitleRow>
                {intl.formatMessage({
                  id: 'please.contact.support',
                })}
              </InstalmentTitleRow>
            )}
          </>
        )}

        {requestedRefundAmount.amount > 0 && (
          <>
            <InstalmentTitleRow>
              <RowLeft>{tt(intl, 'payment.refundpending')}</RowLeft>
              <RowRight>
                {FormattedPrice(
                  requestedRefundAmount.currency,
                  -1 * requestedRefundAmount.amount
                )}
              </RowRight>
            </InstalmentTitleRow>
          </>
        )}

        <InstalmentDate>{moment(txn.createdAt).format('LLL')}</InstalmentDate>
      </>
    )
  }

  return null
}

const InstalmentProgress = ({ order }) => {
  const intl = useIntl()

  const instalmentAmount = OrderPaynow.getInstalmentAmount(order)
  const outstandingAmount = OrderPaynow.getTotalOutstandingAmount(order)
  const paidAmount = OrderPaynow.getTotalPaidAmount(order)
  const precentage = parseInt(
    (paidAmount.amount / instalmentAmount.amount) * 100,
    10
  )

  return (
    <>
      <ProgressBar precentage={precentage} />
      <Spacer height="0.444rem" />
      <RemainingRow>
        <RemainingText>{tt(intl, 'payment.remainingamount')}</RemainingText>
        <RemainingAmount>
          {FormattedPrice(outstandingAmount.currency, outstandingAmount.amount)}
        </RemainingAmount>
      </RemainingRow>
    </>
  )
}

const InstalmentsInfo = ({ order }) => {
  const intl = useIntl()
  const history = useHistory()

  if (!order) return <></>

  const isEnd = OrderPaynow(order).isEnd()

  const onClickPay = () => {
    history.push(`/paynow/${order.orderID}`)
  }

  return (
    <Container>
      <SubTitle>{tt(intl, 'payment.payment')}</SubTitle>
      <Spacer height="0.889rem" />
      {!isEnd && <InstalmentProgress order={order} />}
      <InstalmentsContainer>
        <Instalment>
          <InstalmentLeft>
            <PaymentBubble order={order} />
          </InstalmentLeft>
          <InstalmentRight>
            <InstalmentInfo>
              <PaymentDetails order={order} onPay={onClickPay} />
            </InstalmentInfo>
          </InstalmentRight>
        </Instalment>
      </InstalmentsContainer>
    </Container>
  )
}

export default InstalmentsInfo
