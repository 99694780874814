import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import OfferBanners from '@/components/offers/OfferBanners'
import Spacer from '@/components/Spacer'
import { Features, useFeature } from '@/hooks/useFeature'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import * as orderSelectors from '@/redux/order/orderSelector'

const Container = styled(PaddingContent)`
  margin-top: 2.22rem;
`

const Title = styled.div`
  margin-bottom: 0.88rem;
  font-size: 1.33rem;
  font-weight: 700;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
`

const Offers = () => {
  const intl = useIntl()
  const { featureEnabled } = useFeature()

  const hasOrder = useSelector(orderSelectors.hasOrderSelector)
  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initOffers' },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  return (
    <Container>
      <Title>{intl.formatMessage({ id: 'offers.title' })}</Title>
      {featureEnabled(Features.SIGNUP500BONUS) && !hasOrder && (
        <>
          <SubTitle>{tt(intl, 'miles.500bonus')}</SubTitle>
          <Spacer height="0.88rem" />
        </>
      )}
      <Spacer height="0.889rem" />
      <OfferBanners />
      <Spacer height="0.88rem" />
    </Container>
  )
}

export default Offers
