import React from 'react'
import styled from 'styled-components'

import ActiveSvg from '@/assets/ui/checkbox-active.svg'
import InActiveSvg from '@/assets/ui/checkbox-inactive.svg'

const Container = styled.div`
  position: relative;
`

const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  border: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1.111rem;
  height: 1.111rem;
  cursor: pointer;
`

const Square = styled.div`
  width: 1.111rem;
  height: 1.111rem;
  background-image: url(${(p) => (p.checked ? ActiveSvg : InActiveSvg)});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`

const Checkbox = ({ checked, onChange, ...props }) => (
  <Container>
    <HiddenInput defaultChecked={checked} onChange={onChange} {...props} />
    <Square checked={checked} />
  </Container>
)

export default Checkbox
