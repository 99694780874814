import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const updateProfileSlice = createSlice({
  name: 'updateProfile',
  initialState: {
    updateProfile: AsyncState.create(),
    updateProfileAdditional: AsyncState.create(),
    updateContactMethod: AsyncState.create(),
    linkSocialProvider: AsyncState.create(),
    unlinkSocialProvider: AsyncState.create(),
    changePassword: AsyncState.create(),
    unlinkMilesProgram: AsyncState.create(),
    giftCardMarkAsUsed: AsyncState.create(),
    updateProfileSettings: AsyncState.create(),
    updateProfileAddress: AsyncState.create(),
    removeProfileAddress: AsyncState.create(),
    updateProfileIdentity: AsyncState.create(),
    updateEDDAAccount: AsyncState.create(),
    removeEDDAAccount: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.updateProfile = AsyncState.reset()
      state.updateProfileAdditional = AsyncState.reset()
      state.updateContactMethod = AsyncState.reset()
      state.linkSocialProvider = AsyncState.create()
      state.unlinkSocialProvider = AsyncState.create()
      state.changePassword = AsyncState.reset()
      state.unlinkMilesProgram = AsyncState.reset()
      state.giftCardMarkAsUsed = AsyncState.reset()
      state.updateProfileSettings = AsyncState.reset()
      state.updateProfileAddress = AsyncState.reset()
      state.removeProfileAddress = AsyncState.reset()
      state.updateProfileIdentity = AsyncState.reset()
      state.updateEDDAAccount = AsyncState.reset()
      state.removeEDDAAccount = AsyncState.reset()
    },
    updateProfileStart: (state) => {
      state.updateProfile = AsyncState.start()
    },
    updateProfileSuccess: (state, action) => {
      state.updateProfile = AsyncState.success(action.payload)
    },
    updateProfileFailed: (state, action) => {
      state.updateProfile = AsyncState.failed(action.payload)
    },
    updateProfileAdditionalStart: (state) => {
      state.updateProfileAdditional = AsyncState.start()
    },
    updateProfileAdditionalSuccess: (state, action) => {
      state.updateProfileAdditional = AsyncState.success(action.payload)
    },
    updateProfileAdditionalFailed: (state, action) => {
      state.updateProfileAdditional = AsyncState.failed(action.payload)
    },
    updateProfileIdentityStart: (state) => {
      state.updateProfileIdentity = AsyncState.start()
    },
    updateProfileIdentitySuccess: (state, action) => {
      state.updateProfileIdentity = AsyncState.success(action.payload)
    },
    updateProfileIdentityFailed: (state, action) => {
      state.updateProfileIdentity = AsyncState.failed(action.payload)
    },
    updateContactMethodStart: (state) => {
      state.updateContactMethod = AsyncState.start()
    },
    updateContactMethodSuccess: (state, action) => {
      state.updateContactMethod = AsyncState.success(action.payload)
    },
    updateContactMethodFailed: (state, action) => {
      state.updateContactMethod = AsyncState.failed(action.payload)
    },
    linkSocialProviderStart: (state) => {
      state.linkSocialProvider = AsyncState.start()
    },
    linkSocialProviderSuccess: (state, action) => {
      state.linkSocialProvider = AsyncState.success(action.payload)
    },
    linkSocialProviderFailed: (state, action) => {
      state.linkSocialProvider = AsyncState.failed(action.payload)
    },
    unlinkSocialProviderStart: (state) => {
      state.unlinkSocialProvider = AsyncState.start()
    },
    unlinkSocialProviderSuccess: (state, action) => {
      state.unlinkSocialProvider = AsyncState.success(action.payload)
    },
    unlinkSocialProviderFailed: (state, action) => {
      state.unlinkSocialProvider = AsyncState.failed(action.payload)
    },
    changePasswordStart: (state) => {
      state.changePassword = AsyncState.start()
    },
    changePasswordSuccess: (state, action) => {
      state.changePassword = AsyncState.success(action.payload)
    },
    changePasswordFailed: (state, action) => {
      state.changePassword = AsyncState.failed(action.payload)
    },
    unlinkMilesProgramStart: (state) => {
      state.unlinkMilesProgram = AsyncState.start()
    },
    unlinkMilesProgramSuccess: (state, action) => {
      state.unlinkMilesProgram = AsyncState.success(action.payload)
    },
    unlinkMilesProgramFailed: (state, action) => {
      state.unlinkMilesProgram = AsyncState.failed(action.payload)
    },
    giftCardMarkAsUsedStart: (state) => {
      state.giftCardMarkAsUsed = AsyncState.start()
    },
    giftCardMarkAsUsedSuccess: (state, action) => {
      state.giftCardMarkAsUsed = AsyncState.success(action.payload)
    },
    giftCardMarkAsUsedFailed: (state, action) => {
      state.giftCardMarkAsUsed = AsyncState.failed(action.payload)
    },
    updateProfileSettingsStart: (state) => {
      state.updateProfileSettings = AsyncState.start()
    },
    updateProfileSettingsSuccess: (state, action) => {
      state.updateProfileSettings = AsyncState.success(action.payload)
    },
    updateProfileSettingsFailed: (state, action) => {
      state.updateProfileSettings = AsyncState.failed(action.payload)
    },
    updateProfileAddressStart: (state) => {
      state.updateProfileAddress = AsyncState.start()
    },
    updateProfileAddressSuccess: (state, action) => {
      state.updateProfileAddress = AsyncState.success(action.payload)
    },
    updateProfileAddressFailed: (state, action) => {
      state.updateProfileAddress = AsyncState.failed(action.payload)
    },
    removeProfileAddressStart: (state) => {
      state.removeProfileAddress = AsyncState.start()
    },
    removeProfileAddressSuccess: (state, action) => {
      state.removeProfileAddress = AsyncState.success(action.payload)
    },
    removeProfileAddressFailed: (state, action) => {
      state.removeProfileAddress = AsyncState.failed(action.payload)
    },
    updateEDDAAccountStart: (state) => {
      state.updateEDDAAccount = AsyncState.start()
    },
    updateEDDAAccountSuccess: (state, action) => {
      state.updateEDDAAccount = AsyncState.success(action.payload)
    },
    updateEDDAAccountFailed: (state, action) => {
      state.updateEDDAAccount = AsyncState.failed(action.payload)
    },
    removeEDDAAccountStart: (state) => {
      state.removeEDDAAccount = AsyncState.start()
    },
    removeEDDAAccountSuccess: (state, action) => {
      state.removeEDDAAccount = AsyncState.success(action.payload)
    },
    removeEDDAAccountFailed: (state, action) => {
      state.removeEDDAAccount = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = updateProfileSlice

export default updateProfileSlice.reducer
