import moment from 'moment'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import CancelPurchase from '@/components/CancelPurchase'
import LoadingScreen from '@/components/LoadingScreen'
import Modal from '@/components/Modal'
import CountryOfBirthVerify from '@/components/setupPayment/CountryOfBirthVerify'
import DateOfBirthVerify from '@/components/setupPayment/DateOfBirthVerify'
import NationalityVerify from '@/components/setupPayment/NationalityVerify'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.111rem;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 calc(100% / 20);
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.xs}px`}) {
    height: 110%;
  }
`

const Content = styled.div`
  box-sizing: border-box;
  max-width: 480px;
  width: 100%;
  padding: 1rem;
  background-color: #f5f6f7;
  border-radius: 8px;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.sm}px`}) {
    padding: 1rem 1.5rem;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.8rem;
  text-align: center;
`

const FourStepContainer = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
`

const ContinueButton = styled(Button)`
  font-size: 0.89rem;
`

const SetupPaymentModal = ({ isOpen, orderID, orderType, instalmentID }) => {
  const intl = useIntl()
  const { profileAdditional, loading } = useSelector(
    (state) => state.profileAdditional
  )
  const countryList = useSelector((s) => s.app?.countryList || {})
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: 'fetchProfileAdditional',
      payload: {
        instalmentID,
      },
    })
  }, [])

  const form = useForm()

  const { watch, handleSubmit, setValue } = form

  const updateContact = handleSubmit((data) => {
    dispatch({
      type: 'updateProfile/updateProfileAdditional',
      payload: {
        countryOfBirth: data.countryOfBirth || '',
        DOB:
          moment(data.DOB, 'DD/MM/YYYY')
            .locale('en')
            .format('DD MMM YYYY')
            .toUpperCase() || '', // 01 JAN 1980 (upper case)
        occupation: data.occupation || '',
        address1: data.address1.toUpperCase(),
        address2: data.address2.toUpperCase(),
        district: data.district.toUpperCase(),
        country: data.country,
        province: data.province || '',
        postalCode: data.postalCode || '',
        orderType: orderType || '', // paynow, paylater
        orderID: orderID || '', // uuid
        instalmentID: instalmentID || '', // uuid
        nationality: data.nationality || '',
      },
    })
  })

  useEffect(() => {
    dispatch({ type: 'app/countryList' })
    return () => dispatch({ type: 'updateProfile/reset' })
  }, [])

  useEffect(() => {
    if (profileAdditional) {
      if (profileAdditional.DOB) {
        setValue('DOB', moment(profileAdditional.DOB).format('DD/MM/YYYY'))
      } else {
        setValue('DOB', '')
      }

      setValue('countryOfBirth', profileAdditional.countryOfBirth || '')
      setValue('occupation', profileAdditional.occupation || '')
      setValue('address1', profileAdditional.address1 || '')
      setValue('address2', profileAdditional.address2 || '')
      setValue('district', profileAdditional.district || '')
      setValue('country', profileAdditional.country || '')
      setValue('province', profileAdditional.province || '')
      setValue('postalCode', profileAdditional.postalCode || '')
      setValue('nationality', profileAdditional.nationality || '')
    }
  }, [profileAdditional, process.env.REACT_APP_ENV])

  if (loading) return <LoadingScreen />
  if (!countryList?.HK) return <LoadingScreen />

  const isFullStepValidated =
    watch('DOB') && watch('countryOfBirth') && watch('nationality')

  return (
    <Modal isOpen={isOpen} isLock>
      <Container>
        <Content>
          <Spacer height="1rem" />
          <Title>{tt(intl, 'setupPayment.title')}</Title>
          <Spacer height="1rem" />
          <SubTitle>{tt(intl, 'setupPayment.description')}</SubTitle>
          <Spacer height="2rem" />
          <FourStepContainer>
            <DateOfBirthVerify form={form} />
            <CountryOfBirthVerify form={form} countryList={countryList} />
            <NationalityVerify form={form} countryList={countryList} />
          </FourStepContainer>
          <Spacer height="1.5rem" />
          <ContinueButton
            type="stretch"
            disabled={!isFullStepValidated}
            onClick={() => {
              updateContact()
            }}
          >
            {tt(intl, 'common.continue')}
          </ContinueButton>
          <Spacer height="1rem" />
          <CancelPurchase />
          <Spacer height="1rem" />
        </Content>
      </Container>
    </Modal>
  )
}

export default SetupPaymentModal
