import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 1.75rem;
  border-radius: 0.875rem;
  overflow: hidden;
`

const Step = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 700;
  font-size: 0.667rem;
  color: ${(p) => p.color};
  background: ${(p) => p.backgroundColor};
  position: relative;
  margin-right: 12px;

  :first-child {
    padding-left: 12px;
  }

  :last-child {
    margin-right: 0;
  }

  :before {
    content: '';
    position: absolute;
    border-top: 0.875rem solid ${(p) => p.backgroundColor};
    border-right: 0 solid ${(p) => p.backgroundColor};
    border-bottom: 0.875rem solid ${(p) => p.backgroundColor};
    border-left: 11px solid transparent;
    left: -11px;
  }

  :after {
    content: '';
    width: 0;
    height: 0;
    border-top: 0.875rem solid transparent;
    border-bottom: 0.875rem solid transparent;
    border-left: 11px solid ${(p) => p.backgroundColor};
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    display: inline-block;
  }
`

const CheckoutSteps = ({ index }) => {
  const intl = useIntl()

  const backgroundColor = (position) => {
    if (position === index) return '#ffcc33'
    if (position > index) return '#dedede'
    return '#ffcc33'
  }

  const color = (position) => {
    if (position === index) return '#1c1c1c'
    if (position > index) return 'white'
    return '#1c1c1c'
  }

  return (
    <Container>
      <Step backgroundColor={backgroundColor(0)} color={color(0)}>
        {intl.formatMessage({ id: 'progress.bar.profile' })}
      </Step>
      <Step backgroundColor={backgroundColor(2)} color={color(2)}>
        {intl.formatMessage({ id: 'progress.bar.summary' })}
      </Step>
      <Step backgroundColor={backgroundColor(3)} color={color(3)}>
        {intl.formatMessage({ id: 'progress.bar.payment' })}
      </Step>
    </Container>
  )
}

export default CheckoutSteps
