import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import CancelPurchase from '@/components/CancelPurchase'
import PaddingContent from '@/components/layout/PaddingContent'
import EmailVerify from '@/components/setupAccount/EmailVerify'
import HKAddressVerify from '@/components/setupAccount/HKAddressVerify'
import HKIDVerify from '@/components/setupAccount/HKIDVerify'
import MobileVerify from '@/components/setupAccount/MobileVerify'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'

const Container = styled(PaddingContent)`
  align-items: center;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
`

const SubTitle = styled.div`
  font-size: 16px;
`

const FourStepContainer = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
`

const ContinueButton = styled(Button)`
  font-size: 0.89rem;
`

const SetupAccount = () => {
  const intl = useIntl()
  const { profile, purchaseStatus } = useSelector((state) => state.profile)
  const { state } = useLocation()

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'fetchProfile' })
  }, [])

  const billingAddress = profile?.address?.filter(
    (add) => add.addressType === 'billing'
  )[0]
  const kycNameObj = profile.kyc && profile.kyc[0].metadata
  const isFullStepValidated =
    profile?.email_validated &&
    profile?.tel_validated &&
    (kycNameObj?.firstName || kycNameObj?.lastName) &&
    profile?.nationalID &&
    profile?.DOB &&
    (billingAddress?.address1 ||
      billingAddress?.address2 ||
      billingAddress?.district ||
      billingAddress?.country)

  return (
    <Container>
      <Spacer height="1.5rem" />
      <Title>{tt(intl, 'setup.account')}</Title>
      <Spacer height="1rem" />
      <SubTitle>{tt(intl, 'complete.foursteps')}</SubTitle>
      <Spacer height="2rem" />
      <FourStepContainer>
        <EmailVerify profile={profile} />
        <MobileVerify profile={profile} />
        <HKIDVerify profile={profile} orderId={state?.orderId} />
        <HKAddressVerify profile={profile} />
      </FourStepContainer>
      <Spacer height="1.5rem" />
      <ContinueButton
        type="stretch"
        disabled={!isFullStepValidated}
        onClick={() => {
          history.push(`/order/${state?.orderId}`)
        }}
      >
        {tt(intl, 'common.continue')}
      </ContinueButton>
      <Spacer height="2rem" />
      <CancelPurchase />
      <Spacer height="2rem" />
    </Container>
  )
}

export default SetupAccount
