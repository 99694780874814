/* eslint-disable no-alert */
import axios from 'axios'
import moment from 'moment'

import * as localStore from '@/utils/localStore'

let store = null
export const setApiStore = (s) => {
  store = s
}

const DivitAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'X-Divit-Site-ID': 'consumer',
  },
  timeout: 30000,
  withCredentials: true,
})

// delegated instance to handle refresh token
const RefreshInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'X-Divit-Site-ID': 'consumer',
  },
  timeout: 30000,
  withCredentials: true,
})

const handleTokenError = () => {
  store.dispatch({ type: 'tokenExpiredRedirect' })
}

// How to test token expire cases:
// 1. go to backend project divit-api
// 2. generateJWT with expireTime 0
// 3. comment refreshToken conditions to test
// 4. set expired token in browser localstorage

// eslint-disable-next-line consistent-return
const refreshToken = async () => {
  try {
    const {
      data: { token, expire },
    } = await RefreshInstance.get('auth/refresh')
    if (token) {
      localStore.save(token, moment(expire).toDate())
      store.dispatch({
        type: 'profile/setCustomerID',
        payload: { customerID: localStore.getCustomerID() },
      })
      return token
    }
    const e = new Error()
    e.code = 2002
    e.message = 'token is expired'
    throw e
  } catch (e) {
    handleTokenError()
    throw e
  }
}

// refresh token before request
DivitAPI.interceptors.request.use(async (request) => {
  const expire = localStorage.getItem('expire')
  if (
    expire &&
    moment().isAfter(moment(new Date(expire)).subtract(60, 'seconds'))
  ) {
    try {
      await refreshToken()
      // update on-hold request
    } catch (err) {
      return Promise.reject(err)
    }
  }
  return request
})

// refresh token and retry after server response token expired
DivitAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error
    const errCode = response?.data?.code
    const errMessage = response?.data?.message?.toLowerCase()
    if (
      errCode === 1002 ||
      (errCode === 2002 && errMessage === 'token is expired')
    ) {
      if (localStore.isRoleAvailable()) {
        try {
          await refreshToken()
          // update current request, otherwise dead loop
          return DivitAPI.request(response.config)
        } catch (e) {
          return Promise.reject(e)
        }
      }
    }
    if (
      errCode === 2016 ||
      errCode === 2005 ||
      (errCode === 2002 &&
        (errMessage === 'cookie token is empty' ||
          errMessage === "you don't have permission to access this resource"))
    ) {
      handleTokenError()
    }
    throw error
  }
)

export default DivitAPI
