/* eslint-disable */
import { Decimal } from 'decimal.js'

import convertEn from './numToWordsEn'
import convertZh from './numToWordsZh'

const t = {
  en: {
    currency: '',
    dollar: 'Hong Kong Dollar',
    dollars: 'Hong Kong Dollars',
    cent: 'Cent',
    cents: 'Cents',
    cent10: '',
  },
  'zh-hk': {
    currency: '港幣',
    dollar: '元',
    dollars: '元',
    cent: '分',
    cents: '分',
    cent10: '角',
  },
  'zh-cn': {
    currency: '港币',
    dollar: '元',
    dollars: '元',
    cent: '分',
    cents: '分',
    cent10: '角',
  },
}

function getDollarInWords(number, locale) {
  return number === 1 ? t[locale]['dollar'] : t[locale]['dollars']
}

function getCentInWords(number, locale) {
  return number === 1 ? t[locale]['cent'] : t[locale]['cents']
}

function convertDollar(number, { locale }) {
  const dollar = parseInt(number, 10)
  const cent = parseInt(Decimal(number).minus(dollar).mul(100).valueOf(), 10)

  if (locale === 'en') {
    const dw = convertEn(dollar) + ' ' + getDollarInWords(dollar, locale)
    const cw =
      cent > 0
        ? ' And ' + convertEn(cent) + ' ' + getCentInWords(cent, locale)
        : ''
    return t[locale]['currency'] + dw + cw
  } else if (locale === 'zh-hk' || locale === 'zh-cn') {
    const dw = convertZh(dollar, locale) + getDollarInWords(dollar, locale)
    let cw = ''
    if (cent > 0) {
      if (cent < 10) {
        cw += convertZh(cent, locale) + getCentInWords(cent, locale)
      } else {
        const n10 = parseInt(cent / 10, 10)
        cw += convertZh(n10, locale) + t[locale]['cent10']
        const n01 = parseInt(cent % 10, 10)
        cw += convertZh(n01, locale) + getCentInWords(cent, locale)
      }
    }
    return t[locale]['currency'] + dw + cw
  }

  return ''
}

export default convertDollar
