import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import MilesConvertLabel from '@/components/miles/MilesConvertLabel'
import Spacer from '@/components/Spacer'
import MilesPrograms from '@/constants/MilesPrograms'
import { tt } from '@/locales/format'

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.333rem 1.111rem;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.08);
`

const OrderNumber = styled.div`
  font-size: 0.667rem;
`

const OrderPaidAmount = styled.div``

const OrderPayText = styled.div`
  font-size: 0.7778rem;
  font-weight: 500;
`

const OrderAmount = styled(MilesConvertLabel)`
  font-weight: 600;
`

const MilesConversionSummary = ({ payment }) => {
  const intl = useIntl()

  const programInfo = MilesPrograms.find(
    (p) => p.key === payment.metadata?.partnerCode
  )

  const type = payment.transactionType === 'miles-conversion-in' ? 'in' : 'out'

  return (
    <Container>
      <OrderNumber>
        {tt(intl, 'payment.refnumber', {
          number: `#${payment.transactionID.substring(0, 8)}`,
        })}
      </OrderNumber>
      <Spacer height="0.889rem" />
      <OrderPaidAmount>
        <OrderPayText>{tt(intl, 'miles.youconverted')}</OrderPayText>
        <Spacer height="0.444rem" />
        <OrderAmount
          type={type}
          fontSize="1.333rem"
          arrowSize="0.889rem"
          programInfo={programInfo}
          miles={payment.amount.amount}
        />
      </OrderPaidAmount>
    </Container>
  )
}

export default MilesConversionSummary
