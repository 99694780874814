import { all, call, delay, put, select, take } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

// server update available spend using event
// client need to wait for update
export function* waitForAvailableSpendUpdate() {
  const spend = yield select(
    (s) => s.profile.profile.spend?.availableSpend.amount || 0
  )
  if (spend <= 0) {
    yield delay(1000)
    let counter = 5
    while (counter > 0) {
      const { data: profiles } = yield call(async () =>
        DivitAPI.get('profiles')
      )
      yield put({
        type: 'profile/getProfileSuccess',
        payload: profiles.data,
      })
      if (profiles.data.spend?.availableSpend.amount > 0) {
        counter = 0
      } else {
        counter -= 1
        yield delay(1000)
      }
    }
  }
}

export function* putAction({ type, payload, success, fail, error }) {
  yield put({ type, payload })
  const action = yield take([success, fail])
  if (action === fail && error) throw error
  return action
}

export function* initThenAll(initPromises, otherPromises) {
  yield all(initPromises)
  yield all(otherPromises)
}

export default null
