import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ArrowMoreSvg } from '@/assets/common/arrow-more-yellow.svg'
import { Button } from '@/pages/Auth/shared/style'

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.backgroundColor || '#f0f0f0'};
`

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1;
  }

  & > *:first-child {
    margin-right: 0.889rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`

export const PrimaryButton = styled(Button)`
  min-width: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  font-weight: 700;
  background-color: ${(p) => (p.type === 'main' ? '#ffcc33' : '#1c1c1c')};
  color: ${(p) => (p.type === 'main' ? '#1c1c1c' : 'white')};
  padding: 1rem 0;

  &:disabled {
    background-color: ${(p) => (p.type === 'main' ? '#fee699' : '#969697')};
    color: ${(p) => (p.type === 'main' ? '#8e8e8e' : '#fdfcfc')};
  }
`

export const SecondaryButton = styled(Button)`
  min-width: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-weight: 700;
  font-size: 0.88rem;
  background-color: transparent;
  color: #1c1c1c;
  padding: 1rem 0;
  border: 1px solid #dedede;
`

export const Anchor = styled.a`
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:active {
    color: #1c1c1c;

    & path {
      stroke: #1c1c1c;
    }
  }
`

export const MoreAnchor = ({ children, ...props }) => (
  <Anchor {...props} style={{ display: 'flex', alignItems: 'center' }}>
    {children}
    <ArrowMoreSvg style={{ marginLeft: '0.5rem' }} />
  </Anchor>
)
