// https://www.rvd.gov.hk/doc/tc/hkpr13/06.pdf

export const ADDRESS_DISTRICTS = [
  {
    label: 'CENTRAL AND WESTERN',
    value: 'CENTRAL AND WESTERN',
  },
  {
    label: 'WAN CHAI',
    value: 'WAN CHAI',
  },
  {
    label: 'EASTERN',
    value: 'EASTERN',
  },
  {
    label: 'SOUTHERN',
    value: 'SOUTHERN',
  },
  {
    label: 'YAU TSIM MONG',
    value: 'YAU TSIM MONG',
  },
  {
    label: 'SHAM SHU PO',
    value: 'SHAM SHU PO',
  },
  {
    label: 'KOWLOON CITY',
    value: 'KOWLOON CITY',
  },
  {
    label: 'WONG TAI SIN',
    value: 'WONG TAI SIN',
  },
  {
    label: 'KWUN TONG',
    value: 'KWUN TONG',
  },
  {
    label: 'KWAI TSING',
    value: 'KWAI TSING',
  },
  {
    label: 'TSUEN WAN',
    value: 'TSUEN WAN',
  },
  {
    label: 'TUEN MUN',
    value: 'TUEN MUN',
  },
  {
    label: 'YUEN LONG',
    value: 'YUEN LONG',
  },
  {
    label: 'NORTH',
    value: 'NORTH',
  },
  {
    label: 'TAI PO',
    value: 'TAI PO',
  },
  {
    label: 'SHA TIN',
    value: 'SHA TIN',
  },
  {
    label: 'SAI KUNG',
    value: 'SAI KUNG',
  },
  {
    label: 'ISLANDS',
    value: 'ISLANDS',
  },
]

export const ADDRESS_REGIONS = [
  {
    label: 'HONG KONG ISLAND',
    value: 'HONG KONG ISLAND',
  },
  {
    label: 'KOWLOON',
    value: 'KOWLOON',
  },
  {
    label: 'NEW TERRITORIES',
    value: 'NEW TERRITORIES',
  },
]

export default 0
