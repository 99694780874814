import colors from './colors'

const base = {
  anchor: colors.divitDarkGrey,
  background: colors.backgroundGrey,
  breakpoints: {
    xs: 425,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1600,
  },
  border: colors.borderGrey,
  button: {
    active: colors.divitOrange,
    disabled: colors.divitLightGrey,
    secondary: colors.unnamed2,
  },
  dash: colors.divitLightGrey,
  dashStrong: colors.divitDarkGrey,
  footer: {
    active: colors.divitOrange,
    primary: colors.divitDarkGrey,
  },
  gutter: 10,
  text: colors.divitDarkGrey,
  placeholder: colors.divitLightGrey,
  primary: colors.divitLightPurple,
  steps: {
    active: colors.divitLightPurple,
    disabled: colors.divitLightGrey,
  },
  message: {
    success: colors.paymentGreen,
    error: '#CC0000',
  },
  backButton: {
    text: colors.divitDarkGrey,
  },
}

export default base
