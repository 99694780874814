/* eslint-disable */
const groupsNumber = 4
const dash = '-'
const _smallNumbers = [
  'Zero',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
  'Eleven',
  'Twelve',
  'Thirteen',
  'Fourteen',
  'Fifteen',
  'Sixteen',
  'Seventeen',
  'Eighteen',
  'Nineteen',
]
const _tens = [
  '',
  '',
  'Twenty',
  'Thirty',
  'Forty',
  'Fifty',
  'Sixty',
  'Seventy',
  'Eighty',
  'Ninety',
]
const _scaleNumbers = ['', 'Thousand', 'Million', 'Billion']

// number int
function convertEn(number, useAnd) {
  if (number === 0) {
    return _smallNumbers[0]
  }

  const groups = []
  let positive = Math.abs(number)
  for (let i = 0; i < groupsNumber; i++) {
    groups[i] = intMod(positive, 1000)
    positive /= 1000
  }

  const textGroup = []
  for (let i = 0; i < groupsNumber; i++) {
    textGroup[i] = digitGroup2Text(groups[i], useAnd)
  }

  let combined = textGroup[0]
  let and = useAnd && groups[0] > 0 && groups[0] < 100

  for (let i = 1; i < groupsNumber; i++) {
    if (groups[i] !== 0) {
      let prefix = textGroup[i] + ' ' + _scaleNumbers[i]
      if (combined.length !== 0) {
        prefix += separator(and)
      }
      and = false
      combined = prefix + combined
    }
  }

  if (number < 0) {
    combined = 'Minus ' + combined
  }

  return combined
}

function digitGroup2Text(group, useAnd) {
  let ret = ''

  const hundreds = parseInt(group / 100, 10)
  const tensUnits = intMod(group, 100)

  if (hundreds !== 0) {
    ret += _smallNumbers[hundreds] + ' Hundred'

    if (tensUnits !== 0) {
      ret += separator(useAnd)
    }
  }

  const tens = parseInt(tensUnits / 10)
  const units = intMod(tensUnits, 10)

  if (tens >= 2) {
    ret += _tens[tens]

    if (units !== 0) {
      ret += dash + _smallNumbers[units]
    }
  } else if (tensUnits !== 0) {
    ret += _smallNumbers[tensUnits]
  }

  return ret
}

function intMod(x, y) {
  if (x < 1) return 0
  return parseInt(x % y, 10)
}

function separator(useAnd) {
  return useAnd ? ' And ' : ' '
}

export default convertEn
