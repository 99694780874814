import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const paylaterSlice = createSlice({
  name: 'paylater',
  initialState: {
    previewOrder: AsyncState.create(),
    linkOrder: AsyncState.create(),
    patchProfile: AsyncState.create(),
    patchInstalments: AsyncState.create(),
    acceptTNC: AsyncState.create(),
    cancelOrder: AsyncState.create(),
    getPaymentAccounts: AsyncState.create(),
    burnMiles: AsyncState.create(),
    resetBurnMiles: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.previewOrder = AsyncState.reset()
      state.linkOrder = AsyncState.reset()
      state.patchProfile = AsyncState.reset()
      state.patchInstalments = AsyncState.reset()
      state.acceptTNC = AsyncState.reset()
      state.cancelOrder = AsyncState.reset()
      state.getPaymentAccounts = AsyncState.reset()
      state.burnMiles = AsyncState.reset()
      state.resetBurnMiles = AsyncState.reset()
    },
    previewOrderStart: (state) => {
      state.previewOrder = AsyncState.start()
    },
    previewOrderSuccess: (state, action) => {
      state.previewOrder = AsyncState.success(action.payload)
    },
    previewOrderFailed: (state, action) => {
      state.previewOrder = AsyncState.failed(action.payload)
    },
    linkOrderStart: (state) => {
      state.linkOrder = AsyncState.start()
    },
    linkOrderSuccess: (state, action) => {
      state.linkOrder = AsyncState.success(action.payload)
    },
    linkOrderFailed: (state, action) => {
      state.linkOrder = AsyncState.failed(action.payload)
    },
    patchProfileStart: (state) => {
      state.patchProfile = AsyncState.start()
    },
    patchProfileSuccess: (state, action) => {
      state.patchProfile = AsyncState.success(action.payload)
    },
    patchProfileFailed: (state, action) => {
      state.patchProfile = AsyncState.failed(action.payload)
    },
    patchInstalmentsStart: (state) => {
      state.patchInstalments = AsyncState.start()
    },
    patchInstalmentsSuccess: (state, action) => {
      state.patchInstalments = AsyncState.success(action.payload)
    },
    patchInstalmentsFailed: (state, action) => {
      state.patchInstalments = AsyncState.failed(action.payload)
    },
    acceptTNCStart: (state) => {
      state.acceptTNC = AsyncState.start()
    },
    acceptTNCSuccess: (state, action) => {
      state.acceptTNC = AsyncState.success(action.payload)
    },
    acceptTNCFailed: (state, action) => {
      state.acceptTNC = AsyncState.failed(action.payload)
    },
    cancelOrderStart: (state) => {
      state.cancelOrder = AsyncState.start()
    },
    cancelOrderSuccess: (state, action) => {
      state.cancelOrder = AsyncState.success(action.payload)
    },
    cancelOrderFailed: (state, action) => {
      state.cancelOrder = AsyncState.failed(action.payload)
    },
    getPaymentAccountsStart: (state) => {
      state.getPaymentAccounts = AsyncState.start()
    },
    getPaymentAccountsSuccess: (state, action) => {
      state.getPaymentAccounts = AsyncState.success(action.payload)
    },
    getPaymentAccountsFailed: (state, action) => {
      state.getPaymentAccounts = AsyncState.failed(action.payload)
    },
    burnMilesStart: (state) => {
      state.burnMiles = AsyncState.start()
    },
    burnMilesSuccess: (state, action) => {
      state.burnMiles = AsyncState.success(action.payload)
    },
    burnMilesFailed: (state, action) => {
      state.burnMiles = AsyncState.failed(action.payload)
    },
    resetBurnMilesStart: (state) => {
      state.resetBurnMiles = AsyncState.start()
    },
    resetBurnMilesSuccess: (state, action) => {
      state.resetBurnMiles = AsyncState.success(action.payload)
    },
    resetBurnMilesFailed: (state, action) => {
      state.resetBurnMiles = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = paylaterSlice

export default paylaterSlice.reducer
