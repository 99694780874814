import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import { ActionButtons, PrimaryButton } from '@/components/ui/Shared'
import { tt } from '@/locales/format'

const Title = styled.div`
  font-size: 1.111rem;
  font-weight: 700;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const PaynowSuccessPlainScreen = () => {
  const intl = useIntl()
  const history = useHistory()

  const onClickBackMerchant = () => {
    history.push('/')
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Icon
            renderImage={() => <SuccessOutlinedSvg />}
            width="1.736rem"
            height="1.736rem"
          />
          <Spacer height="0.889rem" />
          <Title>{tt(intl, 'payment.hasbeenpaid')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>
            {tt(intl, 'payment.receiptsent')}
            <Spacer height="0.889rem" />
            {tt(intl, 'payment.contactcs')}
          </SubTitle>
          <Spacer height="1.778rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <PrimaryButton onClick={onClickBackMerchant}>
              {tt(intl, 'common.homepage')}
            </PrimaryButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default PaynowSuccessPlainScreen
