// helpers

export const saveString = (key, str) => {
  localStorage.setItem(key, str)
}

export const getString = (key) => {
  localStorage.getItem(key)
}

export const setSplitString = (key, arr) => {
  localStorage.setItem(key, arr.join('|'))
}

export const getSplitString = (key) => {
  const strArray = localStorage.getItem(key)
  return strArray ? strArray.split('|') : []
}

export const saveJSON = (key, json) => {
  const jsonStr = JSON.stringify(json)
  localStorage.setItem(key, jsonStr)
}

export const getJSON = (key, defaultValue) => {
  const item = localStorage.getItem(key)
  return JSON.parse(item) || defaultValue
}

// functions

const MILES_LINKAGE_KEY = 'miles_linkage_9r32'

export const milesLinkageStorage = {
  set: (milesLinkage) => {
    localStorage.setItem(MILES_LINKAGE_KEY, milesLinkage)
  },
  get: () => {
    const item = localStorage.getItem(MILES_LINKAGE_KEY)
    return item ? item.split(',') : []
  },
}

const TRIP_GOAL_KEY = 'trip_goal_3293'

export const tripGoalStorage = {
  set: (tripGoal) => {
    localStorage.setItem(TRIP_GOAL_KEY, JSON.stringify(tripGoal))
  },
  get: () => JSON.parse(localStorage.getItem(TRIP_GOAL_KEY) || 'null'),
}

export default 0
