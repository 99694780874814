import { createSelector } from 'reselect'

// selectors

export const lookupSelector = (s) => s.lookup

export const paymentInstrumentsSelector = createSelector(
  lookupSelector,
  (lookup) => lookup.paymentInstruments
)

export const getHKDDVMSelector = createSelector(
  lookupSelector,
  (lookup) => lookup.rateCurrencyToDVM.filter((r) => r.currency === 'HKD')?.[0]
)

export const getCurrencyToDVMSelector = createSelector(
  lookupSelector,
  (lookup) => lookup.rateCurrencyToDVM
)

export const getMerchantRateCurrencyToDVMSelector = createSelector(
  lookupSelector,
  (lookup) => lookup.merchantRateCurrencyToDVM
)

export const merchantsSelector = createSelector(
  lookupSelector,
  (lookup) => lookup.merchants
)

const getService = (services, serviceName) =>
  services.find((s) => s.serviceName === serviceName)

export const getFpsServiceSelector = createSelector(lookupSelector, (lookup) =>
  getService(lookup.services, 'fps')
)

export const getFpsCitiServiceSelector = createSelector(
  lookupSelector,
  (lookup) => getService(lookup.services, 'fps_citi')
)

export const getFpsQRServiceSelector = createSelector(
  lookupSelector,
  (lookup) => getService(lookup.services, 'fps_qrcode')
)

export const getConvertMilesServiceSelector = createSelector(
  lookupSelector,
  (lookup) => getService(lookup.services, 'convert_miles')
)
