import { rgba } from 'polished'
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Box from '@/components/Box'
import { Button, H2, Input, Label } from '@/components/Divit'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'
import PasswordValidationToolTip from '@/components/PasswordValidationToolTip'
import SuccessMessageBox from '@/components/SuccessMessageBox'
import usePageError from '@/hooks/usePageError'
import usePasswordValiation from '@/hooks/usePasswordValidation'
import useToolTip from '@/hooks/useToolTip'

const Container = styled(PaddingContent)``

const LoginH2 = styled(H2)`
  margin: 1.25rem 0;
`

const Form = styled.form`
  margin-bottom: 1rem;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`

const FormColumn = styled.div`
  flex: 1;
  ${({ position }) => position && `text-align: ${position}`}
`

const FormContent = styled(Box)`
  background: ${({ theme }) => rgba(theme.text, 0.05)};
  width: calc(100% / 12 * 10);
  padding: 1.375rem calc(100% / 12) 1rem;
  border: 0;
`

const PasswordStrengthMeterContainer = styled.div`
  width: 100%;
  margin: 0.889rem 0 1.333rem 0;
`

const ResetPasswordButton = styled(Button)`
  font-size: 0.75rem;
  margin-top: 0.5rem;
`

const ChangePassword = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const { passwordValidation } = usePasswordValiation(password)
  const { toolTipRef, showToolTip, hideToolTip } = useToolTip()

  const { errorMessage, updateErrorMessage } = usePageError()

  const { changePassword } = useSelector((state) => state.updateProfile)

  const resetForm = () => {
    setOldPassword('')
    setPassword('')
    setConfirmPassword('')
  }

  useEffect(
    () => () =>
      dispatch({
        type: 'updateProfile/reset',
      }),
    []
  )

  useEffect(() => {
    if (changePassword.isSuccess) {
      setSuccessMessage(
        intl.formatMessage({
          id: 'profile.changepassword.success',
        })
      )
      resetForm()
    }
    if (changePassword.isError) {
      updateErrorMessage(
        intl.formatMessage({ id: 'error.action.change.password' })
      )
    }
  }, [changePassword])

  const validateForm = () => {
    if (!oldPassword.trim()) {
      intl.formatMessage({ id: 'error.required' })
      return false
    }
    if (!passwordValidation.valid) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.password' }))
      return false
    }
    if (password !== confirmPassword) {
      updateErrorMessage(
        intl.formatMessage({ id: 'error.invalid.confirm.password' })
      )
      return false
    }
    return true
  }

  const onClickResetPassword = useCallback(
    (e) => {
      e.preventDefault()

      setSuccessMessage('')
      updateErrorMessage('')

      if (!validateForm()) {
        return
      }

      dispatch({
        type: 'updateProfile/changePassword',
        payload: {
          oldPwd: oldPassword,
          newPwd: password,
        },
      })
    },
    [oldPassword, password, confirmPassword]
  )

  const onBlurPassword = () => {
    if (!password || passwordValidation.valid) {
      hideToolTip()
    } else {
      showToolTip()
    }
  }

  return (
    <Container>
      <LoginH2>
        {intl.formatMessage({ id: 'profile.changepassword.title' })}
      </LoginH2>
      <ErrorMessageBox errorMessage={errorMessage} />
      <SuccessMessageBox successMessage={successMessage} />
      <Form>
        <FormContent>
          <FormRow>
            <FormColumn>
              <Label htmlFor="oldpassword">
                {intl.formatMessage({
                  id: 'profile.changepassword.oldpassword',
                })}
              </Label>
              <Input
                type="password"
                id="oldpassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                onBlur={onBlurPassword}
                data-selenium="profile.changepassword.oldpassword"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <Label htmlFor="password">
                {intl.formatMessage({ id: 'profile.changepassword.password' })}
              </Label>
              {!passwordValidation.ignored && (
                <PasswordValidationToolTip
                  toolTipRef={toolTipRef}
                  hasSufficientLength={passwordValidation.validLength}
                  hasNumber={passwordValidation.validNumber}
                  hasLowercase={passwordValidation.validLowercase}
                  hasUppercase={passwordValidation.validUppercase}
                  hasSymbol={passwordValidation.validSymbol}
                />
              )}
              <Input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={onBlurPassword}
                data-selenium="profile.changepassword.password"
              />
            </FormColumn>
          </FormRow>
          {!!password && (
            <PasswordStrengthMeterContainer>
              <PasswordStrengthMeter score={passwordValidation.strengthScore} />
            </PasswordStrengthMeterContainer>
          )}
          <FormRow>
            <FormColumn>
              <Label htmlFor="confirm-password">
                {intl.formatMessage({
                  id: 'profile.changepassword.confirm.password',
                })}
              </Label>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                data-selenium="profile.changepassword.confirm.password"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn />
            <FormColumn position="right">
              <ResetPasswordButton
                type="submit"
                disabled={
                  !(
                    oldPassword &&
                    password &&
                    confirmPassword &&
                    passwordValidation.valid
                  )
                }
                onClick={onClickResetPassword}
                data-selenium="profile.changepassword.submit"
              >
                {intl.formatMessage({ id: 'profile.changepassword.action' })}
              </ResetPasswordButton>
            </FormColumn>
          </FormRow>
        </FormContent>
      </Form>
      <LoadingModal loading={changePassword.isLoading} />
    </Container>
  )
}

export default ChangePassword
