import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { actions as GiftCardActions } from './giftCardSlice'

export function* fetchGiftCardProducts() {
  const { data } = yield call(() => DivitAPI.get('/giftcard/products'))
  yield put(GiftCardActions.getProductsSuccess(data.data))
  return data.data
}

export default 0
