import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import CheckoutSteps from '@/components/CheckoutSteps'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import MerchantName from '@/components/merchants/MerchantName'
import Spacer from '@/components/Spacer'
import BankLogo from '@/components/ui/BankLogo'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { eDDAAvailableAccountSelector } from '@/redux/profile/profileSelector'
import * as BankUtils from '@/utils/Bank'
import * as Instalments from '@/utils/Instalments'
import { FormattedPrice } from '@/utils/Price'
import { IsPaylatering } from '@/utils/Route'

const Container = styled(CSLayout.CSContainer)`
  background-color: #fafafa;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const ItemTitle = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`

const ItemValue = styled.div`
  font-size: 0.889rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BankAccount = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const BankAccountDetails = styled.div`
  flex: 1;
`

const BankAccountName = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const BankAccountNo = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const OrderAmount = styled.div`
  font-size: 0.889rem;
`

const PaylaterPreviewPayment = () => {
  const intl = useIntl()
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()
  const { orderId, instalmentId } = useParams()
  const isPaylatering = IsPaylatering(pathname)

  const { order } = useSelector((s) => s.order)
  const eDDAAccount = useSelector(eDDAAvailableAccountSelector)
  const { getPaymentAccounts } = useSelector((s) => s.paylater)

  const [inited, setInited] = useState(false)

  const { retry, isInitSuccess, isInitError, isInitLoading } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  useEffect(() => {
    if (isInitSuccess) {
      dispatch({ type: 'paylater/getPaymentAccounts' })
    }
  }, [isInitSuccess])

  useEffect(() => {
    if (getPaymentAccounts.isSuccess) {
      if (!eDDAAccount) {
        history.replace(`/pay/fps/paylater/${orderId}/${instalmentId}`)
      } else {
        setInited(true)
      }
    }
  }, [getPaymentAccounts])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!inited) return <LoadingScreen />
  if (!order) return <LoadingScreen />
  if (order.orderid !== orderId) return <></>

  const onClickEDDA = () => {
    history.push(`/pay/fps/paylater/${orderId}/${instalmentId}?edda=true`)
  }

  const onClickFps = () => {
    history.push(`/pay/fps/paylater/${orderId}/${instalmentId}`)
  }

  const { instalments } = order
  const currentInstalment = instalments.find(
    (instalment) => instalment.instalmentID === instalmentId
  )
  const instalmentTotalAmount =
    Instalments.getTotalOutstandingAmount(currentInstalment)

  if (!eDDAAccount) return null

  return (
    <Container>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="1.333rem" />

          <Spacer height="2.222rem" />
          <Title onClickBack={onClickFps}>
            {tt(intl, 'paynow.reviewpayment')}
          </Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'paynow.eddareview')}</SubTitle>
          <Spacer height="1.778rem" />
          <ItemTitle>{tt(intl, 'payment.payto')}</ItemTitle>
          <Spacer height="0.889rem" />
          <ItemValue>
            <MerchantName merchantID={order.partnerid} />
          </ItemValue>
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          <ItemTitle>{tt(intl, 'payment.paywith')}</ItemTitle>
          <Spacer height="0.889rem" />
          <ItemValue>
            <Row>
              <BankAccount>
                <BankLogo
                  width="3.111rem"
                  height="3.111rem"
                  bankCode={eDDAAccount.bankCode}
                  isCircle
                />
                <Spacer width="0.889rem" />
                <BankAccountDetails>
                  <BankAccountName>
                    {BankUtils.getBankString(
                      eDDAAccount.bankCode,
                      currentLocale
                    )}
                  </BankAccountName>
                  <Spacer height="0.444rem" />
                  <BankAccountNo>{eDDAAccount.accountNum}</BankAccountNo>
                </BankAccountDetails>
              </BankAccount>
              <Spacer width="0.889rem" />
              <OrderAmount>
                {FormattedPrice(
                  instalmentTotalAmount.currency,
                  instalmentTotalAmount.amount
                )}
              </OrderAmount>
            </Row>
          </ItemValue>
          <Spacer height="1.111rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <PrimaryButton type="main" onClick={onClickEDDA}>
              {tt(intl, 'common.confirm')}
            </PrimaryButton>
          </ActionButtons>
          <Spacer height="0.889rem" />
          <ActionButtons>
            <SecondaryButton onClick={onClickFps}>
              {tt(intl, 'button.pay.withmethod', {
                method: intl.formatMessage({
                  id: 'payment.method.fps',
                }),
              })}
            </SecondaryButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </Container>
  )
}

export default PaylaterPreviewPayment
