import React from 'react'
import styled from 'styled-components'

const Text = styled.div`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 0.889rem;
  color: #c2c2c2;
`

const EmptyPlaceholder = () => <Text>no item yet</Text>

export default EmptyPlaceholder
