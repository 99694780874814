import { takeEvery } from 'redux-saga/effects'

import * as appSaga from './app/appSaga'
import * as authSaga from './auth/authSaga'
import * as gtmSaga from './gtm/gtmSaga'
import * as csModalSaga from './modal/csModalSaga'
import * as orderSaga from './order/orderSaga'
import * as checkoutGiftCardSaga from './pages/checkoutGiftCardSaga'
import * as claimDivitMilesSaga from './pages/claimDivitMilesSaga'
import * as convertDivitMilesSaga from './pages/convertDivitMilesSaga'
import * as fastSignupSaga from './pages/fastSignupSaga'
import * as pageInitSaga from './pages/pageInitSaga'
import * as paylaterSaga from './pages/paylaterSaga'
import * as paynowFastFpsSaga from './pages/paynowFastFpsSaga'
import * as paynowSaga from './pages/paynowSaga'
import * as paynowVerifySaga from './pages/paynowVerifySaga'
import * as payWithFpsSaga from './pages/payWithFpsSaga'
import * as payWithPaymeSaga from './pages/payWithPaymeSaga'
import * as resetPasswordSaga from './pages/resetPasswordSaga'
import * as setupEDDASaga from './pages/setupEDDASaga'
import * as SSOSaga from './pages/SSOSaga'
import * as updateProfileSaga from './pages/updateProfileSaga'
import * as verifyEmailSaga from './pages/verifyEmailSaga'
import * as verifyPhoneSaga from './pages/verifyPhoneSaga'
import * as pinSaga from './pin/pinSaga'
import * as profileSaga from './profile/profileSaga'
import * as profileAdditionalSaga from './profileAdditional/profileAdditionalSaga'
import * as redirectSaga from './routing/redirectSaga'
import * as lookupSaga from './lookup/lookupSaga'

export default function* rootSaga() {
  // app
  yield takeEvery('@@router/LOCATION_CHANGE', appSaga.updateRoute)

  // auth
  yield takeEvery('login', authSaga.login)
  yield takeEvery('loginWithSocialProvider', authSaga.loginWithSocialProvider)
  yield takeEvery('redirectReturnUrlOrHome', authSaga.redirectReturnUrlOrHome)
  yield takeEvery('setToken', authSaga.setTokenCookie)
  yield takeEvery('logout', authSaga.logout)
  yield takeEvery('clearSignUp', authSaga.clearSignUp)
  yield takeEvery('tokenExpiredRedirect', authSaga.tokenExpiredRedirect)
  yield takeEvery('verifyWithSocialProvider', authSaga.verifyWithSocialProvider)
  yield takeEvery('kyc/skipKyc', authSaga.postSkipKyc)

  // orders
  yield takeEvery('fetchOrder', orderSaga.fetchOrder)
  yield takeEvery('fetchAllOrders', orderSaga.fetchAllOrders)
  yield takeEvery('fetchAllPayments', orderSaga.fetchAllPayments)
  yield takeEvery('fetchBankListFps', orderSaga.fetchBankListFps)

  // profile
  yield takeEvery('fetchProfile', profileSaga.fetchProfile)
  yield takeEvery('patchProfile', profileSaga.patchProfile)
  yield takeEvery('fetchPurchaseStatus', profileSaga.fetchPurchaseStatus)
  yield takeEvery('fetchEDDAAccounts', profileSaga.fetchEDDAAccounts)

  // identity
  yield takeEvery('fetchIdentity', profileSaga.fetchIdentity)

  // fast signup
  yield takeEvery('fastSignup/login', fastSignupSaga.login)
  yield takeEvery('fastSignup/loginPassword', fastSignupSaga.loginPassword)
  yield takeEvery('fastSignup/signup', fastSignupSaga.signup)
  yield takeEvery(
    'fastSignup/signupVerifyEmail',
    fastSignupSaga.signupVerifyEmail
  )
  yield takeEvery('fastSignup/sendOTP', fastSignupSaga.sendOTP)
  yield takeEvery('fastSignup/verifyOTP', fastSignupSaga.verifyOTP)
  yield takeEvery('fastSignup/setupPassword', fastSignupSaga.setupPassword)
  yield takeEvery('fastSignup/forgotPassword', fastSignupSaga.forgotPassword)
  yield takeEvery(
    'fastSignup/checkReferralCode',
    fastSignupSaga.checkReferralCode
  )

  // reset password
  yield takeEvery(
    'resetPassword/forgotPassword',
    resetPasswordSaga.forgotPassword
  )
  yield takeEvery('resetPassword/verifyToken', resetPasswordSaga.verifyToken)
  yield takeEvery(
    'resetPassword/resetPassword',
    resetPasswordSaga.resetPassword
  )

  // verify email
  yield takeEvery('verifyEmail/verifyEmail', verifyEmailSaga.verifyEmail)
  yield takeEvery('verifyEmail/sendEmailOTP', verifyEmailSaga.sendEmailOTP)
  yield takeEvery('verifyEmail/resendEmailOTP', verifyEmailSaga.resendEmailOTP)
  yield takeEvery('verifyEmail/verifyEmailOTP', verifyEmailSaga.verifyEmailOTP)

  // verify phone number
  yield takeEvery('verifyPhone/sendOTP', verifyPhoneSaga.sendOTP)
  yield takeEvery('verifyPhone/verifyOTP', verifyPhoneSaga.verifyOTP)

  // pin
  yield takeEvery('pin/authPin', pinSaga.authPin)
  yield takeEvery('pin/setupPin', pinSaga.setupPin)
  yield takeEvery('pin/validatePin', pinSaga.validatePin)
  yield takeEvery('pin/forgotPin', pinSaga.forgotPin)
  yield takeEvery('pin/validatePinOTP', pinSaga.validatePinOTP)

  // checkout
  yield takeEvery('paylater/previewOrder', paylaterSaga.previewOrder)
  yield takeEvery('paylater/linkOrder', paylaterSaga.linkOrder)
  yield takeEvery('paylater/patchProfile', paylaterSaga.patchProfile)
  yield takeEvery('paylater/patchInstalments', paylaterSaga.patchInstalments)
  yield takeEvery('paylater/acceptTNC', paylaterSaga.acceptTNC)
  yield takeEvery('paylater/cancelOrder', paylaterSaga.cancelOrder)
  yield takeEvery('paylater/burnMiles', paylaterSaga.burnMiles)
  yield takeEvery('paylater/resetBurnMiles', paylaterSaga.resetBurnMiles)
  yield takeEvery(
    'paylater/getPaymentAccounts',
    paylaterSaga.getPaymentAccounts
  )

  // claim divit miles
  yield takeEvery(
    'claimDivitMiles/previewClaim',
    claimDivitMilesSaga.previewClaim
  )
  yield takeEvery('claimDivitMiles/claim', claimDivitMilesSaga.claim)

  // convert divit miles
  yield takeEvery(
    'convertDivitMiles/addMembership',
    convertDivitMilesSaga.addMembership
  )
  yield takeEvery(
    'convertDivitMiles/convertMiles',
    convertDivitMilesSaga.convertMiles
  )

  // payment with payme
  yield takeEvery(
    'payWithPayme/generatePaymeLink',
    payWithPaymeSaga.generatePaymeLink
  )

  // payment with fps
  yield takeEvery('payWithFps/fetchOrder', payWithFpsSaga.fetchOrder)
  yield takeEvery('payWithFps/generateFpsLink', payWithFpsSaga.generateFpsLink)
  yield takeEvery('payWithFps/payByEDDA', payWithFpsSaga.payByEDDA)
  yield takeEvery('payWithFps/fpsRefreshOrder', payWithFpsSaga.fpsRefreshOrder)
  yield takeEvery(
    'fetchProfileAdditional',
    profileAdditionalSaga.fetchProfileAdditional
  )
  yield takeEvery(
    'patchProfileAdditional',
    profileAdditionalSaga.patchProfileAdditional
  )

  // pay now
  yield takeEvery('paynow/fetchOrder', paynowSaga.fetchOrder)
  yield takeEvery('paynow/linkOrder', paynowSaga.linkOrder)
  yield takeEvery(
    'paynow/updateDeliveryAddress',
    paynowSaga.updateDeliveryAddress
  )
  yield takeEvery('paynow/burnMiles', paynowSaga.burnMiles)
  yield takeEvery('paynow/autoBurnMiles', paynowSaga.autoBurnMiles)
  yield takeEvery('paynow/resetBurnMiles', paynowSaga.resetBurnMiles)
  yield takeEvery('paynow/fpsRefreshOrder', paynowSaga.fpsRefreshOrder)
  yield takeEvery('paynow/generateFpsLink', paynowSaga.generateFpsLink)
  yield takeEvery('paynow/payByEDDA', paynowSaga.payByEDDA)
  yield takeEvery('paynow/fetchCompletedOrder', paynowSaga.fetchCompletedOrder)
  yield takeEvery('paynow/cancelOrder', paynowSaga.cancelOrder)
  yield takeEvery('paynow/cancelAllOrders', paynowSaga.cancelAllOrders)

  // paynow fast with fps
  yield takeEvery('paynowFastFps/resumeOrder', paynowFastFpsSaga.resumeOrder)
  yield takeEvery('paynowFastFps/fetchOrder', paynowFastFpsSaga.fetchOrder)
  yield takeEvery(
    'paynowFastFps/generateFpsLink',
    paynowFastFpsSaga.generateFpsLink
  )
  yield takeEvery(
    'paynowFastFps/fpsRefreshOrder',
    paynowFastFpsSaga.fpsRefreshOrder
  )

  // pay now verify payment
  yield takeEvery(
    'paynowVerify/createVerifyPayment',
    paynowVerifySaga.createVerifyPayment
  )
  yield takeEvery('paynowVerify/fetchOrder', paynowVerifySaga.fetchOrder)
  yield takeEvery(
    'paynowVerify/fpsRefreshOrder',
    paynowVerifySaga.fpsRefreshOrder
  )
  yield takeEvery(
    'paynowVerify/generateFpsLink',
    paynowVerifySaga.generateFpsLink
  )

  // page initialize
  yield takeEvery('pageInit/initApp', pageInitSaga.initApp)
  yield takeEvery('pageInit/initAppAgain', pageInitSaga.initAppAgain)
  yield takeEvery('pageInit/initAllOrders', pageInitSaga.initAllOrders)
  yield takeEvery('pageInit/initPaynowOrders', pageInitSaga.initPaynowOrders)
  yield takeEvery('pageInit/initProfileMain', pageInitSaga.initProfileMain)
  yield takeEvery('pageInit/initConfig', pageInitSaga.initConfig)
  yield takeEvery('app/miscConfig', appSaga.fetchMiscConfigs)
  yield takeEvery('app/countryList', appSaga.fetchCountryList)
  yield takeEvery(
    'pageInit/initProfileGiftCardBrands',
    pageInitSaga.initProfileGiftCardBrands
  )
  yield takeEvery(
    'pageInit/initProfileGiftCards',
    pageInitSaga.initProfileGiftCards
  )
  yield takeEvery('pageInit/initProfile', pageInitSaga.initProfile)
  yield takeEvery('pageInit/initProfileEDDA', pageInitSaga.initProfileEDDA)
  yield takeEvery(
    'pageInit/initDivitMilesProfile',
    pageInitSaga.initDivitMilesProfile
  )
  yield takeEvery(
    'pageInit/initDivitMilesHistory',
    pageInitSaga.initDivitMilesHistory
  )
  yield takeEvery(
    'pageInit/initDivitMilesSpend',
    pageInitSaga.initDivitMilesSpend
  )
  yield takeEvery('pageInit/initOrder', pageInitSaga.initOrder)
  yield takeEvery(
    'pageInit/initPaynowFastOrder',
    pageInitSaga.initPaynowFastOrder
  )
  yield takeEvery('pageInit/initPaynowOrder', pageInitSaga.initPaynowOrder)
  yield takeEvery('pageInit/initCheckoutOrder', pageInitSaga.initCheckoutOrder)
  yield takeEvery(
    'pageInit/initCheckoutProfile',
    pageInitSaga.initCheckoutProfile
  )
  yield takeEvery(
    'pageInit/initPaymentFinished',
    pageInitSaga.initPaymentFinished
  )
  yield takeEvery(
    'pageInit/initPaymentDetails',
    pageInitSaga.initPaymentDetails
  )
  yield takeEvery('pageInit/initOffers', pageInitSaga.initOffers)
  yield takeEvery('pageInit/initShop', pageInitSaga.initShop)
  yield takeEvery('pageInit/services', lookupSaga.fetchServices)

  // update profile actions
  yield takeEvery(
    'updateProfile/updateProfile',
    updateProfileSaga.updateProfile
  )
  yield takeEvery(
    'updateProfile/updateProfileAdditional',
    updateProfileSaga.updateProfileAdditional
  )
  yield takeEvery(
    'updateProfile/updateIdentity',
    updateProfileSaga.updateIdentity
  )
  yield takeEvery(
    'updateProfile/updateContactMethod',
    updateProfileSaga.updateContactMethod
  )
  yield takeEvery(
    'updateProfile/linkSocialProvider',
    updateProfileSaga.linkSocialProvider
  )
  yield takeEvery(
    'updateProfile/unlinkSocialProvider',
    updateProfileSaga.unlinkSocialProvider
  )
  yield takeEvery(
    'updateProfile/changePassword',
    updateProfileSaga.changePassword
  )
  yield takeEvery(
    'updateProfile/unlinkMilesProgram',
    updateProfileSaga.unlinkMilesProgram
  )
  yield takeEvery(
    'updateProfile/giftCardMarkAsUsed',
    updateProfileSaga.giftCardMarkAsUsed
  )
  yield takeEvery(
    'updateProfile/updateProfileSettings',
    updateProfileSaga.updateProfileSettings
  )
  yield takeEvery(
    'updateProfile/updateProfileAddress',
    updateProfileSaga.updateProfileAddress
  )
  yield takeEvery(
    'updateProfile/removeProfileAddress',
    updateProfileSaga.removeProfileAddress
  )
  yield takeEvery(
    'updateProfile/updateEDDAAccount',
    updateProfileSaga.updateEDDAAccount
  )
  yield takeEvery(
    'updateProfile/removeEDDAAccount',
    updateProfileSaga.removeEDDAAccount
  )

  // setup edda
  yield takeEvery('setupEDDA/initEDDA', setupEDDASaga.initEDDA)

  // gift card
  yield takeEvery(
    'checkoutGiftCard/getProducts',
    checkoutGiftCardSaga.getProducts
  )
  yield takeEvery(
    'checkoutGiftCard/createOrder',
    checkoutGiftCardSaga.createOrder
  )
  yield takeEvery('checkoutGiftCard/getOrder', checkoutGiftCardSaga.getOrder)

  // Single sign on
  yield takeEvery('sso/generateOAuthToken', SSOSaga.generateOAuthToken)

  yield takeEvery(
    'redirect/scheduleReview',
    redirectSaga.redirectScheduleReview
  )

  // customer service
  yield takeEvery('csmodal/sendCsForm', csModalSaga.sendCsForm)

  // gtm
  yield takeEvery('gtm/sendEvent', gtmSaga.sendEvent)

  // test actions
  yield takeEvery(
    'testPayment/testGeneratePaymeLink',
    payWithPaymeSaga.testGeneratePaymeLink
  )
  yield takeEvery(
    'testPayment/testGenerateFpsLink',
    payWithFpsSaga.testGenerateFpsLink
  )
}
