import parse, { domToReact } from 'html-react-parser'
import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import Checkbox from '@/components/Checkbox'
import SHOW_TERMS from '@/constants/terms'
import { useLocale } from '@/contexts/localeContext'
import { tt } from '@/locales/format'
import { joinAddress } from '@/utils/Address'
import * as localstorage from '@/utils/localstorage'
import * as math from '@/utils/math'
import * as Order from '@/utils/Order'
import {
  FormattedPrice,
  Round,
  TermsWordings,
  TermsWordingsPercent,
} from '@/utils/Price'
import { joinName } from '@/utils/user'
import { useDispatch } from 'react-redux'

import ArrowDownIcon from '../icons/ArrowDown'
import ArrowUpIcon from '../icons/ArrowUp'
import htmlEn from './en'
import {
  AgreeButton,
  AgreeButtonContainer,
  AgreementCheckbox,
  AgreementFlex,
  AgreementMessage,
  AgreementRow,
  C27,
  ModalBodyContainer,
  ModalContainer,
  ModalContentContainer,
  ShowMore,
  ShowMoreTitle,
  TermContainer,
  Title,
} from './Terms_v2.styled'
import htmlZhCN from './zhCN'
import htmlZhHK from './zhHK'

const htmls = {
  en: htmlEn,
  'zh-cn': htmlZhCN,
  'zh-hk': htmlZhHK,
}

const replaceAll = (str, f, t) => str.split(f).join(t)

const agreements = [
  {
    key: 'agreements0',
    msg: 'terms.agreement0',
  },
  {
    key: 'agreements1',
    msg: 'terms.agreement1',
  },
  {
    key: 'agreements2',
    msg: 'terms.agreement2',
  },
]

const TermsV2 = ({ order, profile, modalIsOpen, setIsOpen, orderId }) => {
  const intl = useIntl()
  const { currentLocale } = useLocale()
  const dispatch = useDispatch()

  const { control, handleSubmit } = useForm()
  const watchAgreed = useWatch({ name: 'agreed', control })

  const [showMore, setShowMore] = useState(false)
  const [render, setRender] = useState('')

  const [agreedTerms, setAgreedTerms] = useState(false)

  const onCheckTerms = useCallback(
    (checked) => setAgreedTerms(checked),
    [setAgreedTerms]
  )

  useEffect(() => {
    if (watchAgreed) {
      const agreeds = watchAgreed.filter((a) => a)
      if (agreeds.length === agreements.length) {
        onCheckTerms(true)
      } else {
        onCheckTerms(false)
      }
    }
  }, [watchAgreed])

  useEffect(() => {
    const { firstName, lastName } = profile

    const billingAddress = profile?.address?.filter(
      (add) => add.addressType === 'billing'
    )[0]

    const loanAmount = Order.getLoanAmount(order)
    const transferService = Order.getTransferServiceFee(order)

    const htmlData = {
      agreementDate: moment.unix(order.instalments[0].dueDate).format('LLL'),
      borrowerName: joinName([firstName, lastName]),
      borrowerAddress: billingAddress
        ? joinAddress([
            billingAddress.address1,
            billingAddress.address2,
            billingAddress.district,
            billingAddress.country,
          ])
        : '',
      loanAmount: TermsWordings(
        loanAmount.currency,
        loanAmount.amount,
        currentLocale
      ),
      transferService: TermsWordings(
        transferService.currency,
        transferService.amount,
        currentLocale
      ),
      loanServiceFee: TermsWordings(
        order.serviceFee.currency,
        math.minus(order.serviceFee.amount, transferService.amount),
        currentLocale
      ),
      interestRate: `${TermsWordingsPercent(
        Round(order.apr, 2),
        currentLocale
      )}`,
      instalmentOneAmount: FormattedPrice(
        order.instalments[0].amount.currency,
        order.instalments[0].amount.amount
      ),
      instalmentTwoAmount: FormattedPrice(
        order.instalments[1].amount.currency,
        order.instalments[1].amount.amount
      ),
      instalmentThreeAmount: FormattedPrice(
        order.instalments[2].amount.currency,
        order.instalments[2].amount.amount
      ),
      instalmentTwoReminderDate: moment
        .unix(order.instalments[1].reminderDate)
        .format('LLL'),
      instalmentThreeReminderDate: moment
        .unix(order.instalments[2].reminderDate)
        .format('LLL'),
      instalmentTwoDueDate: moment
        .unix(order.instalments[1].dueDate)
        .format('LLL'),
      instalmentThreeDueDate: moment
        .unix(order.instalments[2].dueDate)
        .format('LLL'),
    }

    const html = htmls[currentLocale]

    let newHTML = replaceAll(
      html,
      '{{(index (index .Instalments 0).PaidRecords 0).PaidDate}}',
      htmlData.agreementDate
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index (index .Instalments 0).PaidRecords 0).PaidDate}}',
      htmlData.agreementDate
    )
    newHTML = replaceAll(
      newHTML,
      '{{.Recipient.FirstName}} {{.Recipient.LastName}}',
      htmlData.borrowerName
    )
    newHTML = replaceAll(
      newHTML,
      '{{.Recipient.Address}}',
      htmlData.borrowerAddress
    )
    newHTML = replaceAll(
      newHTML,
      '{{.Order.LoanAmountInWords}} ({{.Order.LoanAmount}})',
      htmlData.loanAmount
    )
    newHTML = replaceAll(
      newHTML,
      '{{.Order.TransferServiceFeeInWords}} ({{.Order.TransferServiceFee}})',
      htmlData.transferService
    )
    newHTML = replaceAll(
      newHTML,
      '{{.Order.LoanServiceFeeInWords}} ({{.Order.LoanServiceFee}})',
      htmlData.loanServiceFee
    )
    newHTML = replaceAll(
      newHTML,
      '{{.Order.APRateInWords}} ({{.Order.APRate}})',
      htmlData.interestRate
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index .Instalments 0).Amount}}',
      htmlData.instalmentOneAmount
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index .Instalments 1).Amount}}',
      htmlData.instalmentTwoAmount
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index .Instalments 1).ReminderDate}}',
      htmlData.instalmentTwoReminderDate
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index .Instalments 1).DueDate}}',
      htmlData.instalmentTwoDueDate
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index .Instalments 2).Amount}}',
      htmlData.instalmentThreeAmount
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index .Instalments 2).ReminderDate}}',
      htmlData.instalmentThreeReminderDate
    )
    newHTML = replaceAll(
      newHTML,
      '{{(index .Instalments 2).DueDate}}',
      htmlData.instalmentThreeDueDate
    )
    newHTML = replaceAll(
      newHTML,
      '{{.LicenceNumber}}',
      intl.formatMessage({ id: 'common.licence' })
    )

    // document.body.innerHTML = newHTML
    setRender(newHTML)
  }, [order, profile, currentLocale])

  const toggleShowMore = useCallback(() => {
    setShowMore((state) => !state)
  }, [setShowMore])

  if (!order || !profile) return <></>

  const options = {
    replace({ attribs, children }) {
      if (!attribs) {
        return
      }

      if (attribs.class === 'c27') {
        // eslint-disable-next-line consistent-return
        return <C27 className="c27">{domToReact(children, options)}</C27>
      }

      if (attribs.class === 'c28') {
        // eslint-disable-next-line consistent-return
        return (
          <body className="c28" style={{ padding: 0 }}>
            {domToReact(children, options)}
          </body>
        )
      }
    },
  }

  const onSubmit = () => {
    setIsOpen(false)
    dispatch({
      type: 'gtm/sendEvent',
      payload: {
        event: 'agree_tnc',
        userId: profile.customerID,
        orderId: order.orderid,
      },
    })
    dispatch({ type: 'paylater/acceptTNC', payload: { orderId } })
    localstorage.saveString(SHOW_TERMS, false)
  }

  return (
    <ModalContainer isOpen={modalIsOpen} isLock>
      <ModalContentContainer>
        <Title>{intl.formatMessage({ id: 'terms.title' })}</Title>
        <ModalBodyContainer>
          <TermContainer showMore={showMore}>
            {parse(render, options)}
          </TermContainer>
          <ShowMore showMore={showMore} onClick={toggleShowMore}>
            {showMore ? <ArrowUpIcon /> : <ArrowDownIcon />}
            {showMore && (
              <ShowMoreTitle>
                {intl.formatMessage({ id: 'show.less' })}
              </ShowMoreTitle>
            )}
            {!showMore && (
              <ShowMoreTitle>
                {intl.formatMessage({ id: 'show.more' })}
              </ShowMoreTitle>
            )}
          </ShowMore>
        </ModalBodyContainer>

        <AgreementFlex onSubmit={handleSubmit(onSubmit)}>
          {agreements.map((agreement, i) => (
            <AgreementRow key={agreement.key}>
              <AgreementCheckbox>
                <Controller
                  name={`agreed.${i}`}
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id={agreement.key}
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
              </AgreementCheckbox>
              <AgreementMessage htmlFor={agreement.key}>
                {tt(intl, agreement.msg)}
              </AgreementMessage>
            </AgreementRow>
          ))}
          <AgreeButtonContainer>
            <AgreeButton type="submit" disabled={!agreedTerms}>
              {intl.formatMessage({
                id: 'common.continue',
              })}
            </AgreeButton>
          </AgreeButtonContainer>
        </AgreementFlex>
      </ModalContentContainer>
    </ModalContainer>
  )
}

export default TermsV2
