import React, { useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Spacer from '@/components/Spacer'
import AddressSearch, { useAddressSearch } from '@/components/ui/AddressSearch'
import Checkbox from '@/components/ui/CheckBox'
import PhoneInput from '@/components/ui/PhoneInput'
import SelectInput from '@/components/ui/SelectInput'
import { Row } from '@/components/ui/Shared'
import { ADDRESS_REGIONS } from '@/constants/address'
import { tt } from '@/locales/format'
import { Input } from '@/pages/Auth/shared/style'
import { joinAddress } from '@/utils/Address'
import * as PhoneUtils from '@/utils/Phone'

const InputTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  margin-bottom: 0.556rem;
`

const SetDefaultRow = styled(Row)`
  cursor: pointer;
`

const SetDefaultText = styled.div`
  font-size: 0.889rem;
  font-weight: 500;
`

const ProfileDeliveryAddressForm = ({ address, form }) => {
  const intl = useIntl()

  const {
    setValue,
    register,
    control,
    watch,
    formState: { errors },
  } = form

  const watchAddress = watch('address1')
  const address1FocusRef = useRef()

  const { searchText, searchAddress, onAddressSearched } = useAddressSearch()

  useEffect(() => {
    if (address) {
      setValue('contactFirstname', address.contactFirstname || '')
      setValue('contactLastname', address.contactLastname || '')
      setValue('address1', address.address1 || '')
      setValue('address2', address.address2 || '')
      setValue('district', address.district || '')
      setValue('country', (address.country || '')?.toUpperCase())
      setValue('contactNumber', address.contactNumber || '')
      setValue('isDefault', address.isDefault || false)
    }
  }, [])

  useEffect(() => {
    if (address1FocusRef.current) {
      searchAddress(watchAddress)
    }
  }, [watchAddress])

  const onChangeAddressSearch = onAddressSearched((addr) => {
    const { details } = addr?.value || {}
    if (details) {
      setValue('address1', details.building || '')
      setValue('address2', joinAddress([details.street, details.estate]))
      setValue('district', details.district || '')
      setValue('country', (details.country || '').toUpperCase())
    }
  })

  return (
    <>
      <InputTitle>{tt(intl, 'common.fullname')}</InputTitle>
      <Input
        type="text"
        id="contactFirstname"
        {...register('contactFirstname')}
        placeholder={tt(intl, 'common.firstname')}
        error={errors.contactFirstname}
      />
      <Spacer height="0.889rem" />
      <Input
        type="text"
        id="contactLastname"
        {...register('contactLastname')}
        placeholder={tt(intl, 'common.lastname')}
        error={errors.contactLastname}
      />
      <Spacer height="0.889rem" />
      <InputTitle>{tt(intl, 'profile.address')}</InputTitle>
      <AddressSearch searchText={searchText} onChange={onChangeAddressSearch}>
        <Input
          type="text"
          id="address1"
          autoComplete="address-line1"
          {...register('address1', { required: true })}
          placeholder={tt(intl, 'address.address1')}
          error={errors.address1}
          onFocus={() => {
            address1FocusRef.current = true
          }}
          onBlur={() => {
            address1FocusRef.current = false
          }}
        />
      </AddressSearch>
      <Spacer height="0.889rem" />
      <Input
        type="text"
        id="address2"
        autoComplete="address-line2"
        {...register('address2')}
        placeholder={tt(intl, 'address.address2')}
        error={errors.address2}
      />
      <Spacer height="0.889rem" />
      <Input
        type="text"
        id="district"
        autoComplete="address-level2"
        {...register('district')}
        placeholder={tt(intl, 'address.district')}
        error={errors.district}
      />
      <Spacer height="0.889rem" />
      <Controller
        name="country"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <SelectInput
            autoComplete="address-level1"
            placeholder={tt(intl, 'address.country')}
            options={ADDRESS_REGIONS}
            {...field}
            error={errors.country}
          />
        )}
      />
      <Spacer height="0.889rem" />
      <InputTitle>{tt(intl, 'common.phone')}</InputTitle>
      <Controller
        name="contactNumber"
        control={control}
        rules={{
          validate: (v) => PhoneUtils.isValidatePhoneNumber(v),
        }}
        render={({ field }) => (
          <PhoneInput
            placeholder={tt(intl, 'common.mobilenumber')}
            countryCode={PhoneUtils.parsePhoneNumber(field.value).countryCode}
            phoneNumber={PhoneUtils.parsePhoneNumber(field.value).localNumber}
            onChange={(data) => {
              field.onChange(`${data.countryCode}${data.phoneNumber}`)
            }}
            error={errors.contactNumber}
          />
        )}
      />
      <Spacer height="1.333rem" />
      <Controller
        name="isDefault"
        control={control}
        render={({ field }) => (
          <SetDefaultRow onClick={() => field.onChange(!field.value)}>
            <Checkbox checked={field.value} />
            <Spacer width="0.889rem" />
            <SetDefaultText>
              {tt(intl, 'profile.shippingaddress.setdefault')}
            </SetDefaultText>
          </SetDefaultRow>
        )}
      />
    </>
  )
}

export default ProfileDeliveryAddressForm
