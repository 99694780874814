import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/common/close.svg'
import { getPartnerName } from '@/utils/Partner'
import { isCheckout, IsPaylatering } from '@/utils/Route'

import LoadingModal from './LoadingModal'

const Container = styled.div`
  text-align: center;
`

const BackToPartnerLink = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`

const CloseButtonWrapper = styled.div`
  cursor: pointer;
`

const CancelPurchase = ({ icon }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isPaylatering = IsPaylatering(pathname)
  const isCheckoutRoute = isCheckout()

  const { order } = useSelector((state) => state.order)
  const { profile } = useSelector((state) => state.profile)
  const { cancelOrder } = useSelector((state) => state.paylater)

  useEffect(() => {
    const url = order?.webhook_failure || ''
    if (!url) return

    if (cancelOrder.isIdle) return
    if (cancelOrder.isSuccess || cancelOrder.isError) {
      window.location.href = url
    }
  }, [cancelOrder])

  if (cancelOrder.isLoading) return <LoadingModal loading />

  if (!order) return <></>

  const url = order.webhook_failure

  if (!url) return <></>

  const partner = getPartnerName(order.orderid)

  const cancelPayment = (e) => {
    e.preventDefault()

    const msg = partner
      ? intl.formatMessage({ id: 'payment.cancel.confirm.msg' }, { partner })
      : intl.formatMessage({ id: 'payment.cancel.confirm.msg.default' })

    // eslint-disable-next-line no-alert
    const isConfirm = window.confirm(msg)

    if (!isConfirm) return

    dispatch({
      type: 'gtm/sendEvent',
      payload: {
        event: 'cancel_purchase',
        userId: profile?.customerID,
        orderId: order.orderid,
      },
    })

    const isOrderOwner = profile?.customerID === order.customer?.customerID

    if (isOrderOwner) {
      dispatch({
        type: 'paylater/cancelOrder',
        payload: { orderId: order.orderid },
      })
    } else {
      window.location.href = url
    }
  }

  // only display in checkout flow
  if (!(isPaylatering || isCheckoutRoute)) {
    return null
  }

  return (
    <Container>
      {!icon && (
        <BackToPartnerLink onClick={cancelPayment}>
          {partner
            ? intl.formatMessage({ id: 'back.to.partner' }, { partner })
            : intl.formatMessage({ id: 'button.back' })}
        </BackToPartnerLink>
      )}
      {icon && (
        <CloseButtonWrapper onClick={cancelPayment}>
          <CloseSvg />
        </CloseButtonWrapper>
      )}
    </Container>
  )
}

export default CancelPurchase
