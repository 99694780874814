import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  background-color: #fffaea;
  padding: 1.5rem 1rem;
  box-sizing: border-box;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
`

const Amount = styled.div`
  font-size: 1.111rem;
  font-weight: 600;
`

const BarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
`

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(p) => `${p.precentage}%`};
  height: 100%;
  background-color: #ffcc33;
`

const SpendingLimitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.667rem;
`

const ProfileAvailableSpend = ({ availableSpend, totalAvailableSpend }) => {
  const intl = useIntl()

  const percentage = parseInt(
    (availableSpend.amount / totalAvailableSpend.amount) * 100,
    10
  )
  const fAvailableSpend = FormattedPrice(
    availableSpend.currency,
    availableSpend.amount
  )
  const fTotalAvailableSpend = FormattedPrice(
    totalAvailableSpend.currency,
    totalAvailableSpend.amount
  )

  return (
    <Container>
      <Title>{tt(intl, 'paylater.availablespend.amount')}</Title>
      <Spacer height="8px" />
      <Amount>{fAvailableSpend}</Amount>
      <Spacer height="0.889rem" />
      <BarContainer>
        <Bar precentage={percentage} />
      </BarContainer>
      <Spacer height="4px" />
      <SpendingLimitContainer>
        <div>{tt(intl, 'paylater.availablespend.limit')}</div>
        <div>{`${fAvailableSpend} / ${fTotalAvailableSpend}`}</div>
      </SpendingLimitContainer>
    </Container>
  )
}

export default ProfileAvailableSpend
