import React from 'react'
import styled from 'styled-components'

const BarContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${(p) => p.height || '0.889rem'};
  background-color: #f0f0f0;
  border-radius: 100px;
`

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(p) => `${p.precentage}%`};
  height: 100%;
  background-color: #ffcc33;
  border-radius: 100px;
`

const ProgressBar = ({ height, precentage }) => (
  <BarContainer height={height}>
    <Bar precentage={precentage} />
  </BarContainer>
)

export default ProgressBar
