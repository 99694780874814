import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as AppleLoginIconSvg } from '@/assets/socials/signin/apple-colored.svg'
import LoginButton from '@/components/buttons/LoginButton'
import LoginApple from '@/components/LoginApple'
import { tt } from '@/locales/format'

const AppleLoginIcon = styled(AppleLoginIconSvg)``

const AppleLoginButton = ({
  onClickAppleLogin,
  onAppleSuccess,
  onAppleFailure,
}) => {
  const intl = useIntl()

  const doClickAppleLogin = (onClick) => () => {
    const result = onClickAppleLogin ? onClickAppleLogin() : true
    if (result) {
      onClick()
    }
  }

  return (
    <LoginApple
      onSuccess={onAppleSuccess}
      onFailure={onAppleFailure}
      render={({ onClick }) => (
        <LoginButton
          style={{ backgroundColor: 'black', color: 'white' }}
          onClick={doClickAppleLogin(onClick)}
          text={tt(intl, 'login.apple.continue')}
          iconProps={{
            renderImage: () => <AppleLoginIcon />,
            width: '1.1rem',
            height: '1.1rem',
          }}
        />
      )}
    />
  )
}

export default AppleLoginButton
