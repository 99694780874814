import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as AlertSvg } from '@/assets/common/alert.svg'

import Box from './Box'

export const Mode = {
  Home: 'home',
  Order: 'order',
}

const InvalidDocBox = styled(Box)`
  font-size: ${({ mode }) => mode === Mode.Home && `0.75rem`};
  padding-left: ${({ mode }) =>
    mode === Mode.Home ? `calc(100% / 12 - 10px)` : `calc(100% / 12)`};
  padding-right: ${({ mode }) =>
    mode === Mode.Home ? `calc(100% / 12 - 10px)` : `calc(100% / 12)`};
  width: ${({ mode }) =>
    mode === Mode.Home
      ? `calc(100% / 12 * 10 + 20px)`
      : `calc(100% / 12 * 10)`};
  display: flex;
  align-items: flex-start;
`

const InvalidDocIcon = styled(AlertSvg)`
  width: 2rem;
  height: auto;
  display: block;
`

const InvalidDocMessage = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.gutter * 2}px;
  color: ${({ theme }) => theme.message.error};
`

const InvalidDocMessageBox = ({ children, mode }) => (
  <InvalidDocBox mode={mode}>
    <InvalidDocIcon />
    <InvalidDocMessage>{children}</InvalidDocMessage>
  </InvalidDocBox>
)

InvalidDocMessageBox.defaultProps = {
  children: <></>,
  mode: Mode.Order,
}

InvalidDocMessageBox.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
}

export default InvalidDocMessageBox
