import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  order: null,
  orders: null,
  paynowOrders: null,
  paynowOrdersTotal: 0,
  payment: null,
  payments: [],
  paymentsTotal: 0,
  orderMiles: null,
  metadata: [],
  loading: false,
  error: null,
  isFromFastPaynow: false,
  bankListFps: [],
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    getPaynowOrdersSuccess: (state, action) => {
      const { total, data } = action.payload
      state.paynowOrders = data
      state.paynowOrdersTotal = total
    },
    insertPaynowOrders: (state, action) => {
      const { total, data } = action.payload
      state.paynowOrders = state.paynowOrders.concat(data)
      state.paynowOrdersTotal = total
    },
    setIsFromFastPaynow: (state, action) => {
      state.isFromFastPaynow = action.payload
    },
    getAllPaymentsSuccess: (state, action) => {
      const { total, data } = action.payload
      state.payments = data
      state.paymentsTotal = total
    },
    insertPayments: (state, action) => {
      const { total, data } = action.payload
      state.payments = state.payments.concat(data.slice())
      state.paymentsTotal = total
    },
    getPaymentSuccess: (state, action) => {
      state.payment = action.payload
    },
    clearPayment: (state, action) => {
      state.payment = null
    },
    // async
    reset: () => initialState,
    getOrderStart: (state) => {
      state.loading = true
      state.error = null
    },
    getOrderSuccess: (state, action) => {
      state.order = action.payload
      state.loading = false
      state.error = null
      if (state.order) {
        state.order.instalments.sort((a, b) => a.series - b.series)
      }
    },
    getOrderFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    getOrderActivitiesSuccess: (state, action) => {
      state.metadata = action.payload
      state.loading = false
      state.error = null
    },
    getAllOrdersStart: (state) => {
      state.loading = true
      state.error = null
    },
    getAllOrdersSuccess: (state, action) => {
      const {
        paynowOrders = [],
        paylaterOrders = [],
        paynowOrdersTotal = 0,
      } = action.payload
      state.orders = paylaterOrders
      state.loading = false
      state.error = null
      if (state.orders) {
        state.orders.forEach((order) => {
          order.instalments.sort((a, b) => a.series - b.series)
        })
      }
      state.paynowOrders = paynowOrders
      state.paynowOrdersTotal = paynowOrdersTotal
    },
    getAllOrdersFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    getOrderMilesSuccess: (state, action) => {
      state.orderMiles = action.payload
    },
    getBankListStart: (state) => {
      state.loading = true
      state.error = null
    },
    getBankListSuccess: (state, action) => {
      state.bankListFps = action.payload
    },
    getBankListFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getOrderStart,
  getOrderSuccess,
  getOrderFailed,
  getAllOrdersStart,
  getAllOrdersSuccess,
  getAllOrdersFailed,
} = orderSlice.actions

export default orderSlice.reducer
