import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { joinAddress } from '@/utils/Address'
import { parsePhoneNumber } from '@/utils/Phone'
import { joinName } from '@/utils/user'

const Title = styled.div`
  font-size: 0.889rem;
  font-weight: 700;
`

const Body = styled.div`
  font-size: 0.778rem;
`

const Section = styled.div`
  margin-bottom: 0.889rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const ItemTextButton = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
  cursor: pointer;
  text-decoration: underline;
`

const Key = styled.div`
  font-weight: 500;
  flex: 1;
`

const Value = styled.div`
  text-align: right;
`

const Profile = ({ profile, onClickEdit }) => {
  const intl = useIntl()

  if (!profile) return <></>

  const {
    firstName,
    lastName,
    floor,
    building,
    street,
    district,
    region,
    nationalID,
    DOB,
    tel,
    email,
  } = profile

  return (
    <>
      <Row>
        <Title>{intl.formatMessage({ id: 'profile.title' })}</Title>
        <ItemTextButton onClick={onClickEdit}>
          {tt(intl, 'common.edit')}
        </ItemTextButton>
      </Row>
      <Spacer height="1.333rem" />
      <Body>
        <Section>
          <Row>
            <Key>{intl.formatMessage({ id: 'signup.profile.name' })}</Key>
            <Value>{joinName([firstName, lastName])}</Value>
          </Row>
        </Section>
        <Section>
          <Row>
            <Key>{intl.formatMessage({ id: 'signup.profile.address' })}</Key>
            <Value>
              <div>{joinAddress([floor, building])}</div>
              <div>{street}</div>
              <div>{district}</div>
              <div>{region}</div>
            </Value>
          </Row>
        </Section>
        <Section>
          <Row>
            <Key>{intl.formatMessage({ id: 'signup.profile.hkid' })}</Key>
            <Value>{nationalID}</Value>
          </Row>
        </Section>
        <Section>
          <Row>
            <Key>{intl.formatMessage({ id: 'signup.profile.dob' })}</Key>
            <Value>{moment(DOB).format('DD/MM/YYYY')}</Value>
          </Row>
        </Section>
        <Section>
          <Row>
            <Key>{intl.formatMessage({ id: 'signup.profile.mobile' })}</Key>
            <Value>{parsePhoneNumber(tel).formatted || ' '}</Value>
          </Row>
        </Section>
        <Section>
          <Row>
            <Key>{intl.formatMessage({ id: 'signup.profile.email' })}</Key>
            <Value>{email}</Value>
          </Row>
        </Section>
      </Body>
    </>
  )
}

export default Profile
