import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as OverdueAlertSvg } from '@/assets/paylater/overdue-alert.svg'
import { Button } from '@/components/Divit'
import Icon from '@/components/Icon'
import MerchantName from '@/components/merchants/MerchantName'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import {
  getOutstandingInstalment,
  getTotalOutstandingAmount,
} from '@/utils/Instalments'
import { getPartnerRef } from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  padding: 0.88rem 1.33rem;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 8px;
`

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.667rem;
  font-weight: 600;
  color: #e6171a;
`

const Content = styled.div`
  font-size: 0.667rem;
  color: rgba(204, 0, 0, 1);
`

const ActionContainer = styled.div``

const PayButton = styled(Button)`
  font-weight: 700;
  min-width: unset;
  height: auto;
  background-color: #1c1c1c;
  color: white;
  font-size: 0.555rem;
  padding: 0.444rem 1.111rem;
`

const PaylaterOverdueNotice = ({ order }) => {
  const intl = useIntl()
  const history = useHistory()

  if (!order) return null

  const partnerRef = getPartnerRef(order)
  const instalment = getOutstandingInstalment(order.instalments)
  const amount = getTotalOutstandingAmount(instalment)

  if (!instalment) return null

  const onClickPay = () => {
    history.push(`/payment/${order.orderid}/${instalment.instalmentID}`)
  }

  return (
    <Container>
      <Heading>
        <div>
          {tt(intl, 'paylater.overdue.msg', {
            merchant: <MerchantName merchantID={order.partnerid} />,
          })}{' '}
          {partnerRef}
        </div>
        <Icon
          renderImage={() => <OverdueAlertSvg />}
          wrapperWidth="1rem"
          wrapperHeight="0.87rem"
          width="1rem"
          height="0.87rem"
        />
      </Heading>
      <Spacer height="0.444rem" />
      <Content>{tt(intl, 'paylater.overdue.clear')}</Content>
      <Spacer height="0.889rem" />
      <ActionContainer>
        <PayButton onClick={onClickPay}>
          {tt(intl, 'payment.pay')}{' '}
          {FormattedPrice(amount.currency, amount.amount)}
        </PayButton>
      </ActionContainer>
    </Container>
  )
}

export default PaylaterOverdueNotice
