import React, { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import CancelPurchase from '@/components/CancelPurchase'
import CheckoutSteps from '@/components/CheckoutSteps'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import InvalidDocMessageBox, { Mode } from '@/components/InvalidDocMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import UpdateProfileForm, {
  errorFieldMap,
  errorMessageMap,
  toFormValues,
  validateForm,
} from '@/components/UpdateProfileForm'
import ProfileStatus from '@/constants/ProfileStatus'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import scrollToTop from '@/utils/scrollToTop'
import { validateAge } from '@/utils/validation'

const Container = styled(PaddingContent)`
  align-items: center;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
`

const SubTitle = styled.div`
  font-size: 16px;
`

const ProfileContainer = styled.div``

const NextButton = styled(Button)`
  font-size: 0.889rem;
`

const PaylaterProfile = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname, state } = location
  const { orderId } = useParams()

  const { profile, purchaseStatus } = useSelector((s) => s.profile)
  const { patchProfile } = useSelector((s) => s.paylater)

  const callbackAction = useRef(null)

  const form = useForm()
  const { handleSubmit, formState } = form

  const {
    retry,
    isInitLoading,
    isInitError,
    errorMessage,
    updateErrorMessage,
  } = usePage({
    initAction: { type: 'pageInit/initCheckoutOrder', payload: { orderId } },
  })

  useEffect(() => () => dispatch({ type: 'paylater/reset' }), [])

  useEffect(() => {
    if (patchProfile.isError) {
      const { error } = patchProfile
      if (error.code === 1003 && error.errorData) {
        const errorFieldNames = error.errorData.map((f) => f.affectedField)
        // highlight
        errorFieldNames.forEach((e) => {
          const errorFields = errorFieldMap[e.toLowerCase()] || []
          errorFields.forEach((field) => form.setError(field))
        })
        // display error message
        const errorMessages = errorFieldNames
          .map((e) => {
            const errorMessageId = errorMessageMap[e.toLowerCase()]
            return errorMessageId && intl.formatMessage({ id: errorMessageId })
          })
          .filter((s) => s)
        if (errorMessages.length > 0) {
          updateErrorMessage(errorMessages)
          return
        }
      }
      updateErrorMessage(intl.formatMessage({ id: 'error.action.update' }))
    } else {
      updateErrorMessage('')
    }
  }, [patchProfile])

  const getFormErrorMessages = () => {
    const { errors } = formState
    if (Object.keys(errors).length > 0) {
      const errorMessages = Object.keys(errors)
        .map((key) => errors[key].message)
        .filter((s) => s)
      const messages = [...new Set(errorMessages)].map((e) =>
        intl.formatMessage({ id: e })
      )
      return messages
    }
    return []
  }

  useEffect(() => {
    const { errors } = formState
    if (Object.keys(errors).length > 0) {
      scrollToTop(true)
    }
  }, [formState])

  useEffect(() => {
    if (purchaseStatus) {
      if (!purchaseStatus.telValidated || !purchaseStatus.emailValidated) {
        updateErrorMessage(intl.formatMessage({ id: 'error.validate.profile' }))
      } else {
        updateErrorMessage('')
      }
    }
  }, [purchaseStatus])

  useEffect(() => {
    if (patchProfile.isSuccess) {
      if (callbackAction.current) {
        callbackAction.current()
        callbackAction.current = null
        return
      }
      history.push(`/paylater/kyc`, {
        nextPage: `/paylater/summary/${orderId}`,
      })
    }
  }, [profile, patchProfile])

  const onSubmit = (data) => {
    let isValid = validateForm(data, form.setError)

    const formValues = toFormValues(data)

    const { firstName, lastName, nationalID, DOB } = formValues

    if (!firstName) {
      form.setError('firstName', { message: 'error.invalid.firstname' })
      isValid = false
    }

    if (!lastName) {
      form.setError('lastName', { message: 'error.invalid.lastname' })
      isValid = false
    }

    // must need address
    if (
      !(
        formValues.floor ||
        formValues.building ||
        formValues.street ||
        formValues.district ||
        formValues.region
      )
    ) {
      form.setError('floor', { message: 'error.invalid.address' })
      form.setError('building', { message: 'error.invalid.address' })
      form.setError('street', { message: 'error.invalid.address' })
      form.setError('district', { message: 'error.invalid.address' })
      form.setError('region', { message: 'error.invalid.address' })
      isValid = false
    }

    if (!profile.nationalID && !nationalID) {
      // hkid is empty from values, need to check existing one first
      form.setError('HKID', { message: 'error.invalid.hkid' })
      isValid = false
    }

    if (!DOB) {
      form.setError('DOBDay', { message: 'error.invalid.dob' })
      form.setError('DOBMonth', { message: 'error.invalid.dob' })
      form.setError('DOBYear', { message: 'error.invalid.dob' })
      isValid = false
    }

    if (DOB && !validateAge(DOB)) {
      form.setError('DOBDay', { message: 'error.invalid.dob.age' })
      form.setError('DOBMonth', { message: 'error.invalid.dob.age' })
      form.setError('DOBYear', { message: 'error.invalid.dob.age' })
      isValid = false
    }

    if (!isValid) {
      return
    }

    updateErrorMessage('')

    // dont post hkid if already set

    if (profile.nationalID) delete formValues.nationalID

    dispatch({ type: 'paylater/patchProfile', payload: formValues })
  }

  useEffect(() => {
    if (
      !state?.update &&
      purchaseStatus?.emailValidated &&
      purchaseStatus?.telValidated &&
      profile?.status !== ProfileStatus.PAUSED &&
      profile?.firstName &&
      profile?.lastName &&
      profile?.nationalID &&
      profile?.DOB &&
      (profile?.floor ||
        profile?.building ||
        profile?.street ||
        profile?.district ||
        profile?.region)
    ) {
      history.push(`/paylater/summary/${orderId}`)
    }
  }, [purchaseStatus, profile, state])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickChangeMobileNumber = (isVerified) => {
    callbackAction.current = () => {
      history.push(`/paylater/verify-mobile`, {
        mode: isVerified ? 'change' : 'verify',
        returnUrl: pathname,
      })
    }
    handleSubmit(onSubmit)()
  }

  const onClickChangeEmail = (isVerified) => {
    if (!isVerified) {
      callbackAction.current = () => {
        history.push(`/paylater/verify-email`, {
          returnUrl: pathname,
          mode: 'verify',
          email: profile.email,
        })
      }
    } else {
      callbackAction.current = () => {
        history.push(`/paylater/verify-email`, {
          mode: 'change',
          returnUrl: pathname,
        })
      }
    }
    handleSubmit(onSubmit)()
  }

  const onClickNext = (e) => {
    e.preventDefault()

    handleSubmit(onSubmit)()
  }

  const isPurchaseValid =
    purchaseStatus.emailValidated && purchaseStatus.telValidated

  const isPaused = profile.status === ProfileStatus.PAUSED

  const loading = patchProfile.isLoading

  const formErrorMessages = getFormErrorMessages()
  const errorMessages =
    formErrorMessages.length > 0 ? formErrorMessages : errorMessage

  return (
    <Container>
      <Spacer height="1.333rem" />
      <CheckoutSteps index={0} />
      <Spacer height="1.333rem" />
      <Title>{tt(intl, 'setup.account')}</Title>
      <Spacer height="1rem" />
      <SubTitle>{tt(intl, 'complete.foursteps')}</SubTitle>
      {isPaused && (
        <InvalidDocMessageBox mode={Mode.Order}>
          <div>
            <b>{intl.formatMessage({ id: 'kyc.problem' })}</b>
          </div>
          <div>{intl.formatMessage({ id: 'please.contact.support' })}</div>
        </InvalidDocMessageBox>
      )}
      <Spacer height="0.889rem" />
      <ErrorMessageBox errorMessage={errorMessages} />
      <Spacer height="0.889rem" />
      {profile && (
        <ProfileContainer>
          <FormProvider {...form}>
            <UpdateProfileForm
              profile={profile}
              onClickChangeEmail={onClickChangeEmail}
              onClickChangeMobileNumber={onClickChangeMobileNumber}
            />
            <Spacer height="1.33rem" />
            <NextButton
              type="stretch"
              disabled={isPaused || !isPurchaseValid}
              onClick={onClickNext}
            >
              {intl.formatMessage({ id: 'button.continue' })}
            </NextButton>
          </FormProvider>
        </ProfileContainer>
      )}
      <Spacer height="2rem" />
      <CancelPurchase />
      <Spacer height="2rem" />
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default PaylaterProfile
