import moment from 'moment'

import { t } from '@/locales/format'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { fetchProfile } from '@/redux/profile/profileActions'
import {
  profileSelector,
  sessionSelector,
} from '@/redux/profile/profileSelector'

// setup pin => set pin + verify pin
// change email, tel, burn => pin
// change pin => pin => set pin + verify pin
// forgot pin => otp verification => set pin + verify pin

const isValidPasswordSession = (lastLoginTs) =>
  moment(lastLoginTs).add(14, 'minutes').isAfter(moment())

export const forgotPin = (payload) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: 'pin/openVerifyPinOTPModal',
      payload: {
        ...payload,
        callback: (result) => {
          resolve(result)
        },
      },
    })
  })

export const setupPin = (payload) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: 'pin/openSetupModal',
      payload: {
        ...payload,
        callback: (result) => {
          resolve(result)
        },
      },
    })
  })

export const requestPin = (payload) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: 'pin/openRequestModal',
      payload: {
        ...payload,
        callback: (result) => {
          resolve(result)
        },
      },
    })
  })

// validate PIN to do action
export const verifyIdentity =
  ({ isForce } = {}) =>
  async (dispatch, getState) => {
    const session = sessionSelector(getState())
    console.log('getState after fetchProfile', getState(), session)

    if (!isForce && isValidPasswordSession(session.lastLoginTs)) {
      return Promise.resolve({ isSuccess: true })
    }

    // eslint-disable-next-line
    const { isSuccess } = await dispatch(startRequestPinFlow({ isAuth: true }))

    return { isSuccess }
  }

// new or change
export const startSetupPinFlow =
  ({ isReset, isForceCheckIdentity } = {}) =>
  async (dispatch) => {
    let authResult = { isSuccess: true }
    if (isReset) {
      authResult = await dispatch(
        verifyIdentity({ isForce: isForceCheckIdentity })
      )
    }
    if (authResult.isSuccess) {
      const pinResult = await dispatch(setupPin({ isReset }))
      return pinResult
    }
    return { isSucess: false, pin: '' }
  }

export const askSetupPin = () => async (dispatch) => {
  const { isOk } = await dispatch(
    promptConfirmModal({
      title: t('common.heywait'),
      content: t('profile.completepin.ask'),
      okText: t('profile.complete.confirm'),
    })
  )
  if (isOk) {
    const result = await dispatch(startSetupPinFlow())
    return result
  }
  return { isSuccess: false, pin: '' }
}

// auth or validate
export const startRequestPinFlow =
  ({ isAuth } = {}) =>
  async (dispatch, getState) => {
    const profile = profileSelector(getState())
    if (!profile.hasUserPin) {
      const result = await dispatch(askSetupPin())
      return result
    }
    const result = dispatch(requestPin({ isAuth }))
    return result
  }

// forgot pin using verification OTP
export const startForgotPinFlow = () => async (dispatch) => {
  const authResult = await dispatch(forgotPin())
  if (authResult.isSuccess) {
    const pinResult = await dispatch(setupPin())
    return pinResult
  }
  return { isSucess: false, pin: '' }
}

export default 0
