import { createSlice } from '@reduxjs/toolkit'

import { setAuthHeader } from '@/services/apiFast'
import * as SessionStorage from '@/utils/sessionStorage'

import AsyncState from '../utils/AsyncState'

export const SIGN_UP_SESSION_KEY = 'fast_login_session'
export const SIGN_UP_REFERRAL_CODE_KEY = 'referral_code'

const existingSession = SessionStorage.getJSON(SIGN_UP_SESSION_KEY)
const existingReferralCode = SessionStorage.getString(SIGN_UP_REFERRAL_CODE_KEY)

if (existingSession?.token) {
  setAuthHeader(existingSession.token)
}

const fastSignupSlice = createSlice({
  name: 'fastSignup',
  initialState: {
    referralCode: existingReferralCode,
    session: existingSession,
    signup: AsyncState.create(),
    signupVerifyEmail: AsyncState.create(),
    sendOTP: AsyncState.create(),
    verifyOTP: AsyncState.create(),
    setupPassword: AsyncState.create(),
    forgotPassword: AsyncState.create(),
    checkReferralCode: AsyncState.create(),
  },
  reducers: {
    setReferralCode: (state, action) => {
      state.referralCode = action.payload
      SessionStorage.saveString(SIGN_UP_REFERRAL_CODE_KEY, state.referralCode)
    },
    clearReferralCode: (state) => {
      state.referralCode = ''
      SessionStorage.saveString(SIGN_UP_REFERRAL_CODE_KEY, '')
    },
    updateSession: (state, action) => {
      state.session = { ...state.session, ...action.payload }
      SessionStorage.saveJSON(SIGN_UP_SESSION_KEY, state.session)
    },
    clearSession: (state) => {
      state.session = undefined
      SessionStorage.remove(SIGN_UP_SESSION_KEY)
    },
    reset: (state) => {
      state.signup = AsyncState.reset()
      state.signupVerifyEmail = AsyncState.reset()
      state.verifyOTP = AsyncState.reset()
      state.sendOTP = AsyncState.reset()
      state.setupPassword = AsyncState.reset()
      state.forgotPassword = AsyncState.reset()
      state.checkReferralCode = AsyncState.reset()
    },
    signupStart: (state) => {
      state.signup = AsyncState.start()
    },
    signupSuccess: (state, action) => {
      state.signup = AsyncState.success(action.payload)
    },
    signupFailed: (state, action) => {
      state.signup = AsyncState.failed(action.payload)
    },
    signupVerifyEmailStart: (state) => {
      state.signupVerifyEmail = AsyncState.start()
    },
    signupVerifyEmailSuccess: (state, action) => {
      state.signupVerifyEmail = AsyncState.success(action.payload)
    },
    signupVerifyEmailFailed: (state, action) => {
      state.signupVerifyEmail = AsyncState.failed(action.payload)
    },
    sendOTPStart: (state) => {
      state.sendOTP = AsyncState.start()
    },
    sendOTPSuccess: (state, action) => {
      state.sendOTP = AsyncState.success(action.payload)
    },
    sendOTPFailed: (state, action) => {
      state.sendOTP = AsyncState.failed(action.payload)
    },
    verifyOTPStart: (state) => {
      state.verifyOTP = AsyncState.start()
    },
    verifyOTPSuccess: (state, action) => {
      state.verifyOTP = AsyncState.success(action.payload)
    },
    verifyOTPFailed: (state, action) => {
      state.verifyOTP = AsyncState.failed(action.payload)
    },
    setupPasswordStart: (state) => {
      state.setupPassword = AsyncState.start()
    },
    setupPasswordSuccess: (state, action) => {
      state.setupPassword = AsyncState.success(action.payload)
    },
    setupPasswordFailed: (state, action) => {
      state.setupPassword = AsyncState.failed(action.payload)
    },
    forgotPasswordStart: (state) => {
      state.forgotPassword = AsyncState.start()
    },
    forgotPasswordSuccess: (state, action) => {
      state.forgotPassword = AsyncState.success(action.payload)
    },
    forgotPasswordFailed: (state, action) => {
      state.forgotPassword = AsyncState.failed(action.payload)
    },
    checkReferralCodeStart: (state) => {
      state.checkReferralCode = AsyncState.start()
    },
    checkReferralCodeSuccess: (state, action) => {
      state.checkReferralCode = AsyncState.success(action.payload)
    },
    checkReferralCodeFailed: (state, action) => {
      state.checkReferralCode = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = fastSignupSlice

export default fastSignupSlice.reducer
