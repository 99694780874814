import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import Icon from '@/components/Icon'

const Scroller = styled.div`
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const Container = styled.div`
  position: relative;
  display: flex;
  border-bottom: 2px solid #dedede;

  ${(p) =>
    p.itemCount > 3 &&
    css`
      width: calc(22% * ${p.itemCount});

      ${SelectorTab} {
        flex-shrink: 0;
        width: 20%;
      }
    `}

  ${(p) =>
    p.itemCount <= 3 &&
    css`
      width: 100%;

      ${SelectorTab} {
        flex: 1;
      }
    `}
`

const Bg = styled.div`
  position: absolute;
  width: ${({ total }) => `${(1 / total) * 100}%`};
  height: 100%;
  top: 0;
  left: ${({ total, activeIndex }) => `${(activeIndex / total) * 100}%`};
  transition: left 0.3s;
  border-bottom: 2px solid #ffcc33;
`

const SelectorTab = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 9px 0px;

  & svg path,
  & svg line {
    fill: ${(p) => (p.isActive ? '#FFCC33' : '#979797')};
  }

  &:hover {
    cursor: pointer;
  }
`

const TabLabel = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  color: ${({ isActive }) => (isActive ? '#1c1c1c' : '#979797')};
  margin-left: 0.44rem;
`

export const Tab = styled.div`
  width: 100%;
  height: ${({ isActive, isHide }) => (isHide ? 0 : isActive ? 'auto' : 0)};
  overflow: hidden;
`

const SelectorUnderline = ({ defaultTab, tabs, onChange }) => {
  const scrollerRef = useRef()

  const defaultTabIndex = tabs.findIndex((t) => t.key === defaultTab)
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex)

  const scrollToTab = (tab) => {
    scrollerRef.current
      ?.querySelector(`.selector-tab-${tab.key}`)
      ?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
  }

  useEffect(() => {
    if (defaultTab) {
      scrollToTab({ key: defaultTab })
    }
  }, [])

  const onSelectTab = (tab, index) => {
    scrollToTab(tab)
    setActiveTabIndex(index)
    onChange?.(tabs[index])
  }

  return (
    <Scroller ref={scrollerRef}>
      <Container itemCount={tabs.length}>
        <Bg total={tabs.length} activeIndex={activeTabIndex} />
        {tabs.map((tab, i) => (
          <SelectorTab
            className={`selector-tab-${tab.key}`}
            key={i}
            isActive={i === activeTabIndex}
            onClick={() => onSelectTab(tab, i)}
          >
            {tab.Icon && (
              <Icon
                renderImage={() => <tab.Icon />}
                width="0.889rem"
                height="0.889rem"
              />
            )}
            <TabLabel isActive={i === activeTabIndex}>{tab.label}</TabLabel>
          </SelectorTab>
        ))}
      </Container>
    </Scroller>
  )
}

export default SelectorUnderline
