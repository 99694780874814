import React, { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import { ReactComponent as DownArrowSvg } from '@/assets/ui/down-arrow.svg'
import Icon from '@/components/Icon'

export const countryCodeOptions = [
  {
    key: 'hk',
    label: '+852',
    value: '+852',
  },
  {
    key: 'marcu',
    label: '+853',
    value: '+853',
  },

  {
    key: 'cn',
    label: '+86',
    value: '+86',
  },
]

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 2.667rem;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
  border-radius: 0.44rem;
  padding: 0 0.889rem;
  background: white;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;

  &:hover {
    border-color: #f7ce55;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`

const Field = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const CountryCodeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.6rem;
`

const CountryCode = styled.div`
  flex: 1;
  min-width: 2.333rem;
  font-size: 0.722rem;
  text-align: right;
  margin-right: 4px;
`

const Input = styled.input`
  flex: 1;
  border: 0;
  margin: 0;
  padding: 0;
  padding-left: 0.6rem;
  border-left: 1px solid #ddd;
  font-size: 0.778rem;
  line-height: 3em;
  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    width: '3.222rem',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.16)',
  }),
  option: (provided, state) => ({
    ...provided,
    height: 48,
    backgroundColor: state.isSelected ? '#FFFAEA' : 'white',
    fontSize: '0.778rem',
    color: '#1c1c1c',
    textAlign: 'right',
    padding: '12px 12px',
    cursor: 'pointer',
  }),
}

const InputField = React.forwardRef(
  (
    {
      style,
      className,
      defaultOption = countryCodeOptions[0],
      options = countryCodeOptions,
      placeholder,
      countryCode,
      phoneNumber,
      disabled,
      onChange,
      error,
    },
    ref
  ) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const valueLabel =
      options.find((o) => o.value === countryCode)?.label || defaultOption.label

    const onClickField = () => {
      setIsExpanded((s) => !s)
    }

    const onChangeSelect = (e) => {
      setIsExpanded(false)
      onChange({
        countryCode: e.value,
        phoneNumber,
      })
    }

    const onBlurSelect = () => {
      setIsExpanded(false)
    }

    const onChangePhoneNumber = (e) => {
      onChange({
        countryCode: countryCode || '+852',
        phoneNumber: e.target.value,
      })
    }

    return (
      <Container
        style={style}
        className={className}
        disabled={disabled}
        error={error}
      >
        <Field>
          <CountryCodeContainer onClick={onClickField}>
            <CountryCode>{valueLabel}</CountryCode>
            <Icon
              renderImage={() => <DownArrowSvg />}
              width="1.111rem"
              height="1.111rem"
            />
          </CountryCodeContainer>
          <Input
            placeholder={placeholder}
            value={phoneNumber}
            onChange={onChangePhoneNumber}
          />
        </Field>
        <Select
          ref={ref}
          components={{ Control: () => null }}
          styles={selectStyles}
          isLoading={false}
          isSearchable={false}
          cacheOptions
          defaultOptions
          onChange={onChangeSelect}
          onBlur={onBlurSelect}
          options={options}
          menuIsOpen={isExpanded}
        />
      </Container>
    )
  }
)

export default InputField
