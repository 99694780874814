import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { tt } from '@/locales/format'

import CompletedStatus from './CompletedStatus'
import InputDatePicker from './DateInput'

const Container = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eff0f1;
  cursor: pointer;
  gap: 1rem;

  @media (min-width: 480px) {
    padding: 1rem;
    gap: 1rem;
  }
`

const Content = styled.div`
  width: 78%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 80%;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding-left: 0.2rem;
`

const SubTitle = styled.div`
  font-size: 16px;
  margin-top: 0.2rem;
`

const WarningText = styled.div`
  margin-top: 0.2rem;
  padding-left: 0.2rem;
  color: red;
`

const DateOfBirthVerify = ({ form }) => {
  const intl = useIntl()
  const { watch, setValue } = form

  const checkVerify = (date) => {
    if (date) {
      return 'verified'
    }
    return 'unverified'
  }

  const checkInvalidDate = (date) => {
    const dob = date !== '' && moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
    if (dob === 'Invalid date') {
      return true
    }
    return false
  }

  return (
    <>
      <Container>
        <Content>
          <Title>{tt(intl, 'signup.profile.dob')}</Title>
          <SubTitle>
            <InputDatePicker
              dateInit={watch('DOB')}
              setValue={setValue}
              isInvalidDate={checkInvalidDate(watch('DOB'))}
            />
            <WarningText>
              {checkInvalidDate(watch('DOB')) && tt(intl, 'error.invalid.date')}
            </WarningText>
          </SubTitle>
        </Content>
        <CompletedStatus
          status={checkVerify(watch('DOB') && !checkInvalidDate(watch('DOB')))}
        />
      </Container>
    </>
  )
}

export default DateOfBirthVerify
