import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import ReceiptSchedule from '@/components/ReceiptSchedule'
import Spacer from '@/components/Spacer'
import * as Order from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

import {
  DivitFee,
  IconMoreInfo,
  PurchaseInfoContainer,
  PurchaseInfoItem,
  PurchaseInfoTotalContainer,
  PurchaseInfoValue,
  PurchaseSummaryBody,
} from './Receipt.styled'

const Container = styled.div`
  font-size: 0.778rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 16px;
`

const DivitFeeTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  &.place-bottom {
    border-radius: 8px;
    width: 18.75rem;
    padding: 1rem;
  }
`

const TooltipContend = styled.div`
  width: 100%;
  background-color: #d7dade;
  color: black;
  font-size: 0.875rem;
`

const Receipt = ({ order }) => {
  const intl = useIntl()
  const [showSchedule, setShowSchedule] = useState(true)

  if (!order) return <></>

  const { serviceFee } = order
  const isShowPurchase = serviceFee.amount > 0
  const totalAmount = Order.getTotalOutstandingAmount(order)

  return (
    <Container>
      {/* {isShowPurchase && (
        <> */}
      <PurchaseSummaryBody>
        <PurchaseInfoContainer>
          <PurchaseInfoItem>
            {intl.formatMessage({ id: 'paylater.landing.purchase' })}
          </PurchaseInfoItem>
          <PurchaseInfoValue>
            {FormattedPrice(
              order?.totalAmount?.currency,
              order?.totalAmount?.amount
            )}
          </PurchaseInfoValue>
          {order?.serviceFee?.amount > 0 && (
            <>
              <DivitFee>
                {intl.formatMessage({ id: 'paylater.landing.divitfee' })}{' '}
                <IconMoreInfo data-tip="divit-fee" data-for="divit-fee" />
              </DivitFee>
              <DivitFeeTooltip
                id="divit-fee"
                place="bottom"
                type="dark"
                offset="{'right': 120}"
                backgroundColor="#d7dade"
                arrowColor="none"
              >
                <TooltipContend>
                  {intl.formatMessage({ id: 'receipt.serivce.fee.tooltips' })}
                </TooltipContend>
              </DivitFeeTooltip>
              <PurchaseInfoValue>
                {FormattedPrice(
                  order?.serviceFee?.currency,
                  order?.serviceFee?.amount
                )}
              </PurchaseInfoValue>
            </>
          )}
        </PurchaseInfoContainer>
        {order?.serviceFee?.amount > 0 && (
          <>
            <Spacer height="1.5rem" />
            <PurchaseInfoTotalContainer showSchedule={showSchedule}>
              <PurchaseInfoItem>
                {intl.formatMessage({ id: 'payment.ordertotal' })}
              </PurchaseInfoItem>
              <PurchaseInfoValue>
                {FormattedPrice(totalAmount.currency, totalAmount.amount)}
              </PurchaseInfoValue>
            </PurchaseInfoTotalContainer>
          </>
        )}
        <ReceiptSchedule
          order={order}
          showSchedule={showSchedule}
          setShowSchedule={setShowSchedule}
        />
      </PurchaseSummaryBody>
      {/* </>
      )} */}
    </Container>
  )
}

export default Receipt
