// steps = [4, 4] | [3, 4, 4]
const format = ({ tel = '', countryCode = '', steps = [] }) => {
  let str = tel
  const ret = []
  ret.push(countryCode)
  if (tel.indexOf(countryCode) === 0) {
    str = str.substring(countryCode.length)
  }
  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i]
    ret.push(str.substring(0, step))
    str = str.substring(step)
  }
  return ret
}

const countryCodeList = [
  {
    countryCode: '+852',
    region: 'Hong Kong',
    length: 8,
    format: (tel) =>
      format({ tel, countryCode: '+852', steps: [4, 4] }).join(' '),
  },
  {
    countryCode: '+853',
    region: 'Macau',
    length: 8,
    format: (tel) =>
      format({ tel, countryCode: '+853', steps: [4, 4] }).join(' '),
  },
  {
    countryCode: '+86',
    region: 'PRC',
    length: 11,
    format: (tel) =>
      format({ tel, countryCode: '+86', steps: [3, 4, 4] }).join(' '),
  },
]

/**
 * Parser
 * @param {string} phone
 * @returns {object} phoneObject
 */

const Parser = (phone = '') => {
  const defaultCountryCodeModel = {
    countryCode: null,
    region: null,
    format: () => '',
  }

  const target =
    countryCodeList.find(({ countryCode }) => phone.includes(countryCode)) ||
    defaultCountryCodeModel
  const { countryCode, region } = target
  const localNumber = target ? phone.replace(countryCode, '') : phone

  return {
    region,
    countryCode,
    localNumber,
    formatted: target.format(phone),
  }
}

export const parsePhoneNumber = (phoneNumber = '') => {
  let countryCode = ''

  if (phoneNumber.indexOf('+') !== 0) {
    const foundCountryCode = countryCodeList.filter(
      (s) => s.length === phoneNumber.length
    )
    if (foundCountryCode) {
      countryCode = foundCountryCode.countryCode
    }
  }

  const parsedTel = Parser(phoneNumber)

  return {
    ...parsedTel,
    countryCode: countryCode || parsedTel.countryCode,
  }
}

export const isValidatePhoneNumber = (phoneNumber = '') => {
  if (phoneNumber.indexOf('+') !== 0) {
    const foundCountryCode = countryCodeList.filter(
      (s) => s.length === phoneNumber.length
    )[0]
    return !!foundCountryCode
  }

  const foundCountryCode = countryCodeList.filter(
    (s) => phoneNumber.indexOf(s.countryCode) === 0
  )[0]
  if (foundCountryCode) {
    return (
      foundCountryCode.length ===
      phoneNumber.replace(foundCountryCode.countryCode, '').length
    )
  }

  return false
}

export default Parser
