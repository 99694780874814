import { createSlice } from '@reduxjs/toolkit'

import { milesLinkageStorage } from '@/utils/localstorage'
import * as localStore from '@/utils/localStore'

const getLastLoginTs = () => parseInt(localStore.getLastLogin(), 10)

const initialState = {
  customerID: localStore.getCustomerID(),
  lastLoginTs: getLastLoginTs(),
  profile: null,
  addresses: [],
  settings: {},
  purchaseStatus: null,
  paymentAccount: null,
  eDDAs: [],
  divitMiles: [],
  divitTickets: 0,
  divitMilesMemberships: [],
  divitMilesTranscations: {
    all: { total: 0, data: [] },
    earn: { total: 0, data: [] },
    spend: { total: 0, data: [] },
  },
  campaignMissions: [],
  milesLinkage: milesLinkageStorage.get(),
  giftCardBrands: [],
  giftCards: [],
  giftCardsTotal: 0,
  services: [],
  identityProcess: null,
  identity: null,
  loading: false,
  patching: false,
  error: null,
}

const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCustomerID: (state, action) => {
      state.customerID = action.payload.customerID
    },
    setLastLoginTs: (state, action) => {
      state.lastLoginTs = action.payload.lastLoginTs
    },
    updateGiftCard: (state, action) => {
      const index = state.giftCards.findIndex(
        (g) => g.giftcardID === action.payload.giftcardID
      )
      state.giftCards[index] = action.payload
    },
    insertGiftCards: (state, action) => {
      const { total, data } = action.payload
      state.giftCards = state.giftCards.concat(data)
      state.giftCardsTotal = total
    },
    reset: () => initialState,
    getProfileStart: (state) => {
      state.loading = true
      state.error = null
    },
    getProfileSuccess: (state, action) => {
      state.profile = action.payload
      state.loading = false
      state.error = null
    },
    getProfileFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    getProfileIdentityProcessStart: (state) => {
      state.loading = true
      state.error = null
    },
    getProfileIdentityProcessSuccess: (state, action) => {
      state.identityProcess = action.payload
      state.loading = false
      state.error = null
    },
    getProfileIdentityProcessFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    getProfileIdentityStart: (state) => {
      state.loading = true
      state.error = null
    },
    getProfileIdentitySuccess: (state, action) => {
      state.identity = action.payload
      state.loading = false
      state.error = null
    },
    getProfileIdentityFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    patchProfileStart: (state) => {
      state.patching = true
      state.error = null
    },
    patchProfileSuccess: (state) => {
      state.patching = false
      state.error = null
    },
    patchProfileFailed: (state, action) => {
      state.patching = false
      state.error = action.payload
    },
    getProfileAddressesSuccess: (state, action) => {
      state.addresses = action.payload || {}
    },
    getProfileSettingsSuccess: (state, action) => {
      state.settings = action.payload || {}
    },
    updateSocialMediaLinkage: (state, action) => {
      state.profile.socialMediaLinkage = action.payload
    },
    updateEmailStart: (state) => {
      state.patching = true
      state.error = null
    },
    updateEmailSuccess: (state) => {
      state.patching = false
      state.error = null
    },
    updateEmailFailed: (state, action) => {
      state.patching = false
      state.error = action.payload
    },
    updateContactMethodStart: (state) => {
      state.patching = true
      state.error = null
    },
    updateContactMethodSuccess: (state) => {
      state.patching = false
      state.error = null
    },
    updateContactMethodFailed: (state, action) => {
      state.patching = false
      state.error = action.payload
    },
    getPurchaseStatusSuccess: (state, action) => {
      state.purchaseStatus = action.payload
    },
    getDivitMilesSuccess: (state, action) => {
      state.divitMiles = action.payload
    },
    getDivitTicketsSuccess: (state, action) => {
      state.divitTickets = action.payload
    },
    insertDivitMilesTransactions: (state, action) => {
      const { type, data, total } = action.payload
      const list = state.divitMilesTranscations[type].data
      state.divitMilesTranscations[type].data = list.concat(data)
      state.divitMilesTranscations[type].total = total
    },
    getDivitMilesTransactionsSuccess: (state, action) => {
      const { type, data, total } = action.payload
      state.divitMilesTranscations[type].data = data
      state.divitMilesTranscations[type].total = total
    },
    getDivitMilesMembershipSuccess: (state, action) => {
      state.divitMilesMemberships = action.payload
    },
    getCampaignMissions: (state, action) => {
      state.campaignMissions = action.payload
    },
    addMilesLinkage: (state, action) => {
      const linkage = state.milesLinkage.concat([action.payload])
      state.milesLinkage = Array.from(new Set(linkage))
      milesLinkageStorage.set(state.milesLinkage)
    },
    resetMilesLinkage: (state) => {
      state.milesLinkage = []
      milesLinkageStorage.set('')
    },
    getGiftCardBrandsSuccess: (state, action) => {
      state.giftCardBrands = action.payload
    },
    getGiftCardsSuccess: (state, action) => {
      state.giftCards = action.payload.giftCards
      state.giftCardsTotal = action.payload.giftCardsTotal
    },
    getProfileServicesSuccess: (state, action) => {
      state.services = action.payload
    },
    getProfilePaymentAccountSuccess: (state, action) => {
      state.paymentAccount = action.payload
    },
    getProfileEDDAAccountSuccess: (state, action) => {
      state.eDDAs = action.payload
    },
  },
})

export const { getProfileStart, getProfileSuccess, getProfileFailed } =
  ProfileSlice.actions

export default ProfileSlice.reducer
