import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

export const H1 = styled.h1`
  font-size: 1.25rem;
  margin: 0.78125rem 0;
`

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 1.33rem;
  margin: 0.875rem 0;
`

export const H3 = styled.h3`
  font-size: 0.75rem;
  margin: 0.46875rem 0;
`

export const H4 = styled.h4`
  font-size: 0.5rem;
  margin: 0.3125rem 0;
`

export const DottedLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => rgba(theme.dash, 1)} 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 8px 1px;
`

export const HR = DottedLine

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.6);
  background-size: 8px 1px;
`

const HRWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9333rem;

  > span {
    margin: 0 10px;
    font-weight: 700;
  }
`

export const DottedHR = ({ children }) => (
  <HRWrapper>
    <DottedLine />
    <span>{children}</span>
    <DottedLine />
  </HRWrapper>
)

export const LineHR = ({ children }) => (
  <HRWrapper>
    <Line />
    <span>{children}</span>
    <Line />
  </HRWrapper>
)

export const StraightLine = styled.div`
  display: block;
  width: 2px;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => rgba(theme.dash, 1)} 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 1px 10px;
`

export const StraightLineStrong = styled.div`
  display: block;
  width: 2px;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => rgba(theme.dashStrong, 1)} 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 1px 10px;
`

export const Button = styled.button`
  background-color: ${({ secondary, theme }) =>
    secondary ? theme.button.secondary : theme.button.active};
  color: #ffffff;
  font-weight: 700;
  font-size: ${({ size }) => size || '1rem'};
  letter-spacing: 0.2px;
  width: ${({ type }) => (type === 'stretch' ? '100%' : 'auto')};
  border-radius: 1.25rem;
  border: none;
  padding: ${({ type }) =>
    type === 'stretch' ? '0.75rem 1rem' : '0.5rem 1rem'};
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 0.3s;

  &:active:enabled,
  &:hover:enabled {
    opacity: 0.9;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.button.disabled};
  }
`

export const P = styled.p`
  margin: 0;
  font-size: 0.75rem;
`

export const A = styled.a`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.anchor};
`

export const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  display: inline-block;

  color: ${({ error, theme }) => (error ? theme.message.error : 'unset')};
`

export const Input = styled.input`
  width: calc(100% - 1.35rem - 2px);
  height: 2rem;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.message.error : theme.border)};
  // margin: 0.25rem 0 0.5rem;
  padding: 0 0.675rem;
  font-size: 1rem;

  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`

export const Sup = styled.sup`
  font-size: 0.625rem;
`
