/**
 * Modelized addresss object from HK gov
 * Reference: https://data.gov.hk/en-data/dataset/hk-ogcio-st_div_02-als
 * @param {object} data
 * @param {locale} prefix - Chi,Eng
 * @returns {object} humanizedAddress
 * @returns {string} humanizedAddress.building
 * @returns {string} humanizedAddress.street
 * @returns {string} humanizedAddress.district
 * @returns {string} humanizedAddress.region
 */

const capitalized = (text) =>
  text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

const Modelized = (data, locale) => {
  const prefix = locale === 'zh-hk' || locale === 'zh-cn' ? 'Chi' : 'Eng'
  const premisesAddressKey = `${prefix}PremisesAddress`
  const blockKey = `${prefix}Block`
  const buildingKey = 'BuildingName'
  const streetKey = `${prefix}Street`
  const estateKey = `${prefix}Estate`
  const villageKey = `${prefix}Village`
  const districtKey = `${prefix}District`
  const regionKey = 'Region'

  return data.SuggestedAddress.map((suggestedAddress) => {
    // 1st level
    const premisesAddress =
      suggestedAddress.Address.PremisesAddress[premisesAddressKey]

    // 2nd level
    const {
      [blockKey]: block = {},
      [buildingKey]: building = '',
      [streetKey]: street = {},
      [estateKey]: estate = {},
      [villageKey]: village = {},
      [districtKey]: district = {},
      [regionKey]: region = '',
    } = premisesAddress

    // 3rd level
    const { BlockDescriptor = '', BlockNo = '' } = block
    const { BuildingNoFrom = '', LocationName = '', StreetName = '' } = street
    const {
      BuildingNoFrom: VillageBuildingNoFrom = '',
      LocationName: VillageLocationName = '',
      VillageName = '',
    } = village
    const { EstateName = '' } = estate
    const { DcDistrict = '' } = district

    // prepare return data
    const streetInfoArray =
      locale === 'zh-hk' || locale === 'zh-cn'
        ? [StreetName, BuildingNoFrom, BuildingNoFrom && '號']
            .map((item) => item)
            .join('')
        : [BuildingNoFrom, capitalized(StreetName)]
            .map((item) => item)
            .join(' ')
    const villageInfoArray =
      locale === 'zh-hk' || locale === 'zh-cn'
        ? [
            VillageName,
            VillageLocationName,
            VillageBuildingNoFrom,
            VillageBuildingNoFrom && '號',
          ]
            .map((item) => item)
            .join('')
        : [
            VillageBuildingNoFrom,
            capitalized(VillageLocationName),
            capitalized(VillageName),
          ]
            .map((item) => item)
            .join(' ')
    const combinedInfoArray = [
      streetInfoArray,
      capitalized(EstateName),
      villageInfoArray,
      capitalized(LocationName),
    ].filter((item) => !!item.trim())

    return {
      building:
        (locale === 'zh-hk' || locale === 'zh-cn'
          ? [BlockNo, BlockDescriptor].join('')
          : [capitalized(BlockDescriptor), BlockNo]
              .filter((item) => !!item.trim())
              .join(' ')) || capitalized(building),
      street:
        locale === 'zh-hk' || locale === 'zh-cn'
          ? combinedInfoArray.reverse().join('')
          : combinedInfoArray.join(', '),
      district: capitalized(DcDistrict),
      region,
    }
  })
}

const Flatten = (modelizedAddress, locale) =>
  modelizedAddress.map((address) => {
    const { building, street, district, region } = address

    const addressInfoArray = [building, street, district, region].filter(
      (item) => !!item.trim()
    )

    return locale === 'zh-hk' || locale === 'zh-cn'
      ? addressInfoArray.reverse().join('')
      : addressInfoArray.join(', ')
  })

export const joinAddress = (arr) => arr.filter((s) => s).join(', ')

export default {
  Modelized,
  Flatten,
}
