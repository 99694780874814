import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import BrandGrid from '@/components/shop/BrandGrid'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { goBack } from '@/redux/app/appActions'

const Container = styled.div``

const BrandGroupSection = styled.div``

const BrandGroupTitle = styled.div`
  font-size: 1.111rem;
  font-weight: 700;
`

const ShopBrands = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const brands = useSelector((s) => s.shop.brands)

  const brandGroups = useMemo(
    () =>
      brands.reduce((group, b) => {
        b.categories.forEach((cat) => {
          group[cat.id] = group[cat.id] || []
          group[cat.id].push(b)
        })
        return group
      }, {}),
    [brands]
  )

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initShop' },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  const viewBrand = (b) => {
    window.open(b.page, '_blank')
  }

  return (
    <Container>
      <PaddingContent>
        <Spacer height="2.222rem" />
        <Title onClickBack={onClickBack}>
          {intl.formatMessage({ id: 'shop.shopbybrand' })}
        </Title>
        <Spacer height="1.778rem" />
        {Object.keys(brandGroups).map((key) => {
          const brandsInGroup = brandGroups[key]
          const title = brandsInGroup?.[0].categories?.[0].en || ''
          return (
            <BrandGroupSection key={key}>
              <BrandGroupTitle>{title}</BrandGroupTitle>
              <Spacer height="0.889rem" />
              <BrandGrid brands={brandsInGroup} onClickItem={viewBrand} />
              <Spacer height="1.778rem" />
            </BrandGroupSection>
          )
        })}
        <Spacer height="4.444rem" />
      </PaddingContent>
    </Container>
  )
}

export default ShopBrands
