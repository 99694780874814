import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const setupEDDASlice = createSlice({
  name: 'setupEDDA',
  initialState: {
    initEDDA: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.initEDDA = AsyncState.reset()
    },
    initEDDAStart: (state) => {
      state.initEDDA = AsyncState.start()
    },
    initEDDASuccess: (state, action) => {
      state.initEDDA = AsyncState.success(action.payload)
    },
    initEDDAFailed: (state, action) => {
      state.initEDDA = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = setupEDDASlice

export default setupEDDASlice.reducer
