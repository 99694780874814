import moment from 'moment/moment'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import { StraightLine } from '@/components/Divit'
import InstalmentIcon from '@/components/paylater/InstalmentIcon'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import * as Instalments from '@/utils/Instalments'
import { FormattedPrice } from '@/utils/Price'

import ArrowDownIcon from './icons/ArrowDown'
import ArrowUpIcon from './icons/ArrowUp'
import { IconMoreInfo } from './Receipt/Receipt.styled'

const Container = styled.div``

const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

const Schedules = styled.div`
  display: flex;
  flex-direction: column;
`

const Schedule = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: ${({ active }) => (active ? 600 : 'normal')};
`

const ScheduleLeft = styled.div`
  position: relative;
  margin-top: 0.889rem;
`

const ScheduleRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.667rem;
`

const ScheduleStraightLine = styled(StraightLine)`
  position: absolute;
  left: 0.95rem;
  margin-top: 3px;
  height: 12px;
  background: ${(p) =>
    p.isActive
      ? '#000'
      : 'linear-gradient(rgb(204, 204, 204) 50%, rgb(204, 204, 204) 0%)'};
`

const ScheduleIcon = styled(InstalmentIcon)`
  background: white;
  transform: translateX(0.111rem);
`

const ScheduleNumber = styled.div``

const ScheduleAmount = styled.div``

const ShowmoreButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  color: black;
  margin-top: 0.5rem;
`

const TodayContent = styled.div`
  display: flex;
  gap: 8px;
`

const ArrowUpIconStyled = styled(ArrowUpIcon)`
  margin-right: 0.4rem;
`

const ArrowDownStyled = styled(ArrowDownIcon)`
  margin-right: 0.4rem;
`

const DivitFeeTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  &.place-bottom {
    border-radius: 8px;
    width: 18.75rem;
    padding: 1rem;
  }
`
const TooltipContend = styled.div`
  width: 100%;
  background-color: #d7dade;
  color: black;
  font-size: 0.875rem;
  font-weight: normal;
`

const ReceiptSchedule = ({ order, showSchedule, setShowSchedule }) => {
  const intl = useIntl()

  const { instalments } = order
  const useUpSpend =
    instalments[0]?.amount?.amount !== instalments[1]?.amount?.amount

  return (
    <>
      {showSchedule && (
        <Container>
          <Spacer height="1.5rem" />
          <Title>{intl.formatMessage({ id: 'receipt.instalment' })}</Title>
          <Schedules>
            {instalments.map((instalment, i) => {
              const instalmentAmount = Instalments.getTotalAmount(instalment)
              return (
                <Schedule key={i} active={i === 0}>
                  <ScheduleLeft>
                    <ScheduleIcon
                      isActive={i === 0}
                      series={i + 1}
                      width="1.8rem"
                      height="1.8rem"
                    />
                    {i !== instalments.length - 1 && (
                      <ScheduleStraightLine isActive={i === 0} />
                    )}
                  </ScheduleLeft>
                  <Spacer width="0.889rem" />
                  <ScheduleRight>
                    <ScheduleNumber>
                      {i === 0 && (
                        <>
                          <TodayContent>
                            {tt(intl, 'schedule.today')}{' '}
                            {useUpSpend && (
                              <IconMoreInfo
                                data-tip="today-instalment"
                                data-for="today-instalment"
                              />
                            )}
                          </TodayContent>
                          {useUpSpend && (
                            <DivitFeeTooltip
                              id="today-instalment"
                              place="bottom"
                              type="dark"
                              offset="{'right': 120}"
                              backgroundColor="#d7dade"
                              arrowColor="none"
                            >
                              <TooltipContend>
                                {intl.formatMessage({
                                  id: 'receipt.fee.tooltips',
                                })}
                              </TooltipContend>
                            </DivitFeeTooltip>
                          )}
                        </>
                      )}
                      {i > 0 &&
                        moment()
                          .add(1 * i, 'month')
                          .format('LLLL')}
                    </ScheduleNumber>
                    <Spacer width="auto" />
                    <ScheduleAmount>
                      {FormattedPrice(
                        instalmentAmount.currency,
                        instalmentAmount.amount
                      )}
                    </ScheduleAmount>
                  </ScheduleRight>
                </Schedule>
              )
            })}
          </Schedules>
          <Spacer height="1.5rem" />
        </Container>
      )}
      <ShowmoreButton onClick={() => setShowSchedule(!showSchedule)}>
        {showSchedule ? <ArrowUpIconStyled /> : <ArrowDownStyled />}
        {showSchedule ? tt(intl, 'show.less') : tt(intl, 'show.more')}
      </ShowmoreButton>
    </>
  )
}

export default ReceiptSchedule
