import React from 'react'
import styled from 'styled-components'

import Icon from '@/components/Icon'

const ButtonContainer = styled.div`
  width: 100%;
  height: 2.667rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #dedede;
  border-radius: 26px;
  cursor: pointer;
`

const ButtonIcon = styled(Icon)`
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.278rem;
`

const ButtonText = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 0.778rem;
  text-align: center;
  margin-right: 2.389rem; /* left icon */
`

const LoginButton = ({ iconProps, text, ...props }) => (
  <ButtonContainer {...props}>
    <ButtonIcon {...iconProps} />
    <ButtonText>{text}</ButtonText>
  </ButtonContainer>
)

export default LoginButton
