import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as ArrowMoreSvg } from '@/assets/common/arrow-more.svg'
import { ReactComponent as OverdueAlertSvg } from '@/assets/paylater/overdue-alert.svg'
import Icon from '@/components/Icon'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import * as InstalmentUtils from '@/utils/Instalments'
import * as OrderUtils from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div``

const List = styled.div``

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.889rem 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);

  &:hover {
    cursor: pointer;
  }
`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const OrderMerchaneLogo = styled.div``

const OrderInstalment = styled.div`
  font-size: 0.555rem;
`

const OrderMerchantName = styled.div`
  font-size: 0.667rem;
  font-weight: 600;
`

const Date = styled.div`
  display: flex;
  align-items: center;
`

const DateText = styled.div`
  font-size: 0.66rem;
  font-weight: 500;
  color: ${(p) => (p.isOverdue ? '#E6171A' : '#979797')};
`

const PayButton = styled(Button)`
  font-weight: 700;
  min-width: unset;
  height: auto;
  background-color: #1c1c1c;
  color: white;
  font-size: 0.555rem;
  padding: 0.444rem 1.111rem;
`

const PaylaterOutstandingOrderList = ({ orders, onPay, onClickItem }) => {
  const intl = useIntl()

  const onClickPay = (order) => (e) => {
    e.stopPropagation()
    onPay(order)
  }

  return (
    <Container>
      <List>
        {orders.map((order) => {
          const instalment = InstalmentUtils.getOutstandingInstalment(
            order.instalments
          )
          const outstandingAmount =
            InstalmentUtils.getTotalOutstandingAmount(instalment)
          const merchantRef = OrderUtils.getPartnerRef(order)
          const isOverdue = OrderUtils.isOverdue(order)

          return (
            <ListItem key={order.orderid} onClick={() => onClickItem(order)}>
              <OrderMerchaneLogo>
                <MerchantLogo
                  merchantID={order.partnerid}
                  width="2.2rem"
                  height="2.2rem"
                />
              </OrderMerchaneLogo>
              <Spacer width="0.444rem" />
              <Left>
                <OrderInstalment>
                  {intl.formatMessage({
                    id: `instalment.${instalment.series}`,
                  })}
                </OrderInstalment>
                <Spacer height="0.222rem" />
                <OrderMerchantName>
                  <MerchantName merchantID={order.partnerid} />
                  <span>{` ${merchantRef}`}</span>
                </OrderMerchantName>
                <Spacer height="0.222rem" />
                <Date>
                  <DateText isOverdue={isOverdue}>
                    {tt(intl, 'payment.due')}{' '}
                    {moment.unix(instalment.dueDate).format('LLL')}
                  </DateText>
                  <Spacer width="0.444rem" />
                  {isOverdue && (
                    <Icon
                      renderImage={() => <OverdueAlertSvg />}
                      wrapperWidth="1rem"
                      wrapperHeight="0.87rem"
                      width="1rem"
                      height="0.87rem"
                    />
                  )}
                </Date>
              </Left>
              <Spacer width="1.111rem" />
              <Right>
                <PayButton onClick={onClickPay(order)}>
                  {tt(intl, 'payment.pay')}{' '}
                  {FormattedPrice(
                    outstandingAmount.currency,
                    outstandingAmount.amount
                  )}
                </PayButton>
              </Right>
              <Spacer width="0.444rem" />
              <Icon
                renderImage={() => <ArrowMoreSvg />}
                width="1.111rem"
                height="1.111rem"
              />
            </ListItem>
          )
        })}
      </List>
    </Container>
  )
}

export default PaylaterOutstandingOrderList
