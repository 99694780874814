import  React from "react"

const PlaneLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#939599"
      d="m12.404 4.457 4.119-1.92a1.818 1.818 0 0 1 1.537 3.295L5.7 11.594.924 7.803l2.472-1.152 2.416.88 2.472-1.153-4.337-5L6.42.226l5.985 4.231Z"
    />
  </svg>
)
export default PlaneLogo
