import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import useCountDown from '@/hooks/useCountdown'

const Timer = styled.span`
  display: inline-block;
  font-weight: 700;
`

const addSeconds = (seconds) => moment().add(seconds, 'seconds')

const CountDownLabel = ({ timeoutInSec, onTimeout }) => {
  const [timeoutTime, setTimeoutTime] = useState(addSeconds(timeoutInSec + 1))
  const { countDownInSec } = useCountDown({ timeoutInSec, onTimeout })

  useEffect(() => {
    setTimeoutTime(addSeconds(timeoutInSec + 1))
  }, [timeoutInSec])

  if (!timeoutInSec) return null

  const hours = parseInt(countDownInSec / 60 / 60, 10)
  const minutes = parseInt((countDownInSec % 3600) / 60, 10)
  const seconds = countDownInSec % 60

  let msg = ''

  if (hours >= 3) {
    msg = timeoutTime.calendar()
  } else {
    const hourStr = `${hours}`.padStart(2, 0)
    const minuteStr = `${minutes}`.padStart(2, 0)
    const secondStr = `${seconds}`.padStart(2, 0)

    msg =
      hours > 0
        ? `${hourStr}:${minuteStr}:${secondStr}`
        : `${minuteStr}:${secondStr}`
  }

  return <Timer>{msg}</Timer>
}

export default CountDownLabel
