import moment from 'moment'

import { KYC_STATUS, KYC_TYPE } from '@/constants/kyc'

export const getLatestKycType = (kyc = []) => {
  if (kyc.length === 0) return KYC_TYPE.FULL
  return kyc.slice().sort((a, b) => {
    const m1 = moment(a.updatedAt)
    const m2 = moment(b.updatedAt)
    return m1.isBefore(m2) ? 1 : -1
  })[0].type
}

export const getKycByType = (kyc = [], type) => kyc.find((x) => x.type === type)

// canBypass determine ui should show or bypass kyc screen
export const canBypassKyc = (kyc = [], type, isForce = false) => {
  const fullKyc = kyc.find((x) => x.type === KYC_TYPE.FULL)
  const partialKyc = kyc.find((x) => x.type === KYC_TYPE.PARTIAL)

  if (fullKyc?.status === KYC_STATUS.GREEN) {
    return true
  }

  if (
    type === 'partial' &&
    ((partialKyc?.status === KYC_STATUS.REVIEWING && !isForce) ||
      partialKyc?.status === KYC_STATUS.GREEN)
  ) {
    return true
  }

  return false
}

export const isFobiddenKyc = (kyc = []) => {
  const fullKyc = kyc.find((x) => x.type === KYC_TYPE.FULL)
  const partialKyc = kyc.find((x) => x.type === KYC_TYPE.PARTIAL)

  if ([KYC_STATUS.RED, KYC_STATUS.BLACK].indexOf(fullKyc?.status) >= 0) {
    return true
  }

  if ([KYC_STATUS.RED, KYC_STATUS.BLACK].indexOf(partialKyc?.status) >= 0) {
    return true
  }

  return false
}

export const isResubmitRequestedKyc = (kyc = []) => {
  const fullKyc = kyc.find((x) => x.type === KYC_TYPE.FULL)
  const partialKyc = kyc.find((x) => x.type === KYC_TYPE.PARTIAL)

  if ([KYC_STATUS.AMBER].indexOf(fullKyc?.status) >= 0) {
    return true
  }

  if ([KYC_STATUS.AMBER].indexOf(partialKyc?.status) >= 0) {
    return true
  }

  return false
}

// add selfie only
export const isAdditionalSelfie = (kyc = []) => {
  const fullKyc = kyc.find((x) => x.type === KYC_TYPE.FULL)
  const partialKyc = kyc.find((x) => x.type === KYC_TYPE.PARTIAL)

  return (
    partialKyc?.status === KYC_STATUS.GREEN &&
    (!fullKyc || fullKyc?.status === KYC_STATUS.AMBER)
  )
}
