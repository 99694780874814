import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DivitMilesBwSvg } from '@/assets/miles/divit-miles-logo-bordered.svg'
import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-yellow-icon.svg'
import MilesPrograms from '@/constants/MilesPrograms'
import { FormattedMiles } from '@/utils/Price'

import Icon from '../Icon'

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 2px;
`

const Text = styled.div`
  font-size: ${(p) => p.fontSize};
  font-weight: 700;
  margin-left: 5px;
`

const MilesProgramValue = ({
  program,
  miles,
  fontSize = '0.778rem',
  type = 'yellow',
}) => {
  const targetProgram = MilesPrograms.find((p) => p.key === program)
  const ProgramIcon =
    program === 'divit'
      ? type === 'bw'
        ? DivitMilesBwSvg
        : DivitMilesSvg
      : targetProgram.Icon

  return (
    <Container inline>
      <Icon
        renderImage={() => <ProgramIcon />}
        width={fontSize}
        height={fontSize}
      />
      <Text fontSize={fontSize}>{FormattedMiles(miles)}</Text>
    </Container>
  )
}

export default MilesProgramValue
