import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'

import Icon from './Icon'
import Modal from './Modal'
import Spacer from './Spacer'

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100%;
  margin: 0 auto;
  padding: 0 2.5rem;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
`

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  background-color: #f5f6f7;
  border-radius: 8px;
`

const ContenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-size: 1.425rem;
  font-weight: 700;
`

const OverduePaymentContainer = styled.div`
  padding: 1.4rem;
  border-radius: 8px;
  background-color: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  margin: 0;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin: 0;
  }
`

const CloseButton = styled.div`
  cursor: pointer;
  margin-right: -16px;
`
const OverduePaymentTitle = styled.div`
  font-weight: 700;
`
const OverduePaymentSubTitle = styled.div`
  width: 100%;
`

const AvailableSpendWarningButtonGroup = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PayButton = styled(Button)`
  font-size: 1rem;
  height: 2.625rem;
  width: 100%;
`

const FreezedAccountModal = ({ isOpen, onClickPaynow, onClose }) => {
  const intl = useIntl()

  return (
    <Modal isOpen={isOpen} isLock>
      <Container>
        <Content>
          <ContenHeader>
            <Title>{tt(intl, 'order.freezedAccountModal.title')}</Title>
            <CloseButton onClick={onClose}>
              <Icon
                renderImage={() => <CloseSvg />}
                width="2.66rem"
                height="2.66rem"
              />
            </CloseButton>
          </ContenHeader>
          <Spacer height="1rem" />
          <OverduePaymentContainer>
            <OverduePaymentTitle>
              {tt(intl, 'order.freezedAccountModal.overdue.title')}
            </OverduePaymentTitle>
            <Spacer height="1rem" />

            <OverduePaymentSubTitle>
              {tt(intl, 'order.freezedAccountModal.overdue.subTitle')}
            </OverduePaymentSubTitle>
          </OverduePaymentContainer>
          <Spacer height="1rem" />
          <AvailableSpendWarningButtonGroup>
            <PayButton onClick={onClickPaynow}>
              {intl.formatMessage({
                id: 'button.pay.now',
              })}
            </PayButton>
          </AvailableSpendWarningButtonGroup>
        </Content>
      </Container>
    </Modal>
  )
}

export default FreezedAccountModal
