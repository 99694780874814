import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-logo.svg'
import { FormattedMiles } from '@/utils/Price'

// used in payment details

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  color: #000099;
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`

const Desc = styled.div`
  margin-left: 0.2rem;
`

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Right = styled.div`
  text-align: right;
`

const MilesEarnedRow = ({ miles }) => {
  const intl = useIntl()
  return (
    <Container>
      <Left>
        <Logo>
          <DivitMilesSvg />
        </Logo>
        <Desc>{intl.formatMessage({ id: 'miles.earned' })}</Desc>
      </Left>
      <Right>
        <div>{`+${FormattedMiles(miles)}`}</div>
      </Right>
    </Container>
  )
}

export default MilesEarnedRow
