import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import PaymentSettings from '@/constants/payment'
import useInterval from '@/hooks/useInterval'
import { tt } from '@/locales/format'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import scrollToTop from '@/utils/scrollToTop'

const MANUAL_INACTIVE_INTERVAL = 1.5 * 60 * 1000 // 1.5 minute

const useFps = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [fpsPaymentType, setFpsPaymentType] = useState(
    PaymentSettings.PAYMENT_MODE_FPSQRCODE
  )
  const [inactivity, setInactivity] = useState(false)

  const onSwitchFpsPaymentType = async (method) => {
    setFpsPaymentType(method)
    await scrollToTop(true)
    if (method === PaymentSettings.PAYMENT_MODE_FPSMANUAL) {
      dispatch(
        promptConfirmModal({
          title: tt(intl, 'fps.manual.prompt.title'),
          content: tt(intl, 'fps.manual.prompt.note'),
          okText: tt(intl, 'common.understand'),
          closable: false,
        })
      )
    }
  }

  // interval check inactive
  const clearManualInactiveTimer = useInterval(
    async () => {
      // if (fpsPaymentType === PaymentSettings.PAYMENT_MODE_FPSMANUAL) {
      //   const { isOk } = await dispatch(
      //     promptConfirmModal({
      //       content: tt(intl, 'fps.manual.promptcode6inactive'),
      //       okText: tt(intl, 'common.cs'),
      //       cancelText: tt(intl, 'common.backtopage'),
      //     })
      //   )
      //   if (isOk) {
      //     // contact cs
      //   }
      // }
      setInactivity(true)
    },
    MANUAL_INACTIVE_INTERVAL,
    [fpsPaymentType]
  )

  return {
    fpsPaymentType,
    inactivity,
    setFpsPaymentType,
    clearManualInactiveTimer,
    onSwitchFpsPaymentType,
  }
}

export default useFps
