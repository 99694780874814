import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as MilesGiftCardSvg } from '@/assets/home/gift.svg'
import { ReactComponent as MilesSpendMerchantSvg } from '@/assets/home/spend-merchant.svg'
import urls from '@/constants/urls'
import { tt } from '@/locales/format'

const Container = styled.div`
  padding: 2rem 1.5rem;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  background-color: #fff;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`
const Item = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`

const ItemTitle = styled.div`
  font-weight: 700;
  letter-spacing: 0.03em;
`

const MilesGiftCard = styled(MilesGiftCardSvg)`
  width: 2.4rem;
  height: auto;
  margin-left: 0.3rem;
`

const MilesSpendMerchant = styled(MilesSpendMerchantSvg)`
  width: 2.4rem;
  height: auto;
  margin-left: 0.3rem;
`

const MilesAction = () => {
  const intl = useIntl()

  const controls = [
    {
      key: 'giftcards',
      Icon: MilesSpendMerchant,
      title: tt(intl, 'landing.milesAction.shopMerchant'),
      path: urls.en.merchantsUrl,
    },
    {
      key: 'spend',
      Icon: MilesGiftCard,
      title: tt(intl, 'landing.milesAction.spentOnGiftcards'),
      path: '/miles/spend',
    },
  ].filter((s) => s)

  const history = useHistory()

  const goPath = (control) => () => {
    if (control.path.startsWith('/')) {
      history.push(control.path)
    } else {
      window.open(control.path)
    }
  }

  return (
    <Container>
      <Row>
        {controls.map((control) => (
          <Item key={control.key} onClick={goPath(control)}>
            <control.Icon />
            <ItemTitle>{control.title}</ItemTitle>
          </Item>
        ))}
      </Row>
    </Container>
  )
}

export default MilesAction
