import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DivitTicketYellowSvg } from '@/assets/profile/profile-luckydraw-symbol.svg'
import { ReactComponent as DivitTicketBWSvg } from '@/assets/tickets/ticket-bw.svg'
import Icon from '@/components/Icon'
import { FormattedMiles } from '@/utils/Price'

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(p) => p.fontSize};
`

const PrefixText = styled.div`
  font-size: calc(${(p) => p.fontSize} * 0.7);
  font-weight: 700;
  margin-left: calc(${(p) => p.fontSize} * 0.5);
`

const Text = styled.div`
  &:first-child {
    margin-right: 4px;
  }
  &:last-child {
    margin-left: 0.5rem;
  }
`

const TicketsValue = ({
  style,
  className,
  type = 'yellow',
  amount,
  fontSize = '0.778rem',
  prefix = '',
}) => {
  const TicketIcon = type === 'bw' ? DivitTicketBWSvg : DivitTicketYellowSvg

  return (
    <Container style={style} className={className} fontSize={fontSize}>
      <Icon
        renderImage={() => <TicketIcon />}
        width={fontSize}
        height={fontSize}
      />
      {prefix && <PrefixText fontSize={fontSize}>{prefix}</PrefixText>}
      <Text>{FormattedMiles(Math.abs(amount * 100))}</Text>
    </Container>
  )
}

export default TicketsValue
