import DivitAPI from '@/services/api'
import * as Pagination from '@/utils/pagination'
import { sleep } from '@/utils/promises'

export const fetchProfile = () => async (dispatch) => {
  const { data } = await DivitAPI.get('profiles')
  dispatch({ type: 'profile/getProfileSuccess', payload: data.data })
}

export const fetchGiftCardsNextPage = ({ brandCode, active }) => async (
  dispatch,
  getState
) => {
  await sleep(1000)
  const { giftCards, giftCardsTotal } = getState().profile
  const hasMore = Pagination.hasMore(giftCards, giftCardsTotal)
  if (!hasMore) return
  const pageNo = Pagination.getNextPageNo(giftCards)
  const { data } = await DivitAPI.get('/giftcard/profiles/cards', {
    params: { brandCode, active, page: pageNo, pageSize: 25 },
  })
  dispatch({
    type: 'profile/insertGiftCards',
    payload: { total: data.count, data: data.data },
  })
}

export default 0
