export const getType = (lineItems = []) =>
  lineItems.length > 0 ? lineItems[0].orderType : ''

export const hasShowMore = (lineItems = []) => {
  const type = getType(lineItems)
  if (type === 'retail') {
    return lineItems.length > 2
  }
  return lineItems.length > 1
}

export const hasAnyPic = (lineItem) =>
  Object.keys(lineItem.pics ?? {}).length > 0

export const getPic = (lineItem, size = 'small') => (lineItem.pics ?? {})[size]

export default 0
