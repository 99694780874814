function scrollToTop(smooth = false) {
  if (smooth) {
    return new Promise((resolve) => {
      let timeout = null
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      const checkTop = () => {
        if (window.scrollY === 0) {
          clearTimeout(timeout)
          window.removeEventListener('scroll', checkTop)
          resolve()
        }
      }
      window.addEventListener('scroll', checkTop)
      timeout = setTimeout(() => {
        window.removeEventListener('scroll', checkTop)
        resolve()
      }, 1000)
    })
  }
  document.documentElement.scrollTop = 0
  return Promise.resolve()
}

export default scrollToTop
