import { push, replace } from 'connected-react-router'
import moment from 'moment'
import * as qs from 'query-string'
import { call, delay, put, select } from 'redux-saga/effects'

import { setupAccount } from '@/redux/auth/authActions'
import { actions as authActions } from '@/redux/auth/authSlice'
import * as lookupSagas from '@/redux/lookup/lookupSaga'
import { actions as fastSignupActions } from '@/redux/pages/fastSignupSlice'
import { profileSelector } from '@/redux/profile/profileSelector'
import { getError, getErrorMessage } from '@/redux/utils/error'
import routes from '@/routes'
import DivitAPI from '@/services/api'
import * as localStore from '@/utils/localStore'
import {
  findRouteByPath,
  getOrderIdParam,
  isCheckoutPath,
  isClaimMiles,
  IsFastPaynow,
  IsOrder,
  IsSignupReferralCodeLanding,
} from '@/utils/Route'

function* fetchProfile() {
  const { data } = yield call(async () => DivitAPI.get('profiles'))
  yield put({ type: 'profile/getProfileSuccess', payload: data.data })
  return data.data
}

function* setLoginData(data) {
  const { token, expire } = data
  if (token) {
    localStore.save(token, moment(expire).toDate())

    yield put({
      type: 'profile/setCustomerID',
      payload: { customerID: localStore.getCustomerID() },
    })
    yield put({
      type: 'profile/setLastLoginTs',
      payload: { lastLoginTs: Date.now() },
    })
  }
}

export function* setTokenCookie(action) {
  const { token, maxAge } = action?.payload
  yield call(() => DivitAPI.patch('profiles/session', { jwt: token, maxAge }))
}

export function* redirectToShop() {
  const params = {
    redirectUrl: `${process.env.REACT_APP_SHOP_URL}/?wc-api=DIVIT_AUTH_LOGIN`,
    scope: 'full',
  }
  const qss = qs.stringify(params)
  yield put(push(`/sso?${qss}`, { isDelayed: false }))
}

export function* redirectReturnUrlOrHome(action) {
  const { isSignup } = action?.payload || {}
  const returnUrl = sessionStorage.getItem('return_url') || '/home'
  sessionStorage.removeItem('return_url')
  sessionStorage.removeItem('fast_login_session')
  const pathname = yield select((s) => s.router.location.pathname)
  const isCheckout = isCheckoutPath(pathname)
  const mRef = sessionStorage.getItem('mRef')
  if (
    isSignup &&
    !isCheckout &&
    !!mRef &&
    mRef.indexOf(process.env.REACT_APP_SHOP_URL) >= 0
  ) {
    yield redirectToShop()
  } else {
    yield put(replace(returnUrl, { isAfterAuth: true }))
  }
}

export function* login({ payload }) {
  try {
    const { email, password, recapResp } = payload
    yield put({ type: 'auth/loginStart' })
    const formData = new FormData()
    formData.append('client_id', email)
    formData.append('client_secret', password)
    formData.append('recapResp', recapResp)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const { data } = yield call(() => DivitAPI.post('login', formData, config))

    yield setLoginData(data)

    // check role of enduser
    if (!localStore.isRoleAvailable()) {
      yield put({ type: 'auth/loginFailed', payload: 'forbidden' })
      yield put({ type: 'auth/logout' })
      return
    }
    yield put({ type: 'auth/loginSuccess', payload: data })
    const profile = yield fetchProfile()

    yield lookupSagas.fetchLookups()
    yield put({
      type: 'gtm/sendEvent',
      payload: {
        event: 'login',
        userId: profile.customerID,
        accountProvider: 'email',
      },
    })
    yield put(setupAccount({ isPrompt: true }))
    yield put(fastSignupActions.clearReferralCode())
  } catch (e) {
    yield put({
      type: 'auth/loginFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* logout({ payload }) {
  const { isNormalLogout = true } = payload || {}
  if (isNormalLogout) {
    DivitAPI.post('auth/logout')
  }
  const { profile } = yield select((s) => s.profile)
  yield put({
    type: 'gtm/sendEvent',
    payload: {
      event: 'logout',
      userId: (profile && profile.customerID) || '',
    },
  })
  localStore.remove()

  yield put({ type: 'order/reset' })
  yield put({ type: 'profile/reset' })
  yield put({ type: 'auth/logout' })
}

export function* loginWithSocialProvider({ payload }) {
  try {
    yield put({ type: 'auth/loginStart' })
    const { provider, accessToken, language, referralCode, metadata } = payload
    const mRef = sessionStorage.getItem('mRef') || ''
    const { data } = yield call(async () =>
      DivitAPI.post(`oauth/${provider}`, {
        accessToken,
        language,
        source: mRef,
        referralCode,
        metadata,
      })
    )
    const { isNewUser } = data
    yield setLoginData(data)
    yield put({ type: 'auth/loginSuccess', payload: data })
    const profile = yield fetchProfile()
    yield lookupSagas.fetchLookups()
    yield put({
      type: 'gtm/sendEvent',
      payload: {
        event: 'login',
        userId: profile.customerID,
        accountProvider: provider,
      },
    })
    yield put(setupAccount({ isSignup: isNewUser, isPrompt: false }))
    yield put(fastSignupActions.clearReferralCode())
  } catch (e) {
    yield put({
      type: 'auth/loginFailed',
      payload: getErrorMessage(e),
    })
  }
}

// used for checkout match social account with order's customerID
export function* verifyWithSocialProvider({ payload }) {
  try {
    const profile = yield select(profileSelector)
    const { customerID } = profile
    yield put(authActions.verifyWithSocialProviderStart())
    const { provider, accessToken, language } = payload
    const { data } = yield call(async () =>
      DivitAPI.post(`oauth/${provider}`, {
        accessToken,
        language,
      })
    )
    if (customerID !== localStore.getCustomerID()) {
      throw new Error('identity not match')
    }
    yield setLoginData(data)
    yield put(authActions.verifyWithSocialProviderSuccess(data))
  } catch (e) {
    yield put(authActions.verifyWithSocialProviderFailed(getError(e)))
  }
}

export function* clearSignUp() {
  yield put({ type: 'auth/clearSignUp' })
}

// trigger after refresh token
// TOFIX: too easy to forget if enter private route without login
// save return_url if current route is private
export function* tokenExpiredRedirect() {
  const { location } = yield select((s) => s.router)
  const { pathname, search } = location
  const currentRoute = findRouteByPath(routes, pathname)

  const isPrivateRoute = currentRoute && currentRoute.private
  if (isPrivateRoute) {
    sessionStorage.setItem('return_url', `${pathname}${search}`)
  }

  yield put({ type: 'logout', payload: { isNormalLogout: false } })
  yield delay(100)

  // routes that no need to redirect to login
  if (IsSignupReferralCodeLanding(pathname)) {
    return
  }
  if (IsFastPaynow(pathname)) {
    return
  }

  const isCheckoutStart = IsOrder(pathname)
  const isClaimMilesRoute = isClaimMiles()

  if (isCheckoutStart) {
    const orderId = getOrderIdParam(pathname)
    yield put(replace(`/orderpreview/${orderId}`))
  } else if (isClaimMilesRoute) {
    sessionStorage.setItem('return_url', `${pathname}${search}`)
    yield put(replace('/signin'))
  } else {
    yield put(replace('/signin'))
  }
}

export function* postSkipKyc({ payload }) {
  try {
    yield put({ type: 'auth/skipKycStart' })
    yield call(async () => DivitAPI.post('admin/customer', payload))
    yield put({ type: 'auth/skipKycSuccess' })
  } catch (e) {
    yield put({
      type: 'auth/skipKycFailed',
      payload: getErrorMessage(e),
    })
  }
}
