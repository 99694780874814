import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import AppleLoginButton from '@/components/auth/AppleLoginButton'
import FacebookLoginButton from '@/components/auth/FacebookLoginButton'
import GoogleLoginButton from '@/components/auth/GoogleLoginButton'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingModal from '@/components/LoadingModal'
import Spacer from '@/components/Spacer'
import PhoneInput from '@/components/ui/PhoneInput'
import Title from '@/components/ui/Title'
import { useAuth } from '@/contexts/authContext'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'
import { useLocale } from '@/contexts/localeContext'
import { Features, useFeature } from '@/hooks/useFeature'
import usePageError from '@/hooks/usePageError'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { Button, Input, LineHR } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import { validateEmail, validateTel } from '@/utils/validation'

const Container = styled.div`
  color: #1c1c1c;
`

const Hero = styled.div``

const HeroSubTitle = styled.div`
  max-width: 270px;
  margin: 0 auto;
  font-size: 0.78rem;
  text-align: center;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const FormColumn = styled.div`
  flex: ${({ flex }) => flex || 1};
  ${({ position }) => position && `text-align: ${position};`}

  &:not(:last-child) {
    margin-right: ${({ theme, noGutter }) =>
      noGutter ? '0' : `${theme.gutter}px`};
  }
`

const ContinueButton = styled(Button)`
  font-size: 0.89rem;
`

const HRRow = styled.div`
  /* width: 8.78rem; */
  width: 100%;
  margin: 0 auto;
`

const ReferralCodeVerifiedContainer = styled.div`
  font-size: 0.778rem;
  background-color: #13cc93;
  color: white;
  padding: 0.889rem;
  box-sizing: border-box;
  border-radius: 8px;
`

const ReferralCodeText = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`

const DivitMilesLogin = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentLocale } = useLocale()
  const { loginWithSocialProvider, authUserID } = useAuth()
  const { executeRecaptcha } = useCaptcha()
  const { featureEnabled } = useFeature()

  const [clientIdType] = useState('email')
  const [countryCode, setCountryCode] = useState('')
  const [clientId, setClientId] = useState('')
  const { errorMessage, updateErrorMessage } = usePageError()

  const loginLoading = useSelector((s) => s.auth.loading)
  const { referralCode, signup, checkReferralCode } = useSelector(
    (s) => s.fastSignup
  )

  const isLoading =
    signup.isLoading || loginLoading || checkReferralCode.isLoading

  useEffect(() => {
    if (referralCode) {
      dispatch({
        type: 'fastSignup/checkReferralCode',
        payload: { referralCode },
      })
    }
  }, [])

  useUpdateEffect(() => {
    if (clientIdType === 'tel') {
      setCountryCode('+852')
    } else {
      setCountryCode('')
    }
    setClientId('')
  }, [clientIdType])

  useEffect(() => {
    dispatch({ type: 'loginClear' })
  }, [])

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (authUserID) {
      history.replace('/home')
    }
  }, [])

  useEffect(() => () => dispatch({ type: 'fastSignup/reset' }), [])

  useEffect(() => {
    if (signup.isError) {
      if (signup.error.code === 6007) {
        dispatch({
          type: 'fastSignup/login',
          payload: {
            clientIdType,
            countryCode,
            clientId,
          },
        })
        history.push('/signin/password')
      } else {
        updateErrorMessage(intl.formatMessage({ id: 'error.action.login' }))
      }
    }
    if (signup.isSuccess) {
      history.push('/signup/verify-otp')
    }
  }, [signup])

  const onClickBack = () => {
    dispatch(goBack())
  }

  const validateForm = () => {
    if (clientIdType === 'email' && !validateEmail(clientId)) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.email' }))
      return false
    }
    if (clientIdType === 'tel' && !validateTel(countryCode, clientId)) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.mobile' }))
      return false
    }
    return true
  }

  const onClickContinue = async (e) => {
    e?.preventDefault()

    updateErrorMessage('')

    if (!validateForm()) {
      return
    }

    // try signup, if detected duplicated, then login

    let recapResp = ''

    try {
      recapResp = await executeRecaptcha('SIGN_UP')
    } catch (err) {
      console.error(err)
    }

    dispatch({
      type: 'fastSignup/signup',
      payload: {
        clientIdType,
        countryCode,
        clientId,
        language: currentLocale,
        recapResp,
      },
    })
  }

  const onSubmitForm = (e) => {
    e.preventDefault()
    onClickContinue()
  }

  const onClickAppleLogin = useCallback(() => {
    updateErrorMessage('')
    return true
  }, [])

  const onAppleSuccess = useCallback(
    (resp) => {
      const { accessToken } = resp
      loginWithSocialProvider({
        provider: 'apple',
        accessToken,
        language: currentLocale,
        referralCode,
        metadata: resp,
      })
    },
    [currentLocale, loginWithSocialProvider]
  )

  const onAppleFailure = useCallback((err) => {
    console.log(err)
    // setErrorMessage(err.error || JSON.stringify(err))
  }, [])

  const onClickFacebookLogin = useCallback(() => {
    updateErrorMessage('')
    return true
  }, [])

  const onFacebookSuccess = useCallback(
    (resp) => {
      const { accessToken } = resp
      loginWithSocialProvider({
        provider: 'facebook',
        accessToken,
        language: currentLocale,
        referralCode,
      })
    },
    [currentLocale, loginWithSocialProvider]
  )

  const onFacebookFailure = useCallback((err) => {
    console.log(err)
    // setErrorMessage(err.error || JSON.stringify(err))
  }, [])

  const onClickGoogleLogin = useCallback(() => {
    updateErrorMessage('')
    return true
  }, [])

  const onGoogleSuccess = useCallback(
    (resp) => {
      const { accessToken } = resp
      loginWithSocialProvider({
        provider: 'google',
        accessToken,
        language: currentLocale,
        referralCode,
      })
    },
    [currentLocale, loginWithSocialProvider]
  )

  const onGoogleFailure = useCallback((err) => {
    console.log(err)
    // setErrorMessage(err.error || JSON.stringify(err))
  }, [])

  const onClickEditReferralCode = () => {
    history.push('/signup/referralcode', { referralCode })
  }

  return (
    <Container>
      <PaddingContent>
        <Spacer height="2.222rem" />
        <Hero>
          <Title onClickBack={onClickBack}>
            {tt(intl, 'login.loginorsignup')}
          </Title>
          {checkReferralCode.isSuccess && (
            <>
              <Spacer height="1.333rem" />
              <ReferralCodeVerifiedContainer>
                {tt(intl, 'login.referralcode.verified')}
              </ReferralCodeVerifiedContainer>
            </>
          )}
          <Spacer height="0.889rem" />
          <HeroSubTitle>
            {clientIdType === 'tel'
              ? tt(intl, 'login.entermobilenumber')
              : tt(intl, 'login.enteremail')}
          </HeroSubTitle>
        </Hero>
        <Spacer height="0.665rem" />
        <ErrorMessageLine errorMessage={errorMessage} />
        <Spacer height="0.665rem" />
        <FacebookLoginButton
          onClickFacebookLogin={onClickFacebookLogin}
          onFacebookSuccess={onFacebookSuccess}
          onFacebookFailure={onFacebookFailure}
        />
        <Spacer height="0.889rem" />
        <GoogleLoginButton
          onClickGoogleLogin={onClickGoogleLogin}
          onGoogleSuccess={onGoogleSuccess}
          onGoogleFailure={onGoogleFailure}
        />
        <Spacer height="0.889rem" />
        <AppleLoginButton
          onClickAppleLogin={onClickAppleLogin}
          onAppleSuccess={onAppleSuccess}
          onAppleFailure={onAppleFailure}
        />
        <Spacer height="1.333rem" />
        <HRRow>
          <LineHR>or</LineHR>
        </HRRow>
        <Spacer height="1.333rem" />
        <Form onSubmit={onSubmitForm}>
          <FormRow>
            <FormColumn>
              {clientIdType === 'email' && (
                <Input
                  type="email"
                  id="email"
                  onChange={(e) => setClientId(e.target.value)}
                  placeholder={tt(intl, 'common.email')}
                />
              )}
              {clientIdType === 'tel' && (
                <PhoneInput
                  id="tel"
                  countryCode={countryCode}
                  phoneNumber={clientId}
                  onChange={(data) => {
                    setCountryCode(data.countryCode)
                    setClientId(data.phoneNumber)
                  }}
                  placeholder={tt(intl, 'common.mobilenumber')}
                />
              )}
            </FormColumn>
          </FormRow>
        </Form>
        {featureEnabled(Features.REFERRAL) && (
          <>
            <Spacer height="2.556rem" />
            <ReferralCodeText onClick={onClickEditReferralCode}>
              {checkReferralCode.isSuccess
                ? tt(intl, 'login.changereferralcode')
                : tt(intl, 'login.addreferralcode')}
            </ReferralCodeText>
          </>
        )}
        <Spacer height="1.333rem" />
        <ContinueButton
          type="stretch"
          disabled={!clientId}
          onClick={onClickContinue}
        >
          {tt(intl, 'common.continue')}
        </ContinueButton>
        <Spacer height="1.333rem" />
      </PaddingContent>
      <LoadingModal loading={isLoading} />
    </Container>
  )
}

export default (props) => (
  <CaptchaProvider>
    <DivitMilesLogin {...props} />
  </CaptchaProvider>
)
