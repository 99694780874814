import Axios from 'axios'

const HKGOV_REGION_MAP = {
  NT: 'NEW TERRITORIES',
  KLN: 'KOWLOON',
  HK: 'HONG KONG ISLAND',
}

function joinA(arr, sep) {
  return arr.filter((x) => x && x.trim()).join(sep)
}

function govAddressToDetails(data) {
  const id = data.Address.PremisesAddress.GeoAddress
  const address = data.Address.PremisesAddress.EngPremisesAddress

  const region = address.Region

  const buildingInfo = address.EngBlock || {}
  const buildingInfo2 = joinA(
    [buildingInfo.BlockDescriptor, buildingInfo.BlockNo],
    ' '
  )

  const streetInfo = address.EngStreet || {}
  const streetInfo2 = joinA(
    [streetInfo.BuildingNoFrom, streetInfo.StreetName],
    ' '
  )

  const villeageInfo = address.EngVillage || {}
  const villeageInfo2 = joinA(
    [
      villeageInfo.VillageBuildingNoFrom,
      villeageInfo.LocationName,
      villeageInfo.VillageName,
    ],
    ' '
  )

  const estateInfo = address.EngEstate || {}
  const estateInfo2 = estateInfo.EstateName

  const building = buildingInfo2
  const street = joinA(
    [streetInfo2, villeageInfo2, streetInfo.LocationName],
    ', '
  )
  const estate = joinA([estateInfo2, address.BuildingName], ', ')
  const district = address.EngDistrict.DcDistrict

  return {
    id,
    building: building || '',
    estate: estate || '',
    street: street || '',
    district: district || '',
    region: HKGOV_REGION_MAP[region] || '',
  }
}

function addressToLine(details) {
  return joinA(
    [
      details.building,
      details.estate,
      details.street,
      details.district,
      details.region,
    ],
    ', '
  )
}

export const fetchHKGovAddress = async (searchText, { limit = 10 } = {}) => {
  const { data } = await Axios.get(
    `https://www.als.ogcio.gov.hk/lookup?q=${searchText}&n=${limit}`,
    {
      headers: {
        'Accept-Language': 'en,zh-Hant',
      },
    }
  )
  const results = data.SuggestedAddress || []
  const addresses = results.map((addr) => {
    const details = govAddressToDetails(addr)
    return {
      id: details.id,
      line: addressToLine(details),
      details,
    }
  })
  return addresses
}

export default 0
