import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ClaimMilesBgSvg from '@/assets/miles/claim-miles-bg.svg'
import { ReactComponent as ClaimMilesLogoSvg } from '@/assets/miles/claim-miles-logo.svg'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import { useAuth } from '@/contexts/authContext'
import { CaptchaProvider } from '@/contexts/captchaContext'
import usePageError from '@/hooks/usePageError'
import useQueryString from '@/hooks/useQueryString'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import * as SessionStorage from '@/utils/sessionStorage'

const Container = styled.div`
  min-height: 100vh;
  background-image: url(${ClaimMilesBgSvg});
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #fffaea;
`

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
`

const GiftContainer = styled.div`
  width: 17.778rem;
  margin: 0 auto;
  padding: 2.333rem 1.556rem 1.333rem;
  background-color: white;
  box-sizing: border-box;
  border-radius: 0.444rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`

const GiftText = styled.div`
  font-size: 0.889rem;
  font-weight: 500;
  text-align: center;
`

const GiftMilesValue = styled(MilesValue)`
  justify-content: center;
  font-weight: 700;
`

const MerchantNameWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const MerchantName = styled.div`
  position: absolute;
  margin: auto;
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
`

const DivitMilesPreview = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname, search, state } = useLocation()
  const { token } = useParams()
  const { isLoggedIn } = useAuth()
  const { setup = '' } = useQueryString()
  const { previewClaim, claim } = useSelector((s) => s.claimDivitMiles)
  const { merchants } = useSelector((s) => s.lookup)
  const { errorMessage, updateErrorMessage } = usePageError()
  const [merchantName, setMerchantName] = useState('')

  useEffect(() => {
    if (token) {
      sessionStorage.setItem('is_claim_divit_miles', true)

      const payload = { token }
      if (setup) {
        payload.accessToken = setup
      }
      dispatch({ type: 'claimDivitMiles/previewClaim', payload })

      // directly claim after auth success
      if (state?.isAfterAuth) {
        dispatch({ type: 'claimDivitMiles/claim', payload: { token } })
      }
    }
    return () => dispatch({ type: 'claimDivitMiles/reset' })
  }, [])

  useEffect(() => {
    if (claim.isSuccess) {
      history.push('/profile/miles', {
        claimAmount: claim.data.awardedAmount.amount,
      })
    }
    if (claim.isError) {
      // error might be email, tel is not match with the token's
      updateErrorMessage(claim.error.message)
      sessionStorage.setItem('is_claim_divit_miles', false)
    }
  }, [claim])

  useEffect(() => {
    // save token data for later use
    if (previewClaim.isSuccess) {
      SessionStorage.saveJSON('claim_token', previewClaim.data)
    }
    if (previewClaim.isError) {
      sessionStorage.setItem('is_claim_divit_miles', false)
    }
  }, [previewClaim])

  useEffect(() => {
    if (
      previewClaim.isSuccess &&
      previewClaim.data &&
      merchants &&
      merchants.length > 0
    ) {
      const mer = merchants.filter(
        (m) => m.merchantID === previewClaim.data.merchantID
      )
      setMerchantName(mer?.[0]?.merchantName)
    }
  }, [merchants, previewClaim])

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onClickLogin = () => {
    history.push(`/signin`)
  }

  // wont happen as it does not match the route
  if (!token) {
    return (
      <Container>
        <ErrorMessageBox errorMessage="invalid token" />
      </Container>
    )
  }

  if (previewClaim.isLoading || claim.isLoading) return <LoadingScreen />
  if (previewClaim.isError) {
    return (
      <Container>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'miles.claim.title')}</Title>
          <Spacer height="1.333rem" />
          <GiftContainer>
            <Icon
              renderImage={() => <ClaimMilesLogoSvg />}
              width="13.278rem"
              height="9.389rem"
            />
            <Spacer height="0.889rem" />
            <GiftText>{tt(intl, 'claim.miles.msg')}</GiftText>
          </GiftContainer>
          <Spacer height="2rem" />

          <ErrorMessageBox errorMessage={previewClaim.error.message} />
          <Button onClick={onClickBack}>
            {intl.formatMessage({ id: 'button.back' })}
          </Button>
        </PaddingContent>
      </Container>
    )
  }

  const { data } = previewClaim
  if (!data) return <></>

  const onClickNext = () => {
    history.push(`/miles/setup/${token}?setup=${setup}`)
  }

  const onClickClaim = () => {
    updateErrorMessage('')

    if (!isLoggedIn) {
      sessionStorage.setItem('return_url', `${pathname}${search}`)

      history.push('/signup')
      return
    }
    dispatch({
      type: 'claimDivitMiles/claim',
      payload: { token: data.token },
    })
  }

  const claimButtonMsg = isLoggedIn
    ? 'miles.action.claim'
    : 'miles.action.signup.claim'

  return (
    <Container>
      <PaddingContent>
        <Spacer height="2.222rem" />
        <Title>{tt(intl, 'miles.claim.title')}</Title>
        <Spacer height="1.333rem" />
        <GiftContainer>
          <MerchantNameWrapper>
            <Icon
              renderImage={() => <ClaimMilesLogoSvg />}
              width="13.278rem"
              height="9.389rem"
            />
            <MerchantName>{merchantName}</MerchantName>
          </MerchantNameWrapper>
          <Spacer height="0.889rem" />
          <GiftText>{tt(intl, 'claim.miles.msg')}</GiftText>
          <Spacer height="0.444rem" />
          {data.ownerEmail && (
            <>
              <GiftText>{data.ownerEmail}</GiftText>
              <Spacer height="1rem" />
            </>
          )}
          <GiftMilesValue
            fontSize="2.222rem"
            miles={data.claimedAmount.amount}
            isSigned={false}
          />
        </GiftContainer>
        <Spacer height="1.111rem" />
        <ErrorMessageBox errorMessage={errorMessage} />
        <Spacer height="1.111rem" />
        {setup && data.claimedStatus !== 'claimed' && (
          <Button onClick={onClickNext}>{tt(intl, 'common.next')}</Button>
        )}
        {setup && data.claimedStatus === 'claimed' && (
          <Button onClick={onClickLogin}>
            {intl.formatMessage({ id: 'miles.claim.login' })}
          </Button>
        )}
        {!setup && (
          <Button onClick={onClickClaim}>{tt(intl, claimButtonMsg)}</Button>
        )}
      </PaddingContent>
    </Container>
  )
}

export default (props) => (
  <CaptchaProvider>
    <DivitMilesPreview {...props} />
  </CaptchaProvider>
)
