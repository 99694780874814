import React from 'react'
import styled from 'styled-components'

import BackButton from '@/components/BackButton'

export const Nav = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`

const CustomBackButton = styled(BackButton)`
  position: absolute;
  top: -0.444rem;
  left: 0;
`

const TitleTextContainer = styled.div`
  flex: 1;
  max-width: 90%;
  margin: 0 auto;
`

const TitleText = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: -0.444rem;
  text-align: center;
  font-weight: 700;
  font-size: ${(p) => p.fontSize};
  line-height: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TitleContainer = ({ onClickBack, children }) => (
  <Nav>
    {onClickBack && <CustomBackButton onClick={onClickBack} />}
    {children}
  </Nav>
)

const Title = ({ fontSize = '1.333rem', onClickBack, children }) => (
  <TitleContainer onClickBack={onClickBack}>
    <TitleTextContainer>
      <TitleText fontSize={fontSize}>{children}</TitleText>
    </TitleTextContainer>
  </TitleContainer>
)

export default Title
