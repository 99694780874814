import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 2.222rem;
  border-radius: 50px;
  border: 1px solid #f0f0f0;
  padding: 4px;
  box-sizing: border-box;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`

const Bg = styled.div`
  position: absolute;
  width: ${({ total }) => `${(1 / total) * 100}%`};
  height: 100%;
  top: 0;
  left: ${({ total, activeIndex }) => `${(activeIndex / total) * 100}%`};
  transition: left 0.3s;
  background-color: rgba(255, 204, 51, 1);
  border-radius: 50px;
  box-shadow: 0 1px 2px rgba(155, 120, 0, 0.16);
`

const SelectorTab = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.fontSize};
  font-weight: 700;
  color: #1c1c1c;
  padding: 12px 0px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`

export const Tab = styled.div`
  width: 100%;
  height: ${({ isActive }) => (isActive ? 'auto' : 0)};
  overflow: hidden;
`

const SelectorTabs = ({ defaultTab, tabs, onChange, fontSize = '0.7rem' }) => {
  const defaultTabIndex = tabs.findIndex((t) => t.key === defaultTab)
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex)

  const onSelectTab = (index) => {
    setActiveTabIndex(index)
    onChange?.(tabs[index])
  }

  return (
    <Wrapper>
      <Container>
        <Bg total={tabs.length} activeIndex={activeTabIndex} />
        {tabs.map((tab, i) => (
          <SelectorTab
            key={i}
            onClick={() => onSelectTab(i)}
            fontSize={fontSize}
          >
            {tab.label}
          </SelectorTab>
        ))}
      </Container>
    </Wrapper>
  )
}

export default SelectorTabs
