import Axios from 'axios'
import moment from 'moment'
import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { actions as appActions } from './appSlice'

export function* updateRoute({ payload }) {
  yield put(appActions.updateRoute(payload))
}

export function* fetchConfig() {
  const cacheTimer =
    process.env.REACT_APP_API_PLATFORM === 'divit-dev'
      ? moment().unix()
      : (moment().unix() / 300).toFixed()
  const { data } = yield call(Axios.get, [
    `${process.env.REACT_APP_STATIC_URL}/configs/consumer-web/${process.env.REACT_APP_API_PLATFORM}-features.json?t=${cacheTimer}`,
  ])
  yield put({ type: 'app/fetchConfigSuccess', payload: data })
}

export function* fetchMiscConfigs({ payload }) {
  const { configType, language } = payload
  const { data } = yield call(async () =>
    DivitAPI.get('/service/config', { params: { type: configType, language } })
  )
  yield put({ type: 'app/fetchMiscConfigSuccess', payload: data })
}

export function* fetchCountryList() {
  const cacheTimer =
    process.env.REACT_APP_API_PLATFORM === 'divit-dev'
      ? moment().unix()
      : (moment().unix() / 3000).toFixed()
  const { data } = yield call(Axios.get, [
    `${process.env.REACT_APP_STATIC_URL}/configs/consumer-web/countrylist.json?t=${cacheTimer}`,
  ])
  yield put({ type: 'app/fetchCountryListSuccess', payload: data })
}

export default 0
