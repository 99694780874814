import { call, put, select } from 'redux-saga/effects'

import { profileSelector } from '@/redux/profile/profileSelector'
import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as setupEDDAActions } from './setupEDDASlice'

export function* initEDDA() {
  try {
    yield put(setupEDDAActions.initEDDAStart())
    const profile = yield select(profileSelector)
    const { data } = yield call(() =>
      DivitAPI.post('/payment/account/initedda', {
        platform: process.env.REACT_APP_API_PLATFORM,
        customerID: profile.customerID,
      })
    )
    yield put(setupEDDAActions.initEDDASuccess(data))
  } catch (e) {
    yield put(setupEDDAActions.initEDDAFailed(getError(e)))
  }
}

export default null
