import { useDebounceFn } from 'ahooks'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import ReactSelect from 'react-select'

import useBlurElement from '@/hooks/useBlurElement'
import { tt } from '@/locales/format'
import { fetchHKGovAddress } from '@/utils/GovAddress'

const defaultSelectStyles = {
  menu: (provided) => ({
    ...provided,
    fontSize: '0.778rem',
  }),
  control: (provided) => ({
    ...provided,
  }),
  placeholder: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0.675rem',
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
  }),
}

export const useAddressSearch = () => {
  const [searchText, setSearchText] = useState('')

  const searchAddress = (str) => {
    setSearchText(str)
  }

  const cancelSearchAddress = () => {
    setSearchText('')
  }

  const onAddressSearched = (fn) => (result) => {
    fn(result)
  }

  return { searchText, searchAddress, cancelSearchAddress, onAddressSearched }
}

const AddressSearch = ({
  className,
  selectStyles,
  searchText,
  onChange,
  children,
  optionAddress = true,
}) => {
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [suggestedAddresses, setSuggestedAddresses] = useState([])
  const selectedAddress = useRef()

  useBlurElement('.address-search-field', () => onClose())

  const { run: runSearchAddress } = useDebounceFn(async (searchAddr) => {
    try {
      if (!searchAddr) {
        setIsExpanded(false)
        return
      }
      setIsExpanded(true)
      const addresses = await fetchHKGovAddress(searchAddr)
      setSuggestedAddresses(addresses)
      setIsLoading(false)
    } catch (ex) {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    const isEmpty = !searchText
    const isSame = selectedAddress.current?.details.building === searchText
    if (!isEmpty && isSame) return
    if (!isEmpty) {
      setIsExpanded(true)
    }
    setIsLoading(true)
    setSuggestedAddresses([])
    runSearchAddress(searchText)
  }, [searchText])

  const onClose = () => {
    setIsExpanded(false)
    setIsLoading(false)
    setSuggestedAddresses([])
  }

  const onChangeSelect = (v) => {
    onClose()
    selectedAddress.current = v?.value
    onChange(v)
  }

  const onBlurSelect = () => {
    onClose()
  }

  const options = suggestedAddresses.map((addr) => ({
    label: addr.line,
    value: addr,
  }))

  return (
    <>
      {children}
      {optionAddress && (
        <ReactSelect
          className={classNames('address-search-field', className)}
          components={{ Control: () => null }}
          styles={selectStyles || defaultSelectStyles}
          isLoading={isLoading}
          isSearchable={false}
          cacheOptions
          defaultOptions
          onChange={onChangeSelect}
          onBlur={onBlurSelect}
          options={options}
          menuIsOpen={isExpanded}
          placeholder={tt(intl, 'common.searchaddress.placeholder')}
          loadingMessage={() => tt(intl, 'common.loading')}
          noOptionsMessage={() => tt(intl, 'common.searchaddress.notfound')}
        />
      )}
    </>
  )
}

export default AddressSearch
