import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const SSOSlice = createSlice({
  name: 'SSO',
  initialState: {
    generateOAuthToken: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.generateOAuthToken = AsyncState.reset()
    },
    generateOAuthTokenStart: (state) => {
      state.generateOAuthToken = AsyncState.start()
    },
    generateOAuthTokenSuccess: (state, action) => {
      state.generateOAuthToken = AsyncState.success(action.payload)
    },
    generateOAuthTokenFailed: (state, action) => {
      state.generateOAuthToken = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = SSOSlice

export default SSOSlice.reducer
