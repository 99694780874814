import React from 'react'

const RightArrowIcon = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33289 8.5H12.6662"
      stroke={props?.color ? props?.color : '#FC3'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99951 11.1667L12.6662 8.5"
      stroke={props?.color ? props?.color : '#FC3'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99951 5.83331L12.6662 8.49998"
      stroke={props?.color ? props?.color : '#FC3'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default RightArrowIcon
