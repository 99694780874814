import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'
import { actions as verifyPhoneActions } from './verifyPhoneSlice'

export function* sendOTP({ payload }) {
  try {
    yield put(verifyPhoneActions.sendOTPStart())
    yield call(() => DivitAPI.post('verify/phone/sendOTP', payload))
    yield put(verifyPhoneActions.sendOTPSuccess())
  } catch (e) {
    yield put(verifyPhoneActions.sendOTPFailed(getErrorMessage(e)))
  }
}

export function* verifyOTP({ payload }) {
  try {
    yield put(verifyPhoneActions.verifyOTPStart())
    yield call(() => DivitAPI.post('verify/phone', payload))
    yield put({ type: 'fetchProfile' })
    yield put(verifyPhoneActions.verifyOTPSuccess())
  } catch (e) {
    yield put(verifyPhoneActions.verifyOTPFailed(getErrorMessage(e)))
  }
}
