import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import BottomSheet from '@/components/BottomSheet'
import CenteredContent from '@/components/layout/CenteredContent'
import * as CSLayout from '@/components/layout/CSLayout'
import LoadingModal from '@/components/LoadingModal'
import PinError from '@/components/pin/PinError'
import PinInput from '@/components/pin/PinInput'
import Spacer from '@/components/Spacer'
import usePinInput from '@/hooks/usePinInput'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { startForgotPinFlow } from '@/redux/pin/pinActions'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  padding: 0 1.11rem;
  box-sizing: border-box;
  background-color: white;
`

const LeftNavButton = styled.div`
  position: absolute;
  top: 2.222rem;
  left: calc(100% / 12);
  padding: 0.27rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const CancelText = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
`

const Content = styled.div`
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  max-width: 15.333rem;
  margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
`

const LoginAgainButton = styled(Button)`
  background-color: black;
  color: white;
`

const PinErrorScreen = ({ onClickLogin }) => {
  const intl = useIntl()

  return (
    <Container>
      <CSLayout.CSContainer>
        <CSLayout.CSContent>
          <CenteredContent>
            <PinError />
          </CenteredContent>
        </CSLayout.CSContent>
        <CSLayout.CSFooter>
          <LoginAgainButton type="stretch" onClick={onClickLogin}>
            {tt(intl, 'common.loginagain')}
          </LoginAgainButton>
        </CSLayout.CSFooter>
      </CSLayout.CSContainer>
    </Container>
  )
}

const PinRequestModal = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const { pin, setPin, resetPin, pinError, setPinError } = usePinInput()

  const [isReLoginError, setIsReLoginError] = useState(false)

  const { requestModal, authPin, validatePin } = useSelector((s) => s.pin)
  const { isOpen, isAuth } = requestModal

  const { isLoading } = validatePin

  const reset = () => {
    resetPin()
  }

  const onClose = () => {
    const isSuccess = authPin.isSuccess || validatePin.isSuccess
    const result = { isSuccess, pin }
    dispatch({ type: 'pin/closeRequestModal' })
    setTimeout(() => {
      requestModal.callback?.(result)
      dispatch({ type: 'pin/resetRequestModal' })
      reset()
    }, 500)
  }

  useUpdateEffect(() => {
    if (!requestModal.isOpen) {
      dispatch({ type: 'pin/reset' })
    }
  }, [requestModal.isOpen])

  useEffect(() => {
    if (validatePin.isSuccess) {
      onClose()
    }
    if (validatePin.isError) {
      // token expired error
      if (validatePin.error?.code === 2005) {
        setIsReLoginError(true)
      } else {
        setPinError(true)
      }
    }
  }, [validatePin])

  useEffect(() => {
    if (authPin.isSuccess) {
      onClose()
    }
    if (authPin.isError) {
      // token expired error
      if (authPin.error?.code === 2005) {
        setIsReLoginError(true)
      } else {
        setPinError(true)
      }
    }
  }, [authPin])

  const onFinishPin = (newPin) => {
    if (isAuth) {
      dispatch({ type: 'pin/authPin', payload: { pin: newPin } })
    } else {
      dispatch({ type: 'pin/validatePin', payload: { pin: newPin } })
    }
  }

  const onModalLeave = () => {}

  const onForgotPin = async () => {
    onClose()
    const { isSuccess } = dispatch(startForgotPinFlow())
    if (isSuccess) {
      dispatch(
        ToastActions.addToast({ message: tt(intl, 'profile.pin.updated') })
      )
    }
  }

  const onClickCancel = () => {
    onClose()
  }

  const goLogin = () => {
    onClose()
    history.push('/signin')
  }

  const renderMainScreen = () => (
    <Container>
      <LeftNavButton onClick={onClickCancel}>
        <CancelText>{tt(intl, 'common.cancel')}</CancelText>
      </LeftNavButton>
      <Content>
        <Spacer height="2.222rem" />
        <Title>{tt(intl, 'profile.enterpin')}</Title>
        <Spacer height="2.222rem" />
        <PinInput
          pin={pin}
          hasError={!!pinError}
          onChangePin={setPin}
          onFinishPin={onFinishPin}
          onForgotPin={onForgotPin}
        />
      </Content>
      <Spacer height="2.222rem" />
    </Container>
  )

  return (
    <BottomSheet
      fullscreen
      open={isOpen}
      onDismiss={onClose}
      onLeave={onModalLeave}
    >
      {!isReLoginError && renderMainScreen()}
      {isReLoginError && <PinErrorScreen onClickLogin={goLogin} />}
      <LoadingModal loading={isLoading} />
    </BottomSheet>
  )
}

export default PinRequestModal
