import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import LoadingSpinner from './LoadingSpinner'
import Spacer from './Spacer'

const Container = styled.div`
  margin-top: 1.25rem;
`

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
  margin: 0.889rem 0;
`

const Content = styled.div`
  max-width: 375px;
  font-size: 0.778rem;
  text-align: center;
  margin: 0 auto;
`

const LoadingScreen = ({ title, content }) => {
  const [showLoading, setShowLoading] = useState(false)

  // show after short period of time to avoid spark
  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 500)
    return () => clearTimeout(timer)
  }, [])

  return (
    showLoading && (
      <Container>
        <LoadingSpinner />
        <Spacer height="1.333rem" />
        {title && <Title>{title}</Title>}
        {content && <Content>{content}</Content>}
      </Container>
    )
  )
}

export default LoadingScreen
