import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'

export function* sendCsForm({ payload }) {
  try {
    yield put({ type: 'csmodal/sendStart' })
    const { data } = yield call(async () => {
      const body = new FormData()
      body.append('name', payload.name.trim())
      body.append('email', payload.email.trim())
      body.append('tel', payload.tel.trim())
      body.append('content', payload?.content || '')
      body.append('orderID', payload?.orderID || '')
      body.append('customerID', payload?.customerID || '')
      body.append('orderType', payload?.orderType || '')
      body.append('path', payload?.path || '')
      if (payload.files && payload.files?.length > 0) {
        Array.prototype.forEach.call(payload.files, (f) => {
          body.append('files', f)
        })
      }
      return DivitAPI.post('ticket/cs', body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    })
    yield put({ type: 'csmodal/sendSuccess', payload: data.data })
  } catch (e) {
    yield put({
      type: 'csmodal/sendFailed',
      payload: getErrorMessage(e),
    })
  }
}

export default 0
