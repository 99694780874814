import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { merchantsSelector } from '@/redux/lookup/lookupSelector'
import { AirlineNameByPartnerId } from '@/utils/Airline'

const Wrap = styled.span``

const MerchantName = ({ merchantID }) => {
  const intl = useIntl()

  const images = useSelector(merchantsSelector)

  // miles merchant
  if (merchantID === 'asiamiles') {
    return <Wrap>Asia Miles</Wrap>
  }
  if (merchantID === 'youair') {
    return <Wrap>Youair Miles</Wrap>
  }

  // retails merchant
  const merchant = images.find((m) => m.merchantID === merchantID)
  if (merchant) return <Wrap>{merchant.merchantName}</Wrap>

  // airline merchant
  return <Wrap>{AirlineNameByPartnerId(intl, merchantID)}</Wrap>
}

export default MerchantName
