import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import DivitSvg from '@/assets/divit-black.svg'
import { ReactComponent as MenuBtnSvg } from '@/assets/menu/menu-yellow-btn.svg'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import {
  IsFastPaynow,
  IsLanding,
  IsPaylatering,
  IsPaynowCheckout,
} from '@/utils/Route'

import SideBar from './SideBar'

const Wrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  box-shadow: 0 1px 1px #eee;
  display: flex;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
`

export const Container = styled.div`
  width: 100%;
  height: 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
  max-width: 1440px;
  padding: 0.8rem 1.2rem 0.8rem;
`

const DivitLogo = styled.img`
  width: 3.6rem;
  align-self: flex-start;
`

const Panel = styled.div`
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`

const ProfileSettingButton = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg {
    height: auto;
    & path {
      stroke: ${({ color }) => color};
    }
  }
`

const LoginButton = styled(Button)`
  min-width: unset;
  height: 1.778rem;
  font-size: 0.778rem;
  background-color: white;
`

const SignUpButton = styled(Button)`
  min-width: unset;
  height: 1.778rem;
  font-size: 0.778rem;
  margin-right: 1rem;
`

const MenuBtnIcon = styled(MenuBtnSvg)`
  &:hover,
  &:active {
    color: #1c1c1c;

    & path {
      stroke: #1c1c1c;
    }
  }
`

const ModeMap = {
  light: {
    divitLogo: DivitSvg,
    menuColor: '#ffcc33',
    bgColor: 'white',
  },
  dark: {
    divitLogo: DivitSvg,
    menuColor: 'black',
    bgColor: '#ffcc33',
  },
}

const Header = ({ headerMode = 'light' }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const isLanding = IsLanding(pathname)
  const isPaylatering = IsPaylatering(pathname)
  const isFastPaynow = IsFastPaynow(pathname) || IsPaynowCheckout(pathname)

  const mode = ModeMap[headerMode]
  const onClickLogo = () => {
    if (!isPaylatering && !isFastPaynow) {
      history.push('/')
    }
  }

  const onClickLogin = () => {
    history.push('/signin')
  }

  const onClickSignUp = () => {
    history.push('/signup')
  }

  const onClickMenu = () => {
    dispatch({ type: 'app/showSideBar' })
  }

  return (
    <Wrapper bgColor={mode.bgColor}>
      <Container bgColor={mode.bgColor}>
        <DivitLogo
          onClick={onClickLogo}
          src={mode.divitLogo}
          alt="Divit Logo"
        />
        <Panel>
          <RightWrapper>
            {isLanding && (
              <>
                <LoginButton onClick={onClickLogin}>
                  {tt(intl, 'common.login')}
                </LoginButton>
                <SignUpButton onClick={onClickSignUp}>
                  {tt(intl, 'common.signup')}
                </SignUpButton>
              </>
            )}
            <ProfileSettingButton
              className="profile-menu-btn"
              color={mode.menuColor}
              onClick={() => {
                onClickMenu()
              }}
            >
              <MenuBtnIcon />
            </ProfileSettingButton>
          </RightWrapper>
        </Panel>
        <SideBar />
      </Container>
    </Wrapper>
  )
}

Header.propTypes = {}

Header.defaultProps = {}

export default Header
