import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import ScheduleReviewPaylater from '@/components/ScheduleReviewPaylater'
import ScheduleReviewPaynow from '@/components/ScheduleReviewPaynow'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'

const Container = styled(PaddingContent)`
  background-color: #fff;
`

const ScheduleReview = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { orderId } = useParams()
  const { pathname } = useLocation()

  const isPaynow = pathname.indexOf('/paynow') >= 0

  const { order, orderMiles } = useSelector((state) => state.order)

  const initActionType = isPaynow
    ? 'pageInit/initPaynowOrder'
    : 'pageInit/initOrder'

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: initActionType, payload: { orderId } },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  const orderid = order.orderid || order.orderID
  if (orderid !== orderId) return <></>

  const onClickBack = () => {
    dispatch(goBack())
  }

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>{tt(intl, 'payment.details')}</Title>
      <Spacer height="1.778rem" />
      {isPaynow ? (
        <ScheduleReviewPaynow order={order} orderMiles={orderMiles} />
      ) : (
        <ScheduleReviewPaylater order={order} orderMiles={orderMiles} />
      )}
      <Spacer height="2rem" />
    </Container>
  )
}

export default ScheduleReview
