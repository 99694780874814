import moment from 'moment'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import CenteredContent from '@/components/layout/CenteredContent'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import { ActionButtons, PrimaryButton } from '@/components/ui/Shared'
import { tt } from '@/locales/format'
import { getFpsServiceSelector } from '@/redux/lookup/lookupSelector'
import { promptCustomerServiceModal } from '@/redux/modal/csModalActions'
import { formatFullDateTimeWithDay } from '@/utils/date'
import MaintenanceLogo from '@/assets/maintenance.png'

const MaintenanceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MaintenanceTitle = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const MaintenanceDesc = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin: 0 1rem;
`

const MaintenanceDate = styled.div`
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
`

const Image = styled.img`
  object-fit: contain;
  height: 250px;
`

const ContactUsLink = styled.a`
  color: #7c7c7c;
  text-align: center;
  text-decoration: underline;
  font-size: 0.9rem;
  display: block;
  width: 100%;
  &hover: {
    color: #ffcc33;
  }
`

const MaintenanceScreen = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'pageInit/services' })
  }, [])

  const onClickContactus = (e) => {
    e.preventDefault()
    dispatch(promptCustomerServiceModal())
  }

  const onClickBack = () => {
    history.goBack()
  }

  const fpsService = useSelector(getFpsServiceSelector)

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <CenteredContent>
          <MaintenanceContent>
            <Spacer height="5rem" />
            <Image
              src={MaintenanceLogo}
              style={{ textAlign: 'center' }}
              alt="logo"
            />
            <Spacer height="0.889rem" />
            <MaintenanceTitle>
              {tt(intl, 'common.maintenance.title')}
            </MaintenanceTitle>
            {fpsService && (
              <>
                <Spacer height="1.5rem" />
                <MaintenanceDesc>
                  {tt(intl, 'common.maintenance.details')}
                </MaintenanceDesc>
                <Spacer height="0.3rem" />
                <MaintenanceDate>
                  {formatFullDateTimeWithDay(
                    moment(fpsService.periodEnd * 1000)
                  )}
                </MaintenanceDate>
              </>
            )}
            <Spacer height="2rem" />
            <PaddingContent>
              <ActionButtons>
                <PrimaryButton type="main" onClick={onClickBack}>
                  {tt(intl, 'fps.cancel')}
                </PrimaryButton>
              </ActionButtons>
              <Spacer height="1rem" />
              <MaintenanceDesc>
                {tt(intl, 'common.maintenance.details2')}
              </MaintenanceDesc>
              <Spacer height="1rem" />
              <ContactUsLink onClick={onClickContactus}>
                {tt(intl, 'menu.item_contactus')}
              </ContactUsLink>
            </PaddingContent>
          </MaintenanceContent>
        </CenteredContent>
      </CSLayout.CSContent>
    </CSLayout.CSContainer>
  )
}

export default MaintenanceScreen
