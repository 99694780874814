import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import SelectorTabUnderline, { Tab } from '@/components/SelectorTabUnderline'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { formatLineBreak } from '@/utils/format'

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  padding: 0 1.11rem;
  box-sizing: border-box;
  background-color: white;
  overflow-y: scroll;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0.88rem;
  right: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Content = styled.div`
  box-sizing: border-box;
  max-width: 423px;
  margin: 0 auto;
`

const TNC = styled.div`
  width: 100%;
  font-size: 0.667rem;

  &.html ul li {
    margin-bottom: 5px;
  }
`

const FooterCloseButton = styled.div`
  text-align: center;
  cursor: pointer;

  > span {
    font-weight: 700;
    font-size: 0.77rem;
  }
`

const TNCText = ({ text, as }) => {
  const isHTML = /<[^>]*>/.test(text)
  if (isHTML) {
    return <TNC className="html" dangerouslySetInnerHTML={{ __html: text }} />
  }
  return <TNC>{formatLineBreak({ text })}</TNC>
}

const GiftCardTNCModal = ({
  isOpen,
  type,
  product,
  isShowRedemption = true,
  isShowTnc = true,
  onClose,
}) => {
  const intl = useIntl()

  const [activeTab, setActiveTab] = useState(type)

  useEffect(() => {
    setActiveTab(type)
  }, [type])

  const redemptionTexts = product?.metadata.redemptionTexts || ''
  const tncTexts = product?.metadata.termsAndConditions || ''

  return (
    <Modal isOpen={isOpen} isLock>
      <Container>
        <CloseButton onClick={onClose}>
          <Icon
            renderImage={() => <CloseSvg />}
            width="2.66rem"
            height="2.66rem"
          />
        </CloseButton>
        <Content>
          <Spacer height="3rem" />
          <SelectorTabUnderline
            defaultTab={activeTab}
            tabs={[
              isShowRedemption && {
                key: 'redemption',
                label: tt(intl, 'giftcard.redemption'),
              },
              isShowTnc && { key: 'tnc', label: tt(intl, 'giftcard.tnc') },
            ].filter((s) => s)}
            onChange={(tab) => setActiveTab(tab.key)}
          />
          <Spacer height="1.77rem" />
          <Tab isActive={activeTab === 'redemption'}>
            <TNCText text={redemptionTexts} />
          </Tab>
          <Tab isActive={activeTab === 'tnc'}>
            <TNCText text={tncTexts} />
          </Tab>
          <Spacer height="1.6rem" />
          <TNC>
            {tt(intl, 'giftcard.emailtosupport', {
              email: product?.metadata.customerServiceWebsite,
            })}
          </TNC>
          <Spacer height="1.6rem" />
          <FooterCloseButton onClick={onClose}>
            <Icon
              renderImage={() => <CloseSvg />}
              width="2.66rem"
              height="2.66rem"
            />
            <span>{tt(intl, 'common.close')}</span>
          </FooterCloseButton>
          <Spacer height="2.22rem" />
        </Content>
      </Container>
    </Modal>
  )
}

export default GiftCardTNCModal
