import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const verifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState: {
    sendOTP: AsyncState.create(),
    verifyOTP: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.sendOTP = AsyncState.reset()
      state.verifyOTP = AsyncState.reset()
    },
    sendOTPStart: (state) => {
      state.sendOTP = AsyncState.start()
    },
    sendOTPSuccess: (state, action) => {
      state.sendOTP = AsyncState.success(action.payload)
    },
    sendOTPFailed: (state, action) => {
      state.sendOTP = AsyncState.failed(action.payload)
    },
    verifyOTPStart: (state) => {
      state.verifyOTP = AsyncState.start()
    },
    verifyOTPSuccess: (state, action) => {
      state.verifyOTP = AsyncState.success(action.payload)
    },
    verifyOTPFailed: (state, action) => {
      state.verifyOTP = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = verifyEmailSlice

export default verifyEmailSlice.reducer
