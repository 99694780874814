import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-yellow-icon.svg'
import Icon from '@/components/Icon'
import MilesValue from '@/components/miles/MilesValue'
import { Row } from '@/components/ui/Shared'
import { tt } from '@/locales/format'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const GreyIcon = styled(Icon)`
  filter: grayscale(1);
  margin-right: 0.222rem;
`

const Text = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  color: #818181;
`

const YouHaveText = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const MilesDesc = ({ currency, buyRate, totalMiles }) => {
  const intl = useIntl()

  if (!buyRate) return null

  return (
    <Container>
      <Row>
        <GreyIcon
          renderImage={() => <DivitMilesSvg />}
          width="0.889rem"
          height="0.889rem"
        />
        <Text>
          100 = {currency} {(buyRate * 100).toFixed(2)}
        </Text>
      </Row>
      <Row>
        <YouHaveText>{tt(intl, 'payment.youhave')}</YouHaveText>
        <MilesValue fontSize="0.778rem" miles={totalMiles} isSigned={false} />
      </Row>
    </Container>
  )
}

export default MilesDesc
