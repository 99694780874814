import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Box from '@/components/Box'
import { Button, H2 } from '@/components/Divit'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import usePageError from '@/hooks/usePageError'

const Container = styled(PaddingContent)``

const LoginH2 = styled(H2)`
  margin: 1.25rem 0;
`

const MessageBox = styled(Box)`
  margin-bottom: 1.25rem;
  padding-left: calc(100% / 12 - ${({ theme }) => theme.gutter}px);
  padding-right: calc(100% / 12 - ${({ theme }) => theme.gutter}px);
  width: calc(100% / 12 * 10 - 2px + ${({ theme }) => theme.gutter * 2}px);
`

const LoginButton = styled(Button)`
  font-size: 0.75rem;
  margin: 1.25em 0;
`

const VerifyEmailFromEmail = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()

  const { token } = useParams()

  const { errorMessage, updateErrorMessage } = usePageError()
  const { verifyEmail } = useSelector((s) => s.verifyEmail)

  useEffect(() => {
    dispatch({
      type: 'verifyEmail/verifyEmail',
      payload: { token },
    })
    return () => {
      dispatch({
        type: 'verifyEmail/reset',
      })
    }
  }, [token])

  useEffect(() => {
    if (verifyEmail.isError) {
      updateErrorMessage('verify email failed')
    } else {
      updateErrorMessage('')
    }
  }, [verifyEmail])

  const onClickLogin = useCallback(() => {
    // if user is logged in, then go /home continue shopping,
    // otherwise go /login
    history.replace('/home')
  }, [])

  const loading = verifyEmail?.isLoading

  return (
    <Container>
      <LoginH2>{intl.formatMessage({ id: 'verifyemail.email' })}</LoginH2>
      <ErrorMessageBox errorMessage={errorMessage} />
      {verifyEmail?.isSuccess && (
        <>
          <MessageBox>
            {intl.formatMessage({ id: 'verifyemail.email.success' })}
          </MessageBox>
          <LoginButton
            type="stretch"
            onClick={onClickLogin}
            data-selenium="verify.email"
          >
            {intl.formatMessage({ id: 'button.login' })}
          </LoginButton>
        </>
      )}
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default VerifyEmailFromEmail
