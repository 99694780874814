import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'
import { actions as resetPasswordActions } from './resetPasswordSlice'

export function* forgotPassword({ payload }) {
  try {
    yield put(resetPasswordActions.forgotPasswordStart())
    const { data } = yield call(() =>
      DivitAPI.post('/verify/resetpwd/send', payload)
    )
    yield put(resetPasswordActions.forgotPasswordSuccess(data))
  } catch (e) {
    yield put(resetPasswordActions.forgotPasswordFailed(getErrorMessage(e)))
  }
}

export function* verifyToken({ payload }) {
  try {
    yield put(resetPasswordActions.verifyTokenStart())
    const { data } = yield call(() =>
      DivitAPI.post('/verify/resetpwd', payload)
    )
    yield put(resetPasswordActions.verifyTokenSuccess(data))
  } catch (e) {
    yield put(resetPasswordActions.verifyTokenFailed(getErrorMessage(e)))
  }
}

export function* resetPassword({ payload }) {
  try {
    yield put(resetPasswordActions.resetPasswordStart())
    const { data } = yield call(() =>
      DivitAPI.patch('/profiles/resetpwd', payload)
    )
    yield put(resetPasswordActions.resetPasswordSuccess(data))
  } catch (e) {
    yield put(resetPasswordActions.resetPasswordFailed(getErrorMessage(e)))
  }
}

export default null
