import React from 'react'
import styled from 'styled-components'

import { importImage, WelcomeBonusBanners } from '@/constants/images'
import { useLocale } from '@/contexts/localeContext'
import useWindowSize from '@/hooks/useWindowSize'
import Theme from '@/themes/base'
import ImageSet from '@/utils/imageSet'

const Container = styled.div`
  width: 100%;
  text-align: center;
`

const Banner = styled.img`
  width: calc(100% - 2.222rem);
  height: auto;
  border-radius: 16px;
  cursor: pointer;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    width: calc(100% - 100% / 6);
  }
`

const images = ImageSet.createLang({
  en: ImageSet.create({
    mobile: importImage(
      WelcomeBonusBanners,
      './banner-welcome-bonus-mobile_en.png'
    ),
    desktop: importImage(
      WelcomeBonusBanners,
      './banner-welcome-bonus-desktop_en.png'
    ),
  }),
  zhHK: ImageSet.create({
    mobile: importImage(
      WelcomeBonusBanners,
      './banner-welcome-bonus-mobile_zh-hk.png'
    ),
    desktop: importImage(
      WelcomeBonusBanners,
      './banner-welcome-bonus-desktop_zh-hk.png'
    ),
  }),
  zhCN: ImageSet.create({
    mobile: importImage(
      WelcomeBonusBanners,
      './banner-welcome-bonus-mobile_zh-cn.png'
    ),
    desktop: importImage(
      WelcomeBonusBanners,
      './banner-welcome-bonus-desktop_zh-cn.png'
    ),
  }),
})

const WelcomeBonusBanner = ({ onClick }) => {
  const size = useWindowSize()
  const { currentLocale } = useLocale()

  const isDesktop = size.width > Theme.breakpoints.sm

  return (
    <Container>
      <Banner
        onClick={onClick}
        src={
          isDesktop
            ? ImageSet.get(images, currentLocale, 'desktop')
            : ImageSet.get(images, currentLocale, 'mobile')
        }
      />
    </Container>
  )
}

export default WelcomeBonusBanner
