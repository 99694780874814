import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as FailIconSvg } from '@/assets/common/fail.svg'
import Icon from '@/components/Icon'
import CenteredContent from '@/components/layout/CenteredContent'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import { tt } from '@/locales/format'

const FailTitle = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const FailDesc = styled.div`
  font-size: 0.77rem;
  text-align: center;
  margin: 0 1rem;
  max-width: 20.8rem;
`

const FieldTitle = styled.div`
  text-align: center;
  font-size: 0.67rem;
  font-weight: 700;
`

const FieldValue = styled.div`
  text-align: center;
  font-size: 0.67rem;
`

const ErrorScreen = ({ title, content, okText, onOk, error, onRetry }) => {
  const intl = useIntl()
  const history = useHistory()

  const onClickHome = () => {
    history.push('/')
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <CenteredContent>
          <div>
            <Icon
              renderImage={() => <FailIconSvg />}
              width="1.741rem"
              height="1.508rem"
            />
            <Spacer height="0.889rem" />
            <FailTitle>{title}</FailTitle>
            <Spacer height="0.889rem" />
            <FailDesc>{content}</FailDesc>
            <Spacer height="1.778rem" />
            {error?.code && (
              <>
                <FieldTitle>error:</FieldTitle>
                <FieldValue>{error.code}</FieldValue>
              </>
            )}
          </div>
        </CenteredContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            {onRetry && (
              <SecondaryButton onClick={onRetry}>
                {tt(intl, 'common.retry')}
              </SecondaryButton>
            )}
            <PrimaryButton onClick={onOk || onClickHome}>
              {okText || tt(intl, 'common.homepage')}
            </PrimaryButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default ErrorScreen
