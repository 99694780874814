import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profileAdditional: null,
  loading: false,
  patching: false,
  error: null,
}

const ProfileAdditionalSlice = createSlice({
  name: 'profileAdditional',
  initialState,
  reducers: {
    reset: () => initialState,
    getProfileAdditionalStart: (state) => {
      state.loading = true
      state.error = null
    },
    getProfileAdditionalSuccess: (state, action) => {
      state.profileAdditional = action.payload
      state.loading = false
      state.error = null
    },
    getProfileAdditionalFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    patchProfileAdditionalStart: (state) => {
      state.patching = true
      state.error = null
    },
    patchProfileAdditionalSuccess: (state) => {
      state.patching = false
      state.error = null
    },
    patchProfileAdditionalFailed: (state, action) => {
      state.patching = false
      state.error = action.payload
    },
  },
})

export const {
  getProfileAdditionalStart,
  getProfileAdditionalSuccess,
  getProfileAdditionalFailed,
} = ProfileAdditionalSlice.actions

export default ProfileAdditionalSlice.reducer
