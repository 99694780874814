import React from 'react'
import styled from 'styled-components'

import { ReactComponent as MilesConvertArrowSvg } from '@/assets/miles/miles-convert-arrow.svg'
import Icon from '@/components/Icon'
import MilesProgramValue from '@/components/miles/MilesProgramValue'
import Spacer from '@/components/Spacer'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const MilesConvertLabel = ({
  type = 'out',
  programInfo,
  miles,
  fontSize = '0.889rem',
  arrowSize = '0.444rem',
}) => (
  <Container>
    {type === 'out' && (
      <MilesProgramValue fontSize={fontSize} program="divit" miles={miles} />
    )}
    {type === 'in' && (
      <MilesProgramValue
        fontSize={fontSize}
        program={programInfo.key}
        miles={miles}
      />
    )}
    <Spacer width="8px" />
    <Icon
      renderImage={() => <MilesConvertArrowSvg />}
      width={arrowSize}
      height={arrowSize}
    />
    <Spacer width="8px" />
    {type === 'in' && (
      <MilesProgramValue fontSize={fontSize} program="divit" miles={miles} />
    )}
    {type === 'out' && (
      <MilesProgramValue
        fontSize={fontSize}
        program={programInfo.key}
        miles={miles}
      />
    )}
  </Container>
)

export default MilesConvertLabel
