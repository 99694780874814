import { configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import { setApiStore } from '@/services/api'

import appReducer from './app/appSlice'
import authReducer from './auth/authSlice'
import flashMessageSlice from './flashMessage/flashMessageSlice'
import giftCardReducer from './giftCard/giftCardSlice'
import lookupSlice from './lookup/lookupSlice'
import csModelReducer from './modal/csModalSlice'
import modalReducer from './modal/modalSlice'
import orderReducer from './order/orderSlice'
import checkoutGiftCardReducer from './pages/checkoutGiftCardSlice'
import claimDivitMilesReducer from './pages/claimDivitMilesSlice'
import convertDivitMilesReducer from './pages/convertDivitMilesSlice'
import fastSignupReducer from './pages/fastSignupSlice'
import pageInitReducer from './pages/pageInitSlice'
import paylaterReducer from './pages/paylaterSlice'
import paynowFastFpsReducer from './pages/paynowFastFpsSlice'
import paynowReducer from './pages/paynowSlice'
import paynowVerifyReducer from './pages/paynowVerifySlice'
import payWithFpsReducer from './pages/payWithFpsSlice'
import payWithPaymeReducer from './pages/payWithPaymeSlice'
import resetPasswordReducer from './pages/resetPasswordSlice'
import setupEDDAReducer from './pages/setupEDDASlice'
import ssoReducer from './pages/SSOSlice'
import updateProfileReducer from './pages/updateProfileSlice'
import verifyEmailReducer from './pages/verifyEmailSlice'
import verifyPhoneReducer from './pages/verifyPhoneSlice'
import pinReducer from './pin/pinSlice'
import profileReducer from './profile/profileSlice'
import profileAdditionalReducer from './profileAdditional/profileAdditionalSlice'
import saga from './saga'
import shopReducer from './shop/shopSlice'
import toastReducer from './toast/ToastSlice'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger()
const middleware = [
  thunk,
  sagaMiddleware,
  process.env.REACT_APP_ENV !== 'prod' ? loggerMiddleware : null,
  routerMiddleware(history),
].filter((m) => m)

const store = configureStore({
  reducer: {
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    order: orderReducer,
    profile: profileReducer,
    profileAdditional: profileAdditionalReducer,
    giftCard: giftCardReducer,
    flashMessage: flashMessageSlice,
    shop: shopReducer,
    lookup: lookupSlice,
    pin: pinReducer,
    modal: modalReducer,
    csModal: csModelReducer,
    toast: toastReducer,
    // pages
    pageInit: pageInitReducer,
    verifyEmail: verifyEmailReducer,
    verifyPhone: verifyPhoneReducer,
    paylater: paylaterReducer,
    claimDivitMiles: claimDivitMilesReducer,
    convertDivitMiles: convertDivitMilesReducer,
    payWithPayme: payWithPaymeReducer,
    payWithFps: payWithFpsReducer,
    paynowFastFps: paynowFastFpsReducer,
    resetPassword: resetPasswordReducer,
    updateProfile: updateProfileReducer,
    setupEDDA: setupEDDAReducer,
    fastSignup: fastSignupReducer,
    paynow: paynowReducer,
    paynowVerify: paynowVerifyReducer,
    checkoutGiftCard: checkoutGiftCardReducer,
    sso: ssoReducer,
  },
  middleware,
})

sagaMiddleware.run(saga)

setApiStore(store)

export default store
