import { call, put, select } from 'redux-saga/effects'

import { EDDA_MIN_DAILY_TRANSACTION_AMOUNT_HKD } from '@/constants/edda'
import { ERROR_CODE_FPS_UNAVAILABLE } from '@/constants/errors'
import DivitAPI from '@/services/api'

import { getFpsServiceSelector } from '../lookup/lookupSelector'
import { getErrorMessage } from '../utils/error'
import { profileSelector } from './profileSelector'

export function* fetchProfile() {
  try {
    yield put({ type: 'profile/getProfileStart' })
    const { data } = yield call(async () => DivitAPI.get('profiles'))
    yield put({ type: 'profile/getProfileSuccess', payload: data.data })
  } catch (e) {
    yield put({ type: 'profile/getProfileFailed', payload: getErrorMessage(e) })
  }
}

export function* patchProfile({ payload }) {
  try {
    yield put({ type: 'profile/patchProfileStart' })
    const { data } = yield call(async () => DivitAPI.patch('profiles', payload))
    yield put({ type: 'profile/getProfileSuccess', payload: data.data })
    yield put({ type: 'profile/patchProfileSuccess' })
  } catch (e) {
    yield put({
      type: 'profile/patchProfileFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* fetchIdentity() {
  try {
    yield put({ type: 'profile/getProfileIdentityStart' })
    const { data } = yield call(() => DivitAPI.get('/person/identity'))
    yield put({ type: 'profile/getProfileIdentitySuccess', payload: data.data })
  } catch (e) {
    yield put({
      type: 'profile/getProfileIdentityFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* updateEmail({ payload }) {
  try {
    yield put({ type: 'profile/updateEmailStart' })
    yield call(async () => DivitAPI.patch('profiles/email', payload))
    yield put({ type: 'fetchProfile' })
    yield put({ type: 'profile/updateEmailSuccess' })
  } catch (e) {
    yield put({
      type: 'profile/updateEmailFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* updateContactMethod({ payload }) {
  try {
    yield put({ type: 'profile/updateContactMethodStart' })
    yield call(async () => DivitAPI.patch('profiles/contactmethod', payload))
    yield put({ type: 'fetchProfile' })
    yield put({ type: 'profile/updateContactMethodSuccess' })
  } catch (e) {
    yield put({
      type: 'profile/updateContactMethodFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* linkSocialProvider({ payload }) {
  const { provider, accessToken } = payload
  try {
    yield put({ type: 'profile/patchProfileStart' })
    const { data } = yield call(async () =>
      DivitAPI.patch(`oauth/${provider}`, { accessToken })
    )
    const { data: socialMediaLinkage } = data
    yield put({ type: 'profile/patchProfileSuccess' })
    yield put({
      type: 'profile/updateSocialMediaLinkage',
      payload: socialMediaLinkage,
    })
  } catch (e) {
    yield put({
      type: 'profile/patchProfileFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* unlinkSocialProvider({ payload }) {
  const { provider } = payload
  try {
    yield put({ type: 'profile/patchProfileStart' })
    const { data } = yield call(async () =>
      DivitAPI.patch(`oauth/${provider}`, {})
    )
    const { data: socialMediaLinkage } = data
    yield put({ type: 'profile/patchProfileSuccess' })
    yield put({
      type: 'profile/updateSocialMediaLinkage',
      payload: socialMediaLinkage,
    })
  } catch (e) {
    yield put({
      type: 'profile/patchProfileFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* fetchPurchaseStatus() {
  try {
    yield put({ type: 'profile/getPurchaseStatusStart' })
    const { data } = yield call(async () =>
      DivitAPI.get('profiles/spend/status')
    )
    yield put({ type: 'profile/getPurchaseStatusSuccess', payload: data.data })
  } catch (e) {
    yield put({
      type: 'profile/getPurchaseStatusFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* fetchGiftCardBrands() {
  const { data } = yield call(async () =>
    DivitAPI.get('/giftcard/profiles/brands')
  )
  yield put({ type: 'profile/getGiftCardBrandsSuccess', payload: data.data })
  return data.data
}

export function* fetchGiftCards({ brandCode, active }) {
  const { data } = yield call(async () =>
    DivitAPI.get('/giftcard/profiles/cards', {
      params: { brandCode, active },
    })
  )
  const { data: giftCards, count: giftCardsTotal } = data
  yield put({
    type: 'profile/getGiftCardsSuccess',
    payload: {
      giftCards,
      giftCardsTotal,
    },
  })
  return data.data
}

export function* fetchProfileSettings() {
  const { data } = yield call(async () =>
    DivitAPI.get('profiles/settings/general')
  )
  const { metadata } = data.data
  yield put({ type: 'profile/getProfileSettingsSuccess', payload: metadata })
  return metadata
}

export function* fetchProfileAddresses() {
  const { data } = yield call(async () => DivitAPI.get('profiles/addresses'))
  yield put({ type: 'profile/getProfileAddressesSuccess', payload: data.data })
  return data.data
}

export function* checkFpsIsAvailable() {
  const fpsService = yield select(getFpsServiceSelector)
  if (fpsService) {
    const err = new Error('fps is unavailable')
    err.code = ERROR_CODE_FPS_UNAVAILABLE
    err.data = { periodEnd: fpsService.periodEnd }
    throw err
  }
}

export function* fetchPaymentAccount() {
  const { customerID } = yield select(profileSelector)
  const { data } = yield call(async () =>
    DivitAPI.get(`payment/account/info/${customerID}`)
  )
  yield put({
    type: 'profile/getProfilePaymentAccountSuccess',
    payload: data.data[0]?.accountData,
  })
  return data.data
}

export function* fetchEDDAAccounts() {
  const { customerID } = yield select(profileSelector)
  const { data } = yield call(async () =>
    DivitAPI.get(`payment/account/edda/${customerID}`)
  )
  yield put({
    type: 'profile/getProfileEDDAAccountSuccess',
    payload: data.data,
  })
  return data.data
}

export function* patchEDDAAccount({ maxLimit }) {
  const { customerID } = yield select(profileSelector)
  const { data } = yield call(() =>
    DivitAPI.patch(`payment/account/edda/${customerID}`, {
      setMinLimit: EDDA_MIN_DAILY_TRANSACTION_AMOUNT_HKD,
      setMaxLimit: maxLimit,
    })
  )
  yield fetchProfile()
  yield fetchEDDAAccounts()
  return data.data
}

export function* unlinkEDDAAccount() {
  const { customerID } = yield select(profileSelector)
  const { data } = yield call(() =>
    DivitAPI.delete(`payment/account/edda/${customerID}`)
  )
  yield fetchProfile()
  yield fetchEDDAAccounts()
  return data.data
}
