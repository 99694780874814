import * as Pagination from '@/utils/pagination'
import { sleep } from '@/utils/promises'

import { fetchDivitMilesTransactionsAsync } from './divitMilesSaga'

export const fetchDivitMilesHistoryNextPage = ({ type }) => async (
  dispatch,
  getState
) => {
  await sleep(1000)

  const state = getState()
  let list = []
  let total = 0
  if (type === 'all') {
    list = state.profile.divitMilesTranscations.all.data
    total = state.profile.divitMilesTranscations.all.total
  } else if (type === 'earn') {
    list = state.profile.divitMilesTranscations.earn.data
    total = state.profile.divitMilesTranscations.earn.total
  } else if (type === 'spend') {
    list = state.profile.divitMilesTranscations.spend.data
    total = state.profile.divitMilesTranscations.spend.total
  }

  const hasMore = Pagination.hasMore(list, total)
  if (!hasMore) return
  const pageNo = Pagination.getNextPageNo(list)
  const { data } = await fetchDivitMilesTransactionsAsync({
    type,
    page: pageNo,
    pageSize: 25,
  })
  dispatch({
    type: 'profile/insertDivitMilesTransactions',
    payload: { type, total: data.count, data: data.data },
  })
}

export default 0
