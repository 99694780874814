import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

import { ReactComponent as SuccessSvg } from '@/assets/toast/success-white.svg'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import ModalToast from '@/components/ModalToast'
import Spacer from '@/components/Spacer'
import useUpdateEffect from '@/hooks/useUpdateEffect'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 4;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    max-width: 600px;
  }
`

const ToastContainer = styled(animated.div)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.444rem 0.889rem;
  background-color: #13cc93;
  box-sizing: border-box;
  border-radius: 8px;
  /* margin-top: 0.889rem; */
`

const ToastText = styled.div`
  color: white;
  font-size: 0.778rem;
`

export const Toast = ({ message = '', onDismiss }) => {
  const [show, setShow] = useState(true)

  const transitions = useTransition(show, {
    config: { duration: 250 },
    from: { opacity: 0, marginTop: 0 },
    enter: { opacity: 1, marginTop: 16 },
    leave: { opacity: 0, marginTop: 0 },
    onRest: () => {
      setTimeout(() => {
        setShow(false)
      }, 1.5 * 1000)
    },
  })

  useUpdateEffect(() => {
    if (!show) {
      setTimeout(() => {
        onDismiss?.()
      }, 250)
    }
  }, [show])

  return transitions(
    (styles, item) =>
      item && (
        <ToastContainer style={styles}>
          <Icon
            renderImage={() => <SuccessSvg />}
            width="1.333rem"
            height="1.333rem"
          />
          <Spacer width="8px" />
          <ToastText>{message}</ToastText>
        </ToastContainer>
      )
  )
}

const MessageToast = () => {
  const dispatch = useDispatch()

  const { toasts = [] } = useSelector((s) => s.toast)

  const onDismissItem = (toast) => () => {
    dispatch({
      type: 'toast/removeToast',
      payload: { id: toast.id },
    })
  }

  return (
    <ModalToast isOpen>
      <Container>
        <PaddingContent>
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              type={toast.type}
              message={toast.message}
              onDismiss={onDismissItem(toast)}
            />
          ))}
        </PaddingContent>
      </Container>
    </ModalToast>
  )
}

export default MessageToast
