import { useRef } from 'react'
import ReactTooltip from 'react-tooltip'

const useToolTip = () => {
  const toolTipRef = useRef()

  const showToolTip = () => {
    ReactTooltip.show(toolTipRef.current)
  }

  const hideToolTip = () => {
    ReactTooltip.hide(toolTipRef.current)
  }

  return {
    toolTipRef,
    showToolTip,
    hideToolTip,
  }
}

export default useToolTip
