import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import MilesValue from '@/components/miles/MilesValue'
import TicketsValue from '@/components/miles/TicketsValue'
import NormalMessageBox from '@/components/NormalMessageBox'
import InstalmentsInfo from '@/components/paylater/InstalmentsInfo'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { paymentInstrumentsSelector } from '@/redux/lookup/lookupSelector'
import {
  eDDAInitedSelector,
  isFeatureEDDAEnabledSelector,
} from '@/redux/profile/profileSelector'
import * as DivitMiles from '@/utils/DivitMiles'
import * as Instalments from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import { getPartnerName } from '@/utils/Partner'
import { FormattedPrice } from '@/utils/Price'
import { IsPaylatering } from '@/utils/Route'

const Container = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SubTitle = styled.div`
  font-size: 1rem;
  text-align: center;
  font-weight: 400;
  line-height: normal;
`

const MerchantLogoContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`

const MerchantNameContainer = styled.div`
  font-weight: 600;
  text-align: center;
`

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ItemTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
`

const ItemValue = styled.div`
  max-width: 70%;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
`

const EDDANote = styled.div`
  font-size: 0.85rem;
  text-align: center;
`

const ReturnButtonContainer = styled.div`
  text-align: center;
`

const BackButton = styled(Button)`
  font-size: 0.889rem;
`

const PaymentSuccess = ({ isEmbedded }) => {
  const intl = useIntl()
  const history = useHistory()
  const { pathname } = useLocation()
  const isPaylatering = IsPaylatering(pathname)
  const { orderId, instalmentId } = useParams()
  const dispatch = useDispatch()

  const [purchasingOrderId, setPurchasingOrderId] = useState('')

  const paymentInstruments = useSelector(paymentInstrumentsSelector)
  const { order, orderMiles, metadata } = useSelector((state) => state.order)
  const { profile } = useSelector((s) => s.profile)
  const isEDDAEnabled = useSelector(isFeatureEDDAEnabledSelector)
  const isUserEDDAInited = useSelector(eDDAInitedSelector)

  useUnmount(() => dispatch({ type: 'setupEDDA/reset' }))

  useEffect(() => {
    const orderid = sessionStorage.getItem('purchusing_order')
    if (orderid) {
      setPurchasingOrderId(orderid)
    }
  }, [])

  useEffect(() => {
    if (order && order.orderid === orderId) {
      const instalment = order.instalments.find(
        (item) => item.instalmentID === instalmentId
      )
      if (instalment) {
        const { series } = instalment
        dispatch({
          type: 'gtm/sendEvent',
          payload: {
            event: 'purchase',
            userId: profile.customerID,
            orderId: order.orderid,
            ecommerce: Order.getGA4EcommercePayload(order),
          },
        })
        dispatch({
          type: 'gtm/sendEvent',
          payload: {
            event: `paid_instalment_${series}`,
            userId: profile.customerID,
            instalmentId: instalment.instalmentID,
            isSuccess: true,
          },
        })
      }
    }
  }, [order])

  const { retry, isInitLoading, isInitError } = usePage({
    skipInit: isEmbedded,
    initAction: {
      type: 'pageInit/initPaymentFinished',
      payload: { orderId, instalmentId },
    },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  if (order.orderid !== orderId) return <></>

  const orderTotal = Order.getTotalAmount(order)
  const instalment = order.instalments.find(
    (item) => item.instalmentID === instalmentId
  )

  const instruments = paymentInstruments
    .filter((p) => p.name === 'fps' || p.name === 'edda')
    .map((p) => p.ID)
  const lastPaidAmount = Instalments.getLastPaidAmount(
    instalment,
    instruments
  )?.amount
  const milesEarned = DivitMiles.getInstalmentMilesEarned(
    instalment,
    orderMiles
  )
  const ticketsEarned = Order.getTicketsEarned(order, metadata)
  // const bonusMilesEarned = DivitMiles.getBonusMilesEarned(orderMiles)
  const paymentStatus = Instalments.getPaymentStatus(instalment)
  if (paymentStatus !== 'completed') {
    return (
      <NormalMessageBox
        buttonText={intl.formatMessage({ id: 'button.retry' })}
        onClick={retry}
      >
        {intl.formatMessage({ id: 'error.order.status.invalid' })}
      </NormalMessageBox>
    )
  }

  const { series } = instalment
  const isFirstPayment = instalment.series === 1
  // const hasRefund = Order.isRefund(order)
  const partner = getPartnerName(order.partnerid)
  const isFirstCheckout =
    isPaylatering && isFirstPayment && order.webhook_success

  const goHome = () => {
    history.push('/')
  }

  const goWebhookSuccess = (e) => {
    e.preventDefault()

    window.location.href = order.webhook_success
  }

  const resumePurchasingOrder = (e) => {
    e.preventDefault()

    history.push(`/order/${purchasingOrderId}`)
  }
  return (
    <Container>
      <Icon
        renderImage={() => <SuccessOutlinedSvg />}
        width="1.736rem"
        height="1.736rem"
      />
      <Title>
        {intl.formatMessage({
          id: `payment.confirm[${series - 1}].success.title`,
        })}
      </Title>
      <SubTitle>
        {intl.formatMessage({
          id: `payment.confirm[${series - 1}].success.message`,
        })}
      </SubTitle>
      {isEDDAEnabled && !isUserEDDAInited && instalment.series === 1 && (
        <>
          <Spacer height="0.2rem" />
          <EDDANote>{tt(intl, 'edda.requested.sent.paylater')}</EDDANote>
          <EDDANote>{tt(intl, 'edda.requested.receiveinfo')}</EDDANote>
          <Line />
        </>
      )}
      <MerchantLogoContainer>
        <MerchantLogo merchantID={order.partnerid} width="4rem" height="4rem" />
      </MerchantLogoContainer>
      <MerchantNameContainer>
        <MerchantName merchantID={order.partnerid} />
      </MerchantNameContainer>
      <ItemRow>
        <ItemTitle>{tt(intl, 'order.merchantreference')}</ItemTitle>
        <ItemValue>{Order.getPartnerRef(order)}</ItemValue>
      </ItemRow>
      <ItemRow>
        <ItemTitle>{tt(intl, 'payment.ordertotal')}</ItemTitle>
        <ItemValue>
          {FormattedPrice(orderTotal.currency, orderTotal.amount)}
        </ItemValue>
      </ItemRow>
      <Line />
      <ItemRow>
        <ItemTitle>{tt(intl, 'payment.youpaid')}</ItemTitle>
        <MilesAndAmountValue
          fontSize="1rem"
          amountFontSize="1.5rem"
          currency={lastPaidAmount.currency}
          amount={lastPaidAmount.amount}
          miles={instalment.miles}
        />
      </ItemRow>
      {milesEarned > 0 && (
        <ItemRow>
          <ItemTitle>{tt(intl, 'payment.youearned')}</ItemTitle>
          <ItemValue>
            <MilesValue
              fontSize="0.889rem"
              miles={milesEarned}
              isSigned={false}
            />
          </ItemValue>
        </ItemRow>
      )}
      {!!ticketsEarned && (
        <ItemRow>
          <ItemTitle>{tt(intl, 'home.luckydraw.ticketcount')}</ItemTitle>
          <ItemValue>
            <TicketsValue
              amount={ticketsEarned}
              type="yellow"
              fontSize="0.889rem"
            />
          </ItemValue>
        </ItemRow>
      )}
      <Line />
      <InstalmentsInfo
        order={order}
        orderMiles={orderMiles}
        isPayable={false}
      />
      {isFirstCheckout && (
        <ReturnButtonContainer>
          <BackButton type="stretch" onClick={goWebhookSuccess}>
            {partner
              ? intl.formatMessage({ id: 'back.to.partner' }, { partner })
              : intl.formatMessage({ id: 'button.back' })}
          </BackButton>
        </ReturnButtonContainer>
      )}
      {!isFirstCheckout && !purchasingOrderId && (
        <ReturnButtonContainer>
          <BackButton type="stretch" onClick={goHome}>
            {tt(intl, 'button.home')}
          </BackButton>
        </ReturnButtonContainer>
      )}
      {purchasingOrderId && (
        <ReturnButtonContainer>
          <BackButton type="stretch" onClick={resumePurchasingOrder}>
            {intl.formatMessage({ id: 'button.resume.purchase' })}
          </BackButton>
        </ReturnButtonContainer>
      )}
      <Spacer />
    </Container>
  )
}

export default PaymentSuccess
