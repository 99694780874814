import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as NotVerifiedSvg } from '@/assets/common/not-verified.svg'
import { ReactComponent as VerifiedSvg } from '@/assets/common/verified.svg'
import { tt } from '@/locales/format'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 30%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 20%;
  }
`

const VerifiedText = styled.div`
  font-size: 14px;
  color: #00c86d;
`
const NotVerifiedText = styled.div`
  font-size: 14px;
  color: #696c70;
`

const VerifiedStatus = ({ status, hiddenLabel }) => {
  const intl = useIntl()
  return (
    <>
      {status === 'verified' ? (
        <Container>
          <VerifiedSvg />
          {!hiddenLabel && (
            <VerifiedText>{tt(intl, 'common.verified')}</VerifiedText>
          )}
        </Container>
      ) : (
        <Container>
          <NotVerifiedSvg />
          {!hiddenLabel && (
            <NotVerifiedText>{tt(intl, 'common.tobeverified')}</NotVerifiedText>
          )}
        </Container>
      )}
    </>
  )
}

export default VerifiedStatus
