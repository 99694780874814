import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background: #ffffff;
  border: 0.5px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 1rem 0;
  display: inline-block;
  width: calc(100% - 2px);
  font-size: 0.75rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    font-size: 0.85rem;
  }
`

const Box = ({ className, onClick, children }) => (
  <Container className={className} onClick={onClick}>
    {children}
  </Container>
)

Box.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    ),
  ]),
}

Box.defaultProps = {
  className: '',
  onClick: () => {},
  children: <></>,
}

export default Box
