import './style.css'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils from 'react-day-picker/moment'
import styled from 'styled-components'

import { useLocale } from '@/contexts/localeContext'

const InputAndIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  width: 100%;
  border: 1px solid #dedede;
  padding: 0.222rem 0.667rem;
  font-size: 0.778rem;
  border-radius: 30px;

  ::placeholder {
    color: #dedede;
  }
`

const CalendarInput = styled(Input)``

const CalendarIcon = styled(FontAwesomeIcon).attrs({
  icon: faCalendarAlt,
})`
  position: absolute;
  top: 0.35rem;
  right: 0.6rem;
  color: #dedede;
  pointer-events: none;
`

const ReactDayPicker = ({ value, placeholder, onDayChange, ...props }) => {
  const { currentLocale } = useLocale()

  const renderDay = (day) => {
    const date = day.getDate()
    return <div>{date}</div>
  }

  const component = forwardRef(({ ...componentProps }, ref) => (
    <InputAndIcon>
      <CalendarInput {...componentProps} ref={ref} />
      <CalendarIcon />
    </InputAndIcon>
  ))

  return (
    <DayPickerInput
      component={component}
      inputProps={{
        onKeyDown: (e) => e.preventDefault(),
        readOnly: true,
      }}
      value={value}
      placeholder={placeholder}
      formatDate={LocaleUtils.formatDate}
      parseDate={LocaleUtils.parseDate}
      format="LL"
      onDayChange={onDayChange}
      dayPickerProps={{
        renderDay,
        selectedDays: value,
        localeUtils: LocaleUtils,
        locale: currentLocale,
        ...props,
      }}
    />
  )
}

export default ReactDayPicker
