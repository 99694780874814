import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import { ReactComponent as InfoSvg } from '@/assets/common/info.svg'
import CancelPurchase from '@/components/CancelPurchase'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import ReactDayPicker from '@/components/ReactDayPicker/ReactDayPicker'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import { Button } from '@/pages/Auth/shared/style'
import { FormattedPrice } from '@/utils/Price'

const Container = styled(PaddingContent)``

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
`

const SubTitle = styled.div`
  font-size: 0.889rem;
  font-weight: 500;
`

const Schedule = styled.div`
  font-size: 0.778rem;
  padding: 0.889rem 0;
`

const ScheduleNumber = styled.div`
  font-size: 0.889rem;
  font-weight: 700;
`

const InfoIcon = styled(InfoSvg)`
  width: 0.889rem;
  height: auto;
  margin-left: 0.333rem;
  cursor: pointer;
`

const ToolTip = styled(ReactTooltip)`
  &.type-dark.place-top {
    border-radius: 5px;
    font-size: 0.625rem;
    width: 140px;
    padding: 0.5rem 0.75rem;
  }
`

const ScheduleFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ScheduleDate = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Amount = styled.div`
  font-weight: 700;
  text-align: right;
`

const Today = styled.span`
  font-weight: 700;
  margin-right: 0.5rem;
`

const TodayDate = styled.span``

const DueDate = styled.div`
  font-size: 0.778rem;
`

const ReminderText = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
`

const Advice = styled.div``

const NextButton = styled(Button)`
  font-size: 0.889rem;
`

const PaylaterSchedule = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderId } = useParams()

  const [secondInstalmentDate, setSecondInstalmentDate] = useState()
  const [thirdInstalmentDate, setThirdInstalmentDate] = useState()

  const { order } = useSelector((state) => state.order)
  const { profile } = useSelector((s) => s.profile)
  const { patchInstalments } = useSelector((s) => s.paylater)

  const {
    retry,
    isInitLoading,
    isInitError,
    errorMessage,
    updateErrorMessage,
  } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  useUnmount(() => dispatch({ type: 'paylater/reset' }))

  useEffect(() => {
    if (order) {
      const { instalments } = order
      setSecondInstalmentDate(moment.unix(instalments[1].dueDate).toDate())
      setThirdInstalmentDate(moment.unix(instalments[2].dueDate).toDate())
    }
  }, [order])

  useEffect(() => {
    if (patchInstalments.isError) {
      updateErrorMessage(intl.formatMessage({ id: 'error.action.update' }))
    } else {
      updateErrorMessage('')
    }
    if (patchInstalments.isSuccess) {
      history.push(`/paylater/summary/${orderId}`)
    }
  }, [patchInstalments])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  if (order.orderid !== orderId) return <></>

  const changeInstalmentDate = (series, value) => {
    dispatch({
      type: 'gtm/sendEvent',
      payload: {
        event: 'change_instalment_date',
        userId: profile.customerID,
        orderId: order.orderid,
        instalmentSeries: series,
        instalmentInitialDate: moment(secondInstalmentDate).utc().format(),
        instalmentNewDate: moment(value).utc().format(),
      },
    })

    if (series === 2) {
      setSecondInstalmentDate(value)
    } else if (series === 3) {
      setThirdInstalmentDate(value)
    }
  }

  const onClickNext = (e) => {
    e.preventDefault()
    const { instalments } = order
    dispatch({
      type: 'paylater/patchInstalments',
      payload: {
        instalments: [
          {
            instalmentId: instalments[1].instalmentID,
            reminderDate: secondInstalmentDate.getTime() / 1000,
          },
          {
            instalmentId: instalments[2].instalmentID,
            reminderDate: thirdInstalmentDate.getTime() / 1000,
          },
        ],
      },
    })
  }

  if (!order) return <></>

  const { instalments } = order

  const loading = patchInstalments.isLoading

  const dueDate1 = moment.unix(instalments[1].dueDate)
  const dueDate1Display = dueDate1.format('LL')
  const reminderDate1Start = moment
    .unix(instalments[0].dueDate)
    .add(1, 'day')
    .toDate()
  const reminderDate1End = moment.unix(instalments[1].dueDate).toDate()
  const dueDate2 = moment.unix(instalments[2].dueDate)
  const dueDate2Display = dueDate2.format('LL')
  const reminderDate2Start = moment
    .unix(instalments[1].dueDate)
    .add(1, 'day')
    .toDate()
  const reminderDate2End = moment.unix(instalments[2].dueDate).toDate()

  return (
    <Container>
      <Spacer height="1.333rem" />
      <Title>{intl.formatMessage({ id: 'schedule.signup.title' })}</Title>
      <Spacer height="0.778rem" />
      <ErrorMessageBox errorMessage={errorMessage} />
      <Spacer height="0.778rem" />
      <SubTitle>{intl.formatMessage({ id: 'schedule.intro' })}</SubTitle>
      <Spacer height="1.333rem" />
      <Schedule>
        <ScheduleNumber>
          {intl.formatMessage({ id: 'payment.first' })}
        </ScheduleNumber>
        <Spacer height="0.889rem" />
        <ScheduleFlex>
          <ScheduleDate>
            <Today>{intl.formatMessage({ id: 'schedule.today' })}</Today>
            <TodayDate>
              {moment.unix(instalments[0].dueDate).format('LL')}
            </TodayDate>
          </ScheduleDate>
          <Amount>
            {FormattedPrice(
              instalments[0].amount.currency,
              instalments[0].amount.amount
            )}
          </Amount>
        </ScheduleFlex>
      </Schedule>
      <Line />
      <Schedule>
        <ScheduleNumber>
          {intl.formatMessage({ id: 'payment.second' })}
          <InfoIcon data-tip="second-instalment" data-for="second-instalment" />
          <ToolTip
            id="second-instalment"
            place="top"
            type="dark"
            effect="float"
          >
            {intl.formatMessage({ id: 'schedule.payment[1].tooltip' })}
          </ToolTip>
        </ScheduleNumber>
        <Spacer height="0.889rem" />
        <ScheduleFlex>
          <DueDate>
            {intl.formatMessage(
              { id: 'payment.due.date' },
              { date: dueDate1Display }
            )}
          </DueDate>
          <Amount>
            {FormattedPrice(
              instalments[1].amount.currency,
              instalments[1].amount.amount
            )}
          </Amount>
        </ScheduleFlex>
        <Spacer height="0.889rem" />
        <ScheduleFlex>
          <ReminderText>
            {intl.formatMessage({ id: 'payment.reminder.on' })}
          </ReminderText>
          <Spacer width="0.889rem" />
          <ReactDayPicker
            value={secondInstalmentDate}
            placeholder={dueDate1Display}
            initialMonth={moment().toDate()}
            disabledDays={[
              {
                before: reminderDate1Start,
                after: reminderDate1End,
              },
            ]}
            onDayChange={(value) => changeInstalmentDate(2, value)}
          />
        </ScheduleFlex>
      </Schedule>
      <Line />
      <Schedule>
        <ScheduleNumber>
          {intl.formatMessage({ id: 'payment.third' })}
          <InfoIcon data-tip="third-instalment" data-for="third-instalment" />
          <ToolTip id="third-instalment" place="top" type="dark" effect="float">
            {intl.formatMessage({ id: 'schedule.payment[2].tooltip' })}
          </ToolTip>
        </ScheduleNumber>
        <Spacer height="0.889rem" />
        <ScheduleFlex>
          <DueDate>
            {intl.formatMessage(
              { id: 'payment.due.date' },
              { date: dueDate2Display }
            )}
          </DueDate>
          <Amount>
            {FormattedPrice(
              instalments[2].amount.currency,
              instalments[2].amount.amount
            )}
          </Amount>
        </ScheduleFlex>
        <Spacer height="0.889rem" />
        <ScheduleFlex>
          <ReminderText>
            {intl.formatMessage({ id: 'payment.reminder.on' })}
          </ReminderText>
          <Spacer width="0.889rem" />
          <ReactDayPicker
            value={thirdInstalmentDate}
            placeholder={dueDate2Display}
            initialMonth={moment().toDate()}
            disabledDays={[
              {
                before: reminderDate2Start,
                after: reminderDate2End,
              },
            ]}
            onDayChange={(value) => changeInstalmentDate(3, value)}
          />
        </ScheduleFlex>
      </Schedule>
      <Line />
      <Spacer height="1.333rem" />
      <Schedule>
        <Advice>
          {intl.formatMessage(
            { id: 'schedule.advice' },
            {
              sms: <b>{intl.formatMessage({ id: 'communication.sms' })}</b>,
              email: <b>{intl.formatMessage({ id: 'communication.email' })}</b>,
            }
          )}
        </Advice>
      </Schedule>
      <Spacer height="1.333rem" />
      <NextButton
        type="stretch"
        onClick={onClickNext}
        disabled={!(secondInstalmentDate && thirdInstalmentDate)}
      >
        {intl.formatMessage({ id: 'button.continue' })}
      </NextButton>
      <Spacer height="2rem" />
      <CancelPurchase />
      <Spacer height="2rem" />
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default PaylaterSchedule
