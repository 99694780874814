import moment from 'moment'
import { call, put, select } from 'redux-saga/effects'

import * as lookupSagas from '@/redux/lookup/lookupSaga'
import DivitAPI from '@/services/api'
import * as Jwt from '@/utils/Jwt'
import * as localStore from '@/utils/localStore'

import { getError } from '../utils/error'
import { actions as fastSignupActions } from './fastSignupSlice'

export function* setLoginData(data) {
  const { token, expire } = data
  if (token) {
    localStore.save(token, moment(expire).toDate())

    yield put({
      type: 'profile/setCustomerID',
      payload: { customerID: localStore.getCustomerID() },
    })
    yield put({
      type: 'profile/setLastLoginTs',
      payload: { lastLoginTs: Date.now() },
    })
  }
}

export function* login({ payload }) {
  // restart login journey
  yield put(fastSignupActions.clearSession())

  const { clientIdType, countryCode, clientId } = payload
  yield put(
    fastSignupActions.updateSession({ clientIdType, countryCode, clientId })
  )
}

export function* signupVerifyEmail({ payload }) {
  try {
    // restart signup journey
    yield put(fastSignupActions.clearSession())
    yield put(fastSignupActions.signupVerifyEmailStart())
    const { token } = payload
    const { data } = yield call(() =>
      // public endpoint
      DivitAPI.post('/verify/email/token', { token })
    )
    const { token: signupToken, email: signupEmail } = data.data

    localStore.save(signupToken, null)
    yield put(
      fastSignupActions.updateSession({
        clientIdType: 'email',
        countryCode: '',
        clientId: signupEmail,
        token: signupToken,
        otpType: 'emailtoken',
        otp: token,
      })
    )
    yield put(fastSignupActions.signupVerifyEmailSuccess(data.data))
  } catch (e) {
    yield put(fastSignupActions.signupVerifyEmailFailed(getError(e)))
  }
}

export function* loginPassword({ payload }) {
  const session = yield select((s) => s.fastSignup.session)
  const { clientIdType, countryCode, clientId } = session
  const { password, recapResp } = payload
  const email = clientIdType === 'tel' ? `${countryCode}${clientId}` : clientId
  yield put({
    type: 'login',
    payload: {
      email,
      password,
      recapResp,
    },
  })
}

export function* signup({ payload }) {
  try {
    // restart signup journey
    yield put(fastSignupActions.clearSession())
    yield put(fastSignupActions.signupStart())
    const { clientIdType, countryCode, clientId, language, recapResp } = payload
    const mRef = sessionStorage.getItem('mRef') || ''
    const clientID =
      clientIdType === 'tel' ? `${countryCode}${clientId}` : clientId
    const { data } = yield call(() =>
      // public endpoint
      DivitAPI.post('profiles/v2', {
        clientID,
        language,
        source: mRef,
        recapResp,
      })
    )
    const token = data.data
    const expire = Jwt.getExpire(token)
    yield setLoginData({ token, expire })

    yield put(
      fastSignupActions.updateSession({
        clientIdType,
        countryCode,
        clientId,
        token,
      })
    )
    yield put(fastSignupActions.signupSuccess(data.data))
  } catch (e) {
    yield put(fastSignupActions.signupFailed(getError(e)))
  }
}

export function* sendOTP({ payload }) {
  try {
    yield put(fastSignupActions.sendOTPStart())
    const { countryCode, tel, email, recapResp } = payload
    if (tel) {
      const { data } = yield call(() =>
        DivitAPI.post('verify/phone/sendOTP', {
          tel: `${countryCode}${tel}`,
          recapResp,
        })
      )
      yield put(fastSignupActions.sendOTPSuccess(data.data))
    } else {
      const { data } = yield call(() =>
        DivitAPI.post('verify/email/send', {
          email,
          recapResp,
        })
      )
      yield put(fastSignupActions.sendOTPSuccess(data.data))
    }
  } catch (e) {
    yield put(fastSignupActions.sendOTPFailed(getError(e)))
  }
}

export function* verifyOTP({ payload }) {
  try {
    yield put(fastSignupActions.verifyOTPStart())
    const { countryCode, tel, email, code } = payload
    if (tel) {
      yield call(() =>
        DivitAPI.post('verify/phone', {
          tel: `${countryCode}${tel}`,
          code,
        })
      )
      yield put(fastSignupActions.updateSession({ otpType: 'tel', otp: code }))
      yield put(fastSignupActions.verifyOTPSuccess({}))
    } else {
      yield call(() => DivitAPI.post('verify/email/otp', { email, code }))
      yield put(
        fastSignupActions.updateSession({ otpType: 'email', otp: code })
      )
      yield put(fastSignupActions.verifyOTPSuccess({}))
    }
  } catch (e) {
    yield put(fastSignupActions.verifyOTPFailed(getError(e)))
  }
}

function* fetchProfile() {
  const { data } = yield call(async () => DivitAPI.get('profiles'))
  yield put({ type: 'profile/getProfileSuccess', payload: data.data })
  return data.data
}

export function* setupPassword({ payload }) {
  try {
    yield put(fastSignupActions.setupPasswordStart())
    const { referralCode, session } = yield select((s) => s.fastSignup)
    const { otp, otpType } = session
    const { firstName, lastName, pwd } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`profiles/v2`, {
        otp,
        otpType,
        firstName,
        lastName,
        pwd,
        referralCode,
      })
    )
    const token = data.data
    const expire = Jwt.getExpire(token)
    yield setLoginData({ token, expire })

    yield fetchProfile()
    yield put({ type: 'auth/loginSuccess', payload: { token, expire } })
    yield lookupSagas.fetchLookups()
    // success go to private route
    yield put(fastSignupActions.clearSession())
    yield put(fastSignupActions.clearReferralCode())
    yield put(fastSignupActions.setupPasswordSuccess(data.data))
  } catch (e) {
    yield put(fastSignupActions.setupPasswordFailed(getError(e)))
  }
}

export function* forgotPassword({ payload }) {
  try {
    yield put(fastSignupActions.forgotPasswordStart())
    const { clientId, language, recapResp } = payload
    const { data } = yield call(() =>
      // public endpoint
      DivitAPI.post('verify/resetpwd/v2/send', {
        clientID: clientId,
        language,
        recapResp,
      })
    )
    yield put(fastSignupActions.forgotPasswordSuccess(data.data))
  } catch (e) {
    yield put(fastSignupActions.forgotPasswordFailed(getError(e)))
  }
}

export function* checkReferralCode({ payload }) {
  try {
    yield put(fastSignupActions.checkReferralCodeStart())
    const { referralCode } = payload
    const { data } = yield call(() =>
      // public endpoint
      DivitAPI.post('verify/referralCode', { referralCode })
    )
    if (data?.data !== 'valid') {
      throw new Error('invalid referral code')
    }
    yield put(fastSignupActions.checkReferralCodeSuccess(data.data))
  } catch (e) {
    yield put(fastSignupActions.checkReferralCodeFailed(getError(e)))
  }
}

export default null
