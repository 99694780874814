import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import BottomSheet from '@/components/BottomSheet'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import Checkbox from '@/components/ui/CheckBox'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { t } from '@/locales/format'

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.111rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    right: calc(100% / 12);
  }
`

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
`

const List = styled.div`
  margin-top: 0.889rem;
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.889rem 0;
  cursor: pointer;
`

const ItemText = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ActivityFilterModal = ({ isOpen, filtered, onApply, onClose }) => {
  const PAYMENT_TYPE_FILTERS = [
    { label: t('filter.paynow'), value: 'paynow-order' },
    { label: t('filter.paylater'), value: 'paylater-order' },
    { label: t('filter.spend'), value: 'giftcard-order' },
    { label: t('filter.convert'), value: 'miles-conversion' },
    // {  label: 'donate', value: 'donate-order' },
  ]

  const [result, setResult] = useState(filtered || [])

  useEffect(() => {
    if (isOpen) {
      setResult(filtered)
    }
  }, [isOpen])

  const onClickItem = (item) => () => {
    if (result.find((f) => f.value === item.value)) {
      const ret = result.filter((f) => f.value !== item.value)
      setResult(ret)
    } else {
      const ret = result.concat([item])
      setResult(ret)
    }
  }

  const onClickApply = () => {
    onApply(result)
    onClose()
  }

  const onClickClear = () => {
    setResult([])
  }

  const onClickClose = () => {
    setResult(filtered)
    onClose()
  }

  return (
    <BottomSheet open={isOpen} fullscreen>
      <CSLayout.CSContainer>
        <CSLayout.CSContent>
          <PaddingContent>
            <CloseButton onClick={onClickClose}>
              <Icon
                renderImage={() => <CloseSvg />}
                width="2rem"
                height="2rem"
              />
            </CloseButton>
            <Spacer height="3.556rem;" />
            <Title>{t('common.filters')}</Title>
            <Spacer height="1.778rem" />
            <SubTitle>{t('activity.types')}</SubTitle>
            <List>
              {PAYMENT_TYPE_FILTERS.map((f) => {
                const isSelected = result.find((v) => v.value === f.value)
                return (
                  <Item key={f.value} onClick={onClickItem(f)}>
                    <ItemText>{f.label}</ItemText>
                    <Checkbox checked={isSelected} />
                  </Item>
                )
              })}
            </List>
          </PaddingContent>
        </CSLayout.CSContent>
      </CSLayout.CSContainer>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <SecondaryButton onClick={onClickClear}>
              {t('common.clear')}
            </SecondaryButton>
            <PrimaryButton onClick={onClickApply}>
              {t('common.viewresults')}
            </PrimaryButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </BottomSheet>
  )
}

export default ActivityFilterModal
