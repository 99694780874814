/* eslint-disable */
/* add this style for frontend only

<style>
*{ line-height: 1.25; }
body { padding: 0 1rem; }
</style> 
*/

const html = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style>
      *{ line-height: 1.25; }
      body { padding: 0 1rem; }
   </style> 
  </head>
  <body class="c28" style="background-color: #ffffff;max-width: 500pt;padding: 20pt 30pt 30pt 30pt;">
    <div>
      <p class="c16" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: left;height: 11pt;">
        <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"></span>
      </p>
    </div>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">支付貸款協議：</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">條款和細則</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c11" style="font-weight: 700;">請仔細閱讀本協議</span>
      <span>。本協議包含與 </span>
      <span class="c11" style="font-weight: 700;">DIVIT LIMITED</span>
      <span>（在本協議中稱為“ </span>
      <span class="c11" style="font-weight: 700;">Divit </span>
      <span>”）的貸款協議的條款和細則。 Divit 基於此條款和細則同意向您即借款人（在本文件中稱為“您”或“您的”）購買的某些商品和/或服務（“</span>
      <span class="c11" style="font-weight: 700;">條款和細則</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”）貸出款項。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>在 Divit 貸出您要求的金額之前，您必須完全同意條款和細則。如果您於 Divit 應用程式中剔選表示同意條款和細則的方格，然後點擊“</span>
      <span class="c21" style="font-style: italic;">下一步</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”以確認同意繼續，即表示您透過電子方式簽署本協議並確認條款和細則於您與 Divit 之間具有約束力。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本協議附有《放債人條例》（香港法例第 163 章）的撮要。在訂立本協議前，請確保您已仔細閱讀該撮要。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本協議記錄了貸款條款和您的合約責任，因此它是一份重要文件。本協議簽署版本的副本會在您確認同意接受條款和細則後供您下載。Divit 亦會發送一份本協議的副本至您提供的電郵地址，以供記錄。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>1. </span>
      <span class="c3 c6" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">日期</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>1.1 本協議由您和 Divit 訂立於：</span>
      <span class="">{{(index (index .Instalments 0).PaidRecords 0).PaidDate}}</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>1.2 本協議的貸款的貸出日期：</span>
      <span class="">{{(index (index .Instalments 0).PaidRecords 0).PaidDate}}</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>2. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">放債人詳情</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">2.1 Divit Limited為本協議的放債人。Divit Limited是一間根據香港法律註冊成立的有限公司（註冊編號：2869832），並持有放債人牌照（編號：{{.LicenceNumber}}）。 Divit Limited 的註冊辦事處位於香港西營盤威利麻街6號威華商業中心25樓2505室。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>3. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">借款人詳情</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">3.1 您是本協議項下的借款人。您的姓名和地址如下：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(a) 姓名：</span>
      <span class="">{{.Recipient.FirstName}} {{.Recipient.LastName}}</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"></span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(b) 地址：</span>
      <span class="">{{.Recipient.Address}}</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"></span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>4. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">貸款詳情</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>4.1 Divit 同意以貸款的方式向您貸出款項，並且您同意向 Divit 借款，貸款本金金額是 </span>
      <span class="">{{.Order.LoanAmountInWords}} ({{.Order.LoanAmount}})</span>
      <span>（“</span>
      <span class="c11" style="font-weight: 700;">您的貸款金額</span>
      <span>”）並在所有情況下均受本協議（“</span>
      <span class="c11" style="font-weight: 700;">貸款</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”）的條款和細則約束。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">4.2 Divit 在收到您的第一筆根據下文第6段的付款後向您提供您的貸款金額。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">4.3 您同意並在此確認：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(a) Divit不會直接向您支付您的貸款金額而會代表您支付您的購買款項予參與商戶；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(b) 當Divit 向參與商戶支付您的貸款金額即代表Divit已履行 Divit 根據本協議向您貸出您的貸款金額的義務。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>4.4 如果您的購買金額超過您的貸款金額，您要求 Divit 而且同意Divit代表您於支付您的貸款金額的同一天向參與商戶轉賬您的購買金額與您的貸款金額之間的差額（“</span>
      <span class="c11" style="font-weight: 700;">超額金額</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”）。您同意並確認：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(a) 超額金額不構成貸款的一部分；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(b) 您將在購買當日透過任何一種認可的付款方式（定義見下文第 7.1 段）向 Divit 轉賬與超額金額相等的金額 ；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(c) 您將在購買當日向 Divit 支付轉賬服務費 </span>
      <span class="">{{.Order.TransferServiceFeeInWords}} ({{.Order.TransferServiceFee}})</span>
      <span> （“</span>
      <span class="c11" style="font-weight: 700;">轉賬服務費</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”），以作為 Divit 代表您向參與商戶轉賬超額金額的服務費。為免產生疑問，此轉賬服務費不會構成客戶服務費（定義見下文第 5.1 段）或貸款利息的一部分。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">4.5 在本協議中：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(a) “</span>
      <span class="c11" style="font-weight: 700;">參與商戶</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”指通過其網站向您提供向 Divit 貸款購買的機會，並且為貸款事宜將您導向至 Divit 應用程式的商戶；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(b) “</span>
      <span class="c11" style="font-weight: 700;">購買</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”指您同意向參與商戶購買的商品和/或服務所支付的金額。該金額相等於您的貸款金額及超額金額之總和；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(c) “</span>
      <span class="c11" style="font-weight: 700;">Divit 應用程式</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”是指由 Divit 開發和運營的流動應用程式，以處理用戶向 Divit 貸款購買參與商戶的產品和/或服務所需的金額；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(d) “港元”和“港幣”是指香港的法定貨幣。</span>
    </p>
    <p class="c2 c19" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;height: 11pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"></span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>5.</span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">利息</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>5.1 基於 Divit 與您訂立的本協議，您同意向 Divit 支付“</span>
      <span class="c11" style="font-weight: 700;">客戶服務費</span>
      <span>”。 客戶服務費為：</span>
      <span class="">{{.Order.LoanServiceFeeInWords}} ({{.Order.LoanServiceFee}})</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>5.2 客戶服務費代表貸款金額的利息，年息百分率為：</span>
      <span class="">{{.Order.APRateInWords}} ({{.Order.APRate}})</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">。 該利率是根據《放債人條例》（香港法例第 163 章）附表 2 及假設您根據下文第 6.1段所列的擬議付款時間表付款作出計算。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>6.</span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"> 還款條款</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>6.1 您同意根據以下時間表（“</span>
      <span class="c11" style="font-weight: 700;">付款時間表</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”）向 Divit 支付三筆款項，以償還貸款、支付客戶服務費、（如適用）超額金額和轉賬服務費：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(a) 第1次付款：</span>
      <span class="">{{(index .Instalments 0).Amount}}</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">，於您訂立本協議當日到期並應支付；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(b) 第2次付款：</span>
      <span class="">{{(index .Instalments 1).Amount}}</span>
      <span>，您已提議在 </span>
      <span class="">{{(index .Instalments 1).ReminderDate}}</span>
      <span> 支付，但在 </span>
      <span class="">{{(index .Instalments 1).DueDate}}</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"> 到期並須在該天支付； 及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(c) 第3次付款：</span>
      <span class="">{{(index .Instalments 2).Amount}}</span>
      <span>，您已提議在 </span>
      <span class="">{{(index .Instalments 2).ReminderDate}}</span>
      <span> 支付，但在 </span>
      <span class="">{{(index .Instalments 2).DueDate}}</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"> 到期並須在該天支付，</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>6.2 您提議支付每筆款項的日期在本協議中稱為“</span>
      <span class="c11" style="font-weight: 700;">付款日</span>
      <span>”，及每筆款項指定到期付款日稱為“</span>
      <span class="c11" style="font-weight: 700;">到期日</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">6.3 如果：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(a) 任何付款日為非營業日，該款項應在緊接的營業日支付； 及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(b) 任何到期日為非營業日該款項應在緊接的下一個營業日到期並支付。</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>就本協議而言，“</span>
      <span class="c11" style="font-weight: 700;">營業日</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”是指除星期六、星期日或公眾假期外，商業銀行在香港營業的日子。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">6.4 您同意 Divit 可自行決定分配每筆款項中的本金和應付利息比例。您明白及確認每筆款項中的本金和應付利息比例可能不相等。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">6.5 除非是按照付款時間表進行的還款，Divit 不接受部分貸款款項的還款。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>6.6 除非Divit行使酌情權並書面同意，Divit 不接受任何更改到期日的要求。 為免產生疑問，當 Divit 書面同意您可以在到期日之前還款，您同意並確認：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(a) 此等自願提前還款並不會影響貸款條款；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(b) 該貸款的利率計算仍將按照您在上文第 6.1 段中提出的付款時間表所決定，而在上文第 5.1 段列明的年息百分率將保持不變，不論您是否已在到期日之前償還相關的未償還款項。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>7. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">付款</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">7.1 除非 Divit 另有書面同意，您同意並承諾使用以下方式支付本協議內的所有款項（包括但不限於第 6.1 段內的每筆款項及第 11 段內的任何行政費）：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(a)由香港銀行同業結算有限公司營運的快</span>
      <span class="c24" style="background-color: #ffffff;color: #4e4e4e;">速</span>
      <span>支付系統</span>
      <span class="c24" style="background-color: #ffffff;color: #4e4e4e;">(「轉數快」)</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">； 或</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(b)由香港上海匯豐銀行營運的PayMe。</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span>各種付款方式均稱為“</span>
      <span class="c11" style="font-weight: 700;">認可的付款方式</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">7.2 除認可的付款方式外，Divit 可不時但沒有義務通知您有關其他可接受的付款方式。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>8. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">資格</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">8.1 您向 Divit聲明並保證：</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(a) 您是以個人名義而不是為或代表任何其他人訂立本協議；</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(b) 您是年滿 18 歲並居住於香港的香港居民；</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(c) 您擁有有效的電郵地址，和由香港電信運營商發出的有效手機號碼；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(d) 您擁有可連結的認可付款方式及有效的帳戶或信用卡，而您可以使用該付款方式根據協議向 Divit 付款；</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(e) 您心智健全，有能力訂立本協議並履行您在本協議中的義務；</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(f) 您就本協議向 Divit 提供的所有資料在所有層面均為真實、完整和準確；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(g) 您未曾與除 Divit 以外的任何人就本協議中採購、相議、獲取或申請貸款及/或擔保或就確保任何還款達成或簽署任何協議；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(h) 您有還款能力，目前沒有破產、進行訴訟或行政程序，或據您所知，您沒有與任何其他貸款人達成任何可能會影響您償還本協議中所有款項的任何協議；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(i) 在您根據本協議的條款全額支付您的欠款之前，您不會自願提交任何破產申請。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">8.2 您在上文第 8.1段作出的每項聲明和保證均由您在訂立本協議當日作出，並在參考相關的事實和情況後，被視作在本協議期限內一直重覆，直至您根據本協議向 Divit 全數並不可撤銷地支付所有款項為止。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">8.3 您同意並確認：</span>
    </p>
    <p class="c2 c4" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(a) 您將向 Divit 提供 Divit不時合理地要求與本協議相關的所有資料； 及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(b) 如果您之前提供給 Divit 的資料有任何更改，您將立即通知 Divit，</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">對於因您未能提供任何此類資料或未將任何此類更改通知 Divit 而對您造成的任何損失或費用，Divit 概不負責。 </span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">8.4 您授權 Divit 在符合適用的法律或法規要求下及在適當的情況下檢查、驗證和進行風險評估，並直接或通過第三方進行任何必要的查詢，以驗證您的身份及評估您在本協議中的付款能力。您進一步同意並授權 Divit 與任何參與商戶及/或任何其他第三方分享與本協議的訂立或履行有關及/或必要的資料。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>9. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">洽商和協議地點</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">9.1 您確認本協議的洽商和協議地點為 Divit 的註冊辦事處：香港西營盤威利麻街6號威華商業中心25樓2505室。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>10. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">放債人條例撮要</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">10.1 您確認您已閱讀本協議附件所載的放債人條例撮要並了解其規定。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>11. </span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">逾期付款</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">11.1 如果您未能按照付款時間表於到期日前付款，在不影響 Divit 在下文第 13 段的權利的情況下：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">(a) 在您未能付款後的至少五 (5) 個曆日內，每日通知將發送到您已註冊的電郵地址，並通過短訊發送到您提供給 Divit 的手機號碼，以提醒您的付款義務；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span>(b) 如果任何款項超過五 (5) 個曆日仍未支付（“</span>
      <span class="c11" style="font-weight: 700;">逾期款項</span>
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">”），Divit 有權在逾期款項仍未償還的情況下，就每筆逾期款項向您收取港幣 100 元，作為安排收取逾期款項的行政費。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">11.2 您同意 Divit 可以在未通知您或未經您同意，及無論是否已發生違約事件的情況下，任命和僱用第三方收賬公司就您欠 Divit 的任何未償還款項採取和執行任何行動。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">12.</span>
      <span class="c6 c0" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">抵銷</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">12.1 您同意 Divit 除了在法律上可能享有的任何一般留置權或類似權利外，Divit 可能隨時在</span>
      <span>沒有提前通知的情況下，</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">合併您在債務上的利益或</span>
      <span class="c7" style="background-color: #ffffff;color: #4d5156;">整合 </span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">Divit 持有的任何</span>
      <span>款項</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">，及</span>
      <span>抵銷或</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">轉移任何一筆或多於一筆的</span>
      <span>款項</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">以償還您對 Divit 的任何負債。如果您對 Divit 的任何負債</span>
      <span class="c10" style="color: #202124;">涉及有待確定</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">或將來事件，</span>
      <span class="c10" style="color: #202124;"> Divit 對您支付</span>
      <span class="c17" style="background-color: #ffffff;color: #3c4043;">足以覆蓋負債</span>
      <span class="c10" style="color: #202124;">的任何一筆或多於一筆的款項的責任</span>
      <span class="c17" style="background-color: #ffffff;color: #3c4043;">應暫緩直至到</span>
      <span class="c23" style="color: #3c4043;">有待確定</span>
      <span class="c1 c17" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #3c4043;">或未來事件發生為止。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">13.</span>
      <span class="c6 c3" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">違約</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">13.1 如果發生以下任何事件（均稱為“</span>
      <span class="c0 c11" style="background-color: #ffffff;color: #202124;font-weight: 700;">違約事件</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">”），Divit </span>
      <span class="c10" style="color: #202124;">可隨時向您聲明</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">您的貸款的任何和所有未償還金額，加上所有應計但未支付利息、行政費、可報銷費用和您根據本協議對 Divit 的其他債項立即到期並需支付：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 您未能在到期日按本協議規定的方式支付您應在相關到期日支付的任何款項；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(b) 您未能適當履行您在本協議除上文第 6.1 </span>
      <span>段</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">中提及的您的付款義務外的任何義務並且無法補救此類未能履行</span>
      <span class="c10" style="color: #202124;">義務</span>
      <span>。</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">如果未能履行</span>
      <span class="c10" style="color: #202124;">義務可能被</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">補救，則在 Divit 向您發送未能履行義務通知後的七 (7)個曆日內未補救，或者 Divit 認為您違反了本協議的任何規定；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(c) 您在本協議中或以任何形式或文件提供給 Divit 的任何陳述、保證或聲明，在作出或重複時在任何</span>
      <span class="c10" style="color: #202124;">層面上是不正確</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">或具有誤導性；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(d) 您是或被宣佈</span>
      <span class="c20" style="color: #4d5156;">無償貸能力</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">或破產；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(e) 您履行本協議中的任何義務</span>
      <span class="c10" style="color: #202124;">為</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">非法或變得非法；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(f) 您就任何貸款協議、抵押透支、擔保、賠償或信用證或任何擔保文件訂立或作為其中一方或承擔任何責任，而這些協議可能會禁止、阻礙或延遲您的還款或您履行本協議中的其他義務；或</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(g) 您的死亡或喪失能力。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">14.</span>
      <span class="c6 c0" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;"> 賠償</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">14.1 您應</span>
      <span>全數賠償</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;"> Divit 因執行或維護本協議中的任何權利而合理產生的所有</span>
      <span>費用及開支</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">，包括但不限於</span>
      <span class="c10" style="color: #202124;">因Divit 聘請法律顧問及/或收賬公司或其他代理收回根據本協議的應付款項而引致的有關費用及開支</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15. </span>
      <span class="c6 c0" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">對參與商戶的行為不承擔任何責任</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15.1 Divit沒有亦不會承擔任何</span>
      <span class="c10" style="color: #202124;">因以下事項而產生的</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">責任，不論是就合同、侵權（包括疏忽）、違反法定義務或其他（即使可預見）方面</span>
      <span class="c10" style="color: #202124;">的任何損失和賠償</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 參與商戶向您提供的任何產品或服務符合其規格或適用性，或是否無缺陷或具有適銷性；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 您與任何參與商戶之間的任何交易或合同；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(c) 任何參與商戶的</span>
      <span class="c7" style="background-color: #ffffff;color: #4d5156;">償付能力</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">或任何參與商戶能否遵守其向您提供的</span>
      <span class="c10" style="color: #202124;">條款及細則</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15.2 參與商戶</span>
      <span class="c10" style="color: #202124;">將負責</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 您為任何產品或服務所下的任何訂單的可用性、供應、交付、退貨、退款和履行情況，包括已交付的產品或服務是否符合參與商戶網站的描述； 及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 其宣傳的產品或服務的質量、安全性或合法性，產品或服務描述的準確性或參與商戶銷售和交付產品或服務的能力。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15.3 您向 Divit 承諾遵守您與參與商戶達成的任何條款和</span>
      <span class="c10" style="color: #202124;">細則</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16. </span>
      <span class="c6 c0" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">退款</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16.1 您同意如果參與商戶就任何購買向您全額或部分退款，包括任何機場費用或政府徵稅費</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">，費用或收費（“</span>
      <span class="c0 c11" style="background-color: #ffffff;color: #202124;font-weight: 700;">退款</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">”），除非另有法律要求，退款將不會由參與商戶直接向您支付。</span>
      <span class="c10" style="color: #202124;">參與商戶將向Divit直接支付相關退款</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16.2 </span>
      <span>當</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;"> Divit 收到任何退款：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(a) 貸款的所有未償還餘額，加上所有應計但未付的利息、行政費、可報銷費用和您根據本協議</span>
      <span>所</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">欠 Divit 的其他款項，將立即到期並需支付；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 除了 Divit 在法律上可能有權享有的任何一般留置權或類似權利外，Divit 還有權將退款與您的債務合併，並將退款用於清還您對 Divit 的負債；</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(c) 如果退款金額超出您對 Divit 的任何負債及 Divit 因接收和處理退款產生的任何費用或收費的金額，Divit 將在合理的時間內，並取決於您通知 Divit 您希望其使用</span>
      <span>的認可</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">的付款方式，使用相關的</span>
      <span>認可的</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">付款方式處理</span>
      <span class="c10" style="color: #202124;">方法</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">向您支付超出部分的金額，並按照付款處理</span>
      <span class="c10" style="color: #202124;">方法</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">的做法進行支付；及</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(d) 您</span>
      <span class="c10" style="color: #202124;">確認</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">並同意使用</span>
      <span>認可的</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">付款方式支付的任何款項均受制於 Divit 無法控制的方式處理，並且同意不追究 Divit任何對於由任何金融機構或其代理人</span>
      <span class="c10" style="color: #202124;">因</span>
      <span>認可的</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">付款方式而直接或間接造成任何延誤的責任。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16.3 在 Divit 處理退款之前，</span>
      <span class="c10" style="color: #202124;">您仍有責任按照第 6段規定的付款時間表支付款項</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">17. </span>
      <span class="c6 c0" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">個人資料</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c10" style="color: #202124;">17.1 您同意您向 Divit 提供的所有資料均可根據本協議</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">用於</span>
      <span class="c1 c10" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;">相關的目的和向相關的人士披露。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">17.2 Divit 可以在保密的基礎上向任何提議與 Divit 訂立與本協議有關的合同安排的人（“</span>
      <span class="c0 c11" style="background-color: #ffffff;color: #202124;font-weight: 700;">獲准披露者</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">”），或向 Divit 或獲准披露者的控股公司、附屬公司、信用機構、</span>
      <span class="c10" style="color: #202124;">收賬</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">公司或顧問或任何向 Divit 提供服務的其他人或獲准披露者披露您的任何資料、貸款資料、本協議以及 Divit 根據其個人資料私隱條例不時生效的政策和做法下就有關使用和披露個人資料方面認為恰當的文件。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">17.3 您</span>
      <span class="c10" style="color: #202124;">確認</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">您已</span>
      <span class="c10" style="color: #202124;">透過</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;"> Divit 的網站收到一份 Divit 個人資料私隱</span>
      <span class="c10" style="color: #202124;">政策</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">的副本並同意</span>
      <span class="c10" style="color: #202124;">該</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">個人資料私隱</span>
      <span class="c10" style="color: #202124;">政策的條款</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">18. </span>
      <span class="c6 c0" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">通知</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">18.1 Divit 向您提供的通知和其他通訊會</span>
      <span class="c10" style="color: #202124;">經以下方式</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">以書面形式</span>
      <span class="c10" style="color: #202124;">並</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">以英語提供：</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 通過電郵發送至您提供給 Divit 的電郵地址；及/或</span>
    </p>
    <p class="c2 c9" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;margin-left: 36pt;">
      <span class="c0 c1" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 通過短訊發送到您提供給 Divit 的手機號碼。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">18.2 通過電郵及/或短訊發送的通知或其他通訊，將被視為在發送後的第二天收到。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">18.3 如果您更改了您的電郵地址或手機號碼而未通知 Divit 此類更改，則Divit通過之前提供的電郵地址或手機號碼向您發送的通知將被視為</span>
      <span class="c10" style="color: #202124;">符合</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;"> Divit 在本條款下的通知義務。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">19. </span>
      <span class="c6 c10" style="font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;">副本</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">19.1 本協議可簽署任何數量的副本，當所有副本一併交付給 Divit 時，應構成一份相同的文書。任何一方均可通過簽署任何此類副本來簽訂本協議。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">20. </span>
      <span class="c11 c10" style="font-weight: 700;color: #202124;">其他事項</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">20.1 本協議的任何中文譯本僅供參考。如中英文版本有任何不一致或歧義，一概以英文版本為準。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">20.2 如果本協議的任何條款或其中的任何部分被其所適用的任何法律認定為無效、非法或不可執行，則該條款僅在該範圍內被認定為無效、非法或不可執行，並且不會以任何方式影響或損害該條款的其餘部分或本協議的其他條款的可執行性。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">20.3 您明確</span>
      <span>確</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">認您已閱讀本協議並理解其條款，並且您和 Divit 均同意本協議構成您與 Divit 之間關於貸款的完整協議。除本協議中明確規定的內容外，</span>
      <span class="c10" style="color: #202124;">Divit與您沒有向對方</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">作出</span>
      <span class="c10" style="color: #202124;">其他</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">任何承諾、誘導、陳述或協議。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">20.4 除非本協議另有明文規定，否則非本協議</span>
      <span>任何一方</span>
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">無權根據《合同（第三方權利）條例》（香港法例第 623 章）執行或享有本協議任何條款的利益。</span>
    </p>
    <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">20.5 本協議受香港法律管轄並按其解釋。您在此不可撤銷地同意香港法院擁有專屬管轄權。</span>
    </p>
    <p class="c12 c19" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;height: 11pt;">
      <span class="c1 c10" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;"></span>
    </p>
    <p class="c12 c19" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;height: 11pt;">
      <span class="c1 c10" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;"></span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c11 c3 c18" style="-webkit-text-decoration-skip: none;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;color: #000000;">附件</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">放債人條例</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">以下所列的《放債人條例》條文撮要，對保障訂立貸款協議的各方均至為重要，應小心閱讀。該撮要並非法例的一部分，如有疑問，應參閱《放債人條例》有關條文。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c3 c14" style="-webkit-text-decoration-skip: none;font-weight: 400;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">《放債人條例》第III部撮要——放債人進行的交易</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第18條列出關於放債人作出貸款的規定。每份貸款協議須以書面訂立，並由借款人於該協議作出後的7天內及於該筆款項貸出之前簽署。在簽訂協議時，須將已簽署的一份協議摘記，連同本撮要一份給予借款人。該摘記須載有該宗貸款的詳盡細則，包括還款條款、保證形式及利率。不符合上述規定的協議不得予以強制執行，除非法庭信納不強制執行該協議並不公平。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第19條訂定，如借款人提出書面要求及就有關開支而支付訂明費用，則放債人須將該借款人在貸款協議下當時的債務情況(包括已還款項、到期或即將到期的款項及利率)的結算書正本及副本一份給予借款人。借款人須在該結算書的副本上簽註文字，表示已經收到該結算書的正本，並將經如此簽註的該結算書副本交回該放債人。放債人則須在與該結算書有關的協議持續期間保留該份已交回的結算書副本。如放債人不照辦，即屬犯罪。如借款人提出書面要求，放債人亦須供給與該宗貸款有關或與保證有關的任何文件的副本。但上述要求，不得在一個月內提出超過一次。放債人如無充分理由而沒有遵照本段所述的要求辦理，則不得收取在該等要求沒有照辦期間的利息。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第20條訂定，除非保證人亦是借款人，否則須在協議作出後的7天內，給予保證人一份已簽署的協議摘記、一份保證文書(如有的話)及詳列須支付款項總額的結算書。如保證人在任何時間提出書面要求(不得在一個月內超過一次)，放債人須給予他一份已簽署並詳列已支付款項總額及尚欠款項總額的結算書。放債人如無充分理由而沒有遵照辦理，則不得在該項要求沒有照辦期內強制執行該項保證。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第21條訂定，借款人以書面通知後，可隨時將貸款及計算至還款日期為止的利息償還，放債人不得因借款人提早還款而徵收較高利率。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">放債人如是財政司根據《放債人條例》第33A(4)條以憲報公告認可的放債人或認可的社團的成員，則上述條文不適用。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第22條述明，任何貸款協議如訂定須支付複利或訂定不准以分期方式償還貸款，均屬非法。此外，任何貸款協議如訂定到期而未支付的款項須收取較高利率，亦屬非法，但該協議可訂定，未償還的本金部分及利息須收取單利，但利率不得超過在沒有拖欠的情況下須支付的利率；但如法庭信納，該協議如因不符合本條規定而成為非法並不公平，則可宣布該份非法協議全部或部分合法。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第23條述明，如放債人在訂立貸款協議時或接受貸款保證時並未領有牌照，則與該放債人訂立的貸款協議及給予他的保證不得強制執行；但如法庭信納，該協議或保證如因本條規定而不能強制執行並不公平，則可宣布該協議或保證的全部或部分可予強制執行。</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c14 c3" style="-webkit-text-decoration-skip: none;font-weight: 400;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">《放債人條例》第IV部撮要——過高利率</span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第24條釐定任何貸款的最高實際利率為年息60%(“實際利率”須按照本條例附表2計算)任何貸款協議如訂定更高的實際利率，則不得強制執行，而放債人亦可被檢控。此最高利率可由立法會予以變更，但已存在的協議則不受影。對於向繳足款股本不少於$1,000,000的公司作出的貸款或作出如此貸款的人，本條並不適用。 </span>
    </p>
    <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;">本條例第25條訂定，在強制執行貸款協議或強制執行貸款保證的法庭法律程序中，或在借款人本人或保證人本人向法庭申請濟助時，法庭可查察該協議的條款，以視該等條款是否極之不公平或利率過高(實際利率如超逾年息48%或立法會所訂的其他利率，即可單憑該理由而推定該利率過高)，而法庭在顧及所有情況後，可將該協議的條款更改，使其對協議各方均公平。對於向繳足款股本不少於$1,000,000的公司作出的貸款或作出如此貸款的人，本條並不適用。 </span>
    </p>
    <div>
      <p class="c16" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: left;height: 11pt;">
        <span class="c1 c3" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #000000;"></span>
      </p>
    </div>
  </body>
</html>
`

export default html
