import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(p) => p.ratio};
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${(p) =>
    p.isCentered &&
    `
  display: flex;
  justify-content: center;
  align-items: center;
  `}
`

const RatioContent = ({
  className,
  style,
  ratio = '100%',
  isCentered,
  children,
}) => (
  <Container className={className} style={style} ratio={ratio}>
    <Inner isCentered={isCentered}>{children}</Inner>
  </Container>
)

export default RatioContent
