import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DivitMilesBWSvg } from '@/assets/miles/divit-miles-logo-bordered.svg'
import { ReactComponent as DivitMilesYellowSvg } from '@/assets/miles/divit-miles-yellow-icon.svg'
import Icon from '@/components/Icon'
import { FormattedMiles } from '@/utils/Price'

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(p) => p.fontSize};
`

const Text = styled.div`
  &:first-child {
    margin-right: 4px;
  }
  &:last-child {
    margin-left: 4px;
  }
`
const CustomIcon = styled(Icon)`
  border: 2px solid black;
  border-radius: 50%;
`

const MilesValue = ({
  style,
  className,
  type = 'yellow',
  miles,
  fontSize = '0.778rem',
  isSigned = true,
}) => {
  const isNegative = miles < 0
  const MilesIcon = type === 'bw' ? DivitMilesBWSvg : DivitMilesYellowSvg

  return (
    <Container style={style} className={className} fontSize={fontSize}>
      <Text>{isSigned && (isNegative ? '-' : '+')}</Text>
      {type === 'outlined-yellow' && (
        <CustomIcon
          renderImage={() => <MilesIcon />}
          width={fontSize}
          height={fontSize}
        />
      )}
      {type !== 'outlined-yellow' && (
        <Icon
          renderImage={() => <MilesIcon />}
          width={fontSize}
          height={fontSize}
        />
      )}
      <Text>{FormattedMiles(Math.abs(miles))}</Text>
    </Container>
  )
}

export default MilesValue
