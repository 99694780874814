import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
  title: '',
  content: '',
  actions: [],
  closable: true,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    reset: () => initialState,
    openModal: (state, { payload }) => {
      state.isOpen = true
      state.title = payload.title
      state.content = payload.content
      state.actions = payload.actions
      state.closable = payload.closable
    },
    closeModal: (state) => {
      state.isOpen = false
    },
  },
})

export const { actions } = modalSlice

export default modalSlice.reducer
