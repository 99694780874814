import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import DivitLogo from '@/assets/miles/merchants/divit.png'
import Icon from '@/components/Icon'
import * as MilesPrograms from '@/constants/MilesPrograms'
import { merchantsSelector } from '@/redux/lookup/lookupSelector'
import { AirlineLogoByParnterId } from '@/utils/Airline'
import * as GiftCard from '@/utils/GiftCard'

const MerchantIcon = styled(Icon)`
  background-color: white;
  border: 1px solid rgba(28, 28, 28, 0.2);
  padding: 0.2rem;
`

const Image = styled.img`
  object-fit: contain;
  display: block;
`

const MerchantLogo = ({ merchantID, giftCardBrandCode, width, height }) => {
  const images = useSelector(merchantsSelector)

  const getImage = () => {
    // giftcard  merchants
    if (giftCardBrandCode) {
      return <Image alt="" src={GiftCard.getBrandImageUrl(giftCardBrandCode)} />
    }

    // miles merchant
    if (merchantID === 'asiamiles' || merchantID === 'youair') {
      return <img alt="" src={MilesPrograms.getProgramImageUrl(merchantID)} />
    }

    // retail merchant
    const merchant = images.find((m) => m.merchantID === merchantID)
    if (merchant) {
      const logo = (merchant.logos || []).find((m) => m?.docType === 'logo')
      if (logo) {
        return <Image alt="" src={logo.filePath} />
      }
    }

    // airline merchant
    const AirlineLogo = AirlineLogoByParnterId(merchantID)
    if (AirlineLogo) {
      return AirlineLogo
    }

    return <Image alt="" src={DivitLogo} />
  }

  return (
    <MerchantIcon
      renderImage={() => getImage()}
      width={width}
      height={height}
      isCircle
    />
  )
}

export default MerchantLogo
