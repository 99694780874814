import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as AddProgramSvg } from '@/assets/common/plus.svg'
import { ReactComponent as GuideIconSvg } from '@/assets/miles/guide-icon-black.svg'
import { ReactComponent as ProgramEmptySvg } from '@/assets/miles/program-empty.svg'
import BackButton from '@/components/BackButton'
import { Button } from '@/components/Divit'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import MilesBalanceGuideModal from '@/components/miles/MilesBalanceGuideModal'
import MilesConvertModal from '@/components/miles/MilesConvertModal'
import MilesInput from '@/components/miles/MilesInput'
import MilesRateLabel from '@/components/miles/MilesRateLabel'
import Spacer from '@/components/Spacer'
import MilesPrograms from '@/constants/MilesPrograms'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { getConvertMilesServiceSelector } from '@/redux/lookup/lookupSelector'
import {
  divitMilesSelector,
  profileIdSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'
import * as DivitMiles from '@/utils/DivitMiles'
import { FormattedMiles } from '@/utils/Price'

import ConvertMilesUnavailableScreen from './ConvertMilesUnavailableScreen'

const Container = styled(PaddingContent)``

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`

const MilesBalance = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
`

const MilesBalanceText = styled.div`
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1rem;
  color: #1c1c1c;
  cursor: pointer;
`

const MainContent = styled(PaddingContent)`
  flex: 1;
`

const MilesConvertTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  width: 100%;
`

const MilesConvertSubTitle = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
`

const MilesRow = styled.div`
  height: 4.1rem;
  padding: 1rem 1.4rem 1rem 1.1rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`

const PartnerRow = styled(MilesRow)`
  outline: ${({ isSelected }) =>
    isSelected ? '1px solid #FFCC33' : '1px solid rgba(28, 28, 28, 0.2)'};
  margin-bottom: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`

const PartnerRowLeft = styled.div`
  display: flex;
  align-items: center;
`

const PartnerIcon = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(28, 28, 28, 0.2);
`

const PartnerIconText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PartnerText = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
`

const PartnerMemberNo = styled.div`
  color: #979797;
  font-size: 0.666rem;
`

const MilesInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const MilesValue = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.02em;
`

const LinkProgramText = styled.div`
  font-weight: 500;
  font-size: 0.887rem;
`

const EmptyProgramText = styled.div`
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  padding: 0 0.77rem;
`

const ConvertButton = styled(Button)`
  font-size: 0.88rem;
  padding: 1rem 0;
  color: #1c1c1c;
`

const MainButton = styled(Button)`
  min-width: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  color: #1c1c1c;
  padding: 1rem 0;
`

const convertModalDefaultState = {
  isShow: false,
  isSuccess: false,
  program: null,
}

const DivitMilesConvert = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const profile = useSelector(profileSelector)
  const profileId = useSelector(profileIdSelector)
  const divitMiles = useSelector(divitMilesSelector)
  const { convertMiles } = useSelector((s) => s.convertDivitMiles)
  const convertMilesService = useSelector(getConvertMilesServiceSelector)
  const isConvertMilesAvailable = !convertMilesService

  const [selectedProgram, setSelectedProgram] = useState(null)
  const [milesToConvert, setMilesToConvert] = useState(0)
  const [isShowMilesBalanceGuide, setIsShowMilesBalanceGuide] = useState(false)
  const [convertModalState, setConvertModalState] = useState(
    convertModalDefaultState
  )

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileMain' },
  })

  useEffect(() => {
    // page disabled
    history.replace('/')
    return () => dispatch({ type: 'convertDivitMiles/reset' })
  }, [])

  // default select first enabled membership
  useEffect(() => {
    if (selectedProgram === null) {
      const p = MilesPrograms.find((program) =>
        DivitMiles.findMembership(divitMiles.memberships, program.key)
      )
      if (p) {
        setSelectedProgram(p.key)
      }
    }
  }, [divitMiles.memberships])

  useEffect(() => {
    if (convertMiles.isSuccess) {
      const { data } = convertMiles
      const { program } = convertModalState
      const membership = DivitMiles.findMembership(
        divitMiles.memberships,
        program
      )
      setConvertModalState({
        isShow: true,
        isSuccess: true,
        program: convertModalState.program,
        membershipID: membership.metadata.membershipID,
        milesToConvert: data.burn.amount,
        createdAt: data.createdAt,
        txnID: data.txnID,
      })
    }
  }, [convertMiles])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!isConvertMilesAvailable) {
    const { periodEnd } = convertMilesService
    return <ConvertMilesUnavailableScreen periodEnd={periodEnd} />
  }

  if (!profile) return <></>

  const goToHome = () => history.push('/profile/miles')

  const goToOffers = () => history.push('/shop')

  const onClickBack = () => {
    history.push('/profile/miles')
  }

  const onToggleAddProgramModal = () => {
    history.push('/profile/miles/link')
  }

  const onClickConvert = (program) => () => {
    const membership = DivitMiles.findMembership(
      divitMiles.memberships,
      program
    )
    setConvertModalState({
      isShow: true,
      program,
      membershipID: membership.metadata.membershipID,
      milesToConvert,
    })
  }

  const onConvert = () => {
    const { program } = convertModalState
    const targetProgram = MilesPrograms.find((p) => p.key === program)
    dispatch({
      type: 'convertDivitMiles/convertMiles',
      payload: {
        convertToCurrency: targetProgram.currencyCode,
        numOfMiles: milesToConvert / 100,
      },
    })
  }

  const onCloseConvertModal = () => {
    setConvertModalState((s) => ({
      ...s,
      isShow: false,
    }))
  }

  const onCleanupConvertModal = () => {
    setConvertModalState((s) => convertModalDefaultState)
    setMilesToConvert(0)
  }

  const membershipAsiaMiles = DivitMiles.findMembership(
    divitMiles.memberships,
    'asiamiles'
  )
  const memberYouair = DivitMiles.findMembership(
    divitMiles.memberships,
    'youair'
  )

  const existedPrograms = [
    membershipAsiaMiles?.partner,
    memberYouair?.partner,
  ].filter((m) => m)

  const isConvertDisabled = milesToConvert === 0 || !selectedProgram
  const hasEmptyProgram = existedPrograms.length === 0
  const hasAllPrograms = existedPrograms.length === MilesPrograms.length

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Spacer height="1.33rem" />
          <HeaderRow>
            <BackButton onClick={onClickBack} />
            <MilesConvertTitle onClick={() => setIsShowMilesBalanceGuide(true)}>
              {tt(intl, 'miles.convertmiles')}
              <Spacer width="8px" />
              <Icon
                renderImage={() => <GuideIconSvg />}
                width="1rem"
                height="1rem"
              />
            </MilesConvertTitle>
          </HeaderRow>
          <Spacer height="0.5rem" />
          <HeaderRow>
            <MilesBalance onClick={() => setIsShowMilesBalanceGuide(true)}>
              <MilesBalanceText>
                {FormattedMiles(divitMiles.convertibleBalance)}
              </MilesBalanceText>
              <Spacer width="0.2rem" />
              {tt(intl, 'miles.divitmilesavailable')}
            </MilesBalance>
          </HeaderRow>
          <MainContent>
            <Spacer height="2.22rem" />
            {hasEmptyProgram && (
              <>
                <Spacer height="4rem" />
                <Icon
                  renderImage={() => <ProgramEmptySvg />}
                  width="6.256rem"
                  height="6.256rem"
                />
                <Spacer height="1.333rem" />
                <EmptyProgramText>
                  {tt(intl, 'loyalty.addrewardprogramlink')}
                </EmptyProgramText>
              </>
            )}
            {!hasEmptyProgram && (
              <>
                <MilesConvertSubTitle>
                  {tt(intl, 'common.from')}
                </MilesConvertSubTitle>
                <Spacer height="0.5rem" />
                <MilesInput
                  max={divitMiles.convertibleBalance / 100}
                  value={milesToConvert}
                  onChange={(v) => setMilesToConvert(v)}
                />
                <Spacer height="1rem" />
                <MilesConvertSubTitle>
                  {tt(intl, 'common.to')}
                </MilesConvertSubTitle>
                <Spacer height="0.5rem" />
                {MilesPrograms.map((program) => {
                  const membership = DivitMiles.findMembership(
                    divitMiles.memberships,
                    program.key
                  )
                  if (!membership) return null
                  const isSelected = selectedProgram === program.key
                  return (
                    <PartnerRow
                      key={program.key}
                      isSelected={isSelected}
                      onClick={() => setSelectedProgram(program.key)}
                    >
                      <PartnerRowLeft>
                        <PartnerIcon>
                          <Icon
                            renderImage={() => (
                              <img alt="" src={program.image} />
                            )}
                            width="2.2rem"
                            height="2.2rem"
                          />
                        </PartnerIcon>
                        <Spacer width="0.8rem" />
                        <PartnerIconText>
                          <PartnerText>{program.name}</PartnerText>
                          <Spacer height="4px" />
                          <PartnerMemberNo>
                            {membership.metadata.membershipID}
                          </PartnerMemberNo>
                        </PartnerIconText>
                      </PartnerRowLeft>
                      <MilesInfo>
                        <MilesValue>
                          {FormattedMiles(milesToConvert)}
                        </MilesValue>
                        <Spacer height="3px" />
                        <MilesRateLabel program={program.key} rate={1} />
                      </MilesInfo>
                    </PartnerRow>
                  )
                })}
                {!hasAllPrograms && (
                  <>
                    <Spacer height="0.5rem" />
                    <PartnerRow onClick={onToggleAddProgramModal}>
                      <PartnerRowLeft>
                        <Icon
                          renderImage={() => <AddProgramSvg />}
                          width="1.77rem"
                          height="1.77rem"
                        />
                        <Spacer width="0.8rem" />
                        <LinkProgramText>
                          {tt(intl, 'loyalty.rewardprogramlink')}
                        </LinkProgramText>
                      </PartnerRowLeft>
                    </PartnerRow>
                  </>
                )}
              </>
            )}
            <Spacer height="2.22rem" />
          </MainContent>

          <MilesBalanceGuideModal
            isOpen={isShowMilesBalanceGuide}
            onClose={() => setIsShowMilesBalanceGuide(false)}
          />
          <MilesConvertModal
            isOpen={convertModalState.isShow}
            isSuccess={convertModalState.isSuccess}
            profileId={profileId}
            program={convertModalState.program}
            milesToConvert={convertModalState.milesToConvert}
            milesBalance={divitMiles.convertibleBalance}
            membershipID={convertModalState.membershipID}
            createdAt={convertModalState.createdAt}
            goToHome={goToHome}
            goToOffers={goToOffers}
            onClickConvert={onConvert}
            onClose={onCloseConvertModal}
            onCleanup={onCleanupConvertModal}
            error={convertMiles.error}
          />
          <LoadingModal loading={convertMiles.isLoading} />
        </Container>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <Container>
          {hasEmptyProgram && (
            <MainButton onClick={onToggleAddProgramModal}>
              {tt(intl, 'common.linknow')}
            </MainButton>
          )}
          {!hasEmptyProgram && (
            <ConvertButton
              type="stretch"
              disabled={isConvertDisabled}
              onClick={onClickConvert(selectedProgram)}
            >
              {tt(intl, 'common.continue')}
            </ConvertButton>
          )}
        </Container>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default DivitMilesConvert
