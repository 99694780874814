import { createSlice } from '@reduxjs/toolkit'

import PaymentSettings from '@/constants/payment'

import AsyncState from '../utils/AsyncState'

const payWithFpsSlice = createSlice({
  name: 'payWithFps',
  initialState: {
    cachedFpsData: null,
    fetchOrder: AsyncState.create(),
    generateFpsLink: AsyncState.create(),
    payByEDDA: AsyncState.create(),
    refreshOrder: AsyncState.create(),
    fpsMethod: PaymentSettings.PAYMENT_MODE_FPSQRCODE,
    disableManualFPS: false,
    orderID: '',
    instalmentID: '',
    paylater: false,
    instalmentNum: 1,
    orderCurrency: '',
    paymentMethod: '',
  },
  reducers: {
    cacheFpsData: (state, action) => {
      state.cachedFpsData = action.payload
    },
    clearCachedFpsData: (state) => {
      state.cachedFpsData = null
    },
    reset: (state) => {
      state.fetchOrder = AsyncState.reset()
      state.generateFpsLink = AsyncState.reset()
      state.payWithEDDA = AsyncState.reset()
      state.refreshOrder = AsyncState.reset()
      state.fpsMethod = PaymentSettings.PAYMENT_MODE_FPSQRCODE
      state.disableManualFPS = false
      state.orderID = ''
      state.instalmentID = ''
      state.paylater = false
      state.instalmentNum = 1
      state.orderCurrency = ''
      state.paymentMethod = ''
    },
    fetchOrderStart: (state) => {
      state.fetchOrder = AsyncState.start()
    },
    fetchOrderSuccess: (state, action) => {
      state.fetchOrder = AsyncState.success(action.payload)
    },
    fetchOrderFailed: (state, action) => {
      state.fetchOrder = AsyncState.failed(action.payload)
    },
    generateFpsLinkStart: (state) => {
      state.generateFpsLink = AsyncState.start()
    },
    generateFpsLinkSuccess: (state, action) => {
      state.generateFpsLink = AsyncState.success(action.payload)
    },
    generateFpsLinkFailed: (state, action) => {
      state.generateFpsLink = AsyncState.failed(action.payload)
    },
    payByEDDAStart: (state) => {
      state.payByEDDA = AsyncState.start()
    },
    payByEDDASuccess: (state, action) => {
      state.payByEDDA = AsyncState.success(action.payload)
    },
    payByEDDAFailed: (state, action) => {
      state.payByEDDA = AsyncState.failed(action.payload)
    },
    refreshOrderStart: (state) => {
      state.refreshOrder = AsyncState.start()
    },
    refreshOrderSuccess: (state, action) => {
      state.refreshOrder = AsyncState.success(action.payload)
    },
    refreshOrderFailed: (state, action) => {
      state.refreshOrder = AsyncState.failed(action.payload)
    },
    setFpsMethod: (state, action) => {
      state.fpsMethod = action.payload.fpsMethod
    },
    setDisableManualFps: (state, action) => {
      state.disableManualFPS = action.payload.disableManualFPS
    },
    setOrderID: (state, action) => {
      state.orderID = action.payload.orderID
      state.instalmentID = action.payload.instalmentID || ''
      state.paylater = action.payload.paylater
    },
    setOrderCurrency: (state, action) => {
      state.orderCurrency = action.payload.currency
      state.paymentMethod = action.payload.paymentMethod
    },
    setSeriesNum: (state, action) => {
      state.instalmentNum = action.payload.instalmentNum
    },
  },
})

export const { actions } = payWithFpsSlice

export default payWithFpsSlice.reducer
