import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import BackButton from '@/components/BackButton'
import BottomSheet from '@/components/BottomSheet'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import CenteredContent from '@/components/layout/CenteredContent'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import OTPInputBox from '@/components/OTPInputBox'
import Spacer from '@/components/Spacer'
import PhoneInput from '@/components/ui/PhoneInput'
import useCountDown from '@/hooks/useCountdown'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { profileSelector } from '@/redux/profile/profileSelector'
import PhoneParser from '@/utils/Phone'
import * as User from '@/utils/user'

import {
  ActionButtons,
  CancelButton,
  MainButton,
  Row,
  SubTitle,
  Title,
} from './Shared'

const ResendButton = styled(Button)`
  margin: 0 auto;
  background-color: white;
  border: 1px solid #dedede;

  &:disabled {
    background-color: white;
  }
`

const DisplayId = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
  text-align: center;
`

const StepInput = ({
  defaultCountryCode,
  defaultPhoneNumber,
  cancelable,
  onDone,
  onClose,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [countryCode, setCountryCode] = useState(defaultCountryCode)
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber)
  const [errorMessage, setErrorMessage] = useState('')

  const { sendOTP } = useSelector((s) => s.verifyPhone)

  useEffect(() => () => dispatch({ type: 'verifyPhone/reset' }), [])

  useEffect(() => {
    if (sendOTP.isSuccess) {
      onDone({ countryCode, phoneNumber })
    }
    if (sendOTP.isError) {
      setErrorMessage(intl.formatMessage({ id: 'error.action.sendotp.phone' }))
    } else {
      setErrorMessage('')
    }
  })

  const doSendOTP = () => {
    setErrorMessage('')

    dispatch({
      type: 'verifyPhone/sendOTP',
      payload: {
        tel: `${countryCode}${phoneNumber}`,
      },
    })
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'profile.editmobilenumber')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'profile.enterphone')}</SubTitle>
          <Spacer height="0.667rem" />
          <ErrorMessageLine errorMessage={errorMessage} />
          <Spacer height="0.667rem" />
          <PhoneInput
            id="tel"
            countryCode={countryCode}
            phoneNumber={phoneNumber}
            onChange={(data) => {
              setCountryCode(data.countryCode)
              setPhoneNumber(data.phoneNumber)
            }}
            placeholder={`${intl.formatMessage({ id: 'login.phone' })}`}
          />
          <Spacer height="2.222rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            {cancelable && (
              <CancelButton onClick={onClose}>
                {tt(intl, 'common.cancel')}
              </CancelButton>
            )}
            <MainButton onClick={doSendOTP}>
              {tt(intl, 'common.edit')}
            </MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

const COUNT_DOWN_IN_SECOND = 60

const StepVerification = ({ countryCode, phoneNumber, onDone, onBack }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const otpInputBoxRef = useRef()
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [errorMessage, setErrorMessage] = useState('')

  const { countDownInSec, setCountDownInSec } = useCountDown({
    timeoutInSec: COUNT_DOWN_IN_SECOND,
  })

  const { sendOTP, verifyOTP } = useSelector((s) => s.verifyPhone)

  useEffect(() => () => dispatch({ type: 'verifyPhone/reset' }), [])

  const resetOTP = () => {
    setTimeout(() => {
      setOtp(['', '', '', '', '', ''])
      otpInputBoxRef.current?.resetFocus()
    }, 200)
  }

  useEffect(() => {
    if (sendOTP.isSuccess) {
      resetOTP()
      setCountDownInSec(COUNT_DOWN_IN_SECOND)
    }
    if (sendOTP.isError) {
      resetOTP()
      setErrorMessage(intl.formatMessage({ id: 'error.action.sendotp.phone' }))
    } else {
      setErrorMessage('')
    }
  }, [sendOTP])

  useEffect(() => {
    if (verifyOTP.isSuccess) {
      onDone()
    }
    if (verifyOTP.isError) {
      resetOTP()
      setErrorMessage(intl.formatMessage({ id: 'error.action.verify.phone' }))
    } else {
      setErrorMessage('')
    }
  }, [verifyOTP])

  const doVerifyOTP = (newOTP) => {
    dispatch({
      type: 'verifyPhone/verifyOTP',
      payload: {
        tel: `${countryCode}${phoneNumber}`,
        code: newOTP.join(''),
        inCheckout: false,
      },
    })
  }

  const doSendOTP = async () => {
    dispatch({
      type: 'verifyPhone/sendOTP',
      payload: {
        tel: `${countryCode}${phoneNumber}`,
      },
    })
  }

  const onChangeOTP = (newOTP) => {
    setOtp(newOTP)

    if (newOTP.length === 6 && newOTP.every((digit) => digit.length === 1)) {
      doVerifyOTP(newOTP)
    }
  }

  const displayId = User.getProfileId(countryCode, phoneNumber)
  const isLoading = verifyOTP.isLoading || sendOTP.isLoading

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Row>
            <BackButton onClick={onBack} />
            <Title>{tt(intl, 'profile.verification')}</Title>
          </Row>
          <Spacer height="0.889rem" />
          <SubTitle>
            {tt(intl, 'profile.verification.addmobilenumber')}
          </SubTitle>
          <Spacer height="0.889rem" />
          <DisplayId>{displayId}</DisplayId>
          <Spacer height="1.111rem" />
          <ErrorMessageLine errorMessage={errorMessage} />
          <Spacer height="1.111rem" />
          <OTPInputBox
            ref={otpInputBoxRef}
            otp={otp}
            onChangeOTP={onChangeOTP}
          />
          <Spacer height="2.222rem" />
          <ResendButton disabled={countDownInSec > 0} onClick={doSendOTP}>
            <span>{tt(intl, 'common.resend')} </span>
            {countDownInSec > 0 && <span>{`(${countDownInSec})`}</span>}
          </ResendButton>
        </PaddingContent>
      </CSLayout.CSContent>
      <LoadingModal loading={isLoading} />
    </CSLayout.CSContainer>
  )
}

const StepSuccess = ({ onClose }) => {
  const intl = useIntl()

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <CenteredContent>
          <PaddingContent>
            <Spacer height="2.222rem" />
            <Icon
              renderImage={() => <SuccessOutlinedSvg />}
              width="1.736rem"
              height="1.736rem"
            />
            <Spacer height="0.889rem" />
            <Title>{tt(intl, 'profile.verification.success')}</Title>
            <Spacer height="0.889rem" />
            <SubTitle>{tt(intl, 'profile.mobilenumber.verified')}</SubTitle>
            <Spacer height="2.222rem" />
          </PaddingContent>
        </CenteredContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <MainButton onClick={onClose}>ok</MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

const ProfileEditMobile = ({ cancelable = true, callback, onClose }) => {
  // init -> input -> verify -> success
  const [step, setStep] = useState('init')
  const [countryCode, setCountryCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const profile = useSelector(profileSelector)

  useEffect(() => {
    if (profile && step === 'init') {
      if (profile.tel) {
        const parsedTel = PhoneParser(profile.tel)
        setCountryCode(parsedTel.countryCode)
        setPhoneNumber(parsedTel.localNumber)
      } else {
        setCountryCode('+852')
        setPhoneNumber('')
      }
      setStep('input')
    }
  }, [profile])

  const onInputDone = () => {
    setStep('verify')
  }

  const onVerifyDone = () => {
    setStep('success')
  }

  const onClickClose = () => {
    const fn = callback || onClose
    fn?.()
  }

  const onClickBack = () => {
    if (step === 'verify') {
      setStep('input')
    }
  }

  if (step === 'input') {
    return (
      <StepInput
        defaultCountryCode={countryCode}
        defaultPhoneNumber={phoneNumber}
        cancelable={cancelable}
        onDone={(data) => {
          setCountryCode(data.countryCode)
          setPhoneNumber(data.phoneNumber)
          onInputDone()
        }}
        onClose={onClickClose}
      />
    )
  }

  if (step === 'verify') {
    return (
      <StepVerification
        countryCode={countryCode}
        phoneNumber={phoneNumber}
        onDone={onVerifyDone}
        onBack={onClickBack}
      />
    )
  }

  if (step === 'success') {
    return <StepSuccess onClose={callback || onClose} />
  }

  return null
}

export const ProfileEditMobileModal = ({ isOpen, onClose }) => (
  <BottomSheet top open={isOpen} onDismiss={onClose}>
    <ProfileEditMobile onClose={onClose} />
  </BottomSheet>
)

export const ProfileEditMobileGlobalModal = () => {
  const dispatch = useDispatch()
  const { isOpen, props, callback, cancelable } = useSelector(
    (s) => s.app.editMobileModal
  )

  const onDismiss = () => {
    dispatch({ type: 'app/showEditMobileModal', payload: { isOpen: false } })
  }

  return (
    <BottomSheet top open={isOpen} closeable={false} onDismiss={onDismiss}>
      <ProfileEditMobile
        {...props}
        callback={callback}
        cancelable={cancelable}
      />
    </BottomSheet>
  )
}

export const ProfileEditMobilePage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfile' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  return <ProfileEditMobile onClose={() => history.back()} />
}

export default ProfileEditMobile
