import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

const RedirectPage = () => {
  const { state } = useLocation()
  const { redirectTo = '/' } = state || {}

  return <Redirect to={redirectTo} />
}

export default RedirectPage
