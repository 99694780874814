import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as SSOActions } from './SSOSlice'

export function* generateOAuthToken({ payload }) {
  try {
    yield put(SSOActions.generateOAuthTokenStart())
    const { redirectUrl, scope, deviceID } = payload
    const { data } = yield call(() =>
      DivitAPI.post('/auth/token', {
        redirectUrl,
        scope,
        deviceID,
      })
    )
    yield put(SSOActions.generateOAuthTokenSuccess(data.data))
  } catch (e) {
    yield put(SSOActions.generateOAuthTokenFailed(getError(e)))
  }
}

export default null
