// Note: not using Divit components
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'
import PasswordValidation from '@/components/PasswordValidation'
import Spacer from '@/components/Spacer'
import PasswordInput from '@/components/ui/PasswordInput'
import usePageError from '@/hooks/usePageError'
import usePasswordValidation from '@/hooks/usePasswordValidation'
import { tt } from '@/locales/format'
import { Button, Label } from '@/pages/Auth/shared/style'

const Container = styled(PaddingContent)`
  margin-top: 1.25rem;
  color: #1c1c1c;
`

const Hero = styled.div``

const HeroTitle = styled.div`
  font-weight: 700;
  font-size: 1.33rem;
  text-align: center;
`

const HeroSubTitle = styled.div`
  font-size: 0.78rem;
  text-align: center;
  width: 60%;
  margin: auto;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.33rem;
`

const FormColumn = styled.div`
  flex: 1;
  ${({ position }) => position && `text-align: ${position}`}
`

const PasswordStrengthMeterContainer = styled.div`
  width: 100%;
  margin: 0.889rem 0 1.333rem 0;
`

const ResetPasswordButton = styled(Button)`
  font-size: 0.89rem;
  background-color: #ffcc33;
  color: #1c1c1c;
`

const LoginButton = styled(Button)`
  font-size: 0.89rem;
  background-color: #ffcc33;
  color: #1c1c1c;
`

const SuccessIcon = styled(SuccessOutlinedSvg)`
  width: 1.78rem;
  height: 1.78rem;
  display: block;
  margin: auto;
`

const ResetPassword = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()

  const [password, setPassword] = useState('')
  const { passwordValidation } = usePasswordValidation(password)
  const { errorMessage, updateErrorMessage } = usePageError()

  const { token } = useParams()

  const { verifyToken, resetPassword } = useSelector((s) => s.resetPassword)

  useEffect(() => {
    dispatch({ type: 'logout', payload: { isNormalLogout: false } })
    dispatch({
      type: 'resetPassword/verifyToken',
      payload: {
        token,
      },
    })
    return () => {
      dispatch({
        type: 'resetPassword/reset',
      })
    }
  }, [])

  useEffect(() => {
    if (verifyToken.isError) {
      updateErrorMessage(
        intl.formatMessage({ id: 'error.action.reset.password' })
      )
    } else {
      updateErrorMessage('')
    }
  }, [verifyToken])

  useEffect(() => {
    if (resetPassword.isError) {
      updateErrorMessage(
        intl.formatMessage({ id: 'error.action.reset.password' })
      )
    } else {
      updateErrorMessage('')
    }

    if (resetPassword.isSuccess) {
      setTimeout(() => {
        dispatch({ type: 'logout', payload: { isNormalLogout: false } })
        history.push('/signin')
      }, 3000)
    }
  }, [resetPassword])

  const validateForm = () => {
    if (!passwordValidation.valid) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.password' }))
      return false
    }
    return true
  }

  const onClickResetPassword = (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    dispatch({
      type: 'resetPassword/resetPassword',
      payload: {
        token,
        newPwd: password,
      },
    })
  }

  const onClickLogin = useCallback((e) => {
    e.preventDefault()

    // clear token, otherwise login with other user
    dispatch({ type: 'logout', payload: { isNormalLogout: false } })
    setTimeout(() => history.push('/signin'), 500)
  })

  const loading = verifyToken.isLoading || resetPassword.isLoading

  return (
    <Container>
      {/* token expired error case */}
      {verifyToken.isError && (
        <>
          <Hero>
            <Spacer height="0.89rem" />
            <HeroTitle>{tt(intl, 'login.resetpassword')}</HeroTitle>
            {!verifyToken.isError && (
              <>
                <Spacer height="0.89rem" />
                <HeroSubTitle>
                  {tt(intl, 'login.resetpassword.request')}
                </HeroSubTitle>
              </>
            )}
          </Hero>
          <Spacer height="2.22rem" />
          {errorMessage && <ErrorMessageBox errorMessage={errorMessage} />}
          <LoginButton type="stretch" onClick={onClickLogin}>
            {tt(intl, 'common.login')}
          </LoginButton>
        </>
      )}
      {verifyToken.isSuccess && !resetPassword.isSuccess && (
        <>
          <Hero>
            <Spacer height="0.89rem" />
            <HeroTitle>{tt(intl, 'login.resetpassword')}</HeroTitle>
            <Spacer height="0.89rem" />
            <HeroSubTitle>
              {tt(intl, 'login.resetpassword.request')}
            </HeroSubTitle>
          </Hero>
          <Spacer height="2.22rem" />
          {errorMessage && <ErrorMessageBox errorMessage={errorMessage} />}
          <Form>
            <FormRow>
              <FormColumn>
                <Label htmlFor="password">
                  {tt(intl, 'login.newpassword')}
                </Label>
                <PasswordInput
                  value={password}
                  onChange={(v) => setPassword(v)}
                />
                {!!password && (
                  <PasswordStrengthMeterContainer>
                    <PasswordStrengthMeter
                      score={passwordValidation.strengthScore}
                    />
                  </PasswordStrengthMeterContainer>
                )}
                {!!password && !passwordValidation.ignored && (
                  <PasswordValidation
                    hasSufficientLength={passwordValidation.validLength}
                    hasNumber={passwordValidation.validNumber}
                    hasLowercase={passwordValidation.validLowercase}
                    hasUppercase={passwordValidation.validUppercase}
                    hasSymbol={passwordValidation.validSymbol}
                  />
                )}
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <ResetPasswordButton
                  type="stretch"
                  disabled={!(password && passwordValidation.valid)}
                  onClick={onClickResetPassword}
                >
                  {tt(intl, 'login.resetpassword')}
                </ResetPasswordButton>
              </FormColumn>
            </FormRow>
          </Form>
        </>
      )}
      {verifyToken.isSuccess && resetPassword.isSuccess && (
        <>
          <Hero>
            <Spacer height="0.89rem" />
            <SuccessIcon />
            <Spacer height="0.89rem" />
            <HeroTitle>{tt(intl, 'login.resetpassword.success')}</HeroTitle>
            <Spacer height="0.89rem" />
            <HeroSubTitle>
              {tt(intl, 'login.resetpassword.request')}
            </HeroSubTitle>
          </Hero>
          <Spacer height="0.89rem" />
          {/* {errorMessage && <ErrorMessageBox errorMessage={errorMessage} />} */}
          <LoginButton type="stretch" onClick={onClickLogin}>
            {tt(intl, 'common.loginnow')}
          </LoginButton>
        </>
      )}
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default ResetPassword
