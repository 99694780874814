import React from 'react'
import styled, { css } from 'styled-components'

import { desktop } from '@/utils/Styles'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${desktop(css`
    grid-template-columns: repeat(3, 1fr);
  `)};
  grid-gap: 0.889rem;
`

const Item = styled.div`
  border-radius: 0.444rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  cursor: pointer;
`

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
`

const BrandGrid = ({ brands = [], onClickItem }) => {
  const onClickBrand = (b) => () => {
    onClickItem?.(b)
  }

  return (
    <Grid>
      {brands.map((b) => (
        <Item key={b.name} onClick={onClickBrand(b)}>
          <ItemImage src={b.featured_image} />
        </Item>
      ))}
    </Grid>
  )
}

export default BrandGrid
