import PropTypes from 'prop-types'
import React, { useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'

import { Input } from '@/components/Divit'

const OtpInputs = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 17.778rem;
  margin: 0 auto;
`

const OtpInput = styled(Input).attrs({
  type: 'number',
  pattern: '\\d*',
})`
  margin: 0;
  padding: 0;
  text-align: center;
  width: 2.222rem;
  height: 2.222rem;
  background-color: transparent;
  border: 0;
  font-size: 1.333rem;
  color: black;
  border-bottom: 1px solid #c2c2c2;
  margin-right: 0.889rem;

  &:last-child() {
    margin-right: 0;
  }

  &:focus {
    border-bottom: 1px solid #ffcf42;
  }
`

const OTPInputBox = (props, ref) => {
  const { otp } = props

  const focusPos = useRef()
  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]

  useImperativeHandle(ref, () => ({
    resetFocus: () => {
      otpRefs[0].current?.focus()
      focusPos.current = 0
    },
  }))

  useEffect(() => {
    // auto focus first otp input after sent otp
    setTimeout(() => {
      otpRefs[0].current?.focus()
      focusPos.current = 0
    }, 200)
  }, [])

  const onChangeOTP = (e, index) => {
    const { value } = e.target

    // number only
    if (value && !/^[0-9]+$/.test(value)) return

    const chars = otp[index] ? value.substring(1).split('') : value.split('')

    if (chars.length > 1) {
      const newOtp = otp.map((v, i) => chars[i] || '')
      const { onChangeOTP: changeOTP } = props
      changeOTP(newOtp)
    }

    if (chars.length <= 1) {
      const newOtp = otp.map((v, i) => (i === index ? chars[0] || '' : v))
      const { onChangeOTP: changeOTP } = props
      changeOTP(newOtp)

      // jump to next input
      if (index < otpRefs.length - 1 && value) {
        otpRefs[index + 1].current?.focus()
        focusPos.current = index + 1
      }
    }
  }

  // delete back one digit
  const onKeyDown = (e) => {
    if (e.key !== 'Backspace') return

    const curPosValue = otp[focusPos.current]
    const removePos = curPosValue ? focusPos.current : focusPos.current - 1
    if (removePos >= 0) {
      otp[removePos] = ''

      const { onChangeOTP: changeOTP } = props
      changeOTP(otp.slice())

      otpRefs[removePos].current?.focus()
      focusPos.current = removePos
    }
  }

  return (
    <OtpInputs onKeyDown={(e) => onKeyDown(e)}>
      <OtpInput
        id="otp"
        ref={otpRefs[0]}
        value={otp[0]}
        onChange={(e) => onChangeOTP(e, 0)}
        data-selenium="verify.mobile.otp"
      />
      <OtpInput
        ref={otpRefs[1]}
        value={otp[1]}
        onChange={(e) => onChangeOTP(e, 1)}
      />
      <OtpInput
        ref={otpRefs[2]}
        value={otp[2]}
        onChange={(e) => onChangeOTP(e, 2)}
      />
      <OtpInput
        ref={otpRefs[3]}
        value={otp[3]}
        onChange={(e) => onChangeOTP(e, 3)}
      />
      <OtpInput
        ref={otpRefs[4]}
        value={otp[4]}
        onChange={(e) => onChangeOTP(e, 4)}
      />
      <OtpInput
        ref={otpRefs[5]}
        value={otp[5]}
        onChange={(e) => onChangeOTP(e, 5)}
      />
    </OtpInputs>
  )
}

OTPInputBox.propTypes = {
  otp: PropTypes.string.isRequired,
  onChangeOTP: PropTypes.func.isRequired,
}

export default React.forwardRef(OTPInputBox)
