import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { tt } from '@/locales/format'
import { ProfileEditEmailModal } from '@/pages/profile/ProfileEditEmail'
import { verifyIdentity } from '@/redux/pin/pinActions'

import VerifiedStatus from './VerifiedStatus'

const Container = styled.div`
  display: flex;
  padding: 1.2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eff0f1;
  cursor: pointer;
`

const Content = styled.div`
  width: 70%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 80%;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const SubTitle = styled.div`
  font-size: 16px;
  margin-top: 0.5rem;
`

const EmailVerify = ({ profile }) => {
  const [isShowEditEmailModal, setIsShowEditEmailModal] = useState(false)

  const intl = useIntl()

  const dispatch = useDispatch()

  const onClickChangeEmail = async () => {
    const authResult = await dispatch(verifyIdentity({}))
    if (authResult.isSuccess) {
      setIsShowEditEmailModal(true)
    }
  }

  const checkVerify = (email) => {
    if (email) {
      return 'verified'
    }
    return 'unverified'
  }
  return (
    <>
      <Container onClick={() => onClickChangeEmail()}>
        <Content>
          <Title>{tt(intl, 'common.email')}</Title>
          <SubTitle>{profile.email || ''}</SubTitle>
        </Content>
        <VerifiedStatus status={checkVerify(profile.email_validated)} />
      </Container>
      <ProfileEditEmailModal
        isOpen={isShowEditEmailModal}
        onClose={() => setIsShowEditEmailModal(false)}
      />
    </>
  )
}

export default EmailVerify
