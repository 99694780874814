import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as OutOfAvailableSpendSvg } from '@/assets/common/alert.svg'
import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import { Button } from '@/pages/Auth/shared/style'
import { setupAccountForPaylater } from '@/redux/auth/authActions'
import { sessionSelector } from '@/redux/profile/profileSelector'
import * as Instalments from '@/utils/Instalments'
import { FormattedPrice } from '@/utils/Price'

import Icon from './Icon'
import Line from './Line'
import Modal from './Modal'
import Spacer from './Spacer'

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  padding: 0 2.5rem;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
`

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  background-color: #f5f6f7;
  border-radius: 8px;
`

const ContenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-size: 1.425rem;
  font-weight: 700;
`

const OverduePaymentContainer = styled.div`
  padding: 1.4rem;
  border-radius: 8px;
  background-color: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  margin: 0;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin: 0;
  }
`

const MessageBox = styled.div`
  display: flex;
  align-items: flex-start;
`

const IconWarning = styled(OutOfAvailableSpendSvg)`
  width: 2rem;
  height: auto;
  display: block;
`

const Message = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.gutter * 2}px;
  color: ${({ theme }) => theme.message.error};
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 0.75rem;
`

const RowDescription = styled.div`
  max-width: 60%;
`

const RowAmount = styled.div`
  flex: 1;
  text-align: right;
`

const ButtonRow = styled.div`
  text-align: right;
`

const ActionButton = styled(Button)`
  min-width: unset;
  font-size: 0.778rem;
`

const BackButtonContainer = styled.div`
  text-align: center;
`

const BackButton = styled(Button)`
  font-size: 0.75rem;
  background-color: black;
  color: white;
`

const CloseButton = styled.div`
  cursor: pointer;
  margin-right: -16px;
`
const OverduePaymentTitle = styled.div`
  font-weight: 700;
`
const OverduePaymentSubTitle = styled.div`
  width: 100%;
`

const AvailableSpendWarningButtonGroup = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PayButton = styled(Button)`
  font-size: 1rem;
  height: 2.625rem;
  width: 100%;
`

const getOutstandingInstalmentTotalAmount = (order) => {
  const instalment = Instalments.getOutstandingInstalment(order.instalments)
  return Instalments.getTotalOutstandingAmount(instalment)
}

const AvailableSpendModal = ({
  isOpen,
  onClickPaynow,
  onClose,
  nextPaymentOrder,
  backButtonText,
  onClickBackButton,
  onClickPayNextPayment,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const session = useSelector(sessionSelector)

  const onClickVerify = async (e) => {
    e.preventDefault()

    await dispatch(setupAccountForPaylater())
  }

  const instalmentTotalAmount = nextPaymentOrder
    ? getOutstandingInstalmentTotalAmount(nextPaymentOrder)
    : {}

  const hasActions = nextPaymentOrder || !session.hasEmail || !session.hasTel

  return (
    <Modal isOpen={isOpen} isLock>
      <Container>
        <Content>
          <ContenHeader>
            <Title>
              {intl.formatMessage({ id: 'paylater.account.freeze' })}
            </Title>
            <CloseButton onClick={onClose}>
              <Icon
                renderImage={() => <CloseSvg />}
                width="2.66rem"
                height="2.66rem"
              />
            </CloseButton>
          </ContenHeader>
          <Spacer height="1rem" />
          <OverduePaymentContainer>
            <MessageBox>
              <IconWarning />
              <Message>
                <strong>
                  {hasActions &&
                    intl.formatMessage({ id: 'availablespend.outof' })}
                  {!hasActions &&
                    intl.formatMessage({ id: 'availablespend.apr' })}
                </strong>
                <span> </span>
                {hasActions &&
                  intl.formatMessage({ id: 'availablespend.outof.actions' })}
              </Message>
            </MessageBox>
            <Spacer height="1rem" />
            {!!nextPaymentOrder && (
              <>
                <Line />
                <Spacer height="1rem" />
                <Row>
                  <RowDescription>
                    {intl.formatMessage({ id: 'availablespend.paynext' })}
                  </RowDescription>
                  <RowAmount>
                    {FormattedPrice(
                      instalmentTotalAmount.currency,
                      instalmentTotalAmount.amount
                    )}
                  </RowAmount>
                </Row>
              </>
            )}
            <Spacer height="1rem" />
            {(!session.hasEmail || !session.hasTel) && (
              <>
                <Line />
                <Spacer height="1rem" />
                <Row>
                  <div>
                    {intl.formatMessage({
                      id: 'availablespend.verify.profile',
                    })}
                  </div>
                </Row>
                <ButtonRow>
                  <ActionButton onClick={onClickVerify}>
                    {intl.formatMessage({ id: 'button.verify' })}
                  </ActionButton>
                </ButtonRow>
                <Spacer height="1rem" />
              </>
            )}
            <Spacer height="1rem" />
          </OverduePaymentContainer>
          <Spacer height="1rem" />
          <AvailableSpendWarningButtonGroup>
            <PayButton onClick={onClickPayNextPayment}>
              {intl.formatMessage({
                id: 'button.pay.now',
              })}
            </PayButton>
          </AvailableSpendWarningButtonGroup>
        </Content>
      </Container>
    </Modal>
  )
}

export default AvailableSpendModal
