// helpers

export const saveString = (key, str) => {
  sessionStorage.setItem(key, str)
}

export const getString = (key) => sessionStorage.getItem(key)

export const setSplitString = (key, arr) => {
  sessionStorage.setItem(key, arr.join('|'))
}

export const getSplitString = (key) => {
  const strArray = sessionStorage.getItem(key)
  return strArray ? strArray.split('|') : []
}

export const saveJSON = (key, json) => {
  const jsonStr = JSON.stringify(json)
  sessionStorage.setItem(key, jsonStr)
}

export const getJSON = (key, defaultValue) => {
  const item = sessionStorage.getItem(key)
  return JSON.parse(item) || defaultValue
}

export const remove = (key) => {
  sessionStorage.removeItem(key)
}
