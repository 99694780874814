import React from 'react'
import { useIntl } from 'react-intl'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'

import { tt } from '@/locales/format'

const GuideSlide = styled.div``

const GuideSlideImage = styled.img``

const GuideSlideText = styled.div`
  margin-top: 0.444rem;
  font-size: 0.667rem;
`

const Slideshow = styled(Carousel)`
  .carousel .slide {
    background: transparent;
    opacity: 0.1;
    transition: opacity 0.25s linear;
  }

  .carousel .slide:first-child,
  .carousel .slide:last-child {
    visibility: hidden;
  }

  .carousel .slide.selected {
    opacity: 1;
  }

  .carousel .slide:not(.selected) {
    transform: scale(0.95);
  }

  /* hide slide text if not selected */
  .carousel .slide:not(.selected) ${GuideSlideText} {
    visibility: hidden;
  }

  .carousel .control-dots {
    position: relative;
    margin-top: 0.778rem;
    padding: 0;
  }

  .carousel .control-dots .dot {
    background-color: rgba(255, 204, 51, 0.5);
    box-shadow: none;
    opacity: 1;
    margin: 0 0.222rem;
    border-radius: 8px;
    transition: all 0.25s ease-in;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background-color: #ffcc33;
  }

  .carousel .control-dots .dot.selected {
    width: 2.222rem;
    border-radius: 8px;
  }

  .carousel img {
    width: auto;
    height: 18.3rem;
  }
`

const GuideSlideShow = ({ children, onChange }) => (
  <Slideshow
    showArrows={false}
    showThumbs={false}
    showStatus={false}
    infiniteLoop
    swipeable
    centerMode
    centerSlidePercentage={50}
    preventMovementUntilSwipeScrollTolerance
    onChange={(idx) => onChange?.(idx)}
    onClickItem={(idx) => onChange?.(idx)}
  >
    {children}
  </Slideshow>
)

const FpsGuideSlideshow = ({ guides }) => {
  const intl = useIntl()

  return (
    <GuideSlideShow>
      {guides.map((guide) => (
        <GuideSlide key={guide.key}>
          <GuideSlideImage src={guide.img} />
          <GuideSlideText>{tt(intl, guide.text)}</GuideSlideText>
        </GuideSlide>
      ))}
    </GuideSlideShow>
  )
}

export default FpsGuideSlideshow
