import styled from 'styled-components'

import { Button } from '@/pages/Auth/shared/style'

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  flex: 1;
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
`

export const SubTitle = styled.div`
  font-size: 0.778rem;
  line-height: 1.5em;
  text-align: center;
  margin: 0 auto;
`

export const Section = styled.div`
  padding: 1.333rem 0;
  border-bottom: 1px solid #f0f0f0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
`

export const SectionTitle = styled.div`
  font-size: 0.889rem;
  font-weight: 700;
  margin-bottom: 0.889rem;
`

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.889rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1;
  }

  & > *:first-child {
    margin-right: 0.889rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`

export const MainButton = styled(Button)`
  min-width: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  background-color: #1c1c1c;
  color: white;
  padding: 1rem 0;
`

export const CancelButton = styled(Button)`
  min-width: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  font-weight: 700;
  background-color: transparent;
  color: #1c1c1c;
  padding: 1rem 0;
  border: 1px solid #dedede;
`

export const RadioButton = styled.div`
  width: 1.778rem;
  height: 1.778rem;
  background: white;
  border-radius: 50%;
  box-shadow: ${(p) =>
    p.checked
      ? '0px 0px 0px 8px #ffcc33 inset'
      : '0px 0px 0px 1px #DEDEDE inset'};
  cursor: pointer;
`
