import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/footer/divit.svg'
import { ReactComponent as HomeSvg } from '@/assets/footer/home.svg'
import NavBarSvg from '@/assets/footer/navbar-mid.svg'
import { ReactComponent as OrderSvg } from '@/assets/footer/order.svg'
import { ReactComponent as ProfileSvg } from '@/assets/footer/profile.svg'
import { ReactComponent as ShopSvg } from '@/assets/footer/shop.svg'
import FooterMilesModal from '@/components/FooterMilesModal'
import { tt } from '@/locales/format'

import FooterItem from './FooterItem'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  min-width: 360px;
  height: 6rem; /* 108px */
  z-index: 2;
  box-sizing: border-box;
`

const RelativeDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
  height: 3.953rem; /* 71.6px; */
  background-color: white;
  margin-top: auto;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`

const BackgroundDiv = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
`

const BackgroundChild = styled.div`
  flex: 1;
  height: 6.667rem;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`

const BackgroundChildLeftX = styled.div`
  width: 100%;
  height: 3.953rem;
  border-top-left-radius: 16px;
  box-shadow: 0 -4px 8px rgb(0 0 0 / 4%), 0 -14px 30px rgb(0 0 0 / 4%);
`

const BackgroundChildRightX = styled.div`
  width: 100%;
  height: 3.953rem;
  border-top-right-radius: 16px;
  box-shadow: 0 -4px 8px rgb(0 0 0 / 4%), 0 -14px 30px rgb(0 0 0 / 4%);
`

const BackgroundChildMid = styled.div`
  width: 6.667rem;
`

const Indicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6.667rem;
  background-image: url(${NavBarSvg});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 6.667rem 6.667rem;
`

const BigIcon = styled.div`
  position: relative;
  width: ${(p) => p.iconSize};
  height: ${(p) => p.iconSize};

  & svg {
    position: absolute;
    width: ${(p) => p.iconSize};
    height: ${(p) => p.iconSize};
    top: -0.889rem;
    left: 0;
  }

  /* fix */
  & + div {
    margin-top: 0;
  }
`

const DivitMilesCircleBg = styled.div`
  position: absolute;
  width: ${(p) => p.iconSize}px;
  height: ${(p) => p.iconSize}px;
  top: -0.75rem;
  left: 0;
  transform: scale(2.3);
`

const DivitMilesBigIconSvg = styled(DivitMilesSvg)`
  border-radius: 50%;
  box-shadow: 0px 4px 16px rgba(255, 204, 51, 0.3),
    0px 1px 10px rgba(255, 204, 51, 0.2), 0px 2px 4px rgba(255, 204, 51, 0.3);
`

const DivitMilesIcon = ({ iconSize }) => (
  <BigIcon iconSize={iconSize}>
    <DivitMilesCircleBg iconSize={iconSize} />
    <DivitMilesBigIconSvg />
  </BigIcon>
)

const Footer = () => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()

  const [currentPath, setCurrentPath] = useState('')
  const [isShowMilesModal, setIsShowMilesModal] = useState(false)
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  useEffect(() => {
    document.body.classList.add('has-footer')
    return () => document.body.classList.remove('has-footer')
  }, [])

  const iconSize = '1.778rem'

  return (
    <Container>
      <RelativeDiv>
        <BackgroundDiv>
          <BackgroundChild>
            <BackgroundChildLeftX />
          </BackgroundChild>
          <BackgroundChildMid />
          <BackgroundChild>
            <BackgroundChildRightX />
          </BackgroundChild>
        </BackgroundDiv>
        <Indicator />
        <FooterItem
          icon={<HomeSvg width={iconSize} height={iconSize} />}
          label={tt(intl, 'tab.home')}
          onClick={() => history.push('/home')}
          active={currentPath === '/home'}
          data-selenium="footer.home"
        />
        <FooterItem
          icon={<ShopSvg width={iconSize} height={iconSize} />}
          label={tt(intl, 'tab.shop')}
          onClick={() => history.push('/shop')}
          active={currentPath === '/shop'}
          data-selenium="footer.shop"
        />
        <FooterItem
          icon={<DivitMilesIcon iconSize="3.111rem" />}
          label={tt(intl, 'common.miles')}
          labelStyle={{ transform: 'translateY(-0.4rem)' }}
          onClick={() => setIsShowMilesModal(true)}
          enableHightlight={false}
          data-selenium="footer.miles"
        />
        <FooterItem
          icon={<OrderSvg width={iconSize} height={iconSize} />}
          label={tt(intl, 'tab.activity')}
          onClick={() => history.push('/history')}
          active={currentPath === '/history'}
          data-selenium="footer.history"
        />
        <FooterItem
          icon={<ProfileSvg width={iconSize} height={iconSize} />}
          label={tt(intl, 'tab.profile')}
          onClick={() => history.push('/profile')}
          active={currentPath === '/profile'}
          data-selenium="footer.profile"
        />
      </RelativeDiv>
      <FooterMilesModal
        isOpen={isShowMilesModal}
        onClose={() => setIsShowMilesModal(false)}
      />
    </Container>
  )
}

export default Footer
