import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import styled from 'styled-components'

import { ReactComponent as DownArrowSvg } from '@/assets/ui/down-arrow.svg'
import Icon from '@/components/Icon'
import { tt } from '@/locales/format'

import AddressSearch, { useAddressSearch } from './AddressSearch'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 2.667rem;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
  border-radius: 0.44rem;
  padding: 0 0 0 0.889rem;
  background: white;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;

  &:hover {
    border-color: #f7ce55;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`

const Field = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const CountryCodeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.6rem;
  width: 32%;
`

const CountryCode = styled.div`
  flex: 1;
  min-width: 2.333rem;
  font-size: 0.722rem;
  text-align: center;
  margin-right: 4px;
  font-weight: bold;
`

const Input = styled.input`
  flex: 1;
  border: 0;
  margin: 0;
  padding: 0;
  padding-left: 0.6rem;
  border-left: 1px solid #ddd;
  font-size: 0.778rem;
  line-height: 3.3em;
  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
  border-radius: 0 0.44rem 0.44rem 0;
`

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    width: '5.rem',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.16)',
  }),
  option: (provided, state) => ({
    ...provided,
    height: 48,
    backgroundColor: state.isSelected ? '#FFFAEA' : 'white',
    fontSize: '0.778rem',
    color: '#1c1c1c',
    textAlign: 'left',
    padding: '12px 12px',
    cursor: 'pointer',
  }),
}

const InputField = React.forwardRef(
  (
    {
      style,
      className,
      defaultOption,
      options,
      countryCode,
      address,
      disabled,
      onChange,
      onChangeAddressSearch,
      register,
      searchText,
      error,
      errors,
      addressSelectRef,
    },
    ref
  ) => {
    const { cancelSearchAddress } = useAddressSearch()
    const [isExpanded, setIsExpanded] = useState(false)
    const intl = useIntl()

    const addressOptions = (env) => {
      switch (env) {
        case 'dev':
          return [
            {
              key: 'hk',
              label: tt(intl, 'profile.region.hongkong'),
              value: 'HK',
            },
            {
              key: 'th',
              label: tt(intl, 'profile.region.thailand'),
              value: 'TH',
            },
          ]

        default:
          return [
            {
              key: 'hk',
              label: tt(intl, 'profile.region.hongkong'),
              value: 'HK',
            },
          ]
      }
    }

    defaultOption = { ...addressOptions(process.env.REACT_APP_ENV)[0] }
    options = [...addressOptions(process.env.REACT_APP_ENV)]

    const valueOption = options.find((o) => o.value === countryCode)
    const valueLabel =
      options.find((o) => o.value === countryCode)?.label || defaultOption.label

    const onClickField = () => {
      setIsExpanded((s) => !s)
    }

    const onChangeSelect = (e) => {
      setIsExpanded(false)
      onChange({
        countryCode: e.value,
        address,
      })
    }

    const onBlurSelect = () => {
      setIsExpanded(false)
    }

    return (
      <Container
        style={style}
        className={className}
        disabled={disabled}
        error={error}
      >
        <Field className="w-full">
          <CountryCodeContainer onClick={onClickField}>
            <CountryCode className="w-max">{valueLabel}</CountryCode>
            <Icon
              renderImage={() => <DownArrowSvg />}
              width="1.111rem"
              height="1.111rem"
            />
          </CountryCodeContainer>
          <div className="relative w-full">
            <AddressSearch
              searchText={searchText}
              onChange={onChangeAddressSearch}
              className="left-2"
            >
              <Input
                type="text"
                id="searchAddress"
                {...register('searchAddress')}
                autoComplete="searchAddress"
                placeholder={tt(intl, 'profile.findadress')}
                error={errors.searchAddress}
                onFocus={() => {
                  addressSelectRef.current = true
                }}
                onBlur={() => {
                  addressSelectRef.current = false
                  cancelSearchAddress()
                }}
                style={{ width: '100%' }}
              />
            </AddressSearch>
          </div>
        </Field>
        <Select
          ref={ref}
          components={{ Control: () => null }}
          styles={selectStyles}
          isLoading={false}
          isSearchable={false}
          cacheOptions
          defaultOptions
          onChange={onChangeSelect}
          onBlur={onBlurSelect}
          options={options}
          menuIsOpen={isExpanded}
          value={valueOption}
        />
      </Container>
    )
  }
)

export default InputField
