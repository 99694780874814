import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Line from '@/components/Line'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import * as DivitMiles from '@/utils/DivitMiles'
import * as Instalments from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.333rem 1.111rem;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.08);
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.444rem 0;
  box-sizing: border-box;
  font-size: 0.778rem;
  font-weight: ${({ bold }) => bold && 600};

  &:last-child {
    padding-bottom: 0;
  }
`

const RowBonusMiles = styled.div`
  background-color: #fffaea;
  padding: 0.56rem;
  border-radius: 8px;
`

const RowLeft = styled.div`
  flex: 1;
  text-align: left;
  font-weight: 500;
`

const RowRight = styled.div`
  text-align: right;
  font-weight: 600;
`

const OrderNumber = styled.div`
  font-size: 0.667rem;
`

const OrderPaidAmount = styled.div``

const OrderPayText = styled.div`
  font-size: 0.7778rem;
  font-weight: 500;
`

const RemarkText = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const OrderSummary = ({ order, orderMiles }) => {
  const intl = useIntl()

  const { serviceFee, instalments } = order
  const partnerRef = Order.getPartnerRef(order)
  const isRefundCompleted = Order.hasCompleteRefund(order)
  const totalOrderAmount = Order.getOrderAmount(order)
  const totalActualPaidAmount = Order.getTotalActualPaidAmount(order)
  const totalLateFee = Order.getTotalLateFee(order)
  const totalRefundedAmount = Order.getTotalRefundedAmount(order)
  const totalCancelledAmount = Order.getTotalCancelledAmount(order)
  const totalMilesEarned = DivitMiles.getTotalMilesEarned(orderMiles)
  const totalBonusMilesEarned = DivitMiles.getBonusMilesEarned(orderMiles)
  const totalMilesEarnedFromOrder = totalMilesEarned - totalBonusMilesEarned
  const bonusEvents = DivitMiles.getBonusEvents(orderMiles)
  const isShowServiceFeeRemark =
    Instalments.getTotalRefundedAmount(instalments[0]).amount > 0 &&
    serviceFee?.amount > 0

  const totalCustomerActualPaidAmount =
    !isRefundCompleted && totalCancelledAmount.amount > 0
      ? totalOrderAmount.amount - totalCancelledAmount.amount
      : totalActualPaidAmount.amount

  return (
    <Container>
      <OrderNumber>
        {tt(intl, 'order.merchantreference')}: {partnerRef}
      </OrderNumber>
      <Spacer height="0.889rem" />
      <OrderPaidAmount>
        <OrderPayText>{tt(intl, 'payment.youpaid')}</OrderPayText>
        <Spacer height="0.222rem" />
        <MilesAndAmountValue
          fontSize="1.333rem"
          currency={totalActualPaidAmount.currency}
          amount={totalCustomerActualPaidAmount}
          miles={order.milesBurned}
        />
      </OrderPaidAmount>
      {totalMilesEarnedFromOrder > 0 && (
        <Row>
          <RowLeft>{tt(intl, 'payment.youearned')}</RowLeft>
          <RowRight>
            <MilesValue miles={totalMilesEarnedFromOrder} isSigned={false} />
          </RowRight>
        </Row>
      )}
      {bonusEvents.length > 0 && (
        <RowBonusMiles>
          {bonusEvents.map((event) => (
            <Row>
              <RowLeft>{intl.formatMessage({ id: event.msg })}</RowLeft>
              <RowRight>
                <MilesValue miles={event.amount} />
              </RowRight>
            </Row>
          ))}
        </RowBonusMiles>
      )}
      <Spacer height="0.889rem" />
      <Line />
      <Spacer height="0.889rem" />
      <Row>
        <RowLeft>{tt(intl, 'payment.ordertotal')}</RowLeft>
        <RowRight>
          {FormattedPrice(totalOrderAmount.currency, totalOrderAmount.amount)}
        </RowRight>
      </Row>
      {totalLateFee.amount > 0 && (
        <Row>
          <RowLeft>{intl.formatMessage({ id: 'receipt.latefee' })}</RowLeft>
          <RowRight>
            {FormattedPrice(totalLateFee.currency, totalLateFee.amount)}
          </RowRight>
        </Row>
      )}
      {!isRefundCompleted && totalCancelledAmount.amount > 0 && (
        <Row>
          <RowLeft>{tt(intl, 'payment.credit')}</RowLeft>
          <RowRight>
            {FormattedPrice(
              totalCancelledAmount.currency,
              -totalCancelledAmount.amount
            )}
          </RowRight>
        </Row>
      )}
      {isRefundCompleted && totalRefundedAmount.amount > 0 && (
        <Row>
          <RowLeft>{tt(intl, 'payment.refunded')}</RowLeft>
          <RowRight>
            {FormattedPrice(
              totalRefundedAmount.currency,
              -totalRefundedAmount.amount
            )}
          </RowRight>
        </Row>
      )}
      {isShowServiceFeeRemark && (
        <RemarkText>
          {tt(intl, 'payment.nonrefundablefee.note', {
            amt: FormattedPrice(serviceFee.currency, serviceFee.amount),
          })}
        </RemarkText>
      )}
    </Container>
  )
}

export default OrderSummary
