import { put } from 'redux-saga/effects'

import * as DivitMilesSagas from '@/redux/divitMiles/divitMilesSaga'

import { getError } from '../utils/error'
import { actions as convertDivitMilesActions } from './convertDivitMilesSlice'

export function* addMembership({ payload }) {
  try {
    yield put(convertDivitMilesActions.addMembershipStart())
    const { data } = yield DivitMilesSagas.addDivitMilesMembership(payload)
    yield put(convertDivitMilesActions.addMembershipSuccess(data))
  } catch (e) {
    yield put(convertDivitMilesActions.addMembershipFailed(getError(e)))
  }
}

export function* convertMiles({ payload }) {
  try {
    yield put(convertDivitMilesActions.convertMilesStart())
    const { data } = yield DivitMilesSagas.convertDivitMiles(payload)
    yield put(convertDivitMilesActions.convertMilesSuccess(data))
  } catch (e) {
    yield put(convertDivitMilesActions.convertMilesFailed(getError(e)))
  }
}

export default 0
