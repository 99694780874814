import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const claimDivitMilesSlice = createSlice({
  name: 'claimDivitMiles',
  initialState: {
    previewClaim: AsyncState.create(),
    claim: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.previewClaim = AsyncState.reset()
      state.claim = AsyncState.reset()
    },
    previewClaimStart: (state) => {
      state.previewClaim = AsyncState.start()
    },
    previewClaimSuccess: (state, action) => {
      state.previewClaim = AsyncState.success(action.payload)
    },
    previewClaimFailed: (state, action) => {
      state.previewClaim = AsyncState.failed(action.payload)
    },
    claimStart: (state) => {
      state.claim = AsyncState.start()
    },
    claimSuccess: (state, action) => {
      state.claim = AsyncState.success(action.payload)
    },
    claimFailed: (state, action) => {
      state.claim = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = claimDivitMilesSlice

export default claimDivitMilesSlice.reducer
