import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import { ReactComponent as ActivateIconSvg } from '@/assets/eDDA/activate-icon.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import { TitleContainer } from '@/components/ui/Title'
import { KYC_STATUS, KYC_TYPE } from '@/constants/kyc'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { ActionButtons, CancelButton, MainButton } from '@/pages/profile/Shared'
import { goBack, mustReplace, replace } from '@/redux/app/appActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { verifyIdentity } from '@/redux/pin/pinActions'
import {
  eDDAInitedSelector,
  paymentAccountSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'
import * as BankUtils from '@/utils/Bank'
import { getKycByType } from '@/utils/kyc'

const TitleIcon = styled(Icon)`
  width: 100%;
`

const SubTitle = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
`

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.889rem;
  font-weight: 600;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const IDNotice = styled.div`
  max-width: 70%;
  font-size: 0.667rem;
`

const IDText = styled.div`
  max-width: 70%;
  font-size: 0.778rem;
`

const AccountText = styled.div`
  max-width: 70%;
  font-size: 0.778rem;
`

const TextButton = styled.div`
  text-decoration: underline;
  font-size: 0.778rem;
  font-weight: 700;
  cursor: pointer;
`

const TickIcon = () => (
  <Icon
    renderImage={() => <SuccessOutlinedSvg />}
    width="1.111rem"
    height="1.111rem"
  />
)

const EDDASetup = () => {
  const intl = useIntl()
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()

  const [isActivate, setIsActivate] = useState(
    () => qs.parse(window.location.search)?.activate === 'true'
  )

  const profile = useSelector(profileSelector)
  const isEDDAInited = useSelector(eDDAInitedSelector)
  const paymentAccount = useSelector(paymentAccountSelector)
  const { initEDDA } = useSelector((s) => s.setupEDDA)
  const { createVerifyPayment } = useSelector((s) => s.paynowVerify)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileEDDA' },
  })

  const checkIsValidHKID = () =>
    !!profile.nationalID &&
    getKycByType(profile.kyc, KYC_TYPE.PARTIAL)?.status === KYC_STATUS.GREEN

  const checkIsValidAccountInfo = () => !!paymentAccount

  const doActivate = async () => {
    const { isSuccess } = await dispatch(verifyIdentity())
    if (isSuccess) {
      dispatch({ type: 'setupEDDA/initEDDA' })
    }
  }

  useUnmount(() => {
    dispatch({ type: 'setupEDDA/reset' })
    dispatch({ type: 'paynowVerify/reset' })
  })

  useEffect(() => {
    if (isEDDAInited) {
      dispatch(replace('/edda/status'))
    }
  }, [])

  useEffect(() => {
    if (
      isActivate &&
      !isEDDAInited &&
      checkIsValidHKID() &&
      checkIsValidAccountInfo()
    ) {
      doActivate()
    } else {
      setIsActivate(false)
    }
  }, [])

  useUpdateEffect(async () => {
    if (initEDDA.isSuccess) {
      history.push('/edda/confirmation')
    }
    if (initEDDA.isError) {
      let content = tt(intl, 'edda.init.failure')
      if (initEDDA.error?.code === 7213) {
        content = tt(intl, 'edda.initpayment.failure.hkidmatches')
      }
      await dispatch(
        promptConfirmModal({
          title: tt(intl, 'common.aiyah'),
          content,
          closable: false,
        })
      )
      // disable activate process
      dispatch(mustReplace(pathname))
    }
  }, [initEDDA])

  useUpdateEffect(() => {
    if (createVerifyPayment.isSuccess) {
      const { orderID: verifyOrderId } = createVerifyPayment.data
      history.push(`/edda/verify/${verifyOrderId}`, {
        nextPage: pathname,
      })
    }
    if (createVerifyPayment.isError) {
      dispatch(
        promptConfirmModal({
          title: tt(intl, 'common.aiyah'),
          content: tt(intl, 'edda.initpayment.failure'),
          closable: false,
        })
      )
    }
  }, [createVerifyPayment])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onClickAddHKID = () => {
    history.push('/profile/kyc', {
      isWait: true,
      requestKycType: KYC_TYPE.PARTIAL,
      nextPage: pathname,
    })
  }

  const onClickEditPaymentInfo = () => {
    dispatch({ type: 'paynowVerify/createVerifyPayment' })
  }

  const isValidHKID = checkIsValidHKID()
  const isValidAccountInfo = checkIsValidAccountInfo()
  const isActivateButtonEnabled = profile.nationalID && paymentAccount

  if (isActivate) return <LoadingScreen />

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <TitleContainer onClickBack={onClickBack}>
            <TitleIcon
              renderImage={() => <ActivateIconSvg />}
              width="11rem"
              height="6.28rem"
            />
          </TitleContainer>
          <Spacer height="1.778rem" />
          <SubTitle>{tt(intl, 'edda.activate')}</SubTitle>
          <Spacer height="1.333rem" />
          <Row>
            <SectionTitle>
              {tt(intl, 'common.hkidcard')}
              <Spacer width="0.444rem" />
              {isValidHKID && <TickIcon />}
            </SectionTitle>
            {!isValidHKID && (
              <TextButton onClick={onClickAddHKID}>
                {tt(intl, 'common.add')}
              </TextButton>
            )}
          </Row>
          <Spacer height="0.889rem" />
          <IDNotice>{tt(intl, 'edda.hkidmatches')}</IDNotice>
          {isValidHKID && profile.nationalID && (
            <>
              <Spacer height="0.889rem" />
              <IDText>{profile.nationalID}</IDText>
            </>
          )}
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          <Row>
            <SectionTitle>
              {tt(intl, 'common.bankaccount')}
              <Spacer width="0.444rem" />
              {isValidAccountInfo && <TickIcon />}
            </SectionTitle>
            {!paymentAccount && (
              <TextButton onClick={onClickEditPaymentInfo}>
                {tt(intl, 'common.add')}
              </TextButton>
            )}
          </Row>
          {paymentAccount && (
            <>
              <Spacer height="0.889rem" />
              <AccountText>{paymentAccount.holderName}</AccountText>
              <Spacer height="0.444rem" />
              <AccountText>
                {BankUtils.getBankString(
                  paymentAccount.bankCode,
                  currentLocale
                )}
              </AccountText>
              <Spacer height="0.444rem" />
              <AccountText>{paymentAccount.accountNum}</AccountText>
            </>
          )}
          <Spacer height="2.222rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <CancelButton onClick={onClickBack}>
              {tt(intl, 'common.cancel')}
            </CancelButton>
            <MainButton
              disabled={!isActivateButtonEnabled}
              onClick={doActivate}
            >
              {tt(intl, 'common.activate')}
            </MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default EDDASetup
