import QueryString from 'query-string'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ErrorScreen from '@/components/error/ErrorScreen'
import LoadingScreen from '@/components/LoadingScreen'
import useQueryString from '@/hooks/useQueryString'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import PaynowSuccessPlainScreen from '@/pages/paynow/PaynowSuccessPlainScreen'

// resume payment from external, eg: email
// then go to fast checkout

const PaynowResume = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { token = '' } = useQueryString()

  const { resumeOrder } = useSelector((s) => s.paynowFastFps)

  const goFastCheckout = (orderId, qs) => {
    setTimeout(() => {
      history.replace(`/paynow/${orderId}/fast?${qs}`)
    }, 200)
  }

  useEffect(() => {
    dispatch({ type: 'paynowFastFps/resumeOrder', payload: { token } })
  }, [])

  useUpdateEffect(() => {
    if (resumeOrder.isSuccess) {
      const { data } = resumeOrder
      const orderId = data.orderID
      const qs = QueryString.stringify({ token: data.token })
      goFastCheckout(orderId, qs)
    }
  }, [resumeOrder])

  if (resumeOrder.isError) {
    if (resumeOrder.error?.code === 43005 && resumeOrder.error?.message.includes('completed')) {
      return <PaynowSuccessPlainScreen />
    }

    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={tt(intl, 'error.order.invalid')}
      />
    )
  }

  return <LoadingScreen />
}

export default PaynowResume
