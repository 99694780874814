import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { tt } from '@/locales/format'
import { joinAddress } from '@/utils/Address'

import AddressSetupModal from './AddressSetup'
import VerifiedStatus from './VerifiedStatus'

const Container = styled.div`
  display: flex;
  padding: 1.2rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const Content = styled.div`
  width: 70%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 80%;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const SubTitle = styled.div`
  font-size: 16px;
  margin-top: 0.5rem;
`

const HKAddressVerify = ({ profile }) => {
  const intl = useIntl()
  const [isShowAddressModal, setIsShowAddressModal] = useState(false)

  const billingAddress = profile?.address?.filter(
    (add) => add.addressType === 'billing'
  )[0]

  const isIdValidated =
    billingAddress?.address1 ||
    billingAddress?.address2 ||
    billingAddress?.district ||
    billingAddress?.country

  const checkVerify = (infor) => {
    if (infor) {
      return 'verified'
    }
    return 'unverified'
  }

  return (
    <>
      <Container onClick={() => setIsShowAddressModal(true)}>
        <Content>
          <Title>{tt(intl, 'profile.address')}</Title>
          {isIdValidated && (
            <SubTitle>
              {joinAddress([
                billingAddress.address1,
                billingAddress.address2,
                billingAddress.district,
                billingAddress.country,
              ])}
            </SubTitle>
          )}
        </Content>
        <VerifiedStatus status={checkVerify(isIdValidated)} />
      </Container>
      <AddressSetupModal
        isOpen={isShowAddressModal}
        onClose={() => setIsShowAddressModal(false)}
      />
    </>
  )
}

export default HKAddressVerify
