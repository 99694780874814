import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { fetchProfile } from '../profile/profileSaga'
import { getErrorMessage } from '../utils/error'
import { actions as verifyEmailActions } from './verifyEmailSlice'

// verify email from email
export function* verifyEmail({ payload }) {
  try {
    yield put(verifyEmailActions.verifyEmailStart())
    const { data } = yield call(() => DivitAPI.post('/verify/email', payload))
    yield put({ type: 'fetchProfile' })
    yield put(verifyEmailActions.verifyEmailSuccess(data))
  } catch (e) {
    yield put(verifyEmailActions.verifyEmailFailed(getErrorMessage(e)))
  }
}

export function* sendEmailOTP({ payload }) {
  try {
    yield put(verifyEmailActions.sendOTPStart())
    const { data } = yield call(() => DivitAPI.patch('profiles/email', payload))
    yield put({ type: 'fetchProfile' })
    yield put(verifyEmailActions.sendOTPSuccess(data))
  } catch (e) {
    yield put(verifyEmailActions.sendOTPFailed(getErrorMessage(e)))
  }
}

export function* resendEmailOTP({ payload }) {
  try {
    yield put(verifyEmailActions.sendOTPStart())
    const { data } = yield call(() =>
      DivitAPI.post('verify/email/send', payload)
    )
    yield put(verifyEmailActions.sendOTPSuccess(data))
  } catch (e) {
    yield put(verifyEmailActions.sendOTPFailed(getErrorMessage(e)))
  }
}

export function* verifyEmailOTP({ payload }) {
  try {
    yield put(verifyEmailActions.verifyOTPStart())
    const { data } = yield call(() =>
      DivitAPI.post('verify/email/otp', payload)
    )
    yield fetchProfile()
    yield put(verifyEmailActions.verifyOTPSuccess(data))
  } catch (e) {
    yield put(verifyEmailActions.verifyOTPFailed(getErrorMessage(e)))
  }
}
