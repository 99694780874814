import { call, put, select } from 'redux-saga/effects'

import { fetchDivitMiles } from '@/redux/divitMiles/divitMilesSaga'
import * as lookupSagas from '@/redux/lookup/lookupSaga'
import DivitAPI from '@/services/api'

import { fetchServices } from '../lookup/lookupSaga'
import { checkFpsIsAvailable } from '../profile/profileSaga'
import { getError, getErrorMessage } from '../utils/error'
import { actions as payWithFpsActions } from './payWithFpsSlice'

// example:
// const callbackUri = `${window.location.origin}/payment/status/${orderId}/${instalmentId}`

export function* fetchOrder({ payload }) {
  try {
    yield put(payWithFpsActions.fetchOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`profiles/orders/${orderId}`)
    )

    const { partnerid, totalAmount } = yield select((s) => s.order?.order)
    yield lookupSagas.fetchMerchantRateCurrencyToDVM(
      partnerid,
      totalAmount?.currency
    )

    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(payWithFpsActions.fetchOrderSuccess(data.data))
  } catch (e) {
    yield put(payWithFpsActions.fetchOrderFailed(getErrorMessage(e)))
  }
}

export function* generateFpsLink({ payload }) {
  try {
    yield put(payWithFpsActions.generateFpsLinkStart())
    yield fetchServices()
    yield checkFpsIsAvailable()
    const { cachedFpsData } = yield select((s) => s.payWithFps)
    if (cachedFpsData) {
      yield put(payWithFpsActions.generateFpsLinkSuccess(cachedFpsData))
      yield put(payWithFpsActions.clearCachedFpsData())
    } else {
      const { pin, orderId, instalmentId, isBurn, paymentMethod } = payload
      const { data } = yield call(() =>
        DivitAPI.post('payments', {
          pin,
          order_id: orderId,
          instalment_id: instalmentId,
          instrumentCode: paymentMethod || 'fps',
        })
      )
      const order = yield call(() => DivitAPI.get(`profiles/orders/${orderId}`))
      if (isBurn) {
        yield fetchDivitMiles()
      }
      yield put({ type: 'order/getOrderSuccess', payload: order.data.data })
      yield put(
        payWithFpsActions.generateFpsLinkSuccess(data.data.gateway_response)
      )
    }
  } catch (e) {
    yield put(payWithFpsActions.generateFpsLinkFailed(getError(e)))
  }
}

export function* testGenerateFpsLink({ payload }) {
  try {
    yield put(payWithFpsActions.generateFpsLinkStart())
    const { orderId, instalmentId, amount, paymentMethod } = payload
    const { data } = yield call(() =>
      DivitAPI.post('payments/amount', {
        order_id: orderId,
        instalment_id: instalmentId,
        instrumentCode: paymentMethod || 'fps',
        amount,
      })
    )
    yield put(
      payWithFpsActions.generateFpsLinkSuccess(data.data.gateway_response)
    )
  } catch (e) {
    yield put(payWithFpsActions.generateFpsLinkFailed(getError(e)))
  }
}

export function* payByEDDA({ payload }) {
  try {
    yield put(payWithFpsActions.payByEDDAStart())
    const { pin, orderId, instalmentId } = payload
    const { data } = yield call(() =>
      DivitAPI.post('payments', {
        pin,
        order_id: orderId,
        instalment_id: instalmentId,
        instrumentCode: 'edda',
      })
    )
    yield put(payWithFpsActions.payByEDDASuccess(data.data))
  } catch (e) {
    yield put(payWithFpsActions.payByEDDAFailed(getError(e)))
  }
}

export function* fpsRefreshOrder({ payload }) {
  try {
    yield put(payWithFpsActions.refreshOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`profiles/orders/${orderId}`)
    )
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(payWithFpsActions.refreshOrderSuccess(data.data))
  } catch (e) {
    yield put(payWithFpsActions.refreshOrderFailed(getErrorMessage(e)))
  }
}

export default null
