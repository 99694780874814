import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as EmptySvg } from '@/assets/giftcard/empty.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import GiftCardImage from '@/components/giftcard/GiftCardImage'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import {
  giftCardActiveBrandsSelector,
  giftCardInactiveBrandsSelector,
} from '@/redux/profile/profileSelector'
import * as GiftCard from '@/utils/GiftCard'

const Container = styled(PaddingContent)`
  position: relative;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const Anchor = styled.div`
  display: inline-block;
  font-size: 0.778rem;
  text-decoration: underline;
  margin: 0 auto;
  cursor: pointer;
`

const CardList = styled.div`
  display: grid;
  grid-template-columns: 100%;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    grid-template-columns: 50% 50%;
  }
`

const CardItem = styled.div`
  width: 100%;
  margin-bottom: 1.333rem;
`

const MainContent = styled.div`
  position: relative;
  width: 100%;
`

const Footer = styled.div`
  font-size: 0.667rem;
  margin-top: 0.889rem;
  margin-left: 0.778rem;
`

const EmptyTitle = styled.div`
  font-weight: 700;
  font-size: 1.111rem;
  text-align: center;
`

const EmptyText = styled.div`
  max-width: 15rem;
  margin: 0 auto;
  font-weight: 700;
  font-size: 0.889rem;
  line-height: 1.5em;
  text-align: center;
`

const EmptyButtons = styled.div`
  text-align: center;
`

const SpendButton = styled(Button)`
  background-color: white;
  border: 1px solid #dedede;
`

const EmptyScreen = ({ hasUsed, onClickSpend, onClickViewUsed }) => {
  const intl = useIntl()
  return (
    <>
      {hasUsed && (
        <>
          <Spacer height="0.889rem" />
          <Anchor onClick={onClickViewUsed}>
            {tt(intl, 'giftcard.viewusedexpired')}
          </Anchor>
        </>
      )}
      <Spacer height="5.278rem" />
      {!hasUsed && <EmptyTitle>{tt(intl, 'giftcard.empty')}</EmptyTitle>}
      <Spacer height="1.333rem" />
      <Icon
        renderImage={() => <EmptySvg />}
        width="7.585rem"
        height="6.283rem"
      />
      <Spacer height="1.333rem" />
      <EmptyText>
        {tt(intl, 'giftcard.empty.msg1')}
        <br />
        {tt(intl, 'giftcard.empty.msg2')}
      </EmptyText>
      <Spacer height="0.889rem" />
      <EmptyButtons>
        <SpendButton onClick={onClickSpend}>
          {tt(intl, 'giftcard.spendnow')}
        </SpendButton>
      </EmptyButtons>
    </>
  )
}

const BrandCardContainer = styled.div`
  width: 18.444rem;
  height: 11.444rem;
  margin: 0 auto;
  border-radius: 8px;
  cursor: pointer;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 11.5rem;
    height: 7.18rem;
  }
`

const CardsScreen = ({
  hasUsed,
  giftCardBrands,
  onClickCardItem,
  onClickViewUsed,
}) => {
  const intl = useIntl()

  return (
    <>
      <Spacer height="0.889rem" />
      {!hasUsed && <SubTitle>{tt(intl, 'giftcard.findbelow')}</SubTitle>}
      {hasUsed && (
        <>
          <SubTitle>{tt(intl, 'giftcard.findbelowor')}</SubTitle>
          <Anchor onClick={onClickViewUsed}>
            {tt(intl, 'giftcard.viewusedexpired')}
          </Anchor>
        </>
      )}
      <Spacer height="1.778rem" />
      <MainContent>
        <CardList>
          {giftCardBrands.map(({ tag }) => (
            <CardItem key={tag}>
              <BrandCardContainer onClick={onClickCardItem(tag)}>
                <GiftCardImage image={GiftCard.getGiftCardImageUrl(tag)} />
              </BrandCardContainer>
            </CardItem>
          ))}
        </CardList>
      </MainContent>
      <Footer>{tt(intl, 'giftcard.contact.support')}</Footer>
    </>
  )
}

const ProfileGiftCards = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const giftCardBrands = useSelector(giftCardActiveBrandsSelector)
  const hasUsed = useSelector(giftCardInactiveBrandsSelector).length > 0

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileGiftCardBrands' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickSpend = () => {
    history.push('/miles/spend')
  }

  const onClickCardItem = (brandCode) => () => {
    history.push(`/profile/gift-cards/${brandCode}`)
  }

  const onClickViewUsed = () => {
    history.push('/profile/gift-cards/used')
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const isEmpty = giftCardBrands.length === 0

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Spacer height="2.222rem" />
          <Title onClickBack={onClickBack}>{tt(intl, 'giftcard.viewmy')}</Title>
          {isEmpty && (
            <EmptyScreen
              hasUsed={hasUsed}
              onClickSpend={onClickSpend}
              onClickViewUsed={onClickViewUsed}
            />
          )}
          {!isEmpty && (
            <CardsScreen
              hasUsed={hasUsed}
              giftCardBrands={giftCardBrands}
              onClickCardItem={onClickCardItem}
              onClickViewUsed={onClickViewUsed}
            />
          )}
          <Spacer height="4.444rem" />
        </Container>
      </CSLayout.CSContent>
    </CSLayout.CSContainer>
  )
}

export default ProfileGiftCards
