export const sleep = (ms) =>
  new Promise((resolve) => setTimeout(() => resolve(), ms))

export const promisfyDispatch = ({ type = '', payload = {} }) => (dispatch) =>
  new Promise((resolve) =>
    dispatch({
      type,
      payload: {
        ...payload,
        callback: (result) => {
          resolve(result)
        },
      },
    })
  )

export const waitForDomElement = async (
  getElementFn,
  interval = 10,
  maxCounts = 10
) => {
  let counter = maxCounts
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      const ret = getElementFn()
      if (counter > 0) {
        if (ret) {
          clearInterval(timer)
          resolve(true)
        } else {
          counter -= 1
        }
      } else {
        clearInterval(timer)
        resolve(false)
      }
    }, interval)
  })
}

export default 0
