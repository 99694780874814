import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import { ReactComponent as CopyIconSvg } from '@/assets/referral-modal/copy-icon.svg'
import HeroIconPng from '@/assets/referral-modal/hero-icon.png'
import { ReactComponent as MemberIconSvg } from '@/assets/referral-modal/member-icon.svg'
import { ReactComponent as RewardIconSvg } from '@/assets/referral-modal/reward-icon.svg'
import { ReactComponent as ShopIconSvg } from '@/assets/referral-modal/shop-icon.svg'
import { ReactComponent as Step1Svg } from '@/assets/referral-modal/step1.svg'
import { ReactComponent as Step2Svg } from '@/assets/referral-modal/step2.svg'
import { ActionButtons, PrimaryButton } from '@/components//ui/Shared'
import IconButton from '@/components/buttons/IconButton'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import Modal from '@/components/Modal'
import Spacer from '@/components/Spacer'
import { Features, useFeature } from '@/hooks/useFeature'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { copyText, goBack, shareOrCopy } from '@/redux/app/appActions'
import { profileSelector } from '@/redux/profile/profileSelector'

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 600px;
  min-height: var(--app-height);
  margin: 0 auto;
  background-color: white;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0.88rem;
  right: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
`

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const RowResponsive = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    margin-bottom: 0.889rem;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${(p) => `${p.theme.breakpoints.xs}px`}) {
    flex-direction: row;

    & > div {
      margin-right: 0.889rem;
      margin-bottom: 0;
    }

    & > div:last-child {
      margin-right: 0;
    }
  }
`

const ContentTitle = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
`

const SubTitle = styled.div`
  font-size: 0.889rem;
  font-weight: 700;
`

const Section = styled.div`
  display: flex;
  align-items: flex-start;
`

const SectionContent = styled.div`
  margin-left: 0.778rem;
`

const SectionTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
  margin-bottom: 0.444rem;
`

const SectionText = styled.div`
  font-size: 0.778rem;
`

const ReferralShareContent = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { referralCode, firstName, lastName } = useSelector(profileSelector)
  const referralLink = `${window.location.origin}/signup/r/${referralCode}`

  const onClickCopyCode = () => {
    dispatch(copyText({ text: referralCode }))
  }

  const onClickCopyLink = () => {
    dispatch(copyText({ text: referralLink }))
  }

  const onClickShare = () => {
    const text = tt(intl, 'referral.refertemplate', {
      name: [firstName, lastName].filter((n) => n).join(' '),
      referralLink,
      referralCode,
    })
    try {
      dispatch(shareOrCopy({ text }))
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Icon
            renderImage={() => <img src={HeroIconPng} alt="hero" />}
            width="11.889rem"
            height="6.278rem"
          />
          <Spacer height="1.778rem" />
          <ContentTitle>{tt(intl, 'referral.refermore.title')}</ContentTitle>
          <Spacer height="1.278rem" />
          <SubTitle>{tt(intl, 'common.howitworks')}</SubTitle>
          <Spacer height="1.111rem" />
          <Section>
            <Icon
              renderImage={() => <MemberIconSvg />}
              width="1.111rem"
              height="1.111rem"
            />
            <SectionContent>
              <SectionTitle>{tt(intl, 'referral.invitefriends')}</SectionTitle>
              <SectionText>
                {tt(intl, 'referral.sharelink', { code: referralCode })}
              </SectionText>
              <Spacer height="0.667rem" />
              <RowResponsive>
                <IconButton
                  text={tt(intl, 'referral.copycode')}
                  iconProps={{
                    renderImage: () => <CopyIconSvg />,
                    width: '1.111rem',
                    height: '1.111rem',
                  }}
                  spacing="0.889rem"
                  onClick={onClickCopyCode}
                />
                <IconButton
                  text={tt(intl, 'referral.copylink')}
                  iconProps={{
                    renderImage: () => <CopyIconSvg />,
                    width: '1.111rem',
                    height: '1.111rem',
                  }}
                  spacing="0.889rem"
                  onClick={onClickCopyLink}
                />
              </RowResponsive>
            </SectionContent>
          </Section>
          <Spacer height="0.889rem" />
          <Section>
            <Icon
              renderImage={() => <ShopIconSvg />}
              width="1.111rem"
              height="1.111rem"
            />
            <SectionContent>
              <SectionTitle>
                {tt(intl, 'referral.youfriendwillearn')}
              </SectionTitle>
              <Spacer height="0.889rem" />
              <Row>
                <Icon
                  renderImage={() => <Step1Svg />}
                  width="1.556rem"
                  height="1.556rem"
                />
                <Spacer width="0.889rem" />
                <SectionText>
                  {tt(intl, 'referral.youfriendwillearn.doublemiles')}
                </SectionText>
              </Row>
              <Spacer height="0.889rem" />
              <Row>
                <Icon
                  renderImage={() => <Step2Svg />}
                  width="1.556rem"
                  height="1.556rem"
                />
                <Spacer width="0.889rem" />
                <SectionText>
                  {tt(intl, 'referral.youfriendwillearn.500')}
                </SectionText>
              </Row>
              <Spacer height="0.889rem" />
              <SectionText>
                {tt(intl, 'referral.youfriendwillearn.expires')}
              </SectionText>
            </SectionContent>
          </Section>
          <Spacer height="1.333rem" />
          <Section>
            <Icon
              renderImage={() => <RewardIconSvg />}
              width="1.111rem"
              height="1.111rem"
            />
            <SectionContent>
              <SectionTitle>{tt(intl, 'referral.youwillearn')}</SectionTitle>
              <SectionText>{tt(intl, 'referral.500eachreferred')}</SectionText>
            </SectionContent>
          </Section>
          <Spacer height="1.6rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <PrimaryButton onClick={onClickShare}>
              {tt(intl, 'referral.sharelinknow')}
            </PrimaryButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export const ProfileReferralShareModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} isLock>
    <ModalContainer>
      <CloseButton onClick={onClose}>
        <Icon
          renderImage={() => <CloseSvg />}
          width="2.66rem"
          height="2.66rem"
        />
      </CloseButton>
      <ReferralShareContent />
    </ModalContainer>
  </Modal>
)

const ProfileReferralSharePage = () => {
  const dispatch = useDispatch()
  const { featureEnabled } = useFeature()

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileMain' },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  if (!featureEnabled(Features.REFERRAL)) {
    return <Redirect to="/profile" />
  }

  return (
    <PageContainer>
      <ProfileReferralShareModal isOpen onClose={onClickBack} />
    </PageContainer>
  )
}

export default ProfileReferralSharePage
