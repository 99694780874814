import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as MoreSvg } from '@/assets/common/arrow-more.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import MerchantName from '@/components/merchants/MerchantName'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import BankLogo from '@/components/ui/BankLogo'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { getCurrencyToDVMSelector } from '@/redux/lookup/lookupSelector'
import {
  divitMilesSelector,
  eDDAAvailableAccountSelector,
} from '@/redux/profile/profileSelector'
import * as BankUtils from '@/utils/Bank'
import { FormattedPrice } from '@/utils/Price'

const Container = styled(CSLayout.CSContainer)`
  background-color: #fafafa;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const ItemTitle = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`

const ItemValue = styled.div`
  font-size: 0.889rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BankAccount = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const BankAccountDetails = styled.div`
  flex: 1;
`

const BankAccountName = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const BankAccountNo = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const OrderAmount = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

const PayWithDivitMiles = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eff0f1;
  padding: 0.8rem 1.2rem;
  margin-top: 1.2rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  background-color: #fff;

  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;

  @media (max-width: 640px) {
    margin-top: 1.2rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
`

const PayWithDivitMilesTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
`
const PayWithDivitMilesBalance = styled(PayWithDivitMilesTitle)``

const DivitMilesValue = styled(MilesValue).attrs({ type: 'yellow' })`
  & div {
    font-weight: 700;
    letter-spacing: 0.03em;
  }
`
const PaynowPreviewPayment = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()
  const { orderId } = useParams()

  const { linkOrder } = useSelector((s) => s.paynow)
  const { order, isFromFastPaynow } = useSelector((s) => s.order)
  const eDDAAccount = useSelector(eDDAAvailableAccountSelector)
  const rateCurrencyToDVM = useSelector(getCurrencyToDVMSelector)
  const divitMiles = useSelector(divitMilesSelector)

  const [inited, setInited] = useState(false)

  const { retry, isInitSuccess, isInitError, isInitLoading } = usePage({
    skipInit: true,
    initAction: { type: 'pageInit/initPaynowOrder', payload: { orderId } },
  })

  // link order only if come from fast paynow click burn
  useEffect(() => {
    const isTransferedOrder =
      sessionStorage.getItem('transfered_order') === orderId
    if (!isTransferedOrder && isFromFastPaynow) {
      dispatch({ type: 'paynow/linkOrder', payload: { orderId } })
      dispatch({ type: 'order/setIsFromFastPaynow', payload: false })
    } else {
      sessionStorage.setItem('transfered_order', orderId)
      setInited(true)
      retry()
    }

    return () => dispatch({ type: 'paynow/reset' })
  }, [])

  // if no eDDA, then go to burn redirectly
  useUpdateEffect(() => {
    if (isInitSuccess) {
      if (!eDDAAccount) {
        history.replace(`/paynow/${orderId}`)
      }
    }
  }, [isInitSuccess])

  // then get other details after link order
  useUpdateEffect(async () => {
    if (linkOrder.isSuccess) {
      // maybe transfered from fast paynow
      sessionStorage.setItem('transfered_order', orderId)

      // initialize
      setInited(true)
      retry()
    }
  }, [linkOrder])

  if (linkOrder.isLoading) return <LoadingScreen />

  if (linkOrder.isError)
    return (
      <Container>
        <ErrorMessageBox
          errorMessage={intl.formatMessage({ id: 'error.action.link.order' })}
        />
      </Container>
    )

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!inited) return <LoadingScreen />
  if (!order) return <LoadingScreen />
  if (order.orderID !== orderId) return <></>
  const rate = rateCurrencyToDVM.filter(
    (r) => r.currency === order.currency
  )?.[0]

  const onClickBurn = async () => {
    history.push(`/paynow/${orderId}/burn`)
  }

  const onClickEDDA = () => {
    history.push(`/paynow/${orderId}?edda=true`)
  }

  const onClickFps = async () => {
    history.push(`/paynow/${orderId}`)
  }

  const { orderAmount } = order
  let amountToSave = 0
  const milesTxns = (order?.financialTransactions || []).filter(
    (fx) =>
      fx.transactionType === 'payment' &&
      fx.paidBy === 'miles' &&
      fx.transactionStatus === 'completed'
  )
  if (!milesTxns || milesTxns.length === 0) {
    amountToSave = (order.instalments || [])?.[0]?.milesAmount?.amount || 0
  } else {
    amountToSave = milesTxns?.[0]?.amount?.amount || 0
  }
  const milesBurned = (order.instalments || [])?.[0]?.miles || 0
  const amountToSpend = orderAmount.amount - amountToSave

  if (!eDDAAccount) return null

  return (
    <Container>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title onClickBack={onClickFps}>
            {tt(intl, 'paynow.reviewpayment')}
          </Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'paynow.eddareview')}</SubTitle>
          <Spacer height="1.778rem" />
          <ItemTitle>{tt(intl, 'payment.payto')}</ItemTitle>
          <Spacer height="0.889rem" />
          <ItemValue>
            <MerchantName merchantID={order.merchantID} />
          </ItemValue>
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          <ItemTitle>{tt(intl, 'payment.paywith')}</ItemTitle>
          <Spacer height="0.889rem" />
          <ItemValue>
            <Row>
              <BankAccount>
                <BankLogo
                  width="3.111rem"
                  height="3.111rem"
                  bankCode={eDDAAccount.bankCode}
                  isCircle
                />
                <Spacer width="0.889rem" />
                <BankAccountDetails>
                  <BankAccountName>
                    {BankUtils.getBankString(
                      eDDAAccount.bankCode,
                      currentLocale
                    )}
                  </BankAccountName>
                  <Spacer height="0.444rem" />
                  <BankAccountNo>{eDDAAccount.accountNum}</BankAccountNo>
                </BankAccountDetails>
              </BankAccount>
              <Spacer width="0.889rem" />
              {amountToSave > 0 && (
                <MilesAndAmountValue
                  fontSize="0.889rem"
                  currency={orderAmount.currency}
                  amount={amountToSpend}
                  miles={milesBurned}
                />
              )}
              {amountToSave === 0 && (
                <OrderAmount>
                  {FormattedPrice(orderAmount.currency, orderAmount.amount)}
                </OrderAmount>
              )}
            </Row>
          </ItemValue>
          <Spacer height="1.111rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter style={{ paddingBottom: '0rem' }}>
        <PaddingContent>
          <ActionButtons>
            <SecondaryButton onClick={onClickFps}>
              {tt(intl, 'payment.method.fps')}
            </SecondaryButton>
            <PrimaryButton type="main" onClick={onClickEDDA}>
              {tt(intl, 'common.confirm')}
            </PrimaryButton>
          </ActionButtons>
        </PaddingContent>

        <PayWithDivitMiles onClick={onClickBurn}>
          <PayWithDivitMilesTitle>
            {tt(intl, 'payment.paywithmiles')}
          </PayWithDivitMilesTitle>
          <PayWithDivitMilesBalance>
            <DivitMilesValue
              miles={divitMiles.balance}
              isSigned={false}
              fontSize="1rem"
              style={{ marginRight: '0.4rem' }}
            />
            <MoreSvg />
          </PayWithDivitMilesBalance>
        </PayWithDivitMiles>
      </CSLayout.CSFooter>
    </Container>
  )
}

export default PaynowPreviewPayment
