// Note: not using Divit components
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import OTPInputBox from '@/components/OTPInputBox'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import { useAuth } from '@/contexts/authContext'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'
import useCountDown from '@/hooks/useCountdown'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import * as User from '@/utils/user'

const Container = styled(PaddingContent)``

const Row = styled.div`
  display: flex;
  align-items: center;
`

const SubTitle = styled.div`
  max-width: 375px;
  font-size: 0.778rem;
  text-align: center;
  margin: 0 auto;
`

const ClientID = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
  text-align: center;
`

const ActionButtons = styled.div`
  text-align: center;
`

const SignUpButton = styled(Button)`
  font-size: 0.89rem;
`

const COUNT_DOWN_IN_SECOND = 60

const DivitMilesVerifyOTP = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { executeRecaptcha } = useCaptcha()
  const { authUserID } = useAuth()

  const { session, verifyOTP, sendOTP } = useSelector((s) => s.fastSignup)
  const { clientIdType, countryCode, clientId } = session || {}

  const { countDownInSec, setCountDownInSec } = useCountDown({
    timeoutInSec: COUNT_DOWN_IN_SECOND,
  })

  const otpInputBoxRef = useRef()
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [errorMessage, setErrorMessage] = useState('')

  // check correct sign up process
  if (!session) {
    history.replace('/signup')
  }

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (authUserID) {
      history.replace('/home')
    }
  }, [])

  const resetOTP = () => {
    setTimeout(() => {
      setOtp(['', '', '', '', '', ''])
      otpInputBoxRef.current?.resetFocus()
    }, 200)
  }

  useEffect(() => () => dispatch({ type: 'fastSignup/reset' }), [])

  useEffect(() => {
    if (sendOTP.isSuccess) {
      setCountDownInSec(COUNT_DOWN_IN_SECOND)
      resetOTP()
    }
    if (sendOTP.isError) {
      setErrorMessage(tt(intl, 'login.verification.resend.error'))
    } else {
      setErrorMessage('')
    }
  }, [sendOTP])

  useEffect(() => {
    if (verifyOTP.isSuccess) {
      history.push('/signup/setup-password')
    }
    if (verifyOTP.isError) {
      resetOTP()
      setErrorMessage(tt(intl, 'login.verification.code.error'))
    } else {
      setErrorMessage('')
    }
  }, [verifyOTP])

  const onClickBack = () => {
    dispatch(goBack())
  }

  const resendOTP = async () => {
    let recapResp = ''

    try {
      recapResp = await executeRecaptcha('SEND_EMAIL_OTP')
    } catch (err) {
      console.error(err)
    }

    dispatch({
      type: 'fastSignup/sendOTP',
      payload: {
        countryCode,
        [clientIdType]: clientId,
        recapResp,
      },
    })
  }

  const doVerifyOTP = (newOTP) => {
    dispatch({
      type: 'fastSignup/verifyOTP',
      payload: {
        countryCode,
        [clientIdType]: clientId,
        code: newOTP.join(''),
      },
    })
  }

  const onChangeOTP = (newOTP) => {
    setOtp(newOTP)

    if (newOTP.length === 6 && newOTP.every((digit) => digit.length === 1)) {
      doVerifyOTP(newOTP)
    }
  }

  // format clientid
  const displayId = User.getProfileId(countryCode, clientId)

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Row>
        <Title onClickBack={onClickBack}>
          {tt(intl, 'profile.verification')}
        </Title>
      </Row>
      <Spacer height="1rem" />
      <SubTitle>{tt(intl, 'login.signup.verification')}</SubTitle>
      <Spacer height="0.889rem" />
      <ClientID>{displayId}</ClientID>
      <Spacer height="1.111rem" />
      <ErrorMessageLine errorMessage={errorMessage} />
      <Spacer height="1.111rem" />
      <OTPInputBox ref={otpInputBoxRef} otp={otp} onChangeOTP={onChangeOTP} />
      <Spacer height="2.22rem" />
      <ActionButtons>
        <SignUpButton disabled={countDownInSec > 0} onClick={resendOTP}>
          <span>{tt(intl, 'common.resend')} </span>
          {countDownInSec > 0 && <span>{`(${countDownInSec})`}</span>}
        </SignUpButton>
      </ActionButtons>
      <Spacer height="2.22rem" />
      <LoadingModal loading={sendOTP.isLoading || verifyOTP.isLoading} />
    </Container>
  )
}

export default (props) => (
  <CaptchaProvider>
    <DivitMilesVerifyOTP {...props} />
  </CaptchaProvider>
)
