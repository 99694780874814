import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DivitSvg } from '@/assets/divit.svg'
import RegisterAgreement from '@/components/auth/RegisterAgreement'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'
import PasswordValidation from '@/components/PasswordValidation'
import Spacer from '@/components/Spacer'
import PasswordInput from '@/components/ui/PasswordInput'
import { useAuth } from '@/contexts/authContext'
import useBeforeunload from '@/hooks/useBeforeUnload'
import usePageError from '@/hooks/usePageError'
import usePasswordValiation from '@/hooks/usePasswordValidation'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { Button, Input } from '@/pages/Auth/shared/style'
import { setupAccount } from '@/redux/auth/authActions'
import * as User from '@/utils/user'

const Container = styled(PaddingContent)``

const Hero = styled.div``

const Title = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const SubTitle = styled.div`
  max-width: 375px;
  font-size: 0.778rem;
  text-align: center;
  margin: 0 auto;
`

const ClientID = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
  text-align: center;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.889rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const FormColumn = styled.div`
  flex: 1;
  ${({ position }) => position && `text-align: ${position}`}

  &:not(:last-child) {
    margin-right: 1rem;
  }
`

const PasswordStrengthMeterContainer = styled.div`
  width: 100%;
  margin-top: 0.889rem;
`

const SetupPasswordButton = styled(Button)`
  font-size: 0.89rem;
`

const DivitMilesSetupPassword = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { authUserID } = useAuth()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const { passwordValidation } = usePasswordValiation(password)
  const [checkedAgreement, setCheckedAgreement] = useState(false)
  const { errorMessage, updateErrorMessage } = usePageError()

  const { session, setupPassword } = useSelector((s) => s.fastSignup)
  const { profile } = useSelector((s) => s.profile)
  const { countryCode, clientId } = session || {}

  useBeforeunload(() => tt(intl, 'login.leave.ask'))

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (authUserID) {
      history.replace('/home')
    }
  }, [])

  useUpdateEffect(() => {
    if (profile?.isLoading) {
      updateErrorMessage('')
    } else {
      if (setupPassword.isSuccess) {
        dispatch(
          setupAccount({
            isSignup: true,
            isPrompt: false,
          })
        )
      }
      if (setupPassword.isError) {
        updateErrorMessage(tt(intl, 'login.setuppassword.fail'))
      } else {
        updateErrorMessage('')
      }
    }
  }, [setupPassword, profile])

  const validateForm = () => {
    if (!firstName.trim()) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.firstname' }))
      return false
    }
    if (!lastName.trim()) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.lastname' }))
      return false
    }
    if (!passwordValidation.valid) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.password' }))
      return false
    }
    return true
  }

  const onClicksetupPassword = (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    dispatch({
      type: 'fastSignup/setupPassword',
      payload: {
        firstName,
        lastName,
        pwd: password,
      },
    })
  }

  const loading = setupPassword.isLoading

  const displayId = User.getProfileId(countryCode, clientId)

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Hero>
            <Spacer height="1.778rem" />
            <Icon
              renderImage={() => <DivitSvg />}
              width="4.985rem"
              height="1.778rem"
            />
            <Spacer height="1.778rem" />
            <Title>{tt(intl, 'login.createaccount')}</Title>
            <Spacer height="0.889rem" />
            <SubTitle>{tt(intl, 'login.enjoyfastexperience')}</SubTitle>
            <Spacer height="0.889rem" />
            <ClientID>{displayId}</ClientID>
          </Hero>
          <Spacer height="0.665rem" />
          <ErrorMessageLine errorMessage={errorMessage} />
          <Spacer height="0.665rem" />
          <Form>
            <FormRow>
              <FormColumn>
                <Input
                  id="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={tt(intl, 'common.firstname')}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <Input
                  id="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={tt(intl, 'common.lastname')}
                />
              </FormColumn>
            </FormRow>
            <PasswordInput value={password} onChange={(v) => setPassword(v)} />
            {!!password && (
              <PasswordStrengthMeterContainer>
                <PasswordStrengthMeter
                  score={passwordValidation.strengthScore}
                />
              </PasswordStrengthMeterContainer>
            )}
            {!!password && !passwordValidation.ignored && (
              <>
                <Spacer height="0.889rem" />
                <PasswordValidation
                  hasSufficientLength={passwordValidation.validLength}
                  hasNumber={passwordValidation.validNumber}
                  hasLowercase={passwordValidation.validLowercase}
                  hasUppercase={passwordValidation.validUppercase}
                  hasSymbol={passwordValidation.validSymbol}
                />
              </>
            )}
          </Form>
          <Spacer height="1.333rem" />
          <RegisterAgreement
            checked={checkedAgreement}
            onClick={() => setCheckedAgreement((s) => !s)}
          />
          <Spacer height="2.222rem" />
        </Container>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <Container>
          <SetupPasswordButton
            type="stretch"
            disabled={
              !(password && passwordValidation.valid && checkedAgreement)
            }
            onClick={onClicksetupPassword}
          >
            {tt(intl, 'common.continue')}
          </SetupPasswordButton>
        </Container>
      </CSLayout.CSFooter>
      <LoadingModal loading={loading} />
    </CSLayout.CSContainer>
  )
}

export default DivitMilesSetupPassword
