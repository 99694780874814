import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import PaylaterProfileCompletionList from '@/pages/profile/PaylaterProfileCompletionList'
import { goBack } from '@/redux/app/appActions'
import { profileSelector } from '@/redux/profile/profileSelector'

const Container = styled(PaddingContent)``

const Profile = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const profile = useSelector(profileSelector)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileMain' },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!profile) return <></>

  const onClickBack = () => {
    dispatch(goBack())
  }

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>
        {tt(intl, 'paylater.activate.title')}
      </Title>
      <Spacer height="1.778rem" />
      <PaylaterProfileCompletionList />
    </Container>
  )
}

export default Profile
