import styled from 'styled-components'

export const Block = styled.div`
  padding: 1.5rem 1.111rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.04);
`

export const BlockItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.111rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const BlockItemTitle = styled.div`
  font-size: 0.778rem;
`

export const BlockItemValue = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
`
