import styled from 'styled-components'

const Spacer = styled.div`
  width: ${({ width }) => width || '100%'};
  min-width: ${({ width }) => width || '100%'};
  height: ${({ width, height }) => (width ? 'auto' : height || '2rem')};
  min-height: ${({ width, height }) => (width ? 'auto' : height || '2rem')};
`

export default Spacer
