import React from 'react'
import ReactModal from 'react-modal'

const ReactModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 'auto',
    background: 'none',
    zIndex: 4,
  },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 'auto',
    width: '100%',
    border: '0',
    background: 'none',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '0',
    borderRadius: 0,
  },
}

const ModalToast = ({ isOpen, style, ...rest }) => {
  const s = {
    overlay: {
      ...ReactModalStyle.overlay,
      ...style?.overlay,
    },
    content: {
      ...ReactModalStyle.content,
      ...style?.content,
    },
  }

  const onAfterOpen = () => {
    // for always exist modal
    // remove default overlay classname
    setTimeout(() => {
      const overlay = document.querySelector('.MessageToast')
      if (!overlay) return
      overlay.classList.remove('ReactModal__Overlay')
    }, 0)
  }

  return (
    <ReactModal
      {...rest}
      overlayClassName="MessageToast ReactModal__Overlay-show"
      contentLabel="modal"
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      style={s}
      ariaHideApp={false}
    />
  )
}

ModalToast.defaultProps = {
  isOpen: true,
  style: {},
}

export default ModalToast
