import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import PaynowMilesDetails from '@/pages/paynow/PaynowMilesDetails'
import { startRequestPinFlow } from '@/redux/pin/pinActions'
import * as PaymentUtils from '@/utils/Payment'

const Container = styled(PaddingContent)`
  background-color: #fafafa;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const ConfirmButton = styled(PrimaryButton)`
  background: #ffcc33;
  color: #1c1c1c;
`

const PaynowPreview = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { checkoutMiles = 0 } = state || {}
  const { orderId } = useParams()

  const { linkOrder, burnMiles, generateFpsLink } = useSelector((s) => s.paynow)
  const { order } = useSelector((s) => s.order)

  const [milesToBurn, setMilesToBurn] = useState(0)
  const [prevMilesBurned, setPrevMilesBurned] = useState(0)
  const [inited, setInited] = useState(false)
  const [errorMessage, updateErrorMessage] = useState('')

  const { retry, isInitSuccess, isInitError, isInitLoading } = usePage({
    skipInit: true,
    initAction: { type: 'pageInit/initPaynowOrder', payload: { orderId } },
  })

  // initialize miles to burn
  useEffect(() => {
    const miles = sessionStorage.getItem('order_checkout_miles')
    setMilesToBurn(Number(miles) || 0)
  }, [])

  // link order only once
  useEffect(() => {
    const isTransferedOrder =
      sessionStorage.getItem('transfered_order') === orderId
    if (!isTransferedOrder) {
      dispatch({ type: 'paynow/linkOrder', payload: { orderId } })
    } else {
      sessionStorage.setItem('transfered_order', orderId)
      setInited(true)
      retry()
    }
    return () => {
      dispatch({ type: 'paynow/reset' })
    }
  }, [])

  // redirect to burn page if order has miles burned
  useEffect(() => {
    if (order?.milesBurned > 0) {
      history.replace(`/paynow/${orderId}/burn`)
    }
  }, [isInitSuccess])

  // then get other details after link order
  useUpdateEffect(async () => {
    if (linkOrder.isSuccess) {
      // maybe transfered from fast paynow
      sessionStorage.setItem('transfered_order', orderId)

      // initialize
      setInited(true)
      retry()
    }
  }, [linkOrder])

  useUpdateEffect(async () => {
    if (burnMiles.isSuccess) {
      const { pin, isSuccess } = await dispatch(startRequestPinFlow())
      if (isSuccess) {
        const instalmentId = order.instalments[0].instalmentID
        const paymentMethod = PaymentUtils.getDefaultPaymentMethodWithCurrency(
          order.currency
        )
        dispatch({
          type: 'paynow/generateFpsLink',
          payload: {
            pin,
            orderId,
            instalmentId,
            isBurn: true,
            paymentMethod,
          },
        })
      } else {
        dispatch({
          type: 'paynow/resetBurnMiles',
          payload: { orderId, miles: prevMilesBurned },
        })
      }
    }
    if (burnMiles.isError) {
      updateErrorMessage('miles burn failed')
      setPrevMilesBurned(order.milesBurned)
    }
  }, [burnMiles])

  useUpdateEffect(async () => {
    if (generateFpsLink.isSuccess) {
      dispatch({
        type: 'paynow/cacheFpsData',
        payload: generateFpsLink.data,
      })
      setTimeout(() => history.push(`/paynow/${orderId}`), 500)
    }
    if (generateFpsLink.isError) {
      updateErrorMessage('miles burn failed')
      dispatch({
        type: 'paynow/resetBurnMiles',
        payload: { orderId, miles: prevMilesBurned },
      })
    }
  }, [generateFpsLink])

  if (linkOrder.isLoading) return <LoadingScreen />

  if (linkOrder.isError)
    return (
      <Container>
        <ErrorMessageBox
          errorMessage={intl.formatMessage({ id: 'error.action.link.order' })}
        />
      </Container>
    )

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!inited) return <LoadingScreen />
  if (!order) return <LoadingScreen />
  if (order.orderID !== orderId) return <></>

  const onClickApplyBurn = () => {
    updateErrorMessage('')

    setPrevMilesBurned(order.milesBurned)

    dispatch({
      type: 'paynow/burnMiles',
      payload: { orderId, miles: Math.ceil(milesToBurn) },
    })
  }

  const onClickAdjustMiles = () => {
    history.push(`/paynow/${orderId}/burn`, {
      checkoutMiles,
    })
  }

  const onClickBack = () => {
    history.push(`/paynow/${orderId}`)
  }

  const isLoading = burnMiles.isLoading || generateFpsLink.isLoading

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title>{tt(intl, 'spend.confirmspendmiles')}</Title>
      <Spacer height="0.889rem" />
      <SubTitle>{tt(intl, 'spend.confirmamount')}</SubTitle>
      <Spacer height="1.778rem" />
      <PaynowMilesDetails
        orderAmount={order.orderAmount}
        milesBurned={milesToBurn}
        merchantID={order.merchantID}
        currency={order.currency}
      />
      <Spacer height="0.667rem" />
      <ErrorMessageLine errorMessage={errorMessage} />
      <Spacer height="0.667rem" />
      <ConfirmButton onClick={onClickApplyBurn}>
        {tt(intl, 'common.confirm')}
      </ConfirmButton>
      <Spacer height="0.889rem" />
      <ActionButtons>
        <SecondaryButton onClick={onClickBack}>
          {tt(intl, 'common.cancel')}
        </SecondaryButton>
        <SecondaryButton onClick={onClickAdjustMiles}>
          {tt(intl, 'spend.adjustmiles')}
        </SecondaryButton>
      </ActionButtons>
      <Spacer height="2.222rem" />
      <LoadingModal loading={isLoading} />
    </Container>
  )
}

export default PaynowPreview
