import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import MilesDesc from '@/components/miles/MilesDesc'
import MilesInput2 from '@/components/miles/MilesInput2'
import MilesSlider2 from '@/components/miles/MilesSlider2'
import Spacer from '@/components/Spacer'
import AmountInput2 from '@/components/ui/AmountInput2'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import { getMerchantRateCurrencyToDVMSelector } from '@/redux/lookup/lookupSelector'
import { startRequestPinFlow } from '@/redux/pin/pinActions'
import { divitMilesSelector } from '@/redux/profile/profileSelector'
import * as DivitMiles from '@/utils/DivitMiles'
import * as Instalments from '@/utils/Instalments'
import { div, minus, mul } from '@/utils/math'
import * as Order from '@/utils/Order'

import PaynowMilesDetails from './paynow/PaynowMilesDetails'

const Container = styled(PaddingContent)`
  background-color: #fafafa;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const MilesSpendContainer = styled.div`
  display: flex;
  align-items: center;
`

const MilesPlusText = styled.div`
  font-size: 1.333rem;
`

const useMilesBurn = (miles) => {
  const [milesToBurn, setMilesToBurn] = useState(miles)
  const [milesToBurnTemp, setMilesToBurnTemp] = useState(miles)

  return {
    milesToBurn,
    milesToBurnTemp,
    setMilesToBurn: (v) => {
      setMilesToBurn(Math.ceil(v))
      setMilesToBurnTemp(v)
    },
  }
}

const PaylaterBurn = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderId, instalmentId } = useParams()
  const { currentLocale } = useLocale()
  const { state } = useLocation()
  const { checkoutMiles = 0 } = state || {}

  const { retry, isInitError, isInitLoading } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  const { burnMiles } = useSelector((s) => s.paylater)
  const { fetchOrder, generateFpsLink, refreshOrder } = useSelector(
    (s) => s.payWithFps
  )
  const divitMiles = useSelector(divitMilesSelector)
  const rateCurrencyToDVM = useSelector(getMerchantRateCurrencyToDVMSelector)
  const { milesToBurn, milesToBurnTemp, setMilesToBurn } = useMilesBurn(0)
  const [errorMessage, updateErrorMessage] = useState('')
  const [prevMilesBurned, setPrevMilesBurned] = useState(0)
  const [hasBurned, setHasBurned] = useState(false)

  const { order, orderMiles } = useSelector((s) => s.order)

  // link order only if come from fast paynow click burn
  useEffect(() => {
    dispatch({
      type: 'payWithFps/fetchOrder',
      payload: { orderId },
    })
    dispatch({
      type: 'app/miscConfig',
      payload: { configType: 'payment_ui', language: currentLocale },
    })
    dispatch({
      type: 'payWithFps/setOrderID',
      payload: {
        orderID: orderId,
        instalmentID: instalmentId,
        paylater: true,
      },
    })
    return () => {
      dispatch({ type: 'payWithFps/reset' })
    }
  }, [])

  // initialize miles to burn
  useEffect(() => {
    if (order) {
      const getMilesBurned = Order.getMilesBurned(order, instalmentId)
      setMilesToBurn(getMilesBurned || checkoutMiles)

      const instalment = order.instalments.filter(
        (i) => i.instalmentID === instalmentId
      )?.[0]
      dispatch({
        type: 'payWithFps/setOrderCurrency',
        payload: {
          currency: order.totalAmount.currency,
          paymentMethod: 'fps',
        },
      })
      setHasBurned(Instalments.hasBurned(instalment))
    }
  }, [order])

  useUpdateEffect(async () => {
    if (burnMiles.isSuccess) {
      const { pin, isSuccess } = await dispatch(startRequestPinFlow())
      if (isSuccess) {
        dispatch({
          type: 'payWithFps/generateFpsLink',
          payload: {
            pin,
            orderId,
            instalmentId,
            isBurn: true,
            paymentMethod: 'fps',
          },
        })
      } else {
        dispatch({
          type: 'paylater/resetBurnMiles',
          payload: { orderId, instalmentId, miles: prevMilesBurned },
        })
      }
    }
    if (burnMiles.isError) {
      updateErrorMessage('miles burn failed')
      setPrevMilesBurned(order.milesBurned)
    }
  }, [burnMiles])

  useUpdateEffect(async () => {
    if (generateFpsLink.isSuccess) {
      dispatch({
        type: 'payWithFps/cacheFpsData',
        payload: generateFpsLink.data,
      })
      setTimeout(
        () => history.push(`/pay/fps/paylater/${orderId}/${instalmentId}`),
        500
      )
    }
    if (generateFpsLink.isError) {
      updateErrorMessage('miles burn failed')
      dispatch({
        type: 'paylater/resetBurnMiles',
        payload: { orderId, instalmentId, miles: prevMilesBurned },
      })
    }
  }, [generateFpsLink])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />
  if (!order || !rateCurrencyToDVM) return <LoadingScreen />
  if (order.orderid !== orderId) return <></>

  // match instalment
  const instalment = order.instalments.filter(
    (i) => i.instalmentID === instalmentId
  )?.[0]

  const milesReserved = DivitMiles.getTotalReservedMilesInOrder(orderMiles)

  const userMilesBalance = divitMiles.balance + milesReserved

  const totalAmount = Instalments.getOutstandingAmount(instalment, false)

  const totalAmountFloor = Math.floor(totalAmount.amount / 10) * 10

  const totalAmountReminder = totalAmount.amount - totalAmountFloor
  const orderMilesMax = div(totalAmountFloor, rateCurrencyToDVM.buy)

  const milesToBurnMax = Math.min(orderMilesMax, userMilesBalance)
  const isEnableMilesInput = userMilesBalance > 0

  const onChangeMilesAmount = (value) => {
    const v = parseInt(value, 10)
    setMilesToBurn(v)
  }

  const onClickApplyBurn = () => {
    updateErrorMessage('')

    setPrevMilesBurned(instalment.miles)

    dispatch({
      type: 'paylater/burnMiles',
      payload: { orderId, instalmentId, miles: Math.ceil(milesToBurn) },
    })
  }

  const onChangeMilesSlider = (value) => {
    setMilesToBurn(value)
  }

  const onChangeAmount = (value) => {
    const remain = minus(totalAmountFloor / 100, value)
    const miles = div(remain, rateCurrencyToDVM.buy)
    if (miles > 0) {
      setMilesToBurn(Math.ceil(miles))
    } else {
      setMilesToBurn(0)
    }
  }

  const onClickBack = () => {
    if (orderId && instalmentId)
      history.replace(`/pay/fps/paylater/${orderId}/${instalmentId}`)
    else dispatch(goBack())
  }

  const amountToSave = mul(mul(milesToBurnTemp, 100), rateCurrencyToDVM.buy)

  const orderRemainAmountMin = minus(
    totalAmountFloor,
    mul(milesToBurnMax, rateCurrencyToDVM.buy)
  )

  const orderRemainAmount = minus(totalAmountFloor, amountToSave)

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>{tt(intl, 'spend.spendmiles')}</Title>
      <Spacer height="0.889rem" />
      {isEnableMilesInput ? (
        <SubTitle>{tt(intl, 'spend.chooseamount')}</SubTitle>
      ) : (
        <SubTitle>{tt(intl, 'spend.nodivitmiles')}</SubTitle>
      )}
      {isEnableMilesInput && (
        <>
          <Spacer height="0.889rem" />
          <MilesSpendContainer>
            <AmountInput2
              currency={totalAmount.currency}
              min={orderRemainAmountMin / 100}
              max={totalAmount.amount / 100}
              value={orderRemainAmount / 100}
              onChange={onChangeAmount}
              reminder={totalAmountReminder / 100}
            />
            <Spacer width="0.556rem" />
            <MilesPlusText>+</MilesPlusText>
            <Spacer width="0.556rem" />
            <MilesInput2
              max={milesToBurnMax / 100}
              value={milesToBurn}
              onChange={onChangeMilesAmount}
            />
          </MilesSpendContainer>
          <Spacer height="0.889rem" />
          <MilesSlider2
            defaultValue={milesToBurn}
            max={Math.floor(milesToBurnMax / 100)}
            value={milesToBurn}
            onChange={onChangeMilesSlider}
          />
          <Spacer height="1.333rem" />
          <MilesDesc
            buyRate={rateCurrencyToDVM.buy}
            totalMiles={userMilesBalance}
          />
          <Spacer height="1.333rem" />
          <PaynowMilesDetails
            orderAmount={totalAmount}
            milesBurned={milesToBurn}
            merchantID=""
            currency={totalAmount.currency}
          />
        </>
      )}

      <Spacer height="1.111rem" />
      <ErrorMessageLine errorMessage={errorMessage} />
      <Spacer height="1.111rem" />
      <ActionButtons>
        <SecondaryButton onClick={onClickBack}>
          {tt(intl, 'common.back')}
        </SecondaryButton>
        {isEnableMilesInput && (
          <PrimaryButton onClick={onClickApplyBurn} disabled={hasBurned}>
            {tt(intl, 'common.apply')}
          </PrimaryButton>
        )}
      </ActionButtons>
    </Container>
  )
}

export default PaylaterBurn
