import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as MoreSvg } from '@/assets/common/arrow-more.svg'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MilesValue from '@/components/miles/MilesValue'
import TicketsValue from '@/components/miles/TicketsValue'
import Spacer from '@/components/Spacer'
import { useFeature } from '@/hooks/useFeature'
import { tt } from '@/locales/format'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  display: flex;
`

const Content = styled.div`
  width: 100%;
  max-width: 1392px;
  margin: 0 auto;
  border-bottom: 1px solid #eff0f1;
`

const MerchantInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 2rem 1.2rem 1.6rem;
  border-bottom: 1px solid #eff0f1;
  margin-bottom: 1.6rem;

  @media (max-width: 640px) {
    padding: 0.8rem 1.2rem 0.8rem;
    margin-bottom: 0.8rem;
  }
`

const MerchantInfo = styled.div``

const MerchantName = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.2rem;

  @media (max-width: 640px) {
    cursor: pointer;
  }
`

const MerchantRefMore = styled.div`
  display: ${(p) => (p.isopen ? 'none' : 'block')};
  margin-left: 0.3rem;
  margin-top: 0.3rem;

  > svg {
    transform: rotate(${(p) => (p.isopen ? '-90deg' : '90deg')});
  }
  @media (max-width: 640px) {
    display: block;
  }
`

const MerchantRef = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.4rem;
  line-height: 1.2rem;
`

const PaymentAmountRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 1.6rem;
  justify-content: space-between;

  @media (max-width: 640px) {
    padding-bottom: 0.8rem;
  }
`

const PaymentAmountText = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
`

const PaymentCurrency = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  margin-right: 0.3rem;
`

const PaymentAmount = styled.div`
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: baseline;
  line-height: 1.6rem;
`

const EarnMilesText = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
`

const EarnMilesValue = styled(MilesValue)`
  font-weight: 700;
  line-height: 1.2rem;
`

const EarnTicketsValue = styled(TicketsValue)`
  font-weight: 700;
  line-height: 1.2rem;
`

const FpsPaymentAmount = ({
  instalmentAmount,
  milesToEarn,
  merchant,
  merchantRef,
  onClose,
}) => {
  const intl = useIntl()
  const { configs } = useFeature()
  const [nextDraw, setNextDraw] = useState()
  const [showMerchantRef, setShowMerchantRef] = useState(
    window.innerWidth > 640
  )
  let ticketsToEarn = 0

  useEffect(() => {
    if (
      !configs?.luckydraws ||
      (configs?.luckdrawStarts &&
        moment(configs?.luckdrawStarts).isAfter(moment()))
    )
      return
    configs.luckydraws.every((n) => {
      if (moment(n).isAfter(moment())) {
        setNextDraw(n)
        return false
      }
      return true
    })
  }, [configs])

  const onClickShowMerchantRef = () => {
    if (window.innerWidth > 640) setShowMerchantRef(true)
    else setShowMerchantRef(!showMerchantRef)
  }

  if (configs?.luckydrawFormula) {
    // eslint-disable-next-line no-unused-vars
    const amount = instalmentAmount.amount / 100
    // eslint-disable-next-line no-eval
    ticketsToEarn = eval(`${configs?.luckydrawFormula}`)
  }

  return (
    <Container>
      <Content>
        <MerchantInfoWrapper>
          <MerchantLogo
            merchantID={merchant.merchantID}
            width="3.5rem"
            height="3.5rem"
          />
          <Spacer width="1rem" />
          <MerchantInfo>
            <MerchantName onClick={onClickShowMerchantRef}>
              {merchant.merchantName}{' '}
              <MerchantRefMore isopen={showMerchantRef}>
                <MoreSvg />
              </MerchantRefMore>
            </MerchantName>
            {showMerchantRef && (
              <MerchantRef>
                {tt(intl, 'order.merchantreference')}: {merchantRef}
              </MerchantRef>
            )}
          </MerchantInfo>
        </MerchantInfoWrapper>
        <PaymentAmountRow>
          <PaymentAmountText>{tt(intl, 'payment.pay')}</PaymentAmountText>
          <PaymentAmount>
            <PaymentCurrency>{instalmentAmount.currency}</PaymentCurrency>
            {FormattedPrice('', instalmentAmount.amount)}
          </PaymentAmount>
        </PaymentAmountRow>
        {milesToEarn > 0 && (
          <>
            <PaymentAmountRow>
              <EarnMilesText>{tt(intl, 'payment.earnmiles')}</EarnMilesText>
              <EarnMilesValue
                type="yellow"
                isSigned={false}
                miles={milesToEarn * 100}
                fontSize="0.9rem"
              />
            </PaymentAmountRow>
          </>
        )}
        {nextDraw && ticketsToEarn > 0 && (
          <>
            <PaymentAmountRow>
              <EarnMilesText>{tt(intl, 'payment.earntickets')}</EarnMilesText>
              <EarnTicketsValue
                type="yellow"
                isSigned={false}
                amount={ticketsToEarn}
                fontSize="0.9rem"
              />
            </PaymentAmountRow>
          </>
        )}
      </Content>
    </Container>
  )
}

export default FpsPaymentAmount
