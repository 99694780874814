import * as paycode from './paycode'
import * as qrcode from './qrcode'

const suggestTypeNumber = (text) => {
  const { length } = text
  if (length <= 32) return 3
  if (length <= 46) return 4
  if (length <= 60) return 5
  if (length <= 74) return 6
  if (length <= 86) return 7
  if (length <= 108) return 8
  if (length <= 130) return 9
  if (length <= 151) return 10
  if (length <= 177) return 11
  if (length <= 203) return 12
  if (length <= 241) return 13
  if (length <= 258) return 14
  if (length <= 292) return 15
  return 40
}

const createQR = (text, typeNumber, errorCorrectionLevel, mode, mb) => {
  qrcode.stringToBytes = qrcode.stringToBytesFuncs[mb]
  if (typeNumber === 0) {
    typeNumber = suggestTypeNumber(text)
  }

  const qr = qrcode(typeNumber || 4, errorCorrectionLevel || 'M')
  qr.addData(text, mode)
  qr.make()

  return qr
}

const updateQR = (canvas, logo, url, size) => {
  const text = url.replace(/^[\s\u3000]+|[\s\u3000]+$/g, '')
  const t = 0
  const e = 'M'
  const m = 'Byte'
  const mb = 'UTF-8'
  const qr = createQR(text, t, e, m, mb)
  const consumer = false

  const ctx = canvas.getContext('2d')

  canvas.width = size
  canvas.height = size
  ctx.setTransform(1, 0, 0, 1, 0, 0)
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  paycode.drawPayCode(qr, canvas, 7, logo, consumer)
}

const updateQRSvg = (domElement, url) => {
  const t = 0
  const e = 'M'
  const m = 'Byte'
  const mb = 'UTF-8'
  const qr = createQR(url, t, e, m, mb)

  domElement.innerHTML = qr.createSvgTag(2, 0)
}

const createQRImage = (url, size) => {
  const t = 0
  const e = 'M'
  const m = 'Byte'
  const mb = 'UTF-8'
  const qr = createQR(url, t, e, m, mb)

  return qr.createDataURL(size || 6)
}

export { createQR, updateQR, updateQRSvg, createQRImage }
