import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import RewardBgImg from '@/assets/miles/miles-reward-bg.png'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import MilesValue from '@/components/miles/MilesValue'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'
import PasswordValidationToolTip from '@/components/PasswordValidationToolTip'
import Spacer from '@/components/Spacer'
import MilesPrograms from '@/constants/MilesPrograms'
import { useAuth } from '@/contexts/authContext'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'
import usePageError from '@/hooks/usePageError'
import usePasswordValiation from '@/hooks/usePasswordValidation'
import useQueryString from '@/hooks/useQueryString'
import useToolTip from '@/hooks/useToolTip'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import { actions as fastSignupActions } from '@/redux/pages/fastSignupSlice'
import { profileSelector } from '@/redux/profile/profileSelector'
import DivitAPI from '@/services/api'

const Container = styled(PaddingContent)``

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
`
const Subtitle = styled.div`
  font-size: 0.7888rem;
  text-align: center;
`
const Row = styled.div`
  display: flex;
  align-items: center;
`
const RedeemMilesValue = styled(MilesValue)`
  font-weight: 700;
`
const RewardPane = styled.div`
  background: url(${RewardBgImg}) left top no-repeat;
  background-size: contain;
  padding: 1rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    min-height: 110px;
    padding: 2rem;
  }
`
const MerchantName = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-right: 0.5rem;
`
const Label = styled.div`
  font-size: 0.877rem;
  font-weight: 700;
`
const Content = styled.div`
  font-size: 0.877rem;
`
const Input = styled.input`
  display: block;
  width: 100%;
  border: 1px solid rgba(28, 28, 28, 0.2);
  border-radius: 10px;
  padding: 0.77rem 1.33rem;
  box-sizing: border-box;
  font-size: 0.77rem;

  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`
const PasswordStrengthMeterContainer = styled.div`
  width: 100%;
  margin: 0.889rem 0 1.333rem 0;
`
const LinkRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const LinkProgramIcon = styled.div`
  height: 4.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Remarks = styled.div`
  font-size: 0.667rem;
  color: #979797;
`
const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1;
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`
const MainButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  color: #1c1c1c;
  padding: 1rem 0;
  min-width: unset;
`
const CancelButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  background-color: transparent;
  color: #1c1c1c;
  padding: 1rem 0;
  border: 1px solid #dedede;
  min-width: unset;
`

const DivitMilesSetupAndClaim = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useParams()
  const { logout, isLoggedIn, error } = useAuth()
  const { setup = '' } = useQueryString()
  const { previewClaim } = useSelector((s) => s.claimDivitMiles)
  const { setupPassword } = useSelector((s) => s.fastSignup)
  const { addMembership } = useSelector((s) => s.convertDivitMiles)
  const { merchants } = useSelector((s) => s.lookup)
  const profile = useSelector(profileSelector)
  const { executeRecaptcha } = useCaptcha()

  const [selectedProgram, setSelectedProgram] = useState()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [membershipID, setMembershipID] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [merchantName, setMerchantName] = useState('')

  const { toolTipRef, showToolTip, hideToolTip } = useToolTip()
  const { passwordValidation } = usePasswordValiation(password)
  const { errorMessage, updateErrorMessage } = usePageError()

  useEffect(() => {
    updateErrorMessage('')
    if (setup && token) {
      // with both token and setup value
      sessionStorage.setItem('is_claim_divit_miles', true)

      dispatch({
        type: 'claimDivitMiles/previewClaim',
        payload: { token, accessToken: setup },
      })
    }
    if (token && !setup) {
      history.push(`/miles/claim/${token}`)
    }

    // setup selected programme
    const list = MilesPrograms.filter((p) => p.key === 'asiamiles')
    if (list && list.length > 0) setSelectedProgram(list[0])
  }, [])

  useUpdateEffect(async () => {
    if (profile?.isLoading) {
      updateErrorMessage('')
    } else {
      if (setupPassword.isSuccess) {
        await linkMembership()
      }
      if (setupPassword.isError) {
        updateErrorMessage(tt(intl, 'login.setuppassword.fail'))
      } else {
        updateErrorMessage('')
      }
    }
  }, [setupPassword, profile])

  useEffect(() => {
    if (addMembership.isSuccess) {
      setFirstName('')
      setLastName('')
      setMembershipID('')
      history.push('/profile/miles/convert')
    }
    if (addMembership.isError) {
      updateErrorMessage(addMembership?.error?.message)
    } else {
      updateErrorMessage('')
    }
  }, [addMembership])

  useEffect(() => {
    const { data } = previewClaim
    if (previewClaim.isError || previewClaim.isLoading || !data) return
    if (!isLoggedIn && data.jwtToken) {
      setupSession(data)
    }
    if (previewClaim.isSuccess && !data.jwtToken && !data.isMember) {
      history.push(`/miles/claim/${token}`)
    }
  }, [previewClaim])

  useEffect(() => {
    if (
      previewClaim.isSuccess &&
      previewClaim.data &&
      merchants &&
      merchants.length > 0
    ) {
      const mer = merchants.filter(
        (m) => m.merchantID === previewClaim.data.merchantID
      )
      setMerchantName(mer?.[0]?.merchantName)
    }
  }, [merchants, previewClaim])

  useEffect(() => {
    if (error) {
      updateErrorMessage(intl.formatMessage({ id: 'error.action.login' }))
    }
  }, [error])

  useEffect(() => {
    const { data } = previewClaim
    if (
      previewClaim.isError ||
      previewClaim.isLoading ||
      !data ||
      addMembership.isLoading
    )
      return
    if (
      isLoggedIn &&
      profile?.email === data?.ownerEmail &&
      !addMembership.isError
    ) {
      if (!sessionStorage.getItem('return_url')) {
        history.push(`/profile/miles/convert`)
      }
    }
  }, [profile, previewClaim])

  const linkMembership = async () => {
    await dispatch({
      type: 'convertDivitMiles/addMembership',
      payload: {
        firstName,
        lastName,
        membershipID,
        partner: selectedProgram.key,
      },
    })
  }

  const setupSession = async (data) => {
    await DivitAPI.patch('profiles/session', {
      jwt: data.jwtToken,
      maxAge: 3600,
    })
    dispatch(
      fastSignupActions.updateSession({
        clientIdType: 'email',
        countryCode: '',
        clientId: data?.ownerEmail,
        token: data?.jwtToken,
        otpType: 'emailtoken',
        otp: setup,
      })
    )
  }
  const onBlurPassword = () => {
    if (!password || passwordValidation.valid) {
      hideToolTip()
    } else {
      showToolTip()
    }
  }
  const onClickBack = () => {
    dispatch(goBack())
  }
  const onClickLogout = () => {
    logout()
  }
  const validateForm = () => {
    if (!firstName.trim()) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.firstname' }))
      return false
    }
    if (!lastName.trim()) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.lastname' }))
      return false
    }
    if (!passwordValidation.valid) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.password' }))
      return false
    }
    if (password !== confirmPassword) {
      updateErrorMessage(
        intl.formatMessage({ id: 'profile.password.notmatch' })
      )
      return false
    }
    if (!membershipID.trim()) {
      updateErrorMessage(
        intl.formatMessage({ id: 'error.invalid.membershipID' })
      )
      return false
    }
    return true
  }
  const onClickSetupAndLink = (e) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }
    dispatch({
      type: 'fastSignup/setupPassword',
      payload: {
        firstName,
        lastName,
        pwd: password,
      },
    })
  }
  const onClickLink = async (e) => {
    e.preventDefault()
    history.push(`/profile/miles/convert`)
  }

  const onClickLogin = async (e) => {
    e.preventDefault()
    sessionStorage.setItem('return_url', '/profile/miles/convert')
    let recapResp = ''
    try {
      recapResp = await executeRecaptcha('LOGIN')
    } catch (err) {
      console.error(err)
    }
    dispatch({
      type: 'login',
      payload: {
        email: previewClaim?.data?.ownerEmail,
        password,
        recapResp,
      },
    })
  }

  if (!token || !setup) {
    return (
      <Container>
        <ErrorMessageBox errorMessage={tt(intl, 'miles.claim.invalidtoken')} />
      </Container>
    )
  }
  const unmatch =
    isLoggedIn &&
    previewClaim?.data?.ownerEmail &&
    profile &&
    profile?.email &&
    profile?.email !== previewClaim?.data?.ownerEmail

  if (
    !previewClaim.isSuccess ||
    previewClaim.isLoading ||
    addMembership.isLoading ||
    addMembership.isSuccess
  )
    return <LoadingScreen />
  if (previewClaim.isError || unmatch) {
    return (
      <CSLayout.CSContainer>
        <CSLayout.CSContent>
          <Container>
            <Spacer height="2.222rem" />
            <Title>{tt(intl, 'miles.claim.title')}</Title>
            <Spacer height="1.333rem" />
            {unmatch && (
              <ErrorMessageBox errorMessage={tt(intl, 'miles.claim.unmatch')} />
            )}
            {!unmatch && (
              <ErrorMessageBox errorMessage={previewClaim.error.message} />
            )}
          </Container>
        </CSLayout.CSContent>
        <CSLayout.CSFooter>
          <Container>
            <ActionButtons>
              <MainButton onClick={onClickBack}>
                {intl.formatMessage({ id: 'button.back' })}
              </MainButton>
            </ActionButtons>
          </Container>
        </CSLayout.CSFooter>
      </CSLayout.CSContainer>
    )
  }

  const { data } = previewClaim
  if (!data) return <></>

  const setupOK =
    isLoggedIn && data.ownerEmail !== '' && profile?.email === data.ownerEmail

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Spacer height="2.222rem" />
          <Title>
            {data.isMember
              ? tt(intl, 'miles.claim.login')
              : tt(intl, 'miles.claim.signup')}
          </Title>
          <Spacer height="0.788rem" />
          <RewardPane>
            <Row>
              <MerchantName>{merchantName}</MerchantName>
              {tt(intl, 'miles.claim.rewards')}
            </Row>
            <Spacer height="0.5rem" />
            <RedeemMilesValue
              fontSize="2.222rem"
              miles={data.claimedAmount.amount}
              isSigned={false}
              type="outlined-yellow"
            />
          </RewardPane>
          <Spacer height="0.5rem" />
          <ErrorMessageLine errorMessage={errorMessage} />
          <Spacer height="0.5rem" />
          <Label>{tt(intl, 'common.email')}</Label>
          <Spacer height="0.5rem" />
          <Row>
            <Input type="text" id="email" value={data.ownerEmail} disabled />
          </Row>
          <Spacer height="1.5rem" />
          {data.jwtToken === '' && (
            <>
              <Row>
                <Label>{tt(intl, 'common.password')}</Label>
              </Row>
              <Spacer height="0.5rem" />
              <Input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={onBlurPassword}
                placeholder={tt(intl, 'common.password')}
              />
            </>
          )}
          {!setupOK && data.jwtToken !== '' && (
            <>
              <Row>
                <Label>{tt(intl, 'miles.claim.setupnewpwd')}</Label>
                {!passwordValidation.ignored && (
                  <PasswordValidationToolTip
                    toolTipRef={toolTipRef}
                    hasSufficientLength={passwordValidation.validLength}
                    hasNumber={passwordValidation.validNumber}
                    hasLowercase={passwordValidation.validLowercase}
                    hasUppercase={passwordValidation.validUppercase}
                    hasSymbol={passwordValidation.validSymbol}
                  />
                )}
              </Row>
              <Spacer height="0.5rem" />
              <Input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={onBlurPassword}
                placeholder={tt(intl, 'login.newpassword')}
              />
              {!!password && (
                <PasswordStrengthMeterContainer>
                  <PasswordStrengthMeter
                    score={passwordValidation.strengthScore}
                  />
                </PasswordStrengthMeterContainer>
              )}
              {!password && <Spacer height="1rem" />}
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={tt(intl, 'login.reenternewpassword')}
              />
              <Spacer height="2rem" />
              <Title>{tt(intl, 'loyalty.rewardprogramlink')}</Title>
              <Subtitle>{tt(intl, 'loyalty.filltolinkrewardprogram')}</Subtitle>
              {selectedProgram && (
                <>
                  <LinkRow>
                    <LinkProgramIcon>
                      <Icon
                        renderImage={() => (
                          <img alt="" src={selectedProgram?.companyHLogo} />
                        )}
                        height="2.55rem"
                      />
                    </LinkProgramIcon>
                  </LinkRow>
                  <Spacer height="1rem" />
                  <div style={{ display: 'flex' }}>
                    <Input
                      name="firstName"
                      value={firstName}
                      placeholder={tt(intl, 'common.firstname')}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Spacer width="0.56rem" />
                    <Input
                      name="lastName"
                      value={lastName}
                      placeholder={tt(intl, 'common.lastname')}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <Spacer height="0.444rem" />
                  <Remarks>
                    {tt(intl, 'loyalty.filltolinkrewardprogram.note')}
                  </Remarks>
                  <Spacer height="1.33rem" />
                  <Input
                    name="no"
                    value={membershipID}
                    placeholder={tt(intl, 'loyalty.membernumber.enter')}
                    onChange={(e) => setMembershipID(e.target.value)}
                  />
                </>
              )}
            </>
          )}
          {setupOK && data.jwtToken !== '' && addMembership.isError && (
            <>
              <Spacer height="0.5rem" />
              <Label>{tt(intl, 'common.welcometodivit')}</Label>
              <Spacer height="0.5rem" />
              <Content>{tt(intl, 'loyalty.linked.nextstep')}</Content>
            </>
          )}
          <Spacer height="3.33rem" />
          <ActionButtons>
            {!setupOK && data.jwtToken !== '' && (
              <>
                <CancelButton onClick={onClickLogout}>
                  {tt(intl, 'common.cancel')}
                </CancelButton>
                <MainButton
                  disabled={
                    !firstName ||
                    !lastName ||
                    !membershipID ||
                    !password ||
                    !confirmPassword
                  }
                  onClick={onClickSetupAndLink}
                >
                  {tt(intl, 'common.linknow')}
                </MainButton>
              </>
            )}
            {setupOK && data.jwtToken !== '' && addMembership.isError && (
              <MainButton onClick={onClickLink}>
                {tt(intl, 'common.linknow')}
              </MainButton>
            )}
            {data.jwtToken === '' && (
              <MainButton disabled={!password} onClick={onClickLogin}>
                {tt(intl, 'common.login')}
              </MainButton>
            )}
          </ActionButtons>
        </Container>
      </CSLayout.CSContent>
    </CSLayout.CSContainer>
  )
}

export default (props) => (
  <CaptchaProvider>
    <DivitMilesSetupAndClaim {...props} />
  </CaptchaProvider>
)
