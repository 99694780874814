import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import BottomSheet from '@/components/BottomSheet'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import LoadingModal from '@/components/LoadingModal'
import Spacer from '@/components/Spacer'
import PhoneInput from '@/components/ui/PhoneInput'
import UploadFileInputField from '@/components/UploadFileInputField'
import { tt } from '@/locales/format'
import { Input, Textarea } from '@/pages/Auth/shared/style'
import { profileSelector } from '@/redux/profile/profileSelector'
import * as PhoneUtils from '@/utils/Phone'

const Container = styled.div`
  width: 100%;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  box-sizing: border-box;
`

const Title = styled.div`
  font-size: 1.228rem;
  font-weight: 700;
  text-align: center;
`

const PaddingContent = styled.div`
  padding: 0 1rem;
`

const Form = styled.div`
  padding: 0 1.33rem;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const InputTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  margin-bottom: 0.333rem;
`

const ActionButton = styled.button`
  min-width: 200px;
  width: 100%;
  height: 2.667rem;
  font-size: 1rem;
  font-weight: 700;
  color: '#1c1c1c';
  letter-spacing: 0.8px;
  border-radius: 5rem;
  border: none;
  padding: 0 1rem;
  line-height: 1em;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ type }) => (type === 'bw' ? '#fc3' : 'transparent')};
  border: 1px solid ${({ type }) => (type === 'bw' ? 'transparent' : '#ccc')};

  &:disabled {
    cursor: not-allowed;
    color: #8e8e8e;
    background-color: #fee699;
  }
`

const CustomerServiceModal = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const profile = useSelector(profileSelector)
  const { order } = useSelector((s) => s.order)
  const { loading, error } = useSelector((s) => s.csModal)
  const { pathname } = useLocation()

  const form = useForm()
  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
  } = form

  const { isOpen } = useSelector((s) => s.csModal)

  const onReset = () => dispatch({ type: 'csmodal/reset' })

  const onClose = () => {
    reset()
    dispatch({ type: 'csmodal/closeModal' })
    setTimeout(() => onReset(), 200)
  }

  const onClickSend = handleSubmit((data) => {
    if ((data?.tel || '').length <= 4) data.tel = ''
    dispatch({
      type: 'csmodal/sendCsForm',
      payload: { ...data, path: pathname },
    })
  })

  const onModalLeave = () => {}

  useEffect(() => {
    if (!isDirty) {
      reset({
        name: profile?.firstName
          ? `${profile?.firstName} ${profile?.lastName || ''}`
          : '',
        email: profile?.email || '',
        tel: profile?.tel || '',
        orderID: order?.orderID || order?.orderid || '',
        customerID: profile?.customerID || '',
        orderType: order?.orderID ? 'paynow' : 'paylater',
      })
    }
  }, [profile, order])

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      onLeave={onModalLeave}
      style={{ div: { Background: 'unset' } }}
    >
      <Container>
        <Spacer height="1.778rem" />
        <Title>{tt(intl, 'profile.contactus')}</Title>
        <Spacer height="1rem" />
        <PaddingContent>
          <Form>
            <FormRow>
              <InputTitle>{tt(intl, 'common.contact')}*</InputTitle>
              <Input
                type="text"
                id="name"
                {...register('name', { required: true })}
                placeholder={tt(intl, 'common.contact')}
                error={errors?.name}
              />
            </FormRow>
            <FormRow>
              <InputTitle>{tt(intl, 'common.email')}*</InputTitle>
              <Input
                type="email"
                id="email"
                autoComplete="email"
                {...register('email', {
                  required: true,
                  validate: {
                    matchPattern: (v) =>
                      /^[a-zA-Z0-9_+.-]+@[a-zA-Z_+-]+?(\.[a-zA-Z_+-]+)*(\.[a-zA-Z]{2,3})+$/.test(
                        v
                      ) || tt(intl, 'common.validation.email'),
                  },
                })}
                placeholder={tt(intl, 'common.email')}
                error={errors?.email}
              />
            </FormRow>
            <FormRow>
              <InputTitle>{tt(intl, 'common.phone')}</InputTitle>
              <Controller
                name="tel"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    placeholder={tt(intl, 'common.mobilenumber')}
                    countryCode={
                      PhoneUtils.parsePhoneNumber(field.value).countryCode
                    }
                    phoneNumber={
                      PhoneUtils.parsePhoneNumber(field.value).localNumber
                    }
                    onChange={(data) => {
                      field.onChange(`${data.countryCode}${data.phoneNumber}`)
                    }}
                    error={errors?.tel}
                  />
                )}
              />
            </FormRow>
            <FormRow>
              <InputTitle>{tt(intl, 'common.content')}*</InputTitle>
              <Textarea
                rows={4}
                id="content"
                {...register('content', { required: true })}
                placeholder={tt(intl, 'common.content')}
                error={errors?.content}
              />
            </FormRow>
            <FormRow>
              <InputTitle>{tt(intl, 'common.attachment')}</InputTitle>
              <UploadFileInputField
                id="files"
                {...register('files')}
                placeholder={tt(intl, 'common.attachment')}
                accept="image/jpg,image/jpeg,image/png,.pdf"
                error={errors?.files}
                multiple
              />
            </FormRow>
          </Form>
          {error && (
            <>
              <ErrorMessageLine errorMessage={error} />
              <Spacer height="0.5rem" />
            </>
          )}
          {!error && <Spacer height="1rem" />}
          <ActionButton type="bw" onClick={onClickSend}>
            {tt(intl, 'common.send')}
          </ActionButton>
          <Spacer height="0.5rem" />
          <ActionButton type="" onClick={onClose}>
            {tt(intl, 'common.close')}
          </ActionButton>
        </PaddingContent>
        <Spacer height="1.333rem" />
      </Container>
      <LoadingModal loading={loading} />
    </BottomSheet>
  )
}

export default CustomerServiceModal
