import styled from 'styled-components'

import { Button } from '@/pages/Auth/shared/style'

import Modal from '../Modal'


export const Title = styled.div`
  font-size: 0.889rem;
  font-weight: 700;
`

export const ModalContainer = styled(Modal)`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 20;
  max-width: 600px;
  min-width: 360px;
  margin: auto;
  background: rgba(28, 28, 28, 0.5) !important;
`
export const ModalContentContainer = styled.div`
  background-color: #F5F6F7 !important;
  position: relative;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 8px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  padding: 24px;
  margin: 24px;
  /* width: 100%; */
  row-gap: 24px;
`

export const ModalBodyContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
`

export const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
  padding-top: ${(p) => (p.showMore ? '0' : '24px')};
`

export const ShowMoreTitle = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 13.714px;
`

export const C27 = styled.p`
  padding-bottom: 0 !important;
  display: none;
`

export const TermContainer = styled.div`
  height: ${(p) => (p.showMore ? 'unset' : '20rem')};
  overflow: hidden;
`

export const AgreementFlex = styled.form`
  display: flex;
  flex-direction: column;
`

export const AgreementRow = styled.div`
  display: flex;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const AgreementCheckbox = styled.div`
  font-size: 0.625rem;
`

export const AgreementMessage = styled.label`
  font-size: 0.75rem;
`

export const AgreeButtonContainer = styled.div``

export const AgreeButton = styled(Button)`
  font-size: 0.889rem;
  width: 100%;
`