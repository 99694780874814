// Note: not using Divit components
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import BackButton from '@/components/BackButton'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import Spacer from '@/components/Spacer'
import PasswordInput from '@/components/ui/PasswordInput'
import { useAuth } from '@/contexts/authContext'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'
import usePageError from '@/hooks/usePageError'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import * as User from '@/utils/user'

const Container = styled(PaddingContent)``

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Hero = styled.div``

const Title = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const SubTitle = styled.div`
  max-width: 375px;
  font-size: 0.778rem;
  text-align: center;
  margin: 0 auto;
`

const ClientID = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
  text-align: center;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.889rem;
`

const FormColumn = styled.div`
  flex: 1;
  ${({ position }) => position && `text-align: ${position}`}
`

const ContinueButton = styled(Button)`
  font-size: 0.889rem;
`

const ForgotPasswordAnchor = styled.div`
  color: #1c1c1c;
  font-size: 0.778rem;
  margin-top: 0.889rem;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
`

const DivitMilesSetupPassword = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { executeRecaptcha } = useCaptcha()
  const { authUserID } = useAuth()

  const [password, setPassword] = useState('')
  const { errorMessage, updateErrorMessage } = usePageError()

  const { loading, error } = useSelector((state) => state.auth)
  const { session } = useSelector((s) => s.fastSignup)
  const { clientIdType, countryCode, clientId } = session || {}

  useEffect(() => {
    dispatch({ type: 'loginClear' })
  }, [])

  useEffect(() => () => dispatch({ type: 'fastSignup/reset' }), [])

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (authUserID) {
      history.replace('/home')
    }
  }, [])

  useEffect(() => {
    if (error) {
      updateErrorMessage(intl.formatMessage({ id: 'error.action.login' }))
    } else {
      updateErrorMessage(null)
    }
  }, [error])

  const onClickLoginPassword = async (e) => {
    e.preventDefault()

    let recapResp = ''

    try {
      recapResp = await executeRecaptcha('LOGIN')
    } catch (err) {
      console.error(err)
    }

    dispatch({
      type: 'fastSignup/loginPassword',
      payload: {
        password,
        recapResp,
      },
    })
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onClickForgotPassword = (e) => {
    e.preventDefault()
    history.push('/forgot-password', {
      clientIdType,
      countryCode,
      clientId,
    })
  }

  const displayId = User.getProfileId(countryCode, clientId)

  return (
    <Container>
      <Hero>
        <Spacer height="1.778rem" />
        <Row>
          <BackButton onClick={onClickBack} />
          <Title>{tt(intl, 'login.enterpassword')}</Title>
        </Row>
        <Spacer height="0.889rem" />
        <SubTitle>{tt(intl, 'login.enterpasswordtologin')}</SubTitle>
        <Spacer height="0.889rem" />
        <ClientID>{displayId}</ClientID>
      </Hero>
      <Spacer height="0.665rem" />
      <ErrorMessageLine errorMessage={errorMessage} />
      <Spacer height="0.665rem" />
      <Form>
        <FormRow>
          <FormColumn>
            <PasswordInput value={password} onChange={(v) => setPassword(v)} />
            <ForgotPasswordAnchor onClick={onClickForgotPassword}>
              {tt(intl, 'login.forgotpassword')}
            </ForgotPasswordAnchor>
          </FormColumn>
        </FormRow>
        <Spacer height="1.111rem" />
        <FormRow>
          <FormColumn>
            <ContinueButton
              type="stretch"
              disabled={!password}
              onClick={onClickLoginPassword}
            >
              {tt(intl, 'common.continue')}
            </ContinueButton>
          </FormColumn>
        </FormRow>
      </Form>
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default (props) => (
  <CaptchaProvider>
    <DivitMilesSetupPassword {...props} />
  </CaptchaProvider>
)
