import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as AppleLoginIconSvg } from '@/assets/socials/signin/apple-colored.svg'
import { ReactComponent as FacebookLoginIconSvg } from '@/assets/socials/signin/facebook.svg'
import { ReactComponent as GoogleLoginIconSvg } from '@/assets/socials/signin/google.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import LoginApple from '@/components/LoginApple'
import LoginFacebook from '@/components/LoginFacebook'
import LoginGoogle from '@/components/LoginGoogle'
import Spacer from '@/components/Spacer'
import BankLogo from '@/components/ui/BankLogo'
import Title from '@/components/ui/Title'
// import MilesPrograms from '@/constants/MilesPrograms'
import usePage from '@/hooks/usePage'
import usePageError from '@/hooks/usePageError'
import useUnmount from '@/hooks/useUnmount'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { startSetupPinFlow } from '@/redux/pin/pinActions'
import {
  // divitMilesSelector,
  eDDAAccountSelector,
  eDDAActivatedSelector,
  profileSelector,
  socialMediaLinkageSelector,
} from '@/redux/profile/profileSelector'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'
import * as BankUtils from '@/utils/Bank'

// import * as DivitMiles from '@/utils/DivitMiles'
import { ProfileEditPasswordModal } from './ProfileEditPassword'
import { Column, ItemRow, Row, Section, SectionTitle } from './Shared'

const Container = styled(PaddingContent)``

const ItemTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemTextButton = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
  cursor: pointer;
`

// const MilesProgramTitle = styled.div`
//   font-size: 0.722rem;
//   margin-left: 0.889rem;
// `

// const MilesProgramIcon = styled(Icon)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   border: 1px solid rgba(28, 28, 28, 0.2);
// `

const EmailText = styled.div`
  font-size: 0.889rem;
`

const ItemChangeButton = ({ exist, onClick }) => {
  const intl = useIntl()
  return (
    <ItemTextButton onClick={onClick}>
      {exist ? tt(intl, 'common.change') : tt(intl, 'common.add')}
    </ItemTextButton>
  )
}

const ItemLinkButton = ({ linked, onClick }) => {
  const intl = useIntl()
  return (
    <ItemTextButton onClick={onClick}>
      {linked ? tt(intl, 'common.remove') : tt(intl, 'common.link')}
    </ItemTextButton>
  )
}

// const ProfileMilesLinkageSection = () => {
//   const intl = useIntl()
//   const dispatch = useDispatch()

//   const divitMiles = useSelector(divitMilesSelector)

//   useUnmount(() => dispatch({ type: 'updateProfile/reset' }))

//   const onClickRemove = (program) => async () => {
//     const { isOk } = await dispatch(
//       promptConfirmModal({
//         title: tt(intl, 'common.heywait'),
//         content: tt(intl, 'loyalty.remove.ask', { program: program.name }),
//         okText: tt(intl, 'loyalty.remove.confirm'),
//       })
//     )
//     if (isOk) {
//       dispatch({
//         type: 'updateProfile/unlinkMilesProgram',
//         payload: {
//           partner: program.key,
//         },
//       })
//     }
//   }

//   if (divitMiles.memberships.length === 0) {
//     return null
//   }

//   return (
//     <Section>
//       <SectionTitle>{tt(intl, 'loyalty.linked')}</SectionTitle>
//       {MilesPrograms.map((program) => {
//         const membership = DivitMiles.findMembership(
//           divitMiles.memberships,
//           program.key
//         )
//         if (!membership) return null
//         return (
//           <ItemRow key={program.key}>
//             <Row>
//               <MilesProgramIcon
//                 renderImage={() => <img alt="" src={program.image} />}
//                 width="1.778rem"
//                 height="1.778rem"
//               />
//               <MilesProgramTitle>{program.name}</MilesProgramTitle>
//             </Row>
//             <ItemTextButton onClick={onClickRemove(program)}>
//               {tt(intl, 'common.remove')}
//             </ItemTextButton>
//           </ItemRow>
//         )
//       })}
//     </Section>
//   )
// }

const ProfileSocialLinkageSection = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { errorMessage, updateErrorMessage } = usePageError()

  const socialMediaLinkage = useSelector(socialMediaLinkageSelector)
  const googleLinkage = socialMediaLinkage.google
  const facebookLinkage = socialMediaLinkage.facebook
  const appleLinkage = socialMediaLinkage.apple

  const { linkSocialProvider, unlinkSocialProvider } = useSelector(
    (s) => s.updateProfile
  )

  useUnmount(() => dispatch({ type: 'updateProfile/reset' }))

  useEffect(() => {
    if (linkSocialProvider.isError) {
      updateErrorMessage(intl.formatMessage({ id: 'error.action.link.social' }))
    } else {
      updateErrorMessage('')
    }
  }, [linkSocialProvider])

  useEffect(() => {
    if (unlinkSocialProvider.isError) {
      updateErrorMessage(
        intl.formatMessage({ id: 'error.action.unlink.social' })
      )
    } else {
      updateErrorMessage('')
    }
  }, [unlinkSocialProvider])

  const onLinkAppleSuccess = (resp) => {
    const { accessToken } = resp
    dispatch({
      type: 'updateProfile/linkSocialProvider',
      payload: {
        provider: 'apple',
        accessToken,
        metadata: resp,
      },
    })
  }

  const onLinkAppleFailure = (err) => {
    console.log({ err })
    // setErrorMessage(err.error || JSON.stringify(err))
  }

  const onLinkGoogleSuccess = (resp) => {
    const { accessToken } = resp
    dispatch({
      type: 'updateProfile/linkSocialProvider',
      payload: {
        provider: 'google',
        accessToken,
      },
    })
  }

  const onLinkGoogleFailure = (err) => {
    console.log({ err })
    // setErrorMessage(err.error || JSON.stringify(err))
  }

  const onLinkFacebookSuccess = (resp) => {
    const { accessToken } = resp
    dispatch({
      type: 'updateProfile/linkSocialProvider',
      payload: {
        provider: 'facebook',
        accessToken,
      },
    })
  }

  const onLinkFacebookFailure = (err) => {
    console.log({ err })
    // setErrorMessage(err.error || JSON.stringify(err))
  }

  const onClickLink = (onClick) => async (item) => {
    updateErrorMessage('')
    if (item === 'facebook') {
      if (socialMediaLinkage.facebook) {
        const { isOk } = await dispatch(
          promptConfirmModal({
            title: tt(intl, 'common.heywait'),
            content: tt(intl, 'social.fb.unlink.ask'),
            okText: tt(intl, 'social.unlink.confirm'),
          })
        )
        if (isOk) {
          dispatch({
            type: 'updateProfile/unlinkSocialProvider',
            payload: {
              provider: 'facebook',
            },
          })
        }
      } else {
        onClick()
      }
    } else if (item === 'google') {
      if (socialMediaLinkage.google) {
        const { isOk } = await dispatch(
          promptConfirmModal({
            title: tt(intl, 'common.heywait'),
            content: tt(intl, 'social.google.unlink.ask'),
            okText: tt(intl, 'social.unlink.confirm'),
          })
        )
        if (isOk) {
          dispatch({
            type: 'updateProfile/unlinkSocialProvider',
            payload: {
              provider: 'google',
            },
          })
        }
      } else {
        onClick()
      }
    } else if (item === 'apple') {
      if (socialMediaLinkage.apple) {
        const { isOk } = await dispatch(
          promptConfirmModal({
            title: tt(intl, 'common.heywait'),
            content: tt(intl, 'social.apple.unlink.ask'),
            okText: tt(intl, 'social.unlink.confirm'),
          })
        )
        if (isOk) {
          dispatch({
            type: 'updateProfile/unlinkSocialProvider',
            payload: {
              provider: 'apple',
            },
          })
        }
      } else {
        onClick()
      }
    }
  }

  return (
    <Section>
      <SectionTitle>{tt(intl, 'profile.socials.linked')}</SectionTitle>
      <ErrorMessageLine errorMessage={errorMessage} />
      <ItemRow>
        <Row>
          <Icon
            renderImage={() => <AppleLoginIconSvg />}
            width="1.789rem"
            height="1.778rem"
          />
          <Spacer width="0.889rem" />
          <Column>
            <ItemTitle>apple</ItemTitle>
          </Column>
        </Row>
        <LoginApple
          onSuccess={onLinkAppleSuccess}
          onFailure={onLinkAppleFailure}
          render={({ onClick }) => (
            <ItemLinkButton
              linked={!!appleLinkage}
              onClick={() => onClickLink(onClick)('apple')}
            />
          )}
        />
      </ItemRow>
      <ItemRow>
        <Row>
          <Spacer width="1.789rem" />
          <Spacer width="0.889rem" />
          {appleLinkage && <EmailText>{appleLinkage.email}</EmailText>}
        </Row>
      </ItemRow>
      <ItemRow>
        <Row>
          <Icon
            renderImage={() => <GoogleLoginIconSvg />}
            width="1.789rem"
            height="1.778rem"
          />
          <Spacer width="0.889rem" />
          <Column>
            <ItemTitle>google</ItemTitle>
          </Column>
        </Row>
        <LoginGoogle
          onSuccess={onLinkGoogleSuccess}
          onFailure={onLinkGoogleFailure}
          render={({ onClick }) => (
            <ItemLinkButton
              linked={!!googleLinkage}
              onClick={() => onClickLink(onClick)('google')}
            />
          )}
        />
      </ItemRow>
      <ItemRow>
        <Row>
          <Spacer width="1.789rem" />
          <Spacer width="0.889rem" />
          {googleLinkage && <EmailText>{googleLinkage.email}</EmailText>}
        </Row>
      </ItemRow>
      <ItemRow>
        <Row>
          <Icon
            renderImage={() => <FacebookLoginIconSvg />}
            width="1.789rem"
            height="1.778rem"
          />
          <Spacer width="0.889rem" />
          <Column>
            <ItemTitle>facebook</ItemTitle>
          </Column>
        </Row>
        <LoginFacebook
          onSuccess={onLinkFacebookSuccess}
          onFailure={onLinkFacebookFailure}
          render={({ onClick }) => (
            <ItemLinkButton
              linked={!!facebookLinkage}
              onClick={() => onClickLink(onClick)('facebook')}
            />
          )}
        />
      </ItemRow>
      <ItemRow>
        <Row>
          <Spacer width="1.789rem" />
          <Spacer width="0.889rem" />
          {facebookLinkage && <EmailText>{facebookLinkage.email}</EmailText>}
        </Row>
      </ItemRow>
    </Section>
  )
}

const ProfileAccountSection = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [isShowEditPasswordModal, setIsShowEditPasswordModal] = useState(false)

  const profile = useSelector(profileSelector)

  const onClickPasswordChange = () => {
    setIsShowEditPasswordModal(true)
  }

  const onClickPinChange = async () => {
    let result = { isSuccess: false }
    if (!profile.hasUserPin) {
      result = await dispatch(startSetupPinFlow())
    } else {
      result = await dispatch(
        startSetupPinFlow({ isReset: true, isForceCheckIdentity: true })
      )
    }
    if (result.isSuccess) {
      dispatch(
        ToastActions.addToast({ message: tt(intl, 'profile.pin.updated') })
      )
    }
  }

  return (
    <Section>
      <SectionTitle>{tt(intl, 'profile.accountsetting')}</SectionTitle>
      <ItemRow>
        <ItemTitle>{tt(intl, 'common.password')}</ItemTitle>
        <ItemChangeButton
          exist={!profile.systemGenPwd}
          onClick={onClickPasswordChange}
        />
      </ItemRow>
      <ItemRow>
        <ItemTitle>{tt(intl, 'common.pin')}</ItemTitle>
        <ItemChangeButton
          exist={profile.hasUserPin}
          onClick={onClickPinChange}
        />
      </ItemRow>
      <ProfileEditPasswordModal
        isOpen={isShowEditPasswordModal}
        onClose={() => setIsShowEditPasswordModal(false)}
      />
    </Section>
  )
}

const ProfileBankAccountSection = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isUserEDDAActivated = useSelector(eDDAActivatedSelector)
  const eDDAAccount = useSelector(eDDAAccountSelector)
  const { removeEDDAAccount } = useSelector((s) => s.updateProfile)

  useUnmount(() => dispatch({ type: 'updateProfile/reset' }))

  useEffect(async () => {
    if (removeEDDAAccount.isSuccess) {
      dispatch(
        ToastActions.addToast({
          message: tt(intl, 'profile.linkedbankaccount.remove.success'),
        })
      )
    }
    if (removeEDDAAccount.isError) {
      await dispatch(
        promptConfirmModal({
          content: tt(intl, 'profile.linkedbankaccount.remove.failure'),
          closable: false,
        })
      )
    }
  }, [removeEDDAAccount])

  const onClickRemoveBankAccount = async () => {
    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'profile.linkedbankaccount.remove.confirm'),
        okText: tt(intl, 'profile.linkedbankaccount.remove.now'),
      })
    )
    if (isOk) {
      dispatch({ type: 'updateProfile/removeEDDAAccount' })
    }
  }

  if (!isUserEDDAActivated || !eDDAAccount) return null

  return (
    <Section>
      <SectionTitle>{tt(intl, 'profile.linkedbankaccount')}</SectionTitle>
      <ItemRow>
        <Row>
          <BankLogo
            width="1.789rem"
            height="1.778rem"
            bankCode={eDDAAccount.bankCode}
          />
          <Spacer width="0.889rem" />
          <Column>
            <ItemTitle style={{ maxWidth: 240 }}>
              {BankUtils.getBankString(eDDAAccount.bankCode)}
            </ItemTitle>
          </Column>
        </Row>
        <ItemLinkButton linked onClick={onClickRemoveBankAccount} />
      </ItemRow>
      <ItemRow>
        <Row>
          <Spacer width="1.789rem" />
          <Spacer width="0.889rem" />
          <ItemTitle>{eDDAAccount.accountNum}</ItemTitle>
        </Row>
      </ItemRow>
    </Section>
  )
}

const ProfileSettings = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileMain' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Row>
        <Title onClickBack={onClickBack}>
          {intl.formatMessage({ id: 'profile.settings' })}
        </Title>
      </Row>
      <Spacer height="1.778rem" />
      <div>
        <ProfileAccountSection />
        {/* <ProfileMilesLinkageSection /> */}
        <ProfileSocialLinkageSection />
        <ProfileBankAccountSection />
      </div>
      <Spacer height="4rem" />
    </Container>
  )
}

export default ProfileSettings
