import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import { ActionButtons, PrimaryButton } from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { tt } from '@/locales/format'
import { formatFullDateTime } from '@/utils/date'
import * as OrderUtils from '@/utils/OrderPaynow'

const FullWidthPaddingContent = styled(PaddingContent).attrs({
  isFullWidth: true,
})`
  max-width: 600px;
  margin: 0 auto;
`

const SubTitle = styled.div`
  max-width: 420px;
  margin: 0 auto;
  font-size: 0.778rem;
  line-height: 1.5;
  text-align: center;
`

const PaynowFpsUnavailableScreen = ({ order, periodEnd }) => {
  const intl = useIntl()
  const history = useHistory()

  const onClickBackMerchant = () => {
    if (OrderUtils.isRedirectFailureNotAvailable(order)) {
      history.push('/')
    } else {
      window.location.href = order.webhookFailure
    }
  }

  let content = tt(intl, 'common.fpsmaintenance.tryagainlater')

  if (periodEnd && periodEnd > 0) {
    const endAt = formatFullDateTime(moment.unix(periodEnd))
    content = tt(intl, 'common.fpsmaintenance.tryagainafter', { endAt })
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <FullWidthPaddingContent>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'common.systemmaintenance')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{content}</SubTitle>
          <Spacer height="1.333rem" />
        </FullWidthPaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <FullWidthPaddingContent>
          <ActionButtons>
            <PrimaryButton onClick={onClickBackMerchant}>
              {tt(intl, 'common.done')}
            </PrimaryButton>
          </ActionButtons>
        </FullWidthPaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default PaynowFpsUnavailableScreen
