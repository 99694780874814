import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'

import ProfileStatus from './constants/ProfileStatus'
import { useAuth } from './contexts/authContext'
import { getOrderIdParam, isByPassRedirection, IsOrder } from './utils/Route'

const PrivateRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location
  const { isLoggedIn } = useAuth()

  const { profile } = useSelector((state) => state.profile)
  const byPassRedirection = isByPassRedirection(pathname)

  // problem 1: if user logout in private route, token becomes null, then saved wrong return_url
  // solve 1: check did mount only
  // problem 2: if only set in did mount, cannot solve expired token problem  (not null token)
  // solve 2: app init knows exactly user logged or not
  // problem 3: app init token expired, wont go here, cannot capture return_url
  // solve 3: it handled in saga after refresh token

  useEffect(() => {
    const isCheckoutStart = IsOrder(pathname)
    if (isCheckoutStart) {
      sessionStorage.setItem('is_checkout', true)
      const orderId = getOrderIdParam(pathname)
      sessionStorage.setItem('checkout_order', orderId)
    }

    if (!isLoggedIn) {
      dispatch({ type: 'tokenExpiredRedirect' })
    }
  }, [])

  // TODO: if user go to private page without permission
  if (!isLoggedIn) return <></>

  if (profile?.status === ProfileStatus.SUSPEND) {
    return <Redirect to="/error" />
  }

  // TOFIX: token expired redirect trigger this
  // cause mulitple failed api call
  const returnUrl = sessionStorage.getItem('return_url')
  if (byPassRedirection) {
    sessionStorage.removeItem('return_url')
  } else if (returnUrl) {
    sessionStorage.removeItem('return_url')
    return <Redirect to={returnUrl} />
  }

  return <Route {...rest} render={() => children} />
}

export default PrivateRoute
