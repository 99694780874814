import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import ActivityEmptySvg from '@/assets/activity/empty.svg'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'

const Container = styled.div``

const Group = styled.div``

const GroupMonthTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const GroupTitle = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 0.778rem;
  font-weight: 600;
`

const EmptyContainer = styled.div`
  width: 10.555rem;
  text-align: center;
  margin: 0 auto;
`

const EmptyTitle = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
  text-align: center;
  margin: 0 auto;
`

const EmptyText = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
  line-height: 1.3em;
  text-align: center;
  margin: 0 auto;
`

const List = styled.div``

const Item = styled.div`
  font-size: 0.778rem;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid #f0f0f0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom: 1px solid #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &:last-child {
    padding-bottom: 1rem;
  }
`

const Line = styled.div`
  display: block;
  width: 90%;
  height: 1px;
  margin-top: 1rem;
  background-color: ${(p) => p.backgroundColor || '#f0f0f0'};
  margin-left: auto;
  margin-right: auto;
`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const OrderTitle = styled.div`
  color: #000;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;

  & span:last-child {
    margin-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const PaynowOrderHistory = ({ payments, onClickItem }) => {
  const intl = useIntl()

  if (payments.length === 0) {
    return (
      <EmptyContainer>
        <Spacer height="2.222rem" />
        <EmptyTitle>{tt(intl, 'activity.empty')}</EmptyTitle>
        <Spacer height="1.333rem" />
        <img
          alt=""
          src={ActivityEmptySvg}
          style={{
            width: '8.778rem',
            height: '5.611rem',
          }}
        />
        <Spacer height="1.333rem" />
        <EmptyText>{tt(intl, 'slogan.divitpay')}</EmptyText>
        <Spacer height="2.222rem" />
      </EmptyContainer>
    )
  }

  const groups = payments.reduce((obj, txn) => {
    const date = moment(txn.createdAt).format('YYYY-MM-DD')
    obj[date] = obj[date] || []
    obj[date].push(txn)
    return obj
  }, {})

  const formatGroup = (group) => {
    const today = moment().format('YYYY-MM-DD')
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')
    switch (group) {
      case today:
        return tt(intl, 'schedule.today')

      case yesterday:
        return tt(intl, 'landing.historyList.yesterday')

      default:
        return moment(group).format('DD MMMM,YYYY')
    }
  }

  return (
    <Container>
      <List>
        {Object.keys(groups).map((group) => (
          <Group key={group}>
            <GroupTitle>
              <GroupMonthTitle>{formatGroup(group)}</GroupMonthTitle>
            </GroupTitle>
            <Spacer height="0.778rem" />
            <List>
              {groups[group].map((payment, index) => {
                let pTitle = (
                  <>
                    <MerchantName merchantID={payment.merchantID} />
                  </>
                )
                let pCurrency = payment.refAmount?.currency
                let pAmount = payment.refAmount?.amount
                let pMiles = 0
                let pMerchantID = payment.merchantID
                let pGiftCardBrandCode = ''
                if (payment.transactionType === 'miles-conversion-in') {
                  pTitle = tt(intl, 'miles.scheme.miles.conversion.in', {
                    program: payment.metadata.partnerName,
                  })
                  pCurrency = ''
                  pAmount = 0
                  pMiles = parseInt(payment.amount?.amount / 100, 10)
                  pMerchantID = payment.metadata?.partnerCode
                }
                if (payment.transactionType === 'miles-conversion-out') {
                  pTitle = tt(intl, 'miles.scheme.miles.conversion.out', {
                    program: payment.metadata.partnerName,
                  })
                  pCurrency = ''
                  pAmount = 0
                  pMiles = parseInt(payment.refAmount?.amount / 100, 10)
                  pMerchantID = payment.metadata?.partnerCode
                }
                if (payment.transactionType === 'spend') {
                  pTitle = tt(intl, 'giftcard.spent.on', {
                    giftcard: payment.metadata.title,
                  })
                  pCurrency = ''
                  pAmount = 0
                  pMiles = parseInt(payment.amount?.amount / 100, 10)
                  pGiftCardBrandCode = payment.metadata?.brand
                }
                return (
                  <Item key={payment.ID} onClick={() => onClickItem(payment)}>
                    <ItemContent>
                      <MerchantLogo
                        merchantID={pMerchantID}
                        giftCardBrandCode={pGiftCardBrandCode}
                        width="2.722rem"
                        height="2.722rem"
                      />
                      <Spacer width="0.66rem" />
                      <Left>
                        <OrderTitle>{pTitle}</OrderTitle>
                      </Left>
                      <Spacer width="1.111rem" />
                      <Right>
                        <MilesAndAmountValue
                          fontSize="0.667rem"
                          currency={pCurrency}
                          amount={pAmount}
                          miles={pMiles}
                          leftPlusSpace="2px"
                          rightPlusSpace="0px"
                        />
                      </Right>
                    </ItemContent>
                    {groups[group].length > 1 &&
                      index !== groups[group].length - 1 && <Line />}
                  </Item>
                )
              })}
            </List>
          </Group>
        ))}
      </List>
    </Container>
  )
}

export default PaynowOrderHistory
