import 'react-responsive-carousel/lib/styles/carousel.min.css'

import moment from 'moment'
import QueryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Marquee from 'react-fast-marquee'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'

// import CathayImg from '@/assets/airline/cathay.png'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
// import asiamiles from '@/constants/asiamiles'
import urls from '@/constants/urls'
import { useAuth } from '@/contexts/authContext'
import { useLocale } from '@/contexts/localeContext'
import { useFeature } from '@/hooks/useFeature'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'

const Container = styled.div`
  min-height: 100vh;
`

const HeroContainer = styled(PaddingContent)`
  text-align: left;
  background-color: #ffcc33;
`

const LuckydrawHeroContainer = styled.div`
  width: 100%;
  text-align: left;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: linear-gradient(rgba(51, 46, 31, 1) 0%, rgba(28, 28, 28, 1) 100%);

  > div {
    color: white;
  }
`

const PreTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;

  & * {
    font-size: 1.887rem;
    font-weight: 700;
  }

  & b {
    color: white;
  }
`

const MilesTitle = styled.div`
  display: flex;
  flex-direction: column;

  & * {
    font-size: 2.6rem;
    font-weight: 700;
  }

  & b {
    color: white;
  }
`

// const TrustContainer = styled.div`
//   padding: 1.2rem;
//   background-color: white;
//   text-align: center;
// `
// const TrustHeader = styled.div`
//   font-size: 1.22rem;
//   font-weight: 700;
// `

// const CathayImgBw = styled.img`
//   width: 180px;
//   filter: grayscale(1);
// `

const SubTitle = styled.div`
  font-size: 1.22rem;
  font-weight: 300;
`

const LuckydrawContainer = styled.div`
  width: 100%;
  background-image: url(${`${process.env.REACT_APP_STATIC_URL}/banners/luckydraw_KV_landing.png`});
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: 23rem;
  margin-top: -2rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    height: 30rem;
  }
`

const Signup1Container = styled.div`
  width: 100%;
  background-image: url(${`${process.env.REACT_APP_STATIC_URL}/banners/landing_hero_fps.png`});
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: 23rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    height: 28rem;
  }
`

// const SignUp2Container = styled.div`
//   width: 100%;
//   height: 22rem;
//   background-image: url(${`${process.env.REACT_APP_STATIC_URL}/banners/landing_hero_convertmiles.png`});
//   background-size: contain;
//   background-position: bottom center;
//   background-repeat: no-repeat;

//   @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
//     height: 28rem;
//   }
// `

const HeroSignUpButton = styled(Button)`
  min-width: unset;
  width: 8.44rem;
  font-size: 0.89rem;
  color: #fc3;
  background-color: #1c1c1c;
`

const SignUpButton = styled(Button)`
  min-width: unset;
  width: 8.44rem;
  font-size: 0.89rem;
  color: #1c1c1c;
  background-color: #ffcc33;
`

const FeatureSection = styled(PaddingContent)`
  background-color: white;
`

const FeatureTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`

// const FeatureSubTitle = styled.div`
//   font-size: 1.2rem;
//   font-weight: 700;
//   margin-bottom: 0.889rem;
// `

const FeatureItem = styled.div`
  font-size: 0.85rem;
  padding-left: 0.667rem;
  border-left: 2px solid #ffcc33;
  margin-bottom: 0.889rem;
`

// const FeatureContent = styled.div`
//   font-size: 0.85rem;
//   margin-bottom: 0.889rem;
// `

const MerchantFooterContent = styled.p`
  font-size: 0.988rem;
  font-weight: 500;
`

const MerchantsContainer = styled(PaddingContent)`
  background-color: white;
`

const MerchantsTitle = styled.div`
  font-size: 1.222rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
`

const Merchants = styled.div``
const MerchantImg = styled.img`
  border-radius: 16px;
  width: 100%;
`

const MerchantTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
`
const MerchantDescr = styled.div`
  font-size: 0.778rem;
  font-weight: 400;
`

const Slideshow = styled(Carousel)`
  .carousel .slide.selected {
    background-color: #fc3;
  }
  .carousel .control-dots .dot {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
    opacity: 1;
    margin: 0 0.222rem;
    border-radius: 8px;
    transition: all 0.25s ease-in;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background-color: #fff;
  }
`

const Slideshow1 = styled(Carousel)`
  .carousel .slide {
    background-color: #fff;
  }
  .carousel .control-dots {
    top: 13.5rem;

    @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
      top: 18rem;
    }
  }
  .carousel .control-dots .dot {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: none;
    opacity: 1;
    margin: 0 0.222rem;
    border-radius: 8px;
    transition: all 0.25s ease-in;
  }
  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background-color: #fc3;
  }
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    height: 361px;
  }
  .carousel .control-arrow.control-prev {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  .carousel .control-arrow.control-next {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`

const Logo = styled.div`
  padding: 0.5rem 1rem;
`

// const FeatureAnchor = styled.span``

const LuckydrawFeatureSection = () => {
  const intl = useIntl()
  return (
    <LuckydrawHeroContainer>
      <PaddingContent>
        <Spacer height="2rem" />
        <PreTitle>{tt(intl, 'landing.luckydraw.pretitle')}</PreTitle>
        <Spacer height="0.5rem" />
        <MilesTitle>
          <div>{tt(intl, 'landing.luckydraw.title')}</div>
        </MilesTitle>
        <Spacer height="1rem" />
        <SubTitle>{tt(intl, 'landing.luckydraw.subtitle1')} + </SubTitle>
        <SubTitle>{tt(intl, 'landing.luckydraw.subtitle2')}</SubTitle>
      </PaddingContent>
      <LuckydrawContainer />
    </LuckydrawHeroContainer>
  )
}

const EarnFeatureSection = () => {
  const intl = useIntl()
  const history = useHistory()

  const onClickSignUp = () => {
    history.push('/signup')
  }
  return (
    <>
      <HeroContainer>
        <Spacer height="1.8rem" />
        <Title>
          <div>{tt(intl, 'landing.earn.title')}</div>
        </Title>
        <Spacer height="0.889rem" />
        <SubTitle>{tt(intl, 'landing.earn.subtitle')}</SubTitle>
        <Spacer height="1.5rem" />
        <Signup1Container>
          <HeroSignUpButton onClick={onClickSignUp}>
            {tt(intl, 'common.signup')}
          </HeroSignUpButton>
        </Signup1Container>
        <Spacer height="2rem" />
      </HeroContainer>
    </>
  )
}

// const ConvertFeatureSection = () => {
//   const intl = useIntl()
//   const history = useHistory()

//   const onClickSignUp = () => {
//     history.push('/signup')
//   }
//   return (
//     <HeroContainer>
//       <Spacer height="1.8rem" />
//       <Title>
//         <div>{tt(intl, 'landing.convert.title')}</div>
//       </Title>
//       <Spacer height="0.889rem" />
//       <SubTitle>{tt(intl, 'landing.convert.subtitle')}</SubTitle>
//       <Spacer height="0.889rem" />
//       <SignUp2Container>
//         <HeroSignUpButton onClick={onClickSignUp}>
//           {tt(intl, 'common.signup')}
//         </HeroSignUpButton>
//       </SignUp2Container>
//       <Spacer height="2rem" />
//     </HeroContainer>
//   )
// }

const FeaturesSection = ({ landing, onChange }) => {
  const { configs } = useFeature()
  const [nextDraw, setNextDraw] = useState()

  useEffect(() => {
    if (
      !configs?.luckydraws ||
      (configs?.luckdrawStarts &&
        moment(configs?.luckdrawStarts).isAfter(moment()))
    )
      return

    configs.luckydraws.every((n) => {
      if (moment(n).isAfter(moment())) {
        setNextDraw(n)
        return false
      }
      return true
    })
  }, [configs])

  return (
    <>
      {!nextDraw && (
        <div style={{ height: '100%', backgroundColor: '#fc3' }}>
          {/* {landing === 'convert' && <ConvertFeatureSection />} */}
          <EarnFeatureSection />
        </div>
      )}
      {nextDraw && (
        <Slideshow
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          emulateTouch
          swipeable
          preventMovementUntilSwipeScrollTolerance
          onChange={(idx) => onChange?.(idx)}
          onClickItem={(idx) => onChange?.(idx)}
        >
          <div style={{ height: '100%', backgroundColor: '#1c1c1c' }}>
            <LuckydrawFeatureSection />
          </div>
          <div style={{ height: '100%', backgroundColor: '#fc3' }}>
            {/* {landing === 'convert' && <ConvertFeatureSection />} */}
            <EarnFeatureSection />
          </div>
        </Slideshow>
      )}
    </>
  )
}

const EarnMerchantSection = () => {
  const intl = useIntl()
  const { configs } = useFeature()
  const { currentLocale } = useLocale()

  const openUrl = (url) => {
    if (url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }

  return (
    <>
      <MerchantsContainer>
        <Spacer height="1rem" />
        <MerchantsTitle>
          {tt(intl, 'home.luckydraw.merchants.title')}
        </MerchantsTitle>
        <Spacer height="1rem" />
        <Slideshow1
          showArrows={!isMobile}
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          swipeable
          preventMovementUntilSwipeScrollTolerance
          onClickItem={(idx) => openUrl(configs?.shops?.[idx].merchantUrl)}
        >
          {configs?.shops &&
            configs.shops.map((shop) => (
              <Merchants key={shop?.merchantName[currentLocale]}>
                <MerchantImg src={shop.merchantImg} />
                <Spacer height="2rem" />
                <MerchantTitle>
                  {shop?.merchantName[currentLocale]}
                </MerchantTitle>
                <MerchantDescr>
                  {shop?.merchantDesc[currentLocale]}
                </MerchantDescr>
              </Merchants>
            ))}
        </Slideshow1>
        <Spacer height="1rem" />
      </MerchantsContainer>
    </>
  )
}

const FeatureEarnContent = () => {
  const intl = useIntl()
  const history = useHistory()
  const { configs } = useFeature()
  const { currentLocale } = useLocale()

  const onClickSignUp = () => {
    history.push('/signup')
  }

  return (
    <>
      {configs?.shops && (
        <Marquee style={{ backgroundColor: 'white' }}>
          {configs?.shops.map((shop) => (
            <Logo key={shop.merchantName[currentLocale]}>
              <img
                src={shop.merchantLogo}
                alt={shop.merchantName[currentLocale]}
                height="40px"
              />
            </Logo>
          ))}
        </Marquee>
      )}
      <FeatureSection style={{ backgroundColor: '#f7f6f4' }}>
        <Spacer height="1rem" />
        <FeatureTitle>{tt(intl, 'landing.earnandspend')}</FeatureTitle>
        <Spacer height="1rem" />
        <FeatureItem>{tt(intl, 'landing.earn.item1')}</FeatureItem>
        <FeatureItem>{tt(intl, 'landing.earn.item2')}</FeatureItem>
        <FeatureItem>{tt(intl, 'landing.earn.item3')}</FeatureItem>
        <Spacer height="0.5rem" />
        {/* <FeatureSubTitle>{tt(intl, 'landing.earn.convert')}</FeatureSubTitle>
        <FeatureContent>
          {tt(intl, 'landing.earn.convert.content')}
        </FeatureContent> 
        <Spacer height="0.5rem" /> */}
        <SignUpButton onClick={onClickSignUp}>
          {tt(intl, 'common.signup')}
        </SignUpButton>
        <Spacer height="1rem" />
      </FeatureSection>
      <EarnMerchantSection />
    </>
  )
}

// const FeatureConvertContent = () => {
//   const intl = useIntl()
//   const history = useHistory()

//   const onClickSignUp = () => {
//     history.push('/signup')
//   }

//   return (
//     <>
//       <TrustContainer>
//         <TrustHeader>{tt(intl, 'landing.convert.trustedby')}</TrustHeader>
//         <Spacer height="0.1rem" />
//         <CathayImgBw src={CathayImg} alt="" />
//       </TrustContainer>
//       <FeatureSection style={{ backgroundColor: '#f7f6f4' }}>
//         <Spacer height="1rem" />
//         <FeatureTitle>{tt(intl, 'landing.convert.content.title')}</FeatureTitle>
//         <Spacer height="1rem" />
//         <FeatureItem>{tt(intl, 'landing.convert.content.item1')}</FeatureItem>
//         <FeatureItem>
//           {tt(intl, 'landing.convert.content.item2', {
//             am: (
//               <FeatureAnchor
//                 as="a"
//                 target="_blank"
//                 href={asiamiles.en.convertUrl}
//               >
//                 {tt(intl, 'landing.linkdivittoam.am')}
//               </FeatureAnchor>
//             ),
//           })}
//         </FeatureItem>
//         <FeatureItem>{tt(intl, 'landing.convert.content.item3')}</FeatureItem>
//         <Spacer height="0.5rem" />
//         <FeatureSubTitle>
//           {tt(intl, 'landing.convert.content.subtitle')}
//         </FeatureSubTitle>
//         <FeatureContent>{tt(intl, 'landing.convert.content')}</FeatureContent>
//         <Spacer height="0.5rem" />
//         <SignUpButton onClick={onClickSignUp}>
//           {tt(intl, 'common.signup')}
//         </SignUpButton>
//         <Spacer height="1.8rem" />
//       </FeatureSection>
//     </>
//   )
// }

const MerchantFooter = () => {
  const intl = useIntl()

  const onClickContactus = (e) => {
    e.preventDefault()
    const subject = 'Enquiry'
    const email = urls?.en?.email
    window.open(`mailto:${email}?subject=${subject}`)
  }

  return (
    <HeroContainer>
      <Spacer height="1rem" />
      <MerchantFooterContent>
        {tt(intl, 'landing.footer.merchant')}
      </MerchantFooterContent>
      <HeroSignUpButton onClick={onClickContactus}>
        {tt(intl, 'menu.item_contactus')}
      </HeroSignUpButton>
      <Spacer height="1.5rem" />
    </HeroContainer>
  )
}

const { landing = '' } = QueryString.parse(document.location.search)

const Landing = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { authUserID } = useAuth()

  useEffect(() => {
    dispatch({ type: 'loginClear' })
  }, [])

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (authUserID) {
      history.replace('/home')
    }
  }, [])

  if (authUserID) return <Redirect to="/home" />

  const isEarnType = true // landing !== 'convert'
  // const isConvertType = landing === 'convert'

  return (
    <Container>
      <FeaturesSection landing={landing} />
      {isEarnType && <FeatureEarnContent />}
      {/* isConvertType && <FeatureConvertContent /> */}
      <MerchantFooter />
    </Container>
  )
}

export default Landing
