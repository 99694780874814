import React from 'react'
import styled from 'styled-components'

import CardPlaceholderImage from '@/assets/divit-black.svg'

const Card = styled.div`
  width: 100%;
  height: 0;
  padding-top: 62.7%;
  border-radius: 10px;
  background-image: url(${(p) => p.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 4px 16px rgba(28, 28, 28, 0.2),
    0px 2px 4px rgba(28, 28, 28, 0.2);
  overflow: hidden;
`

const GiftCardImage = ({ style, className, image }) => (
  <Card
    style={style}
    className={className}
    image={image || CardPlaceholderImage}
  />
)

export default GiftCardImage
