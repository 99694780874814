import React from 'react'
import styled from 'styled-components'

// import { ReactComponent as GuideIconSvg } from '@/assets/miles/guide-icon-yellow.svg'
// import Icon from '@/components/Icon'
// import MilesBalanceGuideModal from '@/components/miles/MilesBalanceGuideModal'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import { t } from '@/locales/format'

const Container = styled.div`
  padding: 1.111rem;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-size: 0.889rem;
  font-weight: 400;
`

// const SubTitle = styled.div`
//   font-size: 0.778rem;
//   font-weight: 400;
// `

const TotalMiles = styled.div`
  cursor: pointer;
`

// const DivitMiles = styled.div``

const DivitMilesValue = styled(MilesValue).attrs({ type: 'yellow' })`
  & div {
    font-weight: 700;
    letter-spacing: 0.03em;
  }
`

// const Separator = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: #dedede;
// `

const ProfileMiles = ({
  totalMilesBalance,
  divitMilesBalance,
  convertedMilesBalance,
}) => (
  // const [isShowMilesBalanceGuide, setIsShowMilesBalanceGuide] = useState(false)

  <Container>
    <TotalMiles>
      <Row>
        <Title>{t('miles.totalmilesbalance')}</Title>
        {/* <Spacer width="8px" />
        <Icon renderImage={() => <GuideIconSvg />} width="1rem" height="1rem" /> */}
      </Row>
      <Spacer height="0.778rem" />
      <DivitMilesValue
        miles={totalMilesBalance}
        isSigned={false}
        fontSize="1.778rem"
      />
    </TotalMiles>
    {/* <Spacer height="1.222rem" /> */}
    {/* <Separator />
      <Spacer height="0.998rem" />
      <Row>
        <DivitMiles>
          <SubTitle>{t('miles.divitmiles')}</SubTitle>
          <Spacer height="0.222rem" />
          <DivitMilesValue
            miles={divitMilesBalance}
            isSigned={false}
            fontSize="0.889rem"
          />
        </DivitMiles>
        <Spacer width="1.333rem" />
        <DivitMiles>
          <SubTitle>{t('miles.convertedmiles')}</SubTitle>
          <Spacer height="0.222rem" />
          <DivitMilesValue
            miles={convertedMilesBalance}
            isSigned={false}
            fontSize="0.889rem"
          />
        </DivitMiles>
      </Row> 
      <MilesBalanceGuideModal
        isOpen={isShowMilesBalanceGuide}
        onClose={() => setIsShowMilesBalanceGuide(false)}
      /> */}
  </Container>
)

export default ProfileMiles
