import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import BottomSheet from '@/components/BottomSheet'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import CenteredContent from '@/components/layout/CenteredContent'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'
import PasswordValidation from '@/components/PasswordValidation'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import usePasswordValiation from '@/hooks/usePasswordValidation'
import { tt } from '@/locales/format'
import { Input } from '@/pages/Auth/shared/style'

import {
  ActionButtons,
  CancelButton,
  MainButton,
  SubTitle,
  Title,
} from './Shared'

const StepInput = ({ onDone, onClose }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { passwordValidation } = usePasswordValiation(password)

  const { changePassword } = useSelector((state) => state.updateProfile)

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  useEffect(() => {
    if (changePassword.isSuccess) {
      onDone()
    }
    if (changePassword.isError) {
      setErrorMessage(
        intl.formatMessage({ id: 'error.action.change.password' })
      )
    }
  })

  const validateForm = () => {
    if (!oldPassword.trim() || !password.trim() || !confirmPassword.trim()) {
      setErrorMessage(intl.formatMessage({ id: 'error.required' }))
      return false
    }
    if (!passwordValidation.valid) {
      setErrorMessage(intl.formatMessage({ id: 'error.invalid.password' }))
      return false
    }
    if (password !== confirmPassword) {
      setErrorMessage(
        intl.formatMessage({ id: 'error.invalid.confirm.password' })
      )
      return false
    }
    return true
  }

  // change password
  const doChangePassword = () => {
    setErrorMessage('')

    if (validateForm()) {
      dispatch({
        type: 'updateProfile/changePassword',
        payload: {
          oldPwd: oldPassword,
          newPwd: password,
        },
      })
    }
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'profile.changepassword')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'profile.enterpassword')}</SubTitle>
          <Spacer height="0.667rem" />
          <ErrorMessageLine errorMessage={errorMessage} />
          <Spacer height="0.667rem" />
          <Input
            type="password"
            id="oldpassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder={tt(intl, 'login.currentpassword')}
          />
          <Spacer height="0.889rem" />
          <Input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={tt(intl, 'login.newpassword')}
          />
          {!!password && (
            <>
              <Spacer height="0.889rem" />
              <PasswordStrengthMeter score={passwordValidation.strengthScore} />
            </>
          )}
          {!!password && !passwordValidation.ignored && (
            <PasswordValidation
              hasSufficientLength={passwordValidation.validLength}
              hasNumber={passwordValidation.validNumber}
              hasLowercase={passwordValidation.validLowercase}
              hasUppercase={passwordValidation.validUppercase}
              hasSymbol={passwordValidation.validSymbol}
            />
          )}
          <Spacer height="0.889rem" />
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={tt(intl, 'login.reenternewpassword')}
          />
          <Spacer height="2.222rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <CancelButton onClick={onClose}>
              {tt(intl, 'common.cancel')}
            </CancelButton>
            <MainButton onClick={doChangePassword}>
              {tt(intl, 'common.edit')}
            </MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

const StepSuccess = ({ onClose }) => {
  const intl = useIntl()

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <CenteredContent>
          <PaddingContent>
            <Spacer height="2.222rem" />
            <Icon
              renderImage={() => <SuccessOutlinedSvg />}
              width="1.736rem"
              height="1.736rem"
            />
            <Spacer height="0.889rem" />
            <Title>{tt(intl, 'profile.changepassword.success')}</Title>
            <Spacer height="0.889rem" />
            <SubTitle>{tt(intl, 'profile.password.changed')}</SubTitle>
            <Spacer height="2.222rem" />
          </PaddingContent>
        </CenteredContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <MainButton onClick={onClose}>{tt(intl, 'common.ok')}</MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

const ProfileEditPassword = ({ onClose }) => {
  // input -> success
  const [step, setStep] = useState('input')

  const onInputDone = () => {
    setStep('success')
  }

  const onClickClose = () => {
    onClose?.()
  }

  if (step === 'input') {
    return (
      <StepInput
        onDone={(data) => {
          onInputDone()
        }}
        onClose={onClickClose}
      />
    )
  }

  if (step === 'success') {
    return <StepSuccess onClose={onClose} />
  }

  return null
}

export const ProfileEditPasswordModal = ({ isOpen, onClose }) => (
  <BottomSheet top open={isOpen} onDismiss={onClose}>
    <ProfileEditPassword onClose={onClose} />
  </BottomSheet>
)

export const ProfileEditMobilePage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfile' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  return <ProfileEditPassword onClose={() => history.back()} />
}

export default ProfileEditPassword
