import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import OrderReference from '@/components/OrderReference'
import { MERCHANT_ICONS } from '@/constants/merchants'
import { AirlineLogoByParnterId } from '@/utils/Airline'
import * as Order from '@/utils/Order'

import PurchaseItems from './PurchaseItems'
import Spacer from './Spacer'
import Steps from './Steps'

export const Mode = {
  Full: 'full',
  Simple: 'simple',
}

const Container = styled.div``

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.875rem;
`

const BookingReference = styled.div`
  display: inline-block;
  font-size: 0.778rem;

  > span {
    font-weight: 700;
  }
`

const MerchantLogo = styled.div`
  width: 4rem;
  height: auto;

  & > svg {
    width: 100%;
    height: 100%;
  }
`

const Italic = styled.span`
  font-style: italic;
`

export const PurchasePromotionHeader = styled.div`
  text-align: center;
`

export const FPSDivitLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
`

export const PromotionWording = styled.div``

export const PromotionPrice = styled.div`
  font-weight: 700;
  line-height: 13.714px;
`

const Booking = ({ order, showSteps, showLogo }) => {
  const intl = useIntl()

  if (!order) return <></>

  const { partnerid } = order
  const orderType = Order.getOrderItemType(order)
  const partnerRef = Order.getPartnerRef(order)
  const hasOrderRequestedRefund = !!Order.hasRequestedRefund(order)
  const hasOrderCompletedRefund = !!Order.hasCompleteRefund(order)
  const isOrderProcessing = !!Order.isProcessing(order)
  const isOrderCancelled =
    !!Order.isCancelled(order) &&
    !hasOrderRequestedRefund &&
    !hasOrderCompletedRefund
  const isOrderExpired = !!Order.isExpired(order)
  const isOrderFailed = !!Order.isFailed(order)

  const hasAnyOrderItem = Order.hasAnyOrderItem(order)

  return (
    <Container>
      {/* <Italic>
        {(isOrderProcessing || isOrderFailed) &&
          ` - ${intl.formatMessage({ id: 'booking.inprocessing' })}`}
      </Italic> */}
      <Italic>
        {isOrderCancelled &&
          ` - ${intl.formatMessage({ id: 'booking.cancelled' })}`}
      </Italic>
      <Italic>
        {isOrderExpired &&
          ` - ${intl.formatMessage({ id: 'booking.expired' })}`}
      </Italic>
      <Italic>
        {hasOrderRequestedRefund &&
          ` - ${intl.formatMessage({ id: 'booking.refund.pending' })}`}
      </Italic>
      <Italic>
        {hasOrderCompletedRefund &&
          ` - ${intl.formatMessage({ id: 'booking.refund' })}`}
      </Italic>
      {/* {!showSteps && showLogo && (
      <MerchantLogo>{AirlineLogoByParnterId(partnerid)}</MerchantLogo>
        )} */}
      {/* {showSteps && <Steps order={order} />} */}
      {showSteps && showLogo && orderType === 'retail' && (
        <>
          <MerchantLogo>{AirlineLogoByParnterId(partnerid)}</MerchantLogo>
          <Spacer height="0.66rem" />
        </>
      )}
      <PurchaseItems items={order.orderItems} />
    </Container>
  )
}

export default Booking
