import { useEffect, useRef } from 'react'

function useInterval(callback, delay, deps = []) {
  const timer = useRef()
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    timer.current = setInterval(tick, delay)
    return () => clearInterval(timer.current)
  }, [delay, ...deps])

  return () => clearInterval(timer.current)
}

export default useInterval
