import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { HR } from '@/components/Divit'
import { AirlineNameByPartnerId } from '@/utils/Airline'
import * as DivitMiles from '@/utils/DivitMiles'
import * as Order from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

import MilesEarnedRow from './miles/MilesEarnedRow'

const Container = styled.div`
  background-color: white;
`

const InnerContainer = styled.div`
  padding: 0.68rem 0;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  font-size: 0.75rem;
  min-height: 1.62rem;
  font-weight: ${({ bold }) => bold && 700};
`

const RowLeft = styled.div`
  flex: 1;
  text-align: left;
`

const RowRight = styled.div`
  flex: 1;
  text-align: right;
`
const RowHR = styled(HR)`
  width: auto;
  margin: 0 1rem;
`

const OrderSummary = ({ order, orderMiles }) => {
  const intl = useIntl()

  const isRequestRefund = Order.hasRequestedRefund(order)
  const totalOrderAmount = Order.getOrderAmount(order)
  const updatedOrderAmount = Order.getUpdatedOrderAmount(order)
  const totalActualPaidAmount = Order.getTotalActualPaidAmount(order)
  const totalServiceFeeAmount = Order.getTotalServiceFee(order)
  const totalOutstandingAmount = Order.getTotalOutstandingAmount(order)
  const totalLateFee = Order.getTotalLateFee(order)
  const totalRefundAmount = Order.getTotalRefundAmount(order)
  const totalActualRefundAmount = Order.getTotalActualRefundAmount(order)
  const totalMilesEarned = DivitMiles.getTotalMilesEarned(orderMiles)

  return (
    <Container>
      <HR />
      <InnerContainer>
        <Row>
          <RowLeft>
            {intl.formatMessage({ id: 'receipt.total.purchase' })}
          </RowLeft>
          <RowRight>
            {FormattedPrice(totalOrderAmount.currency, totalOrderAmount.amount)}
          </RowRight>
        </Row>
        {totalLateFee.amount > 0 && (
          <Row>
            <RowLeft>{intl.formatMessage({ id: 'receipt.latefee' })}</RowLeft>
            <RowRight>
              {FormattedPrice(totalLateFee.currency, totalLateFee.amount)}
            </RowRight>
          </Row>
        )}
        {totalRefundAmount.amount > 0 && (
          <Row>
            <RowLeft>
              {`${AirlineNameByPartnerId(
                intl,
                order.partnerid
              )} ${intl.formatMessage({
                id: isRequestRefund
                  ? 'receipt.credit.pending'
                  : 'receipt.credit',
              })}`}
            </RowLeft>
            <RowRight>
              {FormattedPrice(
                totalRefundAmount.currency,
                -totalRefundAmount.amount
              )}
            </RowRight>
          </Row>
        )}
        <RowHR />
        <Row>
          <RowLeft>
            {intl.formatMessage({ id: 'receipt.updated.order' })}
          </RowLeft>
          <RowRight>
            {FormattedPrice(
              updatedOrderAmount.currency,
              updatedOrderAmount.amount
            )}
          </RowRight>
        </Row>
        <Row>
          <RowLeft>{intl.formatMessage({ id: 'receipt.paid' })}</RowLeft>
          <RowRight>
            {FormattedPrice(
              totalActualPaidAmount.currency,
              totalActualPaidAmount.amount - totalServiceFeeAmount.amount
            )}
          </RowRight>
        </Row>
        {totalActualRefundAmount.amount > 0 && (
          <Row>
            <RowLeft>
              {intl.formatMessage({
                id: isRequestRefund
                  ? 'receipt.refund.pending'
                  : 'receipt.refund',
              })}
            </RowLeft>
            <RowRight>
              {FormattedPrice(
                totalActualRefundAmount.currency,
                -totalActualRefundAmount.amount
              )}
            </RowRight>
          </Row>
        )}
        <RowHR />
        <Row bold>
          <RowLeft>{intl.formatMessage({ id: 'receipt.total.owing' })}</RowLeft>
          <RowRight>
            {FormattedPrice(
              totalOutstandingAmount.currency,
              totalOutstandingAmount.amount
            )}
          </RowRight>
        </Row>
        {totalMilesEarned > 0 && (
          <Row>
            <MilesEarnedRow miles={totalMilesEarned} />
          </Row>
        )}
      </InnerContainer>
      <HR />
    </Container>
  )
}

export default OrderSummary
