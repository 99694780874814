import React from 'react'
import styled from 'styled-components'

import { ReactComponent as GuideFalseSvg } from '@/assets/miles/guide-false.svg'
import { ReactComponent as GuideTrueSvg } from '@/assets/miles/guide-true.svg'
import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import Spacer from '@/components/Spacer'
import { t } from '@/locales/format'

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  padding: 0 1.11rem;
  box-sizing: border-box;
  background-color: white;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0.88rem;
  right: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Content = styled.div`
  box-sizing: border-box;
  max-width: 423px;
  margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.111rem;
  text-align: center;
`

const SubTitle = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  font-size: 0.778rem;
  text-align: center;
`

const BalanceGuideContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
  padding: 1.333rem 1rem;
`

const BalanceGuideItem = styled.div`
  display: flex;
`

const BalanceNumber = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
  margin-right: 0.889rem;
  width: 1rem;
`

const BalanceContent = styled.div`
  flex: 1;
`

const BalanceName = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
`

const BalanceDesc = styled.div`
  font-size: 0.667rem;
  margin-top: 8px;
`

const WaysContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
  padding: 1.333rem 1rem;
`

const WayRow = styled.div`
  display: flex;
  padding: 0.889rem 0;
  border-bottom: 1px solid #f0f0f0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
`

const WayItem = styled.div`
  flex: 1;
  font-weight: ${({ isHeader }) => (isHeader ? 600 : 500)};
  font-size: ${({ isHeader }) => (isHeader ? '0.889rem' : '0.778rem')};
  text-align: ${({ isHeader }) => (isHeader ? 'left' : 'center')};
`

const FooterCloseButton = styled.div`
  text-align: center;
  cursor: pointer;

  > span {
    font-weight: 700;
    font-size: 0.77rem;
  }
`

const GuideTrue = () => (
  <Icon
    renderImage={() => <GuideTrueSvg />}
    width="1.333rem"
    height="1.333rem"
  />
)

const GuideFalse = () => (
  <Icon
    renderImage={() => <GuideFalseSvg />}
    width="1.333rem"
    height="1.333rem"
  />
)

const FpsGuideModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} isLock>
    <Container>
      <CloseButton onClick={onClose}>
        <Icon
          renderImage={() => <CloseSvg />}
          width="2.66rem"
          height="2.66rem"
        />
      </CloseButton>
      <Content>
        <Spacer height="3.6rem" />
        <Title>{t('miles.guide.remarks')}</Title>
        <Spacer height="1.778rem" />
        <SubTitle>{t('miles.guide.totalmiles')}</SubTitle>
        <Spacer height="0.889rem" />
        <BalanceGuideContainer>
          <BalanceGuideItem>
            <BalanceNumber>01</BalanceNumber>
            <BalanceContent>
              <BalanceName>{t('miles.divitmiles')}</BalanceName>
              <BalanceDesc>{t('miles.guide.earnfrompay')}</BalanceDesc>
            </BalanceContent>
          </BalanceGuideItem>
          <Spacer height="1rem" />
          <BalanceGuideItem>
            <BalanceNumber>02</BalanceNumber>
            <BalanceContent>
              <BalanceName>{t('miles.convertedmiles')}</BalanceName>
              <BalanceDesc>
                {t('miles.guide.convertedfrompartners')}
              </BalanceDesc>
            </BalanceContent>
          </BalanceGuideItem>
        </BalanceGuideContainer>
        <Spacer height="1.778rem" />
        <SubTitle>{t('miles.guide.waysofuse')}</SubTitle>
        <Spacer height="0.889rem" />
        <WaysContainer>
          <WayRow>
            <WayItem />
            <WayItem>{t('miles.divitmiles')}</WayItem>
            <WayItem>{t('miles.convertedmiles')}</WayItem>
          </WayRow>
          <WayRow>
            <WayItem isHeader>{t('miles.spend')}</WayItem>
            <WayItem>
              <GuideTrue />
            </WayItem>
            <WayItem>
              <GuideTrue />
            </WayItem>
          </WayRow>
          <WayRow>
            <WayItem isHeader>{t('miles.convert')}</WayItem>
            <WayItem>
              <GuideTrue />
            </WayItem>
            <WayItem>
              <GuideFalse />
            </WayItem>
          </WayRow>
          <WayRow>
            <WayItem isHeader>{t('miles.donate')}</WayItem>
            <WayItem>
              <GuideTrue />
            </WayItem>
            <WayItem>
              <GuideTrue />
            </WayItem>
          </WayRow>
        </WaysContainer>
        <Spacer height="2.22rem" />
        <FooterCloseButton onClick={onClose}>
          <Icon
            renderImage={() => <CloseSvg />}
            width="2.66rem"
            height="2.66rem"
          />
          <span>{t('common.close')}</span>
        </FooterCloseButton>
        <Spacer height="2.22rem" />
      </Content>
    </Container>
  </Modal>
)

export default FpsGuideModal
