import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const paynowVerifySlice = createSlice({
  name: 'paynowVerify',
  initialState: {
    createVerifyPayment: AsyncState.create(),
    fetchOrder: AsyncState.create(),
    generateFpsLink: AsyncState.create(),
    refreshOrder: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.createVerifyPayment = AsyncState.reset()
      state.fetchOrder = AsyncState.reset()
      state.generateFpsLink = AsyncState.reset()
      state.refreshOrder = AsyncState.reset()
    },
    createVerifyPaymentStart: (state) => {
      state.createVerifyPayment = AsyncState.start()
    },
    createVerifyPaymentSuccess: (state, action) => {
      state.createVerifyPayment = AsyncState.success(action.payload)
    },
    createVerifyPaymentFailed: (state, action) => {
      state.createVerifyPayment = AsyncState.failed(action.payload)
    },
    fetchOrderStart: (state) => {
      state.fetchOrder = AsyncState.start()
    },
    fetchOrderSuccess: (state, action) => {
      state.fetchOrder = AsyncState.success(action.payload)
    },
    fetchOrderFailed: (state, action) => {
      state.fetchOrder = AsyncState.failed(action.payload)
    },
    generateFpsLinkStart: (state) => {
      state.generateFpsLink = AsyncState.start()
    },
    generateFpsLinkSuccess: (state, action) => {
      state.generateFpsLink = AsyncState.success(action.payload)
    },
    generateFpsLinkFailed: (state, action) => {
      state.generateFpsLink = AsyncState.failed(action.payload)
    },
    refreshOrderStart: (state) => {
      state.refreshOrder = AsyncState.start()
    },
    refreshOrderSuccess: (state, action) => {
      state.refreshOrder = AsyncState.success(action.payload)
    },
    refreshOrderFailed: (state, action) => {
      state.refreshOrder = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = paynowVerifySlice

export default paynowVerifySlice.reducer
