import React, { useEffect } from 'react'
import ReactModal from 'react-modal'

const ReactModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'none',
    zIndex: 3,
  },
  content: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    border: '0',
    background: 'none',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '0',
    borderRadius: 0,
  },
}

const Modal = ({
  style,
  isOpen,
  isLock,
  closable,
  openAnimationSelector,
  openAnimationClassName,
  ...rest
}) => {
  useEffect(() => {
    if (isLock) {
      if (isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen, isLock])

  const s = {
    overlay: {
      ...ReactModalStyle.overlay,
      ...style?.overlay,
    },
    content: {
      ...ReactModalStyle.content,
      ...style?.content,
    },
  }

  const onOpenAnimation = () => {
    // react modal animation not work in ios safari
    // https://github.com/reactjs/react-modal/issues/846
    setTimeout(() => {
      const overlay = document.querySelector('.ReactModal__Overlay')
      if (!overlay) return
      overlay.classList.add('ReactModal__Overlay--on-open')

      if (openAnimationSelector && openAnimationClassName) {
        const other = document.querySelector(openAnimationSelector)
        if (!other) return
        other.classList.add(openAnimationClassName)
      }
    }, 0)
  }

  return (
    <ReactModal
      {...rest}
      contentLabel="modal"
      isOpen={isOpen}
      onAfterOpen={onOpenAnimation}
      style={s}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      closeTimeoutMS={200}
    />
  )
}

Modal.defaultProps = {
  style: {},
  isOpen: false,
  isLock: true,
  closable: true,
}

export default Modal
