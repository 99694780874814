import * as qs from 'query-string'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as xssfilters from 'xss-filters'

import ErrorScreen from '@/components/error/ErrorScreen'
import LoadingScreen from '@/components/LoadingScreen'
import { tt } from '@/locales/format'
import { profileSelector } from '@/redux/profile/profileSelector'
import { redirect } from '@/utils/Route'

const SSO = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isDelayed = true } = location.state || {}

  const query = qs.parse(document.location.search)
  const { redirectUrl, scope, deviceID, ...extraQueries } = query

  const profile = useSelector(profileSelector)
  const { generateOAuthToken } = useSelector((s) => s.sso)

  const filter = (val) => {
    const x = xssfilters.uriQueryInUnQuotedAttr(val)
    return x.replaceAll('&#61;', '=')
  }

  const doGenerateOAuthToken = () => {
    dispatch({
      type: 'sso/generateOAuthToken',
      payload: {
        redirectUrl: filter(redirectUrl),
        scope: [scope],
        deviceID: deviceID || '',
      },
    })
  }

  useEffect(() => {
    setTimeout(() => doGenerateOAuthToken(), isDelayed ? 1000 : 0)
  }, [])

  useEffect(() => {
    if (generateOAuthToken.isSuccess) {
      const { data } = generateOAuthToken
      const params = {
        success: 1,
        customerID: profile.customerID,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        scope: data.scope,
        expiresIn: data.expiresIn,
        ...extraQueries,
      }
      redirect(data.redirectUrl, params)
    }
  }, [generateOAuthToken])

  const onClickErrorConfirm = () => {
    const { error } = generateOAuthToken
    const params = {
      success: 0,
      code: error.code,
      message: error.message,
      ...extraQueries,
    }
    redirect('/', params)
  }

  if (generateOAuthToken.isError) {
    const { error } = generateOAuthToken

    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={error?.message || tt(intl, 'common.somethingwentwrong')}
        onOk={onClickErrorConfirm}
        okText={tt(intl, 'common.back')}
        error={error}
        onRetry={doGenerateOAuthToken}
      />
    )
  }

  return <LoadingScreen />
}

export default SSO
