import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { useAuth } from '@/contexts/authContext'
import useQueryString from '@/hooks/useQueryString'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import DivitAPI from '@/services/api'
import { getDefaultPaymentMethodWithCurrency } from '@/utils/Payment'
import { toUrl } from '@/utils/Route'

// fast checkout for paynow:
// login, set token in http client
// redirect to fps page

const PaynowFast = () => {
  const { authUserID, role } = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderId } = useParams()
  const {
    token = '',
    currency = '',
    checkoutMethod = '',
    checkoutMiles = 0,
    edda,
    mode,
  } = useQueryString()
  const { linkOrder } = useSelector((s) => s.paynow)
  const { profile } = useSelector((s) => s.profile)
  const paymentMethod = getDefaultPaymentMethodWithCurrency(currency)
  const payUrl = toUrl(`/pay/${paymentMethod}/paynow/${orderId}`, {
    edda,
    mode,
  })

  const goFastCheckout = async () => {
    // fast paynow api token
    if (!authUserID || role !== 'enduser') {
      await DivitAPI.patch('profiles/session', { jwt: token, maxAge: 3600 })
      history.replace(payUrl)
    } else {
      if (mode === 'POS') {
        // POS logic, make sure no customer login
        dispatch({ type: 'logout' })
        history.goBack()
      }
      dispatch({ type: 'paynow/linkOrder', payload: { orderId } })
      dispatch({ type: 'order/setIsFromFastPaynow', payload: false })
      dispatch({ type: 'fetchProfile' })
    }
  }

  const goCheckoutMiles = () => {
    setTimeout(() => {
      history.replace(`/paynow/${orderId}/preview`)
    }, 200)
  }

  const goExpressCheckout = () => {
    setTimeout(() => {
      history.replace(`/paynow/${orderId}/express`)
    }, 200)
  }

  useUpdateEffect(async () => {
    if (linkOrder.isSuccess && profile.customerID) {
      dispatch({ type: 'fetchEDDAAccounts' })
      // maybe transfered from fast paynow
      sessionStorage.setItem('transfered_order', orderId)
      setTimeout(() => {
        history.replace(payUrl)
      }, 200)
    }
  }, [linkOrder, profile])

  useEffect(async () => {
    if (checkoutMethod === 'express') {
      goExpressCheckout()
    } else if (checkoutMethod === 'spend' && Number(checkoutMiles) > 0) {
      sessionStorage.setItem('order_checkout_miles', Number(checkoutMiles))
      goCheckoutMiles()
    } else {
      goFastCheckout()
    }
  }, [])

  return <LoadingScreen />
}

export default PaynowFast
