import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import AddressInput from '@/components/ui/AddressInput'
import { tt } from '@/locales/format'
import { Button, Input } from '@/pages/Auth/shared/style'
import { profileSelector } from '@/redux/profile/profileSelector'
import { joinAddress } from '@/utils/Address'

import { Title } from '../../pages/profile/Shared'
import BottomSheet from '../BottomSheet'
import Spacer from '../Spacer'
import { useAddressSearch } from '../ui/AddressSearch'
import SelectInput2 from '../ui/SelectInput2'

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const CSContent = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-bottom: 4.444rem;
`

const Content = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.111rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 calc(100% / 12);
  }

  ${(p) =>
    p.isFullWidth &&
    css`
      @media (min-width: 768px) {
        max-width: var(--full-width-mode-max-width);
        padding: 0;
        margin: 0 auto;
      }
    `}
`

const ContinueButton = styled(Button)`
  font-size: 0.89rem;
`

const SelectAddress = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const ProfileAddress = ({ onClose }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const addressOptions = (env) => {
    switch (env) {
      case 'dev':
        return [
          {
            label: tt(intl, 'profile.region.hongkong'),
            value: 'HK',
          },
          {
            label: tt(intl, 'profile.region.thailand'),
            value: 'TH',
          },
        ]

      default:
        return [
          {
            label: tt(intl, 'profile.region.hongkong'),
            value: 'HK',
          },
        ]
    }
  }

  const [address, setAddress] = useState(null)

  const {
    watch,
    handleSubmit,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm()

  const watchAddress = watch('searchAddress')

  const buildingFocusRef = useRef()
  const { searchText, searchAddress, cancelSearchAddress, onAddressSearched } =
    useAddressSearch()

  const profile = useSelector(profileSelector)
  const { updateProfile } = useSelector((s) => s.updateProfile)

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  useEffect(() => {
    if (profile) {
      const billingAddress = profile?.address?.filter(
        (add) => add.addressType === 'billing'
      )[0]
      setValue('address1', billingAddress?.address1 || '')
      setValue('address2', billingAddress?.address2 || '')
      setValue('district', billingAddress?.district || '')

      setValue(
        'country',
        billingAddress?.country ||
          addressOptions(process.env.REACT_APP_ENV)[0].value
      )

      setValue('province', billingAddress?.province || '')
      setValue('postalCode', billingAddress?.postalCode || '')
    }
  }, [profile])

  useEffect(() => {
    if (watch('country') !== 'TH') {
      setValue('province', '')
      setValue('postalCode', '')
    }
  }, [watch('country')])

  useEffect(() => {
    if (updateProfile.isSuccess) {
      clearErrors([
        'address1',
        'address2',
        'district',
        'country',
        'province',
        'postalCode',
      ])
      onClose?.()
    }
  }, [updateProfile])

  useEffect(() => {
    if (buildingFocusRef.current) {
      searchAddress(watchAddress)
    }
  }, [watchAddress])

  const onChangeAddressSearch = onAddressSearched((addr) => {
    const { details } = addr?.value || {}
    if (details) {
      setValue('address1', details.building || '')
      setValue('address2', joinAddress([details.street, details.estate]))
      setValue('district', joinAddress([details.district, details.region]))
    }
  })

  const updateContact = handleSubmit((data) => {
    dispatch({
      type: 'updateProfile/updateProfile',
      payload: {
        address1: data.address1.toUpperCase(),
        address2: data.address2.toUpperCase(),
        district: data.district.toUpperCase(),
        country: data.country,
        province: data.province || '',
        postalCode: data.postalCode || '',
      },
    })
  })

  return (
    <Container>
      <CSContent>
        <Content>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'profile.addhkaddress')}</Title>
          <Spacer height="1.778rem" />
          <SelectAddress>{tt(intl, 'profile.findadress')}</SelectAddress>
          <Spacer height="0.889rem" />
          <AddressInput
            addressSelectRef={buildingFocusRef}
            placeholder={tt(intl, 'profile.findadress')}
            address={address}
            countryCode={watch('country')}
            onChange={(val) => {
              setAddress(val.address)
              setValue('country', val.countryCode)
            }}
            onChangeAddressSearch={onChangeAddressSearch}
            register={register}
            onFocus={() => {
              buildingFocusRef.current = true
            }}
            onBlur={() => {
              buildingFocusRef.current = false
              cancelSearchAddress()
            }}
            value={address}
            searchText={searchText}
            errors={errors}
          />
          <Spacer height="1.778rem" />
          <SelectAddress>{tt(intl, 'profile.enteraddress')}</SelectAddress>
          <Spacer height="0.889rem" />
          <div>
            <Input
              type="text"
              id="address1"
              {...register('address1', { required: true })}
              autoComplete="address1"
              placeholder={tt(intl, 'address.address1')}
              error={errors.address1}
              onFocus={() => {
                buildingFocusRef.current = true
              }}
              onBlur={() => {
                buildingFocusRef.current = false
                cancelSearchAddress()
              }}
            />
            <Spacer height="0.889rem" />
            <Input
              type="text"
              id="address2"
              autoComplete="address2"
              {...register('address2')}
              placeholder={tt(intl, 'address.address2')}
              error={errors.address2}
            />
            <Spacer height="0.889rem" />
            <Input
              type="text"
              id="district"
              autoComplete="address-level2"
              {...register('district')}
              placeholder={tt(intl, 'address.district')}
              error={errors.district}
            />
          </div>
          <Spacer height="0.889rem" />
          {watch('country') === 'TH' && (
            <>
              <Input
                type="text"
                id="province"
                autoComplete="province"
                {...register('province')}
                placeholder={tt(intl, 'address.province')}
                error={errors.province}
              />
              <Spacer height="0.889rem" />
              <Input
                type="text"
                id="postalCode"
                autoComplete="postalCode"
                {...register('postalCode')}
                placeholder={tt(intl, 'address.postalCode')}
                error={errors.postalCode}
              />
              <Spacer height="0.889rem" />
            </>
          )}
          <SelectInput2
            {...register('country', { required: true })}
            placeholder={tt(intl, 'profile.regionorcountry')}
            options={addressOptions(process.env.REACT_APP_ENV)}
            onChange={(val) => {
              setValue('country', val)
            }}
            value={watch('country')}
            isBold
          />
          <Spacer height="2.222rem" />
          <ContinueButton
            type="stretch"
            disabled={
              watch('country') !== 'TH'
                ? !(
                    watch('address1') &&
                    watch('address2') &&
                    watch('district') &&
                    watch('country')
                  )
                : !(
                    watch('address1') &&
                    watch('address2') &&
                    watch('district') &&
                    watch('country') &&
                    watch('province') &&
                    watch('postalCode')
                  )
            }
            onClick={() => {
              onClose()
              updateContact()
            }}
          >
            {tt(intl, 'common.continue')}
          </ContinueButton>
        </Content>
      </CSContent>
    </Container>
  )
}

const AddressSetupModal = ({ isOpen, onClose }) => (
  <BottomSheet top open={isOpen} onDismiss={onClose}>
    <ProfileAddress onClose={onClose} />
  </BottomSheet>
)

export default AddressSetupModal
