import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import { Line } from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { profileShippingAddressOptionsSelector } from '@/redux/profile/profileSelector'
import { joinAddress } from '@/utils/Address'
import { parsePhoneNumber } from '@/utils/Phone'
import { joinName } from '@/utils/user'

import { ProfileAddressModal } from './ProfileAddress'
import { ProfileDeliveryAddressModal } from './ProfileDeliveryAddress'
import ProfileDeliveryAddressForm from './ProfileDeliveryAddressForm'
import { ActionButtons, MainButton, RadioButton } from './Shared'

const Container = styled(PaddingContent)``

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const ItemRowAddress = styled.div`
  flex: 1;
`

const ItemRowMultiAddress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.333rem;
`

const ItemAddress = styled.div``

const ItemText = styled.div`
  font-size: 0.889rem;
`

const ItemButtons = styled.div`
  display: flex;
  align-items: center;
`

const ItemTextButton = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
  text-decoration: underline;
  cursor: pointer;
`

const ItemEditButton = ({ exist, onClick }) => {
  const intl = useIntl()
  return (
    <ItemTextButton onClick={onClick}>
      {exist ? tt(intl, 'common.edit') : tt(intl, 'common.add')}
    </ItemTextButton>
  )
}

const PaynowSelectDeliveryAddress = () => {
  const intl = useIntl()
  const history = useHistory()
  const { state } = useLocation()
  const { selectedAddressId: defaultAddressId } = state || {}
  const dispatch = useDispatch()
  const { orderId } = useParams()

  const newDeliveryAddressForm = useForm()
  const [selectedAddressId, setSelectedAddressId] = useState(0)
  const [isRequestRedirect, setIsRequestRedirect] = useState(false)
  const [isShowAddressModal, setIsShowAddressModal] = useState(false)
  const [showDeliveryAddressModal, setShowDeliveryAddressModal] = useState({
    isOpen: false,
    address: null,
  })

  const shippingAddressOptions = useSelector(
    profileShippingAddressOptionsSelector
  )
  const { updateProfileAddress } = useSelector((s) => s.updateProfile)

  const continueToPay = (addressId) => {
    sessionStorage.setItem('order_shipping_address_id', addressId)
    history.push(`/paynow/${orderId}/express`)
  }

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  useEffect(() => {
    if (updateProfileAddress.isSuccess) {
      if (isRequestRedirect) {
        const { ID } = updateProfileAddress.data
        continueToPay(ID)
      }
    }
  }, [updateProfileAddress])

  useEffect(() => {
    if (shippingAddressOptions.length > 0) {
      const id = defaultAddressId || shippingAddressOptions[0]?.ID
      setSelectedAddressId(id)
    }
  }, [])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfile' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  // select address id => continue
  const onClickContinue = () => {
    continueToPay(selectedAddressId)
  }

  const addNewAddressAndContinue = (data) => {
    setIsRequestRedirect(true)

    dispatch({
      type: 'updateProfile/updateProfileAddress',
      payload: { ...data },
    })
  }

  // add new address => continue
  const onClickSaveAndContinue = () => {
    newDeliveryAddressForm.handleSubmit(addNewAddressAndContinue)()
  }

  const showDeliveryAddress = (isOpen, address) => {
    if (isOpen) {
      setShowDeliveryAddressModal({ isOpen, address })
    } else {
      setShowDeliveryAddressModal({ isOpen })
      setTimeout(() => {
        setShowDeliveryAddressModal({ isOpen, address: null })
      }, 250)
    }
  }

  const onClickEditAddress = async (address) => {
    if (address.ID >= 0) {
      showDeliveryAddress(true, address)
    } else {
      setIsShowAddressModal(true)
    }
  }

  const onClickRemoveAddress = async (address) => {
    const { ID } = address

    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'profile.shippingaddress.askremove'),
        okText: tt(intl, 'profile.shippingaddress.remove'),
      })
    )

    if (!isOk) return

    dispatch({
      type: 'updateProfile/removeProfileAddress',
      payload: { id: ID },
    })
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const hasAnyAddress = shippingAddressOptions.length > 0

  const title = hasAnyAddress
    ? tt(intl, 'profile.shippingaddress.select')
    : tt(intl, 'profile.address.add')

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>{title}</Title>
      {hasAnyAddress && (
        <>
          <Spacer height="0.889rem" />
          <SubTitle>
            {tt(intl, 'profile.shippingaddress.selectuse')}
            <br />
            {tt(intl, 'profile.shippingaddress.enternew')}
          </SubTitle>
          <Spacer height="1.333rem" />
          {shippingAddressOptions.map((address) => (
            <ItemRowMultiAddress key={address.ID}>
              <RadioButton
                checked={selectedAddressId === address.ID}
                onClick={() => setSelectedAddressId(address.ID)}
              />
              <Spacer width="0.889rem" />
              <ItemRowAddress>
                <Spacer height="0.222rem" />
                <ItemAddress onClick={() => setSelectedAddressId(address.ID)}>
                  {(address.contactFirstname || address.contactLastname) && (
                    <>
                      <ItemText>
                        {joinName([
                          address.contactFirstname,
                          address.contactLastname,
                        ])}
                      </ItemText>
                      <Spacer height="0.889rem" />
                    </>
                  )}
                  <ItemText>
                    {joinAddress([
                      address.address1,
                      address.address2,
                      address.district,
                      address.region,
                    ])}
                  </ItemText>
                  {address.contactNumber && (
                    <>
                      <Spacer height="0.889rem" />
                      <ItemText>
                        {tt(intl, 'common.phone')}:{' '}
                        {parsePhoneNumber(address.contactNumber).formatted}
                      </ItemText>
                    </>
                  )}
                </ItemAddress>
                <Spacer height="1.111rem" />
                <ItemButtons>
                  <ItemEditButton
                    exist
                    onClick={() => onClickEditAddress(address)}
                  />
                  <Spacer width="0.889rem" />
                  {address.ID >= 0 && (
                    <ItemTextButton
                      onClick={() => onClickRemoveAddress(address)}
                    >
                      {tt(intl, 'common.remove')}
                    </ItemTextButton>
                  )}
                </ItemButtons>
              </ItemRowAddress>
            </ItemRowMultiAddress>
          ))}
          <Spacer height="2.222rem" />
          <ActionButtons>
            <MainButton onClick={onClickContinue}>
              {tt(intl, 'paynow.delivertoaddress')}
            </MainButton>
          </ActionButtons>
          <Spacer height="1.778rem" />
          <Line />
          <Spacer height="1.778rem" />
          <Title>{tt(intl, 'profile.address.add')}</Title>
        </>
      )}
      <Spacer height="0.665rem" />
      <ErrorMessageLine errorMessage={updateProfileAddress.error} />
      <Spacer height="0.665rem" />
      <Spacer height="1.333rem" />
      <ProfileDeliveryAddressForm form={newDeliveryAddressForm} />
      <Spacer height="2.222rem" />
      <ActionButtons>
        <MainButton onClick={onClickSaveAndContinue}>
          {tt(intl, 'profile.shippingaddress.savedeliver')}
        </MainButton>
      </ActionButtons>
      <Spacer height="4.444rem" />
      <ProfileAddressModal
        isOpen={isShowAddressModal}
        onClose={() => setIsShowAddressModal(false)}
      />
      <ProfileDeliveryAddressModal
        isOpen={showDeliveryAddressModal.isOpen}
        address={showDeliveryAddressModal.address}
        onClose={() => showDeliveryAddress(false)}
      />
      <LoadingModal loading={updateProfileAddress.isLoading} />
    </Container>
  )
}

export default PaynowSelectDeliveryAddress
