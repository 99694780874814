import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as IconAddressSvg } from '@/assets/welcome/icon-address.svg'
import { ReactComponent as IconAgeSvg } from '@/assets/welcome/icon-age.svg'
import { ReactComponent as IconDivitMilesSvg } from '@/assets/welcome/icon-divitmiles.svg'
import { ReactComponent as IconEmailSvg } from '@/assets/welcome/icon-email.svg'
import { ReactComponent as IconHkidSvg } from '@/assets/welcome/icon-hkid.svg'
import { ReactComponent as IconScheduleSvg } from '@/assets/welcome/icon-schedule.svg'
import { ReactComponent as IconSpecialOfferSvg } from '@/assets/welcome/icon-special-offer.svg'
import { ReactComponent as IconSpreadSvg } from '@/assets/welcome/icon-spread.svg'
import Box from '@/components/Box'
import CancelPurchase from '@/components/CancelPurchase'
import { Button, H2 } from '@/components/Divit'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import colors from '@/themes/colors'

import { formatMessage } from '../../locales/format'

const Container = styled(PaddingContent)`
  margin-top: 1.25rem;
`

const Title = styled(H2)``

const Description = styled.div`
  font-size: 0.77rem;
`

const BoxContainer = styled(Box)`
  padding: 1.875rem;
  box-sizing: border-box;
`

const SubTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const ItemIcon = styled.div`
  width: 1.56rem;
  height: 1.56rem;

  & > svg {
    width: 100%;
    height: 100%;
  }
`

const ItemBody = styled.div`
  flex: 1;
  font-size: 0.75rem;
  color: ${colors.divitDarkGrey};
  margin-left: 0.625rem;
`

const NextButton = styled(Button)`
  width: 100%;
  font-size: 0.889rem;
  color: #1c1c1c;
`

const Page1 = () => {
  const intl = useIntl()
  return (
    <BoxContainer>
      <SubTitle>{formatMessage(intl, 'welcome.pages[0].subtitle')}</SubTitle>
      <Spacer height="1.3125rem" />
      <Item>
        <ItemIcon>
          <IconAgeSvg />
        </ItemIcon>
        <ItemBody>{formatMessage(intl, 'welcome.pages[0].age18')}</ItemBody>
      </Item>
      <Item>
        <ItemIcon>
          <IconHkidSvg />
        </ItemIcon>
        <ItemBody>{formatMessage(intl, 'welcome.pages[0].hkid')}</ItemBody>
      </Item>
      <Item>
        <ItemIcon>
          <IconAddressSvg />
        </ItemIcon>
        <ItemBody>{formatMessage(intl, 'welcome.pages[0].address')}</ItemBody>
      </Item>
      <Item>
        <ItemIcon>
          <IconEmailSvg />
        </ItemIcon>
        <ItemBody>{formatMessage(intl, 'welcome.pages[0].email')}</ItemBody>
      </Item>
    </BoxContainer>
  )
}

const Page2 = () => {
  const intl = useIntl()
  return (
    <BoxContainer>
      <SubTitle>{formatMessage(intl, 'welcome.pages[1].subtitle')}</SubTitle>
      <Spacer height="1.3125rem" />
      <Item>
        <ItemIcon>
          <IconSpreadSvg />
        </ItemIcon>
        <ItemBody>{formatMessage(intl, 'welcome.pages[1].spread')}</ItemBody>
      </Item>
      <Item>
        <ItemIcon>
          <IconScheduleSvg />
        </ItemIcon>
        <ItemBody>{formatMessage(intl, 'welcome.pages[1].schedule')}</ItemBody>
      </Item>
      <Item>
        <ItemIcon>
          <IconDivitMilesSvg />
        </ItemIcon>
        <ItemBody>
          {formatMessage(intl, 'welcome.pages[1].divitmiles')}
        </ItemBody>
      </Item>
      <Item>
        <ItemIcon>
          <IconSpecialOfferSvg />
        </ItemIcon>
        <ItemBody>
          {formatMessage(intl, 'welcome.pages[1].specialoffer')}
        </ItemBody>
      </Item>
    </BoxContainer>
  )
}

const Welcome = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const onClickNext = () => {
    dispatch({ type: 'redirectReturnUrlOrHome' })
  }

  return (
    <Container>
      <Title>{formatMessage(intl, 'welcome.title')}</Title>
      <Spacer height="0.66rem" />
      <Description>{formatMessage(intl, 'welcome.description')}</Description>
      <Spacer height="1.125rem" />
      <Page2 />
      <Spacer height="1.125rem" />
      <Page1 />
      <Spacer height="1.5rem" />
      <NextButton onClick={onClickNext}>
        {formatMessage(intl, 'button.continue')}
      </NextButton>
      <Spacer height="2rem" />
      <CancelPurchase />
      <Spacer height="2rem" />
    </Container>
  )
}

export default Welcome
