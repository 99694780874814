import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as DivitSvg } from '@/assets/divit-black.svg'
import { ReactComponent as FpsSvg } from '@/assets/payment/fps.svg'

import Icon from './Icon'

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Slogan = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0 0.5rem;
  color: #1c1c1c;
  font-size: 0.667rem;
  font-weight: 700;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    justify-content: left;
    font-size: 0.887rem;
  }
`

const PoweredByDivit = () => {
  const intl = useIntl()

  return (
    <Container>
      <Slogan>
        {intl.formatMessage(
          { id: 'common.fpsbydivit' },
          {
            fps: (
              <Icon
                renderImage={() => <FpsSvg />}
                style={{ marginRight: '0.3rem', marginLeft: '0.3rem' }}
              />
            ),
            divit: (
              <Icon
                renderImage={() => <DivitSvg />}
                width="3.5rem"
                style={{ marginBottom: '0.4rem', marginLeft: '0.2rem' }}
              />
            ),
          }
        )}
      </Slogan>
    </Container>
  )
}
export default PoweredByDivit
