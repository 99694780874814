import { useEffect, useRef } from 'react'

const useUpdateValue = (fn, dep) => {
  const fnRef = useRef(fn)
  fnRef.current = fn
  const valueRef = useRef(dep)

  useEffect(() => {
    fnRef.current?.(valueRef.current, dep)
    valueRef.current = dep
  }, [dep])
}

export default useUpdateValue
