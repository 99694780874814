import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import PaylaterOutstandingOrderList from '@/components/paylater/PaylaterOutstandingOrderList'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import { paylaterActiveOrdersSelector } from '@/redux/order/orderSelector'
import * as InstalmentUtils from '@/utils/Instalments'

const Container = styled(PaddingContent)``

const HistoryPaylaterOutstanding = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initAllOrders' },
  })

  const orders = useSelector(paylaterActiveOrdersSelector)

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onPay = (order) => {
    const instalment = InstalmentUtils.getOutstandingInstalment(
      order.instalments
    )
    history.push(
      `/pay/fps/paylater/${order.orderid}/${instalment.instalmentID}`
    )
  }

  const onClickOrderItem = (order) => {
    history.push(`/schedule/review/${order.orderid}`)
  }

  return (
    <Container hasPadding>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>
        {tt(intl, 'paylater.upcomingpayment')}
      </Title>
      <Spacer height="1rem" />
      <PaylaterOutstandingOrderList
        orders={orders}
        onPay={onPay}
        onClickItem={onClickOrderItem}
      />
      <Spacer height="4rem" />
    </Container>
  )
}

export default HistoryPaylaterOutstanding
