import { isError, isNaN } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import { Button, Input } from '@/pages/Auth/shared/style'

const MyDatePicker = ({ dateInit, setValue, isInvalidDate }) => {
  const [date, setDate] = useState(dateInit)

  const handleDateChange = (e) => {
    const inputDate = e.target.value

    const sanitizedDate = inputDate.replace(/[^0-9/]/g, '')

    const digitsOnly = sanitizedDate.replace(/\D/g, '')

    let formattedDate = ''
    if (digitsOnly.length <= 2) {
      formattedDate = digitsOnly
    } else if (digitsOnly.length <= 4) {
      formattedDate = `${digitsOnly.slice(0, 2)}/${digitsOnly.slice(2)}`
    } else {
      formattedDate = `${digitsOnly.slice(0, 2)}/${digitsOnly.slice(
        2,
        4
      )}/${digitsOnly.slice(4, 8)}`
    }

    if (/^\d{0,2}\/?\d{0,2}\/?\d{0,4}$/.test(sanitizedDate)) {
      setDate(formattedDate)
    }
  }

  useEffect(() => {
    if (date.length === 10) {
      setValue('DOB', date)
    } else {
      setValue('DOB', '')
    }
  }, [date])
  return (
    <Input
      type="text"
      value={date}
      onChange={handleDateChange}
      placeholder="DD/MM/YYYY"
      error={isInvalidDate}
    />
  )
}

export default MyDatePicker
