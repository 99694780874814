import DefaultBankLogo from '@/assets/banks/default.png'
import { findBankByCode } from '@/constants/banks'

export const getBankString = (bankCode, language) => {
  const bank = findBankByCode(bankCode)
  const bankName = bank ? bank[language] || bank.en : ''
  return [bankName, `(${bankCode})`].join(' ')
}

export const getBankLogo = (bankCode) => {
  const bank = findBankByCode(bankCode)
  return bank?.Logo || DefaultBankLogo
}

export default 0
