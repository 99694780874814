import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: ${(p) => p.wrapperWidth};
  height: ${(p) => p.wrapperHeight};
  justify-content: center;
  align-items: center;

  ${(p) =>
    p.isCircle &&
    `
    border-radius: 50%;
    overflow: hidden;
  `}

  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  }

  & > svg,
  & > img {
    width: ${(p) => p.width};
    height: ${(p) => p.height};
  }
`

const Icon = ({
  style,
  className,
  renderImage,
  wrapperWidth,
  wrapperHeight,
  width,
  height,
  isCircle,
  onClick,
}) => (
  <Wrapper
    style={style}
    className={className}
    wrapperWidth={wrapperWidth}
    wrapperHeight={wrapperHeight}
    width={width}
    height={height}
    isCircle={isCircle}
    onClick={onClick}
  >
    {renderImage && renderImage()}
  </Wrapper>
)

export default Icon
