// Note: not using Divit components
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as EmailSvg } from '@/assets/common/email.svg'
import { ReactComponent as TelSvg } from '@/assets/common/phone.svg'
import BackButton from '@/components/BackButton'
import LoginButton from '@/components/buttons/LoginButton'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import Spacer from '@/components/Spacer'
import PhoneInput from '@/components/ui/PhoneInput'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'
import { useLocale } from '@/contexts/localeContext'
import usePageError from '@/hooks/usePageError'
import useUnmount from '@/hooks/useUnmount'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { Button, Input, LineHR } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import * as User from '@/utils/user'
import { validateEmail, validateTel } from '@/utils/validation'

const Container = styled(PaddingContent)`
  margin-top: 1.25rem;
  color: #1c1c1c;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Hero = styled.div``

const HeroTitle = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 1.33rem;
  text-align: center;
  line-height: 1.33;
`

const HeroSubTitle = styled.div`
  font-size: 0.78rem;
  text-align: center;
`

const AccountInfo = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.33rem;
`

const FormColumn = styled.div`
  flex: ${({ flex }) => flex || 1};
  ${({ position }) => position && `text-align: ${position}`}
`

const ResetPasswordSendButton = styled(Button)`
  font-size: 0.89rem;
  background-color: #ffcc33;
  color: #1c1c1c;
`

const HRRow = styled.div`
  width: 8.78rem;
  margin: 0 auto;
`

const DivitMilesForgotPassword = () => {
  const intl = useIntl()
  const { currentLocale } = useLocale()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const args = location?.state || {}
  const [clientIdType, setClientIdType] = useState(args.clientIdType || 'tel')
  const [countryCode, setCountryCode] = useState(args.countryCode)
  const [clientId, setClientId] = useState(args.clientId || '')

  const { executeRecaptcha } = useCaptcha()
  const { errorMessage, updateErrorMessage } = usePageError()
  const { forgotPassword } = useSelector((s) => s.fastSignup)

  useUnmount(() => dispatch({ type: 'fastSignup/reset' }))

  useUpdateEffect(() => {
    if (clientIdType === 'tel') {
      setCountryCode('+852')
    } else {
      setCountryCode('')
    }
    setClientId('')
  }, [clientIdType])

  useEffect(() => {
    if (forgotPassword.isError) {
      updateErrorMessage(
        intl.formatMessage({ id: 'error.action.forgot.password' })
      )
    } else {
      updateErrorMessage('')
    }
  }, [forgotPassword])

  const validateForm = () => {
    if (clientIdType === 'tel' && !validateTel(countryCode, clientId)) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.mobile' }))
      return false
    }
    if (clientIdType === 'email' && !validateEmail(clientId)) {
      updateErrorMessage(intl.formatMessage({ id: 'error.invalid.email' }))
      return false
    }
    return true
  }

  const onClickResetPasswordSend = async (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    const mClientId =
      clientIdType === 'tel' ? `${countryCode}${clientId}` : clientId

    let recapResp = ''

    try {
      recapResp = await executeRecaptcha('FORGOT_PWD')
    } catch (err) {
      console.error(err)
    }

    dispatch({
      type: 'fastSignup/forgotPassword',
      payload: {
        clientId: mClientId,
        language: currentLocale,
        recapResp,
      },
    })
  }

  const onClickLogin = () => {
    history.push('/signin')
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const loading = forgotPassword.isLoading

  const displayId = User.getProfileId(countryCode, clientId)

  return (
    <Container>
      {!forgotPassword.isSuccess && (
        <Hero>
          <Spacer height="0.89rem" />
          <Row>
            <BackButton onClick={onClickBack} />
            <HeroTitle>{tt(intl, 'login.forgotpassword')}</HeroTitle>
          </Row>
          <Spacer height="0.89rem" />
          <HeroSubTitle>
            {clientIdType === 'tel' &&
              tt(intl, 'login.resetpassword.mobilenumber')}
            {clientIdType === 'email' &&
              tt(intl, 'login.resetpassword.mobilenumberoremail')}
          </HeroSubTitle>
        </Hero>
      )}
      {forgotPassword.isSuccess && (
        <Hero>
          <Spacer height="0.89rem" />
          <HeroTitle>
            {clientIdType === 'tel' && tt(intl, 'login.resetpassword.checksms')}
            {clientIdType === 'email' &&
              tt(intl, 'login.resetpassword.checkemail')}
          </HeroTitle>
          <Spacer height="0.89rem" />
          <HeroSubTitle>
            {clientIdType === 'tel' &&
              tt(intl, 'login.resetpassword.checklink')}
            {clientIdType === 'email' &&
              tt(intl, 'login.resetpassword.checklink')}
          </HeroSubTitle>
          <Spacer height="1.333rem" />
          <AccountInfo>{displayId}</AccountInfo>
          <Spacer height="1.333rem" />
          <HeroSubTitle>
            {clientIdType === 'email' &&
              tt(intl, 'login.resetpassword.junkemail')}
          </HeroSubTitle>
          <Spacer height="2.222rem" />
          <Button type="stretch" onClick={onClickLogin}>
            {tt(intl, 'common.loginnow')}
          </Button>
        </Hero>
      )}
      <Spacer height="0.65rem" />
      <ErrorMessageLine errorMessage={errorMessage} />
      <Spacer height="0.65rem" />
      {!forgotPassword.isSuccess && (
        <>
          <Form>
            <FormRow>
              <FormColumn>
                {clientIdType === 'email' && (
                  <Input
                    type="email"
                    id="email"
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                    placeholder={tt(intl, 'common.email')}
                  />
                )}
                {clientIdType === 'tel' && (
                  <PhoneInput
                    id="tel"
                    countryCode={countryCode}
                    phoneNumber={clientId}
                    onChange={(data) => {
                      setCountryCode(data.countryCode)
                      setClientId(data.phoneNumber)
                    }}
                    placeholder={tt(intl, 'common.mobilenumber')}
                  />
                )}
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <ResetPasswordSendButton
                  type="stretch"
                  disabled={!clientId}
                  onClick={onClickResetPasswordSend}
                >
                  {tt(intl, 'common.submit')}
                </ResetPasswordSendButton>
              </FormColumn>
            </FormRow>
          </Form>
          <HRRow>
            <LineHR>or</LineHR>
          </HRRow>
          <Spacer height="1.333rem" />
          {clientIdType === 'tel' && (
            <LoginButton
              text={tt(intl, 'login.continue.email')}
              iconProps={{
                renderImage: () => <EmailSvg />,
                width: '1rem',
                height: '0.711rem',
              }}
              onClick={() => setClientIdType('email')}
            />
          )}
          {clientIdType === 'email' && (
            <LoginButton
              text={tt(intl, 'login.continue.mobilenumber')}
              iconProps={{
                renderImage: () => <TelSvg />,
                width: '0.952rem',
                height: '0.952rem',
              }}
              onClick={() => setClientIdType('tel')}
            />
          )}
        </>
      )}
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default (props) => (
  <CaptchaProvider>
    <DivitMilesForgotPassword {...props} />
  </CaptchaProvider>
)
