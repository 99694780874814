import styled, { css } from 'styled-components'

const Content = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.111rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 calc(100% / 12);
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.sm}px`}) {
    padding: 0 calc(100% / 18);
  }

  ${(p) =>
    p.isFullWidth &&
    css`
      @media (min-width: 768px) {
        max-width: var(--full-width-mode-max-width);
        padding: 0;
        margin: 0 auto;
      }
    `}
`

export default Content
