import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import HeaderBgSvg from '@/assets/miles/convert-header-bg.svg'
import { ReactComponent as DivitMilesBWSvg } from '@/assets/miles/divit-miles-logo-bordered.svg'
import { ReactComponent as GuideIconSvg } from '@/assets/miles/guide-icon-black.svg'
import { ReactComponent as SpendEmailSvg } from '@/assets/spend/spend-email.svg'
import { ReactComponent as SpendSmsSvg } from '@/assets/spend/spend-sms.svg'
import BackButton from '@/components/BackButton'
import BottomSheet from '@/components/BottomSheet'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import GiftCardImage from '@/components/giftcard/GiftCardImage'
import GiftCardTNCModal from '@/components/GiftCardTNCModal'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import MilesBalanceGuideModal from '@/components/miles/MilesBalanceGuideModal'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import {
  ActionButtons,
  Column,
  Line,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
// import asiamiles from '@/constants/asiamiles'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { ProfileEditMobileModal } from '@/pages/profile//ProfileEditMobile'
import { ProfileEditEmailModal } from '@/pages/profile/ProfileEditEmail'
import { goBack } from '@/redux/app/appActions'
import { productGroupsSelector } from '@/redux/giftCard/giftCardSelector'
import { getCurrencyToDVMSelector } from '@/redux/lookup/lookupSelector'
import { startRequestPinFlow, verifyIdentity } from '@/redux/pin/pinActions'
import {
  divitMilesSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'
import PhoneParser from '@/utils/Phone'
import { FormattedMiles, FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffcc33;
`

const HeaderBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12.222rem;
  background-image: url(${HeaderBgSvg});
  background-size: auto 12rem;
  background-position: top right;
  background-repeat: no-repeat;
`

const Header = styled.div`
  position: relative;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const PageTitle = styled.div`
  font-weight: 700;
  font-size: 1.33rem;
  color: #1c1c1c;
`

const MilesBalance = styled.div`
  display: flex;
  align-items: center;
`

const MilesBalanceText = styled.div`
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.02em;
  line-height: 2rem;
  color: #1c1c1c;
  cursor: pointer;
`

const MainContent = styled(PaddingContent)`
  flex: 1;
`

const CSContainer = styled(CSLayout.CSContainer)`
  background-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`

const SectionTitle = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const SectionSubTitle = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
  text-align: center;
`

const ProductList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`

const ProductItem = styled.div`
  padding: 0 0.444rem;
  margin-bottom: 1.333rem;
  box-sizing: border-box;
  cursor: pointer;

  &:nth-child(2n-1) {
    padding-left: auto;
    padding-right: 0.444rem;
  }

  &:nth-child(2n) {
    padding-left: 0.444rem;
    padding-right: auto;
  }
`

const GiftCardImageContainer = styled.div`
  position: relative;
`

const ProductImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: rgba(28, 28, 28, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
`

const ProductOutOfStockText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.778rem;
  font-weight: 700;
  color: white;
  background-color: rgba(194, 194, 194, 0.9);
  padding: 4px 0;
`

const ProductName = styled.div`
  margin-top: 8px;
  text-align: center;
  font-size: 0.778rem;
  font-weight: 600;
`

const ProductMilesCardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ProductMilesCardItem = styled.div`
  width: 100%;
  cursor: pointer;

  & > div {
    border: ${(p) =>
      p.isSelected ? '1px solid #FFCC33' : '1px solid #dedede'};
  }

  /* 2 columns */

  @media (max-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    &:nth-child(2n-1) > div {
      margin-left: auto;
      margin-right: 0.444rem;
    }

    &:nth-child(2n) > div {
      margin-left: 0.444rem;
      margin-right: auto;
    }
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    /* 3 columns */

    &:nth-child(3n-2) > div {
      margin-left: auto;
      margin-right: 0.444rem;
    }

    &:nth-child(3n-1) > div {
      margin-right: 0.444rem;
      margin-left: 0.444rem;
    }

    &:nth-child(3n) > div {
      margin-left: 0.444rem;
      margin-right: auto;
    }
  }
`

const ProductMilesCard = styled.div`
  position: relative;
  width: 8.778rem;
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dedede;
  margin-bottom: 0.722rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    /* 3 columns */
    width: 7.24rem;
    height: 4.54rem;
  }
`

const ProductMilesCardOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: rgba(28, 28, 28, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
`

const ProductFaceValue = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const ProductMilesValue = styled(MilesValue)`
  font-weight: 600;
`

const ProductDetails = styled.div`
  display: flex;
`

const ProductCardContainer = styled.div`
  width: 10.667rem;
  margin: 0 auto;
`

const ProductQty = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const ProductCardMilesValue = styled(MilesValue)`
  font-weight: 600;
  align-self: flex-start;
`

const CardNameDetails = styled.div`
  flex: 1;
  margin: 0 1.222rem;
`

const CardName = styled.div`
  font-size: 0.778rem;
`

const CardValid = styled.div`
  font-size: 0.778rem;
  color: #979797;
`

const DeliveryDetails = styled.div``

const DeliveryDetailsTitle = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
`

const DeliveryRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.889rem;
`

const DeliveryTitle = styled.div`
  flex: 1;
  font-size: 0.778rem;
  font-weight: 500;
`

const DeliveryText = styled.div`
  font-size: 0.778rem;
`

const DeliverAction = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`

const DeliveryTime = styled.div`
  font-size: 0.778rem;
`

const CheckoutDetails = styled.div``

const CheckoutRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CheckoutText = styled.div`
  font-size: 0.778rem;
`

// const CheckoutExtra = styled.div`
//   display: flex;
//   font-size: 0.778rem;

//   & > * {
//     margin-right: 5px;
//   }
// `

// const CheckoutConvertLink = styled.a`
//   font-size: 0.778rem;
//   font-weight: 600;
//   text-decoration: underline;
//   cursor: pointer;
// `

const SucessSubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const SuccessDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.889rem;
`

const SuccessDetailsTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const SuccessDetailsValue = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
  text-align: right;
`

const TNC = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const Anchor = styled.span`
  text-decoration: underline;
  color: #1c1c1c;
  cursor: pointer;
`

const ProductCardImageContainer = ({ image, isOutOfStock }) => {
  const intl = useIntl()

  return (
    <GiftCardImageContainer>
      {isOutOfStock && (
        <ProductImageOverlay>
          <ProductOutOfStockText>
            {tt(intl, 'giftcard.availablesoon')}
          </ProductOutOfStockText>
        </ProductImageOverlay>
      )}
      <GiftCardImage image={image} />
    </GiftCardImageContainer>
  )
}

const StepProducts = ({ onClickItem }) => {
  const intl = useIntl()

  const productGroups = useSelector(productGroupsSelector)

  return (
    <CSContainer>
      <CSLayout.CSContent>
        <MainContent>
          <Spacer height="2.22rem" />
          <SectionTitle>{tt(intl, 'giftcard.treatyourself')}</SectionTitle>
          <Spacer height="0.889rem" />
          <SectionSubTitle>{tt(intl, 'giftcard.choosecard')}</SectionSubTitle>
          <Spacer height="1.333rem" />
          <ProductList>
            {Object.keys(productGroups).map((group) => {
              const productGroup = productGroups[group]
              const product = productGroup[0]
              const title = product.brandTag.metadata?.title || group
              const image = product.customImage
              const isOutOfStock =
                Object.values(productGroup).filter((p) => !p.isOutOfStock)
                  .length === 0
              const clickable = !isOutOfStock
              return (
                <ProductItem
                  key={group}
                  onClick={() => clickable && onClickItem(group)}
                >
                  <ProductCardImageContainer
                    image={image}
                    isOutOfStock={isOutOfStock}
                  />
                  <ProductName>{title}</ProductName>
                </ProductItem>
              )
            })}
          </ProductList>
        </MainContent>
      </CSLayout.CSContent>
    </CSContainer>
  )
}

const StepCards = ({ productGroup, onClickItem, onClickBack }) => {
  const intl = useIntl()

  const [selectedProduct, setSelectedProduct] = useState(null)

  const productGroups = useSelector(productGroupsSelector)
  const rateCurrencyToDVM = useSelector(getCurrencyToDVMSelector)

  const products = productGroups[productGroup]
  const title = products[0]?.brandTag.metadata?.title || productGroup

  const onClickCheckout = () => {
    onClickItem(selectedProduct)
  }

  const onSelectProduct = (product) => {
    setSelectedProduct(product)
  }

  return (
    <CSContainer>
      <CSLayout.CSContent>
        <MainContent>
          <Spacer height="2.22rem" />
          <Title onClickBack={onClickBack}>{title}</Title>
          <Spacer height="0.889rem" />
          <SectionSubTitle>{tt(intl, 'giftcard.chooseamount')}</SectionSubTitle>
          <Spacer height="1.333rem" />
          <ProductMilesCardList>
            {products.map((product) => {
              const { isOutOfStock, currency } = product
              const rate = rateCurrencyToDVM.filter(
                (r) => r.currency === currency
              )?.[0]
              const milesToBurn =
                Math.ceil(product.faceValue.amount / 100 / rate.buy) * 100
              const clickable = !isOutOfStock

              return (
                <ProductMilesCardItem
                  key={product.ID}
                  isSelected={selectedProduct?.productID === product.productID}
                  onClick={() => clickable && onSelectProduct(product)}
                >
                  <ProductMilesCard>
                    {isOutOfStock && (
                      <ProductMilesCardOverlay>
                        <ProductOutOfStockText>
                          {tt(intl, 'giftcard.availablesoon')}
                        </ProductOutOfStockText>
                      </ProductMilesCardOverlay>
                    )}
                    <ProductFaceValue>
                      {FormattedPrice(
                        product.faceValue.currency,
                        product.faceValue.amount,
                        false
                      )}
                    </ProductFaceValue>
                    <Spacer height="8px" />
                    <ProductMilesValue
                      fontSize="0.778rem"
                      miles={milesToBurn}
                      isSigned={false}
                    />
                  </ProductMilesCard>
                </ProductMilesCardItem>
              )
            })}
          </ProductMilesCardList>
          <Spacer height="2.222rem" />
        </MainContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter isRounded hasShadow>
        <PaddingContent>
          <ActionButtons>
            <PrimaryButton
              onClick={onClickCheckout}
              disabled={!selectedProduct}
            >
              {tt(intl, 'giftcard.checkout')}
            </PrimaryButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSContainer>
  )
}

const DeliveryRowEmail = ({ email, onClickAdd }) => {
  const intl = useIntl()

  return (
    <DeliveryRow>
      <Icon
        style={{ marginTop: '4px' }}
        renderImage={() => <SpendEmailSvg />}
        width="1rem"
        height="0.711rem"
      />
      <Spacer width="0.889rem" />
      {email && <DeliveryTitle>{email}</DeliveryTitle>}
      {!email && (
        <Column>
          <DeliveryText>{tt(intl, 'giftcard.receiveemail.ask')}</DeliveryText>
          <Spacer height="4px" />
          <DeliverAction onClick={onClickAdd}>
            {tt(intl, 'giftcard.addemail')}
          </DeliverAction>
        </Column>
      )}
    </DeliveryRow>
  )
}

const DeliveryRowSms = ({ tel, onClickAdd }) => {
  const intl = useIntl()

  return (
    <DeliveryRow>
      <Icon
        style={{ marginTop: '4px' }}
        renderImage={() => <SpendSmsSvg />}
        width="1rem"
        height="0.711rem"
      />
      <Spacer width="0.889rem" />
      {tel && <DeliveryTitle>{tel}</DeliveryTitle>}
      {!tel && (
        <Column>
          <DeliveryText>{tt(intl, 'giftcard.receivesms.ask')}</DeliveryText>
          <Spacer height="4px" />
          <DeliverAction onClick={onClickAdd}>
            {tt(intl, 'giftcard.addmobilenumber')}
          </DeliverAction>
        </Column>
      )}
    </DeliveryRow>
  )
}

const StepConfirmModal = ({
  isOpen,
  productGroup,
  product,
  error,
  onClickPlaceOrder,
  onClose,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [isShowEditEmailModal, setIsShowEditEmailModal] = useState()
  const [isShowEditMobileModal, setIsShowEditMobileModal] = useState()
  const [isShowTncModal, setIsShowTncModal] = useState()

  const profile = useSelector(profileSelector)
  const divitMiles = useSelector(divitMilesSelector)
  const rateCurrencyToDVM = useSelector(getCurrencyToDVMSelector)

  if (!productGroup || !product) return null

  const hasContact = profile.email_validated || profile.tel_validated
  const { currency } = product
  const rate = rateCurrencyToDVM.filter((r) => r.currency === currency)?.[0]
  const milesToBurn = Math.ceil(product.faceValue.amount / 100 / rate.buy) * 100
  // const milesDiff =
  //   Math.ceil(Math.abs(divitMiles.balance - milesToBurn) / 100) * 100
  const hasNotEnoughMiles = divitMiles.balance - milesToBurn < 0

  const cardName = product.metadata.title
  const formattedPhone = PhoneParser(profile.tel).formatted

  const addEmail = async () => {
    const authResult = await dispatch(verifyIdentity({}))
    if (authResult.isSuccess) {
      setIsShowEditEmailModal(true)
    }
  }

  const addMobileNumber = async () => {
    const authResult = await dispatch(verifyIdentity({}))
    if (authResult.isSuccess) {
      setIsShowEditMobileModal(true)
    }
  }

  return (
    <BottomSheet top open={isOpen}>
      <CSLayout.CSContainer>
        <CSLayout.CSContent>
          <PaddingContent>
            <Spacer height="2.22rem" />
            <Title onClickBack={onClose}>
              {tt(intl, 'giftcard.revieworder')}
            </Title>
            <Spacer height="1.778rem" />
            <ProductCardContainer>
              <GiftCardImage image={product.customImage} />
            </ProductCardContainer>
            <Spacer height="1.778rem" />
            <ProductDetails>
              <ProductQty>1x</ProductQty>
              <CardNameDetails>
                <CardName>{cardName}</CardName>
                {false && <CardValid>valid for 36 months</CardValid>}
              </CardNameDetails>
              <ProductCardMilesValue
                fontSize="0.778rem"
                miles={milesToBurn}
                isSigned={false}
              />
            </ProductDetails>
            <Spacer height="0.889rem" />
            <Line />
            <Spacer height="1.333rem" />
            <DeliveryDetails>
              <DeliveryDetailsTitle>
                {tt(intl, 'giftcard.deliverydetails')}
              </DeliveryDetailsTitle>
              <Spacer height="0.889rem" />
              {/* email first, then sms */}
              {profile.email_validated && (
                <DeliveryRowEmail
                  email={profile.email_validated ? profile.email : ''}
                  onClickAdd={addEmail}
                />
              )}
              {!profile.email_validated && profile.tel_validated && (
                <DeliveryRowSms
                  tel={profile.tel_validated ? formattedPhone : ''}
                  onClickAdd={addMobileNumber}
                />
              )}
              {false && (
                <DeliveryTime>usually arrive in around 1 hour</DeliveryTime>
              )}
            </DeliveryDetails>
            <Spacer height="0.889rem" />
            <Line />
            <Spacer height="1.333rem" />
            <TNC>
              <Anchor onClick={() => setIsShowTncModal(true)}>
                {tt(intl, 'giftcard.tnc')}
              </Anchor>
            </TNC>
          </PaddingContent>
        </CSLayout.CSContent>
        <CSLayout.CSFooter isRounded hasShadow>
          <PaddingContent>
            <CheckoutDetails>
              <CheckoutRow>
                <CheckoutText>{tt(intl, 'payment.youpay')}</CheckoutText>
                <ProductCardMilesValue
                  fontSize="1.111rem"
                  miles={milesToBurn}
                  isSigned={false}
                />
              </CheckoutRow>
              {/* {hasNotEnoughMiles && (
                <>
                  <Spacer height="0.889rem" />
                  <CheckoutExtra>
                    <span>{tt(intl, 'giftcard.extramiles1')}</span>
                    <ProductCardMilesValue
                      fontSize="0.778rem"
                      miles={Math.abs(milesDiff)}
                      isSigned={false}
                    />
                    {tt(intl, 'giftcard.extramiles2')}
                  </CheckoutExtra>
                  <CheckoutConvertLink
                    target="_blank"
                    href={asiamiles.en.convertUrl}
                  >
                    {tt(intl, 'giftcard.convert.asiamiles')}
                  </CheckoutConvertLink>
                </>
              )} */}
            </CheckoutDetails>
            {error && (
              <ErrorMessageLine
                errorMessage={tt(intl, 'giftcard.placeorder.error')}
              />
            )}
            <Spacer height="0.889rem" />
            <ActionButtons>
              <SecondaryButton onClick={onClose}>
                {tt(intl, 'common.cancel')}
              </SecondaryButton>
              <PrimaryButton
                disabled={hasNotEnoughMiles || !hasContact}
                onClick={onClickPlaceOrder}
              >
                {tt(intl, 'giftcard.placeorder')}
              </PrimaryButton>
            </ActionButtons>
          </PaddingContent>
        </CSLayout.CSFooter>
      </CSLayout.CSContainer>
      <ProfileEditEmailModal
        isOpen={isShowEditEmailModal}
        onClose={() => setIsShowEditEmailModal(false)}
      />
      <ProfileEditMobileModal
        isOpen={isShowEditMobileModal}
        onClose={() => setIsShowEditMobileModal(false)}
      />
      <GiftCardTNCModal
        isOpen={isShowTncModal}
        isShowRedemption={false}
        product={product}
        type="tnc"
        onClose={() => setIsShowTncModal(false)}
      />
    </BottomSheet>
  )
}

const StepSuccessModal = ({
  isOpen,
  productGroup,
  product,
  order,
  onClose,
}) => {
  const intl = useIntl()
  const history = useHistory()

  const profile = useSelector(profileSelector)
  const rateCurrencyToDVM = useSelector(getCurrencyToDVMSelector)

  if (!productGroup || !product || !order) return null
  const { currency } = product
  const rate = rateCurrencyToDVM.filter((r) => r.currency === currency)?.[0]
  const milesToBurn = Math.ceil(product.faceValue.amount / 100 / rate.buy) * 100
  const cardName = product.metadata.title
  const formattedPhone = PhoneParser(profile.tel).formatted

  const goHome = () => {
    history.push('/home')
  }

  return (
    <BottomSheet top open={isOpen}>
      <CSLayout.CSContainer>
        <CSLayout.CSContent>
          <PaddingContent>
            <Spacer height="2.22rem" />
            <Icon
              renderImage={() => <SuccessOutlinedSvg />}
              width="1.736rem"
              height="1.736rem"
            />
            <Spacer height="0.889rem" />
            <SectionTitle>{tt(intl, 'giftcard.orderreceived')}</SectionTitle>
            <Spacer height="0.889rem" />
            <SucessSubTitle>
              {tt(intl, 'giftcard.thankyouorder')}
              <br />
              {tt(intl, 'giftcard.orderprocessed')}
            </SucessSubTitle>
            <Spacer height="1.778rem" />
            <SuccessDetailsRow>
              <SuccessDetailsTitle>
                {tt(intl, 'order.merchantreference')}
              </SuccessDetailsTitle>
              <SuccessDetailsValue>
                {`#${order.merchantRef}`}
              </SuccessDetailsValue>
            </SuccessDetailsRow>
            <SuccessDetailsRow>
              <SuccessDetailsTitle>
                {tt(intl, 'giftcard.deliverto')}
              </SuccessDetailsTitle>
              <SuccessDetailsValue>
                {/* email first, then sms */}
                {profile.email_validated && <div>{profile.email}</div>}
                {!profile.email_validated && profile.tel_validated && (
                  <div>{formattedPhone}</div>
                )}
              </SuccessDetailsValue>
            </SuccessDetailsRow>
            <SuccessDetailsRow>
              <SuccessDetailsTitle>
                {tt(intl, 'giftcard.deliverstatus')}
              </SuccessDetailsTitle>
              <SuccessDetailsValue>
                {tt(intl, 'giftcard.onitsway')}
              </SuccessDetailsValue>
            </SuccessDetailsRow>
            <Spacer height="0.889rem" />
            <Line />
            <Spacer height="0.889rem" />
            <ProductDetails>
              <ProductQty>1x</ProductQty>
              <CardNameDetails>
                <CardName>{cardName}</CardName>
                {false && <CardValid>valid for 36 months</CardValid>}
              </CardNameDetails>
              <ProductCardMilesValue
                fontSize="0.778rem"
                miles={milesToBurn}
                isSigned={false}
              />
            </ProductDetails>
            <Spacer height="0.889rem" />
            <Line />
            <Spacer height="0.889rem" />
            <CheckoutRow>
              <CheckoutText>{tt(intl, 'payment.youpaid')}</CheckoutText>
              <ProductMilesValue
                fontSize="1.111rem"
                miles={order.milesBurned * 100}
                isSigned={false}
              />
            </CheckoutRow>
          </PaddingContent>
        </CSLayout.CSContent>
        <CSLayout.CSFooter>
          <PaddingContent>
            <ActionButtons>
              <SecondaryButton onClick={goHome}>
                {tt(intl, 'common.homepage')}
              </SecondaryButton>
              <SecondaryButton onClick={onClose}>
                {tt(intl, 'giftcard.spendmore')}
              </SecondaryButton>
            </ActionButtons>
          </PaddingContent>
        </CSLayout.CSFooter>
      </CSLayout.CSContainer>
    </BottomSheet>
  )
}

const DivitMilesSpend = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const divitMiles = useSelector(divitMilesSelector)

  // init -> cards -> confirm -> success
  const orderTimer = useRef(null)
  const [step, setStep] = useState('init')
  const [productGroup, setProductGroup] = useState(null)
  const [product, setProduct] = useState(null)
  const [createOrderError, setCreateOrderError] = useState(null)
  const [isShowMilesBalanceGuide, setIsShowMilesBalanceGuide] = useState(false)

  const { createOrder, getOrder } = useSelector((s) => s.checkoutGiftCard)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initDivitMilesSpend' },
  })

  useEffect(() => () => dispatch({ type: 'checkoutGiftCard/reset' }), [])

  useEffect(() => {
    if (createOrder.isSuccess) {
      clearInterval(orderTimer.current)
      dispatch({
        type: 'checkoutGiftCard/getOrder',
        payload: { orderId: createOrder.data.orderID },
      })
      setStep('success')
    }
    if (createOrder.isError) {
      setCreateOrderError(createOrder.error)
    }
    return () => clearInterval(orderTimer.current)
  }, [createOrder])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickProductItem = (group) => {
    setProductGroup(group)
    setStep('cards')
  }

  const onClickCardItem = (p) => {
    setProduct(p)
    setStep('confirm')
  }

  const onClickPlaceOrder = async () => {
    const { pin, isSuccess } = await dispatch(startRequestPinFlow())

    if (!isSuccess) return

    dispatch({
      type: 'checkoutGiftCard/createOrder',
      payload: {
        pin,
        products: [
          {
            productID: product.productID,
            productTitle: product.metadata.title,
            qty: 1,
            faceValue: product.faceValue,
          },
        ],
      },
    })
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const onClickStepBack = () => {
    setCreateOrderError(null)

    if (step === 'cards') {
      setStep('init')
      setProductGroup(null)
      setProduct(null)
    } else if (step === 'confirm') {
      setStep('cards')
    } else if (step === 'success') {
      setStep('init')
      setProductGroup(null)
      setProduct(null)
    }
  }

  const { isLoading } = createOrder

  return (
    <Container>
      <HeaderBg />
      <Header>
        <Spacer height="1.33rem" />
        <BackButton type="row" onClick={onClickBack} />
        <PaddingContent>
          <Spacer height="0.889rem" />
          <TitleContainer onClick={() => setIsShowMilesBalanceGuide(true)}>
            <PageTitle>{tt(intl, 'spend.totalmilesbalance')}</PageTitle>
            <Spacer width="8px" />
            <Icon
              renderImage={() => <GuideIconSvg />}
              width="1rem"
              height="1rem"
            />
          </TitleContainer>
          <Spacer height="8px" />
          <MilesBalance onClick={() => setIsShowMilesBalanceGuide(true)}>
            <Icon
              renderImage={() => <DivitMilesBWSvg />}
              width="1.778rem"
              height="1.778rem"
            />
            <Spacer width="0.5rem" />
            <MilesBalanceText>
              {FormattedMiles(divitMiles.balance)}
            </MilesBalanceText>
          </MilesBalance>
          <Spacer height="2.222rem" />
        </PaddingContent>
      </Header>
      {step === 'init' && <StepProducts onClickItem={onClickProductItem} />}
      {step !== 'init' && (
        <StepCards
          productGroup={productGroup}
          onClickItem={onClickCardItem}
          onClickBack={onClickStepBack}
        />
      )}
      <StepConfirmModal
        isOpen={step === 'confirm'}
        productGroup={productGroup}
        product={product}
        error={createOrderError}
        onClickPlaceOrder={onClickPlaceOrder}
        onClose={onClickStepBack}
      />
      <StepSuccessModal
        isOpen={step === 'success'}
        productGroup={productGroup}
        product={product}
        order={getOrder.data}
        onClose={onClickStepBack}
      />
      <MilesBalanceGuideModal
        isOpen={isShowMilesBalanceGuide}
        onClose={() => setIsShowMilesBalanceGuide(false)}
      />
      <LoadingModal loading={isLoading} />
    </Container>
  )
}

export default DivitMilesSpend
