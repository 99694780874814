import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import * as OrderUtils from '@/utils/OrderPaynow'

const Container = styled.div``

const List = styled.div``

const ListItem = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.333rem 1.111rem;
  margin-bottom: 1.77rem;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.08);
`

const OrderReference = styled.div`
  font-size: 0.667rem;
`

const PayText = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 0.778rem;
  }
`

const CancelButton = styled(Button)`
  font-weight: 700;
  min-width: unset;
  height: auto;
  background-color: white;
  color: #1c1c1c;
  font-size: 0.555rem;
  padding: 0.444rem 1.111rem;
  border: 1px solid #dedede;
`

const PayButton = styled(Button)`
  font-weight: 700;
  min-width: unset;
  height: auto;
  background-color: #1c1c1c;
  color: white;
  font-size: 0.555rem;
  padding: 0.444rem 1.111rem;
`

const PaynowProcessOrderList = ({ orders, onPay, onCancel }) => {
  const intl = useIntl()

  const onClickResume = (order) => (e) => {
    e.stopPropagation()
    onPay(order)
  }

  const onClickCancel = (order) => (e) => {
    e.stopPropagation()
    onCancel(order)
  }

  return (
    <Container>
      <List>
        {orders.map((order) => {
          const outstandingAmount = OrderUtils.getTotalOutstandingAmount(order)
          const merchantRef = OrderUtils.getPartnerRef(order)
          const isUnderpay = OrderUtils.isUnderPay(order)
          const isShowCancel = !isUnderpay

          return (
            <ListItem key={order.orderID}>
              <OrderReference>
                {tt(intl, 'order.merchantreference')}: {merchantRef}
              </OrderReference>
              <Spacer height="0.889rem" />
              <PayText>{tt(intl, 'payment.youpay')}</PayText>
              <Spacer height="0.222rem" />
              <MilesAndAmountValue
                fontSize="1.333rem"
                currency={outstandingAmount.currency}
                amount={outstandingAmount.amount}
                miles={order.milesBurned}
              />
              <Spacer height="0.889rem" />
              <Buttons>
                {isShowCancel && (
                  <CancelButton onClick={onClickCancel(order)}>
                    {tt(intl, 'common.cancel')}
                  </CancelButton>
                )}
                <PayButton onClick={onClickResume(order)}>
                  {tt(intl, 'common.resume')}
                </PayButton>
              </Buttons>
            </ListItem>
          )
        })}
      </List>
    </Container>
  )
}

export default PaynowProcessOrderList
