import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'

const Slideshow = styled(Carousel)`
  .carousel .control-dots {
    position: relative;
    margin-top: 0;
    padding: 0;
  }

  .carousel .control-dots .dot {
    background-color: rgba(255, 204, 51, 0.5);
    box-shadow: none;
    opacity: 1;
    margin: 0 0.222rem;
    border-radius: 8px;
    transition: all 0.25s ease-in;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background-color: #ffcc33;
  }

  .carousel .control-dots .dot.selected {
    width: 2.222rem;
    height: 8px;
    border-radius: 8px;
  }
`

const SlideShow = ({ images, onChange, ...props }) => {
  const [selectedItem, setSelectedItem] = useState(0)
  return (
    <Slideshow
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      selectedItem={selectedItem}
      infiniteLoop
      swipeable
      preventMovementUntilSwipeScrollTolerance
      onChange={(idx) => {
        onChange?.(idx)
        setSelectedItem(idx)
      }}
      onClickItem={(idx) => {
        onChange?.(idx)
        setSelectedItem(idx)
      }}
      {...props}
    >
      {images.map((img) => img.content)}
    </Slideshow>
  )
}

export default SlideShow
