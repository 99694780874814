import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorScreen from '@/components/error/ErrorScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import NormalMessageBox from '@/components/NormalMessageBox'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import * as Instalments from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import { IsPaylatering } from '@/utils/Route'

// show why order / instalment is invalid, and redirect
const PaymentCheck = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { orderId, instalmentId } = useParams()

  const { order } = useSelector((state) => state.order)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  // TODO: refactor it, wait until the new order is fetched
  if (order.orderid !== orderId) return <></>

  const currentInstalment = order.instalments.find(
    (instalment) => instalment.instalmentID === instalmentId
  )
  const instalmentPaymentStatus = Instalments.getPaymentStatus(
    currentInstalment
  )
  const isCompleted = instalmentPaymentStatus === 'completed'
  const orderIsExpired = Order.isExpired(order)
  const orderIsCancelled = Order.isCancelled(order)

  const goSummary = () => {
    history.push(`/paylater/summary/${orderId}`)
  }

  const goPaymentFailure = () => {
    if (IsPaylatering(location.pathname)) {
      window.location.href = order.webhook_failure
    } else {
      dispatch({ type: 'redirect/scheduleReview', payload: { order } })
    }
  }

  if (orderIsExpired) {
    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={intl.formatMessage({ id: 'error.order.expired' })}
        onOk={goPaymentFailure}
        okText={intl.formatMessage({ id: 'button.continue' })}
      />
    )
  }

  if (orderIsCancelled) {
    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={intl.formatMessage({ id: 'error.order.cancelled' })}
        onOk={goPaymentFailure}
        okText={intl.formatMessage({ id: 'button.continue' })}
      />
    )
  }

  if (isCompleted) {
    if (IsPaylatering(location.pathname)) {
      return (
        <Redirect to={`/paylater/payment/status/${orderId}/${instalmentId}`} />
      )
    }
    return <Redirect to={`/payment/status/${orderId}/${instalmentId}`} />
  }

  let MessageBox = () => (
    <NormalMessageBox
      buttonText={intl.formatMessage({ id: 'button.retry' })}
      onClick={retry}
    >
      {intl.formatMessage({ id: 'error.order.status.invalid' })}
    </NormalMessageBox>
  )

  if (!order.acceptTNC) {
    MessageBox = () => (
      <NormalMessageBox
        buttonText={intl.formatMessage({ id: 'button.back' })}
        onClick={goSummary}
      >
        {intl.formatMessage({ id: 'error.order.agree.terms' })}
      </NormalMessageBox>
    )
  }

  return (
    <PaddingContent>
      <Spacer height="2.222rem" />
      <MessageBox />
    </PaddingContent>
  )
}

export default PaymentCheck
