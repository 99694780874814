import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import LoadingModal from '@/components/LoadingModal'
import { useAuth } from '@/contexts/authContext'
import useUpdateEffect from '@/hooks/useUpdateEffect'

// token => initialize app
// no token => signin page

const LoginRedirect = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authUserID } = useAuth()

  const { initAppAgain } = useSelector((s) => s.pageInit)

  useUpdateEffect(() => {
    if (initAppAgain.isSuccess) {
      dispatch({ type: 'redirectReturnUrlOrHome' })
    }
    if (initAppAgain.isError) {
      // invalidate token
      dispatch({ type: 'logout', payload: { isNormalLogout: false } })
      setTimeout(() => history.replace('/signin'), 500)
    }
  }, [initAppAgain])

  useEffect(() => {
    if (authUserID) {
      dispatch({ type: 'pageInit/initAppAgain' })
    } else {
      history.replace('/signin')
    }
  }, [])

  return <LoadingModal loading />
}

export default LoginRedirect
