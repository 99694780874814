// import * as DivitMiles from '@/utils/DivitMiles'
// import * as OrderPaynow from '@/utils/OrderPaynow'
import { DivitMiles, OrderPaynow } from '@divit-stem/divit-orderjs'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Line from '@/components/Line'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.333rem 1.111rem;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(28, 28, 28, 0.08);
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.444rem 0;
  box-sizing: border-box;
  font-size: 0.778rem;
  font-weight: ${({ bold }) => bold && 600};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`

const RowBonusMiles = styled.div`
  background-color: #fffaea;
  padding: 0.56rem;
  border-radius: 8px;
`

const RowLeft = styled.div`
  flex: 1;
  text-align: left;
  font-weight: 500;
`

const RowRight = styled.div`
  text-align: right;
  font-weight: 600;
`

const OrderNumber = styled.div`
  font-size: 0.667rem;
`

const OrderPaidAmount = styled.div``

const OrderPayText = styled.div`
  color: ${(p) => (p.isError ? '#E6171A' : '#1c1c1c')};
  font-size: 0.7778rem;
  font-weight: 500;
`
const RevisedOrderAmount = styled.div`
  text-decoration: line-through;
`

const OrderSummary = ({ order, orderMiles }) => {
  const intl = useIntl()

  const {
    getPartnerRef,
    getOrderAmount,
    getTotalPaidAmount,
    isRefundPending,
    isRefunded,
    getTotalRefundedAmount,
    getTotalOffsetAmount,
    getMilesBurned,
  } = OrderPaynow(order)

  const {
    getMilesEarned,
    getBonusEvents,
    getTotalMilesCancelled,
    getTotalMilesRefunded,
  } = DivitMiles(order, orderMiles)

  const partnerRef = getPartnerRef()
  const totalOrderAmount = getOrderAmount()
  const totalPaidAmount = getTotalPaidAmount()
  const totalMilesEarned = getMilesEarned()
  const bonusEvents = getBonusEvents()

  const isRefundPendingBoolean = isRefundPending()
  const isRefundedBoolean = isRefunded()
  const refundAmount = getTotalRefundedAmount()
  const cancelledAmount = getTotalOffsetAmount()
  const cancelledMiles = getTotalMilesCancelled()
  const refundedMiles = getTotalMilesRefunded()

  // display paid / refund
  const isRefundOver = refundAmount.amount > 0

  // display values
  let payText = tt(intl, 'payment.youpaid')
  let payAmount = totalPaidAmount.amount - refundAmount.amount
  let payMiles = getMilesBurned() * 100
  const earnMiles = Math.max(0, totalMilesEarned - cancelledMiles)

  if (isRefundOver) {
    if (isRefundPendingBoolean) {
      payText = tt(intl, 'payment.yourefundpending')
      payAmount = refundAmount.amount
      payMiles = refundedMiles
    } else if (isRefundedBoolean) {
      payText = tt(intl, 'payment.yourefunded')
      payAmount = refundAmount.amount
      payMiles = refundedMiles
    }
  }

  return (
    <Container>
      <OrderNumber>
        {tt(intl, 'order.merchantreference')}: {partnerRef}
      </OrderNumber>
      <Spacer height="0.889rem" />
      <OrderPaidAmount>
        <OrderPayText isError={isRefundOver}>{payText}</OrderPayText>
        <Spacer height="0.222rem" />
        <MilesAndAmountValue
          fontSize="1.333rem"
          currency={totalPaidAmount.currency}
          amount={payAmount}
          miles={payMiles / 100}
        />
      </OrderPaidAmount>
      {earnMiles > 0 && (
        <Row>
          <RowLeft>{tt(intl, 'payment.youearned')}</RowLeft>
          <RowRight>
            <MilesValue miles={earnMiles} isSigned={false} />
          </RowRight>
        </Row>
      )}
      {bonusEvents.length > 0 && (
        <RowBonusMiles>
          {bonusEvents.map((event) => (
            <Row>
              <RowLeft>{intl.formatMessage({ id: event.msg })}</RowLeft>
              <RowRight>
                <MilesValue miles={event.amount} isSigned={false} />
              </RowRight>
            </Row>
          ))}
        </RowBonusMiles>
      )}
      <Spacer height="0.889rem" />
      <Line />
      <Spacer height="0.889rem" />
      <Row>
        <RowLeft>{tt(intl, 'payment.ordertotal')}</RowLeft>
        <RowRight>
          {cancelledAmount.amount === 0 && (
            <>
              {FormattedPrice(
                totalOrderAmount.currency,
                totalOrderAmount.amount
              )}
            </>
          )}
          {cancelledAmount.amount !== 0 && (
            <RevisedOrderAmount>
              {FormattedPrice(
                totalOrderAmount.currency,
                totalOrderAmount.amount
              )}
            </RevisedOrderAmount>
          )}
        </RowRight>
      </Row>
      {cancelledAmount.amount !== 0 && (
        <Row>
          <RowLeft>&nbsp;</RowLeft>
          <RowRight>
            {FormattedPrice(
              totalOrderAmount.currency,
              totalOrderAmount.amount - cancelledAmount.amount
            )}
          </RowRight>
        </Row>
      )}
    </Container>
  )
}

export default OrderSummary
