import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import { ReactComponent as InfoSvg } from '@/assets/common/info.svg'
import { tt } from '@/locales/format'

const InfoIcon = styled(InfoSvg)`
  width: 0.889rem;
  height: auto;
  margin-left: 0.333rem;
`

const ToolTip = styled(ReactTooltip)`
  &.type-dark.place-top {
    border-radius: 5px;
    font-size: 0.625rem;
    padding: 0.625rem 0.875rem;
  }
`

const ToolTipRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.125rem;
`

const TickIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheck,
})`
  background: ${({ theme }) => theme.message.success};
  color: white;
  border-radius: 50%;
  margin-right: 5px;
  width: 0.5rem !important;
  height: 0.5rem;
  padding: 0.1875rem;
`

const CrossIcon = styled(FontAwesomeIcon).attrs({
  icon: faTimes,
})`
  background: ${({ theme }) => theme.message.error};
  color: white;
  border-radius: 50%;
  margin-right: 5px;
  width: 8px !important;
  height: 8px;
  padding: 2px;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    width: 9px !important;
    height: 9px;
    padding: 3px;
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 10px !important;
    height: 10px;
    padding: 3px;
  }
`

const PasswordValidationToolTip = ({
  toolTipRef,
  hasNumber,
  hasUppercase,
  hasLowercase,
  hasSymbol,
  hasSufficientLength,
}) => {
  const intl = useIntl()

  return (
    <>
      <InfoIcon ref={toolTipRef} data-tip="tooltip" />
      <ToolTip place="top" type="dark" effect="float">
        <ToolTipRow>
          {hasSufficientLength && <TickIcon />}
          {!hasSufficientLength && <CrossIcon />}
          <div>{tt(intl, 'password.valid.length8')}</div>
        </ToolTipRow>
        <ToolTipRow>
          {hasNumber && <TickIcon />}
          {!hasNumber && <CrossIcon />}
          <div>{tt(intl, 'password.valid.number')}</div>
        </ToolTipRow>
        <ToolTipRow>
          {hasLowercase && <TickIcon />}
          {!hasLowercase && <CrossIcon />}
          <div>{tt(intl, 'password.valid.lowercase')}</div>
        </ToolTipRow>
        <ToolTipRow>
          {hasUppercase && <TickIcon />}
          {!hasUppercase && <CrossIcon />}
          <div>{tt(intl, 'password.valid.uppercase')}</div>
        </ToolTipRow>
        <ToolTipRow>
          {hasSymbol && <TickIcon />}
          {!hasSymbol && <CrossIcon />}
          <div>{tt(intl, 'password.valid.symbol')}</div>
        </ToolTipRow>
      </ToolTip>
    </>
  )
}

export default PasswordValidationToolTip
