import styled from 'styled-components'

const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
`

export default Line
