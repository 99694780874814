import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as ArrowSvg } from '@/assets/profile/profile-arrow.svg'
import { ReactComponent as ContactUsSvg } from '@/assets/profile/profile-contact-us.svg'
import { ReactComponent as GiftCardSvg } from '@/assets/profile/profile-gift-card.svg'
import { ReactComponent as LogoutSvg } from '@/assets/profile/profile-logout.svg'
import { ReactComponent as NotificationSvg } from '@/assets/profile/profile-notification.svg'
import { ReactComponent as PeronalInformationSvg } from '@/assets/profile/profile-personal.svg'
import { ReactComponent as SettingsSvg } from '@/assets/profile/profile-settings.svg'
import Icon from '@/components/Icon'
import { tt } from '@/locales/format'
import { startRequestPinFlow, startSetupPinFlow } from '@/redux/pin/pinActions'
import { isTestEnv } from '@/utils/env'

const SettingList = styled.div``

const SettingListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.333rem 0;
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
  cursor: pointer;
`

const ItemTitle = styled.div`
  flex: 1;
  font-size: 0.889rem;
  font-weight: 600;
  margin: 0 0.889rem;
`

const routeList = [
  {
    key: 'personal-information',
    name: 'profile.personalinformation',
    icon: PeronalInformationSvg,
    path: '/profile/personal',
  },
  {
    key: 'settings',
    name: 'profile.settings',
    icon: SettingsSvg,
    path: '/profile/settings',
  },
  {
    key: 'notifications',
    name: 'profile.notifications',
    icon: NotificationSvg,
    path: '/profile/notifications',
  },
  {
    key: 'gift-cards',
    name: 'profile.mygiftcards',
    icon: GiftCardSvg,
    path: '/profile/gift-cards',
  },
  {
    key: 'contact-us',
    name: 'profile.contactus',
    icon: ContactUsSvg,
    path: '/profile/change-password',
  },
  {
    key: 'logout',
    name: 'common.logout',
    icon: LogoutSvg,
    path: '',
  },
].filter((s) => s)

const ProfileSettingList = ({ onClickItem }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const onClickSettingItem = (item) => () => onClickItem(item)

  const validatePin = async () => {
    const { isSuccess } = await dispatch(startRequestPinFlow())
    console.log('request pin', isSuccess)
  }

  const setupPin = async () => {
    const { isSuccess } = await dispatch(startSetupPinFlow())
    console.log('setup pin', isSuccess)
  }

  return (
    <SettingList>
      {routeList.map((item) => {
        const ItemIcon = item.icon
        return (
          <SettingListItem key={item.name} onClick={onClickSettingItem(item)}>
            <Icon renderImage={() => <ItemIcon />} width="1rem" height="1rem" />
            <ItemTitle>{tt(intl, item.name)}</ItemTitle>
            <Icon
              renderImage={() => <ArrowSvg />}
              width="1.111rem"
              height="1.111rem"
            />
          </SettingListItem>
        )
      })}
      {isTestEnv && (
        <SettingListItem key="validate-pin" onClick={validatePin}>
          <ItemTitle>[test] validate pin</ItemTitle>
        </SettingListItem>
      )}
      {isTestEnv && (
        <SettingListItem key="setup-pin" onClick={setupPin}>
          <ItemTitle>[test] setup pin</ItemTitle>
        </SettingListItem>
      )}
    </SettingList>
  )
}

export default ProfileSettingList
