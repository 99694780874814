import './index.css'

import * as Sentry from '@sentry/react'
import FastClick from 'fastclick'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'

import BaseAppLayout from '@/components/BaseAppLayout'
import Providers from '@/contexts'
import { LocaleProvider } from '@/contexts/localeContext'
import Error from '@/pages/Error'

import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release:
      process.env.REACT_APP_ENV === 'dev'
        ? process.env.REACT_APP_GIT_SHA
        : process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENV,
  })
}

// Fix CSP by adding nonce to styled-component
// Reference - https://github.com/styled-components/styled-components/issues/887#issuecomment-376858712
// eslint-disable-next-line
__webpack_nonce__ = 'divit'

console.log(`env: ${process.env.REACT_APP_ENV || 'dev'}`)
console.log(
  `version: ${process.env.REACT_APP_VERSION} (${
    process.env.REACT_APP_GIT_SHA || 'dev'
  })`
)

// error page that no provider can be used
const ErrorPage = () => (
  <LocaleProvider>
    <BaseAppLayout>
      <Error />
    </BaseAppLayout>
  </LocaleProvider>
)

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW,
})

// fix ios 100vh
const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  doc.style.setProperty('--app-height-s', `${window.innerHeight - 40}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

// fast click
FastClick.attach(document.body)

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ErrorPage}>
    <Providers>
      <App />
    </Providers>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
