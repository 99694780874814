// priority to get order id:
// - url params
// - session token

export const setPartnerInfo = (orderId, { merchant, expiredAt }) =>
  localStorage.setItem(
    'merchant',
    JSON.stringify({
      o: orderId,
      m: merchant,
      e: expiredAt && parseInt(expiredAt, 10),
    })
  )

export const getPartnerInfo = (orderId) => {
  const checkoutOrderId = sessionStorage.getItem('checkout_order')
  const merchant = localStorage.getItem('merchant') || undefined
  if (merchant) {
    try {
      const j = JSON.parse(merchant)
      if (j.o === orderId || j.o === checkoutOrderId) {
        return j
      }
    } catch (e) {
      return undefined
    }
  }
  return undefined
}

export const getPartnerName = (orderId) => getPartnerInfo(orderId)?.m

export const getExpiredAt = (orderId) => getPartnerInfo(orderId)?.e
