import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import TransactionEmptySvg from '@/assets/miles/transaction-empty.svg'
import Icon from '@/components/Icon'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import { MERCHANT_ICONS } from '@/constants/merchants'
import { tt } from '@/locales/format'
import { merchantsSelector } from '@/redux/lookup/lookupSelector'
import { profileSelector } from '@/redux/profile/profileSelector'
import * as DivitMiles from '@/utils/DivitMiles'
import * as GiftCard from '@/utils/GiftCard'

const Container = styled.div``

const List = styled.div``

const EmptyContainer = styled.div`
  width: 10.555rem;
  text-align: center;
  margin: 0 auto;
`

const EmptyText = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
  text-align: center;
  margin: 0 auto;
`

const Group = styled.div``

const GroupMonthTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const GroupTitle = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 0.778rem;
  font-weight: 600;
`

const Item = styled.div`
  font-size: 0.778rem;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;

  &:first-child {
    border-top: 1px solid #f0f0f0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom: 1px solid #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const ItemContent = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &:last-child {
    padding-bottom: 1rem;
  }
`

const ItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.222rem;
  height: 3.222rem;
  border: 1px solid rgba(28, 28, 28, 0.2);
  border-radius: 50%;
  background-color: white;
  overflow: hidden;

  & img {
    object-fit: contain;
  }
`

const Line = styled.div`
  display: block;
  width: 90%;
  height: 1px;
  margin-top: 1rem;
  background-color: ${(p) => p.backgroundColor || '#f0f0f0'};
  margin-left: auto;
  margin-right: auto;
`

const ItemLeft = styled.div`
  flex: 1;
  display: flex;
  margin-right: 0.444rem;
  align-items: center;
`

const ItemTitle = styled.div`
  font-size: 0.667rem;
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ItemMerchantTitle = styled.div`
  color: #000;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;
`

const ItemCreatedDate = styled.div`
  margin-top: 0.222rem;
  font-size: 0.667rem;
  color: #979797;
`

const ItemMilesValue = styled(MilesValue)`
  justify-content: flex-end;
  font-weight: 700;
`

const MilesTransactionHistory = ({ history, isLanding }) => {
  const intl = useIntl()

  const profile = useSelector(profileSelector)
  const { customerID } = profile
  const images = useSelector(merchantsSelector)

  const getMsg = (txn, txnRaw) => {
    const { type, msg } = txn
    const { metadata, triggeredBy, refType, targetID } = txnRaw

    if (!metadata) return tt(intl, msg)

    const { merchant, metadata: meta } = metadata

    // conversion
    if (type === 'convertOut') {
      return tt(intl, 'miles.scheme.miles.conversion.out', {
        program: merchant.merchantName,
      })
    }
    if (type === 'convertIn') {
      return tt(intl, 'miles.scheme.miles.conversion.in', {
        program: merchant.merchantName,
      })
    }

    // miles spend, show brand image
    if (refType === 'giftcard-order') {
      return tt(intl, 'giftcard.spent.on', {
        giftcard: meta.title,
      })
    }

    // miles burn
    if (type === 'burn') {
      return tt(intl, 'miles.burned.merchant', {
        merchant: merchant.merchantName,
      })
    }
    if (type === 'cancelBurn') {
      return tt(intl, 'miles.refund.merchant', {
        merchant: merchant.merchantName,
      })
    }

    // cancel earn
    if (type === 'cancelEarn') {
      return tt(intl, 'miles.miles.merchant.cancelled', {
        merchant: merchant.merchantName,
      })
    }

    // transfer
    if (type === 'transfer') {
      const isEarn = targetID === customerID
      if (isEarn) {
        return tt(intl, 'miles.scheme.transfer.from', {
          name: metadata?.metadata?.from,
        })
      }
      return tt(intl, 'miles.scheme.transfer.to', {
        name: metadata?.metadata?.to,
      })
    }

    if (triggeredBy === 'ClaimMiles') {
      return tt(intl, 'miles.bonus.merchant', {
        merchant: merchant.merchantName,
      })
    }

    if (triggeredBy === 'CompletedRegistration') {
      return tt(intl, 'miles.signup.merchant', {
        merchant: merchant.merchantName,
      })
    }

    // other messages
    if (msg !== 'miles.earned.default' && msg !== 'miles.burned.default') {
      return tt(intl, msg)
    }

    // earn miles
    if (
      merchant?.merchantName &&
      triggeredBy === 'OrderPaid' &&
      !DivitMiles.isBonusScheme(txnRaw)
    ) {
      return tt(intl, 'miles.scheme.miles.merchant', {
        merchant: merchant.merchantName,
      })
    }
    // game reward
    if (merchant?.merchantName && triggeredBy === 'GameReward') {
      return tt(intl, 'miles.scheme.games.merchant', {
        merchant: merchant.merchantName,
      })
    }

    return intl.formatMessage({ id: msg })
  }

  const getMerchantName = (txn, txnRaw) => {
    const { type, msg } = txn
    const { metadata } = txnRaw

    if (!metadata) return tt(intl, msg)

    const { merchant } = metadata

    return merchant.merchantName
  }

  const getRemark = (txn, txnRaw) => {
    const { type } = txn
    const { reason } = txnRaw

    // transfer
    if (
      (type === 'transfer' && reason !== '') ||
      reason.toLowerCase().indexOf('referrer') >= 0
    ) {
      return `${reason} - `
    }

    return ''
  }

  const getIcon = ({ metadata, triggeredBy, reason, refType }) => {
    let icon = MERCHANT_ICONS.DIVIT
    if (!metadata) return icon

    const { merchant, metadata: meta } = metadata

    // miles spend, show brand image
    if (refType === 'giftcard-order') {
      icon = GiftCard.getBrandImageUrl(meta.brand)
    }
    // payment earn miles, show merchant icon
    if (merchant && merchant.abbreviation) {
      const isShow =
        [
          'OrderPaid',
          'CancelEarnMiles',
          'BurnMiles',
          'CancelBurnMiles',
          'ClaimMiles',
          'GameReward',
          'GameFirstScore',
          'CompletedRegistration',
        ].indexOf(triggeredBy) >= 0
      if (isShow) {
        const merchantInfo = images.find(
          (m) => m.abbreviation === merchant.abbreviation
        )
        if (merchantInfo && merchantInfo.logo) {
          return `${process.env.REACT_APP_STATIC_URL}/merchants/${merchantInfo.logo}`
        }
      }
    }
    // miles conversion, show related miles icon
    if (triggeredBy === 'ConvertMiles') {
      if (
        ['transfer from youair miles', 'transfer to youair miles'].indexOf(
          reason.toLowerCase()
        ) >= 0
      ) {
        icon = MERCHANT_ICONS.YOUAIR
      }
      if (
        ['transfer from asia miles', 'transfer to asia miles'].indexOf(
          reason.toLowerCase()
        ) >= 0
      ) {
        icon = MERCHANT_ICONS.ASIAMILES
      }
    }
    return icon
  }

  const getMiles = (txn, txnRaw) => {
    let { isEarn } = txn
    if (txnRaw.txnType === 'transfer') {
      isEarn = txnRaw.targetID === customerID
    }
    return isEarn ? txn.amount : -txn.amount
  }

  if (history.length === 0) {
    return (
      <EmptyContainer>
        <EmptyText>{tt(intl, 'activity.empty')}</EmptyText>
        <Spacer height="2.222rem" />
        <img
          alt=""
          src={TransactionEmptySvg}
          style={{
            width: '8.778rem',
            height: '5.611rem',
          }}
        />
        <Spacer height="1.333rem" />
        <Spacer height="2.222rem" />
      </EmptyContainer>
    )
  }

  // group by date
  const groups = history.reduce((obj, txn) => {
    const date = moment(txn.raw.createdAt).format('YYYY-MM-DD')
    obj[date] = obj[date] || []
    obj[date].push(txn)
    return obj
  }, {})

  const formatGroup = (group) => {
    const today = moment().format('YYYY-MM-DD')
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')
    switch (group) {
      case today:
        return tt(intl, 'schedule.today')

      case yesterday:
        return tt(intl, 'landing.historyList.yesterday')

      default:
        return moment(group).format('DD MMMM,YYYY')
    }
  }

  return (
    <Container>
      <List>
        {Object.keys(groups).map((group) => (
          <Group key={group}>
            <GroupTitle>
              <GroupMonthTitle>{formatGroup(group)}</GroupMonthTitle>
            </GroupTitle>
            <Spacer height="0.778rem" />
            <List>
              {groups[group].map((txn, index) => {
                const icon = getIcon(txn.raw)
                const msg = getMsg(txn, txn.raw)
                const miles = getMiles(txn, txn.raw)
                const merchantName = getMerchantName(txn, txn.raw)
                return (
                  <Item key={txn.raw.txnID}>
                    <ItemContent>
                      <ItemIcon>
                        <Icon
                          renderImage={() => (
                            <img
                              alt=""
                              src={icon}
                              style={{ padding: '0.2rem' }}
                            />
                          )}
                          width="3.222rem"
                          height="3.222rem"
                        />
                      </ItemIcon>
                      <Spacer width="0.66rem" />
                      <ItemLeft>
                        {isLanding ? (
                          <ItemMerchantTitle>{merchantName}</ItemMerchantTitle>
                        ) : (
                          <ItemTitle>{msg}</ItemTitle>
                        )}
                      </ItemLeft>
                      <ItemMilesValue miles={miles} isSigned />
                    </ItemContent>
                    {groups[group].length > 1 &&
                      index !== groups[group].length - 1 && <Line />}
                  </Item>
                )
              })}
            </List>
          </Group>
        ))}
      </List>
    </Container>
  )
}

export default MilesTransactionHistory
