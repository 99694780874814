import { unescape } from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'

import { desktop } from '@/utils/Styles'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${desktop(css`
    grid-template-columns: repeat(3, 1fr);
  `)};
  grid-gap: 0.889rem;
`

const Item = styled.div`
  cursor: pointer;
`

const ItemImage = styled.img`
  width: 100%;
  border-radius: 0.444rem;
`

const ItemDetails = styled.div`
  text-align: center;
`

const ItemName = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
  text-align: left;
`

const ProductCategoryGrid = ({ categories = [], onClickItem }) => {
  const onClickProductCategory = (c) => () => {
    onClickItem(c)
  }

  return (
    <Grid>
      {categories.map((c) => (
        <Item key={c.id} onClick={onClickProductCategory(c)}>
          <ItemImage src={c.image?.src} />
          <ItemDetails>
            <ItemName>{unescape(c.name)}</ItemName>
          </ItemDetails>
        </Item>
      ))}
    </Grid>
  )
}

export default ProductCategoryGrid
