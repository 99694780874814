import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as OverdueSvg } from '@/assets/common/overdue.svg'
import { ReactComponent as OverdueWarningSvg } from '@/assets/common/overdue-warning.svg'
import Box from '@/components/Box'
import { Button } from '@/components/Divit'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import InstalmentsInfoCollapsable from '@/components/InstalmentsInfoCollapsable'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import usePage from '@/hooks/usePage'
import { payForPaylater } from '@/redux/pages/payActions'
import colors from '@/themes/colors'
import * as Instalments from '@/utils/Instalments'
import { FormattedPrice } from '@/utils/Price'

const Container = styled(PaddingContent)`
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
`

const Icon = styled.div`
  display: block;
  text-align: center;
  margin: 2rem 0 1.125rem;
`

const OverdueErrorIcon = styled(OverdueSvg)`
  width: 6.8rem;
  height: auto;
`

const OverdueWarningIcon = styled(OverdueWarningSvg)`
  width: 6.8rem;
  height: auto;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.875rem;

  ${(props) => {
    if (props.isError) {
      return `color: ${colors.divitRed};`
    }
    if (props.isWarning) {
      return `color: ${colors.divitLightPurple};`
    }
    return ``
  }};
`

const Description = styled.div`
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 1.375rem;
`

const OrderBox = styled(Box)`
  margin-bottom: 1.25rem;
  padding: 1.25rem calc(100% / 12);
  width: calc(100% / 12 * 10 - 2px);
  font-size: 0.75rem;
`

const PayButton = styled(Button)``

const Overdue = () => {
  const intl = useIntl()
  const { orderId } = useParams()
  const location = useLocation()
  const params = queryString.parse(location.search)
  const mockDate = params.mock || undefined
  const dispatch = useDispatch()

  const { order, orderMiles } = useSelector((state) => state.order)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const { instalments } = order

  const outstandingInstalment = Instalments.getOutstandingInstalment(
    instalments
  )

  if (!outstandingInstalment) return <div>No overdue payment</div>

  const orderStatus = Instalments.getOrderStatus(instalments)
  const instalmentTotalAmount = Instalments.getTotalOutstandingAmount(
    outstandingInstalment
  )
  const paid = outstandingInstalment.status.toLowerCase() === 'paid'
  const due = moment(mockDate).isAfter(
    moment.unix(outstandingInstalment.dueDate),
    'd'
  )
  const overdueDays =
    moment(mockDate).diff(moment.unix(outstandingInstalment.dueDate), 'd') + 1
  const severity = ((day) => {
    if (day >= 5) return 'error'
    if (day > 0) return 'warning'
    return 'info'
  })(overdueDays)
  const isError = severity === 'error'
  const isWarning = severity === 'warning'

  if (!due || paid) return <div />

  const onClickPayButton = (e) => {
    e.preventDefault()

    const { instalmentID: instalmentId } = outstandingInstalment
    dispatch(payForPaylater({ orderId, instalmentId }))
  }

  return (
    <Container>
      <Icon>
        {isError && <OverdueErrorIcon />}
        {isWarning && <OverdueWarningIcon />}
      </Icon>
      <Title isError={isError} isWarning={isWarning}>
        {isError && intl.formatMessage({ id: 'overdue.error.title' })}
        {isWarning && intl.formatMessage({ id: 'overdue.warning.title' })}
      </Title>
      <Description>
        {isError &&
          intl.formatMessage(
            { id: 'overdue.error.description' },
            {
              amount: (
                <b>
                  {FormattedPrice(
                    instalmentTotalAmount.currency,
                    instalmentTotalAmount.amount
                  )}
                </b>
              ),
            }
          )}
        {isWarning &&
          intl.formatMessage(
            { id: 'overdue.warning.description' },
            {
              series: <b>{outstandingInstalment.series}</b>,
              amount: (
                <b>
                  {FormattedPrice(
                    instalmentTotalAmount.currency,
                    instalmentTotalAmount.amount
                  )}
                </b>
              ),
            }
          )}
      </Description>
      <OrderBox>
        <InstalmentsInfoCollapsable
          order={order}
          orderMiles={orderMiles}
          showTotal
        />
      </OrderBox>
      {orderStatus.toLowerCase() !== 'completed' && (
        <PayButton type="stretch" onClick={onClickPayButton}>
          {intl.formatMessage({ id: 'button.pay' })}
        </PayButton>
      )}
    </Container>
  )
}

export default Overdue
