import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin-right: 0.4375rem;
  ${({ variant }) =>
    variant &&
    `
    margin-right: 0rem;

    input:hover + div {
      border-color: #CCC;
    }

    input:active + div,
    input:focus + div {
      border-color: #F7CE55;
    }`}
`

const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  border: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  cursor: pointer;

  ${({ variant }) =>
    variant
      ? `
      width: 1.11rem;
      height: 1.11rem;
    `
      : `
      width: 1rem;
      height: 1rem;
    `}
`

const EmptySquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  pointer-events: none;
  cursor: pointer;
  background-color: ${p => p.checked ? 'rgba(255, 204, 51, 1)' : 'white'};

  ${({ variant, theme }) =>
    variant
      ? `
      width: 1.11rem;
      height: 1.11rem;
      border-radius: 0.22rem;
    `
      : `
      width: 1rem;
      height: 1rem;
    `}
`

const TickIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCheck,
}))`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 1);
`

const Checkbox = ({ checked, variant, onChange, ...props }) => (
  <Container variant={variant}>
    <HiddenInput variant checked={checked} onChange={onChange} {...props} />
    <EmptySquare variant checked={checked}>{checked && <TickIcon />}</EmptySquare>
  </Container>
)

Checkbox.propTypes = {
  checked: PropTypes.bool,
  variant: PropTypes.bool,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  checked: false,
  variant: false,
  onChange: () => {},
}

export default Checkbox
