import moment from 'moment'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import ActivityEmptySvg from '@/assets/activity/empty.svg'
import ActivitySearchEmptySvg from '@/assets/activity/search-empty.svg'
import { ReactComponent as ArrowMoreSvg } from '@/assets/common/arrow-more.svg'
import Icon from '@/components/Icon'
import InfinityScroll from '@/components/InfinityScroll'
import LoadingSpinner from '@/components/LoadingSpinner'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import * as Pagination from '@/utils/pagination'

const Container = styled.div``

const EmptyContainer = styled.div`
  width: 10.555rem;
  text-align: center;
  margin: 0 auto;
`

const EmptyTitle = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
  text-align: center;
  margin: 0 auto;
`

const EmptyText = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
  line-height: 1.3em;
  text-align: center;
  margin: 0 auto;
`

const List = styled.div``

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.889rem 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);

  &:hover {
    cursor: pointer;
  }
`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const OrderTitle = styled.div`
  flex: 1;
  font-size: 0.667rem;
  font-weight: 600;

  & span:last-child {
    margin-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Date = styled.div`
  font-size: 0.667rem;
  font-weight: 500;
  color: #979797;
`

const PaynowOrderHistory = ({
  initTransactionId,
  position,
  payments,
  isSearching,
  onClickItem,
  onLoadMore,
}) => {
  const intl = useIntl()

  const paymentsTotal = useSelector((s) => s.order.paymentsTotal)
  const hasMorePayments = Pagination.hasMore(payments, paymentsTotal)

  useEffect(() => {
    if (initTransactionId) {
      const element = document.getElementById(initTransactionId)
      if (element) {
        const yOffset = -position
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  }, [])

  if (payments.length === 0 && isSearching) {
    return (
      <EmptyContainer>
        <Spacer height="2.222rem" />
        <EmptyTitle>{tt(intl, 'common.noresult')}</EmptyTitle>
        <Spacer height="1.333rem" />
        <img
          alt=""
          src={ActivitySearchEmptySvg}
          style={{
            width: '8.778rem',
            height: '5.611rem',
          }}
        />
        <Spacer height="1.333rem" />
        <EmptyText>{tt(intl, 'common.trykeyword')}</EmptyText>
        <Spacer height="2.222rem" />
      </EmptyContainer>
    )
  }

  if (payments.length === 0) {
    return (
      <EmptyContainer>
        <Spacer height="2.222rem" />
        <EmptyTitle>{tt(intl, 'activity.empty')}</EmptyTitle>
        <Spacer height="1.333rem" />
        <img
          alt=""
          src={ActivityEmptySvg}
          style={{
            width: '8.778rem',
            height: '5.611rem',
          }}
        />
        <Spacer height="1.333rem" />
        <EmptyText>{tt(intl, 'slogan.divitpay')}</EmptyText>
        <Spacer height="2.222rem" />
      </EmptyContainer>
    )
  }

  return (
    <Container>
      <List>
        {payments.map((payment) => {
          let pTitle = (
            <>
              <MerchantName merchantID={payment.merchantID} />
              <span>{` #${payment.merchantRef}`}</span>
            </>
          )
          let pCurrency = payment.refAmount?.currency
          let pAmount = payment.refAmount?.amount
          let pMiles = 0
          let pMerchantID = payment.merchantID
          let pGiftCardBrandCode = ''
          if (payment.transactionType === 'miles-conversion-in') {
            pTitle = tt(intl, 'miles.scheme.miles.conversion.in', {
              program: payment.metadata.partnerName,
            })
            pCurrency = ''
            pAmount = 0
            pMiles = parseInt(payment.amount?.amount / 100, 10)
            pMerchantID = payment.metadata?.partnerCode
          }
          if (payment.transactionType === 'miles-conversion-out') {
            pTitle = tt(intl, 'miles.scheme.miles.conversion.out', {
              program: payment.metadata.partnerName,
            })
            pCurrency = ''
            pAmount = 0
            pMiles = parseInt(payment.refAmount?.amount / 100, 10)
            pMerchantID = payment.metadata?.partnerCode
          }
          if (payment.transactionType === 'spend') {
            pTitle = tt(intl, 'giftcard.spent.on', {
              giftcard: payment.metadata.title,
            })
            pCurrency = ''
            pAmount = 0
            pMiles = parseInt(payment.amount?.amount / 100, 10)
            pGiftCardBrandCode = payment.metadata?.brand
          }
          return (
            <ListItem
              id={payment.transactionID}
              key={payment.transactionID}
              onClick={() => onClickItem(payment)}
            >
              <MerchantLogo
                merchantID={pMerchantID}
                giftCardBrandCode={pGiftCardBrandCode}
                width="2.2rem"
                height="2.2rem"
              />
              <Spacer width="0.66rem" />
              <Left>
                <OrderTitle>{pTitle}</OrderTitle>
                <Spacer height="0.222rem" />
                <Date>{moment(payment.transactionDate).format('LLL')}</Date>
              </Left>
              <Spacer width="1.111rem" />
              <Right>
                <MilesAndAmountValue
                  fontSize="0.667rem"
                  currency={pCurrency}
                  amount={pAmount}
                  miles={pMiles}
                  leftPlusSpace="2px"
                  rightPlusSpace="0px"
                />
              </Right>
              <Spacer width="0.444rem" />
              <Icon
                renderImage={() => <ArrowMoreSvg />}
                width="1.111rem"
                height="1.111rem"
              />
            </ListItem>
          )
        })}
        <Spacer height="1rem" />
        <InfinityScroll hasMore={hasMorePayments} loadMore={onLoadMore}>
          <LoadingSpinner />
        </InfinityScroll>
      </List>
    </Container>
  )
}

export default PaynowOrderHistory
