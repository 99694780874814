import React, { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
// import Spacer from '@/components/Spacer'
import Modal from '@/components/Modal'
import PaymentSettings from '@/constants/payment'

import Icon from '../Icon'
import FpsManualPaymentDetails from './FpsManualPaymentDetails'
import FpsPaymentGuide from './FpsPaymentGuide'
import FpsQRPaymentDetails from './FpsQRPaymentDetails'

const ModalContainer = styled.div`
  position: relative;
  min-width: 100%;
  margin: auto;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`

const PaddingContent = styled.div`
  background-color: white;
  border-radius: 0.6rem;
`

const PaymentGuideContent = styled.div`
  width: auto;
  padding: 0 4rem 2rem 4rem;

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.md}px`}) {
    padding: 0 2rem 2rem 2rem;
    height: auto;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.sm}px`}) {
    padding: 0 calc(100% / 20);
    height: auto;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.xs}px`}) {
    padding: 0 calc(100% / 25);
    height: auto;
    width: 100%;
  }
`

const CloseButton = styled.div`
  padding: 0.5rem 0.5rem 0 0.5rem;
  width: 100%;
  display: flex;
  justify-content: right;
  cursor: pointer;
`

export const FpsPaymentGuideModal = ({ isOpen, onClose, type }) => {
  const handleChildElementClick = (e) => {
    e.stopPropagation()
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalContainer onClick={onClose}>
        <PaddingContent onClick={(e) => handleChildElementClick(e)}>
          <CloseButton onClick={onClose}>
            <Icon renderImage={() => <CloseSvg />} width="2rem" height="2rem" />
          </CloseButton>
          <PaymentGuideContent>
            <FpsPaymentGuide type={type} onClose={onClose} />
          </PaymentGuideContent>
        </PaddingContent>
      </ModalContainer>
    </Modal>
  )
}

const FpsPaymentDetails = ({
  generateFpsLink,
  type,
  fpsDetails,
  bankListFps,
  disableManualFPS,
  isPosMode = false,
}) => {
  const [isShowGuideModal, setIsShowGuideModal] = useState(false)
  if (
    disableManualFPS ||
    type === PaymentSettings.PAYMENT_MODE_FPSQRCODE ||
    type === PaymentSettings.PAYMENT_MODE_PROMPTPAYQR ||
    type === PaymentSettings.PAYMENT_MODE_SGPAYNOWQR
  ) {
    return (
      <>
        <FpsQRPaymentDetails
          generateFpsLink={generateFpsLink}
          fpsDetails={fpsDetails}
          bankListFps={bankListFps}
          showGuide={() => setIsShowGuideModal(true)}
          isPosMode={isPosMode}
        />
        <FpsPaymentGuideModal
          type="qrcode"
          isOpen={isShowGuideModal}
          onClose={() => setIsShowGuideModal(false)}
        />
      </>
    )
  }
  if (type === PaymentSettings.PAYMENT_MODE_FPSMANUAL && !disableManualFPS) {
    return (
      <>
        <FpsManualPaymentDetails
          fpsDetails={fpsDetails}
          showGuide={() => setIsShowGuideModal(true)}
        />
        <FpsPaymentGuideModal
          type="manual"
          isOpen={isShowGuideModal}
          onClose={() => setIsShowGuideModal(false)}
        />
      </>
    )
  }
  return null
}

export default FpsPaymentDetails
