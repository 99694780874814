import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const paynowSlice = createSlice({
  name: 'paynow',
  initialState: {
    cachedFpsData: null,
    fetchOrder: AsyncState.create(),
    linkOrder: AsyncState.create(),
    updateDeliveryAddress: AsyncState.create(),
    burnMiles: AsyncState.create(),
    autoBurnMiles: AsyncState.create(),
    resetBurnMiles: AsyncState.create(),
    generateFpsLink: AsyncState.create(),
    payByEDDA: AsyncState.create(),
    refreshOrder: AsyncState.create(),
    fetchCompletedOrder: AsyncState.create(),
    cancelOrder: AsyncState.create(),
    cancelAllOrders: AsyncState.create(),
  },
  reducers: {
    cacheFpsData: (state, action) => {
      state.cachedFpsData = action.payload
    },
    clearCachedFpsData: (state) => {
      state.cachedFpsData = null
    },
    reset: (state) => {
      state.fetchOrder = AsyncState.reset()
      state.linkOrder = AsyncState.reset()
      state.updateDeliveryAddress = AsyncState.reset()
      state.burnMiles = AsyncState.reset()
      state.autoBurnMiles = AsyncState.reset()
      state.resetBurnMiles = AsyncState.reset()
      state.generateFpsLink = AsyncState.reset()
      state.payByEDDA = AsyncState.reset()
      state.refreshOrder = AsyncState.reset()
      state.fetchCompletedOrder = AsyncState.reset()
      state.cancelOrder = AsyncState.reset()
      state.cancelAllOrders = AsyncState.reset()
    },
    fetchOrderStart: (state) => {
      state.fetchOrder = AsyncState.start()
    },
    fetchOrderSuccess: (state, action) => {
      state.fetchOrder = AsyncState.success(action.payload)
    },
    fetchOrderFailed: (state, action) => {
      state.fetchOrder = AsyncState.failed(action.payload)
    },
    linkOrderStart: (state) => {
      state.linkOrder = AsyncState.start()
    },
    linkOrderSuccess: (state, action) => {
      state.linkOrder = AsyncState.success(action.payload)
    },
    linkOrderFailed: (state, action) => {
      state.linkOrder = AsyncState.failed(action.payload)
    },
    updateDeliveryAddressStart: (state) => {
      state.updateDeliveryAddress = AsyncState.start()
    },
    updateDeliveryAddressSuccess: (state, action) => {
      state.updateDeliveryAddress = AsyncState.success(action.payload)
    },
    updateDeliveryAddressFailed: (state, action) => {
      state.updateDeliveryAddress = AsyncState.failed(action.payload)
    },
    burnMilesStart: (state) => {
      state.burnMiles = AsyncState.start()
    },
    burnMilesSuccess: (state, action) => {
      state.burnMiles = AsyncState.success(action.payload)
    },
    burnMilesFailed: (state, action) => {
      state.burnMiles = AsyncState.failed(action.payload)
    },
    autoBurnMilesStart: (state) => {
      state.autoBurnMiles = AsyncState.start()
    },
    autoBurnMilesSuccess: (state, action) => {
      state.autoBurnMiles = AsyncState.success(action.payload)
    },
    autoBurnMilesFailed: (state, action) => {
      state.autoBurnMiles = AsyncState.failed(action.payload)
    },
    resetBurnMilesStart: (state) => {
      state.resetBurnMiles = AsyncState.start()
    },
    resetBurnMilesSuccess: (state, action) => {
      state.resetBurnMiles = AsyncState.success(action.payload)
    },
    resetBurnMilesFailed: (state, action) => {
      state.resetBurnMiles = AsyncState.failed(action.payload)
    },
    generateFpsLinkStart: (state) => {
      state.generateFpsLink = AsyncState.start()
    },
    generateFpsLinkSuccess: (state, action) => {
      state.generateFpsLink = AsyncState.success(action.payload)
    },
    generateFpsLinkFailed: (state, action) => {
      state.generateFpsLink = AsyncState.failed(action.payload)
    },
    payByEDDAStart: (state) => {
      state.payByEDDA = AsyncState.start()
    },
    payByEDDASuccess: (state, action) => {
      state.payByEDDA = AsyncState.success(action.payload)
    },
    payByEDDAFailed: (state, action) => {
      state.payByEDDA = AsyncState.failed(action.payload)
    },
    payByEDDAReset: (state) => {
      state.payByEDDA = AsyncState.reset()
    },
    refreshOrderStart: (state) => {
      state.refreshOrder = AsyncState.start()
    },
    refreshOrderSuccess: (state, action) => {
      state.refreshOrder = AsyncState.success(action.payload)
    },
    refreshOrderFailed: (state, action) => {
      state.refreshOrder = AsyncState.failed(action.payload)
    },
    fetchCompletedOrderStart: (state) => {
      state.fetchCompletedOrder = AsyncState.start()
    },
    fetchCompletedOrderSuccess: (state, action) => {
      state.fetchCompletedOrder = AsyncState.success(action.payload)
    },
    fetchCompletedOrderFailed: (state, action) => {
      state.fetchCompletedOrder = AsyncState.failed(action.payload)
    },
    cancelOrderStart: (state) => {
      state.cancelOrder = AsyncState.start()
    },
    cancelOrderSuccess: (state, action) => {
      state.cancelOrder = AsyncState.success(action.payload)
    },
    cancelOrderFailed: (state, action) => {
      state.cancelOrder = AsyncState.failed(action.payload)
    },
    cancelAllOrdersStart: (state) => {
      state.cancelAllOrders = AsyncState.start()
    },
    cancelAllOrdersSuccess: (state, action) => {
      state.cancelAllOrders = AsyncState.success(action.payload)
    },
    cancelAllOrdersFailed: (state, action) => {
      state.cancelAllOrders = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = paynowSlice

export default paynowSlice.reducer
