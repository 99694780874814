import React, { useState } from 'react'
import styled from 'styled-components'

import Icon from '@/components/Icon'
import { useLocale } from '@/contexts/localeContext'

const Wrapper = styled.div`
  background-color: white;
`

const Container = styled.div`
  position: relative;
  display: flex;
  border-bottom: 3px solid #f0f0f0;
`

const TabsWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
`

const SelectorTab = styled.div`
  position: relative;
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  padding: 0.667rem 0px;
  border-bottom: ${({ isActive }) => (isActive ? '3px solid #ffcc33' : '')};

  &:hover {
    cursor: pointer;
  }
`

const TabLabel = styled.div`
  font-size: 0.667rem;
  font-weight: 500;
  color: ${({ isActive }) => (isActive ? '#1C1C1C' : '#979797')};
  margin-top: 0.33rem;
`

const SelectorTabs = ({ defaultTab, tabs, onChange }) => {
  const defaultTabIndex = tabs.findIndex((t) => t.value === defaultTab)
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex)
  const { currentLocale } = useLocale()

  const onSelectTab = (index) => {
    setActiveTabIndex(index)
    onChange?.(tabs[index])
  }

  return (
    <Wrapper>
      <Container>
        <TabsWrapper>
          {tabs.map((tab, i) => (
            <SelectorTab
              key={i}
              onClick={() => onSelectTab(i)}
              isActive={i === activeTabIndex}
            >
              <Icon
                renderImage={() => <img src={tab.Icon} alt={tab.value} />}
                height="0.88rem"
              />
              <TabLabel isActive={i === activeTabIndex}>
                {tab[currentLocale] || tab.label}
              </TabLabel>
            </SelectorTab>
          ))}
        </TabsWrapper>
      </Container>
    </Wrapper>
  )
}

export default SelectorTabs
