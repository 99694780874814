const PaymentSettings = {
  APR_LIMIT: 48,
  FPS_SHOW_TIMEOUT_SECOND_LIMIT: 1800,

  PAYMENT_MODE_FPSQRCODE: 'fpsqrcode',
  PAYMENT_MODE_PROMPTPAYQR: 'promptpayqr',
  PAYMENT_MODE_SGPAYNOWQR: 'sgpaynowqr',
  PAYMENT_MODE_FPSMANUAL: 'manual',

  PAYMENT_METHOD_FPS: 'fps',
  PAYMENT_METHOD_PROMPTPAY: 'promptpay',
  PAYMENT_METHOD_SGPAYNOW: 'sgpaynow',
  CURRENCY_HKD: 'HKD',
  CURRENCY_THB: 'THB',
  CURRENCY_SGD: 'SGD',
  CURRENCY_INR: 'INR',
  CURRENCY_TO_PAYMENT_METHOD: {
    HKD: 'fps',
    THB: 'promptpay',
    SGD: 'sgpaynow',
  },
}

export default PaymentSettings
