import { useEffect, useRef } from 'react'

// useEffect but ignored first attempt

const useUpdateEffect = (fn, deps = []) => {
  const mounted = useRef(false)

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return null
    }
    return fn?.()
  }, deps)
}

export default useUpdateEffect
