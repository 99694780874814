import { put } from 'redux-saga/effects'

import * as DivitMilesSagas from '@/redux/divitMiles/divitMilesSaga'
import { fetchMerchant } from '@/redux/lookup/lookupSaga'

import { getError } from '../utils/error'
import { actions as claimDivitMilesActions } from './claimDivitMilesSlice'

export function* previewClaim({ payload }) {
  try {
    const { token, accessToken } = payload
    yield put(claimDivitMilesActions.previewClaimStart())
    const { data } = yield DivitMilesSagas.fetchDivitMilesPreviewClaim(
      accessToken ? { token, accessToken } : { token }
    )
    if (data.merchantID) {
      yield fetchMerchant(data.merchantID)
    }
    yield put(claimDivitMilesActions.previewClaimSuccess(data))
  } catch (e) {
    yield put(claimDivitMilesActions.previewClaimFailed(getError(e)))
  }
}

export function* claim({ payload }) {
  try {
    const { token } = payload
    yield put(claimDivitMilesActions.claimStart())
    const { data } = yield DivitMilesSagas.claimDivitMiles({
      token,
    })
    yield put(claimDivitMilesActions.claimSuccess(data))
  } catch (e) {
    yield put(claimDivitMilesActions.claimFailed(getError(e)))
  }
}

export default 0
