import React from 'react'
import styled from 'styled-components'

import Spacer from '@/components/Spacer'
import { FormattedPrice } from '@/utils/Price'

import MilesValue from './MilesValue'

const Container = styled.div`
  display: flex;
  align-items: center;

  & * {
    font-size: ${(p) => p.fontSize};
    font-weight: 700;
    white-space: nowrap;
  }
`
const Amount = styled.span`
  font-size: ${(p) => p.amountFontSize};
`

const MilesAndAmountValue = ({
  className,
  currency,
  amount,
  miles,
  fontSize,
  amountFontSize = fontSize,
  leftPlusSpace = '8px',
  rightPlusSpace = '4px',
}) => {
  const dCurrency = currency !== 'DVM' ? currency : ''
  const dAmount = currency !== 'DVM' ? amount : 0
  const dMiles = currency !== 'DVM' ? miles : amount

  return (
    <Container className={className} fontSize={fontSize}>
      {dAmount !== 0 && (
        <span>
          {dCurrency}{' '}
          <Amount amountFontSize={amountFontSize}>
            {FormattedPrice('', dAmount)}
          </Amount>
        </span>
      )}
      {dMiles > 0 && (
        <>
          {dAmount !== 0 && (
            <>
              <Spacer width={leftPlusSpace} />
              <span>+</span>
              <Spacer width={rightPlusSpace} />
            </>
          )}
          <MilesValue
            fontSize={fontSize}
            miles={dMiles * 100}
            isSigned={false}
          />
        </>
      )}
    </Container>
  )
}

export default MilesAndAmountValue
