import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Input from '@/components/ui/Input'

const PasswordWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const PasswordToggle = styled.div`
  position: absolute;
  right: 0.78rem;
  font-weight: 700;
  font-size: 0.778rem;
  line-height: 3em;
  color: #979797;
  cursor: pointer;
`

const InputStyled = styled(Input)`
  & input {
    padding-left: 1.333rem;
    padding-right: 3rem;
  }
`

const PasswordInput = ({ value, onChange }) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState()

  return (
    <PasswordWrapper>
      <InputStyled
        type={showPassword ? 'text' : 'password'}
        id="password"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={intl.formatMessage({ id: 'login.password' })}
      />
      <PasswordToggle onClick={() => setShowPassword(!showPassword)}>
        {showPassword
          ? intl.formatMessage({ id: 'login.password.hide' })
          : intl.formatMessage({ id: 'login.password.show' })}
      </PasswordToggle>
    </PasswordWrapper>
  )
}

export default PasswordInput
