import { useDebounceFn } from 'ahooks'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import { useAuth } from '@/contexts/authContext'
import { Features, useFeature } from '@/hooks/useFeature'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { Input } from '@/pages/Auth/shared/style'
import { goBack, replace } from '@/redux/app/appActions'
import { actions as fastSignupActions } from '@/redux/pages/fastSignupSlice'

const Container = styled(PaddingContent)``

const SubTitle = styled.div`
  max-width: 375px;
  font-size: 0.778rem;
  text-align: center;
  margin: 0 auto;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.889rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const FormColumn = styled.div`
  flex: 1;
  ${({ position }) => position && `text-align: ${position}`}

  &:not(:last-child) {
    margin-right: 1rem;
  }
`

const ReferralCodeErrorText = styled.div`
  font-size: 0.778rem;
  color: #ff0000;
`

const Remarks = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const SetupReferralCode = () => {
  const intl = useIntl()
  const history = useHistory()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const { authUserID } = useAuth()
  const { featureEnabled } = useFeature()
  const { referralCode: savedReferralCode } = state || {}

  const [referralCode, setReferralCode] = useState(savedReferralCode)

  const { checkReferralCode } = useSelector((s) => s.fastSignup)

  const doCheckReferralCode = (code) => {
    dispatch({
      type: 'fastSignup/checkReferralCode',
      payload: { referralCode: code },
    })
  }

  const { run: runCheckReferralCode } = useDebounceFn(doCheckReferralCode)

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (authUserID) {
      history.replace('/home')
    } else if (!featureEnabled(Features.REFERRAL)) {
      history.replace('/')
    }
  }, [])

  useUpdateEffect(() => {
    const code = referralCode?.trim()
    if (code) {
      runCheckReferralCode(code)
    }
  }, [referralCode])

  useUpdateEffect(() => {
    if (checkReferralCode.isSuccess) {
      dispatch(fastSignupActions.setReferralCode(referralCode?.trim()))
      dispatch(replace('/signup'))
    }
  }, [checkReferralCode])

  const onSubmitForm = (e) => {
    e.preventDefault()
    doCheckReferralCode(referralCode)
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const loading = checkReferralCode.isLoading
  const referralCodeError =
    referralCode && !checkReferralCode.isLoading && checkReferralCode.isError

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Spacer height="2.222rem" />
          <Title onClickBack={onClickBack}>
            {tt(intl, 'common.referralCode')}
          </Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'login.enterreferralcode')}</SubTitle>
          <Spacer height="1.333rem" />
          <Form onSubmit={onSubmitForm}>
            <FormRow>
              <FormColumn>
                <Input
                  id="referralCode"
                  value={referralCode}
                  onChange={(e) => {
                    const v = (e.target.value || '').toUpperCase()
                    setReferralCode(v)
                  }}
                  placeholder={tt(intl, 'common.referralCode')}
                  error={referralCodeError}
                />
              </FormColumn>
            </FormRow>
            {referralCodeError && (
              <ReferralCodeErrorText>
                {tt(intl, 'login.referercode.error')}
              </ReferralCodeErrorText>
            )}
          </Form>
          <Spacer height="1.333rem" />
          <Remarks>{tt(intl, 'login.referercode.remarks')}</Remarks>
          <Spacer height="2.222rem" />
        </Container>
      </CSLayout.CSContent>
      <LoadingModal loading={loading} />
    </CSLayout.CSContainer>
  )
}

export default SetupReferralCode
