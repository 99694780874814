import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as SignupWelcomeSvg } from '@/assets/welcome/signup-welcome.svg'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import useCountDown from '@/hooks/useCountdown'
import { tt } from '@/locales/format'

const MAX_TIMEOUT = 3

const Container = styled.div``

const LoadingBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
`

const LoadingBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ animated }) => (animated ? '100%' : 0)};
  height: 100%;
  background-color: #ffcc33;
  transition: width ${MAX_TIMEOUT - 1}s linear;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
  color: #1c1c1c;
`

const SignupWelcome = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { countDownInSec } = useCountDown({
    timeoutInSec: MAX_TIMEOUT,
    onTimeout: () => {
      dispatch({
        type: 'redirectReturnUrlOrHome',
        payload: {
          isSignup: true,
        },
      })
    },
  })

  return (
    <Container>
      <LoadingBarContainer>
        <LoadingBar animated={MAX_TIMEOUT > countDownInSec} />
      </LoadingBarContainer>
      <Spacer height="10.888rem" />
      <PaddingContent>
        <Title>{tt(intl, 'common.welcometodivit')}</Title>
        <Spacer height="2.222rem" />
        <Icon
          renderImage={() => <SignupWelcomeSvg />}
          width="8.777rem"
          height="7.99rem"
        />
      </PaddingContent>
    </Container>
  )
}

export default SignupWelcome
