import React, { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import styled from 'styled-components'

import Spacer from '@/components/Spacer'
import { Round } from '@/utils/Price'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.667rem;
  padding: 1rem 1rem;
  margin: 0 auto;
  background-color: white;
  border: 1px solid rgba(28, 28, 28, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
`

const CurrencyText = styled.div`
  text-align: left;
  font-size: 0.889rem;
  font-weight: 700;
  line-height: 1.4rem;
`

const Input = styled(CurrencyInput)`
  width: 100%;
  font-size: 0.889rem;
  font-weight: 700;
  text-align: right;
  padding: 0;
  margin-top: 0;
  border: 0;
`

const AmountInput = ({
  currency,
  min = 0,
  max,
  value,
  onChange,
  reminder = 0,
}) => {
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState(() =>
    String(Round(value + reminder, 2))
  )

  useEffect(() => {
    if (parseFloat(value) !== parseFloat(inputValue)) {
      setInputValue(String(Round(value + reminder, 2)))
    }
    if (parseFloat(value) === 0 && parseFloat(inputValue) === 0) {
      setInputValue(String(Round(reminder, 2)))
    }
  }, [value, inputValue])

  const onClickInput = () => {
    inputRef.current.focus()
  }

  const onChangeAmount = (v) => {
    if (max < parseFloat(v)) {
      const maxValue = Math.round(max * 100) / 100
      setInputValue(String(maxValue))
      onChange(maxValue)
      inputRef.current?.blur()
    } else {
      setInputValue(v)
      onChange(parseFloat(v || '0'))
    }
  }

  const onBlur = () => {
    if (!inputValue) setInputValue('0')
    if (parseFloat(inputValue) < min) {
      const minValue = Math.round(min * 100) / 100
      setInputValue(String(minValue))
      onChange(minValue)
    }
  }

  return (
    <Container onClick={onClickInput}>
      <CurrencyText>{currency}</CurrencyText>
      <Spacer width="4px" />
      <Input
        ref={inputRef}
        decimalsLimit={2}
        disableAbbreviations
        defaultValue={inputValue}
        value={inputValue}
        onBlur={onBlur}
        onValueChange={onChangeAmount}
      />
    </Container>
  )
}

export default AmountInput
