import React from 'react'
import { useIntl } from 'react-intl'

import ErrorScreen from '@/components/error/ErrorScreen'
import { tt } from '@/locales/format'

const ErrorPageScreen = ({ title, content, error, onRetry }) => {
  const intl = useIntl()
  return (
    <ErrorScreen
      title={title || tt(intl, 'common.aiyah')}
      content={content || tt(intl, 'common.somethingwentwrong')}
      error={error}
      onOk={onRetry}
      okText={onRetry && tt(intl, 'button.retry')}
    />
  )
}

export default ErrorPageScreen
