import moment from 'moment'
import zxcvbn from 'zxcvbn'

export const validateEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

// symbol test
// var symbols = "\-\.\_\!\"\`\'\#\%\&\,\:\;\<\>\=\@\{\}\~\$\(\)\*\+\/\\?\[\]\^\|".split('')
// symbols.forEach((x) => { console.log(x, /[\-\.\_\!\"\`\'\#\%\&\,\:\;\<\>\=\@\{\}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(x)) })

export const validatePassword = (password = '') => {
  const validLength = password.length >= 8
  const validLengthIgnoreOtherValiations = password.length >= 15
  const strengthScore = zxcvbn(password).score
  const validStrength = strengthScore >= 0
  const validNumber = /[0-9]+/.test(password)
  const validLowercase = /(?=.*[a-z])/.test(password)
  const validUppercase = /(?=.*[A-Z])/.test(password)
  // eslint-disable-next-line no-useless-escape
  const validSymbol = /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(
    password
  )
  return {
    ignored: validLengthIgnoreOtherValiations,
    valid:
      validLengthIgnoreOtherValiations ||
      (validStrength &&
        validLength &&
        validNumber &&
        validLowercase &&
        validUppercase &&
        validSymbol),
    validStrength,
    validNumber,
    validLength,
    validLowercase,
    validUppercase,
    validSymbol,
    strengthScore,
  }
}

export const validateAge = (DOB) =>
  moment().subtract(18, 'years').isAfter(moment(DOB)) &&
  moment(DOB).isAfter(moment().subtract(130, 'years'))

export const validateTel = (countryCode, tel) => {
  if (countryCode === '+852') return tel?.length === 8
  if (countryCode === '+853') return tel?.length === 8
  if (countryCode === '+86') return tel?.length === 11
  return false
}
