import { css } from 'styled-components'

export const desktop = (cssContent, { minWidth } = {}) => css`
  @media (min-width: ${({ theme }) =>
      `${minWidth || theme.breakpoints.sm}px`}) {
    ${cssContent}
  }
`

export const mobile = (cssContent, { maxWidth } = {}) => css`
  @media (max-width: ${({ theme }) =>
      `${maxWidth || theme.breakpoints.sm - 1}px`}) {
    ${cssContent}
  }
`

export default 0
