import styled from 'styled-components'

import MoreInfoIcon from '../icons/MoreInfo'

export const Container = styled.div``

export const Title = styled.div`
  font-size: 1.111rem;
  font-weight: 600;
`

export const PurchaseSummaryHeader = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 1rem;
`

export const PurchasePromotionHeader = styled.div`
  text-align: center;
`

export const Booking = styled.div`
  flex: 1;
  font-size: 0.625rem;
  margin-bottom: 0.68rem;

  & > span {
    font-weight: 700;
  }
`

export const MerchantLogo = styled.div`
  width: 4rem;
  height: auto;

  & > svg {
    width: 100%;
    height: 100%;
  }
`

export const PurchaseSummaryBody = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  margin: 0 0.667rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin: 0 calc(100% / 24);
  }
`

export const PurchaseInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  padding-bottom: 1.5rem; /* 24px */
  border-bottom-width: 1px;
`

export const PurchaseInfoTotalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1.5rem; /* 24px */
  border-bottom-width: ${(p) => (p.showSchedule ? '1px' : '0')};
`

export const PurchaseInfoItem = styled.div`
  width: 50%;
  font-size: 1rem;
`

export const PurchaseInfoValue = styled.div`
  width: 50%;
  text-align: end;
  font-size: 1rem;
`

export const DivitFee = styled.div`
  width: 50%;
  display: flex;
  gap: 0.375rem;
  font-size: 1rem;
`

export const IconMoreInfo = styled(MoreInfoIcon)`
  margin-top: auto;
  margin-bottom: auto;
`

export const ShowMoreButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
`

export const FPSDivitLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
`

export const PromotionWording = styled.div``

export const PromotionPrice = styled.div`
  font-weight: 700;
  line-height: 13.714px;
`
