import React from 'react'

const ArrowUpIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke={props?.color ? props?.color : '#FC3'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6.501 15 6-6 6 6"
    />
  </svg>
)
export default ArrowUpIcon
