import moment from 'moment'

export const getNextInstalment = (instalments, instalment) => {
  const { series } = instalment
  return instalments.find((i) => i.series === series + 1)
}

export const getNextTxn = (instalment, txnId) => {
  const txns = (instalment.financialTx || []).slice()
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1))
  for (let i = 0; i < txns.length; i += 1) {
    const txn = txns[i]
    if (txn === txnId) {
      return txns[i + 1]
    }
  }
  return undefined
}

export const isPaid = (instalment) => instalment.status.toLowerCase() === 'paid'

export const isOffset = (instalment) =>
  instalment.status.toLowerCase() === 'offset'

export const hasRequestedRefund = (instalment) => {
  const refundTxns = (instalment.financialTx || []).filter(
    (tx) =>
      tx.transaction_status.toLowerCase() === 'requested' &&
      ['refund'].indexOf(tx.transaction_type) >= 0
  )
  return refundTxns.length > 0
}

export const hasCompletedRefund = (instalment) => {
  const refundTxns = (instalment.financialTx || []).filter(
    (tx) =>
      tx.transaction_status.toLowerCase() === 'completed' &&
      ['refund'].indexOf(tx.transaction_type) >= 0
  )
  return refundTxns.length > 0
}

export const hasCancellation = (instalment) => {
  const refundTxns = (instalment.financialTx || []).filter(
    (tx) =>
      tx.transaction_status.toLowerCase() === 'cancelled' &&
      ['instalment', 'noshow'].indexOf(tx.transaction_type) >= 0
  )
  return refundTxns.length > 0
}

export const isRefund = (instalment) =>
  hasRequestedRefund(instalment) || hasCompletedRefund(instalment)

// cancel without any refund
export const isCancelled = (instalment) => {
  const cancelledTxns = (instalment.financialTx || []).filter(
    (tx) =>
      tx.transaction_status.toLowerCase() === 'cancelled' &&
      ['instalment', 'noshow'].indexOf(tx.transaction_type) >= 0
  )
  const refundTxns = (instalment.financialTx || []).filter(
    (tx) =>
      (tx.transaction_status.toLowerCase() === 'requested' &&
        ['refund'].indexOf(tx.transaction_type) >= 0) ||
      (tx.transaction_status.toLowerCase() === 'completed' &&
        ['refund'].indexOf(tx.transaction_type) >= 0)
  )
  return (
    isOffset(instalment) && cancelledTxns.length > 0 && refundTxns.length === 0
  )
}

export const getSuccessTransactions = (instalment) => {
  const txns = (instalment.financialTx || []).filter(
    (tx) =>
      tx.transaction_status.toLowerCase() === 'completed' &&
      ['payment', 'adjpayment'].indexOf(tx.transaction_type) >= 0
  )
  // sort old first
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1))
  return txns
}

export const getAdjustmentTransactions = (instalment) => {
  const txns = (instalment.financialTx || []).filter(
    (tx) =>
      ['requested', 'completed'].indexOf(tx.transaction_status.toLowerCase()) >=
        0 && ['adjustment', 'refund'].indexOf(tx.transaction_type) >= 0
  )
  // sort old first
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1))
  return txns
}

export const getCancelledTransactions = (instalment) =>
  (instalment.financialTx || []).filter(
    (tx) =>
      tx.transaction_status.toLowerCase() === 'cancelled' &&
      ['noshow', 'instalment'].indexOf(tx.transaction_type) >= 0
  )

/*
  sorting
  1. merchant credit
  2. payment
  3. refund
*/
export const getAllTransactions = (instalment) => {
  const txns1 = getSuccessTransactions(instalment)
  const txns2 = getAdjustmentTransactions(instalment)
  const txns3 = getCancelledTransactions(instalment)
  const txns = txns3.concat(txns1).concat(txns2)
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1))
  return txns
}

export const getOutstandingInstalment = (instalments) =>
  [...instalments]
    .sort((a, b) => a.series - b.series)
    .find((instalment) => !isPaid(instalment))

export const getLatestPaidInstalment = (instalments) =>
  [...instalments]
    .sort((a, b) => b.series - a.series)
    .find(({ status }) => status.toLowerCase() === 'paid')

// get latest payment transaction sort by created datetime
export const getLastPaidAmount = (instalment, instrumentIDs) => {
  const txns = (instalment.financialTx || []).slice()
  txns
    .filter(
      (t) =>
        t.transaction_status === 'completed' &&
        instrumentIDs.indexOf(t.payment_instrument_id) >= 0
    )
    .sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  return txns.length > 0 ? txns[0] : undefined
}

// get latest payment transaction sort by created datetime
export const getLatestPayment = (instalment) => {
  const txns = (instalment.financialTx || []).slice()
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  return txns.length > 0 ? txns[0] : undefined
}

// get latest completed payment transaction sort by created datetime
export const getSuccessPayment = (instalment) => {
  const txns = getSuccessTransactions(instalment).slice()
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  return txns.length > 0 ? txns[0] : undefined
}

// get latest refund payment transaction
export const getRefundPayment = (instalment) => {
  const txns = (instalment.financialTx || []).filter(
    (tx) =>
      ['requested', 'completed'].indexOf(tx.transaction_status.toLowerCase()) >=
        0 && ['refund'].indexOf(tx.transaction_type) >= 0
  )
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  return txns.length > 0 ? txns[0] : undefined
}

export const getOrderStatus = (instalments) => {
  if (
    instalments.every(({ status }) => ['paid', 'offset'].indexOf(status) >= 0)
  ) {
    return 'completed'
  }
  return instalments.some(
    ({ status, dueDate }) =>
      status.toLowerCase() === 'unpaid' &&
      moment().isAfter(moment.unix(dueDate), 'd')
  )
    ? 'overdue'
    : 'active'
}

export const getPaymentStatus = (instalment) => {
  // status 1: completed
  if (instalment.status.toLowerCase() === 'paid') {
    return 'completed'
  }
  // status 2: processing
  if (instalment.status.toLowerCase() === 'processing') {
    return 'processing'
  }
  // status 3: failure
  if (instalment.status.toLowerCase() === 'unpaid') {
    // last txn marked as failed
    const lastTxn = getLatestPayment(instalment)
    const isFailed = lastTxn?.transaction_status.toLowerCase() === 'failed'
    if (isFailed) {
      return 'failed'
    }
  }
  // status 4: offset (refund)
  if (instalment.status.toLowerCase() === 'offset') {
    return 'offset'
  }
  return 'unpaid'
}

export const isPayable = (instalment) =>
  ['paid', 'offset'].indexOf(instalment.status.toLowerCase()) < 0

export const isOutstandingInstalment = (instalments, instalment) =>
  getOutstandingInstalment(instalments)?.instalmentID ===
  instalment.instalmentID

export const getPaymentFailureInstalment = (instalments) =>
  [...instalments]
    .sort((a, b) => b.series - a.series)
    .find((instalment) => getPaymentStatus(instalment) === 'failed')

// total amount of instalment, not paid amount
export const getTotalAmount = (instalment) => ({
  currency: instalment.amount.currency,
  amount: instalment.amount.amount + (instalment.lateFee?.amount || 0),
})

// total late fee of instalment
export const getTotalLateFee = (instalments) => {
  const { currency } = instalments[0].amount
  const amount = instalments.reduce((sum, instalment) => {
    sum += instalment.lateFee?.amount || 0
    return sum
  }, 0)
  return { currency, amount }
}

// get outstanding amount
export const getOutstandingAmount = (instalment, includeBurn) => {
  const burnAmount = includeBurn
    ? instalment.miles > 0
      ? instalment.milesAmount.amount
      : 0
    : 0

  if (!instalment.financialTx)
    return {
      currency: instalment.amount.currency,
      amount: instalment.amount.amount - burnAmount,
    }

  const paid = instalment.financialTx.reduce((sum, tx) => {
    if (
      ['payment', 'adjpayment'].indexOf(tx.transaction_type) >= 0 &&
      tx.transaction_status === 'completed'
    ) {
      sum += tx.amount.amount - tx.transaction_fee.amount
    }
    if (['refund'].indexOf(tx.transaction_type) >= 0) {
      sum -= tx.amount.amount
    }
    return sum
  }, 0)
  const cancelled = instalment.financialTx.reduce((sum, tx) => {
    if (
      tx.transaction_type === 'latefee' &&
      tx.transaction_status === 'completed'
    ) {
      sum -= tx.amount.amount
    }
    if (
      ['adjustment'].indexOf(tx.transaction_type) >= 0 &&
      tx.transaction_status === 'completed'
    ) {
      sum += tx.amount.amount
    }
    if (
      ['instalment', 'noshow'].indexOf(tx.transaction_type) >= 0 &&
      tx.transaction_status === 'cancelled'
    ) {
      sum += tx.amount.amount
    }
    return sum
  }, 0)

  return {
    currency: instalment.amount.currency,
    amount: instalment.amount.amount - paid - cancelled - burnAmount,
  }
}

export const hasBurned = (instalment) => {
  if (!instalment.financialTx) return false
  return instalment.financialTx.find(
    (tx) =>
      tx.transactionType === 'payment' && tx.transactionStatus === 'completed'
  )
}

// total paid amount, included service fee, refund
export const getTotalPaidAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns1 = getSuccessTransactions(instalment)
  const txns2 = getAdjustmentTransactions(instalment)
  const txns = txns1.concat(txns2)
  const amount = txns.reduce((sum, tx) => {
    if (tx.transaction_type === 'payment' && tx.paid_by === 'miles') return sum
    if (['adjustment', 'refund'].indexOf(tx.transaction_type) >= 0) {
      sum -= tx.amount.amount
    } else {
      sum += tx.amount.amount
    }
    return sum
  }, 0)
  return { currency, amount }
}

export const getTotalActualPaidAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = getSuccessTransactions(instalment)
  const amount = txns.reduce((sum, tx) => {
    sum += tx.amount.amount
    return sum
  }, 0)
  return { currency, amount }
}

export const getTotalCashPaidAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = getSuccessTransactions(instalment)
  const amount = txns.reduce((sum, tx) => {
    if (tx.payment_ref_id.indexOf('miles') === -1) sum += tx.amount.amount
    return sum
  }, 0)
  return { currency, amount }
}

export const getTotalMilesPaidAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = getSuccessTransactions(instalment)
  const amount = txns.reduce((sum, tx) => {
    if (tx.payment_ref_id.indexOf('miles') >= 0) sum += tx.amount.amount
    return sum
  }, 0)
  return { currency, amount }
}

export const getTotalMilesBurned = (instalment) => {
  const txns = getSuccessTransactions(instalment)
  const amount = txns.reduce((sum, tx) => {
    if (tx.payment_ref_id.indexOf('miles') >= 0) {
      const miles = parseInt(tx.payment_ref_id.replace('miles:', ''), 10)
      sum += !miles.isNaN ? miles : 0
    }
    return sum
  }, 0)
  return amount
}

export const getMilesBurned = (instalment) => {
  const ins = instalment
  return ins.miles || 0
}

export const getTotalCancelledCashAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = (instalment.financialTx || []).filter(
    (tx) =>
      (tx.transaction_status.toLowerCase() === 'requested' ||
        tx.transaction_status.toLowerCase() === 'completed') &&
      ['refund'].indexOf(tx.transaction_type) >= 0
  )
  const amount = txns.reduce((sum, tx) => {
    sum += tx.amount.amount
    return sum
  }, 0)
  return { currency, amount }
}

export const getTotalCancelledMiles = (instalment) => {
  const txns = (instalment.financialTx || []).filter(
    (tx) =>
      tx.transaction_status.toLowerCase() === 'cancelled' &&
      ['payment'].indexOf(tx.transaction_type) >= 0 &&
      tx.payment_ref_id.indexOf('miles') >= 0
  )
  const amount = txns.reduce((sum, tx) => {
    const miles = parseInt(tx.payment_ref_id.replace('miles:', ''), 10)
    sum += !miles.isNaN ? miles : 0
    return sum
  }, 0)
  const burned = getTotalMilesBurned(instalment) || 0

  return amount - burned > 0 ? amount - burned : amount
}

export const getTotalCancelledAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = getCancelledTransactions(instalment)
  const amount = txns.reduce((sum, tx) => {
    sum += tx.amount.amount
    return sum
  }, 0)
  return { currency, amount }
}

// include noshow
export const getTotalRefundAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = getCancelledTransactions(instalment)
  const amount = txns.reduce((sum, txn) => {
    sum += txn.amount.amount || 0
    return sum
  }, 0)

  return { currency, amount }
}

// include refund & reallocated
export const getTotalActualRefundAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = getAdjustmentTransactions(instalment)
  const amount = txns.reduce((sum, txn) => {
    sum += txn.amount.amount || 0
    return sum
  }, 0)

  return { currency, amount }
}

// only refunded amount
export const getTotalRefundedAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = (instalment.financialTx || []).filter(
    (tx) =>
      ['requested', 'completed'].indexOf(tx.transaction_status.toLowerCase()) >=
        0 && ['refund'].indexOf(tx.transaction_type) >= 0
  )
  const amount = txns.reduce((sum, txn) => {
    sum += txn.amount.amount || 0
    return sum
  }, 0)
  return { currency, amount }
}

// only reallocated amount
export const getTotalReallocatedAmount = (instalment) => {
  const { currency } = instalment.amount
  const txns = (instalment.financialTx || []).filter(
    (tx) =>
      ['requested', 'completed'].indexOf(tx.transaction_status.toLowerCase()) >=
        0 && ['adjustment'].indexOf(tx.transaction_type) >= 0
  )
  const amount = txns.reduce((sum, txn) => {
    sum += txn.amount.amount || 0
    return sum
  }, 0)
  return { currency, amount }
}

export const isFullRefund = (instalment) =>
  getTotalRefundedAmount(instalment).amount >=
  getTotalPaidAmount(instalment).amount

// total paid service fee amount
export const getTotalPaidServiceFee = (instalment) => {
  const { currency } = instalment.amount
  const txns1 = getSuccessTransactions(instalment)
  const txns2 = getAdjustmentTransactions(instalment)
  const txns = txns1.concat(txns2)
  const amount = txns.reduce((sum, tx) => {
    sum += tx.transaction_fee.amount
    return sum
  }, 0)
  return { currency, amount }
}

// total paid amount without service fee
export const getTotalPaidAmountWithoutServiceFee = (instalment) => ({
  currency: instalment.amount.currency,
  amount:
    getTotalPaidAmount(instalment).amount -
    getTotalPaidServiceFee(instalment).amount,
})

// total paid service fee in all instalments
export const getAllTotalServiceFee = (instalments) => {
  const { currency } = instalments[0].amount
  const amount = instalments.reduce((sum, instalment) => {
    sum += getTotalPaidServiceFee(instalment).amount
    return sum
  }, 0)
  return { currency, amount }
}

// total unpaid amount
export const getTotalOutstandingAmount = (instalment) => {
  const { currency } = instalment.amount
  // refunded
  if (isOffset(instalment)) {
    return { currency, amount: 0 }
  }
  const totalPaidAmount = getTotalPaidAmount(instalment).amount
  const totalRefundAmount = getTotalRefundAmount(instalment).amount
  const totalServiceFee = getTotalPaidServiceFee(instalment).amount
  const totalAmount = getTotalAmount(instalment).amount
  const milesAmount = (instalment.milesAmount || { currency, amount: 0 }).amount

  return {
    currency,
    amount: Math.max(
      0,
      totalAmount -
        (totalPaidAmount - totalServiceFee) -
        totalRefundAmount -
        milesAmount
    ),
  }
}

// total over paid amount
export const getOverPaidAmount = (instalment) => {
  const { currency } = instalment.amount
  const totalPaidAmount = getTotalPaidAmount(instalment).amount
  const totalAmount = getTotalAmount(instalment).amount
  const totalServiceFee = getTotalPaidServiceFee(instalment).amount
  const totalCancelledAmount = getTotalCancelledAmount(instalment).amount

  return {
    currency,
    amount:
      totalPaidAmount - (totalAmount - totalCancelledAmount) - totalServiceFee,
  }
}

// status 4: underpay, sum up amount of completed < total amount of payment
// ps: total paid amount include cancel amount
export const isUnderPay = (instalment) => {
  const totalAmount = getTotalAmount(instalment).amount
  const totalPaidAmount = getTotalPaidAmount(instalment).amount
  const totalServiceFee = getTotalPaidServiceFee(instalment).amount
  const totalCancelledAmount = getTotalCancelledAmount(instalment).amount

  return (
    !isPaid(instalment) &&
    !isOffset(instalment) &&
    totalPaidAmount > 0 &&
    totalPaidAmount - totalServiceFee < totalAmount - totalCancelledAmount
  )
}

// status 5: overpay sum up amount of completed > total amount of payment
export const isOverPay = (instalment) => {
  const totalPaidAmount = getTotalPaidAmount(instalment).amount
  const totalServiceFee = getTotalPaidServiceFee(instalment).amount
  const totalAmount = getTotalAmount(instalment).amount
  const totalCancelledAmount = getTotalCancelledAmount(instalment).amount

  return (
    isPaid(instalment) &&
    totalPaidAmount > 0 &&
    totalPaidAmount - totalServiceFee > totalAmount - totalCancelledAmount
  )
}

export const anyInstalmentIsOverPay = (instalments) =>
  instalments.find((instalment) => isOverPay(instalment))

export const anyInstalmentIsUnderPay = (instalments) =>
  instalments.find((instalment) => isUnderPay(instalment))

export const getSubtotalAmount = (instalment) => {
  const { currency } = instalment.amount
  const totalPaidAmount = getTotalPaidAmount(instalment).amount
  const totalServiceFee = getTotalPaidServiceFee(instalment).amount
  const totalAmount = getTotalAmount(instalment).amount
  const totalCancelledAmount = getTotalCancelledAmount(instalment).amount

  let subtotalAmount = 0
  if (isUnderPay(instalment)) {
    // paid amount + paid service fee + unpaid amount
    subtotalAmount = totalAmount + totalServiceFee - totalCancelledAmount
  } else if (isPaid(instalment)) {
    // paid normally, might be reduced before pay
    subtotalAmount = totalPaidAmount
  } else if (isOffset(instalment)) {
    // refund
    subtotalAmount = totalPaidAmount
  } else {
    // unpaid
    subtotalAmount = totalAmount - totalCancelledAmount
  }
  return { currency, amount: subtotalAmount }
}

export const getSuccessInstalments = (instalments) =>
  instalments.reduce((sum, instalment) => {
    const txns = getSuccessTransactions(instalment)
    sum = sum.concat(txns)
    return sum
  }, [])

export const isActive = (instalment) =>
  !isPaid(instalment) && !isCancelled(instalment) && !isRefund(instalment)

export const isOverdue = (instalment) =>
  isActive(instalment) && moment().isAfter(moment.unix(instalment.dueDate), 'd')

export const isPaymentInstrumentProcessing = (instalment, instrument) => {
  const txns = (instalment.financialTx || []).filter(
    (txn) =>
      txn.payment_instrument?.name.toLowerCase() === instrument &&
      txn.transaction_status.toLowerCase() === 'pending'
  )
  return txns.length > 0
}
