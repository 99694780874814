import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as ArrowSvg } from '@/assets/miles/convert-arrow.svg'
import { ReactComponent as DivitMilesIconSvg } from '@/assets/miles/divit-miles-logo.svg'
import { Button, H2, H3, Input, Label } from '@/components/Divit'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import MilesPrograms from '@/constants/MilesPrograms'
import usePage from '@/hooks/usePage'
import {
  divitMilesSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'
import { FormattedMiles } from '@/utils/Price'

const Container = styled.div`
  padding-top: 1.25rem;
`

const ConvertInfoContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 17rem;
  margin: 0 auto;
  margin-top: 0.5rem;
`

const ConvertInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ConvertIcon = styled.div``

const ConvertValue = styled.div`
  margin-top: 0.5rem;
`

const ConvertRateContainer = styled.div`
  margin-top: 0.5rem;
  text-align: center;
`

const ConvertRate = styled.div`
  display: inline-block;
  margin: 0 auto;
  padding: 0.3rem 0.7rem;
  font-size: 0.66rem;
  border-radius: 0.5rem;
  background-color: #eee;
`

const ConvertButtonContainer = styled(PaddingContent)``

const ConvertButton = styled(Button)`
  background-color: #ff9732;
  font-size: 0.66rem;
`

const SuccessIconContainer = styled.div`
  position: relative;
  width: 5.5rem;
  height: 6rem;
  margin: 0 auto;
`

const SuccessIconFrom = styled.div`
  position: absolute;
  top: 0;
  left: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`

const SuccessIconTo = styled.div`
  position: absolute;
  top: 2rem;
  left: 0;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`

const SuccessText = styled.div`
  font-size: 0.77rem;
  text-align: center;
`

const DivitMilesConvert = () => {
  const history = useHistory()
  const { state } = useLocation()
  const { program } = useParams()

  const profile = useSelector(profileSelector)
  const divitMiles = useSelector(divitMilesSelector)

  const [firstName, setFirstName] = useState(profile.firstName)
  const [lastName, setLastName] = useState(profile.lastName)
  const [asiaMilesMemberNo, setAsiaMilesMemberNo] = useState('')
  const [milesToConvert, setMilesToConvert] = useState(state.miles || 0)
  const [step, setStep] = useState(1)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileMain' },
  })

  useEffect(() => {
    history.replace('/')
  }, [])

  useEffect(() => {
    if (!milesToConvert) {
      setMilesToConvert(divitMiles.balance)
    }
  }, [divitMiles.balance])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!profile) return <></>

  const onClickNext = () => {
    setStep(2)
  }

  const onClickBack = () => {
    history.push('/profile/miles')
  }

  const programInfo = MilesPrograms.find((p) => p.key === program)

  if (!programInfo) {
    return <div>invalid loyalty program</div>
  }

  return (
    <Container>
      {step === 1 && (
        <>
          <ConvertInfoContainer>
            <ConvertInfo>
              <ConvertIcon>
                <Icon
                  renderImage={() => <DivitMilesIconSvg />}
                  width="2rem"
                  height="2rem"
                />
              </ConvertIcon>
              <ConvertValue>{FormattedMiles(milesToConvert)}</ConvertValue>
            </ConvertInfo>
            <ConvertInfo>
              <Icon
                renderImage={() => <ArrowSvg />}
                width="2rem"
                height="2rem"
              />
            </ConvertInfo>
            <ConvertInfo>
              <ConvertIcon>
                <Icon
                  renderImage={() => <programInfo.Icon />}
                  width="2rem"
                  height="2rem"
                />
              </ConvertIcon>
              <ConvertValue>{FormattedMiles(milesToConvert)}</ConvertValue>
            </ConvertInfo>
          </ConvertInfoContainer>
          <ConvertRateContainer>
            <ConvertRate>
              <span>1 divit miles = 1 </span>
              <span>{programInfo.pointName}</span>
            </ConvertRate>
          </ConvertRateContainer>
          <Spacer height="1.2rem" />
          <H3>
            <span>{programInfo.name}</span>
            <span> account</span>
          </H3>
          <form>
            <div style={{ display: 'flex' }}>
              <div>
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div style={{ width: '1rem' }} />
              <div>
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div style={{ fontSize: '0.66rem' }}>
              <span>Please ensure your name in both Divit and </span>
              <span>{programInfo.name}</span>
              <span> records are the same to be eligible for conversion</span>
            </div>
            <Spacer height="0.5rem" />
            <div>
              <Label htmlFor="no">Member No.</Label>
              <Input
                name="no"
                onChange={(e) => setAsiaMilesMemberNo(e.target.value)}
              />
            </div>
          </form>
          <Spacer height="1rem" />
          <ConvertButtonContainer>
            <ConvertButton type="stretch" onClick={onClickNext}>
              Convert
            </ConvertButton>
          </ConvertButtonContainer>
        </>
      )}
      {step === 2 && (
        <>
          <H2>Convert Successfully</H2>
          <Spacer height="1rem" />
          <SuccessIconContainer>
            <SuccessIconFrom>
              <Icon
                renderImage={() => <DivitMilesIconSvg />}
                width="3rem"
                height="3rem"
              />
            </SuccessIconFrom>
            <SuccessIconTo>
              <Icon
                renderImage={() => <programInfo.Icon />}
                width="3rem"
                height="3rem"
              />
            </SuccessIconTo>
          </SuccessIconContainer>
          <Spacer height="1rem" />
          <SuccessText>
            <span>You have succesfully converted</span>
            <br />
            <b>{`${FormattedMiles(divitMiles.balance)} divit miles`}</b>
            <span> into </span>
            <b>
              {`${FormattedMiles(divitMiles.balance)} ${programInfo.pointName}`}
            </b>
          </SuccessText>
          <Spacer height="1rem" />
          <SuccessText>
            <span>{programInfo.name}</span>
            <span> member no:</span>
            <br />
            <b>{asiaMilesMemberNo}</b>
          </SuccessText>
          <Spacer height="2rem" />
          <ConvertButtonContainer>
            <ConvertButton type="stretch" onClick={onClickBack}>
              Return
            </ConvertButton>
          </ConvertButtonContainer>
        </>
      )}
    </Container>
  )
}

export default DivitMilesConvert
