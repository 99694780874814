import { call, put } from 'redux-saga/effects'

import { fetchOrderActivities } from '@/redux/divitMiles/activities'
import { fetchDivitMilesByOrder } from '@/redux/divitMiles/divitMilesSaga'
import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as paynowVerifyActions } from './paynowVerifySlice'

export function* createVerifyPayment() {
  try {
    yield put(paynowVerifyActions.createVerifyPaymentStart())
    const { data } = yield call(() => DivitAPI.post(`/paynow/profiles/orders`))
    yield put(paynowVerifyActions.createVerifyPaymentSuccess(data.data))
  } catch (e) {
    yield put(paynowVerifyActions.createVerifyPaymentFailed(getError(e)))
  }
}

export function* fetchOrder({ payload }) {
  try {
    yield put(paynowVerifyActions.fetchOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`paynow/profiles/orders/${orderId}`)
    )
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowVerifyActions.fetchOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowVerifyActions.fetchOrderFailed(getError(e)))
  }
}

export function* generateFpsLink({ payload }) {
  try {
    yield put(paynowVerifyActions.generateFpsLinkStart())
    const { pin, orderId, instalmentId, paymentMethod } = payload
    const { data } = yield call(() =>
      DivitAPI.post('paynow/payments', {
        pin,
        orderID: orderId,
        instalmentID: instalmentId,
        instrumentCode: paymentMethod,
      })
    )
    yield put(paynowVerifyActions.generateFpsLinkSuccess(data.data))
  } catch (e) {
    yield put(paynowVerifyActions.generateFpsLinkFailed(getError(e)))
  }
}

export function* fpsRefreshOrder({ payload }) {
  try {
    yield put(paynowVerifyActions.refreshOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitAPI.get(`paynow/profiles/orders/${orderId}`)
    )
    if (data.data.status.toLowerCase() === 'completed') {
      yield fetchDivitMilesByOrder({ orderId })
      yield fetchOrderActivities({ orderId })
    }
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowVerifyActions.refreshOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowVerifyActions.refreshOrderFailed(getError(e)))
  }
}

export default null
