import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import routes from './routes'

const Router = () => {
  const location = useLocation()

  return (
    <>
      <Switch location={location}>
        {Object.values(routes).map((r) => {
          if (r.redirect) {
            return <Redirect key={r.name} from={r.path} to={r.redirect} />
          }
          const RouteComponent = r.private ? PrivateRoute : Route
          return (
            <RouteComponent
              key={r.name}
              path={r.path}
              component={r.component}
              exact={r.exact}
            />
          )
        })}
        <Redirect to="/not-found" />
      </Switch>
    </>
  )
}

export default Router
