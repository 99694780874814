import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
  contact: '',
  email: '',
  tel: '',
  content: '',
  actions: [],
  closable: true,
  loading: false,
  error: null,
}

const csmodalSlice = createSlice({
  name: 'csmodal',
  initialState,
  reducers: {
    reset: () => initialState,
    openModal: (state) => {
      state.loading = false
      state.isOpen = true
      state.error = null
    },
    sendStart: (state) => {
      state.loading = true
      state.error = null
    },
    sendFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    sendSuccess: (state) => {
      state.loading = false
      state.isOpen = false
      state.contact = ''
      state.email = ''
      state.tel = ''
      state.content = ''
      state.error = null
    },
    closeModal: (state) => {
      state.isOpen = false
      state.error = null
    },
  },
})

export const { actions } = csmodalSlice

export default csmodalSlice.reducer
