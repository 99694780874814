import qs from 'query-string'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import useUnmount from '@/hooks/useUnmount'

const SignupEmailFromLink = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { signupVerifyEmail } = useSelector((s) => s.fastSignup)

  useUnmount(() => dispatch({ type: 'fastSignup/reset' }))

  useEffect(() => {
    const { token } = qs.parse(window.location.search)
    dispatch({
      type: 'fastSignup/signupVerifyEmail',
      payload: { token },
    })
  }, [])

  useEffect(() => {
    if (signupVerifyEmail.isSuccess) {
      history.replace('/signup/setup-password')
    }
    if (signupVerifyEmail.isError) {
      history.replace('/')
    }
  }, [signupVerifyEmail])

  return <LoadingScreen />
}

export default SignupEmailFromLink
