import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Box from '@/components/Box'
import { H2, HR } from '@/components/Divit'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import InstalmentsInfoCollapsable from '@/components/InstalmentsInfoCollapsable'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import OrderReference from '@/components/OrderReference'
import OrderSummary from '@/components/OrderSummary'
import PurchaseDesc from '@/components/PurchaseDesc'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import { formatMessage } from '@/locales/format'
import { AirlineNameByPartnerId } from '@/utils/Airline'
import * as Order from '@/utils/Order'

const Container = styled(PaddingContent)`
  margin: 1.25rem 0;
`

const BookingReferenceText = styled.div`
  font-size: 0.625rem;
`

const VendorText = styled.div`
  font-size: 0.625rem;
`

const RefundMessageBox = styled(Box)`
  font-size: 0.75rem;
  padding: 1rem 1.18rem;
  box-sizing: border-box;
`

const OrderSummaryTitle = styled(H2)``

const PaymentSchedule = styled.div`
  margin: 1rem auto;
  padding: 0 1rem;
`

const PurchaseSummaryTitle = styled(H2)``

const PurchaseSummary = styled.div`
  font-size: 0.75rem;
`

const ScheduleReview = () => {
  const intl = useIntl()
  const { orderId } = useParams()

  const { order, orderMiles } = useSelector((state) => state.order)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  if (order.orderid !== orderId) return <></>

  const orderType = Order.getOrderItemType(order)
  const partnerRef = Order.getPartnerRef(order)

  const renderNoticeMessage = () => {
    if (Order.hasRequestedRefund(order)) {
      return formatMessage(intl, 'order.refund.requested')
    }
    if (Order.isFullRefund(order)) {
      return formatMessage(intl, 'order.refund.cancelled.full')
    }
    if (Order.getTotalOutstandingAmount(order).amount > 0) {
      return formatMessage(intl, 'order.refund.cancelled.partial.owing')
    }
    return formatMessage(intl, 'order.refund.cancelled.partial')
  }

  return (
    <Container>
      <BookingReferenceText>
        <OrderReference orderType={orderType} partnerRef={partnerRef} />
      </BookingReferenceText>
      <Spacer height="0.2rem" />
      <VendorText>
        {`${intl.formatMessage({ id: 'receipt.vendor' })}:`}
        <b>{AirlineNameByPartnerId(intl, order.partnerid)}</b>
      </VendorText>
      <Spacer height="1rem" />
      <RefundMessageBox>{renderNoticeMessage()}</RefundMessageBox>
      <OrderSummaryTitle>
        {intl.formatMessage({ id: 'receipt.order.summary' })}
      </OrderSummaryTitle>
      <OrderSummary order={order} orderMiles={orderMiles} />
      <PaymentSchedule>
        <InstalmentsInfoCollapsable
          order={order}
          orderMiles={orderMiles}
          isExpandable
          showTotal={false}
        />
      </PaymentSchedule>
      <HR />
      <PurchaseSummaryTitle>
        {intl.formatMessage({ id: 'receipt.purchase.summary' })}
      </PurchaseSummaryTitle>
      <PurchaseSummary>
        <PurchaseDesc orderItems={order.orderItems} />
      </PurchaseSummary>
      <Spacer height="2rem" />
    </Container>
  )
}

export default ScheduleReview
