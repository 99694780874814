import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as NotVerifiedSvg } from '@/assets/common/not-verified.svg'
import { ReactComponent as VerifiedSvg } from '@/assets/common/verified.svg'
import { tt } from '@/locales/format'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 22%;
  margin-top: auto;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}|| '425'}px`}) {
    width: 20%;
  }
`

const VerifiedText = styled.div`
  font-size: 0.6rem;
  color: #00c86d;
`
const NotVerifiedText = styled.div`
  font-size: 0.6rem;
  color: #696c70;
`

const CompletedStatus = ({ status, hiddenLabel }) => {
  const intl = useIntl()
  return (
    <>
      {status === 'verified' ? (
        <Container>
          <VerifiedSvg height="30px" width="30px" />
          {!hiddenLabel && (
            <VerifiedText>{tt(intl, 'common.completed')}</VerifiedText>
          )}
        </Container>
      ) : (
        <Container>
          <NotVerifiedSvg height="30px" width="30px" />
          {!hiddenLabel && (
            <NotVerifiedText>
              {tt(intl, 'common.validation.required')}
            </NotVerifiedText>
          )}
        </Container>
      )}
    </>
  )
}

export default CompletedStatus
