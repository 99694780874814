import { createSlice } from '@reduxjs/toolkit'

const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    products: [],
    productCategories: [],
    brands: [],
  },
  reducers: {
    getProductsSuccess: (state, action) => {
      state.products = action.payload
    },
    getProductCategoriesSuccess: (state, action) => {
      state.productCategories = action.payload
    },
    getBrandsSuccess: (state, action) => {
      state.brands = action.payload
    },
  },
})

export const { actions } = shopSlice

export default shopSlice.reducer
