// import { FPS_BANK_GUIDES, importImage } from './images'

// const i = (img) => importImage(FPS_BANK_GUIDES, img)
const imgPathPrefix = `${process.env.REACT_APP_STATIC_URL}/fps/bank-guides/`

export const FPS_QR_GUIDES = {
  CITI: {
    desktop: {
      en: [
        {
          key: 1,
          img: `${imgPathPrefix}CITI_QR_D_EN_1.jpg`,
          text: 'fps.citi_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}CITI_QR_D_EN_2.jpg`,
          text: 'fps.citi_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}CITI_QR_D_EN_3.jpg`,
          text: 'fps.citi_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}CITI_QR_D_EN_4.jpg`,
          text: 'fps.citi_qr_d_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}CITI_QR_D_EN_5.jpg`,
          text: 'fps.citi_qr_d_5',
        },
      ],
      'zh-hk': [
        {
          key: 1,
          img: `${imgPathPrefix}CITI_QR_D_TC_1.jpg`,
          text: 'fps.citi_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}CITI_QR_D_TC_2.jpg`,
          text: 'fps.citi_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}CITI_QR_D_TC_3.jpg`,
          text: 'fps.citi_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}CITI_QR_D_TC_4.jpg`,
          text: 'fps.citi_qr_d_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}CITI_QR_D_TC_5.jpg`,
          text: 'fps.citi_qr_d_5',
        },
      ],
    },
    mobile: {
      en: [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_EN.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}CITI_QR_M_EN_1.jpg`,
          text: 'fps.citi_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}CITI_QR_M_EN_2.jpg`,
          text: 'fps.citi_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}CITI_QR_M_EN_3.jpg`,
          text: 'fps.citi_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}CITI_QR_M_EN_4.jpg`,
          text: 'fps.citi_qr_m_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}CITI_QR_M_EN_5.jpg`,
          text: 'fps.citi_qr_m_5',
        },
      ],
      'zh-hk': [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_TC.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}CITI_QR_M_TC_1.jpg`,
          text: 'fps.citi_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}CITI_QR_M_TC_2.jpg`,
          text: 'fps.citi_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}CITI_QR_M_TC_3.jpg`,
          text: 'fps.citi_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}CITI_QR_M_TC_4.jpg`,
          text: 'fps.citi_qr_m_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}CITI_QR_M_TC_5.jpg`,
          text: 'fps.citi_qr_m_5',
        },
      ],
    },
  },
  BOC: {
    desktop: {
      en: [
        {
          key: 1,
          img: `${imgPathPrefix}BOC_QR_D_EN_1.jpg`,
          text: 'fps.boc_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}BOC_QR_D_EN_2.jpg`,
          text: 'fps.boc_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}BOC_QR_D_EN_3.jpg`,
          text: 'fps.boc_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}BOC_QR_D_EN_4.jpg`,
          text: 'fps.boc_qr_d_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}BOC_QR_D_EN_5.jpg`,
          text: 'fps.boc_qr_d_5',
        },
      ],
      'zh-hk': [
        {
          key: 1,
          img: `${imgPathPrefix}BOC_QR_D_TC_1.jpg`,
          text: 'fps.boc_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}BOC_QR_D_TC_2.jpg`,
          text: 'fps.boc_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}BOC_QR_D_TC_3.jpg`,
          text: 'fps.boc_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}BOC_QR_D_TC_4.jpg`,
          text: 'fps.boc_qr_d_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}BOC_QR_D_TC_5.jpg`,
          text: 'fps.boc_qr_d_5',
        },
      ],
    },
    mobile: {
      en: [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_EN.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}BOC_QR_M_EN_1.jpg`,
          text: 'fps.boc_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}BOC_QR_M_EN_2.jpg`,
          text: 'fps.boc_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}BOC_QR_M_EN_3.jpg`,
          text: 'fps.boc_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}BOC_QR_M_EN_4.jpg`,
          text: 'fps.boc_qr_m_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}BOC_QR_M_EN_5.jpg`,
          text: 'fps.boc_qr_m_5',
        },
      ],
      'zh-hk': [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_TC.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}BOC_QR_M_TC_1.jpg`,
          text: 'fps.boc_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}BOC_QR_M_TC_2.jpg`,
          text: 'fps.boc_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}BOC_QR_M_TC_3.jpg`,
          text: 'fps.boc_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}BOC_QR_M_TC_4.jpg`,
          text: 'fps.boc_qr_m_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}BOC_QR_M_TC_5.jpg`,
          text: 'fps.boc_qr_m_5',
        },
      ],
    },
  },
  HSB: {
    desktop: {
      en: [
        {
          key: 1,
          img: `${imgPathPrefix}HSB_QR_D_EN_1.jpg`,
          text: 'fps.hsb_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSB_QR_D_EN_2.jpg`,
          text: 'fps.hsb_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSB_QR_D_EN_3.jpg`,
          text: 'fps.hsb_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSB_QR_D_EN_4.jpg`,
          text: 'fps.hsb_qr_d_4',
        },
      ],
      'zh-hk': [
        {
          key: 1,
          img: `${imgPathPrefix}HSB_QR_D_TC_1.jpg`,
          text: 'fps.hsb_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSB_QR_D_TC_2.jpg`,
          text: 'fps.hsb_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSB_QR_D_TC_3.jpg`,
          text: 'fps.hsb_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSB_QR_D_TC_4.jpg`,
          text: 'fps.hsb_qr_d_4',
        },
      ],
    },
    mobile: {
      en: [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_EN.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}HSB_QR_M_EN_1.jpg`,
          text: 'fps.hsb_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSB_QR_M_EN_2.jpg`,
          text: 'fps.hsb_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSB_QR_M_EN_3.jpg`,
          text: 'fps.hsb_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSB_QR_M_EN_4.jpg`,
          text: 'fps.hsb_qr_m_4',
        },
      ],
      'zh-hk': [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_TC.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}HSB_QR_M_TC_1.jpg`,
          text: 'fps.hsb_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSB_QR_M_TC_2.jpg`,
          text: 'fps.hsb_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSB_QR_M_TC_3.jpg`,
          text: 'fps.hsb_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSB_QR_M_TC_4.jpg`,
          text: 'fps.hsb_qr_m_4',
        },
      ],
    },
  },
  HSBC: {
    desktop: {
      en: [
        {
          key: 1,
          img: `${imgPathPrefix}HSBC_QR_D_EN_1.jpg`,
          text: 'fps.hsbc_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSBC_QR_D_EN_2.jpg`,
          text: 'fps.hsbc_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSBC_QR_D_EN_3.jpg`,
          text: 'fps.hsbc_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSBC_QR_D_EN_4.jpg`,
          text: 'fps.hsbc_qr_d_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}HSBC_QR_D_EN_5.jpg`,
          text: 'fps.hsbc_qr_d_5',
        },
      ],
      'zh-hk': [
        {
          key: 1,
          img: `${imgPathPrefix}HSBC_QR_D_TC_1.jpg`,
          text: 'fps.hsbc_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSBC_QR_D_TC_2.jpg`,
          text: 'fps.hsbc_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSBC_QR_D_TC_3.jpg`,
          text: 'fps.hsbc_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSBC_QR_D_TC_4.jpg`,
          text: 'fps.hsbc_qr_d_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}HSBC_QR_D_TC_5.jpg`,
          text: 'fps.hsbc_qr_d_5',
        },
      ],
    },
    mobile: {
      en: [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_EN.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}HSBC_QR_M_EN_1.jpg`,
          text: 'fps.hsbc_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSBC_QR_M_EN_2.jpg`,
          text: 'fps.hsbc_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSBC_QR_M_EN_3.jpg`,
          text: 'fps.hsbc_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSBC_QR_M_EN_4.jpg`,
          text: 'fps.hsbc_qr_m_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}HSBC_QR_M_EN_5.jpg`,
          text: 'fps.hsbc_qr_m_5',
        },
        {
          key: 6,
          img: `${imgPathPrefix}HSBC_QR_M_EN_6.jpg`,
          text: 'fps.hsbc_qr_m_6',
        },
      ],
      'zh-hk': [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_TC.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}HSBC_QR_M_TC_1.jpg`,
          text: 'fps.hsbc_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}HSBC_QR_M_TC_2.jpg`,
          text: 'fps.hsbc_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}HSBC_QR_M_TC_3.jpg`,
          text: 'fps.hsbc_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}HSBC_QR_M_TC_4.jpg`,
          text: 'fps.hsbc_qr_m_4',
        },
        {
          key: 5,
          img: `${imgPathPrefix}HSBC_QR_M_TC_5.jpg`,
          text: 'fps.hsbc_qr_m_5',
        },
        {
          key: 6,
          img: `${imgPathPrefix}HSBC_QR_M_TC_6.jpg`,
          text: 'fps.hsbc_qr_m_6',
        },
      ],
    },
  },
  SC: {
    desktop: {
      en: [
        {
          key: 1,
          img: `${imgPathPrefix}SC_QR_D_EN_1.jpg`,
          text: 'fps.sc_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}SC_QR_D_EN_2.jpg`,
          text: 'fps.sc_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}SC_QR_D_EN_3.jpg`,
          text: 'fps.sc_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}SC_QR_D_EN_4.jpg`,
          text: 'fps.sc_qr_d_4',
        },
      ],
      'zh-hk': [
        {
          key: 1,
          img: `${imgPathPrefix}SC_QR_D_TC_1.jpg`,
          text: 'fps.sc_qr_d_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}SC_QR_D_TC_2.jpg`,
          text: 'fps.sc_qr_d_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}SC_QR_D_TC_3.jpg`,
          text: 'fps.sc_qr_d_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}SC_QR_D_TC_4.jpg`,
          text: 'fps.sc_qr_d_4',
        },
      ],
    },
    mobile: {
      en: [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_EN.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}SC_QR_M_EN_1.jpg`,
          text: 'fps.sc_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}SC_QR_M_EN_2.jpg`,
          text: 'fps.sc_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}SC_QR_M_EN_3.jpg`,
          text: 'fps.sc_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}SC_QR_M_EN_4.jpg`,
          text: 'fps.sc_qr_m_4',
        },
      ],
      'zh-hk': [
        {
          key: 0,
          img: `${imgPathPrefix}FPS_QR_TC.jpg`,
          text: 'fps.fps_qr_mobile',
        },
        {
          key: 1,
          img: `${imgPathPrefix}SC_QR_M_TC_1.jpg`,
          text: 'fps.sc_qr_m_1',
        },
        {
          key: 2,
          img: `${imgPathPrefix}SC_QR_M_TC_2.jpg`,
          text: 'fps.sc_qr_m_2',
        },
        {
          key: 3,
          img: `${imgPathPrefix}SC_QR_M_TC_3.jpg`,
          text: 'fps.sc_qr_m_3',
        },
        {
          key: 4,
          img: `${imgPathPrefix}SC_QR_M_TC_4.jpg`,
          text: 'fps.sc_qr_m_4',
        },
      ],
    },
  },
}

export const FPS_ID_GUIDES = {
  CITI: {
    en: [
      {
        key: 0,
        img: `${imgPathPrefix}FPS_ID_EN.jpg`,
        text: 'fps.fps_id',
      },
      {
        key: 1,
        img: `${imgPathPrefix}CITI_ID_EN_1.jpg`,
        text: 'fps.citi_id_1',
      },
      {
        key: 2,
        img: `${imgPathPrefix}CITI_ID_EN_2.jpg`,
        text: 'fps.citi_id_2',
      },
      {
        key: 3,
        img: `${imgPathPrefix}CITI_ID_EN_3.jpg`,
        text: 'fps.citi_id_3',
      },
      {
        key: 4,
        img: `${imgPathPrefix}CITI_ID_EN_4.jpg`,
        text: 'fps.citi_id_4',
      },
      {
        key: 5,
        img: `${imgPathPrefix}CITI_ID_EN_5.jpg`,
        text: 'fps.citi_id_5',
      },
    ],
    'zh-hk': [
      {
        key: 0,
        img: `${imgPathPrefix}FPS_ID_TC.jpg`,
        text: 'fps.fps_id',
      },
      {
        key: 1,
        img: `${imgPathPrefix}CITI_ID_TC_1.jpg`,
        text: 'fps.citi_id_1',
      },
      {
        key: 2,
        img: `${imgPathPrefix}CITI_ID_TC_2.jpg`,
        text: 'fps.citi_id_2',
      },
      {
        key: 3,
        img: `${imgPathPrefix}CITI_ID_TC_3.jpg`,
        text: 'fps.citi_id_3',
      },
      {
        key: 4,
        img: `${imgPathPrefix}CITI_ID_TC_4.jpg`,
        text: 'fps.citi_id_4',
      },
      {
        key: 5,
        img: `${imgPathPrefix}CITI_ID_TC_5.jpg`,
        text: 'fps.citi_id_5',
      },
    ],
  },
  BOC: {
    en: [
      { key: 0, img: `${imgPathPrefix}FPS_ID_EN.jpg`, text: 'fps.fps_id' },
      { key: 1, img: `${imgPathPrefix}BOC_ID_EN_1.jpg`, text: 'fps.boc_id_1' },
      { key: 2, img: `${imgPathPrefix}BOC_ID_EN_2.jpg`, text: 'fps.boc_id_2' },
      { key: 3, img: `${imgPathPrefix}BOC_ID_EN_3.jpg`, text: 'fps.boc_id_3' },
      { key: 4, img: `${imgPathPrefix}BOC_ID_EN_4.jpg`, text: 'fps.boc_id_4' },
      { key: 5, img: `${imgPathPrefix}BOC_ID_EN_5.jpg`, text: 'fps.boc_id_5' },
      { key: 6, img: `${imgPathPrefix}BOC_ID_EN_6.jpg`, text: 'fps.boc_id_6' },
      { key: 7, img: `${imgPathPrefix}BOC_ID_EN_7.jpg`, text: 'fps.boc_id_7' },
    ],
    'zh-hk': [
      { key: 0, img: `${imgPathPrefix}FPS_ID_TC.jpg`, text: 'fps.fps_id' },
      { key: 1, img: `${imgPathPrefix}BOC_ID_TC_1.jpg`, text: 'fps.boc_id_1' },
      { key: 2, img: `${imgPathPrefix}BOC_ID_TC_2.jpg`, text: 'fps.boc_id_2' },
      { key: 3, img: `${imgPathPrefix}BOC_ID_TC_3.jpg`, text: 'fps.boc_id_3' },
      { key: 4, img: `${imgPathPrefix}BOC_ID_TC_4.jpg`, text: 'fps.boc_id_4' },
      { key: 5, img: `${imgPathPrefix}BOC_ID_TC_5.jpg`, text: 'fps.boc_id_5' },
      { key: 6, img: `${imgPathPrefix}BOC_ID_TC_6.jpg`, text: 'fps.boc_id_6' },
      { key: 7, img: `${imgPathPrefix}BOC_ID_TC_7.jpg`, text: 'fps.boc_id_7' },
    ],
  },
  HSB: {
    en: [
      { key: 0, img: `${imgPathPrefix}FPS_ID_EN.jpg`, text: 'fps.fps_id' },
      { key: 1, img: `${imgPathPrefix}HSB_ID_EN_1.jpg`, text: 'fps.hsb_id_1' },
      { key: 2, img: `${imgPathPrefix}HSB_ID_EN_2.jpg`, text: 'fps.hsb_id_2' },
      { key: 3, img: `${imgPathPrefix}HSB_ID_EN_3.jpg`, text: 'fps.hsb_id_3' },
      { key: 4, img: `${imgPathPrefix}HSB_ID_EN_4.jpg`, text: 'fps.hsb_id_4' },
      { key: 5, img: `${imgPathPrefix}HSB_ID_EN_5.jpg`, text: 'fps.hsb_id_5' },
      { key: 6, img: `${imgPathPrefix}HSB_ID_EN_6.jpg`, text: 'fps.hsb_id_6' },
      { key: 7, img: `${imgPathPrefix}HSB_ID_EN_7.jpg`, text: 'fps.hsb_id_7' },
    ],
    'zh-hk': [
      { key: 0, img: `${imgPathPrefix}FPS_ID_TC.jpg`, text: 'fps.fps_id' },
      { key: 1, img: `${imgPathPrefix}HSB_ID_TC_1.jpg`, text: 'fps.hsb_id_1' },
      { key: 2, img: `${imgPathPrefix}HSB_ID_TC_2.jpg`, text: 'fps.hsb_id_2' },
      { key: 3, img: `${imgPathPrefix}HSB_ID_TC_3.jpg`, text: 'fps.hsb_id_3' },
      { key: 4, img: `${imgPathPrefix}HSB_ID_TC_4.jpg`, text: 'fps.hsb_id_4' },
      { key: 5, img: `${imgPathPrefix}HSB_ID_TC_5.jpg`, text: 'fps.hsb_id_5' },
      { key: 6, img: `${imgPathPrefix}HSB_ID_TC_6.jpg`, text: 'fps.hsb_id_6' },
      { key: 7, img: `${imgPathPrefix}HSB_ID_TC_7.jpg`, text: 'fps.hsb_id_7' },
    ],
  },
  HSBC: {
    en: [
      { key: 0, img: `${imgPathPrefix}FPS_ID_EN.jpg`, text: 'fps.fps_id' },
      {
        key: 1,
        img: `${imgPathPrefix}HSBC_ID_EN_1.jpg`,
        text: 'fps.hsbc_id_1',
      },
      {
        key: 2,
        img: `${imgPathPrefix}HSBC_ID_EN_2.jpg`,
        text: 'fps.hsbc_id_2',
      },
      {
        key: 3,
        img: `${imgPathPrefix}HSBC_ID_EN_3.jpg`,
        text: 'fps.hsbc_id_3',
      },
      {
        key: 4,
        img: `${imgPathPrefix}HSBC_ID_EN_4.jpg`,
        text: 'fps.hsbc_id_4',
      },
      {
        key: 5,
        img: `${imgPathPrefix}HSBC_ID_EN_5.jpg`,
        text: 'fps.hsbc_id_5',
      },
      {
        key: 6,
        img: `${imgPathPrefix}HSBC_ID_EN_6.jpg`,
        text: 'fps.hsbc_id_6',
      },
      {
        key: 7,
        img: `${imgPathPrefix}HSBC_ID_EN_7.jpg`,
        text: 'fps.hsbc_id_7',
      },
      {
        key: 8,
        img: `${imgPathPrefix}HSBC_ID_EN_8.jpg`,
        text: 'fps.hsbc_id_8',
      },
    ],
    'zh-hk': [
      { key: 0, img: `${imgPathPrefix}FPS_ID_TC.jpg`, text: 'fps.fps_id' },
      {
        key: 1,
        img: `${imgPathPrefix}HSBC_ID_TC_1.jpg`,
        text: 'fps.hsbc_id_1',
      },
      {
        key: 2,
        img: `${imgPathPrefix}HSBC_ID_TC_2.jpg`,
        text: 'fps.hsbc_id_2',
      },
      {
        key: 3,
        img: `${imgPathPrefix}HSBC_ID_TC_3.jpg`,
        text: 'fps.hsbc_id_3',
      },
      {
        key: 4,
        img: `${imgPathPrefix}HSBC_ID_TC_4.jpg`,
        text: 'fps.hsbc_id_4',
      },
      {
        key: 5,
        img: `${imgPathPrefix}HSBC_ID_TC_5.jpg`,
        text: 'fps.hsbc_id_5',
      },
      {
        key: 6,
        img: `${imgPathPrefix}HSBC_ID_TC_6.jpg`,
        text: 'fps.hsbc_id_6',
      },
      {
        key: 7,
        img: `${imgPathPrefix}HSBC_ID_TC_7.jpg`,
        text: 'fps.hsbc_id_7',
      },
      {
        key: 8,
        img: `${imgPathPrefix}HSBC_ID_TC_8.jpg`,
        text: 'fps.hsbc_id_8',
      },
    ],
  },
  SC: {
    en: [
      { key: 0, img: `${imgPathPrefix}FPS_ID_EN.jpg`, text: 'fps.fps_id' },
      { key: 1, img: `${imgPathPrefix}SC_ID_EN_1.jpg`, text: 'fps.sc_id_1' },
      { key: 2, img: `${imgPathPrefix}SC_ID_EN_2.jpg`, text: 'fps.sc_id_2' },
      { key: 3, img: `${imgPathPrefix}SC_ID_EN_3.jpg`, text: 'fps.sc_id_3' },
      { key: 4, img: `${imgPathPrefix}SC_ID_EN_4.jpg`, text: 'fps.sc_id_4' },
    ],
    'zh-hk': [
      { key: 0, img: `${imgPathPrefix}FPS_ID_TC.jpg`, text: 'fps.fps_id' },
      { key: 1, img: `${imgPathPrefix}SC_ID_TC_1.jpg`, text: 'fps.sc_id_1' },
      { key: 2, img: `${imgPathPrefix}SC_ID_TC_2.jpg`, text: 'fps.sc_id_2' },
      { key: 3, img: `${imgPathPrefix}SC_ID_TC_3.jpg`, text: 'fps.sc_id_3' },
      { key: 4, img: `${imgPathPrefix}SC_ID_TC_4.jpg`, text: 'fps.sc_id_4' },
    ],
  },
}

export default 0
