import { isProdEnv } from '@/utils/env'

export default {
  en: {
    aboutusUrl: 'https://divit.com.hk/about-us/',
    divitShopUrl: isProdEnv
      ? 'https://shop.divit.com.hk'
      : 'https://staging2.shop.divit.com.hk',
    faqUrl: 'https://divit.com.hk/support-center/#section-a',
    merchantsUrl: 'https://divit.com.hk/divit-merchants',
    email: 'hello@divit.com.hk',
  },
  'zh-hk': {
    aboutusUrl: 'https://divit.com.hk/about-us/',
    divitShopUrl: isProdEnv
      ? 'https://shop.divit.com.hk'
      : 'https://staging2.shop.divit.com.hk',
    faqUrl: 'https://divit.com.hk/support-center/#section-a',
    merchantsUrl: 'https://divit.com.hk/divit-merchants',
    email: 'hello@divit.com.hk',
  },
  'zh-cn': {
    aboutusUrl: 'https://divit.com.hk/about-us/',
    divitShopUrl: isProdEnv
      ? 'https://shop.divit.com.hk'
      : 'https://staging2.shop.divit.com.hk',
    faqUrl: 'https://divit.com.hk/support-center/#section-a',
    merchantsUrl: 'https://divit.com.hk/divit-merchants',
    email: 'hello@divit.com.hk',
  },
}
