import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import CancelPurchase from '@/components/CancelPurchase'
import ErrorScreen from '@/components/error/ErrorScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import PowerbyCiti from '@/components/PowerbyCiti'
import PurchaseSummaryShort from '@/components/PurchaseSummaryShort/PurchaseSummaryShort'
import Spacer from '@/components/Spacer'
import { useTheme } from '@/contexts/themeContext'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import {
  getFpsCitiServiceSelector,
  getFpsServiceSelector,
} from '@/redux/lookup/lookupSelector'
import { AirlineThemeByParnterId } from '@/utils/Airline'

import PaylaterFpsUnavailableScreen from './paylater/PaylaterFpsUnavailableScreen'

const Container = styled(PaddingContent)`
  padding: 0;
`
const BookButtonContainer = styled.div`
  padding: 0 0.667rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 calc(100% / 24);
  }
`

const BookButton = styled(Button)`
  font-size: 0.889rem;
`
const BackgroundContainer = styled.div`
  background: linear-gradient(43deg, #ffcc33 75%, rgba(255, 204, 51, 0) 150%);
  background-repeat: no-repeat;
  background-size: 100% 165px;
  background-size: ${(p) => p.isRetail ? '100% 165px' : '100% 210px'};

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    background-size: ${(p) => p.isRetail ? '100% 185px' : '100% 210px'};
  }
  /* height: 210px; */
`

const OrderPreview = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const { orderId } = useParams()
  const { changeTheme } = useTheme()

  const { order } = useSelector((state) => state.order)
  const { previewOrder } = useSelector((state) => state.paylater)
  const fpsService = useSelector(getFpsServiceSelector)
  const fpsCitiService = useSelector(getFpsCitiServiceSelector)
  const isFpsUnavailable = !!fpsService || !!fpsCitiService

  useEffect(() => {
    dispatch({ type: 'paylater/previewOrder', payload: { orderId } })

    return () => dispatch({ type: 'paylater/reset' })
  }, [])

  if (previewOrder.isError) {
    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={intl.formatMessage({ id: 'error.action.link.order' })}
        error={previewOrder.error}
        onOk={() => window.history.back()}
        okText={intl.formatMessage({ id: 'button.back' })}
      />
    )
  }

  if (!order) return <LoadingScreen />
  if (order.orderid !== orderId) return <></>

  const { partnerid } = order

  const theme = AirlineThemeByParnterId(partnerid)
  if (theme) {
    setTimeout(() => changeTheme(theme), 0)
  }

  const onClickNext = () => {
    // if user suddenly logged before click next, will go to redirect_url / home
    history.push('/signup')
  }

  // fps citi service unavailable
  if (isFpsUnavailable) {
    return (
      <PaylaterFpsUnavailableScreen
        order={order}
        periodEnd={fpsService?.periodEnd || fpsCitiService?.periodEnd}
      />
    )
  }

  return (
    <BackgroundContainer isRetail={order.orderItems && order.orderItems.length !== 0 && order?.orderItems[0]?.orderType === 'retail'}>
      <Container>
        <Spacer height="1.333rem" />
        <PurchaseSummaryShort
          title={intl.formatMessage({ id: 'booking.your.order' })}
          order={order}
        />
        <Spacer height="2.222rem" />
        <BookButtonContainer>
          <BookButton type="stretch" onClick={onClickNext}>
            {tt(intl, 'common.signuplogin')}
          </BookButton>
        </BookButtonContainer>
        <Spacer height="1.5rem" />
        <PowerbyCiti />
        <Spacer height="2rem" />
        <CancelPurchase />
        <Spacer height="2rem" />
      </Container>
    </BackgroundContainer>

  )
}

export default OrderPreview
