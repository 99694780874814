import { useState } from 'react'

import scrollToTop from '@/utils/scrollToTop'

const usePageError = () => {
  const [errorMessage, setErrorMessage] = useState('')

  function updateErrorMessage(errMsg) {
    if (errMsg) {
      scrollToTop(true)
    }
    setErrorMessage(errMsg)
  }

  return {
    errorMessage,
    updateErrorMessage,
  }
}

export default usePageError
