import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const checkoutGiftCardSlice = createSlice({
  name: 'checkoutGiftCard',
  initialState: {
    getProducts: AsyncState.create(),
    createOrder: AsyncState.create(),
    getOrder: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.getProducts = AsyncState.reset()
      state.createOrder = AsyncState.reset()
      state.getOrder = AsyncState.reset()
    },
    getProductsStart: (state) => {
      state.getProducts = AsyncState.start()
    },
    getProductsSuccess: (state, action) => {
      state.getProducts = AsyncState.success(action.payload)
    },
    getProductsFailed: (state, action) => {
      state.getProducts = AsyncState.failed(action.payload)
    },
    createOrderStart: (state) => {
      state.createOrder = AsyncState.start()
    },
    createOrderSuccess: (state, action) => {
      state.createOrder = AsyncState.success(action.payload)
    },
    createOrderFailed: (state, action) => {
      state.createOrder = AsyncState.failed(action.payload)
    },
    getOrderStart: (state) => {
      state.getOrder = AsyncState.start()
    },
    getOrderSuccess: (state, action) => {
      state.getOrder = AsyncState.success(action.payload)
    },
    getOrderFailed: (state, action) => {
      state.getOrder = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = checkoutGiftCardSlice

export default checkoutGiftCardSlice.reducer
