import 'rc-slider/assets/index.css'

import Slider from 'rc-slider'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as MilesSliderMinusSvg } from '@/assets/miles/slider/slider-minus.svg'
import { ReactComponent as MilesSliderPlusSvg } from '@/assets/miles/slider/slider-plus.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MilesSlider = ({
  defaultValue,
  min = 0,
  max = 0,
  value,
  onChange,
  adjustControls = false,
}) => {
  const isMinusEnabled = value > 0
  const isPlusEnabled = value < parseInt(max, 10)

  return (
    <Container>
      {adjustControls && (
        <>
          <Icon
            style={{ opacity: isMinusEnabled ? 1 : 0.3 }}
            renderImage={() => <MilesSliderMinusSvg />}
            width="1.77rem"
            height="1.77rem"
            onClick={() => onChange(Math.max(min, value - 1))}
          />
          <Spacer width="1rem" />
        </>
      )}
      <Slider
        // style={{ margin: '0 10px', }}
        defaultValue={defaultValue}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        railStyle={{
          // width: 'calc(100% + 10px)',
          backgroundColor: '#F0F0F0',
          height: 16,
          borderRadius: 8,
        }}
        trackStyle={{
          // left: -10,
          backgroundColor: '#FFCC33',
          height: 16,
          borderRadius: 8,
        }}
        handleStyle={{
          border: 0,
          width: 30,
          height: 30,
          marginTop: -7,
          backgroundColor: '#FFCC33',
          borderWidth: 3,
          borderStyle: 'solid',
          borderColor: 'white',
          borderRadius: '50%',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        }}
      />
      {adjustControls && (
        <>
          <Spacer width="1rem" />
          <Icon
            style={{ opacity: isPlusEnabled ? 1 : 0.3 }}
            renderImage={() => <MilesSliderPlusSvg />}
            width="1.77rem"
            height="1.77rem"
            onClick={() => onChange(Math.min(max, value + 1))}
          />
        </>
      )}
    </Container>
  )
}

export default MilesSlider
