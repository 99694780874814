import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import BackButton from '@/components/BackButton'
import OTPInputBox from '@/components/OTPInputBox'
import PhoneInput from '@/components/ui/PhoneInput'
import useCountDown from '@/hooks/useCountdown'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import PhoneParser from '@/utils/Phone'
import * as User from '@/utils/user'

import { Row, SubTitle, Title } from '../../pages/profile/Shared'
import BottomSheet from '../BottomSheet'
import ErrorMessageLine from '../ErrorMessageLine'
import Icon from '../Icon'
import Spacer from '../Spacer'

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const CSContent = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-bottom: 4.444rem;
`

const Content = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.111rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 calc(100% / 12);
  }

  ${(p) =>
    p.isFullWidth &&
    css`
      @media (min-width: 768px) {
        max-width: var(--full-width-mode-max-width);
        padding: 0;
        margin: 0 auto;
      }
    `}
`

const ResendButton = styled(Button)`
  margin: 0 auto;
  background-color: white;
  border: 1px solid #dedede;

  &:disabled {
    background-color: white;
  }
`

const DisplayId = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
  text-align: center;
`

const ContinueButton = styled(Button)`
  font-size: 0.89rem;
`

const AddPhoneNumber = ({
  defaultCountryCode,
  defaultPhoneNumber,
  onDone,
  onClose,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [countryCode, setCountryCode] = useState(defaultCountryCode)
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber)
  const [errorMessage, setErrorMessage] = useState('')

  const { sendOTP } = useSelector((s) => s.verifyPhone)

  useEffect(() => () => dispatch({ type: 'verifyPhone/reset' }), [])

  useEffect(() => {
    if (sendOTP.isSuccess) {
      onDone({ countryCode, phoneNumber })
    }
    if (sendOTP.isError) {
      setErrorMessage(intl.formatMessage({ id: 'error.action.sendotp.phone' }))
    } else {
      setErrorMessage('')
    }
  })

  const doSendOTP = () => {
    setErrorMessage('')

    dispatch({
      type: 'verifyPhone/sendOTP',
      payload: {
        tel: `${countryCode}${phoneNumber}`,
      },
    })
  }

  return (
    <Container>
      <CSContent>
        <Content>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'profile.editmobilenumber')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'profile.enterphone')}</SubTitle>
          <Spacer height="0.667rem" />
          <ErrorMessageLine errorMessage={errorMessage} />
          <Spacer height="0.667rem" />
          <PhoneInput
            id="tel"
            countryCode={countryCode}
            phoneNumber={phoneNumber}
            onChange={(data) => {
              setCountryCode(data.countryCode)
              setPhoneNumber(data.phoneNumber)
            }}
            placeholder={`${intl.formatMessage({ id: 'login.phone' })}`}
          />
          <Spacer height="2.222rem" />
          <ContinueButton
            type="stretch"
            disabled={phoneNumber && phoneNumber.length < 8}
            onClick={doSendOTP}
          >
            {tt(intl, 'common.continue')}
          </ContinueButton>
        </Content>
      </CSContent>
    </Container>
  )
}

const COUNT_DOWN_IN_SECOND = 60

const VerifyOTP = ({ countryCode, phoneNumber, onBack, onDone }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const otpInputBoxRef = useRef()
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [errorMessage, setErrorMessage] = useState('')

  const { countDownInSec, setCountDownInSec } = useCountDown({
    timeoutInSec: COUNT_DOWN_IN_SECOND,
  })

  const { sendOTP, verifyOTP } = useSelector((s) => s.verifyPhone)

  useEffect(() => () => dispatch({ type: 'verifyPhone/reset' }), [])

  useEffect(() => {
    if (sendOTP.isSuccess) {
      resetOTP()
      setCountDownInSec(COUNT_DOWN_IN_SECOND)
    }
    if (sendOTP.isError) {
      resetOTP()
      setErrorMessage(intl.formatMessage({ id: 'error.action.sendotp.phone' }))
    } else {
      setErrorMessage('')
    }
  }, [sendOTP])

  useEffect(() => {
    if (verifyOTP.isSuccess) {
      onDone()
    }
    if (verifyOTP.isError) {
      resetOTP()
      setErrorMessage(intl.formatMessage({ id: 'error.action.verify.phone' }))
    } else {
      setErrorMessage('')
    }
  }, [verifyOTP])

  const doVerifyOTP = (newOTP) => {
    dispatch({
      type: 'verifyPhone/verifyOTP',
      payload: {
        tel: `${countryCode}${phoneNumber}`,
        code: newOTP.join(''),
        inCheckout: false,
      },
    })
  }

  const doSendOTP = async () => {
    dispatch({
      type: 'verifyPhone/sendOTP',
      payload: {
        tel: `${countryCode}${phoneNumber}`,
      },
    })
  }

  const resetOTP = () => {
    setTimeout(() => {
      setOtp(['', '', '', '', '', ''])
      otpInputBoxRef.current?.resetFocus()
    }, 200)
  }

  const onChangeOTP = (newOTP) => {
    setOtp(newOTP)

    if (newOTP.length === 6 && newOTP.every((digit) => digit.length === 1)) {
      doVerifyOTP(newOTP)
    }
  }

  const displayId = User.getProfileId(countryCode, phoneNumber)
  return (
    <Container>
      <CSContent>
        <Content>
          <Spacer height="2.222rem" />
          <Row>
            <BackButton onClick={onBack} />
            <Title>{tt(intl, 'common.verify.mobile')}</Title>
          </Row>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'common.verify.mobile.code')}</SubTitle>
          <Spacer height="0.889rem" />
          <DisplayId>{displayId}</DisplayId>
          <Spacer height="1.111rem" />
          <Spacer height="1.111rem" />
          <OTPInputBox
            ref={otpInputBoxRef}
            otp={otp}
            onChangeOTP={onChangeOTP}
          />
          <Spacer height="2.222rem" />
          <ResendButton disabled={countDownInSec > 0} onClick={doSendOTP}>
            <span>{tt(intl, 'common.resend')} </span>
            {countDownInSec > 0 && <span>{`(${countDownInSec})`}</span>}
          </ResendButton>
        </Content>
      </CSContent>
    </Container>
  )
}

const StepSuccess = ({ onVerifyDone }) => {
  const intl = useIntl()

  return (
    <Container>
      <CSContent>
        <Content>
          <Spacer height="2.222rem" />
          <Icon
            renderImage={() => <SuccessOutlinedSvg />}
            width="1.736rem"
            height="1.736rem"
          />
          <Spacer height="0.889rem" />
          <Title>{tt(intl, 'profile.verification.success')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'profile.mobilenumber.verified')}</SubTitle>
          <Spacer height="2.222rem" />
          <ContinueButton type="stretch" onClick={onVerifyDone}>
            {tt(intl, 'common.continue')}
          </ContinueButton>
        </Content>
      </CSContent>
    </Container>
  )
}

const MobileSetupStepModal = ({ isOpen, onClose, profile }) => {
  const [step, setStep] = useState('init')
  const [countryCode, setCountryCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    if (profile && step === 'init') {
      if (profile.tel) {
        const parsedTel = PhoneParser(profile.tel)
        setCountryCode(parsedTel.countryCode)
        setPhoneNumber(parsedTel.localNumber)
      } else {
        setCountryCode('+852')
        setPhoneNumber('')
      }
      setStep('input')
    }
  }, [profile])

  const onInputDone = () => {
    setStep('verify')
  }

  const onVerifyDone = () => {
    setStep('success')
  }

  const onClickClose = () => {
    const fn = onClose
    fn?.()
  }

  const onClickBack = () => {
    if (step === 'verify') {
      setStep('input')
    }
  }

  return (
    <BottomSheet top open={isOpen} onDismiss={onClose}>
      {step === 'input' && (
        <AddPhoneNumber
          defaultCountryCode={countryCode}
          defaultPhoneNumber={phoneNumber}
          onDone={(data) => {
            setCountryCode(data.countryCode)
            setPhoneNumber(data.phoneNumber)
            onInputDone()
          }}
          onClose={onClickClose}
        />
      )}
      {step === 'verify' && (
        <VerifyOTP
          countryCode={countryCode}
          phoneNumber={phoneNumber}
          onBack={onClickBack}
          onDone={onVerifyDone}
        />
      )}
      {step === 'success' && (
        <StepSuccess
          onVerifyDone={() => {
            onClose()
            setStep('input')
          }}
        />
      )}
    </BottomSheet>
  )
}

export default MobileSetupStepModal
