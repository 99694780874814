import React from 'react'
import styled, { css } from 'styled-components'

import Line from '@/components/Line'
import LineItem from '@/components/LineItem'
import Spacer from '@/components/Spacer'
import Ticket from '@/components/Ticket'
import SlideShow from '@/components/ui/SlideShow'

const SlideShowStyled = styled(SlideShow)`
  ${(p) =>
    p.centerMode &&
    css`
      & ${TicketWapper} {
        padding-right: 0.889rem;
      }
      & ${TicketWapper}.last {
        padding-right: 0;
      }
    `}
  
  .carousel {
    display: flex;
    flex-direction: column-reverse
  }

  .control-dots{
    margin-bottom: 1.5rem
  }

  padding: 0 0 0 0.667rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 0 0 calc(100% / 24);
  }
`

const TicketWapper = styled.div`
  /* show ticket shadow */
  padding-top: 2px;
`

const TicketItem = styled(Ticket)`
  margin-bottom: 0.5rem !important;
`

const FlightItems = ({ items = [] }) => (
  <SlideShowStyled
    infiniteLoop={false}
    showIndicators={items.length > 1}
    centerMode={items.length > 1}
    centerSlidePercentage={90}
    images={items.map((item, index) => {
      const isLast = index === items.length - 1
      return {
        key: index,
        content: (
          <TicketWapper className={isLast && 'last'} key={index}>
            <TicketItem orderItem={item} index={index} />
          </TicketWapper>
        ),
      }
    })}
  />
)

const RetailItems = ({ items = [] }) => (
  <div>
    {items.map((item, index) => (
      <div key={index}>
        <Spacer height="0.444rem" />
        <LineItem orderItem={item} hidePic />
        <Spacer height="0.444rem" />
      </div>
    ))}
  </div>
)

const PurchaseItems = ({ items = [] }) => {
  // only support flight / retails, no mix
  const isRetail = items[0]?.orderType === 'retail'
  const isFlight = items[0]?.orderType === 'flight'
  if (isRetail) return <RetailItems items={items} />
  if (isFlight) return <FlightItems items={items} />
  return null
}

export default PurchaseItems
