import React, { useEffect, useRef, useState } from 'react'
import { Camera } from 'react-camera-pro'
import Resizer from 'react-image-file-resizer'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SwitchSvg } from '@/assets/common/switch-camera.svg'
import { ReactComponent as UploadSvg } from '@/assets/common/upload-image.svg'
import BackButton from '@/components/BackButton'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import FastClickButton from '@/components/ui/FastClickButton'
import UploadImageButton from '@/components/ui/UploadImageButton'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import { profileSelector } from '@/redux/profile/profileSelector'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'

import { ActionButtons, CancelButton, MainButton, Row, Title } from './Shared'

const Container = styled.div`
  background-color: #fff;
`
const PaddingRow = styled(Row)`
  padding: 0 1.2rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 calc(100% / 12);
  }
`
const PaddingActionButtons = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.2rem;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 0 calc(100% / 12);
  }
`
const CameraSection = styled.div`
  width: 100%;
  height: calc(100% - 10rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const CameraWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 500px;
  height: 100%;
  background-color: #111;

  & > div {
    color: #fff;
  }
`
const CameraTargetOutline = styled.div`
  position: absolute;
  width: 90%;
  height: calc(90vw / 1.64);
  margin: auto;
  max-width: 460px;
  max-height: 280px;
  border: 4px dotted #fc3;
  border-radius: 16px;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const CapturedImage = styled.div`
  width: 100%;
  max-height: 500px;
  z-index: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;

  & > img {
    height: fit-content;
  }
`

const CaptureButtonWrapper = styled.div`
  border-radius: 50%;
  border: 5px solid ${(p) => (p.disabled ? '#ccc' : '#555')};
  padding: 2px;
`
const CaptureButton = styled(FastClickButton)`
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${(p) => (p.disabled ? '#ccc' : '#555')};
  width: 3.5rem;
  height: 3.5rem;
  background-color: #eee;

  &:hover {
    border: 1px solid ${(p) => (p.disabled ? '#ccc' : 'transparent')};
    background-color: ${(p) => (p.disabled ? '#eee' : '#f55')};
  }
`
const SwitchCameraButton = styled(FastClickButton)`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  margin-right: 3rem;

  & > svg {
    height: auto;
    & path {
      fill: ${(p) => (p.disabled ? '#ddd' : '#555')};
    }
  }

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin-right: 4rem;
  }
`

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      960,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64'
    )
  })

const ProfileIdentity = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const cameraRef = useRef(null)
  const [numberOfCameras, setNumberOfCameras] = useState(0)
  const [image, setImage] = useState(null)

  const profile = useSelector(profileSelector)
  const { updateProfileIdentity } = useSelector((s) => s.updateProfile)

  // disable this function in production
  if (process.env.NODE_ENV === 'production') {
    history.replace('/profile/personal')
  }

  const onClickBack = () => {
    dispatch(goBack())
  }
  const onClickSwitchCamera = () => {
    if (numberOfCameras > 1) {
      cameraRef.current.switchCamera()
    }
  }
  const onClickTakePhoto = () => {
    if (numberOfCameras > 0) {
      setImage(cameraRef.current.takePhoto())
    }
  }
  const onClickResetImage = () => {
    setImage(null)
  }
  const onClickConfirm = () => {
    dispatch({
      type: 'updateProfile/updateIdentity',
      payload: { customerID: profile.customerID, base64Image: image },
    })
  }
  const onSelectFile = async (e) => {
    const resizedImage = await resizeFile(e.value?.[0])
    setImage(resizedImage)
  }

  useEffect(() => {
    if (updateProfileIdentity.isSuccess) {
      dispatch(
        ToastActions.addToast({
          message: tt(intl, 'profile.identity.uploaded'),
        })
      )
      history.replace('/profile/personal')
    }
  }, [updateProfileIdentity])

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Spacer height="2rem" />
        <PaddingRow>
          <BackButton onClick={onClickBack} />
          <Title>{tt(intl, 'camera.identity.title')}</Title>
        </PaddingRow>
        <Spacer height="1.2rem" />
        <CameraSection>
          <CameraWrapper>
            {image && (
              <CapturedImage>
                <img src={image} alt="captured" />
              </CapturedImage>
            )}
            {!image && (
              <Camera
                ref={cameraRef}
                facingMode="environment"
                aspectRatio="1.3333"
                errorMessages={{
                  noCameraAccessible: tt(intl, 'camera.nocamera'),
                  permissionDenied: tt(intl, 'camera.permissiondenied'),
                  canvas: tt(intl, 'camera.nosupport'),
                }}
                numberOfCamerasCallback={setNumberOfCameras}
              />
            )}
            {numberOfCameras > 0 && !image && <CameraTargetOutline />}
          </CameraWrapper>
        </CameraSection>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <Container>
          <Spacer height="2rem" />
          <PaddingActionButtons>
            {!image && (
              <>
                <SwitchCameraButton
                  disabled={numberOfCameras <= 1}
                  onPress={onClickSwitchCamera}
                >
                  <SwitchSvg />
                </SwitchCameraButton>
                <CaptureButtonWrapper disabled={numberOfCameras === 0}>
                  <CaptureButton
                    disabled={numberOfCameras === 0}
                    onPress={onClickTakePhoto}
                  />
                </CaptureButtonWrapper>
                <UploadImageButton
                  id="files"
                  accept="image/jpg,image/jpeg,image/png"
                  onChange={onSelectFile}
                >
                  <UploadSvg />
                </UploadImageButton>
              </>
            )}
          </PaddingActionButtons>
          {image && (
            <PaddingContent>
              <ActionButtons>
                <CancelButton onClick={onClickResetImage}>
                  {tt(intl, 'common.cancel')}
                </CancelButton>
                <MainButton onClick={onClickConfirm}>
                  {tt(intl, 'common.confirm')}
                </MainButton>
              </ActionButtons>
            </PaddingContent>
          )}
        </Container>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default ProfileIdentity
