import { createSlice } from '@reduxjs/toolkit'

// toast: { id, type, message }

const initialState = {
  toasts: [],
}

const ToastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    reset: () => initialState,
    addToast: (state, { payload }) => {
      state.toasts.push({
        id: String(Date.now()),
        type: payload.type || 'success',
        message: payload.message || '',
      })
      state.toasts = state.toasts.slice(-3)
    },
    removeToast: (state, { payload }) => {
      state.toasts = state.toasts.filter((t) => t.id !== payload.id)
    },
  },
})

export const { actions } = ToastSlice

export default ToastSlice.reducer
