import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const pageInitSlice = createSlice({
  name: 'pageInit',
  initialState: {
    initApp: AsyncState.create(),
    initAppAgain: AsyncState.create(),
    initAllOrders: AsyncState.create(),
    initOrder: AsyncState.create(),
    initPaynowOrders: AsyncState.create(),
    initPaynowFastOrder: AsyncState.create(),
    initPaynowOrder: AsyncState.create(),
    initProfileMain: AsyncState.create(),
    initProfileGiftCardBrands: AsyncState.create(),
    initProfileGiftCards: AsyncState.create(),
    initProfile: AsyncState.create(),
    initProfileEDDA: AsyncState.create(),
    initDivitMilesProfile: AsyncState.create(),
    initDivitMilesHistory: AsyncState.create(),
    initDivitMilesSpend: AsyncState.create(),
    initCheckoutOrderPreview: AsyncState.create(),
    initCheckoutOrder: AsyncState.create(),
    initCheckoutProfile: AsyncState.create(),
    initPaymentFinished: AsyncState.create(),
    initPaymentDetails: AsyncState.create(),
    initOffers: AsyncState.create(),
    initShop: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.initApp = AsyncState.reset()
      state.initAppAgain = AsyncState.reset()
      state.initAllOrders = AsyncState.reset()
      state.initOrder = AsyncState.reset()
      state.initPaynowOrders = AsyncState.reset()
      state.initPaynowFastOrder = AsyncState.reset()
      state.initPaynowOrder = AsyncState.reset()
      state.initProfileMain = AsyncState.reset()
      state.initProfileGiftCardBrands = AsyncState.reset()
      state.initProfileGiftCards = AsyncState.reset()
      state.initProfile = AsyncState.reset()
      state.initProfileEDDA = AsyncState.reset()
      state.initDivitMilesProfile = AsyncState.reset()
      state.initDivitMilesHistory = AsyncState.reset()
      state.initDivitMilesSpend = AsyncState.reset()
      state.initCheckoutOrderPreview = AsyncState.reset()
      state.initCheckoutOrder = AsyncState.reset()
      state.initCheckoutProfile = AsyncState.reset()
      state.initPaymentFinished = AsyncState.reset()
      state.initPaymentDetails = AsyncState.reset()
      state.initOffers = AsyncState.reset()
      state.initShop = AsyncState.reset()
    },
    initAppStart: (state) => {
      state.initApp = AsyncState.start()
    },
    initAppSuccess: (state, action) => {
      state.initApp = AsyncState.success(action.payload)
    },
    initAppFailed: (state, action) => {
      state.initApp = AsyncState.failed(action.payload)
    },
    initAppAgainStart: (state) => {
      state.initAppAgain = AsyncState.start()
    },
    initAppAgainSuccess: (state, action) => {
      state.initAppAgain = AsyncState.success(action.payload)
    },
    initAppAgainFailed: (state, action) => {
      state.initAppAgain = AsyncState.failed(action.payload)
    },
    initAllOrdersStart: (state) => {
      state.initAllOrders = AsyncState.start()
    },
    initAllOrdersSuccess: (state, action) => {
      state.initAllOrders = AsyncState.success(action.payload)
    },
    initAllOrdersFailed: (state, action) => {
      state.initAllOrders = AsyncState.failed(action.payload)
    },
    initPaynowOrdersStart: (state) => {
      state.initPaynowOrders = AsyncState.start()
    },
    initPaynowOrdersSuccess: (state, action) => {
      state.initPaynowOrders = AsyncState.success(action.payload)
    },
    initPaynowOrdersFailed: (state, action) => {
      state.initPaynowOrders = AsyncState.failed(action.payload)
    },
    initOrderStart: (state) => {
      state.initOrder = AsyncState.start()
    },
    initOrderSuccess: (state, action) => {
      state.initOrder = AsyncState.success(action.payload)
    },
    initOrderFailed: (state, action) => {
      state.initOrder = AsyncState.failed(action.payload)
    },
    initPaynowFastOrderStart: (state) => {
      state.initPaynowFastOrder = AsyncState.start()
    },
    initPaynowFastOrderSuccess: (state, action) => {
      state.initPaynowFastOrder = AsyncState.success(action.payload)
    },
    initPaynowFastOrderFailed: (state, action) => {
      state.initPaynowFastOrder = AsyncState.failed(action.payload)
    },
    initPaynowOrderStart: (state) => {
      state.initPaynowOrder = AsyncState.start()
    },
    initPaynowOrderSuccess: (state, action) => {
      state.initPaynowOrder = AsyncState.success(action.payload)
    },
    initPaynowOrderFailed: (state, action) => {
      state.initPaynowOrder = AsyncState.failed(action.payload)
    },
    initProfileMainStart: (state) => {
      state.initProfileMain = AsyncState.start()
    },
    initProfileMainSuccess: (state, action) => {
      state.initProfileMain = AsyncState.success(action.payload)
    },
    initProfileMainFailed: (state, action) => {
      state.initProfileMain = AsyncState.failed(action.payload)
    },
    initProfileGiftCardBrandsStart: (state) => {
      state.initProfileGiftCardBrands = AsyncState.start()
    },
    initProfileGiftCardBrandsSuccess: (state, action) => {
      state.initProfileGiftCardBrands = AsyncState.success(action.payload)
    },
    initProfileGiftCardBrandsFailed: (state, action) => {
      state.initProfileGiftCardBrands = AsyncState.failed(action.payload)
    },
    initProfileGiftCardsStart: (state) => {
      state.initProfileGiftCards = AsyncState.start()
    },
    initProfileGiftCardsSuccess: (state, action) => {
      state.initProfileGiftCards = AsyncState.success(action.payload)
    },
    initProfileGiftCardsFailed: (state, action) => {
      state.initProfileGiftCards = AsyncState.failed(action.payload)
    },
    initProfileStart: (state) => {
      state.initProfile = AsyncState.start()
    },
    initProfileSuccess: (state, action) => {
      state.initProfile = AsyncState.success(action.payload)
    },
    initProfileFailed: (state, action) => {
      state.initProfile = AsyncState.failed(action.payload)
    },
    initProfileEDDAStart: (state) => {
      state.initProfileEDDA = AsyncState.start()
    },
    initProfileEDDASuccess: (state, action) => {
      state.initProfileEDDA = AsyncState.success(action.payload)
    },
    initProfileEDDAFailed: (state, action) => {
      state.initProfileEDDA = AsyncState.failed(action.payload)
    },
    initDivitMilesProfileStart: (state) => {
      state.initDivitMilesProfile = AsyncState.start()
    },
    initDivitMilesProfileSuccess: (state, action) => {
      state.initDivitMilesProfile = AsyncState.success(action.payload)
    },
    initDivitMilesProfileFailed: (state, action) => {
      state.initDivitMilesProfile = AsyncState.failed(action.payload)
    },
    initDivitMilesHistoryStart: (state) => {
      state.initDivitMilesHistory = AsyncState.start()
    },
    initDivitMilesHistorySuccess: (state, action) => {
      state.initDivitMilesHistory = AsyncState.success(action.payload)
    },
    initDivitMilesHistoryFailed: (state, action) => {
      state.initDivitMilesHistory = AsyncState.failed(action.payload)
    },
    initDivitMilesSpendStart: (state) => {
      state.initDivitMilesSpend = AsyncState.start()
    },
    initDivitMilesSpendSuccess: (state, action) => {
      state.initDivitMilesSpend = AsyncState.success(action.payload)
    },
    initDivitMilesSpendFailed: (state, action) => {
      state.initDivitMilesSpend = AsyncState.failed(action.payload)
    },
    initCheckoutOrderPreviewStart: (state) => {
      state.initCheckoutOrderPreview = AsyncState.start()
    },
    initCheckoutOrderPreviewSuccess: (state, action) => {
      state.initCheckoutOrderPreview = AsyncState.success(action.payload)
    },
    initCheckoutOrderPreviewFailed: (state, action) => {
      state.initCheckoutOrderPreview = AsyncState.failed(action.payload)
    },
    initCheckoutOrderStart: (state) => {
      state.initCheckoutOrder = AsyncState.start()
    },
    initCheckoutOrderSuccess: (state, action) => {
      state.initCheckoutOrder = AsyncState.success(action.payload)
    },
    initCheckoutOrderFailed: (state, action) => {
      state.initCheckoutOrder = AsyncState.failed(action.payload)
    },
    initCheckoutProfileStart: (state) => {
      state.initCheckoutProfile = AsyncState.start()
    },
    initCheckoutProfileSuccess: (state, action) => {
      state.initCheckoutProfile = AsyncState.success(action.payload)
    },
    initCheckoutProfileFailed: (state, action) => {
      state.initCheckoutProfile = AsyncState.failed(action.payload)
    },
    initPaymentFinishedStart: (state) => {
      state.initPaymentFinished = AsyncState.start()
    },
    initPaymentFinishedSuccess: (state, action) => {
      state.initPaymentFinished = AsyncState.success(action.payload)
    },
    initPaymentFinishedFailed: (state, action) => {
      state.initPaymentFinished = AsyncState.failed(action.payload)
    },
    initPaymentDetailsStart: (state) => {
      state.initPaymentDetails = AsyncState.start()
    },
    initPaymentDetailsSuccess: (state, action) => {
      state.initPaymentDetails = AsyncState.success(action.payload)
    },
    initPaymentDetailsFailed: (state, action) => {
      state.initPaymentDetails = AsyncState.failed(action.payload)
    },
    initOffersStart: (state) => {
      state.initOffers = AsyncState.start()
    },
    initOffersSuccess: (state, action) => {
      state.initOffers = AsyncState.success(action.payload)
    },
    initOffersFailed: (state, action) => {
      state.initOffers = AsyncState.failed(action.payload)
    },
    initShopStart: (state) => {
      state.initShop = AsyncState.start()
    },
    initShopSuccess: (state, action) => {
      state.initShop = AsyncState.success(action.payload)
    },
    initShopFailed: (state, action) => {
      state.initShop = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = pageInitSlice

export default pageInitSlice.reducer
