import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as SuccessSvg } from '@/assets/miles/success.svg'
import BottomSheet from '@/components/BottomSheet'
import { Button } from '@/components/Divit'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import MilesConvertLabel from '@/components/miles/MilesConvertLabel'
import Spacer from '@/components/Spacer'
import MilesPrograms from '@/constants/MilesPrograms'
import { tt } from '@/locales/format'

const Content = styled(PaddingContent)`
  width: 100%;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  box-sizing: border-box;
`

const Title = styled.div`
  font-weight: 700;
  text-align: center;
  font-size: 1.33rem;
`

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ItemRowTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemRowValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const TxnTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const TxnDate = styled.div`
  font-size: 0.778rem;
  font-weight: 700;
`

const MilesProgramTitle = styled.div`
  font-size: 0.886rem;
  font-weight: 700;
`

const MilesProgramId = styled.div`
  font-size: 0.778rem;
  color: #979797;
  font-weight: 500;
  text-align: right;
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1;
  }

  & > *:first-child {
    margin-right: 0.889rem;
  }
`

const MainButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  color: #1c1c1c;
  padding: 1rem 0;
`

const CancelButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  background-color: transparent;
  color: #1c1c1c;
  padding: 1rem 0;
  border: 1px solid #dedede;
`

const ConfirmContent = ({
  profileId,
  programInfo,
  membershipID,
  milesToConvert,
  error,
  onClose,
  onClickConvert,
}) => {
  const intl = useIntl()

  return (
    <Content>
      <Spacer height="2.22rem" />
      <Title>{tt(intl, 'common.review')}</Title>
      <Spacer height="1.1rem" />
      <ErrorMessageBox errorMessage={error?.message} />
      <Spacer height="1.1rem" />
      <ItemRow>
        <ItemRowTitle>{tt(intl, 'common.from')}</ItemRowTitle>
        <ItemRowValue>
          <MilesProgramTitle>{tt(intl, 'miles.divitmiles')}</MilesProgramTitle>
          <Spacer height="8px" />
          <MilesProgramId>{profileId}</MilesProgramId>
        </ItemRowValue>
      </ItemRow>
      <Spacer height="1rem" />
      <ItemRow>
        <ItemRowTitle>{tt(intl, 'common.to')}</ItemRowTitle>
        <ItemRowValue>
          <MilesProgramTitle>{programInfo.name}</MilesProgramTitle>
          <Spacer height="8px" />
          <MilesProgramId>{membershipID}</MilesProgramId>
        </ItemRowValue>
      </ItemRow>
      <Spacer height="1rem" />
      <Line />
      <Spacer height="1rem" />
      <ItemRow>
        <TxnTitle>{tt(intl, 'miles.convert.total')}</TxnTitle>
        <MilesConvertLabel
          type="out"
          fontSize="0.95rem"
          programInfo={programInfo}
          miles={milesToConvert}
        />
      </ItemRow>
      <Spacer height="2rem" />
      <ActionButtons>
        <CancelButton onClick={onClose}>
          {tt(intl, 'common.cancel')}
        </CancelButton>
        <MainButton onClick={onClickConvert}>
          {tt(intl, 'common.confirm')}
        </MainButton>
      </ActionButtons>
      <Spacer height="2.22rem" />
    </Content>
  )
}

const SuccessContent = ({
  profileId,
  programInfo,
  membershipID,
  milesToConvert,
  milesBalance,
  createdAt,
  goToHome,
  goToOffers,
  onClose,
}) => {
  const intl = useIntl()

  return (
    <Content>
      <Spacer height="2.22rem" />
      <Icon
        renderImage={() => <SuccessSvg />}
        width="1.73rem"
        height="1.73rem"
      />
      <Spacer height="0.88rem" />
      <Title>{tt(intl, 'miles.convert.success')}</Title>
      <Spacer height="2.22rem" />
      <ItemRow>
        <ItemRowTitle>{tt(intl, 'common.from')}</ItemRowTitle>
        <ItemRowValue>
          <MilesProgramTitle>divit</MilesProgramTitle>
          <Spacer height="8px" />
          <MilesProgramId>{profileId}</MilesProgramId>
        </ItemRowValue>
      </ItemRow>
      <Spacer height="1rem" />
      <ItemRow>
        <ItemRowTitle>{tt(intl, 'common.to')}</ItemRowTitle>
        <ItemRowValue>
          <MilesProgramTitle>{programInfo.name}</MilesProgramTitle>
          <Spacer height="8px" />
          <MilesProgramId>{membershipID}</MilesProgramId>
        </ItemRowValue>
      </ItemRow>
      <Spacer height="1rem" />
      <Line />
      <Spacer height="1rem" />
      <ItemRow>
        <TxnTitle>{tt(intl, 'miles.converted.total')}</TxnTitle>
        <MilesConvertLabel
          type="out"
          programInfo={programInfo}
          miles={milesToConvert}
        />
      </ItemRow>
      <Spacer height="0.889rem" />
      <ItemRow>
        <TxnTitle>{tt(intl, 'miles.converted.on')}</TxnTitle>
        <TxnDate>{moment(createdAt).format('LLL')}</TxnDate>
      </ItemRow>
      <Spacer height="2rem" />
      <ActionButtons>
        <CancelButton onClick={goToHome}>
          {tt(intl, 'common.homepage')}
        </CancelButton>
        {milesBalance === 0 ? (
          <MainButton onClick={goToOffers}>
            {tt(intl, 'miles.shoptoearn')}
          </MainButton>
        ) : (
          <MainButton onClick={onClose}>
            {tt(intl, 'miles.convert.more')}
          </MainButton>
        )}
      </ActionButtons>
      <Spacer height="2.22rem" />
    </Content>
  )
}

const MilesConvertModal = ({
  isOpen,
  goToHome,
  goToOffers,
  onClose,
  onCleanup,
  profileId,
  program,
  membershipID,
  milesToConvert = 0,
  milesBalance = 0,
  createdAt,
  onClickConvert,
  isSuccess = false,
  error,
}) => {
  const programInfo = MilesPrograms.find((p) => p.key === program)

  const onLeave = () => {
    // onCleanup()
  }

  if (!programInfo) return null

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      onSpringEnd={(e) => e.type === 'CLOSE' && onLeave()}
    >
      {isSuccess ? (
        <SuccessContent
          profileId={profileId}
          programInfo={programInfo}
          membershipID={membershipID}
          milesToConvert={milesToConvert}
          milesBalance={milesBalance}
          goToHome={goToHome}
          goToOffers={goToOffers}
          onClose={onCleanup}
        />
      ) : (
        <ConfirmContent
          profileId={profileId}
          programInfo={programInfo}
          membershipID={membershipID}
          milesToConvert={milesToConvert}
          createdAt={createdAt}
          error={error}
          onClickConvert={onClickConvert}
          onClose={onClose}
        />
      )}
    </BottomSheet>
  )
}

export default MilesConvertModal
