import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import routes from '@/routes'
import { findRouteByPath, getRouteName } from '@/utils/Route'

// log screen_view to ga4

const useScreenView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const logScreenView = (loc) => {
      const route = findRouteByPath(routes, loc.pathname)
      const routeName = getRouteName(route)
      dispatch({
        type: 'gtm/sendEvent',
        payload: {
          event: 'screen_view',
          screen_name: routeName,
        },
      })
    }

    logScreenView(location)

    const unlisten = history.listen((loc) => {
      logScreenView(loc)
    })

    return () => unlisten()
  }, [])

  return null
}

export default useScreenView
