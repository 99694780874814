import snsWebSdk from '@sumsub/websdk'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import CancelPurchase from '@/components/CancelPurchase'
import { A } from '@/components/Divit'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import NormalMessageBox from '@/components/NormalMessageBox'
import Spacer from '@/components/Spacer'
import { KYC_TYPE } from '@/constants/kyc'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import {
  hasPayLaterOrderSelector,
  orderSelector,
} from '@/redux/order/orderSelector'
import { profileSelector } from '@/redux/profile/profileSelector'
import DivitAPI from '@/services/api'
import { isTestEnv } from '@/utils/env'
import { canBypassKyc, getLatestKycType, isAdditionalSelfie } from '@/utils/kyc'
import { devLog } from '@/utils/log'
import { isCheckout, IsPaylatering } from '@/utils/Route'

const getAccessToken = async (kycType) => {
  const { data } = await DivitAPI.get(
    `/profiles/kyc/accesstoken?type=${kycType}`
  )
  return data?.data?.token
}

const langMap = (locale) => {
  switch (locale) {
    case 'zh-hk':
      return 'zh-tw'
    case 'zh-cn':
      return 'zh'
    default:
      return 'en'
  }
}

const Container = styled(PaddingContent)``

const AnchorWrapper = styled.div`
  text-align: center;
`
const SumsubWrapper = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 1.5rem;
`

const KYC = () => {
  const { currentLocale } = useLocale()
  const history = useHistory()
  const dispatch = useDispatch()
  const intl = useIntl()
  const location = useLocation()
  const { pathname, state } = location
  const { requestKycType, previousPage, nextPage, isWait, orderLink } =
    state || {}

  const isPaylatering = IsPaylatering(pathname)
  const isCheckoutRoute = isCheckout()
  const orderId = sessionStorage.getItem('checkout_order')
  const isCheckouting = isPaylatering && isCheckoutRoute && orderId

  const [isReady, setIsReady] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const profile = useSelector(profileSelector)
  const hasPaylaterOrder = useSelector(hasPayLaterOrderSelector)

  const initAction = isCheckouting
    ? { type: 'pageInit/initCheckoutOrder', payload: { orderId } }
    : { type: 'pageInit/initProfile' }
  const { retry, isInitLoading, isInitError, isInitSuccess } = usePage({
    initAction,
  })

  // case 1: order required type
  // case 2: normal edit use latest one
  // case 3: parameter
  let kycType = KYC_TYPE.PARTIAL
  if (requestKycType) {
    kycType = requestKycType
  }

  // add message if only need selfie for over 3k order
  // const needSelfieForOrder =
  //   isCheckouting &&
  //   kycType === KYC_TYPE.FULL &&
  //   isAdditionalSelfie(profile?.kyc)

  const lang = langMap(currentLocale)
  const goNext = () => {
    if (nextPage) {
      // history.replace(nextPage)
    } else if (previousPage) {
      history.replace(previousPage)
    } else {
      console.warn('go next but no where to go')
    }
  }

  /**
   * @param isTest - is in test environment
   * @param applicantEmail - applicant email (not required)
   * @param applicantPhone - applicant phone, if available (not required)
   * @param customI18nMessages - customized locale messages for current session (not required)
   */
  const launchWebSdk = async ({
    isTest,
    applicantEmail,
    applicantPhone,
    customI18nMessages,
  }) => {
    const accessToken = await getAccessToken(kycType)
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => getAccessToken(kycType))
      .withConf({
        lang,
        email: applicantEmail,
        phone: applicantPhone,
        i18n: customI18nMessages,
      })

    if (isTestEnv) {
      // only capture other message that didnt listen yet
      snsWebSdkInstance = snsWebSdkInstance.onMessage((type, payload) =>
        console.log(type, payload)
      )
    }

    snsWebSdkInstance = snsWebSdkInstance
      .on('idCheck.onReady', () => {
        dispatch({
          type: 'gtm/sendEvent',
          payload: {
            event: 'begin_kyc',
            userId: profile.customerID,
          },
        })
      })
      .on('idCheck.onApplicantLoaded', () => {
        setIsReady(true)
      })
      .on('idCheck.applicantStatus', (payload) => {
        devLog('idCheck.applicantStatus', payload)

        // wait for pending if partial kyc
        if (
          !isWait &&
          kycType === KYC_TYPE.PARTIAL &&
          !hasPaylaterOrder &&
          ['pending', 'queued', 'onHold'].indexOf(payload.reviewStatus) >= 0
        ) {
          goNext()
        }

        // wait for complete if full kyc or have purchased before
        if (
          payload.reviewStatus === 'completed' &&
          payload.reviewResult.reviewAnswer === 'GREEN'
        ) {
          setTimeout(() => {
            goNext()
          }, 2 * 1000)
        }
      })
      .on('idCheck.onApplicantSubmitted', (payload) => {
        devLog('idCheck.onApplicantSubmitted', payload)
        setIsSubmitted(true)
      })
      .on('idCheck.onError', (err) => {
        console.error('kyc websdk onError', err)
      })

    snsWebSdkInstance = snsWebSdkInstance.build()
    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }

  useEffect(() => {
    // TOFIX: called twice here
    if (isInitSuccess) {
      if (
        !isWait &&
        canBypassKyc(profile.kyc, kycType, hasPaylaterOrder) &&
        profile.nationalID
      ) {
        goNext()
      } else {
        launchWebSdk({ isTest: isTestEnv })
      }
    }
  }, [isInitSuccess, currentLocale])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickVerifyLater = (e) => {
    e.preventDefault()

    dispatch({
      type: 'gtm/sendEvent',
      payload: {
        event: 'skip_kyc',
        userId: profile.customerID,
      },
    })

    history.push(nextPage)
  }

  const onClickSkip = (e) => {
    e.preventDefault()
    history.push({
      pathname: '/paylater/skip-kyc',
      state: {
        nextPage: `/order/${orderId}`,
      },
    })
  }

  return (
    <Container>
      <Spacer height="1.2rem" />
      {/* {needSelfieForOrder && !isSubmitted && (
        <>
          <NormalMessageBox>{tt(intl, 'paylater.kyc.level2')}</NormalMessageBox>
        </>
      )} */}
      <SumsubWrapper>
        <div id="sumsub-websdk-container" />
      </SumsubWrapper>
      {isReady && previousPage && (
        <AnchorWrapper>
          <A href={previousPage}>
            {intl.formatMessage({ id: 'back.to.booking' })}
          </A>
        </AnchorWrapper>
      )}
      {orderLink && isTestEnv && (
        <AnchorWrapper>
          <A href="#" onClick={onClickSkip}>
            {tt(intl, 'common.skip')}
          </A>
        </AnchorWrapper>
      )}
      <Spacer height="2rem" />
      {isPaylatering && (
        <>
          <CancelPurchase />
          <Spacer height="2rem" />
        </>
      )}
    </Container>
  )
}

export default KYC
