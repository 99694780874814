import React from 'react'
import { Redirect, useLocation, useParams } from 'react-router-dom'

import { IsPaylatering } from '@/utils/Route'

const Payment = () => {
  const { pathname } = useLocation()
  const isPaylatering = IsPaylatering(pathname)
  const { orderId, instalmentId } = useParams()
  if (isPaylatering) {
    return <Redirect to={`/pay/fps/paylater/${orderId}/${instalmentId}`} />
  }
  return <Redirect to={`/pay/fps/paynow/${orderId}/${instalmentId}`} />
}

export default Payment
