import React, { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-yellow-icon.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.667rem;
  padding: 1rem 1rem;
  margin: 0 auto;
  background-color: white;
  border: 1px solid rgba(28, 28, 28, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
`

const Input = styled(CurrencyInput)`
  width: 100%;
  font-size: 0.889rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 0;
  border: 0;
  text-align: right;
`

const MilesInput = ({ max, min = 0, value, onChange }) => {
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState(() => String(value))

  useEffect(() => {
    setInputValue(String(value))
  }, [value])

  const onClickInput = () => {
    inputRef.current.focus()
  }

  const onChangeMiles = (v) => {
    if (max < parseFloat(v)) {
      const maxValue = Math.round(max * 100) / 100
      setInputValue(String(maxValue))
      onChange(maxValue)
      inputRef.current?.blur()
    } else {
      setInputValue(v)
      onChange(parseInt(v || '0', 10))
    }
  }

  const onBlur = () => {
    if (!inputValue) setInputValue('0')
    if (parseFloat(inputValue) < min) {
      const minValue = Math.round(min * 100) / 100
      setInputValue(String(minValue))
      onChange(minValue)
    }
  }

  return (
    <Container onClick={onClickInput}>
      <Icon
        renderImage={() => <DivitMilesSvg />}
        width="1.111rem"
        height="1.111rem"
      />
      <Spacer width="4px" />
      <Input
        ref={inputRef}
        decimalsLimit={0}
        disableAbbreviations
        onBlur={onBlur}
        defaultValue={inputValue}
        value={inputValue}
        onValueChange={onChangeMiles}
      />
    </Container>
  )
}

export default MilesInput
