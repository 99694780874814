import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import FpsPaymentAmount from '@/components/fps/FpsPaymentAmount'
import FpsPaymentDetails from '@/components/fps/FpsPaymentDetails'
import useFps from '@/components/fps/useFps'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import PoweredByDivit from '@/components/PoweredByDivit'
import Spacer from '@/components/Spacer'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import PaynowFailScreen from '@/pages/paynow/PaynowFailScreen'
import { merchantsSelector } from '@/redux/lookup/lookupSelector'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'
import * as OrderPaynow from '@/utils/OrderPaynow'
import * as PaymentUtils from '@/utils/Payment'

const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`

const FpsTopNoticeContainer = styled(PaddingContent).attrs({
  isFullWidth: true,
})``

const FpsTopNotice = styled.div`
  font-size: 0.778rem;
  padding: 0.667rem 0.889rem;
  box-sizing: border-box;
  background-color: #e0f1ff;
  color: #048bf1;
  border-radius: 0.444rem;
`

const Footer = styled.div``

const PaynowVerifyPayment = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { nextPage } = state || {}
  const { orderId } = useParams()

  const checkOrderStatusTimer = useRef()
  const payAmountRef = useRef()

  const [fpsDetails, setFpsDetails] = useState()
  const [disableManualFPS, setDisableManualFPS] = useState(false)
  const { appLink: payLink = '' } = fpsDetails || {}
  const [isPaymentFailed, setIsPaymentFailed] = useState(false)
  const [merchant, setMerchant] = useState()

  const { order } = useSelector((s) => s.order)
  const merchants = useSelector(merchantsSelector)
  const instalmentId = order?.instalments[0].instalmentID
  const { fetchOrder, generateFpsLink, refreshOrder } = useSelector(
    (s) => s.paynowVerify
  )

  const { fpsPaymentType, clearManualInactiveTimer, onSwitchFpsPaymentType } =
    useFps()

  const clearAllTimers = () => {
    clearInterval(checkOrderStatusTimer.current)
    clearManualInactiveTimer?.()
  }

  const refreshOrderStatus = () => {
    dispatch({
      type: 'paynowVerify/fpsRefreshOrder',
      payload: { orderId },
    })
  }

  const refreshFpsCode = () => {
    const paymentMethod =
      sessionStorage.getItem('paymentMethod') ||
      PaymentUtils.getDefaultPaymentMethodWithCurrency(order.currency)
    dispatch({
      type: 'paynowVerify/generateFpsLink',
      payload: { orderId, instalmentId, paymentMethod },
    })
  }

  const startRefreshOrderTimer = () => {
    clearInterval(checkOrderStatusTimer.current)
    checkOrderStatusTimer.current = setInterval(() => {
      refreshOrderStatus()
    }, 10 * 1000)
    return () => clearInterval(checkOrderStatusTimer.current)
  }

  // get order when mount
  useEffect(() => {
    dispatch({
      type: 'paynowVerify/fetchOrder',
      payload: { orderId },
    })
    return () => {
      clearAllTimers()
      dispatch({ type: 'paynowVerify/reset' })
    }
  }, [])

  useUpdateEffect(async () => {
    if (fetchOrder.isSuccess) {
      // maybe transfered from paynow fast
      sessionStorage.setItem('transfered_order', orderId)

      // set payment for underpay case
      const instalmentTotalAmount = OrderPaynow.getTotalOutstandingAmount(
        fetchOrder.data
      )
      payAmountRef.current = instalmentTotalAmount.amount
    }
  }, [fetchOrder])

  // generate fps only valid order
  useUpdateEffect(async () => {
    if (fetchOrder.isSuccess) {
      const newOrder = fetchOrder.data
      if (newOrder && !OrderPaynow.isEnd(newOrder)) {
        refreshFpsCode()
      }
    }
  }, [fetchOrder])

  // refresh fps when fps timeout
  useUpdateEffect(() => {
    if (generateFpsLink.isSuccess) {
      const { data } = generateFpsLink
      const { merchantID } = order
      const mer = merchants.find((m) => m.merchantID === merchantID)
      setMerchant(mer)
      setDisableManualFPS(mer.disableManualFPS)
      // TODO: GA4
      setFpsDetails(PaymentUtils.getFpsDetails(data))
    }
  }, [generateFpsLink])

  // if paylink is set, refresh long polling order status
  useEffect(() => startRefreshOrderTimer(), [payLink])

  // check current order status is invalid
  // if order amount changed, generate qr code
  useUpdateEffect(() => {
    if (refreshOrder.isSuccess) {
      if (OrderPaynow.isEnd(refreshOrder.data)) {
        clearAllTimers()
        // display success / failed screen
      } else if (OrderPaynow.isFailed(refreshOrder.data)) {
        setIsPaymentFailed(true)
        // dispatch failed screen => refresh fps code
      } else {
        const { amount } = OrderPaynow.getTotalOutstandingAmount(
          refreshOrder.data
        )
        if (amount !== payAmountRef.current && amount > 0) {
          refreshFpsCode()
        }
        payAmountRef.current = amount
      }
    }
  }, [refreshOrder])

  // first get order error
  if (fetchOrder.isError)
    return <PaynowFailScreen order={order} error={fetchOrder.error} />

  if (fetchOrder.isLoading) return <LoadingScreen />
  if (!fetchOrder.data) return <LoadingScreen />

  // show order success
  const isOrderSuccess = (data) => {
    if (!data) return false
    return OrderPaynow.isCompleted(data)
  }

  const onClickClose = () => {
    window.history.back()
  }

  if ((order && isOrderSuccess(order)) || isOrderSuccess(refreshOrder.data)) {
    clearAllTimers()
    dispatch(ToastActions.addToast({ message: tt(intl, 'payment.success') }))
    return <Redirect to={nextPage} />
  }

  // refresh order error
  const isOrderInvalid = (data) => {
    if (!data) return false
    return OrderPaynow.isExpired(data) || OrderPaynow.isCancelled(data)
  }

  // show order fail
  if (
    isOrderInvalid(order) ||
    isOrderInvalid(refreshOrder.data) ||
    refreshOrder.isError ||
    generateFpsLink.isError
  ) {
    clearAllTimers()
    return (
      <PaynowFailScreen
        order={order}
        onNext={() => {
          history.push(nextPage)
        }}
      />
    )
  }

  // show payment failed
  if (isPaymentFailed) {
    return (
      <PaynowFailScreen
        order={order}
        onRetry={() => {
          refreshFpsCode()
          setTimeout(() => {
            setIsPaymentFailed(false)
          }, 250)
        }}
      />
    )
  }

  if (!fetchOrder.data) return <LoadingScreen />
  if (!payLink || !merchant || !order) return <LoadingScreen />

  const instalmentTotalAmount = OrderPaynow.getTotalOutstandingAmount(order)

  return (
    <Container>
      <FpsPaymentAmount
        instalmentAmount={instalmentTotalAmount}
        merchant={merchant}
        merchantRef={order.merchantRef}
        onClose={onClickClose}
      />
      <Spacer height="0.889rem" />
      <FpsTopNoticeContainer>
        <FpsTopNotice>
          {tt(intl, 'edda.verifypayment.hkidmatches')}
        </FpsTopNotice>
      </FpsTopNoticeContainer>
      <Spacer height="0.889rem" />
      <FpsPaymentDetails
        type={fpsPaymentType}
        fpsDetails={fpsDetails}
        onSwitchMethod={onSwitchFpsPaymentType}
        disableManualFPS={disableManualFPS}
      />
      <Spacer height="2.222rem" />
      <Footer>
        <PoweredByDivit />
        <Spacer height="2.222rem" />
      </Footer>
    </Container>
  )
}

export default PaynowVerifyPayment
