import { push } from 'connected-react-router'

import { t } from '@/locales/format'
import { replace } from '@/redux/app/appActions'
import { actions as modalActions } from '@/redux/modal/modalSlice'
import { verifyIdentity } from '@/redux/pin/pinActions' // startSetupPinFlow,
import { fetchProfile } from '@/redux/profile/profileActions'
import {
  eDDAInitedSelector,
  isFeatureEDDAEnabledSelector,
  sessionSelector,
} from '@/redux/profile/profileSelector'
import { promisfyDispatch } from '@/utils/promises'
import { isCheckoutPath, IsFastPaynow } from '@/utils/Route'

export const editEmail = (props) =>
  promisfyDispatch({
    type: 'app/showEditEmailModal',
    payload: { isOpen: true, props },
  })

export const editMobile = (props) =>
  promisfyDispatch({
    type: 'app/showEditMobileModal',
    payload: { isOpen: true, props },
  })

const promptSetupAccount = (dispatch, { content }) =>
  new Promise((resolve) => {
    dispatch(
      modalActions.openModal({
        title: t('common.heywait'),
        content,
        actions: [
          {
            key: 'ok',
            title: t('profile.complete.confirm'),
            action: () => {
              resolve({ result: 'ok' })
            },
          },
          {
            key: 'logout',
            title: t('common.logout'),
            action: () => {
              resolve({ result: 'logout' })
            },
          },
        ],
      })
    )
  })

export const setupAccount = ({
  isSignup,
  isPrompt,
  isRedirect = true,
} = {}) => async (dispatch, getState) => {
  const state = getState()
  const { pathname } = state.router.location

  // fast pay now do not disturb
  if (IsFastPaynow(pathname)) {
    return
  }

  const session = sessionSelector(getState())
  const isEDDAEnabled = isFeatureEDDAEnabledSelector(getState())
  const isUserEDDAInited = eDDAInitedSelector(getState())
  const { hasEmail } = session
  const isCompleted = hasEmail

  if (isCompleted) {
    if (
      isEDDAEnabled &&
      !isUserEDDAInited &&
      sessionStorage.getItem('register_edda') === 'true'
    ) {
      dispatch(push('/edda/activation', { isSignup }))
    } else if (isSignup && isCheckoutPath(pathname)) {
      dispatch({ type: 'redirectReturnUrlOrHome' })
    } else if (isSignup) {
      dispatch(push('/signup/welcome'))
    } else if (isRedirect) {
      dispatch({ type: 'redirectReturnUrlOrHome' })
    }
    return
  }

  if (isPrompt) {
    let content = ''

    if (!hasEmail) {
      content = t('profile.completeemail.ask')
    }

    const { result } = await dispatch(async (d) =>
      promptSetupAccount(d, { content })
    )

    if (result === 'logout') {
      dispatch({ type: 'logout' })
      setTimeout(() => dispatch(replace('/'), 500))
      return
    }
  }

  if (!hasEmail) {
    const authResult = await dispatch(verifyIdentity())
    if (authResult.isSuccess) {
      await dispatch(editEmail())
      dispatch({ type: 'app/showEditEmailModal', payload: { isOpen: false } })
    }
  }

  // refresh profile
  await fetchProfile()

  // loop checking other missing profile
  dispatch(setupAccount({ isSignup, isPrompt: false, isRedirect }))
}

// pay later setup profile in checkout
export const setupAccountForPaylater = () => async (dispatch, getState) => {
  let session = sessionSelector(getState())

  if (!session.hasEmail) {
    const authResult = await dispatch(verifyIdentity())
    if (authResult.isSuccess) {
      await dispatch(editEmail())
      dispatch({ type: 'app/showEditEmailModal', payload: { isOpen: false } })

      // refresh profile in case they have tel
      await fetchProfile()
      session = sessionSelector(getState())
    }
  }

  if (!session.hasTel) {
    const authResult = await dispatch(verifyIdentity())
    if (authResult.isSuccess) {
      await dispatch(editMobile())
      dispatch({ type: 'app/showEditMobileModal', payload: { isOpen: false } })
    }
  }
}

export default 0
