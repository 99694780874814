import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import SelectorUnderline, { Tab } from '@/components/SelectorTabUnderline'
import ProductCategoryGrid from '@/components/shop/ProductCategoryGrid'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { goBack } from '@/redux/app/appActions'

const Container = styled.div``

const ShopCategories = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { defaultTab } = state || {}

  const [selectedCategory, setSelectedCategory] = useState()

  const categories = useSelector((s) => s.shop.productCategories)

  useEffect(() => {
    if (categories.length > 0 && !defaultTab && !selectedCategory) {
      setSelectedCategory(categories[0])
    }
  }, [categories])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initShop' },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    dispatch(goBack())
  }

  const viewCategory = (c) => {
    const url = `https://shop.divit.com.hk/product-category/${c.slug}`
    window.open(url, '_blank')
  }

  const onChangeTab = (tab) => {
    const cat = categories.find((c) => c.id === tab.key)
    setSelectedCategory(cat)
  }

  const tabs = categories.map((c) => ({
    key: c.id,
    label: c.name,
    categories: c.children,
  }))

  const activeTab = selectedCategory?.id || defaultTab

  return (
    <Container>
      <PaddingContent>
        <Spacer height="2.222rem" />
        <Title onClickBack={onClickBack}>
          {intl.formatMessage({ id: 'shop.shopbycategory' })}
        </Title>
        <Spacer height="1.778rem" />
        <SelectorUnderline
          defaultTab={activeTab}
          tabs={tabs}
          onChange={onChangeTab}
        />
        <Spacer height="1.333rem" />
        {tabs.map((t) => (
          <Tab key={t.key} isActive={activeTab === t.key}>
            <ProductCategoryGrid
              categories={t.categories}
              onClickItem={viewCategory}
            />
          </Tab>
        ))}
        <Spacer height="4.444rem" />
      </PaddingContent>
    </Container>
  )
}

export default ShopCategories
