import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'

import { ReactComponent as VerifiedSvg } from '@/assets/profile/verified.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import DOBInput from '@/components/ui/DOBInput'
import HKIDInput from '@/components/ui/HKIDInput'
import { useLocale } from '@/contexts/localeContext'
import { tt } from '@/locales/format'
import { Button, Input } from '@/pages/Auth/shared/style'
import { ProfileAddressModal } from '@/pages/profile/ProfileAddress'
import { ProfileEditEmailModal } from '@/pages/profile/ProfileEditEmail'
import { ProfileEditMobileModal } from '@/pages/profile/ProfileEditMobile'
import { verifyIdentity } from '@/redux/pin/pinActions'
import Address, { joinAddress } from '@/utils/Address'
import { parsePhoneNumber } from '@/utils/Phone'

import VerifiedStatus from './setupAccount/VerifiedStatus'

const Section = styled.div`
  border-bottom: 1px solid #f0f0f0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
`

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const FormColumn = styled.div`
  flex: ${({ col }) => col};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.889rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const ItemTitle = styled.div`
  color: ${(p) => (p.error ? '#E6171A' : '#1c1c1c')};
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemText = styled.div`
  font-size: 0.889rem;
`

const SubLabel = styled.span`
  font-size: 0.625rem;
  font-weight: 700;
`

const SearchButton = styled(Button)`
  min-width: unset;
  font-size: 0.778rem;
`

const ItemTextButton = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
  cursor: pointer;
  text-decoration: underline;
`

const FourStepContainer = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
`

const ItemContainer = styled.div`
  display: flex;
  padding: 1.2rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const ItemContent = styled.div`
  width: 70%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 80%;
  }
`

const ContendTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const ContendSubTitle = styled.div`
  font-size: 16px;
  margin-top: 0.5rem;
`

const ItemEditButton = ({ exist, onClick }) => {
  const intl = useIntl()
  return (
    <ItemTextButton onClick={onClick}>
      {exist ? tt(intl, 'common.edit') : tt(intl, 'common.add')}
    </ItemTextButton>
  )
}

const ItemEdit = ({ title, subtitle, onClick, isIdValidated }) => {
  const intl = useIntl()

  const checkVerify = (infor) => {
    if (infor) {
      return 'verified'
    }
    return 'unverified'
  }

  return (
    <ItemContainer onClick={onClick}>
      <ItemContent>
        <ContendTitle>{title}</ContendTitle>
        <ContendSubTitle>{subtitle}</ContendSubTitle>
      </ItemContent>
      <VerifiedStatus status={checkVerify(isIdValidated)} />
    </ItemContainer>
  )
}

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    fontSize: '0.778rem',
  }),
  control: (provided) => ({
    ...provided,
  }),
  placeholder: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0.675rem',
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
  }),
}

const validDateOrEmpty = (momentDate, format) =>
  momentDate.isValid() ? momentDate.format(format) : ''

export const errorMessageMap = {
  nationalid: 'error.invalid.hkid',
  dob: 'error.invalid.dob',
}

// error to field highlight
export const errorFieldMap = {
  nationalid: ['HKID'],
  dob: ['DOBDay', 'DOBMonth', 'DOBYear'],
}

export const validateForm = (data, setError) => {
  const { DOBDay, DOBMonth, DOBYear, HKID } = data

  let isValid = true

  const DOBs = [DOBDay, DOBMonth, DOBYear].filter((s) => s && s.trim() !== '')
  if (DOBs.length > 0 && DOBs.length !== 3) {
    setError('DOBDay', { message: 'error.invalid.dob' })
    setError('DOBMonth', { message: 'error.invalid.dob' })
    setError('DOBYear', { message: 'error.invalid.dob' })
    isValid = false
  }

  if (HKID.length > 0 && HKID.length > 9) {
    setError('HKID', { message: 'error.invalid.hkid' })
    isValid = false
  }

  return isValid
}

export const toFormValues = (data) => {
  // form values
  const DOBValue =
    (data.DOBDay &&
      data.DOBMonth &&
      data.DOBYear &&
      validDateOrEmpty(
        moment(`${data.DOBDay}-${data.DOBMonth}-${data.DOBYear}`, 'DD-MM-YYYY'),
        'YYYY-MM-DD'
      )) ||
    ''

  const formValues = {
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
    DOB: DOBValue,
    nationalID: data.HKID.trim(),
    floor: data.floor,
    building: data.building,
    street: data.street,
    district: data.district,
    region: data.region,
  }

  return formValues
}

const UpdateProfileForm = ({ profile }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()

  const { currentLocale } = useLocale()

  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    formState,
    clearErrors,
  } = useFormContext()
  const { errors } = formState

  // search address form
  const [searchText, setSearchText] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [expandSuggestion, setExpandSuggestion] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const [isShowEditEmailModal, setIsShowEditEmailModal] = useState(false)
  const [isShowEditMobileModal, setIsShowEditMobileModal] = useState(false)
  const [isShowAddressModal, setIsShowAddressModal] = useState(false)

  useEffect(() => {
    if (profile) {
      // form values > profile > empty

      const formValues = getValues()

      setValue('firstName', formValues.firstName || profile.firstName || '')
      setValue('lastName', formValues.lastName || profile.lastName || '')

      setValue('HKID', formValues.HKID || '')

      setValue('floor', profile.floor || '')
      setValue('building', profile.building || '')
      setValue('street', profile.street || '')
      setValue('district', profile.district || '')
      setValue('region', profile.region || '')

      const dobs = (profile.DOB || '').split('-')
      setValue('DOBDay', formValues.DOBDay || dobs[2] || '')
      setValue('DOBMonth', formValues.DOBMonth || dobs[1] || '')
      setValue('DOBYear', formValues.DOBYear || dobs[0] || '')
    }
  }, [profile])

  const DOBDay = watch('DOBDay')
  const DOBMonth = watch('DOBMonth')
  const DOBYear = watch('DOBYear')

  const onClickChangeAddress = () => {
    setIsShowAddressModal(true)
  }

  const onClickChangeEmail = async () => {
    const authResult = await dispatch(verifyIdentity({}))
    if (authResult.isSuccess) {
      setIsShowEditEmailModal(true)
    }
  }

  const onClickChangeMobile = async () => {
    const authResult = await dispatch(verifyIdentity({}))
    if (authResult.isSuccess) {
      setIsShowEditMobileModal(true)
    }
  }

  const selectOptions = () => {
    const data = Address.Flatten(suggestions, currentLocale)
    return data.map((value, i) => ({
      label: value,
      value: i,
    }))
  }

  const onClickSearch = (e) => {
    e.preventDefault()
    if (!searchText) return

    setSearchLoading(true)
    setExpandSuggestion(true)

    axios
      .get(`https://www.als.ogcio.gov.hk/lookup?q=${searchText}&n=10`, {
        headers: {
          'Accept-Language': 'en,zh-Hant',
        },
      })
      .then(({ data }) => {
        const modelizedAddress = Address.Modelized(data, currentLocale)
        setSuggestions(modelizedAddress)
        setSearchLoading(false)
      })
  }

  const onChangeSelect = ({ value }) => {
    setExpandSuggestion(false)
    const data = suggestions[value]

    setValue('floor', '')
    setValue('building', data.building)
    setValue('street', data.street)
    setValue('district', data.district)
    setValue('region', data.region)
  }

  const onBlurSelect = () => {
    setExpandSuggestion(false)
  }

  if (!profile) return null

  const isIdValidated =
    profile.firstName && profile.lastName && profile.nationalID && profile.DOB
  const isMobileVerified = profile.tel_validated
  const isEmailVerified = profile.email_validated

  const name =
    profile.firstName || profile.lastName
      ? `${profile.firstName || ''} ${profile.lastName || ''}`
      : 'Nil'
  const DOB = moment(profile.DOB, 'YYYY-MM-DD').format('DD / MM / YYYY')

  const formDob = `${DOBDay} / ${DOBMonth} / ${DOBYear}`

  return (
    <form>
      <FourStepContainer>
        <Section>
          <ItemEdit
            title={intl.formatMessage({ id: 'signup.profile.email' })}
            subtitle={profile.email || ''}
            isIdValidated={isEmailVerified}
            onClick={onClickChangeEmail}
          />
        </Section>
        <Section>
          <ItemEdit
            title={tt(intl, 'common.mobile')}
            subtitle={parsePhoneNumber(profile.tel).formatted}
            onClick={onClickChangeMobile}
            isIdValidated={isMobileVerified}
          />
        </Section>
        {isIdValidated && (
          <>
            <Section>
              <ItemEdit
                title={tt(intl, 'common.HKID')}
                subtitle={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <>
                    <div>{name}</div>
                    <div>{profile.nationalID}</div>
                    <div>{DOB}</div>
                  </>
                }
                onClick={() => {
                  if (!isIdValidated) {
                    history.push(`/paylater/kyc`)
                  }
                }}
                isIdValidated={isIdValidated}
              />
            </Section>
          </>
        )}
        {!isIdValidated && (
          <>
            <Section>
              <ItemTitle error={errors.firstName || errors.lastName}>
                {intl.formatMessage({ id: 'signup.profile.name' })}
                <span> </span>
                <SubLabel>
                  {`(${intl.formatMessage({
                    id: 'signup.profile.name.as.hkid',
                  })})`}
                </SubLabel>
              </ItemTitle>
              <Spacer height="0.889rem" />
              <Row>
                <FormColumn col={6}>
                  <Input
                    type="text"
                    {...register('firstName')}
                    data-selenium="signup.profile.first.name"
                    placeholder="First Name"
                    error={errors.firstName}
                  />
                </FormColumn>
                <Spacer width="0.889rem" />
                <FormColumn col={6}>
                  <Input
                    type="text"
                    {...register('lastName')}
                    data-selenium="signup.profile.last.name"
                    placeholder="Last Name"
                    error={errors.lastName}
                  />
                </FormColumn>
              </Row>
            </Section>
            <Section>
              <ItemTitle error={errors.HKID}>
                {intl.formatMessage({ id: 'common.hkidcard' })}
              </ItemTitle>
              <Spacer height="0.889rem" />
              <Row>
                <Controller
                  name="HKID"
                  control={control}
                  render={({ field }) => (
                    <HKIDInput
                      value={field.value}
                      onValueChange={(v) => {
                        field.onChange(v)
                      }}
                      error={errors.HKID}
                    />
                  )}
                />
              </Row>
            </Section>
            <Section>
              <ItemTitle
                error={errors.DOBDay || errors.DOBMonth || errors.DOBYear}
              >
                {intl.formatMessage({ id: 'signup.profile.dob' })}
              </ItemTitle>
              <Spacer height="0.889rem" />
              <Row>
                <Controller
                  name="DOB"
                  control={control}
                  render={({ field }) => (
                    <DOBInput
                      value={formDob}
                      onValueChange={(v) => {
                        const [day, month, year] = v
                        setValue('DOBDay', day)
                        setValue('DOBMonth', month)
                        setValue('DOBYear', year)
                        const DOBValue =
                          (day &&
                            month &&
                            year &&
                            validDateOrEmpty(
                              moment(`${day}-${month}-${year}`, 'DD-MM-YYYY'),
                              'YYYY-MM-DD'
                            )) ||
                          ''
                        if (DOBValue !== '') {
                          clearErrors('DOBDay')
                          clearErrors('DOBMonth')
                          clearErrors('DOBYear')
                        }
                      }}
                      error={errors.DOBDay || errors.DOBMonth || errors.DOBYear}
                    />
                  )}
                />
              </Row>
            </Section>
          </>
        )}
        {false && (
          <Section>
            <ItemTitle
              error={
                errors.floor ||
                errors.building ||
                errors.street ||
                errors.district ||
                errors.region
              }
            >
              {intl.formatMessage({ id: 'signup.profile.address' })}
            </ItemTitle>
            <FormRow>
              <FormColumn col={12}>
                <Input
                  type="text"
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                  value={searchText}
                  data-selenium="signup.profile.address"
                  placeholder={intl.formatMessage({
                    id: 'signup.profile.address.placeholder',
                  })}
                />
                <Select
                  components={{
                    Control: () => <></>,
                  }}
                  styles={selectStyles}
                  isLoading={searchLoading}
                  isSearchable={false}
                  cacheOptions
                  defaultOptions
                  placeholder="Addresses"
                  onChange={onChangeSelect}
                  onBlur={onBlurSelect}
                  options={selectOptions(suggestions)}
                  menuIsOpen={expandSuggestion}
                  noOptionsMessage={() => 'No found address '}
                />
              </FormColumn>
              <Spacer width="0.889rem" />
              <FormColumn>
                <SearchButton secondary onClick={onClickSearch}>
                  {intl.formatMessage({ id: 'button.search' })}
                </SearchButton>
              </FormColumn>
            </FormRow>
          </Section>
        )}
        <Section>
          <ItemEdit
            title={intl.formatMessage({ id: 'common.hkaddress' })}
            subtitle={joinAddress([
              profile.floor,
              profile.building,
              profile.street,
              profile.district,
              profile.region,
            ])}
            onClick={onClickChangeAddress}
            isIdValidated={
              profile.floor ||
              profile.building ||
              profile.street ||
              profile.district ||
              profile.region
            }
          />
        </Section>

        <ProfileEditEmailModal
          isOpen={isShowEditEmailModal}
          onClose={() => setIsShowEditEmailModal(false)}
        />
        <ProfileEditMobileModal
          isOpen={isShowEditMobileModal}
          onClose={() => setIsShowEditMobileModal(false)}
        />
        <ProfileAddressModal
          isOpen={isShowAddressModal}
          onClose={() => {
            if (profile.building && profile.region) {
              clearErrors(['floor', 'building', 'street', 'district', 'region'])
            }
            setIsShowAddressModal(false)
          }}
        />
      </FourStepContainer>
    </form>
  )
}

export default UpdateProfileForm
