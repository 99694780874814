import QueryString from 'query-string'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
import HeaderSpacer from '@/components/HeaderSpacer'
import LoadingScreen from '@/components/LoadingScreen'
import ScrollToTop from '@/components/ScrollToTop'
import { useAuth } from '@/contexts/authContext'
import usePage from '@/hooks/usePage'
import useScreenView from '@/hooks/useScreenView'
import GlobalLoadingModal from '@/pages/containers/GlobalLoadingModal'
import MessageModal from '@/pages/containers/MessageModal'
import MessageToast from '@/pages/containers/MessageToast'
import PinRequestModal from '@/pages/containers/PinRequestModal'
import PinSetupModal from '@/pages/containers/PinSetupModal'
import VerifyPinOTPModal from '@/pages/containers/VerifyPinOTPModal'
import { ProfileEditEmailGlobalModal } from '@/pages/profile/ProfileEditEmail'
import { ProfileEditMobileGlobalModal } from '@/pages/profile/ProfileEditMobile'
import routes from '@/routes'
import { setPartnerInfo } from '@/utils/Partner'
import {
  hasFooter,
  hasHeader,
  IsFastPaynow,
  isFullWidth,
  IsOrder,
} from '@/utils/Route'

import CustomerServiceModal from './pages/containers/CustomerServiceModal'
import Router from './Router'

const ViewportContainer = styled.div`
  position: relative;
  min-height: var(--app-height);
  max-width: ${(p) => (p.isFullWidth ? 'none' : '600px')};
  min-width: 360px;
  margin: 0 auto;
  background-color: white;
  background: linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  box-shadow: 0px 1px 5px #eee;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(p) => (p.hasFooter ? '6rem' : '0')};
`

const AppState = ({ children }) => {
  const { authUserID } = useAuth()
  const { pathname } = useLocation()

  const isFastPaynow = IsFastPaynow(pathname)

  // check token is valid and get user profile
  const { isInitLoading } = usePage({
    skipInit: isFastPaynow || !authUserID,
    initAction: { type: 'pageInit/initApp' },
  })

  if (isInitLoading) return <LoadingScreen />

  return children
}

const App = () => {
  const { authUserID } = useAuth()
  const location = useLocation()
  const { pathname } = location

  const isOrder = IsOrder(pathname)

  const { initApp } = useSelector((s) => s.pageInit)

  // listen to force update
  useSelector((s) => s.app.forceUpdate)

  // listen to history change to log event screen_view
  useScreenView()

  // merchant reference id generated by server, send when signup
  useEffect(() => {
    if (!sessionStorage.getItem('mRef')) {
      const {
        mRef = '',
        redirectUrl = '',
        features = '',
      } = QueryString.parse(document.location.search)
      sessionStorage.setItem('mRef', mRef || redirectUrl || document.referrer)
      sessionStorage.setItem('features', features)
    }
  }, [])

  // register edda from email
  useEffect(() => {
    const data = QueryString.parse(document.location.search)
    if (data?.register_edda === 'true') {
      sessionStorage.setItem('register_edda', true)
    }
  }, [])

  // get partner data from query string
  useEffect(() => {
    if (isOrder) {
      const { merchant, expiredAt } = QueryString.parse(
        document.location.search
      )
      const { params } =
        matchPath(pathname, '/orderpreview/:orderId') ||
        matchPath(pathname, '/order/:orderId')
      const { orderId } = params || {}
      if (orderId && merchant) {
        setPartnerInfo(orderId, { merchant, expiredAt })
      }
    }
  }, [isOrder])

  const showHeader = !initApp.isLoading && hasHeader(routes, pathname)
  const showFooter =
    !initApp.isLoading && hasFooter(routes, pathname) && authUserID
  const fullWidth = !initApp.isLoading && isFullWidth(routes, pathname)

  return (
    <div className="App">
      <ScrollToTop />
      <ViewportContainer isFullWidth={fullWidth}>
        {showHeader && (
          <>
            <Header headerMode={fullWidth ? 'dark' : 'light'} />
            <HeaderSpacer />
          </>
        )}
        <Body hasFooter={showFooter}>
          <AppState>
            <Router />
          </AppState>
        </Body>
        {showFooter && <Footer />}
        <ProfileEditEmailGlobalModal />
        <ProfileEditMobileGlobalModal />
        <PinRequestModal />
        <VerifyPinOTPModal />
        <PinSetupModal />
        <MessageModal />
        <MessageToast />
        <GlobalLoadingModal />
        <CustomerServiceModal />
      </ViewportContainer>
    </div>
  )
}

export default App
