import DivitMilesForgotPassword from './pages/Auth/ForgotPassword'
import DivitMilesLanding from './pages/Auth/Landing'
import DivitMilesLogin from './pages/Auth/Login'
import DivitMilesLoginPassword from './pages/Auth/LoginPassword'
import LoginRedirect from './pages/Auth/LoginRedirect'
import FastSignupSetupPassword from './pages/Auth/SetupPassword'
import SetupReferralCode from './pages/Auth/SetupReferralCode'
import SignupEmailFromLink from './pages/Auth/SignupEmailFromLink'
import SignupReferral from './pages/Auth/SignupReferral'
import DivitMilesSignupWelcome from './pages/Auth/SignupWelcome'
import FastSignupVerifyOTP from './pages/Auth/VerifyOTP'
import ChangePassword from './pages/ChangePassword'
import DivitMilesConvertProgram from './pages/DivitMilesConvertProgram'
import DivitMilesHome from './pages/DivitMilesHome'
import DivitMilesPreview from './pages/DivitMilesPreview'
import DivitMilesSetupAndClaim from './pages/DivitMilesSetupAndClaim'
import DownloadApp from './pages/download/Download'
import { EDDAActivationPage } from './pages/eDDA/EDDAActivation'
import EDDAConfirmation from './pages/eDDA/EDDAConfirmation'
import EDDASettings from './pages/eDDA/EDDASettings'
import EDDASetup from './pages/eDDA/EDDASetup'
import EDDAStatus from './pages/eDDA/EDDAStatus'
import EnablePaylater from './pages/EnablePaylater'
import Error from './pages/Error'
import History from './pages/History'
import HistoryPaylaterOutstanding from './pages/HistoryPaylaterOutstanding'
import HistoryPaynowProcessing from './pages/HistoryPaynowProcessing'
// import LuckyDrawWinner from './pages/home/LuckyDrawWinner'
import KYC from './pages/KYC'
import Logout from './pages/Logout'
import DivitMilesConvert from './pages/Miles/DivitMilesConvert'
import DivitMilesHistory from './pages/Miles/DivitMilesHistory'
import DivitMilesLinkProgram from './pages/Miles/DivitMilesLinkProgram'
import DivitMilesSpend from './pages/Miles/DivitMilesSpend'
import NotFound from './pages/NotFound'
import Offers from './pages/Offers'
import Order from './pages/Order'
import OrderPreview from './pages/OrderPreview'
import Overdue from './pages/Overdue'
import PaylaterPreviewPayment from './pages/paylater/PaylaterReviewPayment'
import PaylaterWelcome from './pages/paylater/PaylaterWelcome'
import PaylaterBurn from './pages/PaylaterBurn'
import PaylaterProfile from './pages/PaylaterProfile'
import PaylaterSchedule from './pages/PaylaterSchedule'
import PaylaterSummary from './pages/PaylaterSummary'
import Payme from './pages/Payme'
import Payment from './pages/Payment'
import PaymentCheck from './pages/PaymentCheck'
import PaymentDetails from './pages/PaymentDetails'
import PaymentStatus from './pages/PaymentStatus'
import PaynowPreviewPayment from './pages/paynow/PaynowReviewPayment'
import PaynowVerifyPayment from './pages/paynow/PaynowVerifyPayment'
import PaynowBurn from './pages/PaynowBurn'
import PaynowExpressCheckout from './pages/PaynowExpressCheckout'
import PaynowFast from './pages/PaynowFast'
import PaynowPreview from './pages/PaynowPreview'
import PaynowResume from './pages/PaynowResume'
import Profile from './pages/Profile'
import OrderPayment from './pages/profile/Payment'
import PaynowSelectDeliveryAddress from './pages/profile/PaynowSelectDeliveryAddress'
import ProfileGiftCardDetails from './pages/profile/ProfileGiftCardDetails'
import ProfileGiftCards from './pages/profile/ProfileGiftCards'
import ProfileIdentity from './pages/profile/ProfileIdentity'
import ProfileNotifications from './pages/profile/ProfileNotifications'
import ProfilePersonal from './pages/profile/ProfilePersonal'
import ProfileReferralShare from './pages/profile/ProfileReferralShare'
import ProfileSettings from './pages/profile/ProfileSettings'
import RedirectPage from './pages/Redirect'
import ResetPassword from './pages/ResetPassword'
import ScheduleReview from './pages/ScheduleReview2'
import ScheduleReviewRefund from './pages/ScheduleReviewRefund'
import SetupAccount from './pages/SetupAccount'
import Shop from './pages/shop/Shop'
import ShopBrands from './pages/shop/ShopBrands'
import ShopCategories from './pages/shop/ShopCategories'
import SkipKyc from './pages/SkipKyc'
import SSO from './pages/SSO'
import VerifyEmailFromEmail from './pages/VerifyEmailFromEmail'
import Maintenance from './pages/Maintenance'

const TestError = () => {
  const a = {}
  a.b.c = 1
  return ''
}

const routes = {
  root: {
    name: 'root',
    path: '/',
    component: DivitMilesLanding,
    private: false,
    exact: true,
  },
  testError: {
    name: 'test error',
    path: '/test-error',
    component: TestError,
    private: false,
    exact: true,
  },
  error: {
    name: 'error',
    path: '/error',
    component: Error,
    private: false,
    exact: true,
  },
  maintenance: {
    name: 'maintenance',
    path: '/maintenance',
    component: Maintenance,
    private: false,
    exact: true,
  },
  appRedirect: {
    name: 'app redirect',
    path: '/app/redirect',
    component: RedirectPage,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  download: {
    name: 'download',
    path: '/download',
    component: DownloadApp,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  SSO: {
    name: 'SSO',
    path: '/sso',
    component: SSO,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  loginRedirect: {
    name: 'login redirect',
    path: '/login/redirect',
    component: LoginRedirect,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  login: {
    name: 'login',
    path: '/login',
    redirect: '/signin',
  },
  logout: {
    name: 'logout',
    path: '/logout',
    component: Logout,
    private: false,
    exact: true,
  },
  signUp: {
    name: 'sign up',
    path: '/sign-up',
    redirect: '/signup',
  },
  resetPassword: {
    name: 'Reset password',
    path: '/verify/resetpwd/:token',
    component: ResetPassword,
    private: false,
    exact: true,
  },
  verifyEmailFromEmail: {
    name: 'Verify email from email',
    path: '/verify/email/:token',
    component: VerifyEmailFromEmail,
    private: false,
    exact: true,
  },
  checkoutWelcome: {
    name: 'Checkout welcome',
    path: '/paylater/welcome',
    component: PaylaterWelcome,
    private: true,
    exact: true,
  },
  checkoutOverdue: {
    name: 'Checkout view overdue order',
    path: '/paylater/overdue/:orderId',
    component: Overdue,
    private: true,
    exact: true,
  },
  checkoutKyc: {
    name: 'Checkout kyc',
    path: '/paylater/kyc',
    component: KYC,
    private: true,
    exact: true,
  },
  SkipKyc: {
    name: 'Skip kyc',
    path: '/paylater/skip-kyc',
    component: SkipKyc,
    private: true,
    exact: true,
  },
  checkoutProfile: {
    name: 'Checkout profile',
    path: '/paylater/profile/:orderId',
    component: PaylaterProfile,
    private: true,
    exact: true,
  },
  checkoutSchedule: {
    name: 'Checkout schedule',
    path: '/paylater/schedule/:orderId',
    component: PaylaterSchedule,
    private: true,
    exact: true,
  },
  checkoutSummary: {
    name: 'Checkout summary',
    path: '/paylater/summary/:orderId',
    component: PaylaterSummary,
    private: true,
    exact: true,
  },
  checkoutPaymentCheck: {
    name: 'Checkout payment check',
    path: '/paylater/payment/check/:orderId/:instalmentId',
    component: PaymentCheck,
    private: true,
    exact: true,
  },
  checkoutPaymentStatus: {
    name: 'Checkout payment status',
    path: '/paylater/payment/status/:orderId/:instalmentId',
    component: PaymentStatus,
    private: true,
    exact: true,
  },
  checkoutPayment: {
    name: 'Checkout payment',
    path: '/paylater/payment/:orderId/:instalmentId',
    component: Payment,
    private: true,
    exact: true,
  },
  checkoutPaymentReview: {
    name: 'Checkout payment review',
    path: '/paylater/payment/:orderId/:instalmentId/review',
    component: PaylaterPreviewPayment,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  checkoutPaymentFps: {
    name: 'Checkout Fps',
    path: '/pay/:paymentMethod/paylater/:orderId/:instalmentId',
    component: OrderPayment,
    private: true,
    exact: true,
    isFullWidth: true,
  },
  checkoutPaymentPayme: {
    name: 'Checkout Payme',
    path: '/paylater/payme/:orderId/:instalmentId',
    component: Payme,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  checkoutOrderPreview: {
    name: 'Checkout order preview',
    path: '/orderpreview/:orderId',
    component: OrderPreview,
    exact: true,
  },
  checkoutOrder: {
    name: 'Checkout order',
    path: '/order/:orderId',
    component: Order,
    private: true,
    exact: true,
  },
  overdue: {
    name: 'Overdue order',
    path: '/overdue/:orderId',
    component: Overdue,
    private: true,
    exact: true,
  },
  home: {
    name: 'Home',
    path: '/home',
    component: DivitMilesHome,
    private: true,
    exact: true,
    hasFooter: true,
  },
  offers: {
    name: 'Offers',
    path: '/offers',
    component: Offers,
    private: true,
    exact: true,
    hasFooter: true,
  },
  shop: {
    name: 'Shop',
    path: '/shop',
    component: Shop,
    private: true,
    exact: true,
    hasFooter: true,
  },
  shopCategories: {
    name: 'Shop Categories',
    path: '/shop/categories',
    component: ShopCategories,
    private: true,
    exact: true,
  },
  shopBrands: {
    name: 'Shop Brands',
    path: '/shop/brands',
    component: ShopBrands,
    private: true,
    exact: true,
  },
  payment: {
    name: 'Select payment',
    path: '/payment/:orderId/:instalmentId',
    component: Payment,
    private: true,
    exact: true,
  },
  paymentReview: {
    name: 'Checkout payment review',
    path: '/payment/:orderId/:instalmentId/review',
    component: PaylaterPreviewPayment,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  paymentCheck: {
    name: 'Payment check',
    path: '/payment/check/:orderId/:instalmentId',
    component: PaymentCheck,
    private: true,
    exact: true,
  },
  // paymentFps: {
  //   name: 'Fps',
  //   path: '/fps/:orderId/:instalmentId',
  //   component: OrderPayment,
  //   private: true,
  //   exact: true,
  //   isFullWidth: true,
  // },
  PaylaterBurn: {
    // burn adjustment page
    name: 'pay now miles',
    path: '/paylater/:orderId/:instalmentId/burn',
    component: PaylaterBurn,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  // paymentPayme: {
  //   name: 'Payme',
  //   path: '/payme/:orderId/:instalmentId',
  //   component: Payme,
  //   private: true,
  //   exact: true,
  // },
  paymentStatus: {
    name: 'Payment status',
    path: '/payment/status/:orderId/:instalmentId',
    component: PaymentStatus,
    private: true,
    exact: true,
  },
  paymentDetails: {
    name: 'Payment details',
    path: '/history/:transactionId',
    component: PaymentDetails,
    private: true,
    exact: true,
  },
  history: {
    name: 'History',
    path: '/history',
    component: History,
    private: true,
    exact: true,
    hasFooter: true,
  },
  historyPaylaterOutstanding: {
    name: 'History pay later outstanding',
    path: '/history/paylater/active',
    component: HistoryPaylaterOutstanding,
    private: true,
    exact: true,
  },
  historyPaynowProcessing: {
    name: 'History pay now processing',
    path: '/history/paynow/active',
    component: HistoryPaynowProcessing,
    private: true,
    exact: true,
  },
  scheduleReviewRefund: {
    name: 'Schedule review',
    path: '/schedule/review/refund/:orderId',
    component: ScheduleReviewRefund,
    private: true,
    exact: true,
  },
  scheduleReview: {
    name: 'Schedule review',
    path: '/schedule/review/:orderId',
    component: ScheduleReview,
    private: true,
    exact: true,
  },
  scheduleReviewPaynow: {
    name: 'Schedule review paynow',
    path: '/schedule/review/paynow/:orderId',
    component: ScheduleReview,
    private: true,
    exact: true,
  },
  profile: {
    name: 'Profile',
    path: '/profile',
    component: Profile,
    private: true,
    exact: true,
    hasFooter: true,
  },
  enablePaylater: {
    name: 'Enable pay later',
    path: '/enable/paylater',
    component: EnablePaylater,
    private: true,
    exact: true,
  },
  profileReferralShare: {
    name: 'Profile Referral',
    path: '/profile/referral/share',
    component: ProfileReferralShare,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  profilePersonalInformation: {
    name: 'Profile Personal',
    path: '/profile/personal',
    component: ProfilePersonal,
    private: true,
    exact: true,
  },
  profilePersonIdentity: {
    name: 'Profile Identity',
    path: '/profile/identity',
    component: ProfileIdentity,
    private: true,
    exact: true,
  },
  profileSettings: {
    name: 'Profile Settings',
    path: '/profile/settings',
    component: ProfileSettings,
    private: true,
    exact: true,
  },
  profileGiftCards: {
    name: 'Profile Gift Cards',
    path: '/profile/gift-cards',
    component: ProfileGiftCards,
    private: true,
    exact: true,
  },
  profileGiftCardsUsed: {
    name: 'Profile Gift Cards Used',
    path: '/profile/gift-cards/used',
    component: ProfileGiftCardDetails,
    private: true,
    exact: true,
  },
  profileGiftCardDetails: {
    name: 'Profile Gift Card Details',
    path: '/profile/gift-cards/:brandCode',
    component: ProfileGiftCardDetails,
    private: true,
    exact: true,
  },
  profileNotifications: {
    name: 'Profile Notifications',
    path: '/profile/notifications',
    component: ProfileNotifications,
    private: true,
    exact: true,
  },
  updateKyc: {
    name: 'Update kyc',
    path: '/profile/kyc',
    component: KYC,
    private: true,
    exact: true,
  },
  changePassword: {
    name: 'Change password',
    path: '/profile/change-password',
    component: ChangePassword,
    private: true,
    exact: true,
  },
  DivitMilesLogin: {
    name: 'Divit miles signin',
    path: '/signin',
    component: DivitMilesLogin,
    private: false,
    exact: true,
    hasNoHeader: false,
  },
  DivitSignupReferralEmtpy: {
    name: 'Signup referral Empty',
    path: '/signup/r',
    component: DivitMilesLogin,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  DivitSignupReferral: {
    name: 'Signup referral',
    path: '/signup/r/:referralCode',
    component: SignupReferral,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  DivitMilesSignupReferralCode: {
    name: 'Divit miles signup referral code',
    path: '/signup/referralcode',
    component: SetupReferralCode,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  DivitMilesSignup: {
    name: 'Divit miles signup',
    path: '/signup',
    component: DivitMilesLogin,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  signupEmailFromLink: {
    name: 'Sign up email from link',
    path: '/signup/email',
    component: SignupEmailFromLink,
    private: false,
    exact: true,
  },
  DivitMilesSignupWelcome: {
    name: 'Divit miles signup',
    path: '/signup/welcome',
    component: DivitMilesSignupWelcome,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  DivitMilesLoginPassword: {
    name: 'Divit miles signin password',
    path: '/signin/password',
    component: DivitMilesLoginPassword,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  DivitMilesForgotPassword: {
    name: 'Divit miles forgot password',
    path: '/forgot-password',
    component: DivitMilesForgotPassword,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  DivitMilesPreview: {
    name: 'Divit miles preview',
    path: '/miles/claim/:token',
    component: DivitMilesPreview,
    private: false,
    exact: true,
  },
  DivitMilesSetupAndClaim: {
    name: 'Divit miles preview, setup mileage account and claim miles',
    path: '/miles/setup/:token',
    component: DivitMilesSetupAndClaim,
    private: false,
    exact: true,
  },
  DivitMilesHome: {
    name: 'Divit miles home',
    path: '/profile/miles',
    component: DivitMilesHome,
    private: true,
    exact: true,
    hasFooter: true,
  },
  DivitMilesConvert: {
    name: 'Divit miles convert',
    path: '/profile/miles/convert',
    component: DivitMilesConvert,
    private: true,
    exact: true,
  },
  DivitMilesConvertProgram: {
    name: 'Convert asia miles',
    path: '/profile/miles/convert/:program',
    component: DivitMilesConvertProgram,
    private: true,
    exact: true,
  },
  DivitMilesAddProgram: {
    name: 'Link loyalty program',
    path: '/profile/miles/link',
    component: DivitMilesLinkProgram,
    private: true,
    exact: true,
  },
  DivitMilesSpend: {
    name: 'Miles spend',
    path: '/miles/spend',
    component: DivitMilesSpend,
    private: true,
    exact: true,
  },
  DivitMilesHistory: {
    name: 'Miles history',
    path: '/miles/history',
    component: DivitMilesHistory,
    private: true,
    exact: true,
  },
  PaynowPayment: {
    name: 'Checkout',
    path: '/pay/:paymentMethod/paynow/:orderId/:instalmentId?',
    component: OrderPayment,
    private: false,
    exact: true,
    isFullWidth: true,
  },
  PaynowSelectDeliveryAddress: {
    name: 'Profile select delivery address',
    path: '/paynow/:orderId/address',
    component: PaynowSelectDeliveryAddress,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  PaynowResume: {
    name: 'Paynow fast checkout',
    path: '/paynow/:orderId/resume',
    component: PaynowResume,
    private: false,
    exact: true,
    isFullWidth: true,
  },
  PaynowFast: {
    // landing jumper page
    name: 'Paynow fast checkout',
    path: '/paynow/:orderId/fast',
    component: PaynowFast,
    private: false,
    exact: true,
    isFullWidth: true,
  },
  Paynow: {
    // login user pay
    name: 'pay now',
    path: '/paynow/:orderId',
    component: PaynowFast,
    private: true,
    exact: true,
    isFullWidth: true,
  },
  PaynowExpressCheckout: {
    name: 'pay now express checkout',
    path: '/paynow/:orderId/express',
    component: PaynowExpressCheckout,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  PaynowPreview: {
    // burn (whole order) preview page
    name: 'pay now Fps preview',
    path: '/paynow/:orderId/preview',
    component: PaynowPreview,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  PaynowPreviewPayment: {
    // edda preview page
    name: 'pay now Fps',
    path: '/paynow/:orderId/payment',
    component: PaynowPreviewPayment,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  PaynowBurn: {
    // burn adjustment page
    name: 'pay now miles',
    path: '/paynow/:orderId/burn',
    component: PaynowBurn,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  FastSignupVerifyOTP: {
    name: 'Fast signup verify otp',
    path: '/signup/verify-otp',
    component: FastSignupVerifyOTP,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  FastSignupSetupPassword: {
    name: 'Fast signup setup password',
    path: '/signup/setup-password',
    component: FastSignupSetupPassword,
    private: false,
    exact: true,
    hasNoHeader: true,
  },
  EDDASetup: {
    name: 'eDDA setup',
    path: '/edda/setup',
    component: EDDASetup,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  EDDASetupPayment: {
    name: 'edda verify payment',
    path: '/edda/verify/:orderId',
    component: PaynowVerifyPayment,
    private: true,
    exact: true,
    hasNoHeader: true,
    isFullWidth: true,
  },
  EDDAActivation: {
    name: 'eDDA activation',
    path: '/edda/activation',
    component: EDDAActivationPage,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  EDDAConfirmation: {
    name: 'eDDA confirmation',
    path: '/edda/confirmation',
    component: EDDAConfirmation,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  EDDAStatus: {
    name: 'eDDA status',
    path: '/edda/status',
    component: EDDAStatus,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  EDDASettings: {
    name: 'eDDA settings',
    path: '/edda/settings',
    component: EDDASettings,
    private: true,
    exact: true,
    hasNoHeader: true,
  },
  notFound: {
    name: 'Not Found',
    path: '/not-found',
    component: NotFound,
    private: false,
    exact: true,
    hasFooter: false,
  },
  setUpAccount: {
    name: 'Set Up Account',
    path: '/paylater/setup-account',
    component: SetupAccount,
    private: true,
    exact: true,
    hasFooter: false,
  },
}

export default routes
