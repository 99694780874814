import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-logo.svg'
import { FormattedMiles } from '@/utils/Price'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: rgba(0, 0, 153, 0.1);
  padding: 0 0.625rem;
  box-sizing: border-box;
  min-height: 2rem;
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`

const Desc = styled.div`
  margin-left: 0.5rem;
  font-weight: 700;
  color: #000099;
`

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Right = styled.div`
  text-align: right;
`

const Mile = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: #000099;
`

const BonusMilesEarned = ({ miles }) => {
  const intl = useIntl()
  return (
    <Container>
      <Left>
        <Logo>
          <DivitMilesSvg />
        </Logo>
        <Desc>{intl.formatMessage({ id: 'miles.bonus.earned' })}</Desc>
      </Left>
      <Right>
        <Mile>{`+ ${FormattedMiles(miles)}`}</Mile>
      </Right>
    </Container>
  )
}

export default BonusMilesEarned
