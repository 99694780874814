import { useEffect, useRef } from 'react'

const useBeforeunload = (handler, enabled = true) => {
  const eventListenerRef = useRef()

  useEffect(() => {
    eventListenerRef.current = (event) => {
      if (!enabled) {
        return null
      }

      const returnValue = handler?.(event)
      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === 'string') {
        event.returnValue = returnValue
      }
      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        event.returnValue = ''
      }
      return event.returnValue
    }
  }, [enabled, handler])

  useEffect(() => {
    if (!enabled) {
      return null
    }

    const eventListener = (event) => eventListenerRef.current(event)
    window.addEventListener('beforeunload', eventListener)
    return () => {
      window.removeEventListener('beforeunload', eventListener)
    }
  }, [enabled, handler])
}

export default useBeforeunload
