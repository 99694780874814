export const importImage = (r, key) => r(key).default

export const HomeBanners = require.context(
  '@/assets/banners/home',
  false,
  /\.(png|jpe?g|svg)$/
)

export const WelcomeBonusBanners = require.context(
  '@/assets/banners/miles',
  false,
  /\.(png|jpe?g|svg)$/
)

// export const FPS_BANK_GUIDES = require.context(
//   '@/assets/fps-bank-guides',
//   false,
//   /\.(png|jpe?g|svg)$/
// )

export default 0
