import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { tt } from '@/locales/format'

import Spacer from '../Spacer'
import VerifiedStatus from './VerifiedStatus'

const Container = styled.div`
  display: flex;
  padding: 1.2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eff0f1;
  cursor: pointer;
`

const Content = styled.div`
  width: 70%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 80%;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const SubTitle = styled.div`
  font-size: 16px;
`

const HKIDVerify = ({ profile, orderId }) => {
  const intl = useIntl()
  const history = useHistory()
  const { pathname } = useLocation()
  const kycNameObj = profile.kyc && profile.kyc[0].metadata
  const isIdValidated =
    ((kycNameObj?.firstName || kycNameObj?.lastName) &&
      profile.nationalID &&
      profile.DOB) ||
    false

  const name =
    kycNameObj?.firstName || kycNameObj?.lastName
      ? `${kycNameObj?.firstName || ''} ${kycNameObj?.lastName || ''}`
      : ''
  const DOB = moment(profile.DOB, 'YYYY-MM-DD').format('DD / MM / YYYY') || ''
  const checkVerify = (infor) => {
    if (infor) {
      return 'verified'
    }
    return 'unverified'
  }

  return (
    <Container
      onClick={() => {
        if (!isIdValidated) {
          history.push({
            pathname: `/paylater/kyc`,
            state: {
              orderLink: `/order/${orderId}`,
              nextPage: pathname,
              previousPage: pathname,
            },
          })
        }
      }}
    >
      <Content>
        <Title>{tt(intl, 'common.HKID')}</Title>
        {kycNameObj && <Spacer height="0.5rem" />}
        <SubTitle>
          <div>{name}</div>
          <div>{profile.nationalID || ''}</div>
          <div>{DOB !== 'Invalid date' ? DOB : ''}</div>
        </SubTitle>
      </Content>
      <VerifiedStatus status={checkVerify(isIdValidated)} />
    </Container>
  )
}

export default HKIDVerify
