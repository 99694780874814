import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { tt } from '@/locales/format'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.67rem;
  color: ${({ fulfilled }) => (fulfilled ? '#13CC93' : '#1c1c1c')};
  opacity: ${({ fulfilled }) => (fulfilled ? '1' : '0.5')};

  svg {
    border: ${({ fulfilled }) =>
      fulfilled ? '1px solid #13CC93' : '1px solid #1c1c1c'};
    color: ${({ fulfilled }) => (fulfilled ? '#13CC93' : '#1c1c1c')};
  }
`

const TickIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheck,
})`
  border-radius: 50%;
  margin: 0 0.44rem;
  width: 0.44rem !important;
  height: 0.44rem;
  padding: 0.22rem;
`

const PasswordValidation = ({
  hasNumber,
  hasUppercase,
  hasLowercase,
  hasSymbol,
  hasSufficientLength,
}) => {
  const intl = useIntl()

  return (
    <Container>
      <Row fulfilled={hasSufficientLength}>
        <TickIcon />
        <div>{tt(intl, 'password.valid.length8')}</div>
      </Row>
      <Row fulfilled={hasNumber}>
        <TickIcon />
        <div>{tt(intl, 'password.valid.number')}</div>
      </Row>
      <Row fulfilled={hasLowercase}>
        <TickIcon />
        <div>{tt(intl, 'password.valid.lowercase')}</div>
      </Row>
      <Row fulfilled={hasUppercase}>
        <TickIcon />
        <div>{tt(intl, 'password.valid.uppercase')}</div>
      </Row>
      <Row fulfilled={hasSymbol}>
        <TickIcon />
        <div>{tt(intl, 'password.valid.symbol')}</div>
      </Row>
    </Container>
  )
}

export default PasswordValidation
