import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import HeroIconPng from '@/assets/referral-modal/hero-icon.png'
import { ReactComponent as MemberIconSvg } from '@/assets/referral-modal/member-icon.svg'
import { ReactComponent as RewardIconSvg } from '@/assets/referral-modal/reward-icon.svg'
import { ReactComponent as ShopIconSvg } from '@/assets/referral-modal/shop-icon.svg'
import { ReactComponent as Step1Svg } from '@/assets/referral-modal/step1.svg'
import { ReactComponent as Step2Svg } from '@/assets/referral-modal/step2.svg'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import { Features, useFeature } from '@/hooks/useFeature'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { actions as fastSignupActions } from '@/redux/pages/fastSignupSlice'

const Container = styled.div``

const HeroContainer = styled.div`
  text-align: center;
  padding: 2rem 0;
  background: rgb(255, 250, 234);
  background: linear-gradient(
    90deg,
    rgba(255, 250, 234, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
`

const SubTitle = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`

const SectionTitle = styled.div`
  font-size: 1.111rem;
  font-weight: 700;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`

const AgreementText = styled.div`
  font-size: 0.778rem;
`

const ReferralCode = styled.div`
  font-size: 1.111rem;
  font-weight: 600;
`

const Text = styled.div`
  font-size: 0.889rem;
`

const MainButtonContainer = styled.div``

const RewardContainer = styled.div`
  background-color: #fffaea;
`

const SignupReferral = () => {
  const intl = useIntl()
  const { featureEnabled } = useFeature()
  const history = useHistory()
  const dispatch = useDispatch()
  const { referralCode = '' } = useParams()

  useEffect(() => {
    if (featureEnabled(Features.REFERRAL)) {
      history.replace('/')
    } else if (referralCode) {
      dispatch(fastSignupActions.setReferralCode(referralCode))
    } else {
      history.replace('/')
    }
  }, [])

  const onClickSignUp = (e) => {
    e.preventDefault()
    // TODO: store referral code
    history.push('/signup')
  }

  return (
    <Container>
      <HeroContainer>
        <Icon
          renderImage={() => <img src={HeroIconPng} alt="hero" />}
          width="11.889rem"
          height="6.278rem"
        />
      </HeroContainer>
      <Spacer height="1.778rem" />
      <PaddingContent>
        <Title>{tt(intl, 'referral.invitedtojoin')}</Title>
        <Spacer height="0.889rem" />
        <SubTitle>{tt(intl, 'referral.earnfasterspendeasier')}</SubTitle>
        <MainButtonContainer>
          <Spacer height="1.333rem" />
          <Button type="stretch" onClick={onClickSignUp}>
            {tt(intl, 'common.signupnow')}
          </Button>
          <Spacer height="1.333rem" />
          <AgreementText>{tt(intl, 'referral.clickedagreement')}</AgreementText>
          <Spacer height="1.333rem" />
        </MainButtonContainer>
        <SectionTitle>{tt(intl, 'referral.signupandearn')}</SectionTitle>
        <Spacer height="0.889rem" />
        <Row>
          <Icon
            renderImage={() => <Step1Svg />}
            width="1.556rem"
            height="1.556rem"
          />
          <Spacer width="0.889rem" />
          <Text>{tt(intl, 'referral.500bonus')}</Text>
        </Row>
        <Spacer height="1.333rem" />
        <Row>
          <Icon
            renderImage={() => <Step2Svg />}
            width="1.556rem"
            height="1.556rem"
          />
          <Spacer width="0.889rem" />
          <Text>{tt(intl, 'referral.doublemiles')}</Text>
        </Row>
        <Spacer height="1.778rem" />
        <SectionTitle>{tt(intl, 'common.referralCode')}</SectionTitle>
        <Spacer height="0.889rem" />
        <ReferralCode>{referralCode}</ReferralCode>
        <Spacer height="1.778rem" />
        <SectionTitle>{tt(intl, 'common.howitworks')}</SectionTitle>
        <Spacer height="1.333rem" />
        <Row>
          <Icon
            renderImage={() => <MemberIconSvg />}
            width="1.222rem"
            height="1.222rem"
          />
          <Spacer width="0.889rem" />
          <Text>{tt(intl, 'referral.createaccount')}</Text>
        </Row>
        <Spacer height="1.333rem" />
        <Row>
          <Icon
            renderImage={() => <ShopIconSvg />}
            width="1.222rem"
            height="1.222rem"
          />
          <Spacer width="0.889rem" />
          <Text>{tt(intl, 'referral.makefirstpurchase')}</Text>
        </Row>
        <Spacer height="1.333rem" />
        <Row>
          <Icon
            renderImage={() => <RewardIconSvg />}
            width="1.222rem"
            height="1.222rem"
          />
          <Spacer width="0.889rem" />
          <Text>{tt(intl, 'referral.earn500doublemiles')}</Text>
        </Row>
        <Spacer height="2.222rem" />
      </PaddingContent>
      <RewardContainer>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'referral.toberewarded')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'referral.earn500doublemiles')}</SubTitle>
          <Spacer height="0.889rem" />
          <Button style={{ width: '8.778rem' }} onClick={onClickSignUp}>
            {tt(intl, 'common.signupnow')}
          </Button>
          <Spacer height="0.889rem" />
          <AgreementText>{tt(intl, 'referral.clickedagreement')}</AgreementText>
          <Spacer height="1.333rem" />
        </PaddingContent>
      </RewardContainer>
    </Container>
  )
}

export default SignupReferral
