import 'moment-timezone'

import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as CancelledSvg } from '@/assets/paylater/instalment-cancelled.svg'
import { ReactComponent as PaidSvg } from '@/assets/paylater/instalment-paid.svg'
import { ReactComponent as PendingRefundSvg } from '@/assets/paylater/instalment-pending-refund.svg'
import { Button } from '@/components/Divit'
import Icon from '@/components/Icon'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import InstalmentIcon from '@/components/paylater/InstalmentIcon'
import ProgressBar from '@/components/ui/ProgressBar'
import { tt } from '@/locales/format'
import { payForPaylater } from '@/redux/pages/payActions'
import * as Instalments from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div`
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 1rem;
`

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 1rem;
`

const RemainingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RemainingText = styled.div`
  font-size: 1rem;
`

const RemainingAmount = styled.div`
  font-size: 1rem;
`

const InstalmentsContainer = styled.div`
  overflow: hidden;
`

const Instalment = styled.div`
  font-size: 0.75rem;
  display: flex;
  color: ${({ active, theme }) => active && `${theme.primary}`};
  padding: 1rem 0;
`

const InstalmentLeft = styled.div``

const InstalmentRight = styled.div`
  flex: 1;
  margin-left: 1.111rem;
`

const InstalmentStraightLine = styled.div`
  display: block;
  width: 1px;
  height: 100%;
  position: relative;
  left: 1rem;
  background-color: ${(p) => (p.isActive ? '#13cc93' : '#DEDEDE')};
  background-size: 1px 10px;
`

const InstalmentStep = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0.55rem 0;
`

const InstalmentInfo = styled.div``

const RowLeft = styled.div`
  font-weight: 500;
`

const RowRight = styled.div`
  font-weight: 600;
`

const InstalmentTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${({ error }) => (error ? '#E6171A' : '#1c1c1c')};
`

const InstalmentDate = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const HR = styled.div`
  /* width: 100%;
  height: 1px;
  background-color: rgba(112, 112, 112, 0.1); */
`

const PayButton = styled(Button)`
  font-size: 0.6rem;
  font-weight: 700;
  background-color: #fc3;
  color: #1c1c1c;
  font-weight: 700;
  padding-top: 0.33rem;
  padding-bottom: 0.33rem;
`

const RemarkText = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const PaymentBubble = ({ instalments, instalment }) => {
  const { series } = instalment
  const isPaid = Instalments.isPaid(instalment)
  const isCancelled = Instalments.isCancelled(instalment)
  const isOutstanding = Instalments.isOutstandingInstalment(
    instalments,
    instalment
  )
  const isRequestedRefund = Instalments.hasRequestedRefund(instalment)
  const isCompletedRefund = Instalments.hasCompletedRefund(instalment)
  const isActive = isOutstanding

  const renderContent = () => {
    if (isPaid || isCompletedRefund) {
      return (
        <Icon
          renderImage={() => <PaidSvg />}
          width="1.556rem"
          height="1.556rem"
        />
      )
    }
    if (isRequestedRefund) {
      return (
        <Icon
          renderImage={() => <PendingRefundSvg />}
          width="1.556rem"
          height="1.556rem"
        />
      )
    }
    if (isCancelled) {
      return (
        <Icon
          renderImage={() => <CancelledSvg />}
          width="1.556rem"
          height="1.556rem"
        />
      )
    }
    return (
      <InstalmentIcon
        isActive={isActive}
        series={series}
        width="1.556rem"
        height="1.556rem"
      />
    )
  }

  return <InstalmentStep>{renderContent()}</InstalmentStep>
}

const PaymentStraightLine = ({ instalments, instalment }) => {
  const nextInstalment = Instalments.getNextInstalment(instalments, instalment)
  const isNextOutstanding =
    nextInstalment &&
    Instalments.isOutstandingInstalment(instalments, nextInstalment)
  const isNextPaid =
    nextInstalment &&
    (Instalments.isPaid(nextInstalment) ||
      Instalments.hasCompletedRefund(nextInstalment))
  const isNextCancelled =
    nextInstalment && Instalments.isCancelled(nextInstalment)
  const isActive = (isNextOutstanding || isNextPaid) && !isNextCancelled
  const isShowLine = instalment.series <= 2

  if (!isShowLine) {
    return null
  }

  return <InstalmentStraightLine isActive={isActive} />
}

const PaymentTitle = ({ order, instalment, isPayable, onPay }) => {
  const intl = useIntl()

  const { instalments, serviceFee } = order
  const { series, lateFee, amount } = instalment
  const instalmentAmount = Instalments.getTotalAmount(instalment)
  const isPaid = Instalments.isPaid(instalment)
  const isOverPay = Instalments.isOverPay(instalment)
  const isUnderPay = Instalments.isUnderPay(instalment)
  const isRefunded = Instalments.isRefund(instalment)
  const isCancelled = Instalments.isCancelled(instalment)
  const isActive = Instalments.isActive(instalment)
  const isOutstandingInstalment =
    Instalments.isPayable(instalment) &&
    Instalments.getOutstandingInstalment(instalments).series ===
      instalment.series
  const outstandingAmount = Instalments.getTotalOutstandingAmount(instalment)
  const haveOutstanding = outstandingAmount.amount > 0
  const isRequestedRefund = Instalments.hasRequestedRefund(instalment)
  const totalCashPaidAmount = Instalments.getTotalCashPaidAmount(instalment)
  const totalMilesBurned = Instalments.getTotalMilesBurned(instalment)
  const totalCancelledAmount = Instalments.getTotalCancelledAmount(instalment)
  const totalOverPaidAmount = Instalments.getOverPaidAmount(instalment)
  const totalReallocatedAmount =
    Instalments.getTotalReallocatedAmount(instalment)

  const isShowServiceFee =
    Order.isFullRefund(order) && series === 1 && serviceFee.amount > 0

  if (isRequestedRefund) {
    const refundAmount = Instalments.getTotalCancelledCashAmount(instalment)
    const refundMiles = Instalments.getTotalCancelledMiles(instalment)

    return (
      <>
        <InstalmentTitleRow>
          <RowLeft style={{ color: '#E6171A' }}>
            {tt(intl, 'payment.refundpending')}
          </RowLeft>
        </InstalmentTitleRow>
        <InstalmentTitleRow>
          <RowLeft>{tt(intl, 'payment.refundableamount')}</RowLeft>
          <RowRight>
            <MilesAndAmountValue
              fontSize="1rem"
              currency={refundAmount.currency}
              amount={-refundAmount.amount}
              miles={-refundMiles}
            />
          </RowRight>
        </InstalmentTitleRow>
        {isShowServiceFee && (
          <RemarkText>
            {tt(intl, 'payment.nonrefundablefee.note', {
              amt: FormattedPrice(serviceFee.currency, serviceFee.amount),
            })}
          </RemarkText>
        )}
      </>
    )
  }

  if (isCancelled) {
    return (
      <>
        <InstalmentTitleRow>
          <RowLeft>{tt(intl, 'payment.instalmentamount')}</RowLeft>
          <RowRight>
            {FormattedPrice(instalmentAmount.currency, instalmentAmount.amount)}
          </RowRight>
        </InstalmentTitleRow>
        <InstalmentDate>
          {intl.formatMessage({ id: 'payment.status.cancelled' })}
        </InstalmentDate>
      </>
    )
  }

  if (isActive || haveOutstanding) {
    return (
      <>
        {isPayable && isOutstandingInstalment && (
          <InstalmentTitleRow>
            <RowLeft>{tt(intl, 'payment.youpay')}</RowLeft>
            <RowRight>
              <PayButton
                data-testid="instalment.pay"
                onClick={() => onPay(instalment)}
              >
                {tt(intl, 'payment.pay')}{' '}
                {FormattedPrice(
                  outstandingAmount.currency,
                  outstandingAmount.amount
                )}
              </PayButton>
            </RowRight>
          </InstalmentTitleRow>
        )}
        {isUnderPay && (
          <InstalmentTitleRow>
            <RowLeft>{tt(intl, 'payment.youpaid')}</RowLeft>
            <RowRight>
              <MilesAndAmountValue
                fontSize="1rem"
                currency={totalCashPaidAmount.currency}
                amount={totalCashPaidAmount.amount}
                miles={totalMilesBurned}
              />
            </RowRight>
          </InstalmentTitleRow>
        )}
        <InstalmentTitleRow>
          <RowLeft>{tt(intl, 'payment.instalmentamount')}</RowLeft>
          <RowRight>{FormattedPrice(amount.currency, amount.amount)}</RowRight>
        </InstalmentTitleRow>
        {lateFee?.amount > 0 && (
          <InstalmentTitleRow>
            <RowLeft>{intl.formatMessage({ id: 'payment.latefee' })}</RowLeft>
            <RowRight>
              {FormattedPrice(lateFee.currency, lateFee.amount)}
            </RowRight>
          </InstalmentTitleRow>
        )}
        {totalCancelledAmount.amount > 0 && (
          <InstalmentTitleRow>
            <RowLeft>
              {intl.formatMessage({
                id: isRequestedRefund
                  ? 'payment.credit.pending'
                  : 'payment.credit',
              })}
            </RowLeft>
            <RowRight>
              {FormattedPrice(
                totalCancelledAmount.currency,
                -totalCancelledAmount.amount
              )}
            </RowRight>
          </InstalmentTitleRow>
        )}
        <InstalmentDate>
          {tt(intl, 'payment.due')}{' '}
          {moment.unix(instalment.dueDate).format('LLL')}
        </InstalmentDate>
      </>
    )
  }

  if (isRefunded) {
    const isFullRefund = Instalments.isFullRefund(instalment)
    const refundedAmount = Instalments.getTotalRefundedAmount(instalment)
    const txn = Instalments.getRefundPayment(instalment)
    return (
      <>
        {!isFullRefund && (
          <InstalmentTitleRow>
            <RowLeft>{tt(intl, 'payment.youpaid')}</RowLeft>
            <RowRight>
              <MilesAndAmountValue
                fontSize="1rem"
                currency={totalCashPaidAmount.currency}
                amount={totalCashPaidAmount.amount}
                miles={totalMilesBurned}
              />
            </RowRight>
          </InstalmentTitleRow>
        )}
        <InstalmentTitleRow>
          <RowLeft>
            {intl.formatMessage({ id: 'payment.status.refunded' })}
          </RowLeft>
          <RowRight>
            {FormattedPrice(refundedAmount.currency, -refundedAmount.amount)}
          </RowRight>
        </InstalmentTitleRow>
        <InstalmentDate>
          {tt(intl, 'payment.refundedon')} {moment(txn.createdAt).format('LLL')}
        </InstalmentDate>
      </>
    )
  }

  if (isPaid) {
    const txn = Instalments.getSuccessPayment(instalment)
    return (
      <>
        <InstalmentTitleRow>
          <RowLeft>{tt(intl, 'payment.youpaid')}</RowLeft>
          <RowRight>
            <MilesAndAmountValue
              fontSize="1rem"
              currency={totalCashPaidAmount.currency}
              amount={totalCashPaidAmount.amount}
              miles={totalMilesBurned}
            />
          </RowRight>
        </InstalmentTitleRow>
        {isOverPay && (
          <>
            <InstalmentTitleRow>
              <RowLeft>{tt(intl, 'payment.overpaid')}</RowLeft>
              <RowRight>
                {FormattedPrice(
                  totalOverPaidAmount.currency,
                  totalOverPaidAmount.amount
                )}
              </RowRight>
            </InstalmentTitleRow>
            <InstalmentTitleRow>
              {intl.formatMessage({
                id: 'please.contact.support',
              })}
            </InstalmentTitleRow>
          </>
        )}
        {totalReallocatedAmount.amount > 0 && (
          <InstalmentTitleRow>
            <RowLeft>
              {intl.formatMessage({
                id: 'payment.reallocated',
              })}
            </RowLeft>
            <RowRight>
              {FormattedPrice(
                totalReallocatedAmount.currency,
                -totalReallocatedAmount.amount
              )}
            </RowRight>
          </InstalmentTitleRow>
        )}
        <InstalmentDate>{moment(txn.createdAt).format('LLL')}</InstalmentDate>
      </>
    )
  }

  return ''
}

const InstalmentProgress = ({ order }) => {
  const intl = useIntl()

  const instalmentAmount = Order.getOrderAmount(order)
  const outstandingAmount = Order.getTotalOutstandingAmount(order)
  const paidAmount = Order.getTotalPaidAmount(order)
  const precentage = parseInt(
    (paidAmount.amount / instalmentAmount.amount) * 100,
    10
  )

  return (
    <>
      <ProgressBar precentage={precentage} height="1.25rem" />
      <RemainingRow>
        <RemainingText>{tt(intl, 'payment.remainingamount')}</RemainingText>
        <RemainingAmount>
          {FormattedPrice(outstandingAmount.currency, outstandingAmount.amount)}
        </RemainingAmount>
      </RemainingRow>
    </>
  )
}

const InstalmentsInfo = ({ order, isPayable = true }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  if (!order) return <></>

  const onClickPay = (instalment) => {
    dispatch(
      payForPaylater({
        orderId: order.orderid,
        instalmentId: instalment.instalmentID,
      })
    )
  }

  const { instalments } = order
  const isEnd = Order.isEnd(order)

  return (
    <Container>
      <SubTitle>{tt(intl, 'payment.upcoming_payment')}</SubTitle>
      {!isEnd && <InstalmentProgress order={order} />}
      <InstalmentsContainer>
        {instalments.map((instalment, i) => (
          <Instalment key={instalment.instalmentID}>
            <InstalmentLeft>
              <PaymentBubble
                instalments={instalments}
                instalment={instalment}
              />
              <PaymentStraightLine
                instalments={instalments}
                instalment={instalment}
              />
            </InstalmentLeft>
            <InstalmentRight>
              <InstalmentInfo>
                <PaymentTitle
                  order={order}
                  instalment={instalment}
                  isPayable={isPayable}
                  onPay={onClickPay}
                />
              </InstalmentInfo>
              {i !== instalments.length - 1 && <HR />}
            </InstalmentRight>
          </Instalment>
        ))}
      </InstalmentsContainer>
    </Container>
  )
}

export default InstalmentsInfo
