import { useEffect } from 'react'

const useBlurElement = (className, fn) => {
  useEffect(() => {
    const click = (e) => {
      const classNames = Array.isArray(className) ? className : [className]
      let match = true
      for (let i = 0; i < classNames.length; i += 1) {
        match = e.target.closest(classNames[i])
      }
      if (!match) {
        fn()
      }
    }
    document.body.addEventListener('click', click)
    return () => document.body.removeEventListener('click', click)
  }, [fn])
}

export default useBlurElement
