import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as MilesCloseSvg } from '@/assets/footer/miles-close.svg'
import { ReactComponent as MilesConvertSvg } from '@/assets/footer/miles-convert.svg'
import { ReactComponent as MilesDonateSvg } from '@/assets/footer/miles-donate.svg'
import { ReactComponent as MilesGiftcardSvg } from '@/assets/footer/miles-giftcards.svg'
import { ReactComponent as MilesSpendSvg } from '@/assets/footer/miles-spend.svg'
import BottomSheet from '@/components/BottomSheet'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import urls from '@/constants/urls'
import { tt } from '@/locales/format'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
  box-sizing: border-box;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Control = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const ControlIcon = styled(Icon)`
  border-radius: 50%;
  box-shadow: 0px 4px 16px rgba(255, 204, 51, 0.3),
    0px 1px 10px rgba(255, 204, 51, 0.2), 0px 2px 4px rgba(255, 204, 51, 0.3);
`

const ControlTitle = styled.div`
  font-weight: 700;
  font-size: 0.667rem;
  text-align: center;
`

const FooterMilesModal = ({ isOpen, onClose }) => {
  const intl = useIntl()

  const controls = [
    {
      key: 'spend',
      Icon: MilesSpendSvg,
      title: tt(intl, 'tab.merchant'),
      path: urls.en.merchantsUrl,
    },
    {
      key: 'giftcards',
      Icon: MilesGiftcardSvg,
      title: tt(intl, 'tab.giftcard'),
      path: '/miles/spend',
    },
    false && {
      key: 'convert',
      Icon: MilesConvertSvg,
      title: tt(intl, 'tab.convert'),
      path: '/profile/miles/convert',
    },
    false && {
      key: 'donate',
      Icon: MilesDonateSvg,
      title: 'donate',
      path: '/miles/donate',
    },
  ].filter((s) => s)

  const history = useHistory()

  const goPath = (control) => () => {
    if (control.path.startsWith('/')) {
      history.push(control.path)
    } else {
      window.open(control.path)
    }
    onClose()
  }

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <Content>
        <Spacer height="2.222rem" />
        <Controls>
          {controls.map((control) => (
            <Control key={control.key} onClick={goPath(control)}>
              <ControlIcon
                renderImage={() => <control.Icon />}
                width="3.111rem"
                height="3.111rem"
              />
              <Spacer height="0.778rem" />
              <ControlTitle>{control.title}</ControlTitle>
            </Control>
          ))}
        </Controls>
        <Spacer height="0.5rem" />
        <Icon onClick={onClose} renderImage={() => <MilesCloseSvg />} />
      </Content>
    </BottomSheet>
  )
}

export default FooterMilesModal
