import { call, put, select } from 'redux-saga/effects'

import { fetchDivitMilesMembership } from '@/redux/divitMiles/divitMilesSaga'
import {
  fetchProfile,
  patchEDDAAccount,
  unlinkEDDAAccount,
} from '@/redux/profile/profileSaga'
import { profileSelector } from '@/redux/profile/profileSelector'
import DivitAPI from '@/services/api'

import { profileAdditionalSelector } from '../profileAdditional/profileAdditionalSelector'
import { getError, getErrorMessage } from '../utils/error'
import { actions as updateProfileActions } from './updateProfileSlice'

export function* updateProfile({ payload }) {
  try {
    const profile = yield select(profileSelector)
    yield put(updateProfileActions.updateProfileStart())
    const newProfile = { ...profile, ...payload }
    const {
      firstName,
      lastName,
      address1,
      address2,
      district,
      country,
      province,
      postalCode,
    } = newProfile
    const { data } = yield call(() =>
      DivitAPI.patch('profiles', {
        firstName,
        lastName,
        address1: address1 || '',
        address2: address2 || '',
        district: district || '',
        country: country || '',
        province: province || '',
        postalCode: postalCode || '',
      })
    )
    yield put({ type: 'profile/getProfileSuccess', payload: data.data })
    yield put(updateProfileActions.updateProfileSuccess(data))
  } catch (e) {
    yield put(updateProfileActions.updateProfileFailed(getError(e)))
  }
}

export function* updateProfileAdditional({ payload }) {
  try {
    const profile = yield select(profileAdditionalSelector)
    yield put(updateProfileActions.updateProfileAdditionalStart())
    const newProfile = { ...profile, ...payload }
    const {
      countryOfBirth,
      DOB,
      occupation,
      address1,
      address2,
      district,
      country,
      province,
      postalCode,
      orderType,
      orderID,
      instalmentID,
      nationality,
    } = newProfile
    const { data } = yield call(() =>
      DivitAPI.patch('profiles/additional', {
        countryOfBirth: countryOfBirth || '',
        DOB: DOB || '',
        occupation: occupation || '',
        address1: address1 || '',
        address2: address2 || '',
        district: district || '',
        country: country || '',
        province: province || '',
        postalCode: postalCode || '',
        orderType: orderType || '',
        orderID: orderID || '',
        instalmentID: instalmentID || '',
        nationality: nationality || '',
      })
    )
    yield put({
      type: 'profileAdditional/getProfileAdditionalSuccess',
      payload: data.data,
    })
    yield put(updateProfileActions.updateProfileAdditionalSuccess(data))
  } catch (e) {
    yield put(updateProfileActions.updateProfileAdditionalFailed(getError(e)))
  }
}

export function* updateIdentity({ payload }) {
  try {
    yield put(updateProfileActions.updateProfileIdentityStart())
    const { customerID, base64Image } = payload
    const { data } = yield call(() =>
      DivitAPI.post('/person/identity', {
        customerID,
        base64Image,
      })
    )
    yield put({
      type: 'profile/getProfileIdentityProcessSuccess',
      payload: data.data,
    })
    yield put(updateProfileActions.updateProfileIdentitySuccess(data))
  } catch (e) {
    yield put(updateProfileActions.updateProfileIdentityFailed(getError(e)))
  }
}

export function* updateContactMethod({ payload }) {
  try {
    yield put(updateProfileActions.updateContactMethodStart())
    yield call(() => DivitAPI.patch('profiles/contactmethod', payload))
    yield put({ type: 'fetchProfile' })
    yield put(updateProfileActions.updateContactMethodSuccess())
  } catch (e) {
    yield put(
      updateProfileActions.updateContactMethodFailed(getErrorMessage(e))
    )
  }
}

export function* linkSocialProvider({ payload }) {
  const { provider, accessToken, metadata } = payload
  try {
    yield put(updateProfileActions.linkSocialProviderStart())
    const { data } = yield call(async () =>
      DivitAPI.patch(`oauth/${provider}`, { accessToken, metadata })
    )
    const { data: socialMediaLinkage } = data
    yield put({
      type: 'profile/updateSocialMediaLinkage',
      payload: socialMediaLinkage,
    })
    yield put(updateProfileActions.linkSocialProviderSuccess(data))
  } catch (e) {
    yield put(updateProfileActions.linkSocialProviderFailed(getErrorMessage(e)))
  }
}

export function* unlinkSocialProvider({ payload }) {
  const { provider } = payload
  try {
    yield put(updateProfileActions.unlinkSocialProviderStart())
    const { data } = yield call(async () =>
      DivitAPI.patch(`oauth/${provider}`, {})
    )
    const { data: socialMediaLinkage } = data
    yield put({
      type: 'profile/updateSocialMediaLinkage',
      payload: socialMediaLinkage,
    })
    yield put(updateProfileActions.unlinkSocialProviderSuccess())
  } catch (e) {
    yield put(
      updateProfileActions.unlinkSocialProviderFailed(getErrorMessage(e))
    )
  }
}

export function* changePassword({ payload }) {
  try {
    yield put(updateProfileActions.changePasswordStart())
    const { oldPwd, newPwd } = payload
    const { data } = yield call(() =>
      DivitAPI.patch('/profiles/pwd', {
        oldPwd,
        newPwd,
      })
    )
    yield put(updateProfileActions.changePasswordSuccess(data))
  } catch (e) {
    yield put(updateProfileActions.changePasswordFailed(getErrorMessage(e)))
  }
}

export function* unlinkMilesProgram({ payload }) {
  try {
    yield put(updateProfileActions.unlinkMilesProgramStart())
    const { partner } = payload
    const { data } = yield call(() =>
      DivitAPI.delete('/miles/profiles/membership', { data: { partner } })
    )
    yield fetchDivitMilesMembership()
    yield put(updateProfileActions.unlinkMilesProgramSuccess(data))
  } catch (e) {
    yield put(updateProfileActions.unlinkMilesProgramFailed(getErrorMessage(e)))
  }
}

export function* giftCardMarkAsUsed({ payload }) {
  try {
    yield put(updateProfileActions.giftCardMarkAsUsedStart())
    const { cardId, markedUsed } = payload
    const { data } = yield call(() =>
      DivitAPI.patch(`/giftcard/profiles/cards/${cardId}`, { markedUsed })
    )
    yield put({ type: 'profile/updateGiftCard', payload: data.data })
    yield put(updateProfileActions.giftCardMarkAsUsedSuccess(data.data))
  } catch (e) {
    yield put(updateProfileActions.giftCardMarkAsUsedFailed(getErrorMessage(e)))
  }
}

export function* updateProfileSettings({ payload }) {
  try {
    const settings = yield select((s) => s.profile.settings)
    yield put(updateProfileActions.updateProfileSettingsStart())
    const { data } = yield call(() =>
      DivitAPI.patch('profiles/settings', {
        appType: 'general',
        metadata: { ...settings, ...payload },
      })
    )
    const { metadata } = data.data
    yield put({ type: 'profile/getProfileSettingsSuccess', payload: metadata })
    yield put(updateProfileActions.updateProfileSettingsSuccess())
  } catch (e) {
    yield put(
      updateProfileActions.updateProfileSettingsFailed(getErrorMessage(e))
    )
  }
}

export function* updateProfileAddress({ payload }) {
  try {
    yield put(updateProfileActions.updateProfileAddressStart())
    const { data } = yield call(() =>
      DivitAPI.post('/profiles/addresses', {
        ID: payload.ID,
        addressType: 'shipping',
        address1: payload.address1 || '',
        address2: payload.address2 || '',
        district: payload.district,
        country: payload.country,
        contactFirstname: payload.contactFirstname,
        contactLastname: payload.contactLastname,
        contactNumber: payload.contactNumber,
        isDefault: payload.isDefault,
      })
    )
    yield fetchProfile()
    yield put(updateProfileActions.updateProfileAddressSuccess(data.data))
  } catch (e) {
    yield put(
      updateProfileActions.updateProfileAddressFailed(getErrorMessage(e))
    )
  }
}

export function* removeProfileAddress({ payload }) {
  try {
    yield put(updateProfileActions.removeProfileAddressStart())
    const { data } = yield call(() =>
      DivitAPI.delete('/profiles/addresses', { data: { ID: payload.id } })
    )
    yield fetchProfile()
    yield put(updateProfileActions.removeProfileAddressSuccess(data.data))
  } catch (e) {
    yield put(
      updateProfileActions.removeProfileAddressFailed(getErrorMessage(e))
    )
  }
}

export function* updateEDDAAccount({ payload }) {
  try {
    yield put(updateProfileActions.updateEDDAAccountStart())
    const { maxLimit } = payload
    const data = yield patchEDDAAccount({ maxLimit })
    yield put(updateProfileActions.updateEDDAAccountSuccess(data))
  } catch (e) {
    yield put(updateProfileActions.updateEDDAAccountFailed(getErrorMessage(e)))
  }
}

export function* removeEDDAAccount() {
  try {
    yield put(updateProfileActions.removeEDDAAccountStart())
    const data = yield unlinkEDDAAccount()
    yield put(updateProfileActions.removeEDDAAccountSuccess(data))
  } catch (e) {
    yield put(updateProfileActions.removeEDDAAccountFailed(getErrorMessage(e)))
  }
}

export default null
