import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import ShowMore from '@/components/ShowMore'
import Spacer from '@/components/Spacer'
import * as LineItem from '@/utils/LineItem'

const Flights = styled.div`
  font-size: 0.77rem;
`

const FlightDesc = ({ orderItems }) => {
  const intl = useIntl()

  return orderItems.map((orderItem) => (
    <Flights>
      {intl.formatMessage(
        { id: 'purchases.flight' },
        {
          departure: orderItem.orderData.departure.iataCode,
          arrival: orderItem.orderData.arrival.iataCode,
        }
      )}
    </Flights>
  ))
}

const ListItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ListItemTitle = styled.div`
  flex: 1;
  margin-right: 2rem;
  font-size: 0.77rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ListItemQuantity = styled.div`
  font-size: 0.77rem;
  font-weight: 700;
`

const ListItemsDesc = ({ orderItems }) => {
  const [isShowMore, setIsShowMore] = useState(false)
  const hasShowMoreComponent = LineItem.hasShowMore(orderItems)

  return (
    <>
      {orderItems.map((orderItem, index) => {
        if (index >= 2 && !isShowMore) {
          return null
        }
        return (
          <ListItemContainer>
            <ListItemTitle>{orderItem.orderData.productTitle}</ListItemTitle>
            <ListItemQuantity>
              <span> X</span>
              <span>{orderItem.orderData.qty}</span>
            </ListItemQuantity>
          </ListItemContainer>
        )
      })}
      {hasShowMoreComponent && (
        <>
          <Spacer height="0.66rem" />
          <ShowMore
            isShowMore={isShowMore}
            onClick={() => setIsShowMore((s) => !s)}
          />
        </>
      )}
    </>
  )
}

const PurchaseDesc = ({ orderItems = [] }) => {
  const { orderType } = orderItems[0] ?? {}
  if (orderType === 'retail') {
    return <ListItemsDesc orderItems={orderItems} />
  }
  if (orderType === 'flight') {
    return <FlightDesc orderItems={orderItems} />
  }
  return null
}

export default PurchaseDesc
