import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { animated } from 'react-spring'
import styled from 'styled-components'

import BackButton from '@/components/BackButton'
import BottomSheet from '@/components/BottomSheet'
import LoadingModal from '@/components/LoadingModal'
import PinInput from '@/components/pin/PinInput'
import Spacer from '@/components/Spacer'
import usePageTransition from '@/hooks/usePageTransition'
import usePinInput from '@/hooks/usePinInput'
import useUnmount from '@/hooks/useUnmount'
import { tt } from '@/locales/format'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  background-color: white;
`

const PageContainer = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 1.11rem;
  box-sizing: border-box;
`

const LeftNavButton = styled.div`
  position: absolute;
  top: 2.222rem;
  left: calc(100% / 12);
  padding: 0.27rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const BackButtonContainer = styled.div`
  position: absolute;
  top: 1.889rem;
  left: calc(100% / 12);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const CancelText = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
`

const Content = styled.div`
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  max-width: 15.333rem;
  margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.7778rem;
  text-align: center;
`

const MODAL_RESET_DURATION = 500

const PinInputPage = ({ style, isOpen, isReset, onConfirmPin, onClose }) => {
  const intl = useIntl()

  const { pin, setPin, resetPin, pinError } = usePinInput()

  useEffect(() => {
    if (!isOpen) {
      resetPin()
    }
  }, [isOpen])

  const onFinishPin = (newPin) => {
    onConfirmPin(newPin)
    setTimeout(() => {
      resetPin()
    }, MODAL_RESET_DURATION)
  }

  const title = isReset
    ? tt(intl, 'profile.resetpin')
    : tt(intl, 'profile.setuppin')
  const subTitle = isReset
    ? tt(intl, 'profile.enternewpin')
    : tt(intl, 'profile.enterpin.msg1')

  return (
    <PageContainer style={style}>
      <LeftNavButton onClick={onClose}>
        <CancelText>{tt(intl, 'common.cancel')}</CancelText>
      </LeftNavButton>
      <Content>
        <Spacer height="2.222rem" />
        <Title>{title}</Title>
        <Spacer height="2.222rem" />
        <SubTitle>{subTitle}</SubTitle>
        <Spacer height="2.222rem" />
        <PinInput
          pin={pin}
          hasError={!!pinError}
          onChangePin={setPin}
          onFinishPin={onFinishPin}
        />
      </Content>
      <Spacer height="2.222rem" />
    </PageContainer>
  )
}

const PinConfirmInputPage = ({
  style,
  isOpen,
  isReset,
  confirmPin,
  onDone,
  onBack,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { pin, setPin, resetPin, pinError, setPinError } = usePinInput()

  const { setupPin } = useSelector((s) => s.pin)

  const { isLoading } = setupPin

  useUnmount(() => {
    dispatch({ type: 'pin/reset' })
  })

  useEffect(() => {
    if (!isOpen) {
      resetPin()
      dispatch({ type: 'pin/reset' })
    }
  }, [isOpen])

  useEffect(() => {
    if (setupPin.isSuccess) {
      onDone({ isSuccess: true, pin })
    }
    if (setupPin.isError) {
      setPinError(true)
      setTimeout(() => {
        onBack()
      }, 1000)
    }
  }, [setupPin])

  const onFinishPin = (newPin) => {
    if (confirmPin === newPin) {
      dispatch({ type: 'pin/setupPin', payload: { pin: newPin } })
    } else {
      resetPin()
      setPinError(true)
      setTimeout(() => {
        onBack()
      }, 1000)
    }
  }

  const title = tt(intl, 'profile.verifypin')
  const subTitle = isReset
    ? tt(intl, 'profile.reenterpin')
    : tt(intl, 'profile.reenterpin.msg1')

  return (
    <PageContainer style={style}>
      <BackButtonContainer>
        <BackButton onClick={onBack} />
      </BackButtonContainer>
      <Content>
        <Spacer height="2.222rem" />
        <Title>{title}</Title>
        <Spacer height="2.222rem" />
        <SubTitle>{subTitle}</SubTitle>
        <Spacer height="2.222rem" />
        <PinInput
          pin={pin}
          hasError={!!pinError}
          onChangePin={setPin}
          onFinishPin={onFinishPin}
        />
      </Content>
      <Spacer height="2.222rem" />
      <LoadingModal loading={isLoading} />
    </PageContainer>
  )
}

const PinSetupModal = () => {
  const dispatch = useDispatch()

  const { transitions, setPageIndex } = usePageTransition()
  const [confirmPin, setConfirmPin] = useState('')

  const { setupModal } = useSelector((s) => s.pin)
  const { isOpen, isReset } = setupModal

  const reset = () => {
    setConfirmPin('')
    setPageIndex(0)
  }

  const closeModal = (result) => {
    dispatch({ type: 'pin/closeSetupModal' })
    setTimeout(() => {
      setupModal.callback?.(result)
      dispatch({ type: 'pin/resetSetupModal' })
      reset()
    }, MODAL_RESET_DURATION)
  }

  const onDone = (done) => {
    closeModal(done)
  }

  const onClose = () => {
    closeModal({ isSuccess: false, pin: '' })
  }

  const nextStep = (newPin) => {
    setConfirmPin(newPin)
    setPageIndex(1)
  }

  const onBack = () => {
    setPageIndex(0)
  }

  const onModalLeave = () => {}

  return (
    <BottomSheet
      fullscreen
      open={isOpen}
      onDismiss={onClose}
      onLeave={onModalLeave}
    >
      <Container>
        {transitions((style, i) => {
          if (i === 0) {
            return (
              <PinInputPage
                style={style}
                isOpen={isOpen}
                isReset={isReset}
                onConfirmPin={nextStep}
                onClose={onClose}
              />
            )
          }
          if (i === 1) {
            return (
              <PinConfirmInputPage
                style={style}
                isOpen={isOpen}
                isReset={isReset}
                confirmPin={confirmPin}
                onDone={onDone}
                onBack={onBack}
                onClose={onClose}
              />
            )
          }
          return null
        })}
      </Container>
    </BottomSheet>
  )
}

export default PinSetupModal
