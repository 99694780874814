import moment from 'moment'

export const getPartnerRef = (order) => `#${order.merchantRef}`

export const getOrderAmount = (order) => {
  const { orderAmount, serviceFee } = order
  return {
    currency: orderAmount.currency,
    amount: orderAmount.amount + serviceFee.amount,
  }
}

export const getStatus = (order) => {
  if ((order?.status || '').toLowerCase() === 'completed') return 'completed'
  // TODO: handle paynow other status
  return ''
}

export const isExpired = (order) =>
  (order?.status || '').toLowerCase() === 'expired'

export const isCancelled = (order) =>
  (order?.status || '').toLowerCase() === 'cancelled'

export const isCompleted = (order) =>
  (order?.status || '').toLowerCase() === 'completed'

export const isEnd = (order) =>
  isExpired(order) || isCancelled(order) || isCompleted(order)

export const isPaid = (order) => isCompleted(order)

export const isNormal = (order) =>
  (
    (order?.financialTransactions || [])?.filter(
      (txn) =>
        txn?.transactionType === 'refund' ||
        txn?.transactionType === 'instalment' ||
        txn?.transactionType === 'adjustment' ||
        txn?.transactionType === 'offset'
    ) || []
  ).length === 0

export const getInstalment = (order) => order?.instalments?.[0]

export const getInstalmentAmount = (order) => getInstalment(order)?.amount

const getSuccessTransactions = (order) =>
  order.financialTransactions?.filter(
    (txn) =>
      (txn.transactionType === 'payment' ||
        txn.transactionType === 'adjpayment') &&
      txn.paidBy !== 'miles' &&
      txn.transactionStatus === 'completed'
  ) || []

export const getTotalPaidAmount = (order) => {
  const { currency } = order.orderAmount
  const txns = getSuccessTransactions(order)
  const amount = txns.reduce((sum, txn) => {
    sum += txn.amount.amount
    return sum
  }, 0)
  return { currency, amount }
}

export const isUnderPay = (order) => {
  const { amount } = getInstalment(order).amount
  const totalPaidAmount = getTotalPaidAmount(order).amount
  return totalPaidAmount > 0 && amount > totalPaidAmount
}

export const isOverPay = (order) => {
  const { amount } = getInstalment(order).amount
  const totalPaidAmount = getTotalPaidAmount(order).amount
  const totalRefundedAmount = getTotalRefundedAmount(order).amount
  const totalAdjustedAmount = getTotalAdjustedAmount(order).amount
  return amount < totalPaidAmount - totalRefundedAmount - totalAdjustedAmount
}

// get outstanding amount
export const getOutstandingAmount = (instalment, fx, includeBurn) => {
  if (!fx)
    return {
      currency: instalment.amount.currency,
      amount: instalment.amount.amount,
    }
  const paid = fx.reduce((sum, tx) => {
    if (
      (['payment', 'adjpayment'].indexOf(tx.transactionType) >= 0 &&
        tx.transactionStatus === 'completed') ||
      (['payment', 'adjpayment'].indexOf(tx.type) >= 0 &&
        tx.status === 'completed')
    ) {
      sum += tx.amount.amount - (tx.paymentFee?.amount || 0)
    }
    if (
      ['refund'].indexOf(tx.transactionType) >= 0 ||
      ['refund'].indexOf(tx.type) >= 0
    ) {
      sum -= tx.amount.amount
    }
    return sum
  }, 0)
  const cancelled = fx.reduce((sum, tx) => {
    if (
      tx.transactionType === 'latefee' &&
      tx.transactionStatus === 'completed'
    ) {
      sum -= tx.amount.amount
    }
    if (
      ['adjustment'].indexOf(tx.transactionType) >= 0 &&
      tx.transactionStatus === 'completed'
    ) {
      sum += tx.amount.amount
    }
    if (
      ['instalment', 'noshow'].indexOf(tx.transactionType) >= 0 &&
      tx.transactionStatus === 'cancelled'
    ) {
      sum += tx.amount.amount
    }
    return sum
  }, 0)

  const burnAmount =
    includeBurn && !hasBurned(fx)
      ? instalment.miles > 0
        ? instalment.milesAmount.amount
        : 0
      : 0

  const burnAmountUnpaid =
    includeBurn && !hasBurned(fx)
      ? ['unpaid', 'processing'].indexOf(instalment.status) >= 0 &&
        instalment.miles > 0
        ? instalment.milesAmount.amount
        : 0
      : 0

  return {
    currency: instalment.amount.currency,
    amount:
      instalment.amount.amount -
      paid -
      cancelled -
      (burnAmount - burnAmountUnpaid),
  }
}

export const hasBurned = (fx) => {
  if (!fx) return false
  return fx.find(
    (tx) =>
      (tx.transactionType === 'payment' &&
        tx.paidBy === 'miles' &&
        tx.transactionStatus === 'completed') ||
      (tx.type === 'payment' &&
        tx.paidBy === 'miles' &&
        tx.status === 'completed')
  )
}

export const getTotalOutstandingAmount = (order) => {
  const instalment = getInstalment(order)
  const { currency, amount } = instalment.amount
  let totalPaidAmount = getTotalPaidAmount(order).amount

  // include miles reserved for payment
  if (instalment.miles > 0) {
    totalPaidAmount += instalment.milesAmount.amount
  }

  return { currency, amount: Math.max(amount - totalPaidAmount, 0) }
}

export const getMilesBurned = (order) => {
  const instalment = getInstalment(order)
  return instalment.miles || 0
}

export const getOverPaidAmount = (order) => {
  const { currency, amount } = getInstalment(order).amount
  const totalPaidAmount = getTotalPaidAmount(order).amount
  return { currency, amount: Math.max(totalPaidAmount - amount, 0) }
}

export const getLatestTransaction = (order) => {
  const txns = order.financialTransactions?.slice() || []
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  return txns.length > 0 ? txns[0] : undefined
}

export const getPaymentStatus = (order) => {
  const instalment = getInstalment(order)
  // status 1: completed
  if (instalment.status.toLowerCase() === 'paid') {
    return 'completed'
  }
  // status 2: processing
  if (instalment.status.toLowerCase() === 'processing') {
    return 'processing'
  }
  return 'unpaid'
}

export const isFailed = (order) => {
  const txn = getLatestTransaction(order)
  return txn?.transactionStatus === 'failed'
}

export const getTotalRequestedRefundAmount = (order) => {
  const { currency } = getInstalment(order).amount
  const txns = order.financialTransactions?.slice() || []
  const amt = txns.reduce((sum, txn) => {
    if (
      txn.transactionType === 'refund' &&
      txn.transactionStatus === 'requested'
    ) {
      sum += txn.amount.amount
    }
    return sum
  }, 0)
  return {
    currency,
    amount: amt,
  }
}

export const getTotalRefundedAmount = (order) => {
  const { currency } = getInstalment(order).amount
  const txns = order.financialTransactions?.slice() || []
  const amt = txns.reduce((sum, txn) => {
    if (
      txn.transactionType === 'refund' &&
      txn.transactionStatus === 'completed'
    ) {
      sum += txn.amount.amount
    }
    return sum
  }, 0)
  return {
    currency,
    amount: amt,
  }
}

export const getTotalAdjustedAmount = (order) => {
  const { currency } = getInstalment(order).amount
  const txns = order.financialTransactions?.slice() || []
  const amt = txns.reduce((sum, txn) => {
    if (
      txn.transactionType === 'adjustment' &&
      txn.transactionStatus === 'completed'
    ) {
      sum += txn.amount.amount
    }
    return sum
  }, 0)
  return {
    currency,
    amount: amt,
  }
}

export const getTotalOffsetAmount = (order) => {
  const { currency } = getInstalment(order).amount
  const txns = order.financialTransactions?.slice() || []
  const amt = txns.reduce((sum, txn) => {
    if (
      (txn.transactionType === 'offset' &&
        txn.transactionStatus === 'completed') ||
      (txn.transactionType === 'instalment' &&
        txn.transactionStatus === 'cancelled')
    ) {
      sum += txn.amount.amount
    }
    return sum
  }, 0)
  return {
    currency,
    amount: amt,
  }
}

export const getRefundAmount = (order) => {
  const { currency } = getInstalment(order).amount
  const txns = order.financialTransactions?.slice() || []
  const amt = txns.reduce((sum, txn) => {
    if (txn.transactionType === 'refund') {
      sum += txn.amount.amount
    }
    return sum
  }, 0)
  return {
    currency,
    amount: amt,
  }
}

export const getPaidByMilesAmount = (order) => {
  const { currency } = getInstalment(order).amount
  const txns = order.financialTransactions?.slice() || []
  const amt = txns.reduce((sum, txn) => {
    if (
      txn.transactionStatus === 'completed' &&
      txn.transactionType === 'payment' &&
      txn.paidBy === 'miles'
    ) {
      sum += txn.amount.amount
    }
    return sum
  }, 0)
  return {
    currency,
    amount: amt,
  }
}

export const isPaidByMiles = (order) => {
  const { amount } = getOrderAmount(order)
  return amount === getPaidByMilesAmount(order).amount
}

export const isRefundPending = (order) => {
  if (isPaidByMiles(order)) return false
  const txns = order.financialTransactions?.slice() || []
  return !!txns.find(
    (txn) =>
      txn.transactionStatus === 'requested' && txn.transactionType === 'refund'
  )
}

export const isRefunded = (order) => {
  if (isPaidByMiles(order) && order.status === 'cancelled') {
    return true
  }
  const txns = order.financialTransactions?.slice() || []
  return !!txns.find(
    (txn) =>
      txn.transactionStatus === 'completed' && txn.transactionType === 'refund'
  )
}

export const isRedirectSuccessNotAvailable = (order) =>
  !order?.webhookSuccess || order?.webhookSuccess === 'NA'

export const isRedirectFailureNotAvailable = (order) =>
  !order?.webhookFailure || order?.webhookFailure === 'NA'

export const isPaymentInstrumentProcessing = (order, instrument) => {
  const txns = order.financialTransactions?.slice() || []
  const found = txns.find(
    (txn) =>
      txn.paidBy === instrument &&
      txn.transactionStatus.toLowerCase() === 'pending'
  )
  return !!found
}

export default 0
