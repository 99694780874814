import { useEffect, useState } from 'react'

const useCountDown = ({ timeoutInSec, onTimeout }) => {
  const [countDownInSec, setCountDownInSec] = useState(() =>
    Math.max(0, timeoutInSec)
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countDownInSec <= 0) return
      const t = countDownInSec - 1
      if (t <= 0) {
        setCountDownInSec(0)
        onTimeout?.()
      } else {
        setCountDownInSec(t)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [countDownInSec])

  useEffect(() => {
    setCountDownInSec(Math.max(0, timeoutInSec))
  }, [timeoutInSec])

  return { countDownInSec, setCountDownInSec }
}

export default useCountDown
