import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import * as localStore from '@/utils/localStore'

import { fetchProfile } from '../profile/profileSaga'
import { getError } from '../utils/error'
import { actions as pinActions } from './pinSlice'

export function* authPin({ payload }) {
  try {
    const { pin } = payload
    yield put(pinActions.authPinStart())
    const { data } = yield call(() =>
      DivitAPI.post('/profiles/auth', { pwd: pin, pwdType: 'pin' })
    )
    localStore.setLastLogin(Date.now())
    yield put({
      type: 'profile/setLastLoginTs',
      payload: { lastLoginTs: Date.now() },
    })
    yield put(pinActions.authPinSuccess(data))
  } catch (e) {
    yield put(pinActions.authPinFailed(getError(e)))
  }
}

export function* setupPin({ payload }) {
  try {
    const { pin } = payload
    yield put(pinActions.setupPinStart())
    const { data } = yield call(() => DivitAPI.post('/profiles/pin', { pin }))
    yield fetchProfile()
    yield put(pinActions.setupPinSuccess(data))
  } catch (e) {
    yield put(pinActions.setupPinFailed(getError(e)))
  }
}

export function* validatePin({ payload }) {
  try {
    const { pin } = payload
    yield put(pinActions.validatePinStart())
    const { data } = yield call(() =>
      DivitAPI.post('/profiles/pin/validate', { pin })
    )
    yield put(pinActions.validatePinSuccess(data))
  } catch (e) {
    yield put(pinActions.validatePinFailed(getError(e)))
  }
}

export function* forgotPin() {
  try {
    yield put(pinActions.forgotPinStart())
    const { data } = yield call(() => DivitAPI.post('/verify/pin/forgot'))
    yield put(pinActions.forgotPinSuccess(data))
  } catch (e) {
    yield put(pinActions.forgotPinFailed(getError(e)))
  }
}

export function* validatePinOTP({ payload }) {
  try {
    const { token } = payload
    yield put(pinActions.validatePinOTPStart())
    const { data } = yield call(() =>
      DivitAPI.post('/verify/pin/validate', { token })
    )
    yield put(pinActions.validatePinOTPSuccess(data))
  } catch (e) {
    yield put(pinActions.validatePinOTPFailed(getError(e)))
  }
}

export default 0
