import { useMemoizedFn } from 'ahooks'
import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const Upload = styled.div`
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 3rem;
  background-color: transparent;
  border: none;
  padding: 0;

  & > svg {
    height: auto;
    & path {
      fill: #555;
    }
  }

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    margin-left: 4rem;
  }
`

const HiddenFileInput = styled.input`
  display: none;
`

const UploadImageButton = React.forwardRef(
  ({ accept, children, ...props }, ref) => {
    const inputRef = useRef(null)
    const { onChange, ...restProps } = props

    const onClickField = useMemoizedFn(() => {
      inputRef.current?.click()
      if (isMobile) inputRef.current?.click()
    })
    const onSelectFile = useMemoizedFn((e) => {
      onChange({
        type: 'file',
        target: e.currentTarget,
        value: e.currentTarget?.files,
      })
    })

    return (
      <Wrapper onClick={onClickField}>
        <HiddenFileInput
          type="file"
          ref={(node) => {
            ref?.(node)
            inputRef.current = node
          }}
          {...restProps}
          accept={accept}
          onChange={onSelectFile}
        />
        <Upload>{children}</Upload>
      </Wrapper>
    )
  }
)

export default UploadImageButton
