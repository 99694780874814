import QueryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import LoadingSpinner from '@/components/LoadingSpinner'
import Spacer from '@/components/Spacer'
import { useFeature } from '@/hooks/useFeature'
import { tt } from '@/locales/format'
import * as platform from '@/utils/platform'
import { redirect } from '@/utils/Route'

const Container = styled.div`
  margin-top: 1.25rem;
`

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
  margin: 0.889rem 0;
`

const Content = styled.div`
  max-width: 375px;
  font-size: 0.778rem;
  text-align: center;
  margin: 0 auto;
`
const DownloadApp = ({ title, content }) => {
  const intl = useIntl()
  const { configs } = useFeature()
  const { game = '' } = QueryString.parse(document.location.search)
  const [downloadLink, setDownloadLink] = useState('')

  useEffect(() => {
    if (configs && game) {
      if (platform.IS_MOBILE && configs?.games?.[game]) {
        setDownloadLink(
          configs?.games?.[game]?.downloadUrl?.[
            platform.IS_IOS ? 'ios' : 'android'
          ]
        )
      } else {
        content = tt(intl, 'common.loading')
      }
    } else if (!game) {
      redirect('/')
    } else {
      content = tt(intl, 'common.loading')
    }
  }, [configs, game])

  useEffect(() => {
    if (downloadLink) redirect(downloadLink)
  }, [downloadLink])

  return (
    <Container>
      <LoadingSpinner />
      <Spacer height="1.333rem" />
      {title && <Title>{title}</Title>}
      {content && <Content>{content}</Content>}
    </Container>
  )
}

export default DownloadApp
