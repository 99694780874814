import React from 'react'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/common/close.svg'
import { ReactComponent as DivitMilesIconSvg } from '@/assets/miles/divit-miles-icon-text.svg'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import Spacer from '@/components/Spacer'
import { FormattedMiles } from '@/utils/Price'

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  padding-top: 4.5rem;
  box-sizing: border-box;
  background: rgba(171, 171, 171, 0.75);
`

const Content = styled.div`
  position: relative;
  overflow: auto;
  margin: 0 1rem;
  padding: 0 1.74rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
  max-width: 420px;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    margin: 0 auto;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 1.11rem;
  font-weight: 700;
`

const Miles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MilesValue = styled.div`
  font-size: 2.22rem;
  font-weight: 700;
  margin-right: 1rem;
`

const MilesEarnedModal = ({ isOpen, onClose, miles = 50000 }) => (
  <Modal isOpen={isOpen} isLock>
    <Container>
      <Content>
        <CloseButton onClick={onClose}>
          <Icon
            renderImage={() => <CloseSvg />}
            width="0.8rem"
            height="0.8rem"
          />
        </CloseButton>
        <Spacer height="2.6rem" />
        <Title>You just earned:</Title>
        <Spacer height="1.38rem" />
        <Miles>
          <MilesValue>{FormattedMiles(miles)}</MilesValue>
          <Icon
            renderImage={() => <DivitMilesIconSvg />}
            width="3.1rem"
            height="2.35rem"
          />
        </Miles>
        <Spacer height="2.22rem" />
      </Content>
    </Container>
  </Modal>
)

export default MilesEarnedModal
