import React from 'react'
import Barcode from 'react-barcode'

const BarCode = ({ value, height }) => (
  <Barcode
    value={value}
    width={2}
    height={height || 48}
    margin={0}
    format="CODE128"
    displayValue={false}
  />
)

export default BarCode
