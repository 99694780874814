import React from 'react'
import styled from 'styled-components'

import DivitSvg from '@/assets/divit-black.svg'
import { Container as HeaderContainer } from '@/components/Header'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  height: var(--app-height);
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
`

const Header = styled(HeaderContainer)`
  box-shadow: rgb(238 238 238) 0px 1px 1px;
`

const DivitLogo = styled.img`
  width: 3.889rem;
  height: auto;
`

const Body = styled.div`
  flex: 1;
`

const BaseAppLayout = ({ children }) => (
  <Container className="App">
    <Header>
      <DivitLogo src={DivitSvg} alt="divit logo" />
    </Header>
    <Body>{children}</Body>
  </Container>
)

export default BaseAppLayout
