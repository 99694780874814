import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import AvailableSpend from '@/components/AvailableSpend'
import AvailableSpendModal from '@/components/AvailableSpendModal'
import CancelPurchase from '@/components/CancelPurchase'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorScreen from '@/components/error/ErrorScreen'
import FreezedAccountModal from '@/components/FreezedAccountModal'
import InvalidDocMessageBox, { Mode } from '@/components/InvalidDocMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import PowerbyCiti from '@/components/PowerbyCiti'
import PurchaseSummary from '@/components/PurchaseSummary'
import Spacer from '@/components/Spacer'
import TermsV2 from '@/components/Terms/Terms_v2'
import { MERCHANT_ICONS } from '@/constants/merchants'
import PaymentSettings from '@/constants/payment'
import ProfileStatus from '@/constants/ProfileStatus'
import SHOW_TERMS from '@/constants/terms'
import usePage from '@/hooks/usePage'
import useUpdateValue from '@/hooks/useUpdateValue'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import {
  getFpsCitiServiceSelector,
  getFpsServiceSelector,
} from '@/redux/lookup/lookupSelector'
import { payForPaylater } from '@/redux/pages/payActions'
import { isFobiddenKyc, isResubmitRequestedKyc } from '@/utils/kyc'
import * as LocalStorage from '@/utils/localstorage'
import * as OrderUtils from '@/utils/Order'
import { getExpiredAt, getPartnerName } from '@/utils/Partner'
import { DollarAndCent, FormattedPrice } from '@/utils/Price'
import * as SessionStorage from '@/utils/sessionStorage'

import PaylaterFpsUnavailableScreen from './paylater/PaylaterFpsUnavailableScreen'

const Container = styled(PaddingContent)`
  padding: 0;
`

const InstalmentAmount = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.444rem;
`

const BookButtonContainer = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const BookButton = styled(Button)`
  font-size: 0.889rem;
`

const InstalmentContainer = styled.div`
  position: relative;
`

const OverlayContainer = styled.div`
  margin-top: ${(p) => (p.orderType === 'flight' ? '-30px' : '0')};
  /* pointer-events: ${(p) => (p.isBlur ? 'none' : 'auto')}; */
`

const PurchasePromotionHeader = styled.div`
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const FPSDivitLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
`

const PromotionWording = styled.div``

const PromotionHeading = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 13.714px; /* 85.714% */
`

const BackgroundContainer = styled.div`
  background: linear-gradient(43deg, #ffcc33 75%, rgba(255, 204, 51, 0) 150%);
  background-repeat: no-repeat;
  background-size: ${(p) => (p.isRetail ? '100% 140px' : '100% 180px')};
  /* height: 210px; */
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    background-size: ${(p) => (p.isRetail ? '100% 180px' : '100% 200px')};
  }
`

const Order = () => {
  const history = useHistory()
  const location = useLocation()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { orderId } = useParams()

  // mock
  const { mockIsOutOfAvailableSpend } = queryString.parse(location.search) || {}

  const { profile, purchaseStatus } = useSelector((state) => state.profile)
  const { acceptTNC } = useSelector((state) => state.paylater)
  const { order, orders } = useSelector((state) => state.order)
  const { linkOrder } = useSelector((state) => state.paylater)
  const fpsService = useSelector(getFpsServiceSelector)
  const fpsCitiService = useSelector(getFpsCitiServiceSelector)
  const isFpsUnavailable = !!fpsService || !!fpsCitiService
  const [isOpenModal, setIsOpenModal] = useState(true)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalAvailableSpendIsOpen, setAvailableSpendIsOpen] = useState(true)
  const [errorMessage, updateErrorMessage] = useState('')

  const onClickBackMerchant = () => {
    window.location.href = order.webhook_failure
  }

  const { retry, isInitError, isInitLoading } = usePage({
    skipInit: true,
    initAction: { type: 'pageInit/initCheckoutOrder', payload: { orderId } },
  })

  useEffect(() => {
    if (acceptTNC.isError) {
      updateErrorMessage(intl.formatMessage({ id: 'error.action.agree.terms' }))
    } else {
      updateErrorMessage('')
    }
    if (acceptTNC.isSuccess) {
      const instalmentId = order.instalments[0].instalmentID
      dispatch(
        payForPaylater({
          orderId,
          instalmentId,
        })
      )
    }
  }, [acceptTNC])

  useEffect(() => {
    // remove tmp value for page callback
    SessionStorage.remove('purchusing_order')

    // init / link order
    const checkoutOrder = SessionStorage.getJSON('checkout_order_data')
    if (checkoutOrder?.orderid === orderId) {
      retry()
    } else {
      const expiredAt = getExpiredAt(orderId)
      dispatch({
        type: 'paylater/linkOrder',
        payload: { orderId, expiredAt },
      })
    }

    return () => dispatch({ type: 'paylater/reset' })
  }, [])

  // get other details after link order
  useEffect(() => {
    if (linkOrder.isSuccess) {
      // save checkout order data
      SessionStorage.saveJSON('checkout_order_data', order)

      // re init order data
      retry()
    }
  }, [linkOrder])

  useEffect(() => {
    const billingAddress = profile?.address?.filter(
      (add) => add.addressType === 'billing'
    )[0]
    const isIdValidated =
      (profile?.firstName &&
        profile?.lastName &&
        profile?.nationalID &&
        profile?.DOB) ||
      false
    const isMobileVerified = profile?.tel_validated || false
    const isEmailVerified = profile?.email_validated || false
    const isAddressVerified =
      billingAddress?.address1 ||
      billingAddress?.address2 ||
      billingAddress?.district ||
      billingAddress?.country

    if (
      !isIdValidated ||
      !isMobileVerified ||
      !isEmailVerified ||
      !isAddressVerified
    ) {
      history.push({
        pathname: `/paylater/setup-account`,
        state: {
          orderId,
        },
      })
    }
  }, [profile])

  // get updated order if spend is changed
  useUpdateValue((prevSpend, nextSpend) => {
    const prevAvailableSpend = prevSpend?.availableSpend?.amount || 0
    const nextAvailableSpend = nextSpend?.availableSpend?.amount || 0
    if (prevAvailableSpend !== nextAvailableSpend) {
      dispatch({ type: 'fetchOrder', payload: { orderId } })
    }
  }, profile?.spend)

  if (linkOrder.isLoading) return <LoadingScreen />

  if (linkOrder.isError)
    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={intl.formatMessage({ id: 'error.action.link.order' })}
        error={linkOrder.error}
        onOk={() => window.history.back()}
        okText={intl.formatMessage({ id: 'button.back' })}
      />
    )

  if (acceptTNC.isError)
    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={acceptTNC.error}
        error={acceptTNC.error}
        onOk={() => onClickBackMerchant()}
        okText={intl.formatMessage({ id: 'fps.cancel' })}
      />
    )

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order || !orders || !profile || !purchaseStatus) return <LoadingScreen />
  if (order.orderid !== orderId) return <></>

  const { instalments, cashAmount } = order
  const { availableSpend } = profile?.spend || {}
  const isPurchaseInvalid = purchaseStatus.noOverdueInstalment

  const { amount: instalmentAmount, currency: instalmentCurrency } =
    instalments[0].amount

  const { dollar: instalmentDollar, cent: instalmentCent } = DollarAndCent(
    instalmentAmount - cashAmount.amount
  )

  const nextPaymentOrder = OrderUtils.getNextPaymentOrder(orders)

  const firstUncompleteOrder = OrderUtils.getFirstUncompleteOrder(orders)
  const isOutOfAvailableSpend =
    mockIsOutOfAvailableSpend || order.apr > PaymentSettings.APR_LIMIT
  // const isExceedAvailableSpend = true
  // const isExceedAvailableSpend = !isOutOfAvailableSpend && cashAmount.amount > 0
  const isProfilePaused = profile.status === ProfileStatus.PAUSED
  const isAmber = isResubmitRequestedKyc(profile.kyc)
  const isRed = isFobiddenKyc(profile.kyc)
  const partnerName = getPartnerName(order.partnerid)
  const itemType = OrderUtils.getOrderItemType(order) || 'retail'

  const goPayNextPayment = () => {
    SessionStorage.saveString('purchusing_order', orderId)
    dispatch({
      type: 'redirect/scheduleReview',
      payload: { order: nextPaymentOrder },
    })
  }

  const goPayFirstUncompleteOrder = () => {
    SessionStorage.saveString('purchusing_order', orderId)
    dispatch({
      type: 'redirect/scheduleReview',
      payload: { order: firstUncompleteOrder },
    })
  }

  const goOverduePayment = () => {
    const overdueOrder = OrderUtils.getOverdueOrder(orders)
    if (overdueOrder) {
      SessionStorage.saveString('purchusing_order', orderId)
      history.push(`/schedule/review/${overdueOrder.orderid}`)
    }
  }

  const onClickResubmit = (e) => {
    e.preventDefault()
    history.push(`/paylater/kyc`, {
      previousPage: location.pathname,
    })
  }

  const bookNow = async () => {
    LocalStorage.saveString(SHOW_TERMS, true)
    // history.push(`/paylater/profile/${orderId}`)
    setIsOpen(true)
  }

  const goBackToPartner = (e) => {
    e.preventDefault()

    window.location.href = order.webhook_failure
  }

  // order validation
  if (OrderUtils.isEnd(order)) {
    const instalmentId = order.instalments[0].instalmentID
    const redirectUrl = `/paylater/payment/check/${orderId}/${instalmentId}`
    return <Redirect to={redirectUrl} />
  }

  // fps service unavailable
  if (isFpsUnavailable) {
    return (
      <PaylaterFpsUnavailableScreen
        order={order}
        periodEnd={fpsService?.periodEnd || fpsCitiService?.periodEnd}
      />
    )
  }

  return (
    <>
      {/* <TermsModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} /> */}
      <TermsV2
        order={order}
        profile={profile}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        orderId={orderId}
      />
      <BackgroundContainer
        isRetail={
          order.orderItems &&
          order.orderItems.length !== 0 &&
          order?.orderItems[0]?.orderType === 'retail'
        }
      >
        <Container>
          <Spacer height="1.333rem" />
          {isRed && isProfilePaused && (
            <>
              <InvalidDocMessageBox mode={Mode.Order}>
                <div>
                  <b>{intl.formatMessage({ id: 'kyc.problem' })}</b>
                </div>
                <div>
                  {intl.formatMessage({ id: 'please.contact.support' })}
                </div>
              </InvalidDocMessageBox>
              <Spacer height="1rem" />
            </>
          )}
          {isAmber && (
            <>
              <InvalidDocMessageBox mode={Mode.Order}>
                <b>{intl.formatMessage({ id: 'kyc.problem' })}</b>
                <span> </span>
                <span>{intl.formatMessage({ id: 'kyc.resubmit' })}</span>
              </InvalidDocMessageBox>
              <Spacer height="1rem" />
              <BookButton type="stretch" onClick={onClickResubmit}>
                {intl.formatMessage({ id: 'button.resubmit' })}
              </BookButton>
              <Spacer height="1rem" />
            </>
          )}
          {/* {!purchaseStatus.noOverdueInstalment && (
            <>
              <OverdueOrderMessageBox onClick={goOverduePayment} />
              <Spacer height="1rem" />
            </>
          )} */}
          {/* {isOutOfAvailableSpend && (
          <>
            <OutOfAvailableSpendBox
              nextPaymentOrder={nextPaymentOrder}
              onClickPayNextPayment={goPayNextPayment}
              onClickBackButton={goBackToPartner}
              backButtonText={
                partnerName
                  ? intl.formatMessage(
                      { id: 'back.to.partner' },
                      { partner: partnerName }
                    )
                  : intl.formatMessage({ id: 'button.back' })
              }
            />
          </>
          )}  */}
          <InstalmentContainer>
            <>
              <PurchasePromotionHeader>
                <FPSDivitLogo alt="" src={MERCHANT_ICONS.FPS_BY_DIVIT} />
                <Spacer height="1rem" />
                <PromotionWording>
                  <PromotionHeading>
                    {intl.formatMessage({
                      id:
                        itemType === 'airline'
                          ? 'booking.instalment.box_v2'
                          : 'buy.instalment.box_v2',
                    })}
                    <InstalmentAmount>
                      {FormattedPrice(
                        instalmentCurrency,
                        instalmentDollar * 100 + parseInt(instalmentCent, 10)
                      )}
                      <span>/month</span>
                    </InstalmentAmount>
                  </PromotionHeading>

                  {/* {isExceedAvailableSpend && (
                      <>
                        <Spacer height="0.444rem" />
                        <InstalmentCashAmount>
                          <span>
                            {intl.formatMessage(
                              { id: 'availablespend.cashamount.added' },
                              {
                                amount: FormattedPrice(
                                  cashAmount.currency,
                                  cashAmount.amount
                                ),
                              }
                            )}
                          </span>
                          <InfoIcon data-tip="cash-fee" data-for="cash-fee" />
                        </InstalmentCashAmount>
                        <CashFeeTooltip
                          id="cash-fee"
                          place="top"
                          type="dark"
                          effect="float"
                        >
                          {intl.formatMessage({
                            id: 'availablespend.exceed.tooltip',
                          })}
                        </CashFeeTooltip>
                        <Spacer height="2rem" />
                        <AvailableSpendWarning>
                          <AvailableSpendWarningTitle>
                            {intl.formatMessage(
                              { id: 'availablespend.exceed.amount' },
                              {
                                amount: FormattedPrice(
                                  availableSpend.currency,
                                  availableSpend.amount
                                ),
                              }
                            )}
                          </AvailableSpendWarningTitle>
                          {!!firstUncompleteOrder && (
                            <>
                              <Spacer height="0.444rem" />
                              <AvailableSpendWarningContent>
                                {intl.formatMessage({
                                  id: 'availablespend.complete.pucharse',
                                })}
                              </AvailableSpendWarningContent>
                              <Spacer height="0.444rem" />
                              <AvailableSpendWarningButtonGroup>
                                <PayButton onClick={goPayFirstUncompleteOrder}>
                                  {intl.formatMessage({
                                    id: 'button.pay.now',
                                  })}
                                </PayButton>
                              </AvailableSpendWarningButtonGroup>
                            </>
                          )}
                        </AvailableSpendWarning>
                      </>
                    )} */}
                </PromotionWording>
                <Spacer height="3rem" />
              </PurchasePromotionHeader>
              {/* <InstalmentBox>
                {isExceedAvailableSpend && (
                <>
                  <Spacer height="0.444rem" />
                  <InstalmentCashAmount>
                    <span>
                      {intl.formatMessage(
                        { id: 'availablespend.cashamount.added' },
                        {
                          amount: FormattedPrice(
                            cashAmount.currency,
                            cashAmount.amount
                          ),
                        }
                      )}
                    </span>
                    <InfoIcon data-tip="cash-fee" data-for="cash-fee" />
                  </InstalmentCashAmount>
                  <CashFeeTooltip
                    id="cash-fee"
                    place="top"
                    type="dark"
                    effect="float"
                  >
                    {intl.formatMessage({
                      id: 'availablespend.exceed.tooltip',
                    })}
                  </CashFeeTooltip>
                  <Spacer height="1rem" />
                  <Line />
                  <Spacer height="1rem" />
                  <AvailableSpendWarning>
                    <AvailableSpendWarningTitle>
                      {intl.formatMessage(
                        { id: 'availablespend.exceed.amount' },
                        {
                          amount: FormattedPrice(
                            availableSpend.currency,
                            availableSpend.amount
                          ),
                        }
                      )}
                    </AvailableSpendWarningTitle>
                    {!!firstUncompleteOrder && (
                      <>
                        <Spacer height="0.444rem" />
                        <AvailableSpendWarningContent>
                          {intl.formatMessage({
                            id: 'availablespend.complete.pucharse',
                          })}
                        </AvailableSpendWarningContent>
                        <Spacer height="0.444rem" />
                        <AvailableSpendWarningButtonGroup>
                          <PayButton onClick={goPayFirstUncompleteOrder}>
                            {intl.formatMessage({
                              id: 'button.pay.now',
                            })}
                          </PayButton>
                        </AvailableSpendWarningButtonGroup>
                      </>
                    )}
                  </AvailableSpendWarning>
                </>
              )}
              </InstalmentBox>  */}
            </>
            <OverlayContainer
              isBlur={isOutOfAvailableSpend || isProfilePaused}
              orderType={itemType}
            >
              <PurchaseSummary
                order={order}
                isAccountFrozen={
                  !purchaseStatus.noOverdueInstalment && !isOpenModal
                }
                onClickPaynow={goOverduePayment}
              />
            </OverlayContainer>
          </InstalmentContainer>
          <Spacer height="1.5rem" />

          <AvailableSpend profile={profile} />
          <Spacer height="2.222rem" />
          <BookButtonContainer>
            <BookButton
              type="stretch"
              disabled={
                !isPurchaseInvalid || isOutOfAvailableSpend || isProfilePaused
              }
              onClick={bookNow}
            >
              {intl.formatMessage({
                id:
                  itemType === 'airline' ? 'button.book.now' : 'button.pay.now',
              })}
            </BookButton>
          </BookButtonContainer>
          <Spacer height="1.5rem" />
          <PowerbyCiti />
          <Spacer height="2rem" />
          <CancelPurchase />
          <Spacer height="2rem" />
        </Container>
        <FreezedAccountModal
          isOpen={!purchaseStatus.noOverdueInstalment && isOpenModal}
          onClickPaynow={goOverduePayment}
          onClose={() => setIsOpenModal(false)}
        />
        <AvailableSpendModal
          isOpen={isOutOfAvailableSpend && modalAvailableSpendIsOpen}
          onClose={() => setAvailableSpendIsOpen(false)}
          nextPaymentOrder={nextPaymentOrder}
          onClickPayNextPayment={goPayNextPayment}
          onClickBackButton={goBackToPartner}
          backButtonText={
            partnerName
              ? intl.formatMessage(
                  { id: 'back.to.partner' },
                  { partner: partnerName }
                )
              : intl.formatMessage({ id: 'button.back' })
          }
        />
      </BackgroundContainer>
    </>
  )
}

export default Order
