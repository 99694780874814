import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ImageSvg } from '@/assets/common/image.svg'
import * as LineItemUtils from '@/utils/LineItem'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
`

const ItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: ${(p) => (p.isEmpty ? '#ccc' : 'white')};
  border-radius: ${(p) => (p.isEmpty ? '3px' : '0px')};
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* empty svg */
  & > svg {
    width: 40%;
    height: auto;
    fill: #333;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url(${({ source }) => source}) center center no-repeat;
  background-size: contain;
`

const ItemTitle = styled.div`
  flex: 1;
  display: flex;
  margin-right: 1rem;
  font-size: 0.77rem;
`

const ItemQuantity = styled.div`
  width: 2rem;
  margin-right: 0.8rem;
  font-weight: 700;
  font-size: 0.77rem;
  text-align: right;
`

const LineItem = ({ orderItem, hidePic }) => {
  const { orderData } = orderItem
  const hasAnyPic = LineItemUtils.hasAnyPic(orderData)
  const image = LineItemUtils.getPic(orderData)

  return (
    <Container>
      {hasAnyPic && !hidePic && (
        <ItemImage isEmpty={!image}>
          {image && <Image source={image} />}
          {!image && <ImageSvg />}
        </ItemImage>
      )}
      <ItemTitle>{orderData.productTitle}</ItemTitle>
      <ItemQuantity>
        <span>X</span>
        <span>{orderData.qty}</span>
      </ItemQuantity>
    </Container>
  )
}

export default LineItem
