import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isBrowser, isMobileOnly, isTablet } from 'react-device-detect'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import DivitIcon from '@/assets/divit-alt.svg'
import { ReactComponent as PaymeCTASvg } from '@/assets/payment/payme-cta.svg'
import Box from '@/components/Box'
import CancelPurchase from '@/components/CancelPurchase'
import CheckoutSteps from '@/components/CheckoutSteps'
import { Button, H2, HR } from '@/components/Divit'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import { goBack } from '@/redux/app/appActions'
import * as Instalments from '@/utils/Instalments'
import { mul } from '@/utils/math'
import * as Order from '@/utils/Order'
import { updateQR } from '@/utils/Payme'
import { FormattedPrice, Round } from '@/utils/Price'
import { getPaymentCallbackUri, IsPaylatering } from '@/utils/Route'

import { toErrorMessageWithCode } from '../utils/error'

const Container = styled(PaddingContent)`
  margin-top: 1.25rem;
`

const PaymeH2 = styled(H2)`
  margin-bottom: 1.25rem 0;
`

const PayLink = styled.div`
  display: none;
`

const PaymeBox = styled(Box)`
  padding: 1.25rem calc(100% / 12 - ${({ theme }) => theme.gutter}px);
  width: calc(100% / 12 * 10 - 2px + ${({ theme }) => theme.gutter * 2}px);
  font-size: 0.75rem;
`

const Step = styled.div`
  display: flex;
`

const StepNumber = styled.div`
  flex: 1;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 ${({ theme }) => theme.gutter}px;
`

const StepInfo = styled.div`
  flex: 4;
`

const StepHR = styled(HR)`
  margin: 1rem 0;
`

const Intro = styled.div`
  margin: 0 ${({ theme }) => theme.gutter}px;
`
const Desktop = styled.div``

const Mobile = styled.div`
  margin-bottom: 1rem;
`

const MobileAlt = styled.div`
  margin: 0 10px;
`

const PaymeButtonWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`

const PaymeButton = styled(PaymeCTASvg)`
  #Background {
    fill: #db0011;
  }
`

const ShowMore = styled.div`
  font-size: 0.625rem;
  font-weight: 700;
  color: ${({ theme }) => theme.ticket.secondary};
  cursor: pointer;

  > span {
    margin-left: 0.3125rem;
  }
`

const QRCode = styled.div`
  margin: 0.675rem 0 1rem;
`

const TotalWrapper = styled.div`
  margin: 0.875rem 0 1.875rem;
  padding: 0 calc(100% / 12 - ${({ theme }) => theme.gutter}px);
`

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.75rem calc(100% / 12 - ${({ theme }) => theme.gutter}px);
`

const SelectNewMethodContainer = styled.div`
  text-align: center;
  padding: 0 calc(100% / 12 - ${({ theme }) => theme.gutter}px);
`

const SelectNewMethodButton = styled(Button)`
  background-color: #707070;
  width: 100%;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
`

const Payme = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()
  const { orderId, instalmentId } = useParams()
  const { pathname, state } = useLocation()
  const isPaylatering = IsPaylatering(pathname)
  const { test: isTest, amount: testAmount } = state || {}

  const stepInfoRef = useRef()
  const canvasRef = useRef()
  const logoRef = useRef()
  const payLinkRef = useRef()
  const [showMore, setShowMore] = useState(false)
  const [payLink, setPayLink] = useState(false)

  const method = intl.formatMessage({ id: 'payment.method.payme' })
  const title = intl.formatMessage({ id: 'payment.with' }, { method })

  const toggleShowMore = useCallback(() => {
    setShowMore((s) => !s)
  }, [setShowMore])

  const { order } = useSelector((s) => s.order)
  const { profile } = useSelector((s) => s.profile)
  const { generatePaymeLink } = useSelector((s) => s.payWithPayme)
  const { isLoading, error } = generatePaymeLink

  const drawQR = () => {
    const stepInfo = stepInfoRef.current
    const canvas = canvasRef.current
    const logo = logoRef.current
    const payLinkElement = payLinkRef.current

    if (!stepInfo || !payLinkElement) return

    const url = payLinkElement.dataset.value
    const size = stepInfo.offsetWidth * 0.75
    updateQR(canvas, logo, url, size)
  }

  useEffect(async () => {
    const callbackUri = getPaymentCallbackUri({
      pathname,
      orderId,
      instalmentId,
    })

    if (isTest) {
      dispatch({
        type: 'testPayment/testGeneratePaymeLink',
        payload: { orderId, instalmentId, callbackUri, amount: testAmount },
      })
    } else {
      dispatch({
        type: 'payWithPayme/generatePaymeLink',
        payload: { orderId, instalmentId, callbackUri },
      })
    }
    return () => dispatch({ type: 'payWithPayme/reset' })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', drawQR)
    return () => window.removeEventListener('resize', drawQR)
  }, [])

  useEffect(() => {
    if (generatePaymeLink.isSuccess) {
      const { instalments } = order
      const currentInstalment = instalments.find(
        (instalment) => instalment.instalmentID === instalmentId
      )
      const instalmentTotalAmount = Instalments.getTotalOutstandingAmount(
        currentInstalment
      )
      dispatch({
        type: 'gtm/sendEvent',
        payload: {
          event: 'add_payment_info',
          userId: profile.customerID,
          orderId: order.orderid,
          ecommerce: Order.getGA4EcommercePaymentPayload(
            order,
            instalmentTotalAmount?.amount,
            'payme'
          ),
        },
      })
      setPayLink(generatePaymeLink.data.appLink)
    }
  }, [generatePaymeLink])

  // TODO: handle payment completed case
  useEffect(() => {
    if (error === 'Unable to complete payment') {
      history.push(`/payment/status/${orderId}/${instalmentId}`)
    }
  }, [error])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const selectOtherPayments = (e) => {
    e.preventDefault()

    dispatch({
      type: 'gtm/sendEvent',
      payload: {
        event: 'change_payment_info',
        userId: profile.customerID,
        orderId: order.orderid,
        paymentType: 'payme',
      },
    })

    dispatch(goBack())
  }

  if (isLoading) return <LoadingModal loading />

  if (error)
    return (
      <Container>
        <ErrorMessageBox
          errorMessage={toErrorMessageWithCode(
            intl.formatMessage({ id: 'error.action.payment' }),
            error
          )}
        />
      </Container>
    )

  if (!order || !payLink) return <></>
  if (order.orderid !== orderId) return <></>

  const currentInstalment = order.instalments.find(
    (instalment) => instalment.instalmentID === instalmentId
  )
  const instalmentTotalAmount = Instalments.getTotalOutstandingAmount(
    currentInstalment
  )

  const availablePaymentMethods = Order.getAvailablePaymentMethods(order)

  return (
    <Container>
      {isPaylatering && <CheckoutSteps index={3} />}
      <PaymeH2>{title}</PaymeH2>
      <PayLink ref={payLinkRef} data-value={payLink} />
      <PaymeBox>
        {(isBrowser || isTablet) && (
          <Intro>
            <Desktop>
              {intl.formatMessage(
                { id: 'payment.payme.desktop.intro' },
                {
                  highlight: (
                    <b>
                      {intl.formatMessage({
                        id: 'payment.payme.desktop.intro.highlight',
                      })}
                    </b>
                  ),
                }
              )}
            </Desktop>
          </Intro>
        )}
        {isMobileOnly && (
          <Intro>
            <Mobile>
              {intl.formatMessage(
                { id: 'payment.payme.mobile.intro' },
                {
                  highlight: (
                    <b>
                      {intl.formatMessage({
                        id: 'payment.payme.mobile.intro.highlight',
                      })}
                    </b>
                  ),
                }
              )}
            </Mobile>
            <PaymeButtonWrapper>
              <a href={payLink} rel="noreferrer" aria-label="pay">
                <PaymeButton />
              </a>
            </PaymeButtonWrapper>
            <ShowMore onClick={toggleShowMore}>
              <FontAwesomeIcon
                icon={faChevronDown}
                transform={{ rotate: showMore ? 180 : 0 }}
              />
              {showMore && (
                <span>{intl.formatMessage({ id: 'show.less' })}</span>
              )}
              {!showMore && (
                <span>{intl.formatMessage({ id: 'show.more' })}</span>
              )}
            </ShowMore>
          </Intro>
        )}
        {showMore && (
          <>
            <StepHR />
            <MobileAlt>
              {intl.formatMessage(
                { id: 'payment.payme.mobile.alt' },
                {
                  highlight: (
                    <b>
                      {intl.formatMessage({
                        id: 'payment.payme.mobile.alt.highlight',
                      })}
                    </b>
                  ),
                }
              )}
            </MobileAlt>
          </>
        )}
        {(showMore || isBrowser || isTablet) && (
          <>
            <StepHR />
            <Step>
              <StepNumber>
                {intl.formatMessage({ id: 'payment.steps[0]' })}
              </StepNumber>
              <StepInfo>
                {intl.formatMessage({ id: 'payment.payme.steps[0]' })}
              </StepInfo>
            </Step>
            <StepHR />
            <Step>
              <StepNumber>
                {intl.formatMessage({ id: 'payment.steps[1]' })}
              </StepNumber>
              <StepInfo ref={stepInfoRef}>
                {intl.formatMessage({ id: 'payment.payme.steps[1]' })}
                <QRCode>
                  <canvas ref={canvasRef} />
                  <img
                    ref={logoRef}
                    src={DivitIcon}
                    alt="Divit Icon"
                    hidden
                    onLoad={drawQR}
                  />
                </QRCode>
                {/* temp button on debug purpose because payme doesn't have sandbox app */}
                {(isBrowser || isTablet) && (
                  <a href={payLink} rel="noreferrer" aria-label="pay">
                    <PaymeButton />
                  </a>
                )}
              </StepInfo>
            </Step>
            <StepHR />
            <Step>
              <StepNumber>
                {intl.formatMessage({ id: 'payment.steps[2]' })}
              </StepNumber>
              <StepInfo>
                {intl.formatMessage({ id: 'payment.payme.steps[2]' })}
              </StepInfo>
            </Step>
          </>
        )}
      </PaymeBox>
      <TotalWrapper>
        <HR />
        <Total>
          <div>{intl.formatMessage({ id: 'schedule.total' })}</div>
          <div>
            {FormattedPrice(
              instalmentTotalAmount.currency,
              Round(mul(instalmentTotalAmount.amount, 1.015), 0)
            )}
          </div>
        </Total>
        <HR />
      </TotalWrapper>
      {availablePaymentMethods.length > 1 && (
        <SelectNewMethodContainer>
          <SelectNewMethodButton onClick={selectOtherPayments}>
            {intl.formatMessage({ id: 'payment.select.new.method' })}
          </SelectNewMethodButton>
        </SelectNewMethodContainer>
      )}
      <Spacer height="2rem" />
      <CancelPurchase />
      <Spacer height="2rem" />
    </Container>
  )
}

export default Payme
