import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const payWithPaymeSlice = createSlice({
  name: 'payWithPayme',
  initialState: {
    generatePaymeLink: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.generatePaymeLink = AsyncState.reset()
    },
    generatePaymeLinkStart: (state) => {
      state.generatePaymeLink = AsyncState.start()
    },
    generatePaymeLinkSuccess: (state, action) => {
      state.generatePaymeLink = AsyncState.success(action.payload)
    },
    generatePaymeLinkFailed: (state, action) => {
      state.generatePaymeLink = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = payWithPaymeSlice

export default payWithPaymeSlice.reducer
