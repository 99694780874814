import InputMask from 'react-input-mask'
import styled from 'styled-components'

const MaskedInput = styled(InputMask)`
  width: 100%;
  height: 2.667rem;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.message.error : '#dedede')};
  border-radius: 0.44rem;
  padding: 0 1.333rem;
  font-size: 0.78rem;
  outline: none;
  box-sizing: border-box;
  color: black;

  &:hover {
    border-color: #ccc;
  }

  &:active,
  &:focus {
    border-color: #f7ce55;
  }

  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`

export default MaskedInput
