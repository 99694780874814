import { replace } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import BannerWithDm from '@/assets/banner_with_dm.png'
import Cmer from '@/assets/home/CMER_400x400.png'
import EscapeSportPng from '@/assets/home/EscapadeSports_400x400.png'
import { ReactComponent as FollowBannerSvg } from '@/assets/home/follow-banner.svg'
import Nutrition from '@/assets/home/Nutrition Kitchen_400x400.png'
import OnTheList from '@/assets/home/OnTheList Kitchen_400x400.png'
import { ReactComponent as RightArrowSvg } from '@/assets/home/right-arrow-black.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
// import HomeBanner from '@/components/home/HomeBanner'
// import TripGoalPanel from '@/components/home/TripGoalPanel'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import MilesEarnedModal from '@/components/miles/MilesEarnedModal'
import MilesTransactionHistory from '@/components/miles/MilesTransactionHistory_v2'
import MilesValue from '@/components/miles/MilesValue'
import WelcomeBonusBanner from '@/components/miles/WelcomeBonusBanner'
import PaynowOrderHistory from '@/components/OrderHistory_v2'
import PaylaterOutstandingOrderList from '@/components/paylater/PaylaterOutstandingOrderList_v2'
import Spacer from '@/components/Spacer'
import { useLocale } from '@/contexts/localeContext'
import { Features, useFeature } from '@/hooks/useFeature'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import {
  paylaterActiveOrdersShortSelector,
  paymentsSelector,
} from '@/redux/order/orderSelector'
import {
  campaignSelector,
  divitMilesHistorySelector,
  divitMilesSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'
import * as Campaign from '@/utils/Campaign'
import * as Instalments from '@/utils/Instalments'
import * as OrderPaynow from '@/utils/OrderPaynow'

import MilesAction from './MilesAction'
import ScanToPayBanner from './ScanToPayBanner'

const Container = styled.div`
  background: ${(props) =>
    props.miscConfigs !== 0 ? 'none' : `url(${BannerWithDm}) no-repeat`};
  background-size: contain;
`

const MilesTransactionContainer = styled.div`
  box-sizing: border-box;
`

const MilesTransactionPaddingContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 1.778rem 1.111rem 1.111rem 1.111rem;
  background-color: #fff;

  @media (min-width: ${({ theme }) => `${theme?.breakpoints?.xs || '425'}px`}) {
    padding: 1.778rem calc(100% / 12) calc(100% / 20) calc(100% / 12);
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.sm}px`}) {
    padding: 1.778rem calc(100% / 18) calc(100% / 18) calc(100% / 18);
  }

  ${(p) =>
    p.isFullWidth &&
    css`
      @media (min-width: 768px) {
        max-width: var(--full-width-mode-max-width);
        padding: 0;
        margin: 0 auto;
      }
    `}
`

const MerchantListContainer = styled.div`
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`

const LogoTitle = styled.div`
  font-weight: 600;
`

const LogoBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem 0;
`

const FollowBannerContent = styled.div`
  display: flex;
  justify-content: center;
  background-color: #0f1632;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`

const DivitMilesValue = styled(MilesValue).attrs({ type: 'bw' })`
  margin-left: -15px;
  gap: 10px;
  & div {
    font-weight: 700;
    letter-spacing: 0.03em;
  }
`

const TabsContainer = styled.div`
  background-color: #f5f6f7;
  border-radius: 8px;
  display: flex;
`

const TabsItem = styled.div`
  border-radius: 8px;
  background-color: ${(props) =>
    props.actionNumb === props.actionValue ? '#FFCC33' : '#f5f6f7'};
  height: 3rem;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`

const AllActivities = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
`

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 1.111rem;
`

const DivitMilesHome = () => {
  const intl = useIntl()
  const [tabAction, setTabAction] = useState(0)
  const { configs, featureEnabled } = useFeature()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { state, pathname, search } = location
  const { claimAmount = 0 } = state || {}

  const [isShowEarnedModal, setIsShowEarnedModal] = useState(claimAmount > 0)

  const profile = useSelector(profileSelector)
  const divitMiles = useSelector(divitMilesSelector)
  const divitMilesHistory = useSelector(divitMilesHistorySelector)
  const paylaterActiveOrders = useSelector(paylaterActiveOrdersShortSelector)
  const payments = useSelector(paymentsSelector)
  const hasUpcomingPayment = paylaterActiveOrders.length > 0
  const hasTransactions = payments.length > 0

  const campaign = useSelector(campaignSelector)
  const { currentLocale } = useLocale()
  const miscConfigs = useSelector((s) => s.app?.miscconfigs || [])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileMain' },
  })
  useEffect(() => {
    // checkout processing should be ended
    if (sessionStorage.getItem('is_checkout') === 'true') {
      sessionStorage.removeItem('is_checkout')
      sessionStorage.removeItem('checkout_order')
      dispatch({ type: 'app/forceUpdate' })
    }
    dispatch({
      type: 'app/miscConfig',
      payload: {
        configType: 'landing_ui',
        language: currentLocale,
        status: 'active',
      },
    })
  }, [])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!profile) return <></>

  const onClickCampaignBlock = () => {
    history.push('/history')
  }

  const onCloseMilesEarnedModal = () => {
    // clear state / move to redux
    dispatch(replace(`${pathname}${search}`, {}))
  }

  const onPay = (order) => {
    if (order.instalments.length === 3) {
      const instalment = Instalments.getOutstandingInstalment(order.instalments)
      history.push(
        `/pay/fps/paylater/${order.orderid}/${instalment.instalmentID}`
      )
    } else {
      const instalment = OrderPaynow.getInstalment(order)
      history.push(
        `/pay/fps/paynow/${order.orderID}/${instalment.instalmentID}`
      )
    }
  }

  const onViewOrder = (order) => {
    if (order.instalments.length === 3) {
      history.push(`/schedule/review/${order.orderid}`)
    } else {
      history.push(`/schedule/review/${order.orderID}`)
    }
  }

  const onClickPayment = (payment) => {
    history.push(`/history/${payment.transactionID}`)
  }

  const firstPurchaseMission = Campaign.getPendingCampaign(
    campaign.missions,
    'FIRSTPURCHASE'
  )
  // show 500 bonus miles banner if feature enabled or mission is pending
  const isShow500BonusMilesBanner =
    featureEnabled(Features.SIGNUP500BONUS) || !!firstPurchaseMission

  const milesTransactions = divitMilesHistory.all.slice(0, 5)

  const paymentTransactions = payments.slice(0, 5)

  const paylaterActiveOrdersList = paylaterActiveOrders.slice(0, 3)

  return (
    <Container miscConfigs={miscConfigs.length}>
      {/* {featureEnabled(Features.LUCKYDRAW) &&
        (!configs?.luckdrawStarts ||
          moment(configs?.luckdrawStarts).isBefore(moment())) && (
          <>
            <LuckyDrawSection amount={divitTickets.tickets || 0} />
          </>
        )} */}
      {miscConfigs.length !== 0 && (
        <img
          alt=""
          src={
            miscConfigs[Math.floor(Math.random() * miscConfigs.length)]?.content
              ?.imageURL
          }
        />
      )}
      <Spacer height="2.222rem" />
      <PaddingContent>
        <DivitMilesValue
          miles={divitMiles.balance}
          isSigned={false}
          fontSize="1.778rem"
        />
        <div>{tt(intl, 'landing.availableBalance')}</div>
      </PaddingContent>
      <Spacer height="1.778rem" />
      <PaddingContent>
        <MilesAction />
      </PaddingContent>
      {/* <PaddingContent>
        <HomeBanner />
      </PaddingContent> */}
      <Spacer height="1.778rem" />
      <PaddingContent>
        <ScanToPayBanner />
      </PaddingContent>
      <Spacer height="1.778rem" />

      {/* <PaddingContent>
        <TripGoalPanel totalMilesBalance={divitMiles.balance} />
      </PaddingContent> */}
      {isShow500BonusMilesBanner && (
        <>
          <Spacer height="2.222rem" />
          <WelcomeBonusBanner onClick={onClickCampaignBlock} />
        </>
      )}
      {/* <Spacer height="2.222rem" /> */}
      <MilesTransactionPaddingContainer>
        <MilesTransactionContainer>
          <TabsContainer>
            <TabsItem
              actionNumb={tabAction}
              actionValue={0}
              onClick={() => setTabAction(0)}
            >
              {tt(intl, 'payment.payment')}
            </TabsItem>
            <TabsItem
              actionNumb={tabAction}
              actionValue={1}
              onClick={() => setTabAction(1)}
            >
              {tt(intl, 'common.miles')}
            </TabsItem>
          </TabsContainer>
          <Spacer height="1.778rem" />
          {tabAction === 1 ? (
            <>
              <MilesTransactionHistory isLanding history={milesTransactions} />
            </>
          ) : (
            <div>
              {hasUpcomingPayment && (
                <>
                  <SubTitle>{tt(intl, 'paylater.upcomingpayment')}</SubTitle>
                  <Spacer height="0.889rem" />
                  <PaylaterOutstandingOrderList
                    orders={paylaterActiveOrdersList}
                    onPay={onPay}
                    onClickItem={onViewOrder}
                  />
                  <Spacer height="0.889rem" />
                </>
              )}

              {!hasTransactions && !hasUpcomingPayment ? (
                <></>
              ) : (
                <>
                  <SubTitle>{tt(intl, 'common.transactions')}</SubTitle>
                  <Spacer height="0.889rem" />
                </>
              )}
              <PaynowOrderHistory
                payments={paymentTransactions}
                onClickItem={onClickPayment}
              />
            </div>
          )}
        </MilesTransactionContainer>

        <Spacer height="1rem" />

        {tabAction === 1 ? (
          <AllActivities onClick={() => history.push('/miles/history')}>
            {tt(intl, 'landing.seeAllActivities')} <RightArrowSvg />
          </AllActivities>
        ) : (
          <AllActivities onClick={() => history.push('/history')}>
            {tt(intl, 'landing.seeAllActivities')} <RightArrowSvg />
          </AllActivities>
        )}
      </MilesTransactionPaddingContainer>
      <Spacer height="2.222rem" />
      <PaddingContent>
        <MerchantListContainer>
          <LogoTitle>{tt(intl, 'landing.payWithDivit')}</LogoTitle>
          <Spacer height="1.778rem" />
          <LogoBox>
            <img
              src={EscapeSportPng}
              alt=""
              style={{
                width: '64px',
                height: '64px',
              }}
            />
            <img
              src={Cmer}
              alt=""
              style={{
                width: '64px',
                height: '64px',
              }}
            />
            <img
              src={Nutrition}
              alt=""
              style={{
                width: '64px',
                height: '64px',
              }}
            />
            <img
              src={OnTheList}
              alt=""
              style={{
                width: '64px',
                height: '64px',
              }}
            />
          </LogoBox>
          <Spacer height="1.778rem" />
          <AllActivities
            onClick={() => {
              window.open('https://divit.com.hk/divit-merchants/')
            }}
          >
            {tt(intl, 'landing.seeAllMerchants')} <RightArrowSvg />
          </AllActivities>
        </MerchantListContainer>
      </PaddingContent>
      <Spacer height="2.222rem" />
      <PaddingContent>
        <FollowBannerContent
          onClick={() => {
            window.open('https://www.instagram.com/divit.com.hk/')
          }}
        >
          <FollowBannerSvg />
        </FollowBannerContent>
      </PaddingContent>
      <Spacer height="4rem" />
      <MilesEarnedModal
        isOpen={isShowEarnedModal}
        miles={claimAmount}
        onClose={() => {
          onCloseMilesEarnedModal()
          setIsShowEarnedModal(false)
        }}
      />
    </Container>
  )
}

export default DivitMilesHome
