export const joinName = (arr) => arr.filter((s) => s).join(' ')

export const getProfileId = (countryCode, clientId) => {
  if (countryCode) {
    return `${countryCode} ${clientId.substring(0, 4)} ****`
  }
  return clientId
}

export default 0
