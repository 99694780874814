import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import { ActionButtons, PrimaryButton } from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { tt } from '@/locales/format'

const SubTitle = styled.div`
  max-width: 375px;
  margin: 0 auto;
  font-size: 0.778rem;
  line-height: 1.5;
  text-align: center;
`

const ConvertMilesUnavailableScreen = ({ periodEnd }) => {
  const intl = useIntl()
  const history = useHistory()

  const goHome = () => {
    history.push('/')
  }

  const content =
    periodEnd && periodEnd > 0
      ? tt(intl, 'common.convertmilesmaintenance.tryagainafter', {
          endAt: moment.unix(periodEnd).format('DD MMM YYYY h:mm a'),
        })
      : tt(intl, 'common.convertmilesmaintenance.tryagainlater')

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title>{tt(intl, 'common.systemmaintenance')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{content}</SubTitle>
          <Spacer height="1.333rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <PrimaryButton onClick={goHome}>
              {tt(intl, 'common.done')}
            </PrimaryButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default ConvertMilesUnavailableScreen
