import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ArrowSvg } from '@/assets/common/back-arrow-black.svg'
import Icon from '@/components/Icon'

const BackButton = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${({ color }) => color};
  margin-left: -0.444rem;
`

const BackRow = styled.div`
  width: 100%;
  padding: 0 1.111rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    padding: 0 calc(100% / 12);
  }
`

const Button = ({ className, type = 'button', color = 'black', onClick }) => {
  const Btn = () => (
    <BackButton className={className} color={color} onClick={onClick}>
      <Icon
        renderImage={() => <ArrowSvg />}
        wrapperWidth="1.778rem"
        wrapperHeight="1.778rem"
        width="0.444rem"
        height="0.889rem"
      />
    </BackButton>
  )

  if (type === 'row') {
    return (
      <BackRow>
        <Btn />
      </BackRow>
    )
  }

  return <Btn />
}

export default Button
