import BOCLogo from '@/assets/banks/boc.png'
import BOCOMLogo from '@/assets/banks/bocom.png'
import CitiLogo from '@/assets/banks/citi.png'
import DBSLogo from '@/assets/banks/dbs.png'
import HSBLogo from '@/assets/banks/hsb.png'
import HSBCLogo from '@/assets/banks/hsbc.png'
import MoxLogo from '@/assets/banks/mox.png'
import SCLogo from '@/assets/banks/sc.png'

const banks = [
  {
    bankCode: 3,
    en: 'Standard Chartered Bank (Hong Kong)',
    'zh-hk': '渣打銀行（香港）',
    'zh-cn': '渣打银行（香港）',
    Logo: SCLogo,
  },
  {
    bankCode: 4,
    en: 'The Hongkong and Shanghai Banking Corporation',
    'zh-hk': '香港上海滙豐銀行',
    'zh-cn': '香港上海汇丰银行',
    Logo: HSBCLogo,
  },
  {
    bankCode: 9,
    en: 'China Construction Bank (Asia)',
    'zh-hk': '中國建設銀行（亞洲）',
    'zh-cn': '中国建设银行（亚洲）',
  },
  {
    bankCode: 12,
    en: 'Bank of China (Hong Kong)',
    'zh-hk': '中國銀行（香港）',
    'zh-cn': '中国银行（香港）',
    Logo: BOCLogo,
  },
  {
    bankCode: 15,
    en: 'The Bank of East Asia',
    'zh-hk': '東亞銀行',
    'zh-cn': '东亚银行',
  },
  {
    bankCode: 16,
    en: 'DBS Bank (Hong Kong)',
    'zh-hk': '星展銀行（香港）',
    'zh-cn': '星展银行（香港）',
    Logo: DBSLogo,
  },
  {
    bankCode: 18,
    en: 'China CITIC Bank International',
    'zh-hk': '中信銀行（國際）',
    'zh-cn': '中信银行（国际）',
  },
  {
    bankCode: 20,
    en: 'CMB Wing Lung Bank',
    'zh-hk': '招商永隆銀行',
    'zh-cn': '招商永隆银行',
  },
  {
    bankCode: 24,
    en: 'Hang Seng Bank',
    'zh-hk': '恒生銀行',
    'zh-cn': '恒生银行',
    Logo: HSBLogo,
  },
  {
    bankCode: 25,
    en: 'Shanghai Commercial Bank',
    'zh-hk': '上海商業銀行',
    'zh-cn': '上海商业银行',
  },
  {
    bankCode: 28,
    en: 'Public Bank (Hong Kong)',
    'zh-hk': '大眾銀行（香港）',
    'zh-cn': '大众银行（香港）',
  },
  {
    bankCode: 35,
    en: 'OCBC Wing Hang Bank',
    'zh-hk': '華僑永亨銀行',
    'zh-cn': '华侨永亨银行',
  },
  {
    bankCode: 38,
    en: 'Tai Yau Bank',
    'zh-hk': '大有銀行',
    'zh-cn': '大有银行',
  },
  {
    bankCode: 39,
    en: 'Chiyu Banking Corporation',
    'zh-hk': '集友銀行',
    'zh-cn': '集友银行',
  },
  {
    bankCode: 40,
    en: 'Dah Sing Bank',
    'zh-hk': '大新銀行',
    'zh-cn': '大新银行',
  },
  {
    bankCode: 41,
    en: 'Chong Hing Bank',
    'zh-hk': '創興銀行',
    'zh-cn': '创兴银行',
  },
  {
    bankCode: 43,
    en: 'Nanyang Commercial Bank',
    'zh-hk': '南洋商業銀行',
    'zh-cn': '南洋商业银行',
  },
  {
    bankCode: 61,
    en: 'Tai Sang Bank',
    'zh-hk': '大生銀行',
    'zh-cn': '大生银行',
  },
  {
    bankCode: 72,
    en: 'Industrial and Commercial Bank of China (Asia)',
    'zh-hk': '中國工商銀行（亞洲）',
    'zh-cn': '中国工商银行（亚洲）',
  },
  {
    bankCode: 128,
    en: 'Fubon Bank (Hong Kong)',
    'zh-hk': '富邦銀行（香港）',
    'zh-cn': '富邦银行（香港）',
  },
  {
    bankCode: 250,
    en: 'Citibank (Hong Kong)',
    'zh-hk': '花旗銀行（香港）',
    'zh-cn': '花旗银行（香港）',
    Logo: CitiLogo,
  },
  {
    bankCode: 382,
    en: 'Bank of Communications (Hong Kong)',
    'zh-hk': '交通銀行（香港）',
    'zh-cn': '交通银行（香港）',
    Logo: BOCOMLogo,
  },
  {
    bankCode: 384,
    en: 'Morgan Stanley Bank Asia',
    'zh-hk': '摩根史坦利銀行（亞洲）',
    'zh-cn': '摩根史坦利银行（亚洲）',
  },
  {
    bankCode: 387,
    en: 'ZA Bank',
    'zh-hk': '眾安銀行',
    'zh-cn': '众安银行',
  },
  {
    bankCode: 388,
    en: 'Livi Bank',
    'zh-hk': '理慧銀行',
    'zh-cn': '理慧银行',
  },
  {
    bankCode: 389,
    en: 'Mox Bank',
    'zh-hk': 'Mox',
    'zh-cn': 'Mox',
    Logo: MoxLogo,
  },
  {
    bankCode: 390,
    en: 'Welab Bank',
    'zh-hk': '匯立銀行',
    'zh-cn': '汇立银行',
  },
  {
    bankCode: 391,
    en: 'Fusion Bank',
    'zh-hk': '富融銀行',
    'zh-cn': '富融银行',
  },
  {
    bankCode: 392,
    en: 'Ping An OneConnect Bank (Hong Kong)',
    'zh-hk': '平安壹賬通銀行（香港）',
    'zh-cn': '平安壹账通银行（香港）',
  },
  {
    bankCode: 393,
    en: 'Ant Bank (Hong Kong)',
    'zh-hk': '螞蟻銀行（香港）',
    'zh-cn': '蚂蚁银行（香港）',
  },
  {
    bankCode: 395,
    en: 'Airstar Bank',
    'zh-hk': '天星銀行',
    'zh-cn': '天星银行',
  },
  {
    bankCode: 5,
    en: 'Credit Agricole Corporate and Investment Bank',
    'zh-hk': '東方匯理銀行',
    'zh-cn': '东方汇理银行',
  },
  {
    bankCode: 6,
    en: 'Citibank, N.A.',
    'zh-hk': '花旗銀行',
    'zh-cn': '花旗银行',
  },
  {
    bankCode: 7,
    en: 'JP Morgan Chase Bank',
    'zh-hk': '摩根大通銀行',
    'zh-cn': '摩根大通银行',
  },
  {
    bankCode: 8,
    en: 'NatWest Markets',
    'zh-hk': '國民西敏寺資本市場銀行',
    'zh-cn': '国民西敏寺资本市场银行',
  },
  {
    bankCode: 22,
    en: 'Oversea-Chinese Banking Corporation',
    'zh-hk': '華僑銀行',
    'zh-cn': '华侨银行',
  },
  {
    bankCode: 27,
    en: 'Bank of Communications',
    'zh-hk': '交通銀行',
    'zh-cn': '交通银行',
  },
  {
    bankCode: 45,
    en: 'UCO Bank',
    'zh-hk': 'UCO銀行',
    'zh-cn': 'UCO银行',
  },
  {
    bankCode: 46,
    en: 'KEB Hana Bank',
    'zh-hk': '韓亞銀行',
    'zh-cn': '韩亚银行',
  },
  {
    bankCode: 47,
    en: 'MUFG Bank',
    'zh-hk': '三菱日聯銀行',
    'zh-cn': '三菱日联银行',
  },
  {
    bankCode: 49,
    en: 'Bangkok Bank',
    'zh-hk': '盤谷銀行',
    'zh-cn': '盘谷银行',
  },
  {
    bankCode: 50,
    en: 'Indian Overseas Bank',
    'zh-hk': '印度海外銀行',
    'zh-cn': '印度海外银行',
  },
  {
    bankCode: 54,
    en: 'Deutsche Bank',
    'zh-hk': '德意志銀行',
    'zh-cn': '德意志银行',
  },
  {
    bankCode: 55,
    en: 'Bank of America',
    'zh-hk': '美國銀行',
    'zh-cn': '美国银行',
  },
  {
    bankCode: 56,
    en: 'BNP Paribas',
    'zh-hk': '法國巴黎銀行',
    'zh-cn': '法国巴黎银行',
  },
  {
    bankCode: 58,
    en: 'Bank of India',
    'zh-hk': '印度銀行',
    'zh-cn': '印度银行',
  },
  {
    bankCode: 60,
    en: 'National Bank of Pakistan',
    'zh-hk': '巴基斯坦國民銀行',
    'zh-cn': '巴基斯坦国民银行',
  },
  {
    bankCode: 63,
    en: 'Malayan Banking Corporation',
    'zh-hk': '馬來亞銀行',
    'zh-cn': '马来亚银行',
  },
  {
    bankCode: 65,
    en: 'Sumitomo Mitsui Banking Corporation',
    'zh-hk': '三井住友銀行',
    'zh-cn': '三井住友银行',
  },
  {
    bankCode: 66,
    en: 'Bank Negara Indonesia',
    'zh-hk': '印尼國家銀行',
    'zh-cn': '印尼国家银行',
  },
  {
    bankCode: 67,
    en: 'BDO Unibank',
    'zh-hk': '金融銀行',
    'zh-cn': '金融银行',
  },
  {
    bankCode: 71,
    en: 'United Overseas Bank',
    'zh-hk': '大華銀行',
    'zh-cn': '大华银行',
  },
  {
    bankCode: 74,
    en: 'Barclays Bank PLC',
    'zh-hk': '巴克萊銀行',
    'zh-cn': '巴克莱银行',
  },
  {
    bankCode: 76,
    en: 'The Bank of Nova Scotia',
    'zh-hk': '加拿大豐業銀行',
    'zh-cn': '加拿大丰业银行',
  },
  {
    bankCode: 80,
    en: 'Royal Bank of Canada',
    'zh-hk': '加拿大皇家銀行',
    'zh-cn': '加拿大皇家银行',
  },
  {
    bankCode: 81,
    en: 'Societe Generale',
    'zh-hk': '法國興業銀行',
    'zh-cn': '法国兴业银行',
  },
  {
    bankCode: 82,
    en: 'State Bank of India',
    'zh-hk': '印度國家銀行',
    'zh-cn': '印度国家银行',
  },
  {
    bankCode: 85,
    en: 'The Toronto-Dominion Bank',
    'zh-hk': '多倫多道明銀行',
    'zh-cn': '多伦多道明银行',
  },
  {
    bankCode: 86,
    en: 'Bank of Montreal',
    'zh-hk': '滿地可銀行',
    'zh-cn': '满地可银行',
  },
  {
    bankCode: 92,
    en: 'Canadian Imperial Bank of Commerce',
    'zh-hk': '加拿大帝國商業銀行',
    'zh-cn': '加拿大帝国商业银行',
  },
  {
    bankCode: 97,
    en: 'Commerzbank',
    'zh-hk': '德國商業銀行',
    'zh-cn': '德国商业银行',
  },
  {
    bankCode: 103,
    en: 'UBS Hong Kong',
    'zh-hk': '瑞銀集團香港',
    'zh-cn': '瑞银集团香港',
  },
  {
    bankCode: 106,
    en: 'HSBC Bank USA',
    'zh-hk': '美國滙豐銀行',
    'zh-cn': '美国汇丰银行',
  },
  {
    bankCode: 109,
    en: 'Mizuho Bank',
    'zh-hk': '瑞穗銀行',
    'zh-cn': '瑞穗银行',
  },
  {
    bankCode: 113,
    en: 'Deutsche Zentral-Genossenschaftsbank',
    'zh-hk': '德國中央合作銀行',
    'zh-cn': '德国中央合作银行',
  },
  {
    bankCode: 118,
    en: 'Woori Bank',
    'zh-hk': '友利銀行',
    'zh-cn': '友利银行',
  },
  {
    bankCode: 119,
    en: 'Philippine National Bank',
    'zh-hk': '菲律賓國家銀行',
    'zh-cn': '菲律宾国家银行',
  },
  {
    bankCode: 138,
    en: 'Mitsubishi UFJ Trust and Banking',
    'zh-hk': '三菱日聯信託銀行',
    'zh-cn': '三菱日联信托银行',
  },
  {
    bankCode: 139,
    en: 'The Bank of New York Mellon',
    'zh-hk': '紐約梅隆銀行',
    'zh-cn': '纽约梅隆银行',
  },
  {
    bankCode: 145,
    en: 'ING Bank',
    'zh-hk': 'ING銀行',
    'zh-cn': 'ING银行',
  },
  {
    bankCode: 147,
    en: 'Banco Bilbao Vizcaya Argentaria',
    'zh-hk': '西班牙外換銀行',
    'zh-cn': '西班牙外换银行',
  },
  {
    bankCode: 150,
    en: 'National Australia Bank',
    'zh-hk': '澳洲國民銀行',
    'zh-cn': '澳洲国民银行',
  },
  {
    bankCode: 151,
    en: 'Westpac Banking Corporation',
    'zh-hk': '西太平洋銀行',
    'zh-cn': '西太平洋银行',
  },
  {
    bankCode: 152,
    en: 'Australia and New Zealand Banking Group',
    'zh-hk': '澳新銀行集團',
    'zh-cn': '澳新银行集团',
  },
  {
    bankCode: 153,
    en: 'Commonwealth Bank of Australia',
    'zh-hk': '澳洲聯邦銀行',
    'zh-cn': '澳洲联邦银行',
  },
  {
    bankCode: 161,
    en: 'Intesa Sanpaolo',
    'zh-hk': '義大利聯合聖保羅銀行',
    'zh-cn': '义大利联合圣保罗银行',
  },
  {
    bankCode: 164,
    en: 'UniCredit Bank',
    'zh-hk': '裕信（德國）銀行',
    'zh-cn': '裕信（德国）银行',
  },
  {
    bankCode: 170,
    en: 'The Chiba Bank',
    'zh-hk': '千葉銀行',
    'zh-cn': '千叶银行',
  },
  {
    bankCode: 178,
    en: 'KBC Bank',
    'zh-hk': '比利時聯合銀行',
    'zh-cn': '比利时联合银行',
  },
  {
    bankCode: 180,
    en: 'Wells Fargo Bank',
    'zh-hk': '富國銀行',
    'zh-cn': '富国银行',
  },
  {
    bankCode: 183,
    en: 'Cooperatieve Rabobank',
    'zh-hk': '荷蘭合作銀行',
    'zh-cn': '荷兰合作银行',
  },
  {
    bankCode: 185,
    en: 'DBS Bank',
    'zh-hk': '星展銀行',
    'zh-cn': '星展银行',
    Logo: DBSLogo,
  },
  {
    bankCode: 186,
    en: 'The Shizuoka Bank',
    'zh-hk': '靜岡銀行',
    'zh-cn': '静冈银行',
  },
  {
    bankCode: 188,
    en: 'The Hachijuni Bank',
    'zh-hk': '八十二銀行',
    'zh-cn': '八十二银行',
  },
  {
    bankCode: 198,
    en: 'Hua Nan Commercial Bank',
    'zh-hk': '華南商業銀行',
    'zh-cn': '华南商业银行',
  },
  {
    bankCode: 199,
    en: 'The Shiga Bank',
    'zh-hk': '滋賀銀行',
    'zh-cn': '滋贺银行',
  },
  {
    bankCode: 201,
    en: 'Bank of Taiwan',
    'zh-hk': '臺灣銀行',
    'zh-cn': '台湾银行',
  },
  {
    bankCode: 202,
    en: 'The Chugoku Bank',
    'zh-hk': '中國銀行',
    'zh-cn': '中国银行',
  },
  {
    bankCode: 203,
    en: 'First Commercial Bank',
    'zh-hk': '第一商業銀行',
    'zh-cn': '第一商业银行',
  },
  {
    bankCode: 206,
    en: 'Chang Hwa Commercial Bank',
    'zh-hk': '彰化商業銀行',
    'zh-cn': '彰化商业银行',
  },
  {
    bankCode: 210,
    en: 'Natixis',
    'zh-hk': '法國外貿銀行',
    'zh-cn': '法国外贸银行',
  },
  {
    bankCode: 214,
    en: 'Industrial and Commercial Bank of China',
    'zh-hk': '中國工商銀行',
    'zh-cn': '中国工商银行',
  },
  {
    bankCode: 220,
    en: 'State Street Bank and Trust Company',
    'zh-hk': '道富銀行和信託公司',
    'zh-cn': '道富银行和信托公司',
  },
  {
    bankCode: 221,
    en: 'China Construction Bank',
    'zh-hk': '中國建設銀行',
    'zh-cn': '中国建设银行',
  },
  {
    bankCode: 222,
    en: 'Agricultural Bank of China',
    'zh-hk': '中國農業銀行',
    'zh-cn': '中国农业银行',
  },
  {
    bankCode: 227,
    en: 'Erste Group Bank',
    'zh-hk': '第一儲蓄銀行',
    'zh-cn': '第一储蓄银行',
  },
  {
    bankCode: 229,
    en: 'CTBC Bank',
    'zh-hk': '中國信託商業銀行',
    'zh-cn': '中国信托商业银行',
  },
  {
    bankCode: 230,
    en: 'Taiwan Business Bank',
    'zh-hk': '臺灣中小企業銀行',
    'zh-cn': '台湾中小企业银行',
  },
  {
    bankCode: 233,
    en: 'Credit Suisse',
    'zh-hk': '瑞士信貸集團',
    'zh-cn': '瑞士信贷集团',
  },
  {
    bankCode: 236,
    en: 'Cathay United Bank',
    'zh-hk': '國泰世華商業銀行',
    'zh-cn': '国泰世华商业银行',
  },
  {
    bankCode: 237,
    en: 'EFG Bank',
    'zh-hk': '瑞士盈豐銀行',
    'zh-cn': '瑞士盈丰银行',
  },
  {
    bankCode: 238,
    en: 'China Merchants Bank',
    'zh-hk': '招商銀行',
    'zh-cn': '招商银行',
  },
  {
    bankCode: 239,
    en: 'Taipei Fubon Commercial Bank',
    'zh-hk': '台北富邦商業銀行',
    'zh-cn': '台北富邦商业银行',
  },
  {
    bankCode: 241,
    en: 'Bank SinoPac',
    'zh-hk': '永豐商業銀行',
    'zh-cn': '永丰商业银行',
  },
  {
    bankCode: 242,
    en: 'Mega International Commercial Bank',
    'zh-hk': '兆豐國際商業銀行',
    'zh-cn': '兆丰国际商业银行',
  },
  {
    bankCode: 243,
    en: 'E.SUN Commercial Bank',
    'zh-hk': '玉山商業銀行',
    'zh-cn': '玉山商业银行',
  },
  {
    bankCode: 244,
    en: 'Bank SinoPac',
    'zh-hk': '永豐商業銀行',
    'zh-cn': '永丰商业银行',
  },
  {
    bankCode: 245,
    en: 'Taishin International Bank',
    'zh-hk': '台新國際商業銀行',
    'zh-cn': '台新国际商业银行',
  },
  {
    bankCode: 248,
    en: 'Hong Leong Bank',
    'zh-hk': '豐隆銀行',
    'zh-cn': '丰隆银行',
  },
  {
    bankCode: 249,
    en: 'Standard Chartered Bank',
    'zh-hk': '渣打銀行',
    'zh-cn': '渣打银行',
  },
  {
    bankCode: 251,
    en: 'ICICI Bank',
    'zh-hk': '印度工業信貸投資銀行',
    'zh-cn': '印度工业信贷投资银行',
  },
  {
    bankCode: 254,
    en: 'Melli Bank Plc',
    'zh-hk': 'Melli Bank Plc',
    'zh-cn': 'Melli Bank Plc',
  },
  {
    bankCode: 258,
    en: 'East West Bank',
    'zh-hk': '華美銀行',
    'zh-cn': '华美银行',
  },
  {
    bankCode: 260,
    en: 'Far Eastern International Bank',
    'zh-hk': '遠東國際商業銀行',
    'zh-cn': '远东国际商业银行',
  },
  {
    bankCode: 263,
    en: 'Cathay Bank',
    'zh-hk': '國泰銀行',
    'zh-cn': '国泰银行',
  },
  {
    bankCode: 264,
    en: 'Land Bank of Taiwan',
    'zh-hk': '臺灣土地銀行',
    'zh-cn': '台湾土地银行',
  },
  {
    bankCode: 265,
    en: 'Taiwan Cooperative Bank',
    'zh-hk': '合作金庫商業銀行',
    'zh-cn': '合作金库商业银行',
  },
  {
    bankCode: 267,
    en: 'Banco Santander',
    'zh-hk': '桑坦德銀行',
    'zh-cn': '桑坦德银行',
  },
  {
    bankCode: 268,
    en: 'Union Bank of India',
    'zh-hk': '印度聯合銀行',
    'zh-cn': '印度联合银行',
  },
  {
    bankCode: 269,
    en: 'The Shanghai Commercial & Savings Bank',
    'zh-hk': '上海商業儲蓄銀行',
    'zh-cn': '上海商业储蓄银行',
  },
  {
    bankCode: 271,
    en: 'Industrial Bank of Korea',
    'zh-hk': '中小企業銀行',
    'zh-cn': '中小企业银行',
  },
  {
    bankCode: 272,
    en: 'Bank of Singapore',
    'zh-hk': '新加坡銀行',
    'zh-cn': '新加坡银行',
  },
  {
    bankCode: 273,
    en: 'Shinhan Bank',
    'zh-hk': '新韓銀行',
    'zh-cn': '新韩银行',
  },
  {
    bankCode: 274,
    en: 'O-Bank',
    'zh-hk': '王道商業銀行',
    'zh-cn': '王道商业银行',
  },
  {
    bankCode: 275,
    en: 'BNP Paribas Securities Services',
    'zh-hk': 'BNP Paribas Securities Services',
    'zh-cn': 'BNP Paribas Securities Services',
  },
  {
    bankCode: 276,
    en: 'China Development Bank',
    'zh-hk': '國家開發銀行',
    'zh-cn': '国家开发银行',
  },
  {
    bankCode: 277,
    en: 'First Abu Dhabi Bank',
    'zh-hk': '阿布達比第一銀行',
    'zh-cn': '阿布达比第一银行',
  },
  {
    bankCode: 278,
    en: 'Bank J. Safra Sarasin',
    'zh-hk': '嘉盛銀行',
    'zh-cn': '嘉盛银行',
  },
  {
    bankCode: 307,
    en: 'ABN AMRO Bank',
    'zh-hk': '荷蘭銀行',
    'zh-cn': '荷兰银行',
  },
  {
    bankCode: 308,
    en: 'HDFC Bank',
    'zh-hk': 'HDFC銀行',
    'zh-cn': 'HDFC银行',
  },
  {
    bankCode: 309,
    en: 'Union Bancaire Privee',
    'zh-hk': '瑞聯銀行',
    'zh-cn': '瑞联银行',
  },
  {
    bankCode: 316,
    en: 'Skandinaviska Enskilda Banken',
    'zh-hk': '瑞典北歐斯安銀行',
    'zh-cn': '瑞典北欧斯安银行',
  },
  {
    bankCode: 320,
    en: 'Bank Julius Baer',
    'zh-hk': '寶盛銀行',
    'zh-cn': '宝盛银行',
  },
  {
    bankCode: 324,
    en: 'Credit Industriel et Commercial',
    'zh-hk': '法國工商銀行',
    'zh-cn': '法国工商银行',
  },
  {
    bankCode: 337,
    en: 'Taiwan Shin Kong Commercial Bank',
    'zh-hk': '臺灣新光商業銀行',
    'zh-cn': '台湾新光商业银行',
  },
  {
    bankCode: 338,
    en: 'Bank of China',
    'zh-hk': '中國銀行',
    'zh-cn': '中国银行',
  },
  {
    bankCode: 339,
    en: 'CA Indosuez (Switzerland)',
    'zh-hk': '東方匯理財富管理',
    'zh-cn': '东方汇理财富管理',
  },
  {
    bankCode: 342,
    en: 'LGT Bank',
    'zh-hk': '皇家銀行',
    'zh-cn': '皇家银行',
  },
  {
    bankCode: 345,
    en: 'Shanghai Pudong Development Bank',
    'zh-hk': '上海浦東發展銀行',
    'zh-cn': '上海浦东发展银行',
  },
  {
    bankCode: 353,
    en: 'China Minsheng Banking',
    'zh-hk': '中國民生銀行',
    'zh-cn': '中国民生银行',
  },
  {
    bankCode: 359,
    en: 'China Guangfa Bank',
    'zh-hk': '廣發銀行',
    'zh-cn': '广发银行',
  },
  {
    bankCode: 361,
    en: 'China Bohai Bank',
    'zh-hk': '渤海銀行',
    'zh-cn': '渤海银行',
  },
  {
    bankCode: 364,
    en: 'Banque Pictet & Cie SA',
    'zh-hk': '瑞士百達銀行',
    'zh-cn': '瑞士百达银行',
  },
  {
    bankCode: 365,
    en: 'Bank of Dongguan',
    'zh-hk': '東莞銀行',
    'zh-cn': '东莞银行',
  },
  {
    bankCode: 368,
    en: 'China Everbright Bank',
    'zh-hk': '中國光大銀行',
    'zh-cn': '中国光大银行',
  },
  {
    bankCode: 371,
    en: 'Sumitomo Mitsui Trust Bank',
    'zh-hk': '三井住友信託銀行',
    'zh-cn': '三井住友信托银行',
  },
  {
    bankCode: 374,
    en: 'CIMB Bank',
    'zh-hk': '聯昌銀行',
    'zh-cn': '联昌银行',
  },
  {
    bankCode: 376,
    en: 'NongHyup Bank',
    'zh-hk': '農協銀行',
    'zh-cn': '农协银行',
  },
  {
    bankCode: 377,
    en: 'Industrial Bank',
    'zh-hk': '興業銀行',
    'zh-cn': '兴业银行',
  },
  {
    bankCode: 378,
    en: 'Yuanta Commercial Bank',
    'zh-hk': '元大商業銀行',
    'zh-cn': '元大商业银行',
  },
  {
    bankCode: 379,
    en: 'Mashreq Bank',
    'zh-hk': '馬士禮格銀行',
    'zh-cn': '马士礼格银行',
  },
  {
    bankCode: 381,
    en: 'Kookmin Bank',
    'zh-hk': '國民銀行',
    'zh-cn': '国民银行',
  },
  {
    bankCode: 383,
    en: 'China Zheshang Bank',
    'zh-hk': '浙商銀行',
    'zh-cn': '浙商银行',
  },
  {
    bankCode: 385,
    en: 'Ping An Bank',
    'zh-hk': '平安銀行',
    'zh-cn': '平安银行',
  },
  {
    bankCode: 386,
    en: 'Hua Xia Bank',
    'zh-hk': '華夏銀行',
    'zh-cn': '华夏银行',
  },
  {
    bankCode: 394,
    en: 'Qatar National Bank',
    'zh-hk': '卡達國家銀行',
    'zh-cn': '卡达国家银行',
  },
]

export const findBankByCode = (bankCode) =>
  banks.find((b) => b.bankCode === parseInt(bankCode, 10))

export default banks
