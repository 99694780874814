import Divit from '@/assets/divit-black.svg'
import FPSByDivit from '@/assets/FPS_by_divit_black.png'
import AsiaMiles from '@/assets/miles/merchants/asiamiles.png'
import WingWing from '@/assets/miles/merchants/wingwing.png'
import Youair from '@/assets/miles/merchants/youair-logo.png'
import Youmall from '@/assets/miles/merchants/youmall.png'

export const MERCHANT_ICONS = {
  DIVIT: Divit,
  YOUAIR: Youair,
  ASIAMILES: AsiaMiles,
  FPS_BY_DIVIT: FPSByDivit,
  // merchants
  YOUMALL: Youmall,
  WINGWING: WingWing,
}

export const MERCHANT_CODES = {
  HK_AIRLINES: 'HX',
}

export const DIVIT_MARKETING = 'DIVITMARKET'
export const DIVIT_PROMOTION = 'DIVITPROMOT'

export default 0
