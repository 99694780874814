import moment from 'moment'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as CopySvg } from '@/assets/common/copy.svg'
import { ReactComponent as MinusSvg } from '@/assets/common/minus.svg'
import { ReactComponent as PlusSvg } from '@/assets/common/plus.svg'
import BarCode from '@/components/BarCode'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import GiftCardImage from '@/components/giftcard/GiftCardImage'
import GiftCardTNCModal from '@/components/GiftCardTNCModal'
import Icon from '@/components/Icon'
import InfinityScroll from '@/components/InfinityScroll'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import LoadingSpinner from '@/components/LoadingSpinner'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import { fetchGiftCardsNextPage } from '@/redux/profile/profileActions'
import {
  availableGiftCardsSelector,
  giftCardBrandsSelector,
  usedGiftCardsSelector,
} from '@/redux/profile/profileSelector'
import copy from '@/utils/copy'
import * as Pagination from '@/utils/pagination'
import { FormattedPrice } from '@/utils/Price'

import { ItemRow, Row } from './Shared'

const Container = styled(PaddingContent)`
  position: relative;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const CardList = styled.div`
  position: relative;
`

const CardItem = styled.div`
  width: 100%;
  margin-bottom: 1.333rem;
`

const CardContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0.889rem;
  border-radius: 8px;
  background-image: url(${(p) => p.image});
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: 0 4px 16px rgba(28, 28, 28, 0.2), 0 2px 4px rgba(28, 28, 28, 0.2);
`

const CardHeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardName = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const CardFaceValue = styled.div`
  font-size: 1.111rem;
  font-weight: 600;
`

const CardImageContainer = styled.div`
  position: relative;
  width: 7.222rem;
  height: auto;
`

const CardImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const CardStatusText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.778rem;
  font-weight: 700;
  color: white;
  background-color: rgba(194, 194, 194, 0.9);
  padding: 4px 0;
`

const ItemTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemValue = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const CardExpire = styled.div`
  font-size: 0.667rem;
`

const CardNumber = styled.div`
  font-size: 0.889rem;
`

const MarkAsUsedButton = styled(Button)`
  font-size: 0.889rem;
  background-color: #1c1c1c;
  color: white;

  &:disabled {
    background-color: #979696;
    color: #fefefe;
  }
`

const MarkAsUnuseButton = styled(Button)`
  font-size: 0.889rem;
  background-color: #fefefe;
  color: #1c1c1c;
  border: 1px solid #dedede;
`

const TNC = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const Anchor = styled.span`
  text-decoration: underline;
  color: #1c1c1c;
  cursor: pointer;
`

const CardImage = ({ image, isUsed, isExpired }) => {
  const intl = useIntl()

  const status = isUsed ? 'used' : isExpired ? 'expired' : ''

  return (
    <CardImageContainer>
      <GiftCardImage image={image} />
      {status !== '' && (
        <CardImageOverlay>
          {status === 'used' && (
            <CardStatusText>{tt(intl, 'giftcard.used')}</CardStatusText>
          )}
          {status === 'expired' && (
            <CardStatusText>{tt(intl, 'giftcard.expired')}</CardStatusText>
          )}
        </CardImageOverlay>
      )}
    </CardImageContainer>
  )
}

export const GiftCardDetails = ({
  card,
  isExpanded,
  onClickCopy,
  onClickExpand,
  onClickMarkAsUsed,
  onClickShowRedemption,
  onClickShowTNC,
}) => {
  const intl = useIntl()

  return (
    <CardContainer>
      <CardHeadContainer>
        <div>
          <CardName>{card.product.metadata.title}</CardName>
          <Spacer height="0.889rem" />
          <CardFaceValue>
            {FormattedPrice(card.faceValue.currency, card.faceValue.amount)}
          </CardFaceValue>
        </div>
        <Spacer width="1.333rem" />
        <div>
          <CardImage
            isUsed={card.markedUsed}
            isExpired={
              card.expiredAt > 0 &&
              moment.unix(card.expiredAt).isBefore(moment())
            }
            image={card.product.customImage}
          />
        </div>
      </CardHeadContainer>
      <Spacer height="0.889rem" />
      <ItemRow onClick={onClickExpand}>
        {card.expiredAt !== 0 ? (
          <CardExpire>
            {tt(intl, 'giftcard.expireson')}{' '}
            {moment.unix(card.expiredAt).format('LLL')}
          </CardExpire>
        ) : (
          <CardExpire>{tt(intl, 'giftcard.noexpirydate')}</CardExpire>
        )}
        <Icon
          renderImage={() => (isExpanded ? <MinusSvg /> : <PlusSvg />)}
          width="1.111rem"
          height="1.111rem"
        />
      </ItemRow>
      {isExpanded && (
        <>
          {false && (
            <ItemRow>
              <ItemTitle>shop location</ItemTitle>
              <ItemValue>online / in-store</ItemValue>
            </ItemRow>
          )}
          <Spacer height="0.444rem" />
          <Line />
          <Spacer height="0.889rem" />
          <ItemRow>
            <ItemTitle>{tt(intl, 'giftcard.cardnumber')}</ItemTitle>
          </ItemRow>
          <ItemRow>
            <CardNumber>{card.accountNumber}</CardNumber>
            <ItemValue onClick={onClickCopy}>
              <Row style={{ cursor: 'pointer' }}>
                <Icon
                  renderImage={() => <CopySvg />}
                  width="0.889rem"
                  height="0.889rem"
                />
                <Spacer width="8px" />
                <span>{tt(intl, 'common.copy')}</span>
              </Row>
            </ItemValue>
          </ItemRow>
          <Spacer height="0.889rem" />
          <BarCode value={card.accountNumber} />
          {card.securityCode && (
            <ItemTitle>
              <Spacer height="0.889rem" />
              <ItemRow>
                <ItemTitle>{tt(intl, 'giftcard.securitycode')}</ItemTitle>
              </ItemRow>
              <ItemRow>
                <CardNumber>{card.securityCode}</CardNumber>
                <ItemValue onClick={onClickCopy}>
                  <Row style={{ cursor: 'pointer' }}>
                    <Icon
                      renderImage={() => <CopySvg />}
                      width="0.889rem"
                      height="0.889rem"
                    />
                    <Spacer width="8px" />
                    <span>{tt(intl, 'common.copy')}</span>
                  </Row>
                </ItemValue>
              </ItemRow>
            </ItemTitle>
          )}
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          {!card.markedUsed && (
            <MarkAsUsedButton type="stretch" onClick={onClickMarkAsUsed}>
              {tt(intl, 'giftcard.markasspent')}
            </MarkAsUsedButton>
          )}
          {card.markedUsed && (
            <MarkAsUnuseButton type="stretch" onClick={onClickMarkAsUsed}>
              {tt(intl, 'giftcard.markasavailable')}
            </MarkAsUnuseButton>
          )}
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          <TNC>
            {tt(intl, 'giftcard.cardtnc', {
              here: (
                <Anchor onClick={onClickShowRedemption}>
                  {tt(intl, 'giftcard.cardtnc.here')}
                </Anchor>
              ),
              tnc: (
                <Anchor onClick={onClickShowTNC}>
                  {tt(intl, 'giftcard.cardtnc.tnc')}
                </Anchor>
              ),
            })}
          </TNC>
        </>
      )}
    </CardContainer>
  )
}

const ProfileGiftCardDetails = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { brandCode } = useParams()

  const isUsedPage = pathname.indexOf('used') >= 0

  const [expandedCardIds, setExpandedCardIds] = useState([])
  const [includedCardIds, setIncludedCardIds] = useState([])

  const [tncModalState, setTncModalState] = useState({
    isOpen: false,
    card: null,
    type: '',
  })

  const giftCardBrands = useSelector(giftCardBrandsSelector)
  const giftCardBrand = giftCardBrands.find((b) => b.tag === brandCode)
  const giftCards = useSelector(
    isUsedPage
      ? usedGiftCardsSelector(includedCardIds)
      : availableGiftCardsSelector(includedCardIds)
  )
  const giftCardsTotal = useSelector((s) => s.profile.giftCardsTotal)
  const hasMoreGiftCards = Pagination.hasMore(giftCards, giftCardsTotal)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: {
      type: 'pageInit/initProfileGiftCards',
      payload: {
        brandCode,
        active: !isUsedPage,
      },
    },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onLoadMore = () =>
    dispatch(fetchGiftCardsNextPage({ brandCode, active: !isUsedPage }))

  const onClickExpand = (card) => () => {
    if (expandedCardIds.indexOf(card.giftcardID) >= 0) {
      setExpandedCardIds((s) => s.filter((id) => id !== card.giftcardID))
    } else {
      setExpandedCardIds((s) => s.concat([card.giftcardID]))
    }
  }

  const onClickCopy = (card) => () => {
    const isSuccess = copy(card.accountNumber)
    if (isSuccess) {
      dispatch({
        type: 'toast/addToast',
        payload: {
          message: tt(intl, 'common.copied'),
        },
      })
    }
  }

  const onClickMarkAsUsed = (card) => () => {
    setIncludedCardIds((s) => s.concat([card.giftcardID]))
    dispatch({
      type: 'updateProfile/giftCardMarkAsUsed',
      payload: {
        cardId: card.giftcardID,
        markedUsed: !card.markedUsed,
      },
    })
  }

  const openTncModal = (card, type) => () => {
    setTncModalState({
      isOpen: true,
      card,
      type,
    })
  }

  const closeTncModal = () => {
    setTncModalState({
      isOpen: false,
      card: null,
      type: '',
    })
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Spacer height="2.222rem" />
          <Title onClickBack={onClickBack}>
            {isUsedPage
              ? tt(intl, 'giftcard.usedexpired')
              : giftCardBrand?.metadata.title}
          </Title>
          {!isUsedPage && giftCardBrand?.metadata.customerServiceWebsite && (
            <>
              <Spacer height="0.889rem" />
              <SubTitle>
                {tt(intl, 'giftcard.contact.question')}
                <br />
                <Anchor
                  as="a"
                  href={`mailto:${giftCardBrand?.metadata.customerServiceWebsite}`}
                >
                  {giftCardBrand?.metadata.customerServiceWebsite}
                </Anchor>
              </SubTitle>
            </>
          )}
          <Spacer height="1.778rem" />
          <CardList>
            {giftCards.map((card) => {
              const isExpanded = expandedCardIds.indexOf(card.giftcardID) >= 0
              return (
                <CardItem key={card.giftcardID}>
                  <GiftCardDetails
                    card={card}
                    isExpanded={isExpanded}
                    onClickCopy={onClickCopy(card)}
                    onClickExpand={onClickExpand(card)}
                    onClickMarkAsUsed={onClickMarkAsUsed(card)}
                    onClickShowRedemption={openTncModal(card, 'redemption')}
                    onClickShowTNC={openTncModal(card, 'tnc')}
                  />
                </CardItem>
              )
            })}
          </CardList>
          <Spacer height="1rem" />
          <InfinityScroll hasMore={hasMoreGiftCards} loadMore={onLoadMore}>
            <LoadingSpinner />
          </InfinityScroll>
          <Spacer height="4.444rem" />
        </Container>
      </CSLayout.CSContent>
      <GiftCardTNCModal
        isOpen={tncModalState.isOpen}
        type={tncModalState.type}
        product={tncModalState.card?.product}
        onClose={closeTncModal}
      />
    </CSLayout.CSContainer>
  )
}

export default ProfileGiftCardDetails
