import moment from 'moment'

import * as Instalments from './Instalments'
import * as math from './math'

export const getOrderAmount = (order) => {
  const { totalAmount, serviceFee } = order
  return {
    currency: totalAmount.currency,
    amount: totalAmount.amount + serviceFee.amount,
  }
}

export const getMilesBurned = (order, instalmentId) => {
  const { instalments } = order
  const insts = instalments.filter((i) => i.instalmentID === instalmentId)
  return insts?.[0].miles || 0
}

export const getTotalAmount = (order) => {
  const { instalments, totalAmount } = order
  const total = instalments.reduce((sum, instalment) => {
    sum += Instalments.getSubtotalAmount(instalment).amount
    return sum
  }, 0)
  return {
    currency: totalAmount.currency,
    amount: total,
  }
}

export const getTicketsEarned = (order, metadata) => {
  let tickets = order.metadata.ticketsEarned || 0
  if (tickets === 0) {
    if (metadata && metadata.length > 0) {
      tickets = metadata[0]?.metadata?.ticketEarned
    }
  }
  return tickets
}

export const getTotalServiceFee = (order) => {
  const { instalments, totalAmount } = order
  const total = instalments.reduce((sum, instalment) => {
    sum += Instalments.getTotalPaidServiceFee(instalment).amount
    return sum
  }, 0)
  return {
    currency: totalAmount.currency,
    amount: total,
  }
}

export const getTotalPaidAmount = (order) => {
  const { instalments, totalAmount } = order
  const total = instalments.reduce((sum, instalment) => {
    sum += Instalments.getTotalPaidAmount(instalment).amount
    return sum
  }, 0)
  return {
    currency: totalAmount.currency,
    amount: total,
  }
}

export const getTotalActualPaidAmount = (order) => {
  const { instalments, totalAmount } = order
  const total = instalments.reduce((sum, instalment) => {
    sum += Instalments.getTotalActualPaidAmount(instalment).amount
    return sum
  }, 0)
  return {
    currency: totalAmount.currency,
    amount: total,
  }
}

export const getTotalOutstandingAmount = (order) => {
  const { instalments, totalAmount } = order
  const total = instalments.reduce((sum, instalment) => {
    sum += Instalments.getTotalOutstandingAmount(instalment).amount
    return sum
  }, 0)
  return {
    currency: totalAmount.currency,
    amount: total,
  }
}

export const getTotalLateFee = (order) => {
  const { instalments } = order
  return Instalments.getTotalLateFee(instalments)
}

// order is overdue if it is active, and
// any instalment is unpaid and over due date
export const isOverdue = (order) => {
  const { instalments, status } = order
  if (status.toLowerCase() !== 'active') {
    return false
  }
  return instalments.find(
    (instalment) =>
      !Instalments.isPaid(instalment) &&
      moment().isAfter(moment.unix(instalment.dueDate))
  )
}

export const getOverdueOrder = (orders = []) =>
  orders.find((order) => isOverdue(order))

// payment 1 is not fully paid yet, might have partial paid
export const isNew = (order) => order.status.toLowerCase() === 'new'

// payment 1 is not paid yet
export const isNewCreated = (order) => {
  const { instalments } = order
  return (
    isNew(order) &&
    instalments[0].status.toLowerCase() === 'unpaid' &&
    !instalments[0].financialTx
  )
}

export const isActive = (order) => order.status.toLowerCase() === 'active'

// payment 1 processing
export const isProcessing = (order) => {
  const { instalments } = order
  const firstInstalment = instalments[0]

  const isFirstInstalmentProcessing =
    Instalments.getPaymentStatus(firstInstalment) === 'processing'
  const isFirstInstalmentUnderPay = Instalments.isUnderPay(firstInstalment)

  return (
    isNew(order) && (isFirstInstalmentProcessing || isFirstInstalmentUnderPay)
  )
}

export const getProcessingOrder = (orders = []) =>
  orders.find((order) => isProcessing(order))

// payment 1 expired
export const isExpired = (order) => order.status.toLowerCase() === 'expired'

// user clicked cancel purchase
export const isCancelled = (order) => order.status.toLowerCase() === 'cancelled'

// order is cancelled after user paid
export const isCancelledWithoutPaid = (order) => {
  if (!isCancelled(order)) {
    return false
  }
  const { instalments } = order
  if (!Instalments.getSuccessPayment(instalments[0])) {
    return true
  }
  return false
}

// all payments paid
export const isCompleted = (order) => order.status.toLowerCase() === 'complete'

// payment 1 failed
export const isFailed = (order) => {
  const { instalments } = order
  return (
    isNew(order) && Instalments.getPaymentStatus(instalments[0]) === 'failed'
  )
}

// order cannot continue
export const isEnd = (order) =>
  isExpired(order) || isCancelled(order) || isCompleted(order)

export const getNextPaymentOrder = (orders) =>
  orders.find((order) => {
    const { instalments } = order
    return isActive(order) && Instalments.getOutstandingInstalment(instalments)
  })

export const getLoanAmount = (order) => {
  const { totalAmount, serviceFee, cashAmount } = order
  const loanAmount = totalAmount.amount + serviceFee.amount - cashAmount.amount
  if (loanAmount < totalAmount.amount) {
    return { currency: totalAmount.currency, amount: loanAmount }
  }
  return totalAmount
}

export const getTransferServiceFee = (order) => {
  const { totalAmount, serviceFee, cashAmount } = order
  const loanAmount = math.minus(
    math.add(totalAmount.amount, serviceFee.amount),
    cashAmount.amount
  )
  if (loanAmount < totalAmount.amount) {
    const loanServiceFee = parseFloat(
      math.mul(serviceFee.amount, math.div(loanAmount, totalAmount.amount))
    ).toFixed(2)
    const transferServiceFee = math.minus(serviceFee.amount, loanServiceFee)
    return { currency: totalAmount.currency, amount: transferServiceFee }
  }
  return { currency: totalAmount.currency, amount: 0 }
}

// used in GA4 ecommerce general event
export const getGA4EcommercePayload = (order) => ({
  currency: order.totalAmount.currency,
  value: order.totalAmount.amount / 100,
  items: order.orderItems.map((d) => ({
    item_id: d.orderData.duration,
    item_name: d.orderType,
  })),
})

// used in GA4 ecommerce add_payment_info event
export const getGA4EcommercePaymentPayload = (order, amount, paymentType) => {
  const base = getGA4EcommercePayload(order)
  base.value = amount
  base.payment_type = paymentType
  return base
}

// get available payment method by order
export const getAvailablePaymentMethods = () => ['fps']

export const getPartnerRef = (order) => `#${order.partnerRef}`

const sortByCreatedAt = (orders) =>
  orders
    .slice()
    .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1))

export const didFirstPurchase = (orders) => {
  if (orders.length === 0) return false

  // check any completes
  const completes = orders.filter((o) => isCompleted(o))
  if (completes.length > 0) return true

  return false
}

export const getFirstUncompleteOrder = (orders) => {
  if (orders.length === 0) return undefined

  // check any completes
  const completes = orders.filter((o) => isCompleted(o))
  if (completes.length > 0) return undefined

  // get oldest active order
  const actives = orders.filter((o) => isActive(o))
  if (actives.length > 0) {
    return sortByCreatedAt(actives)[0]
  }

  return undefined
}

export const getTotalRefundAmount = (order) => {
  const { totalAmount, instalments } = order
  const amount = instalments.reduce((sum, instalment) => {
    sum += Math.abs(Instalments.getTotalRefundAmount(instalment).amount)
    return sum
  }, 0)
  return { currency: totalAmount.currency, amount }
}

export const getTotalActualRefundAmount = (order) => {
  const { totalAmount, instalments } = order
  const amount = instalments.reduce((sum, instalment) => {
    sum += Math.abs(Instalments.getTotalActualRefundAmount(instalment).amount)
    return sum
  }, 0)
  return { currency: totalAmount.currency, amount }
}

export const getTotalRefundedAmount = (order) => {
  const { totalAmount, instalments } = order
  const amount = instalments.reduce((sum, instalment) => {
    sum += Math.abs(Instalments.getTotalRefundedAmount(instalment).amount)
    return sum
  }, 0)
  return { currency: totalAmount.currency, amount }
}

export const getTotalCancelledAmount = (order) => {
  const { totalAmount, instalments } = order
  const amount = instalments.reduce((sum, instalment) => {
    sum += Math.abs(Instalments.getTotalCancelledAmount(instalment).amount)
    return sum
  }, 0)
  return { currency: totalAmount.currency, amount }
}

export const hasRequestedRefund = (order) => {
  const { instalments } = order
  const arr = instalments.filter((instalment) =>
    Instalments.hasRequestedRefund(instalment)
  )
  return arr.length > 0
}

export const hasCompleteRefund = (order) => {
  const { instalments } = order
  const arr = instalments.filter((instalment) =>
    Instalments.hasCompletedRefund(instalment)
  )
  return arr.length > 0
}

export const isRefund = (order) => {
  const { instalments } = order
  const arr = instalments.filter((instalment) =>
    Instalments.hasCancellation(instalment)
  )
  return arr.length > 0
}

export const isFullRefund = (order) => {
  const { totalAmount } = order
  const totalRefundAmount = getTotalRefundAmount(order).amount
  return totalRefundAmount >= totalAmount.amount
}

export const getUpdatedOrderAmount = (order) => {
  const { totalAmount } = order

  const orderAmount = getOrderAmount(order).amount
  const totalRefundAmount = getTotalRefundAmount(order).amount

  return {
    currency: totalAmount.currency,
    amount: orderAmount - totalRefundAmount,
  }
}

export const getGrandTotalAmount = (order) => {
  const { totalAmount } = order

  const orderAmount = getOrderAmount(order).amount
  const lateFee = getTotalLateFee(order).amount
  const totalRefundAmount = getTotalRefundAmount(order).amount

  return {
    currency: totalAmount.currency,
    amount: orderAmount + lateFee - totalRefundAmount,
  }
}

export const hasAnyOrderItem = (order) => {
  const { orderItems = [] } = order
  return (
    orderItems.length > 0 &&
    orderItems.filter((l) => !!l.orderData.productTitle || !!l.orderData.id)
      .length > 0
  )
}

export const getOrderItemType = (order) =>
  order.orderType || order.orderItems?.[0]?.orderType || ''

export const getAllActivePaymentAmount = (orders = []) => {
  const currency = orders[0]?.totalAmount.currency
  const amount = orders.reduce((sum, order) => {
    sum += getTotalOutstandingAmount(order).amount
    return sum
  }, 0)
  return { currency, amount }
}

export const getOutstandingOrders = (orders = []) =>
  orders.filter(
    (order) => isActive(order) && getTotalOutstandingAmount(order).amount > 0
  )

export const getOutstandingInstalments = (orders = []) => {
  const os = getOutstandingOrders(orders)
  return os.reduce((sum, order) => {
    const instalments = Instalments.getOutstandingInstalment(order.instalments)
    sum.push(instalments)
    return sum
  }, [])
}

export const getActiveCompletedOrders = (orders = []) =>
  orders.filter((order) => isCompleted(order) || isActive(order))

export const sortByInstalmentPaidDateTime = (orders = []) => {
  const instalments = orders.reduce((sum, o) => {
    // every instalment embedded order reference
    const all = o.instalments.map((i) => ({ ...i, orderRef: o }))
    sum = sum.concat(all)
    return sum
  }, [])
  const ret = instalments.filter((instalment) => Instalments.isPaid(instalment))
  // latest payment first
  ret.sort((a, b) => {
    const ap = Instalments.getSuccessPayment(a)
    const bp = Instalments.getSuccessPayment(b)
    return moment(ap.createdAt).isBefore(bp.createdAt) ? 1 : -1
  })
  return ret
}

export default null
