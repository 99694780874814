import moment from 'moment'

import * as Jwt from './Jwt'

let expireTimeoutId
export const save = (token, expires) => {
  const d = Jwt.decode(token)
  if (!expires) {
    expires = Jwt.getExpire(token)
    expires = moment(expires).toDate
  }
  localStorage.setItem('customerID', d.payload.sub)
  localStorage.setItem('role', d.payload.scope)
  localStorage.setItem('expire', expires)
  localStorage.setItem('last_login_ts', Date.now())

  if (expireTimeoutId) clearTimeout(expireTimeoutId)

  // 70 hours after the expire
  const timeout = moment(expires).unix() - moment().unix() + 259200
  expireTimeoutId = setTimeout(() => {
    checkExpire()
  }, timeout)
}

export const remove = () => {
  localStorage.removeItem('customerID')
  localStorage.removeItem('role')
  localStorage.removeItem('expire')
  localStorage.removeItem('last_login_ts')
  localStorage.removeItem('sumsub-access-token')
}
export const checkExpire = () => {
  const expire = localStorage.getItem('expire')
  if (expire) {
    if (moment().isAfter(moment(new Date(expire)).add(70, 'hours'))) remove()
  }
}

export const getCustomerID = () => {
  checkExpire()
  return localStorage.getItem('customerID')
}
export const getRole = () => {
  checkExpire()
  return localStorage.getItem('role')
}
export const getExpire = () => {
  checkExpire()
  return localStorage.getItem('expire')
}
export const setLastLogin = (lastLoginTs) => {
  localStorage.setItem('last_login_ts', lastLoginTs)
}
export const getLastLogin = () => {
  checkExpire()
  return localStorage.getItem('last_login_ts', Date.now())
}

export const isRoleAvailable = () => {
  const roles = getRole()
  if (roles) {
    const arr = Array.isArray(roles) ? roles : roles.split(',')
    return arr.indexOf('enduser') >= 0
  }
  return -1
}
