import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as ProfileActivateExpressCheckoutIcon } from '@/assets/profile/profile-activate-express-checkout-icon.svg'
import { ReactComponent as ActivatePaylaterIcon } from '@/assets/profile/profile-activite-paylater-icon.svg'
import { ReactComponent as ProfileLuckyDrawTicketIcon } from '@/assets/profile/profile-lucky-draw-icon.svg'
import { ReactComponent as ProfileReferralIcon } from '@/assets/profile/profile-referral-icon.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import ProfileAvailableSpend from '@/components/profile/ProfileAvailableSpend'
import ProfileMiles from '@/components/profile/ProfileMiles'
import ProfileSettingList from '@/components/profile/ProfileSettingList'
import Spacer from '@/components/Spacer'
import { useAuth } from '@/contexts/authContext'
import { Features, useFeature } from '@/hooks/useFeature'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import { tt } from '@/locales/format'
import PaynowOrderResumePanel from '@/pages/paynow/PaynowOrderResumePanel'
import { ProfileReferralShareModal } from '@/pages/profile/ProfileReferralShare'
import { promptCustomerServiceModal } from '@/redux/modal/csModalActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { payNowProcessOrderSelector } from '@/redux/order/orderSelector'
import {
  divitMilesSelector,
  eDDAInitedSelector,
  isFeatureEDDAEnabledSelector,
  isProfileCompletedSelector,
  profileSelector,
  rewardTicketSelector,
} from '@/redux/profile/profileSelector'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'

const Container = styled(PaddingContent)``

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
`

const ProfileTicketRow = styled.div`
  display: flex;
  align-items: center;
`

const ProfileReferralRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ProfileReferralContent = styled.div`
  margin-left: 0.889rem;
`

const ProfileReferralTitle = styled.div`
  font-size: 0.889rem;
  font-weight: 700;
`

const ProfileReferralDesc = styled.div`
  font-size: 0.667rem;
`

const Profile = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { logout } = useAuth()
  const { configs, featureEnabled } = useFeature()
  const [nextDraw, setNextDraw] = useState()

  const profile = useSelector(profileSelector)
  const divitMiles = useSelector(divitMilesSelector)
  const tickets = useSelector(rewardTicketSelector)
  const isProfileCompleted = useSelector(isProfileCompletedSelector)
  const paynowProcessOrders = useSelector(payNowProcessOrderSelector)
  const isEDDAEnabled = useSelector(isFeatureEDDAEnabledSelector)
  const isUserEDDAInited = useSelector(eDDAInitedSelector)
  const { cancelAllOrders } = useSelector((s) => s.paynow)

  const [isShowReferralModal, setIsShowReferralModal] = useState(false)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileMain' },
  })

  useUnmount(() => dispatch({ type: 'paynow/reset' }))

  useEffect(() => {
    if (cancelAllOrders.isSuccess) {
      dispatch(
        ToastActions.addToast({
          message: tt(intl, 'paynow.order.cancel.success'),
        })
      )
      retry()
    }
  }, [cancelAllOrders])

  useEffect(() => {
    if (
      !configs?.luckydraws ||
      (configs?.luckdrawStarts &&
        moment(configs?.luckdrawStarts).isAfter(moment()))
    )
      return
    configs.luckydraws.every((n) => {
      if (moment(n).isAfter(moment())) {
        setNextDraw(n)
        return false
      }
      return true
    })
  }, [configs])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!profile) return <></>

  const onClickEDDA = () => {
    if (profile.eddaStatus === 'active') {
      history.push('/edda/status')
    } else {
      history.push('/edda/setup')
    }
  }

  const onCancelPaynowOrders = async () => {
    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'paynow.order.allcancel.ask'),
        okText: tt(intl, 'paynow.order.allcancel.confirm'),
      })
    )
    if (isOk) {
      const orderIds = paynowProcessOrders.map((o) => o.orderID)
      dispatch({
        type: 'paynow/cancelAllOrders',
        payload: { orderIds },
      })
    }
  }

  const doLogout = async () => {
    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'login.logout.ask'),
        okText: tt(intl, 'login.logout.confirm'),
      })
    )
    if (isOk) {
      logout()
    }
  }

  const onClickSettingItem = (settingItem) => {
    if (settingItem.key === 'logout') {
      doLogout()
    } else if (settingItem.key === 'contact-us') {
      dispatch(promptCustomerServiceModal())
    } else if (settingItem.path) {
      history.push(settingItem.path)
    }
  }

  const { spend } = profile
  const { availableSpend, spentAmount } = spend || {}
  const totalSpend = (availableSpend?.amount || 0) + (spentAmount?.amount || 0)

  return (
    <Container>
      {paynowProcessOrders.length > 0 && (
        <>
          <Spacer height="1.333rem" />
          <PaynowOrderResumePanel
            orders={paynowProcessOrders}
            onCancel={onCancelPaynowOrders}
          />
        </>
      )}
      <Spacer height="2.222rem" />
      <Title>{tt(intl, 'profile.my')}</Title>
      <Spacer height="1.778rem" />
      <ProfileMiles
        totalMilesBalance={divitMiles?.balance}
        divitMilesBalance={divitMiles?.convertibleBalance}
        convertedMilesBalance={divitMiles?.convertedBalance}
      />
      {(isProfileCompleted || availableSpend?.amount > 0) && (
        <>
          <Spacer height="1.778rem" />
          <ProfileAvailableSpend
            availableSpend={{
              currency: availableSpend?.currency,
              amount: availableSpend?.amount,
            }}
            totalAvailableSpend={{
              currency: availableSpend?.currency,
              amount: totalSpend,
            }}
          />
        </>
      )}
      {featureEnabled(Features.LUCKYDRAW) && nextDraw && (
        <>
          <Spacer height="1.778rem" />
          <ProfileTicketRow>
            <Icon renderImage={() => <ProfileLuckyDrawTicketIcon />} />
            <ProfileReferralContent>
              <ProfileReferralTitle>
                {tt(intl, 'reward.draw.ticket.number', {
                  ticket: <>{tickets.tickets}</>,
                })}
              </ProfileReferralTitle>
              <Spacer height="0.222rem" />
              <ProfileReferralDesc>
                {tt(intl, 'reward.draw.ticket.subtitle')}
              </ProfileReferralDesc>
            </ProfileReferralContent>
          </ProfileTicketRow>
        </>
      )}
      {featureEnabled(Features.REFERRAL) && (
        <>
          <Spacer height="0.889rem" />
          <ProfileReferralRow onClick={() => setIsShowReferralModal(true)}>
            <Icon renderImage={() => <ProfileReferralIcon />} />
            <ProfileReferralContent>
              <ProfileReferralTitle>
                {tt(intl, 'referral.refermore.title')}
              </ProfileReferralTitle>
              <Spacer height="0.222rem" />
              <ProfileReferralDesc>
                {tt(intl, 'referral.refermore.desc.ticket')}
              </ProfileReferralDesc>
            </ProfileReferralContent>
          </ProfileReferralRow>
        </>
      )}
      {false && !isProfileCompleted && (
        <>
          <Spacer height="0.889rem" />
          <ProfileReferralRow onClick={() => history.push('/enable/paylater')}>
            <Icon renderImage={() => <ActivatePaylaterIcon />} />
            <ProfileReferralContent>
              <ProfileReferralTitle>
                {tt(intl, 'paylater.activate.title')}
              </ProfileReferralTitle>
              <Spacer height="0.222rem" />
              <ProfileReferralDesc>
                {tt(intl, 'paylater.activate.desc')}
              </ProfileReferralDesc>
            </ProfileReferralContent>
          </ProfileReferralRow>
        </>
      )}
      {isEDDAEnabled && (
        <>
          <Spacer height="0.889rem" />
          <ProfileReferralRow onClick={onClickEDDA}>
            <Icon renderImage={() => <ProfileActivateExpressCheckoutIcon />} />
            <ProfileReferralContent>
              <ProfileReferralTitle>
                {isUserEDDAInited
                  ? tt(intl, 'edda.yourcheckout')
                  : tt(intl, 'edda.activate')}
              </ProfileReferralTitle>
              <Spacer height="0.222rem" />
              <ProfileReferralDesc>
                {isUserEDDAInited
                  ? tt(intl, 'edda.subtitle')
                  : tt(intl, 'edda.activate.why')}
              </ProfileReferralDesc>
            </ProfileReferralContent>
          </ProfileReferralRow>
        </>
      )}
      <Spacer height="0.889rem" />
      <ProfileSettingList onClickItem={onClickSettingItem} />
      <Spacer height="4rem" />
      <ProfileReferralShareModal
        isOpen={isShowReferralModal}
        onClose={() => setIsShowReferralModal(false)}
      />
    </Container>
  )
}

export default Profile
