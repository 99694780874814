import PropTypes from 'prop-types'
import React from 'react'
import AppleLogin from 'react-apple-login'

const appleClientId = process.env.REACT_APP_APPLE_APP_ID

const LoginApple = ({ render, onSuccess, onFailure }) => {
  const onResponse = (resp) => {
    if (!resp || !resp.authorization) {
      onFailure(resp)
      return
    }
    /*
    {
     "authorization": {
       "code": "[CODE]",
       "id_token": "[ID_TOKEN]",
       "state": "[STATE]"
     },
     "user": {
       "email": "[EMAIL]",
       "name": {
         "firstName": "[FIRST_NAME]",
         "lastName": "[LAST_NAME]"
       }
     }
    }
    */
    // console.log('grapped apple resp object', resp, resp.authorization)
    onSuccess({ ...resp, accessToken: resp.authorization.code })
  }

  return (
    <AppleLogin
      clientId={appleClientId}
      scope="name email"
      callback={onResponse}
      redirectURI={`${document.location.origin}${document.location.pathname}`}
      render={({ onClick }) => render({ onClick })}
      usePopup
    />
  )
}

LoginApple.propTypes = {
  render: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
}

export default LoginApple
