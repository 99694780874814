import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DivitLogoSvg } from '@/assets/divit.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
// import MilesToEarnBlockShort from '@/components/paynow/MilesToEarnBlockShort'
import Spacer from '@/components/Spacer'
import {
  ActionButtons,
  PrimaryButton,
  SecondaryButton,
} from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import { profileShippingAddressOptionsSelector } from '@/redux/profile/profileSelector'
import { joinAddress } from '@/utils/Address'
import { parsePhoneNumber } from '@/utils/Phone'
import { FormattedPrice } from '@/utils/Price'

const Container = styled(PaddingContent)``

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SectionTitleText = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`

const ItemAddress = styled.div``

const ItemText = styled.div`
  font-size: 0.889rem;
`

const ItemTextButton = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
  text-decoration: underline;
  cursor: pointer;
`

const ItemEditButton = ({ exist, onClick }) => {
  const intl = useIntl()
  return (
    <ItemTextButton onClick={onClick}>
      {exist ? tt(intl, 'common.edit') : tt(intl, 'common.add')}
    </ItemTextButton>
  )
}

const PaymentMethod = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PaymentMethodLogo = styled.div`
  display: flex;
  align-items: flex-end;
`

const PaymentMethodLogoText = styled.div`
  font-size: 0.667rem;
  font-weight: 700;
  margin-left: 0.333rem;
`

const OrderAmount = styled.div`
  font-size: 0.889rem;
`

const PaynowExpressCheckout = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderId } = useParams()

  const { linkOrder } = useSelector((s) => s.paynow)
  const { order } = useSelector((s) => s.order)
  const shippingAddresses = useSelector(profileShippingAddressOptionsSelector)
  const { updateDeliveryAddress } = useSelector((s) => s.paynow)

  const [inited, setInited] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState(0)

  const { retry, isInitSuccess, isInitError, isInitLoading } = usePage({
    skipInit: true,
    initAction: { type: 'pageInit/initPaynowOrder', payload: { orderId } },
  })

  // link order only once
  useEffect(() => {
    const isTransferedOrder =
      sessionStorage.getItem('transfered_order') === orderId
    if (!isTransferedOrder) {
      dispatch({ type: 'paynow/linkOrder', payload: { orderId } })
    } else {
      sessionStorage.setItem('transfered_order', orderId)
      setInited(true)
      retry()
    }
    return () => {
      dispatch({ type: 'paynow/reset' })
    }
  }, [])

  // select the selected one or default one
  useUpdateEffect(() => {
    const addressId = sessionStorage.getItem('order_shipping_address_id')
    if (addressId) {
      setSelectedAddressId(Number(addressId))
      return
    }
    const defaultAddress = shippingAddresses.find((s) => s.isDefault)
    if (defaultAddress) {
      setSelectedAddressId(defaultAddress.ID)
    }
  }, [isInitSuccess])

  // then get other details after link order
  useUpdateEffect(async () => {
    if (linkOrder.isSuccess) {
      // maybe transfered from fast paynow
      sessionStorage.setItem('transfered_order', orderId)

      // initialize
      setInited(true)
      retry()
    }
  }, [linkOrder])

  useUpdateEffect(() => {
    if (updateDeliveryAddress.isSuccess) {
      history.push(`/paynow/${orderId}`)
    }
  }, [updateDeliveryAddress])

  if (linkOrder.isLoading) return <LoadingScreen />

  if (linkOrder.isError)
    return (
      <Container>
        <ErrorMessageBox
          errorMessage={intl.formatMessage({ id: 'error.action.link.order' })}
        />
      </Container>
    )

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!inited) return <LoadingScreen />
  if (!order) return <LoadingScreen />
  if (order.orderID !== orderId) return <></>

  const onClickEditAddress = () => {
    history.push(`/paynow/${orderId}/address`, { selectedAddressId })
  }

  const onClickPlaceOrder = () => {
    const deliveryAddress = shippingAddresses.find(
      (a) => a.ID === selectedAddressId
    )

    dispatch({
      type: 'paynow/updateDeliveryAddress',
      payload: { orderId, deliveryAddress },
    })
  }

  const onClickBack = () => {
    history.push(`/paynow/${orderId}`)
  }

  const { orderAmount } = order
  const hasAnyAddress = shippingAddresses.length > 0
  const address = shippingAddresses.find((a) => a.ID === selectedAddressId)

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title>{tt(intl, 'paynow.revieworder')}</Title>
      <Spacer height="0.889rem" />
      <SubTitle>{tt(intl, 'paynow.revieworder.subtitle')}</SubTitle>
      <Spacer height="2.667rem" />
      <SectionTitle>
        <SectionTitleText>
          {tt(intl, 'profile.shippingaddress')}
        </SectionTitleText>
        <ItemEditButton exist={hasAnyAddress} onClick={onClickEditAddress} />
      </SectionTitle>
      {address && (
        <>
          <ItemAddress>
            <Spacer height="0.889rem" />
            {(address.contactFirstname || address.contactLastname) && (
              <>
                <ItemText>
                  {address.contactFirstname || ''}{' '}
                  {address.contactLastname || ''}
                </ItemText>
                <Spacer height="0.889rem" />
              </>
            )}
            <ItemText>
              {joinAddress([
                address.address1,
                address.address2,
                address.district,
                address.region,
              ])}
            </ItemText>
            {address.contactNumber && (
              <>
                <Spacer height="0.889rem" />
                <ItemText>
                  {tt(intl, 'common.phone')}:{' '}
                  {parsePhoneNumber(address.contactNumber).formatted}
                </ItemText>
              </>
            )}
          </ItemAddress>
        </>
      )}
      <Spacer height="1.333rem" />
      <Line />
      <Spacer height="1.333rem" />
      <SectionTitle>
        <SectionTitleText>{tt(intl, 'paynow.paymentmethod')}</SectionTitleText>
      </SectionTitle>
      <Spacer height="1.333rem" />
      <PaymentMethod>
        <PaymentMethodLogo>
          <Icon
            renderImage={() => <DivitLogoSvg />}
            width="3.722rem"
            height="1.333rem"
          />
          <PaymentMethodLogoText>
            {tt(intl, 'payment.paynow')}
          </PaymentMethodLogoText>
        </PaymentMethodLogo>
        <OrderAmount>
          {FormattedPrice(orderAmount.currency, orderAmount.amount)}
        </OrderAmount>
      </PaymentMethod>
      {/* <Spacer height="1.333rem" />
      <MilesToEarnBlockShort
        programKey="asiamiles"
        miles={milesToEarn}
        fontSize="0.778rem"
      /> */}
      <Spacer height="0.889rem" />
      <ErrorMessageLine errorMessage={updateDeliveryAddress.error?.message} />
      <Spacer height="0.889rem" />
      <ActionButtons>
        <SecondaryButton onClick={onClickBack}>
          {tt(intl, 'common.cancel')}
        </SecondaryButton>
        <PrimaryButton onClick={onClickPlaceOrder}>
          {tt(intl, 'paynow.placeorder')}
        </PrimaryButton>
      </ActionButtons>
      <Spacer height="2.222rem" />
      <LoadingModal loading={updateDeliveryAddress.isLoading} />
    </Container>
  )
}

export default PaynowExpressCheckout
