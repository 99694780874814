import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as AlertSvg } from '@/assets/paylater/alert.svg'
import { ReactComponent as AddressSvg } from '@/assets/paylater/profile/address.svg'
import { ReactComponent as EmailSvg } from '@/assets/paylater/profile/email.svg'
import { ReactComponent as HKIDSvg } from '@/assets/paylater/profile/hkid.svg'
import { ReactComponent as PhoneSvg } from '@/assets/paylater/profile/phone.svg'
import { ReactComponent as TickSvg } from '@/assets/paylater/profile/tick.svg'
import { ReactComponent as WarnSvg } from '@/assets/paylater/warn.svg'
import { Button } from '@/components/Divit'
import EnablePayLaterModal from '@/components/EnablePayLaterModal'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { ProfileAddressModal } from '@/pages/profile/ProfileAddress'
import { ProfileEditEmailModal } from '@/pages/profile/ProfileEditEmail'
import { ProfileEditMobileModal } from '@/pages/profile/ProfileEditMobile'
import { profileSelector } from '@/redux/profile/profileSelector'
import {
  canBypassKyc,
  getLatestKycType,
  isFobiddenKyc,
  isResubmitRequestedKyc,
} from '@/utils/kyc'

const Container = styled.div``

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 0.889rem;
`

const CompleteText = styled.div`
  font-size: 0.66rem;
`

const List = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0.55rem 0;
  overflow: auto;
`

const ListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 30%;
  padding: 0.88rem 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  padding: 1.33rem 1.11rem;
  text-align: center;
  border: 1px solid rgba(28, 28, 28, 0.1);
  border-radius: 8px;
  box-shadow: 0px 2px 12px #eee;
  margin-right: 10px;
  background-color: white;
`

const AlertIcon = styled(Icon)`
  position: absolute;
  top: 0.55rem;
  right: 0.55rem;
`

const IconWrapper = styled.div`
  position: relative;

  & .tick {
    position: absolute;
    right: -0.44rem;
    bottom: 0;
  }
`

const MerchantName = styled.div`
  font-size: 0.66rem;
`

const Separator = styled.div`
  width: 0.77rem;
  max-width: 0.77rem;
  height: 2px;
  background-color: rgba(28, 28, 28, 0.1);
`

const ActionButton = styled(Button)`
  font-size: 0.6rem;
  background-color: ${({ isCompleted }) => (isCompleted ? 'white' : '#1c1c1c')};
  color: ${({ isCompleted }) => (isCompleted ? '#1c1c1c' : 'white')};
  border: ${({ isCompleted }) =>
    isCompleted ? '1px solid rgba(28, 28, 28, 0.2)' : 'none'};
  font-weight: 700;
  padding-top: 0.33rem;
  padding-bottom: 0.33rem;
`

const steps = [
  {
    id: 'hkid',
    Icon: HKIDSvg,
    content: 'profile.addyourhkid',
    buttonText: 'common.add',
    completedButtonText: 'common.edit',
    path: '/profile/kyc',
    isCompletedFn: (profile) =>
      canBypassKyc(profile.kyc, getLatestKycType(profile.kyc)),
    isErrorFn: (profile) =>
      isFobiddenKyc(profile.kyc) || isResubmitRequestedKyc(profile.kyc),
  },
  {
    id: 'address',
    Icon: AddressSvg,
    content: 'profile.addyourhkaddress',
    buttonText: 'common.add',
    completedButtonText: 'common.edit',
    isCompletedFn: (profile) =>
      profile.firstName &&
      profile.lastName &&
      profile.nationalID &&
      profile.DOB,
    isErrorFn: () => false,
  },
  {
    id: 'email',
    Icon: EmailSvg,
    content: 'profile.verifyyouremail',
    buttonText: 'common.add',
    completedButtonText: 'common.edit',
    isCompletedFn: (profile) => profile.email_validated,
    isErrorFn: () => false,
  },
  {
    id: 'phone',
    Icon: PhoneSvg,
    content: 'profile.verifyyourphonenumber',
    buttonText: 'common.add',
    completedButtonText: 'common.edit',
    isCompletedFn: (profile) => profile.tel_validated,
    isErrorFn: () => false,
  },
]

const PaylaterProfileCompletionList = () => {
  const intl = useIntl()
  const history = useHistory()

  const [isShowGuide, setIsShowGuide] = useState(false)
  const [isShowEditEmailModal, setIsShowEditEmailModal] = useState(false)
  const [isShowEditMobileModal, setIsShowEditMobileModal] = useState(false)
  const [isShowAddressModal, setIsShowAddressModal] = useState(false)

  const profile = useSelector(profileSelector)

  const onClickEdit = (step) => {
    if (step.id === 'address') {
      setIsShowAddressModal(true)
    } else if (step.id === 'email') {
      setIsShowEditEmailModal(true)
    } else if (step.id === 'phone') {
      setIsShowEditMobileModal(true)
    } else if (step.path) {
      history.push(step.path)
    }
  }

  const stepsWithStatus = steps.map((s) => {
    s.isCompleted = s.isCompletedFn(profile)
    s.isError = s.isErrorFn(profile)
    return s
  })
  // sort by incomplete first
  stepsWithStatus.sort((a, b) => (!a.isCompleted && b.isCompleted ? -1 : 1))
  const completedSteps = stepsWithStatus.filter((s) => s.isCompleted).length

  return (
    <Container>
      <Row onClick={() => setIsShowGuide(true)}>
        <Title>{tt(intl, 'paylater.enableservice')}</Title>
        <Spacer width="8px" />
        <Icon
          renderImage={() => <WarnSvg />}
          width="0.88rem"
          height="0.88rem"
        />
      </Row>
      <Spacer height="0.88rem" />
      <CompleteText>
        <b>
          {completedSteps} of {steps.length}
        </b>{' '}
        {tt(intl, 'common.completed')}
      </CompleteText>
      <Spacer height="0.88rem" />
      <List>
        {stepsWithStatus.map((step) => {
          const { isCompleted, isError } = step
          return (
            <ListItem key={step.id}>
              {isError && (
                <AlertIcon
                  renderImage={() => <AlertSvg />}
                  width="0.88rem"
                  height="0.88rem"
                />
              )}
              <IconWrapper isCompleted={isCompleted}>
                <Icon
                  renderImage={() => <step.Icon />}
                  width="2.22rem"
                  height="2.22rem"
                />
                {isCompleted && (
                  <Icon
                    className="tick"
                    renderImage={() => <TickSvg />}
                    width="0.88rem"
                    height="0.88rem"
                  />
                )}
              </IconWrapper>
              <Spacer height="8px" />
              <MerchantName>{tt(intl, step.content)}</MerchantName>
              <Spacer height="0.55rem" />
              <Separator />
              <Spacer height="0.55rem" />
              <ActionButton
                isCompleted={isCompleted}
                onClick={() => onClickEdit(step)}
              >
                {isCompleted
                  ? tt(intl, step.completedButtonText)
                  : tt(intl, step.buttonText)}
              </ActionButton>
            </ListItem>
          )
        })}
      </List>
      <EnablePayLaterModal
        isOpen={isShowGuide}
        onClose={() => setIsShowGuide(false)}
      />
      <ProfileEditEmailModal
        isOpen={isShowEditEmailModal}
        onClose={() => setIsShowEditEmailModal(false)}
      />
      <ProfileEditMobileModal
        isOpen={isShowEditMobileModal}
        onClose={() => setIsShowEditMobileModal(false)}
      />
      <ProfileAddressModal
        isOpen={isShowAddressModal}
        onClose={() => setIsShowAddressModal(false)}
      />
    </Container>
  )
}

export default PaylaterProfileCompletionList
