import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorScreen from '@/components/error/ErrorScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import NormalMessageBox from '@/components/NormalMessageBox'
import Spacer from '@/components/Spacer'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import PaymentSuccess from '@/pages/PaymentSuccess'
import * as Instalments from '@/utils/Instalments'
import { IsPaylatering } from '@/utils/Route'

const Container = styled(PaddingContent)`
  background: #fff;
`

// render according to instalment status
const PaymentStatus = () => {
  const intl = useIntl()
  const history = useHistory()
  const { pathname } = useLocation()
  const isPaylatering = IsPaylatering(pathname)
  const { orderId, instalmentId } = useParams()
  const dispatch = useDispatch()

  const { order } = useSelector((state) => state.order)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: {
      type: 'pageInit/initPaymentFinished',
      payload: { orderId, instalmentId },
    },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  if (order.orderid !== orderId) return <></>

  const orderStatus = order.status.toLowerCase()

  const instalment = order.instalments.find(
    (item) => item.instalmentID === instalmentId
  )
  const status = Instalments.getPaymentStatus(instalment)
  const isUnderPay =
    Instalments.isUnderPay(instalment) ||
    Instalments.getTotalOutstandingAmount(instalment).amount > 0

  const refreshPage = (e) => {
    e.preventDefault()

    history.go(0)
  }

  const goPaymentFailure = () => {
    if (isPaylatering) {
      window.location.href = order.webhook_failure
    } else {
      dispatch({ type: 'redirect/scheduleReview', payload: { order } })
    }
  }

  const retryPayment = () => {
    if (isPaylatering) {
      history.push(`/pay/fps/paylater/${orderId}/${instalmentId}`)
    } else {
      history.push(`/pay/fps/paynow/${orderId}/${instalmentId}`)
    }
  }

  if (status === 'completed') {
    return (
      <Container>
        <PaymentSuccess isEmbedded />
      </Container>
    )
  }
  if (status === 'failed') {
    return (
      <ErrorScreen
        title={tt(intl, 'payment.failed')}
        content={intl.formatMessage({ id: 'payment.notprocess' })}
        onOk={retryPayment}
        okText={intl.formatMessage({ id: 'button.retry' })}
      />
    )
  }
  if (isUnderPay) {
    if (isPaylatering) {
      return <Redirect to={`/pay/fps/paylater/${orderId}/${instalmentId}`} />
    }
    return <Redirect to={`/pay/fps/paynow/${orderId}/${instalmentId}`} />
  }
  if (status === 'processing') {
    return (
      <Container>
        <Spacer height="2.222rem" />
        <NormalMessageBox
          buttonText={intl.formatMessage({ id: 'button.continue' })}
          onClick={refreshPage}
        >
          {intl.formatMessage({ id: 'warning.order.processing' })}
        </NormalMessageBox>
      </Container>
    )
  }
  if (orderStatus === 'cancelled' || orderStatus === 'expired') {
    return <Redirect to={`/payment/check/${orderId}/${instalmentId}`} />
  }
  return (
    <Container>
      <Spacer height="2.222rem" />
      <NormalMessageBox
        buttonText={intl.formatMessage({ id: 'button.retry' })}
        onClick={goPaymentFailure}
      >
        {intl.formatMessage({ id: 'error.order.status.invalid' })}
      </NormalMessageBox>
    </Container>
  )
}

export default PaymentStatus
