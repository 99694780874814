import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as payWithPaymeActions } from './payWithPaymeSlice'

// example:
// const callbackUri = `${window.location.origin}/payment/status/${orderId}/${instalmentId}`

export function* generatePaymeLink({ payload }) {
  try {
    yield put(payWithPaymeActions.generatePaymeLinkStart())
    const { orderId, instalmentId, callbackUri } = payload
    const { data } = yield call(() =>
      DivitAPI.post('payments', {
        order_id: orderId,
        instalment_id: instalmentId,
        instrumentCode: 'payme',
      })
    )
    const appLink = `https://${
      data.data.gateway_response.appLink.split('https://')[1]
    }${callbackUri}`
    yield put(payWithPaymeActions.generatePaymeLinkSuccess({ appLink }))
  } catch (e) {
    yield put(payWithPaymeActions.generatePaymeLinkFailed(getError(e)))
  }
}

export function* testGeneratePaymeLink({ payload }) {
  try {
    yield put(payWithPaymeActions.generatePaymeLinkStart())
    const { orderId, instalmentId, callbackUri, amount } = payload
    const { data } = yield call(() =>
      DivitAPI.post('payments/amount', {
        order_id: orderId,
        instalment_id: instalmentId,
        instrumentCode: 'payme',
        amount,
      })
    )
    const appLink = `https://${
      data.data.gateway_response.appLink.split('https://')[1]
    }${callbackUri}`
    yield put(payWithPaymeActions.generatePaymeLinkSuccess({ appLink }))
  } catch (e) {
    yield put(payWithPaymeActions.generatePaymeLinkFailed(getError(e)))
  }
}

export default null
