import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { ActionButtons, MainButton } from '@/pages/profile/Shared'
import { eDDAAccountSelector } from '@/redux/profile/profileSelector'
import * as BankUtils from '@/utils/Bank'

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
  text-align: center;
`

const Content = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const ItemTitle = styled.div`
  max-width: 40%;
  font-size: 0.778rem;
  font-weight: 600;
`

const ItemValue = styled.div`
  max-width: 60%;
  font-size: 0.778rem;
  font-weight: 700;
  text-align: right;
`

const EDDAConfirmation = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()

  const eDDAAccount = useSelector(eDDAAccountSelector)

  const { retry, isInitSuccess, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileEDDA' },
  })

  useEffect(() => {
    if (isInitSuccess) {
      // server might have delay for mircoservices
      const timer = setTimeout(() => {
        dispatch({ type: 'fetchEDDAAccounts' })
      }, 2 * 1000)
      return () => clearTimeout(timer)
    }
    return () => {}
  }, [isInitSuccess])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const goStatus = () => {
    history.push('/edda/status')
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Icon
            renderImage={() => <SuccessOutlinedSvg />}
            width="1.736rem"
            height="1.736rem"
          />
          <Spacer height="0.889rem" />
          <Title>{tt(intl, 'edda.requested')}</Title>
          <Spacer height="2.222rem" />
          <Content>{tt(intl, 'edda.requested.sent')}</Content>
          <Spacer height="1.333rem" />
          <Content>{tt(intl, 'edda.requested.receiveinfo')}</Content>
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          {eDDAAccount && (
            <>
              <Row>
                <ItemTitle>{tt(intl, 'common.accountholdername')}</ItemTitle>
                <ItemValue>{eDDAAccount.holderName}</ItemValue>
              </Row>
              <Spacer height="0.889rem" />
              <Row>
                <ItemTitle>{tt(intl, 'common.bank')}</ItemTitle>
                <ItemValue>
                  {BankUtils.getBankString(eDDAAccount.bankCode, currentLocale)}
                </ItemValue>
              </Row>
              <Spacer height="0.889rem" />
              <Row>
                <ItemTitle>{tt(intl, 'common.accountno')}</ItemTitle>
                <ItemValue>{eDDAAccount.accountNum}</ItemValue>
              </Row>
            </>
          )}
          <Spacer height="2.222rem" />
        </PaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <PaddingContent>
          <ActionButtons>
            <MainButton onClick={goStatus}>{tt(intl, 'common.ok')}</MainButton>
          </ActionButtons>
        </PaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default EDDAConfirmation
