import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import ScheduleReviewGiftCard from '@/components/ScheduleReviewGiftCard'
import ScheduleReviewMilesConversion from '@/components/ScheduleReviewMilesConversion'
import ScheduleReviewPaylater from '@/components/ScheduleReviewPaylater'
import ScheduleReviewPaynow from '@/components/ScheduleReviewPaynow'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import usePage from '@/hooks/usePage'
import useUnmount from '@/hooks/useUnmount'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'

const Container = styled(PaddingContent)`
  background-color: #fff;
`

const PaymentDetails = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { transactionId } = useParams()
  const history = useHistory()

  const { state } = useLocation()

  const { payment, order, orderMiles } = useSelector((s) => s.order)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: {
      type: 'pageInit/initPaymentDetails',
      payload: { transactionId },
    },
  })

  useUnmount(() => dispatch({ type: 'order/clearPayment' }))

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const { transactionID } = payment
  if (transactionId !== transactionID) return <></>

  const onClickBack = () => {
    if (state) {
      history.push('/history', {
        stateSize: state?.stateSize,
        transactionID,
        yOffset: state?.yOffset,
      })
    } else {
      dispatch(goBack())
    }
  }

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title onClickBack={onClickBack}>{tt(intl, 'payment.details')}</Title>
      <Spacer height="1.778rem" />
      {payment.refType === 'paynow-order' && (
        <ScheduleReviewPaynow order={order} orderMiles={orderMiles} />
      )}
      {payment.refType === 'paylater-order' && (
        <ScheduleReviewPaylater order={order} orderMiles={orderMiles} />
      )}
      {payment.refType === 'giftcard-order' && (
        <ScheduleReviewGiftCard payment={payment} />
      )}
      {payment.refType === 'miles-conversion' && (
        <ScheduleReviewMilesConversion payment={payment} />
      )}
      <Spacer height="2rem" />
    </Container>
  )
}

export default PaymentDetails
