export const getBrandImageUrl = (brandCode) =>
  `${process.env.REACT_APP_STATIC_URL}/giftcards/logo-${brandCode}.png`

export const getGiftCardImageUrl = (brandCode) =>
  `${process.env.REACT_APP_STATIC_URL}/giftcards/card-${brandCode}.jpg`

export const calcGiftCardMiles = (fiatValue = 0, buyRate = 1) =>
  Math.ceil(fiatValue / buyRate)

export const groupProductsByBrand = (products = []) => {
  const groups = products.reduce((obj, p) => {
    if (!obj[p.brandCode]) {
      obj[p.brandCode] = []
    }
    obj[p.brandCode].push(p)
    return obj
  }, {})
  return groups
}

export default 0
