import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
// import LotteryBanner from '@/components/lottery/LotteryBanner'
import BrandGrid from '@/components/shop/BrandGrid'
import ProductCategoryGrid from '@/components/shop/ProductCategoryGrid'
import Spacer from '@/components/Spacer'
import { Anchor } from '@/components/ui/Shared'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'

const Container = styled.div``

const Title = styled.div`
  font-size: 1.333rem;
  font-weight: 700;
`

const SubTitle = styled.div`
  font-size: 1.111rem;
  font-weight: 700;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ExtraAnchor = styled(Anchor)`
  font-size: 0.778rem;
  font-weight: 500;
`

const CategoriesContainer = styled.div``

const CategorySection = ({ categories }) => {
  const intl = useIntl()
  const history = useHistory()

  const viewAll = () => {
    history.push(`/shop/categories`)
  }

  const viewCat = (cat) => {
    history.push(`/shop/categories`, {
      defaultTab: cat.id,
    })
  }

  return (
    <CategoriesContainer>
      <TitleRow>
        <SubTitle>{tt(intl, 'shop.shopbycategory')}</SubTitle>
        {categories.length > 0 && (
          <ExtraAnchor onClick={viewAll}>
            {tt(intl, 'common.viewall')}
          </ExtraAnchor>
        )}
      </TitleRow>
      <Spacer height="0.889rem" />
      <ProductCategoryGrid categories={categories} onClickItem={viewCat} />
    </CategoriesContainer>
  )
}

const BrandsContainer = styled.div``

const BrandSection = ({ brands }) => {
  const intl = useIntl()
  const history = useHistory()

  const viewAll = () => {
    history.push(`/shop/brands`)
  }

  const viewBrand = (b) => {
    window.open(b.page, '_blank')
  }

  return (
    <BrandsContainer>
      <TitleRow>
        <SubTitle>{tt(intl, 'shop.shopbybrand')}</SubTitle>
        {brands.length > 0 && (
          <ExtraAnchor onClick={viewAll}>
            {tt(intl, 'common.viewall')}
          </ExtraAnchor>
        )}
      </TitleRow>
      <Spacer height="0.889rem" />
      <BrandGrid brands={brands.slice(0, 12)} onClickItem={viewBrand} />
    </BrandsContainer>
  )
}

const Shop = () => {
  const intl = useIntl()

  const categories = useSelector((s) => s.shop.productCategories)
  const brands = useSelector((s) => s.shop.brands)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initShop' },
  })

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  // const onClickLottery = () => {
  //   window.open(`${process.env.REACT_APP_SHOP_URL}/cny-special/`, '_blank')
  // }

  return (
    <Container>
      <PaddingContent>
        <Spacer height="2.222rem" />
        <Title>{intl.formatMessage({ id: 'common.divitshop' })}</Title>
      </PaddingContent>
      {/* <Spacer height="1.778rem" />
      <LotteryBanner onClick={onClickLottery} /> */}
      <Spacer height="1.778rem" />
      <PaddingContent>
        <CategorySection categories={categories} />
        {/* <Spacer height="1.778rem" />
        <BrandSection brands={brands} />
        <Spacer height="4.444rem" /> */}
      </PaddingContent>
    </Container>
  )
}

export default Shop
