import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as ScanQrSvg } from '@/assets/home/asset.svg'
import { ReactComponent as RightArrowSvg } from '@/assets/home/right-arrow.svg'
import { tt } from '@/locales/format'

const Container = styled.div`
  padding: 0 2rem;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #255be3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 178px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: white;
`

const SubText = styled.div`
  font-size: 14px;
`

const MainText = styled.div`
  font-weight: bold;
  font-size: 28px;
`

const FollowText = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  gap: 0.5em;
  align-items: center;
`

const ScanQrImage = styled(ScanQrSvg)`
  width: 6rem;
`

const ScanToPayBanner = () => {
  const intl = useIntl()
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://divit.com.hk/members/"
      aria-label="guide-fps"
    >
      <Container>
        <Content>
          <SubText>{tt(intl, 'landing.banner.howToPayFps')}</SubText>
          <MainText>{tt(intl, 'fps.scantopay')}</MainText>
          <FollowText>
            {tt(intl, 'landing.banner.watchTutorial')} <RightArrowSvg />
          </FollowText>
        </Content>
        <ScanQrImage />
      </Container>
    </a>
  )
}

export default ScanToPayBanner
