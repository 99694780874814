import { all, call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { actions as lookupActions } from './lookupSlice'

export function* fetchPaymentInstruments() {
  const { data } = yield call(async () => DivitAPI.get('payments/instruments'))
  yield put(lookupActions.getPaymentInstrumentsSuccess(data.data))
}

export function* fetchRateCurrencyToDVM(currency) {
  const { data: rateData } = yield call(async () =>
    DivitAPI.get(`/miles/rates/${currency}/DVM`)
  )
  yield put(
    lookupActions.getRateCurrencyToDVMSuccess({ currency, ...rateData.data })
  )
}

export function* fetchMerchantRateCurrencyToDVM(merchantID, currency) {
  const { data: rateData } = yield call(async () =>
    DivitAPI.get(`/miles/merchants/${merchantID}/rate/${currency}`)
  )
  yield put(
    lookupActions.getMerchantRateCurrencyToDVMSuccess({
      currency,
      ...rateData.data,
    })
  )
}

export function* fetchMerchantList() {
  const { data: merchants } = yield call(async () =>
    DivitAPI.get(`/merchants/v2/list`)
  )
  yield put(lookupActions.getMerchantListSuccess(merchants.data))
}

export function* fetchMerchant(merchantID) {
  const { data: merchant } = yield call(async () =>
    DivitAPI.get(`/merchants/v2/${merchantID}`)
  )
  yield put(lookupActions.getMerchantListSuccess([merchant.data]))
}

export function* fetchServices() {
  const { data } = yield call(async () => DivitAPI.get('/service/status'))
  yield put(lookupActions.getServicesSuccess(data.data))
}

export function* fetchLookups() {
  yield all([
    fetchPaymentInstruments(),
    fetchRateCurrencyToDVM('HKD'),
    fetchRateCurrencyToDVM('THB'),
    fetchRateCurrencyToDVM('SGD'),
    fetchMerchantList(),
    fetchServices(),
  ])
}

export default 0
