export const KYC_TYPE = {
  FULL: 'full',
  PARTIAL: 'partial',
}

export const KYC_STATUS = {
  BLACK: 'BLACK',
  RED: 'RED',
  AMBER: 'AMBER',
  GREEN: 'GREEN',
  REVIEWING: 'REVIEWING',
}

export default 0
