import React from 'react'
import { useIntl } from 'react-intl'

import ErrorScreen from '@/components/error/ErrorScreen'
import { tt } from '@/locales/format'

const NotFound = () => {
  const intl = useIntl()
  return (
    <ErrorScreen
      title={tt(intl, 'common.aiyah')}
      content={tt(intl, 'common.pagenotfound')}
    />
  )
}

export default NotFound
