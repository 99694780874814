import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { FormattedMiles } from '@/utils/Price'

const Container = styled.div`
  background-color: #fafafa;
  padding: 1.333rem;
  border-box: box-sizing;
  border: 1px solid #dddddd;
  border-radius: 16px;
`

const Title = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const Content = styled.div`
  font-size: 0.667rem;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
`

const CancelButton = styled(Button)`
  font-weight: 700;
  min-width: unset;
  height: auto;
  background-color: white;
  color: #1c1c1c;
  font-size: 0.555rem;
  padding: 0.444rem 1.111rem;
  border: 1px solid #dedede;
`

const ViewButton = styled(Button)`
  font-weight: 700;
  min-width: unset;
  height: auto;
  background-color: #1c1c1c;
  color: white;
  font-size: 0.555rem;
  padding: 0.444rem 1.111rem;
`

const PaynowOrderResumePanel = ({ orders = [], onCancel }) => {
  const intl = useIntl()
  const history = useHistory()

  const totalMiles = orders.reduce((sum, order) => {
    sum += order.milesBurned
    return sum
  }, 0)

  const onClickCancelOrders = () => {
    onCancel()
  }

  const onClickEditOrders = () => {
    history.push('/history/paynow/active')
  }

  if (orders.length === 0) {
    return null
  }

  return (
    <Container>
      <Title>{tt(intl, 'paynow.status.inprocess')}</Title>
      <Spacer height="0.444rem" />
      <Content>
        <span>{tt(intl, 'paynow.order.inprocess')}</span>
        <span> </span>
        {totalMiles > 0 && (
          <span>
            {tt(intl, 'paynow.holdmiles', {
              amt: FormattedMiles(totalMiles * 100),
            })}
          </span>
        )}
      </Content>
      <Spacer height="0.778rem" />
      <Buttons>
        <CancelButton onClick={onClickCancelOrders}>
          {tt(intl, 'paynow.order.cancel')}
        </CancelButton>
        <Spacer width="0.778rem" />
        <ViewButton onClick={onClickEditOrders}>
          {tt(intl, 'paynow.order.view')}
        </ViewButton>
      </Buttons>
    </Container>
  )
}

export default PaynowOrderResumePanel
