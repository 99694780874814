import React from 'react'
import styled from 'styled-components'

import { ReactComponent as SearchSvg } from '@/assets/common/search.svg'
import Icon from '@/components/Icon'
import Input from '@/components/ui/Input'
import { t } from '@/locales/format'

const InputIcon = styled(Icon)`
  position: absolute;
  top: 0.556rem;
  left: 0.889rem;
`

const InputField = styled(Input)`
  flex: 1;
  height: 2.222rem;
  border-radius: 35px;
  border: 0;
  outline: 1px solid #dedede;
`

const SearchIcon = () => (
  <InputIcon
    renderImage={() => <SearchSvg />}
    width="1.111rem"
    height="1.111rem"
  />
)

const SearchInput = ({ onChangeKeyword }) => (
  <InputField
    prefix={<SearchIcon />}
    placeholder={t('common.search')}
    onChange={onChangeKeyword}
  />
)

export default SearchInput
