import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import Toggle from '@/components/ui/Toggle'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { goBack } from '@/redux/app/appActions'
import {
  contactPreferenceSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'

import {
  ItemRow,
  RadioButton,
  Row,
  Section,
  SectionTitle,
  SubTitle,
} from './Shared'

const Container = styled(PaddingContent)``

const ItemTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemRadio = styled.div`
  display: flex;
  align-items: center;
`

const ItemRadioText = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
  margin-left: 0.889rem;
  cursor: pointer;
`

const ProfileLanguageSection = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { changeLocale } = useLocale()

  const { contact_pref: contactPreference, language } = useSelector(
    profileSelector
  )

  const updateLanguage = (lang) => {
    dispatch({
      type: 'updateProfile/updateContactMethod',
      payload: {
        language: lang,
        ...contactPreference,
      },
    })
  }

  const onClickLanguageRadio = (locale) => {
    updateLanguage(locale)
    changeLocale(locale)
  }

  return (
    <Section>
      <SectionTitle>{tt(intl, 'profile.language')}</SectionTitle>
      <ItemRow>
        <ItemRadio onClick={() => onClickLanguageRadio('en')}>
          <RadioButton checked={language === 'en'} />
          <ItemRadioText>english</ItemRadioText>
        </ItemRadio>
      </ItemRow>
      <ItemRow>
        <ItemRadio onClick={() => onClickLanguageRadio('zh-hk')}>
          <RadioButton checked={language === 'zh-hk'} />
          <ItemRadioText>繁體中文</ItemRadioText>
        </ItemRadio>
      </ItemRow>
      <ItemRow>
        <ItemRadio onClick={() => onClickLanguageRadio('zh-cn')}>
          <RadioButton checked={language === 'zh-cn'} />
          <ItemRadioText>简体中文</ItemRadioText>
        </ItemRadio>
      </ItemRow>
    </Section>
  )
}

const ProfileNotifications = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { currentLocale } = useLocale()

  const [bySms, setBySms] = useState(false)
  const [byEmail, setByEmail] = useState(false)
  const [receivePromotion, setReceivePromotion] = useState(false)

  const contactPreference = useSelector(contactPreferenceSelector)

  const items = {
    sms: {
      key: 'sms',
      setFn: setBySms,
    },
    email: {
      key: 'email',
      setFn: setByEmail,
    },
    promotion: {
      key: 'promotion',
      setFn: setReceivePromotion,
    },
  }

  useEffect(() => {
    if (contactPreference) {
      setBySms(contactPreference.sms)
      setByEmail(contactPreference.email)
      setReceivePromotion(contactPreference.promotion)
    }
  }, [contactPreference])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfile' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const updateContact = (payload) => {
    dispatch({
      type: 'updateProfile/updateContactMethod',
      payload: {
        sms: bySms,
        email: byEmail,
        language: currentLocale,
        promotion: receivePromotion,
        ...payload,
      },
    })
  }

  const onClickToggle = ({ key, setFn }) => (v) => {
    setFn(v)
    updateContact({ [key]: v })
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Row>
        <Title onClickBack={onClickBack}>
          {tt(intl, 'profile.notifications')}
        </Title>
      </Row>
      <Spacer height="0.889rem" />
      <SubTitle>{tt(intl, 'profile.notifications.select')}</SubTitle>
      <Spacer height="1.778rem" />
      <div>
        <Section>
          <SectionTitle>
            {tt(intl, 'profile.notifications.activity')}
          </SectionTitle>
          <ItemRow>
            <ItemTitle>{tt(intl, 'profile.notifications.sms')}</ItemTitle>
            <Toggle checked={bySms} onChange={onClickToggle(items.sms)} />
          </ItemRow>
          <ItemRow>
            <ItemTitle>{tt(intl, 'profile.notifications.email')}</ItemTitle>
            <Toggle checked={byEmail} onChange={onClickToggle(items.email)} />
          </ItemRow>
        </Section>
        <Section>
          <SectionTitle>
            {tt(intl, 'profile.notifications.offersnpromotion')}
          </SectionTitle>
          <ItemRow>
            <ItemTitle>{tt(intl, 'profile.notifications.promotion')}</ItemTitle>
            <Toggle
              checked={receivePromotion}
              onChange={onClickToggle(items.promotion)}
            />
          </ItemRow>
        </Section>

        <ProfileLanguageSection />
      </div>
      <Spacer height="4rem" />
    </Container>
  )
}

export default ProfileNotifications
