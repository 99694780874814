import { rgba } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as SuccessSvg } from '@/assets/common/success.svg'

const MessageBox = styled.div`
  border: ${({ theme }) => theme.message.success} 1px solid;
  border-radius: 10px;
  color: ${({ theme }) => theme.message.success};
  background: ${({ theme }) => rgba(theme.message.success, 0.07)};
  font-size: 0.6125rem;
  margin-bottom: 1.25rem;
  padding: 0.8125rem 0;
  padding-left: calc(100% / 12);
  padding-right: calc(100% / 12);
  width: calc(100% / 12 * 10 - 2px);
  display: flex;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    font-size: 0.75rem;
  }
`

const MessageIcon = styled(SuccessSvg)`
  width: 0.6rem;
  height: 0.6rem;
  margin-top: 0.05rem;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 0.8rem;
    height: 0.8rem;
  }
`

const Message = styled.span`
  flex: 1;
  margin-left: 0.5rem;
  font-weight: 700;
`

const SuccessMessageBox = ({ successMessage }) => {
  if (!successMessage) return <></>

  return (
    <MessageBox>
      <MessageIcon />
      <Message>{successMessage}</Message>
    </MessageBox>
  )
}

SuccessMessageBox.defaultProps = {
  successMessage: '',
}

SuccessMessageBox.propTypes = {
  successMessage: PropTypes.string,
}

export default SuccessMessageBox
