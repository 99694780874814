import { t } from '@/locales/format'

import { actions as modalActions } from './modalSlice'

export const actionModal = ({
  title = '',
  content = '',
  actions,
  closable = true,
}) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(
      modalActions.openModal({
        title,
        content,
        actions,
        closable,
      })
    )
  })

export const promptConfirmModal = ({
  title = '',
  content = '',
  okText = '',
  cancelText = '',
  closable = true,
}) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(
      modalActions.openModal({
        title,
        content,
        actions: [
          {
            key: 'ok',
            title: okText || t('common.ok'),
            action: () => {
              resolve({ isOk: true })
            },
          },
          closable && {
            key: 'cancel',
            title: cancelText || t('common.cancel'),
            action: () => {
              resolve({ isOk: false })
            },
          },
        ].filter((s) => s),
        closable,
      })
    )
  })

export default 0
