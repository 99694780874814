import { call, put } from 'redux-saga/effects'

import * as orderActions from '@/redux/order/orderActions'
import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'

export function* fetchOrder({ payload }) {
  try {
    const { orderId } = payload
    yield put({ type: 'order/getOrderStart' })
    const { data } = yield call(async () =>
      DivitAPI.get(`profiles/orders/${orderId}`)
    )
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
  } catch (e) {
    yield put({ type: 'order/getOrderFailed', payload: getErrorMessage(e) })
  }
}

export function* fetchBankListFps() {
  try {
    yield put({ type: 'order/getBankListStart' })
    const { data } = yield call(async () =>
      DivitAPI.get('payment/a2a/fps/list')
    )
    yield put({ type: 'order/getBankListSuccess', payload: data.data })
  } catch (e) {
    yield put({ type: 'order/getBankListFailed', payload: getErrorMessage(e) })
  }
}

export function* fetchPaylaterActiveOrders() {
  const {
    data: { data: paylaterOrders },
  } = yield call(async () =>
    DivitAPI.get('/profiles/orders', { params: { status: 'Active' } })
  )
  yield put({
    type: 'order/getAllOrdersSuccess',
    payload: { paylaterOrders },
  })
}

export function* fetchPaynowNewOrders() {
  const {
    data: { data: paynowOrders, count: paynowOrdersTotal },
  } = yield call(async () =>
    DivitAPI.get('/paynow/profiles/orders', { params: { status: 'new' } })
  )
  yield put({
    type: 'order/getPaynowOrdersSuccess',
    payload: { data: paynowOrders, total: paynowOrdersTotal },
  })
}

export function* fetchAllOrders() {
  const {
    data: { data: paylaterOrders },
  } = yield call(async () => DivitAPI.get('profiles/orders'))
  const {
    data: { data: paynowOrders, count: paynowOrdersTotal },
  } = yield call(() => DivitAPI.get('paynow/profiles/orders?status=completed'))
  yield put({
    type: 'order/getAllOrdersSuccess',
    payload: { paynowOrders, paylaterOrders, paynowOrdersTotal },
  })
}

export function* fetchAllPayments({ payload }) {
  const { refTypes, keyword, page, pageSize } = payload
  const data = yield call(() =>
    orderActions.fetchAllPayments({
      refTypes,
      keyword,
      page,
      pageSize,
    })
  )
  yield put({
    type: 'order/getAllPaymentsSuccess',
    payload: { total: data.count, data: data.data },
  })
  return data
}
