import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

export function* fetchCampaignMissions({ customerID }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`/campaign/customers/${customerID}/missions`)
  )
  yield put({ type: 'profile/getCampaignMissions', payload: data.data })

  return data
}

export default 0
