import bowser from 'bowser'

const browser = bowser.getParser(window.navigator.userAgent)

export const IS_DESKTOP = browser.getPlatform().type === 'desktop'
export const IS_MOBILE = browser.getPlatform().type === 'mobile'
export const IS_IOS = browser.getOS().name === 'iOS'
export const IS_ANDROID = browser.getOS().name === 'Android'
export const IS_SAFARI = browser.getBrowserName() === 'Safari'
export const IS_IOS_SAFARI =
  browser.getOS().name === 'iOS' && browser.getBrowserName() === 'Safari'
export const IS_FACEBOOK_APP = (() => {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
})()

export default null
