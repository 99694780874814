import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as ProgramSelectTickSvg } from '@/assets/miles/program-select-tick.svg'
import BackButton from '@/components/BackButton'
import { Button } from '@/components/Divit'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import Spacer from '@/components/Spacer'
import MilesPrograms from '@/constants/MilesPrograms'
import { tt } from '@/locales/format'
import {
  divitMilesSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'
import * as DivitMiles from '@/utils/DivitMiles'

const Container = styled(PaddingContent)``

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: 700;
  text-align: center;
  font-size: 1.33rem;
  width: 100%;
`

const Desc = styled.div`
  text-align: center;
  font-size: 0.88rem;
  font-weight: 700;
`

const List = styled.div`
  position: relative;
  overflow: auto;
  box-sizing: border-box;
`

const ListItem = styled.div`
  height: 2.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(255, 205, 51, 0.1)' : 'transparent'};
  border: ${({ isSelected }) =>
    isSelected ? '1px solid rgba(255, 205, 51, 1)' : '1px solid #eee'};

  &:hover {
    cursor: pointer;
  }
`

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
`

const ItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
`

const ItemLabelText = styled.div`
  flex: 1;
  font-size: 0.88rem;
  font-weight: 700;
`

const ItemText = styled.div`
  flex: 1;
  font-size: 0.77rem;
  font-weight: 500;
  color: #999;
`

const ItemSelect = styled.div``

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1;
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`

const MainButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  color: #1c1c1c;
  padding: 1rem 0;
`

const CancelButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.66rem;
  font-size: 0.88rem;
  background-color: transparent;
  color: #1c1c1c;
  padding: 1rem 0;
  border: 1px solid #dedede;
`

const Input = styled.input`
  display: block;
  width: 100%;
  border: 1px solid rgba(28, 28, 28, 0.2);
  border-radius: 10px;
  padding: 0.77rem 1.33rem;
  box-sizing: border-box;
  font-size: 0.77rem;

  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`

const LinkRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkProgramIcon = styled.div`
  height: 4.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Remarks = styled.div`
  font-size: 0.667rem;
  color: #979797;
`

const DivitMilesLinkProgram = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()

  const profile = useSelector(profileSelector)
  const divitMiles = useSelector(divitMilesSelector)
  const { addMembership } = useSelector((s) => s.convertDivitMiles)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [showMembershipForm, setShowMembershipForm] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [memberNo, setMemberNo] = useState('')

  const membershipAsiaMiles = DivitMiles.findMembership(
    divitMiles.memberships,
    'asiamiles'
  )
  const memberYouair = DivitMiles.findMembership(
    divitMiles.memberships,
    'youair'
  )
  const existedPrograms = [
    membershipAsiaMiles?.partner,
    memberYouair?.partner,
  ].filter((m) => m)

  const list = MilesPrograms.filter((p) => existedPrograms.indexOf(p.key) < 0)

  useEffect(() => {
    history.replace('/')
    return () => dispatch({ type: 'convertDivitMiles/reset' })
  }, [])

  useEffect(() => {
    if (addMembership.isSuccess) {
      setFirstName('')
      setLastName('')
      setMemberNo('')
      history.push('/profile/miles/convert')
    }
  }, [addMembership])

  // default select first enabled membership
  useEffect(() => {
    if (selectedProgram === null) {
      const p = MilesPrograms.find((program) =>
        DivitMiles.findMembership(divitMiles.memberships, program.key)
      )
      if (p) {
        setSelectedProgram(p)
      }
    }
  }, [divitMiles.memberships])

  if (!profile) return <></>

  const onBack = () => {
    setFirstName('')
    setLastName('')
    setMemberNo('')
    if (showMembershipForm) {
      setShowMembershipForm(false)
    } else {
      history.push('/profile/miles/convert')
    }
  }
  const onClickNext = () => {
    setShowMembershipForm(true)
  }
  const onClickLink = () => {
    dispatch({
      type: 'convertDivitMiles/addMembership',
      payload: {
        firstName,
        lastName,
        membershipID: memberNo,
        partner: selectedProgram.key,
      },
    })
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Spacer height="2.22rem" />
          <HeaderRow>
            <BackButton onClick={onBack} />
            <Title>{tt(intl, 'loyalty.rewardprogramlink')}</Title>
            <Spacer width="1.5rem" />
          </HeaderRow>
          <Spacer height="0.88rem" />
          {(!showMembershipForm || !selectedProgram) && (
            <>
              <Desc>{tt(intl, 'loyalty.chooserewardprogram')}</Desc>
              <Spacer height="2.22rem" />
              <List>
                {list.map((p) => (
                  <ListItem
                    key={p.key}
                    isSelected={selectedProgram?.key === p.key}
                    onClick={() => setSelectedProgram(p)}
                  >
                    <ItemTitle>
                      <ItemIcon>
                        <Icon
                          renderImage={() => <img alt="" src={p.companyLogo} />}
                          width="2.4rem"
                          height="2.4rem"
                        />
                      </ItemIcon>
                      <Spacer width="1rem" />
                      <ItemLabelText>
                        {p.companyName}
                        <ItemText>{p.name}</ItemText>
                      </ItemLabelText>
                    </ItemTitle>
                    <ItemSelect>
                      {selectedProgram?.key === p.key && (
                        <Icon
                          renderImage={() => <ProgramSelectTickSvg />}
                          width="0.88rem"
                          height="0.66rem"
                        />
                      )}
                    </ItemSelect>
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {showMembershipForm && selectedProgram && (
            <>
              <Desc>{tt(intl, 'loyalty.filltolinkrewardprogram')}</Desc>
              <Spacer height="1rem" />
              <LinkRow>
                <LinkProgramIcon>
                  <Icon
                    renderImage={() => (
                      <img alt="" src={selectedProgram?.companyHLogo} />
                    )}
                    height="2.55rem"
                  />
                </LinkProgramIcon>
              </LinkRow>
              <Spacer height="0.5rem" />
              <ErrorMessageBox errorMessage={addMembership?.error?.message} />
              <Spacer height="0.5rem" />
              <div style={{ display: 'flex' }}>
                <Input
                  name="firstName"
                  value={firstName}
                  placeholder={tt(intl, 'common.firstname')}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Spacer width="0.56rem" />
                <Input
                  name="lastName"
                  value={lastName}
                  placeholder={tt(intl, 'common.lastname')}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <Spacer height="0.444rem" />
              <Remarks>
                {tt(intl, 'loyalty.filltolinkrewardprogram.note')}
              </Remarks>
              <Spacer height="1.33rem" />
              <Input
                name="no"
                value={memberNo}
                placeholder={tt(intl, 'loyalty.membernumber.enter')}
                onChange={(e) => setMemberNo(e.target.value)}
              />
              <Spacer height="1.33rem" />
            </>
          )}
          <LoadingModal loading={addMembership.isLoading} />
        </Container>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <Container>
          <ActionButtons>
            <CancelButton onClick={onBack}>
              {tt(intl, 'common.cancel')}
            </CancelButton>
            {!showMembershipForm && (
              <MainButton
                disabled={!selectedProgram}
                onClick={() => onClickNext()}
              >
                {tt(intl, 'common.linknow')}
              </MainButton>
            )}
            {showMembershipForm && (
              <MainButton
                disabled={!firstName || !lastName || !memberNo}
                onClick={onClickLink}
              >
                {tt(intl, 'common.linknow')}
              </MainButton>
            )}
          </ActionButtons>
        </Container>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default DivitMilesLinkProgram
