import DivitAPI from '@/services/api'
import * as Pagination from '@/utils/pagination'
import { sleep } from '@/utils/promises'
import { arrayToQs } from '@/utils/request'

export const fetchPaynowNextPage = () => async (dispatch, getState) => {
  await sleep(1000)
  const { paynowOrders, paynowOrdersTotal } = getState().order
  const hasMore = Pagination.hasMore(paynowOrders, paynowOrdersTotal)
  if (!hasMore) return
  const pageNo = Pagination.getNextPageNo(paynowOrders)
  const { data } = await DivitAPI.get('paynow/profiles/orders', {
    params: { status: 'completed', page: pageNo, pageSize: 25 },
  })
  dispatch({
    type: 'order/insertPaynowOrders',
    payload: { total: data.count, data: data.data },
  })
}

const PAYMENT_DEFAULT_PAGE_SIZE = 25

export const fetchAllPayments = async ({
  refTypes = [],
  keyword = '',
  page = 0,
  pageSize = PAYMENT_DEFAULT_PAGE_SIZE,
}) => {
  let paramStr = `page=${page}&pageSize=${pageSize}`

  if (refTypes.length > 0) {
    paramStr += `&${arrayToQs('refTypes', refTypes)}`
  }
  if (keyword) {
    paramStr += `&keyword=${keyword}`
  }

  const { data } = await DivitAPI.get(`/reporting/transactions?${paramStr}`)

  return data
}

export const fetchPaymentsNextPage =
  ({ refTypes, keyword }) =>
  async (dispatch, getState) => {
    await sleep(1000)
    const { payments, paymentsTotal } = getState().order
    const hasMore = Pagination.hasMore(payments, paymentsTotal)
    if (!hasMore) return
    const pageNo = Pagination.getNextPageNo(payments, PAYMENT_DEFAULT_PAGE_SIZE)
    const data = await fetchAllPayments({
      refTypes,
      keyword,
      page: pageNo,
      pageSize: PAYMENT_DEFAULT_PAGE_SIZE,
    })
    dispatch({
      type: 'order/insertPayments',
      payload: { total: data.count, data: data.data },
    })
  }

export default 0
