import React from 'react'

import Spacer from '@/components/Spacer'

// 1.25 + [2.68]

export const defaultHeaderHeight = '3.2rem'

const HeaderSpacer = () => {
  const headerHeight = defaultHeaderHeight

  return <Spacer height={headerHeight} />
}

export default HeaderSpacer
