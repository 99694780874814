import React from 'react'
import styled from 'styled-components'

const Message = styled.div`
  font-size: 0.778rem;
  color: #ff0000;
  margin: 0.5rem 0;
  text-align: center;
`

const ListItem = styled.div`
  margin-bottom: 0.2rem;
`

const ErrorMessageLine = ({ errorMessage, ...props }) => {
  if (!errorMessage) return <></>

  const message = Array.isArray(errorMessage)
    ? errorMessage.map((e, i) => <ListItem key={i}>{e}</ListItem>)
    : errorMessage

  return <Message {...props}>{message}</Message>
}

export default ErrorMessageLine
