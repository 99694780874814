import '@/locales/moment_zh-hk'
import 'moment/locale/zh-cn'

import moment from 'moment'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { createIntl, createIntlCache, IntlProvider } from 'react-intl'

import messages, {
  convertToLocale,
  getBrowserLanguage,
  getQueryStringLanguage,
} from '../locales'

export const EN = 'en'
export const ZH_HK = 'zh-hk'
export const ZH_CN = 'zh-cn'

/*
How lang works:
1. get query string lang=
2. get browser
3. get saved lang
4. default to en
*/

const LocaleContext = createContext({
  changeLocale: (locale) => locale,
  currentLocale: '',
  allLocales: [],
})

const getDefaultLocale = () => {
  // query string language
  let language = getQueryStringLanguage()
  let locale = convertToLocale(language)
  if (locale) {
    return locale
  }

  // browser language
  language = getBrowserLanguage()
  locale = convertToLocale(language)
  if (locale) {
    return locale
  }

  // default
  return EN
}

const getCurrentLocale = () => {
  // query string language
  const language = getQueryStringLanguage()
  let locale = convertToLocale(language)
  if (locale) {
    return locale
  }

  // from local
  locale = localStorage.getItem('locale')
  if (locale) {
    return locale
  }

  return getDefaultLocale()
}

class I18n {
  constructor(locale) {
    this.cache = createIntlCache()
    this.updateLocale(locale)
  }

  updateLocale = (locale) => {
    this.intl = createIntl(
      {
        locale,
        messages: messages[locale],
      },
      this.cache
    )
  }
}

const i18n = new I18n(getCurrentLocale())

const LocaleProvider = (props) => {
  const defaultLocale = getDefaultLocale()
  const allLocales = [EN, ZH_HK, ZH_CN]
  const savedLocale = getCurrentLocale()
  const [currentLocale, setLocale] = useState(savedLocale || defaultLocale)

  const configMoment = (locale) => {
    const options = ((param) => {
      let l
      let L
      let LL
      let LT
      let LLL
      let LLLL
      switch (param) {
        case EN:
          l = 'MMM YYYY'
          L = 'ddd DD MMM'
          LL = 'ddd, D MMM YYYY'
          LT = 'h:mma z'
          LLL = 'DD MMM YYYY'
          LLLL = 'DD MMMM YYYY'
          break
        case ZH_HK:
        case ZH_CN:
          l = 'YYYY年M月'
          L = 'YYYY年M月D日'
          LL = 'YYYY年M月D日'
          LT = 'a h:mm'
          LLL = 'YYYY年M月D日'
          LLLL = 'YYYY年M月D日'
          break
        default:
      }
      return {
        longDateFormat: {
          l,
          L,
          LL,
          LT,
          LLL,
          LLLL,
        },
      }
    })(locale)
    moment.updateLocale(locale, options)
  }

  const changeLocale = (locale) => {
    setLocale(locale)
    i18n.updateLocale(locale)
    configMoment(locale)
    localStorage.setItem('locale', locale)
    // TOFIX: mac chrome bold word look thinner in zh-hk
    // document.documentElement.setAttribute('lang', locale)
  }

  useEffect(() => {
    changeLocale(currentLocale)
  }, [])

  return (
    <IntlProvider
      locale={currentLocale}
      defaultLocale={defaultLocale}
      messages={messages[currentLocale]}
    >
      <LocaleContext.Provider
        value={{ changeLocale, currentLocale, allLocales }}
        {...props}
      />
    </IntlProvider>
  )
}

const useLocale = () => useContext(LocaleContext)

export { LocaleProvider, useLocale, i18n }
