import React from 'react'
import { useIntl } from 'react-intl'

import { ReactComponent as FacebookLoginIconSvg } from '@/assets/socials/signin/facebook-colored.svg'
import LoginButton from '@/components/buttons/LoginButton'
import LoginFacebook from '@/components/LoginFacebook'
import { tt } from '@/locales/format'

const FacebookLoginButton = ({
  onClickFacebookLogin,
  onFacebookSuccess,
  onFacebookFailure,
}) => {
  const intl = useIntl()

  const doClickFacebookLogin = (onClick) => () => {
    const result = onClickFacebookLogin ? onClickFacebookLogin() : true
    if (result) {
      onClick()
    }
  }

  return (
    <LoginFacebook
      onSuccess={onFacebookSuccess}
      onFailure={onFacebookFailure}
      render={({ onClick }) => (
        <LoginButton
          style={{ backgroundColor: '#4267B2', color: 'white' }}
          onClick={doClickFacebookLogin(onClick)}
          text={tt(intl, 'login.facebook.continue')}
          iconProps={{
            renderImage: () => <FacebookLoginIconSvg />,
            width: '1.111rem',
            height: '1.111rem',
          }}
        />
      )}
    />
  )
}

export default FacebookLoginButton
