import moment from 'moment'

// only handle these fundTxnSource
export const TXN_FUND_SOURCES = [
  'directFund',
  'fundFromReserved',
  'fundFromPending',
  'fundConvertConfirmed',
  'fundBurnConfirmed',
  'fundInternalTransfer',
]

// schemes that has pending state
export const CAMPAIGNS_SCHEMES = [
  {
    scheme: 'miles.scheme.500miles',
    pending: {
      reason: '500 MILES AFTER REGISTRATION COMPLETED',
    },
    done: {
      reason: '500 MILES AFTER FIRST ORDER COMPLETED',
    },
  },
]

// special handled scheme, shows in payment receipt
export const BONUS_SCHEMES = [
  {
    title: '500 MILES AFTER FIRST ORDER COMPLETED',
  },
]

// mapping for transactions details
export const SCHEMES = [
  {
    reason: '500 MILES AFTER FIRST ORDER COMPLETED',
    msg: 'miles.scheme.500miles',
    isEarn: true,
  },
  {
    reason: 'REWARD REFERRER ON THE FIRST ORDER',
    msg: 'miles.scheme.500referral',
    isEarn: true,
  },
  {
    reason: 'CUSTOMER FIRST PURCHASE WITH A REFERRER',
    msg: 'miles.scheme.doublemiles',
    isEarn: true,
  },
  {
    type: 'earn',
    triggeredBy: 'FirstInstalmentPaid',
    msg: 'miles.scheme.5pmiles.1st',
    isEarn: true,
  },
  {
    type: 'earn',
    triggeredBy: 'SecondInstalmentPaid',
    msg: 'miles.scheme.5pmiles.2nd',
    isEarn: true,
  },
  {
    type: 'earn',
    triggeredBy: 'ThirdInstalmentPaid',
    msg: 'miles.scheme.5pmiles.3rd',
    isEarn: true,
  },
  // default case for each txnType
  {
    type: 'claim',
    msg: 'miles.earned.default',
    isEarn: true,
  },
  {
    type: 'convertOut',
    msg: 'miles.scheme.miles.conversion.out',
    isEarn: false,
  },
  {
    type: 'convertIn',
    msg: 'miles.scheme.miles.conversion.in',
    isEarn: true,
  },
  {
    type: 'earn',
    msg: 'miles.earned.default',
    isEarn: true,
  },
  {
    type: 'cancelEarn',
    msg: 'payment.cancelled',
    isEarn: false,
  },
  {
    type: 'burn',
    msg: 'miles.burned.default',
    isEarn: false,
  },
  {
    type: 'cancelBurn',
    msg: 'payment.refund',
    isEarn: true,
  },
  {
    type: 'transfer',
    // determine by condition
    msg: 'transfer miles',
  },
]

// earn miles from pay later instalments
export const instalmentTriggerEventsMap = {
  1: 'FirstInstalmentPaid',
  2: 'SecondInstalmentPaid',
  3: 'ThirdInstalmentPaid',
}

// for pay later instalments
export const getInstalmentMilesEarned = (
  instalment,
  orderTransactions = []
) => {
  const eventName = instalmentTriggerEventsMap[instalment.series]
  return orderTransactions
    .filter((t) => t.triggeredBy === eventName && t.fundSource === 'directFund')
    .reduce((sum, txn) => {
      sum += txn.targetAmount.amount
      return sum
    }, 0)
}

// for paynow instalment
export const getMilesEarned = (orderTransactions = []) =>
  orderTransactions
    .filter(
      (t) => t.triggeredBy === 'OrderPaid' && t.fundSource === 'directFund'
    )
    .reduce((sum, txn) => {
      sum += txn.targetAmount.amount
      return sum
    }, 0)

export const getTicketsEarned = (metadata = []) => {
  const txns = metadata.filter(
    (t) => t.transactionType === 'payment' && t.status === 'completed'
  )
  return txns?.[0]?.metadata?.ticketsEarned || 0
}

// earn from bonus event
export const getBonusMilesEarned = (transactions = []) => {
  const schemeTitles = BONUS_SCHEMES.map((s) => s.title)
  return transactions
    .filter((t) => schemeTitles.indexOf(t.reason.split('|')[0]) >= 0)
    .reduce((sum, t) => {
      if (TXN_FUND_SOURCES.indexOf(t.fundSource) >= 0) {
        sum += t.targetAmount.amount
      }
      return sum
    }, 0)
}

export const getTotalMilesEarned = (transactions = []) =>
  transactions.reduce((sum, t) => {
    if (TXN_FUND_SOURCES.indexOf(t.fundSource) >= 0) {
      sum += t.targetAmount.amount
    }
    return sum
  }, 0)

// refunded burn miles
export const getTotalMilesRefunded = (order) => {
  const transactions = order.financialTransactions?.slice() || []
  return transactions.reduce((sum, t) => {
    if (
      t.transactionType === 'payment' &&
      t.paidBy === 'miles' &&
      t.transactionStatus === 'cancelled'
    ) {
      sum += (parseInt(t.paymentRef.replace('miles:', ''), 10) || 0) * 100
    }
    if (
      t.transactionType === 'payment' &&
      t.paidBy === 'miles' &&
      t.transactionStatus === 'completed'
    ) {
      sum -= (parseInt(t.paymentRef.replace('miles:', ''), 10) || 0) * 100
    }
    return sum
  }, 0)
}

// cancel earn miles
export const getTotalMilesCancelled = (transactions = []) =>
  transactions.reduce((sum, t) => {
    if (['cancelEarn'].indexOf(t.txnType) >= 0) {
      sum += t.targetAmount.amount
    }
    return sum
  }, 0)

export const getTotalReservedMilesInOrder = (transactions = []) => {
  if (!transactions) return 0
  return transactions.reduce((sum, t) => {
    if (t.txnType === 'burnReserved') {
      sum += t.targetAmount.amount
    } else if (t.txnType === 'cancelBurnReserved') {
      sum -= t.targetAmount.amount
    }
    return sum
  }, 0)
}

export const getBalance = (books = []) =>
  books.reduce((sum, r) => {
    sum += r.balance.amount
    return sum
  }, 0)

export const getReserved = (books = []) =>
  books.reduce((sum, r) => {
    sum += r.reserved?.amount || 0
    return sum
  }, 0)

export const getPendingBalance = (books = []) =>
  books.reduce((sum, r) => {
    sum += r.pending.amount
    return sum
  }, 0)

export const getConvertedBalance = (books = []) =>
  books.reduce((sum, r) => {
    sum += r.convertedBalance?.amount || 0
    return sum
  }, 0)

export const getReservedConvertedBalance = (books = []) =>
  books.reduce((sum, r) => {
    sum += r.reservedConvertedBalance?.amount || 0
    return sum
  }, 0)

export const getConvertibleBalance = (books = []) => {
  const balance = getBalance(books)
  const convertedBalance = getConvertedBalance(books)
  const reservedConvertedBalance = getReservedConvertedBalance(books)
  return Math.max(0, balance - (convertedBalance - reservedConvertedBalance))
}

// find scheme by matching all fields required
export const findTxnScheme = (txn) =>
  SCHEMES.find((scheme) => {
    if (scheme.type) {
      if (txn.txnType !== scheme.type) {
        return false
      }
    }
    if (scheme.reason) {
      if (txn.reason.indexOf(scheme.reason) < 0) {
        return false
      }
    }
    if (scheme.triggeredBy) {
      if (txn.triggeredBy !== scheme.triggeredBy) {
        return false
      }
    }
    return true
  })

// bonus scheme should have another name
export const isBonusScheme = (txn) =>
  BONUS_SCHEMES.find((scheme) => {
    if (txn.reason.indexOf(scheme.title) >= 0) {
      return true
    }
    return false
  })

// convert to { amount, msg }
export const translateTxnToEvent = (txn) => {
  const { createdAt } = txn
  const scheme = findTxnScheme(txn)
  if (!scheme) return null
  return {
    type: scheme.type,
    msg: scheme.msg,
    isEarn: scheme.isEarn,
    amount: txn.targetAmount.amount,
    createdAt: moment(createdAt).format('LLL'),
    raw: txn,
  }
}

// all earn & burn transctions
export const getAllTransactions = (transactions = []) => {
  const txns = transactions.filter(
    (t) => TXN_FUND_SOURCES.indexOf(t.fundSource) >= 0
  )
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  return txns.map((t) => translateTxnToEvent(t)).filter((t) => t)
}

// get bonus events => { msg, amount }
export const getBonusEvents = (transactions = []) =>
  CAMPAIGNS_SCHEMES.reduce((gs, scheme) => {
    const isDone = transactions.find(
      (t) => t.reason.split('|')[0] === scheme.done.reason
    )
    if (isDone) {
      gs.push({ msg: scheme.scheme, amount: isDone.targetAmount.amount })
    }
    return gs
  }, [])

// get pending events => { msg, amount }
export const getPendingEvents = (transactions = []) =>
  CAMPAIGNS_SCHEMES.reduce((gs, scheme) => {
    const isPending = transactions.find(
      (t) => t.reason.split('|')[0] === scheme.pending.reason
    )
    const isDone = transactions.find(
      (t) => t.reason.split('|')[0] === scheme.done.reason
    )
    if (isPending && !isDone) {
      gs.push({ msg: scheme.scheme, amount: isPending.targetAmount.amount })
    }
    return gs
  }, [])

export const findMembership = (memberships, partner) =>
  memberships.find((m) => m.partner === partner)

export default null
