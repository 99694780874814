import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import CancelPurchase from '@/components/CancelPurchase'
import CheckoutSteps from '@/components/CheckoutSteps'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import ErrorMessageBox from '@/components/ErrorMessageBox'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import NormalMessageBox from '@/components/NormalMessageBox'
import Profile from '@/components/Profile'
import PurchaseSummary from '@/components/PurchaseSummary'
import Spacer from '@/components/Spacer'
import TermsV2 from '@/components/Terms/Terms_v2'
import SHOW_TERMS from '@/constants/terms'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import { payForPaylater } from '@/redux/pages/payActions'
import {
  eDDAInitedSelector,
  isFeatureEDDAEnabledSelector,
} from '@/redux/profile/profileSelector'
import * as Localstorage from '@/utils/localstorage'
import * as OrderUtils from '@/utils/Order'
import * as sessionStorage from '@/utils/sessionStorage'

const Container = styled(PaddingContent)``

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const ItemTextButton = styled.div`
  font-size: 0.778rem;
  cursor: pointer;
  text-decoration: underline;
`

const NextButton = styled(Button)`
  font-size: 0.889rem;
`

const EDDANote = styled.div`
  font-size: 0.778rem;
`

const PaylaterSummary = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderId } = useParams()

  const [modalIsOpen, setIsOpen] = useState(true)
  const [isOrderUpdated, setIsOrderUpdated] = useState(false)
  // TODO: removing agreed terms on this component
  // const [agreedTerms, setAgreedTerms] = useState(false)

  // const onCheckTerms = useCallback((checked) => setAgreedTerms(checked), [
  //   setAgreedTerms,
  // ])

  const { profile } = useSelector((state) => state.profile)
  const { order } = useSelector((state) => state.order)
  const { acceptTNC } = useSelector((state) => state.paylater)
  const isEDDAEnabled = useSelector(isFeatureEDDAEnabledSelector)
  const isUserEDDAInited = useSelector(eDDAInitedSelector)

  const [errorMessage, updateErrorMessage] = useState('')

  useEffect(() => {
    dispatch({ type: 'fetchProfile' })
    return () => dispatch({ type: 'paylater/reset' })
  }, [])

  const { retry, isInitLoading, isInitError, isInitSuccess } = usePage({
    initAction: { type: 'pageInit/initOrder', payload: { orderId } },
  })

  useEffect(() => {
    if (acceptTNC.isError) {
      updateErrorMessage(intl.formatMessage({ id: 'error.action.agree.terms' }))
    } else {
      updateErrorMessage('')
    }
    if (acceptTNC.isSuccess) {
      const instalmentId = order.instalments[0].instalmentID
      dispatch(
        payForPaylater({
          orderId,
          instalmentId,
        })
      )
    }
  }, [acceptTNC])

  // detect order is different from previous
  useEffect(() => {
    if (isInitSuccess) {
      try {
        const checkoutOrder = sessionStorage.getJSON('checkout_order_data')
        const isDiff =
          checkoutOrder &&
          checkoutOrder.cashAmount?.amount !== order.cashAmount?.amount
        sessionStorage.saveJSON('checkout_order_data', order)
        setIsOrderUpdated(isDiff)
      } catch (err) {
        console.log(err)
      }
    }
  }, [isInitSuccess])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  if (!order) return <></>
  if (order.orderid !== orderId) return <></>

  const onClickEditReminderDate = () => {
    history.push(`/paylater/schedule/${orderId}`)
  }

  const onClickEditProfile = () => {
    history.push({
      pathname: `/paylater/profile/${orderId}`,
      state: {
        update: true,
      },
    })
  }

  const onClickNext = (e) => {
    e.preventDefault()

    // if (agreedTerms) {
    dispatch({
      type: 'gtm/sendEvent',
      payload: {
        event: 'agree_tnc',
        userId: profile.customerID,
        orderId: order.orderid,
      },
    })
    dispatch({ type: 'paylater/acceptTNC', payload: { orderId } })
    // }
  }

  // order validation
  if (OrderUtils.isEnd(order)) {
    const instalmentId = order.instalments[0].instalmentID
    const redirectUrl = `/paylater/payment/check/${orderId}/${instalmentId}`
    return <Redirect to={redirectUrl} />
  }

  return (
    <>
      <TermsV2
        order={order}
        profile={profile}
        modalIsOpen={
          modalIsOpen && JSON.parse(localStorage.getItem(SHOW_TERMS))
        }
        setIsOpen={setIsOpen}
        orderId={orderId}
      />
      <Container>
        <Spacer height="1.333rem" />
        <CheckoutSteps index={2} />
        <Spacer height="1.333rem" />
        {isOrderUpdated && (
          <>
            <NormalMessageBox>
              {tt(intl, 'paylater.kyc.dumpedspend')}
            </NormalMessageBox>
            <Spacer height="1rem" />
          </>
        )}
        <PurchaseSummary order={order} />
        <Spacer height="0.889rem" />
        <Row>
          <div />
          <ItemTextButton onClick={onClickEditReminderDate}>
            {tt(intl, 'paylater.editreminderdate')}
          </ItemTextButton>
        </Row>
        <Spacer height="1.333rem" />
        <Line />
        <Spacer height="1.333rem" />
        <Profile profile={profile} onClickEdit={onClickEditProfile} />
        <Spacer height="1.333rem" />
        <Line />
        {/* <Spacer height="1.333rem" />
      <Terms
        order={order}
        profile={profile}
        value={agreedTerms}
        onCheck={onCheckTerms}
      />
      <Spacer height="0.889rem" /> */}
        <ErrorMessageBox errorMessage={errorMessage} />
        <Spacer height="0.889rem" />
        <NextButton
          type="stretch"
          // disabled={!agreedTerms || acceptTNC.isLoading}
          disabled={acceptTNC.isLoading}
          onClick={onClickNext}
          data-selenium="signup.schedule.next"
        >
          {intl.formatMessage({ id: 'button.pay' })}
        </NextButton>
        {isEDDAEnabled && !isUserEDDAInited && (
          <>
            <Spacer height="0.889rem" />
            <EDDANote>{tt(intl, 'paylater.clickpayenableedda')}</EDDANote>
          </>
        )}
        <Spacer height="2rem" />
        <CancelPurchase />
        <Spacer height="2rem" />
      </Container>
    </>
  )
}

export default PaylaterSummary
