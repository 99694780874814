import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as CopySvg } from '@/assets/common/copy.svg'
import { ReactComponent as AlertSvg } from '@/assets/payment/alert-triangle.svg'
import { ReactComponent as QuestionSvg } from '@/assets/payment/fps-howto.svg'
import CountDownLabel from '@/components/CountDownLabel'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import PaymentSettings from '@/constants/payment'
import useWindowSize from '@/hooks/useWindowSize'
import { tt } from '@/locales/format'

import { ListItemRow } from './FpsPaymentStyles'

const MobileContainer = styled(PaddingContent)`
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DesktopContainer = styled(PaddingContent)`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding: 1.2rem;
`

const DesktopContainerLeft = styled.div`
  flex: 1;
  text-align: left;
`

const DesktopContainerRight = styled.div`
  text-align: right;
`

const PaymentMethodTitle = styled.div`
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  word-wrap: wrap;
`

const HowToPay = styled(ListItemRow)`
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  justify-content: left;
  align-items: center;
  margin-left: 0.2rem;
`

const FpsDetailsContainer = styled.div`
  background-color: #f5f5f5;
  padding: 1.333rem 1.111rem;
  box-sizing: border-box;
  border-radius: 10px;

  @media (min-width: 768px) {
    max-width: 400px;
  }
`

const FpsDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FpsDetailsTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const FpsDetailsValue = styled.div`
  font-size: 1.111rem;
  font-weight: 700;
`

const FpsDetailsCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #dd732d;
  color: white;
  padding: 0.889rem;
  box-sizing: border-box;
  border-radius: 10px;
`

const FpsDetailsCodeNote = styled.div`
  flex: 1;
  text-align: left;
  font-size: 0.7rem;
  font-weight: 500;
`

const FpsDetailsCodeValueContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  align-items: center;
`

const FpsDetailsCode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.778rem;
  font-weight: 700;
  color: #ffb519;
  cursor: pointer;
`

const AlertIcon = styled(AlertSvg)`
  & path {
    fill: #fff;
  }
`

const Instruction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.8rem;
`

const InstructionNum = styled.div`
  border-radius: 50%;
  background-color: #fff7e8;
  font-size: 0.8rem;
  font-weight: 700;
  color: #ffc20d;
  margin-right: 1rem;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InstructionText = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
`

const FpsUserGuideTitle = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.8rem 1.2rem;
  border: 1px solid #eff0f1;
  border-radius: 0.8rem;
`

const FpsManualPaymentDetailsDesktop = ({ fpsDetails, showGuide }) => {
  const intl = useIntl()
  const { fpsID, payee, paymentRefID } = fpsDetails || {}

  const onCopyPaymentRef = () => {
    navigator.clipboard.writeText(paymentRefID)
  }

  // fpsDetails.effectiveDuration = 1800
  return (
    <DesktopContainer>
      <DesktopContainerLeft>
        <PaymentMethodTitle>
          {tt(intl, 'fps.paytosecureorder', {
            countdown: (
              <>
                &nbsp;
                <CountDownLabel timeoutInSec={fpsDetails.effectiveDuration} />
              </>
            ),
          })}
        </PaymentMethodTitle>
        <Instruction>
          <InstructionNum>1</InstructionNum>
          <InstructionText>
            {tt(intl, 'fps.manual.instruction.1')}
          </InstructionText>
        </Instruction>
        <Instruction>
          <InstructionNum>2</InstructionNum>
          <InstructionText>
            {tt(intl, 'fps.manual.instruction.2.1')}
          </InstructionText>
        </Instruction>
        <Spacer height="2.4rem" />
        <FpsUserGuideTitle onClick={showGuide}>
          {tt(
            intl,
            `${
              PaymentSettings.CURRENCY_TO_PAYMENT_METHOD[
                fpsDetails.paidCurrency
              ]
            }.userguide`
          )}
          <HowToPay>
            <QuestionSvg />
          </HowToPay>
        </FpsUserGuideTitle>
      </DesktopContainerLeft>
      <Spacer width="0.889rem" />
      <DesktopContainerRight>
        <FpsDetailsContainer>
          <FpsDetailsRow>
            <FpsDetailsTitle>
              {tt(
                intl,
                `${
                  PaymentSettings.CURRENCY_TO_PAYMENT_METHOD[
                    fpsDetails.paidCurrency
                  ]
                }.id`
              )}
            </FpsDetailsTitle>
            <FpsDetailsValue>{fpsID}</FpsDetailsValue>
          </FpsDetailsRow>
          <Spacer height="0.889rem" />
          <FpsDetailsRow>
            <FpsDetailsTitle>{tt(intl, 'fps.manual.payee')}</FpsDetailsTitle>
            <FpsDetailsValue>{payee}</FpsDetailsValue>
          </FpsDetailsRow>
          <Spacer height="0.5rem" />
          <FpsDetailsRow>
            <FpsDetailsTitle>{tt(intl, 'fps.manual.enter')}*</FpsDetailsTitle>
            <FpsDetailsValue>
              <FpsDetailsCode onClick={onCopyPaymentRef}>
                {paymentRefID} &nbsp; <CopySvg />
              </FpsDetailsCode>
            </FpsDetailsValue>
          </FpsDetailsRow>
          <Spacer height="0.5rem" />
          <FpsDetailsCodeContainer>
            <AlertIcon />
            <FpsDetailsCodeValueContainer>
              <FpsDetailsCodeNote>
                {`${tt(intl, 'fps.manual.note')} "${paymentRefID}" ${tt(
                  intl,
                  'fps.manual.note2'
                )}*`}
              </FpsDetailsCodeNote>
            </FpsDetailsCodeValueContainer>
          </FpsDetailsCodeContainer>
        </FpsDetailsContainer>
      </DesktopContainerRight>
    </DesktopContainer>
  )
}

const FpsManualPaymentDetailsMobile = ({ fpsDetails, showGuide }) => {
  const intl = useIntl()
  const { fpsID, payee, paymentRefID } = fpsDetails || {}

  const onCopyPaymentRef = () => {
    navigator.clipboard.writeText(paymentRefID)
  }

  return (
    <MobileContainer>
      <Spacer height="1.2rem" />
      <PaymentMethodTitle>
        {tt(intl, 'fps.paytosecureorder', {
          countdown: (
            <>
              &nbsp;
              <CountDownLabel timeoutInSec={fpsDetails.effectiveDuration} />
            </>
          ),
        })}
      </PaymentMethodTitle>
      <Spacer height="1.2rem" />
      <FpsDetailsContainer>
        <FpsDetailsRow>
          <FpsDetailsTitle>
            {tt(
              intl,
              `${
                PaymentSettings.CURRENCY_TO_PAYMENT_METHOD[
                  fpsDetails.paidCurrency
                ]
              }.id`
            )}
          </FpsDetailsTitle>
          <FpsDetailsValue>{fpsID}</FpsDetailsValue>
        </FpsDetailsRow>
        <Spacer height="0.889rem" />
        <FpsDetailsRow>
          <FpsDetailsTitle>{tt(intl, 'fps.manual.payee')}</FpsDetailsTitle>
          <FpsDetailsValue>{payee}</FpsDetailsValue>
        </FpsDetailsRow>
        <Spacer height="0.5rem" />
        <FpsDetailsRow>
          <FpsDetailsTitle>{tt(intl, 'fps.manual.enter')}*</FpsDetailsTitle>
          <FpsDetailsValue>
            <FpsDetailsCode onClick={onCopyPaymentRef}>
              {paymentRefID} &nbsp; <CopySvg />
            </FpsDetailsCode>
          </FpsDetailsValue>
        </FpsDetailsRow>
        <Spacer height="0.5rem" />
        <FpsDetailsCodeContainer>
          <AlertIcon />
          <FpsDetailsCodeValueContainer>
            <FpsDetailsCodeNote>
              {`${tt(intl, 'fps.manual.note')} "${paymentRefID}" ${tt(
                intl,
                'fps.manual.note2'
              )}*`}
            </FpsDetailsCodeNote>
          </FpsDetailsCodeValueContainer>
        </FpsDetailsCodeContainer>
      </FpsDetailsContainer>
      <Spacer height="1.2rem" />
      <FpsUserGuideTitle onClick={showGuide}>
        {tt(
          intl,
          `${
            PaymentSettings.CURRENCY_TO_PAYMENT_METHOD[fpsDetails.paidCurrency]
          }.userguide`
        )}
        <HowToPay>
          <QuestionSvg />
        </HowToPay>
      </FpsUserGuideTitle>
      <Spacer height="1.2rem" />
    </MobileContainer>
  )
}

const FpsManualPaymentDetails = ({ fpsDetails, showGuide }) => {
  const { width } = useWindowSize()
  const isDesktop = width >= 768

  return isDesktop ? (
    <FpsManualPaymentDetailsDesktop
      fpsDetails={fpsDetails}
      showGuide={showGuide}
    />
  ) : (
    <FpsManualPaymentDetailsMobile
      fpsDetails={fpsDetails}
      showGuide={showGuide}
    />
  )
}

export default FpsManualPaymentDetails
