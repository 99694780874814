import moment from 'moment'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as FligthSvg } from '@/assets/common/flight.svg'
import { MERCHANT_CODES } from '@/constants/merchants'
import { AirlineLogo, AirlinePrimaryColor } from '@/utils/Airline'
import FormattedDuration from '@/utils/Duration'

import CircleDots from './icons/CircleDots'
import PlaneLogo from './icons/PlaneLogo'

// Ticket
const Container = styled.div`
  margin: 0 auto 0.625rem;
  width: 100%;
  max-width: 480px;
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 5px #ccc;
  overflow: hidden;
  transition: padding-bottom 0.15s;
  font-size: 1rem;
  background-color: white;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    font-size: 1.2rem;
  }

  &.hidden {
    padding-bottom: 0;
  }
`

// Ticket > TicketEdge
const TicketEdge = styled.div`
  position: absolute;
  top: 0;
  background-color: ${({ color }) => color};
  width: 0.5rem;
  height: 100%;
  ${({ position }) => position === 'left' && 'left: 0;'}
  ${({ position }) => position === 'right' && 'right: 0;'}
`

// Ticket > FlightInfo
const FlightInfo = styled.div`
  width: 82%;
  height: 100%;
  margin: 0.778rem auto 0.889rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

// Ticket > FlightInfo > FlightInfoHeader
const FlightInfoHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.778rem;
`

// Ticket > FlightInfo > FlightInfoHeader > FlightNumber
const FlightNumber = styled.div`
  text-align: right;
  font-size: 0.625em;
  font-weight: 700;
`

// Ticket > FlightInfo > FlightInfoBody > Departure
const FlightInfoBody = styled.div`
  display: flex;
`

// Ticket > FlightInfo > FlightInfoBody > Departure
const Departure = styled.div`
  text-align: left;
  flex: 1;
`

// Ticket > FlightInfo > FlightInfoBody > Arrival
const Arrival = styled.div`
  text-align: right;
  flex: 1;
`

// Ticket > FlightInfo > FlightInfoBody > Placeholder
const Placeholder = styled.div`
  text-align: center;
  flex: 1;
  font-size: 0.375em;
  color: ${({ theme }) => rgba(theme.text, 0.5)};
  position: relative;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    flex: 2;
  }
`

// Ticket > FlightInfo > FlightInfoBody > Placeholder > DottedLine
const DottedLine = styled.div`
  position: absolute;
  margin-top: 2em;
  top: 6%;
  border-bottom: 1px dashed #ccc;
  width: 38%;
  ${({ position }) => position === 'left' && 'left: 0;'}
  ${({ position }) => position === 'right' && 'right: 0;'}
`

// Ticket > FlightInfo > FlightInfoBody > Placeholder > FlightLogo
const FlightLogo = styled(FligthSvg)`
  width: auto;
  height: 1.5em;
  margin: 1.875em 0;

  & path {
    fill: #ccc;
  }
`

// Ticket > * > IATA
const IATA = styled.div`
  font-size: 1em;
  font-weight: 700;
  line-height: 2;
`

// Ticket > * > Date
const Date = styled.div`
  font-size: 0.5em;
`

// Ticket > * > Time
const Time = styled.div`
  font-size: 0.5em;
  font-weight: 700;
`

const Duration = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-top: 14px;
`

const BookingRef = styled.div`
  color: #939599;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; /* 150% */
`

const CircleDotsStyled = styled(CircleDots)`
  width: 56px;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
`

const PlaneLogoStyled = styled(PlaneLogo)`
  position: absolute;
  top: 1rem;
`

const PlaneLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Ticket = ({ index, orderItem, ...props }) => {
  const { orderData } = orderItem
  const { arrival, carrierCode, departure, duration, number } = orderData
  const { at: arrivalTime, iataCode: arrivalIATA } = arrival
  const { at: departureTime, iataCode: departureIATA } = departure
  const formattedDuration = FormattedDuration(duration)
  const flightNumber = carrierCode + number
  const { previewOrder } = useSelector((state) => state.paylater)
  const { order } = useSelector((state) => state.order)

  const paymentRefPreview = previewOrder?.data?.data?.partnerRef || ''
  const paymentRef = order?.partnerRef || ''

  return (
    <Container {...props}>
      {/* TODO: Uncomment for testing */}
      {/* <TicketEdge position="left" color={AirlinePrimaryColor('HX')} /> */}
      <TicketEdge position="left" color={AirlinePrimaryColor(carrierCode)} />
      <FlightInfo>
        <FlightInfoHeader>
          {AirlineLogo(carrierCode)}
          {carrierCode !== MERCHANT_CODES.HK_AIRLINES ? (
            <FlightNumber>{flightNumber}</FlightNumber>
          ) : (
            <BookingRef>
              booking ref: #{`${paymentRef || paymentRefPreview || ''}`}
            </BookingRef>
          )}
        </FlightInfoHeader>
        <FlightInfoBody>
          <Departure>
            <IATA>{departureIATA}</IATA>
            <Time>{moment(departureTime).format('HH:mm')}</Time>
            <Date>
              {moment(departureTime).format('DD MMM')} (
              <span>{moment(departureTime).format('ddd')}</span>)
            </Date>
          </Departure>
          <Placeholder>
            {carrierCode !== MERCHANT_CODES.HK_AIRLINES ? (
              <div>
                <DottedLine position="left" />
                <FlightLogo />
                <DottedLine position="right" />
              </div>
            ) : (
              <>
                <CircleDotsStyled />
                <PlaneLogoContainer>
                  <PlaneLogoStyled />
                </PlaneLogoContainer>
              </>
            )}
            <Duration>{formattedDuration}</Duration>
          </Placeholder>
          <Arrival>
            <IATA>{arrivalIATA}</IATA>
            <Time>{moment(arrivalTime).format('HH:mm')}</Time>
            <Date>
              {moment(arrivalTime).format('DD MMM')} (
              <span>{moment(arrivalTime).format('ddd')}</span>)
            </Date>
          </Arrival>
        </FlightInfoBody>
      </FlightInfo>
      {/* TODO: Uncomment for testing */}
      {/* <TicketEdge position="right" color={AirlinePrimaryColor('HX')} /> */}
      <TicketEdge position="right" color={AirlinePrimaryColor(carrierCode)} />
    </Container>
  )
}

Ticket.propTypes = {
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderItem: PropTypes.object.isRequired,
}

export default Ticket
