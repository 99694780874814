import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

export function* fetchOrderActivities({ orderId, instalmentId }) {
  let url = `reporting/orders/${orderId}/transaction`
  if (instalmentId) {
    url = `${url}?instalments=${instalmentId}`
  }
  const { data } = yield call(async () => DivitAPI.get(url))
  yield put({ type: 'order/getOrderActivitiesSuccess', payload: data.data })
}

export default 0
