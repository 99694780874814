import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as VerifiedSvg } from '@/assets/profile/verified.svg'
import BackButton from '@/components/BackButton'
import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import Checkbox from '@/components/ui/CheckBox'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { Input } from '@/pages/Auth/shared/style'
import { goBack } from '@/redux/app/appActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { verifyIdentity } from '@/redux/pin/pinActions'
import {
  identitySelector,
  profileSelector,
  profileShippingAddressesSelector,
} from '@/redux/profile/profileSelector'
import { actions as ToastActions } from '@/redux/toast/ToastSlice'
import { joinAddress } from '@/utils/Address'
import { parsePhoneNumber } from '@/utils/Phone'
import { joinName } from '@/utils/user'

import { ProfileAddressModal } from './ProfileAddress'
import { ProfileDeliveryAddressModal } from './ProfileDeliveryAddress'
import { ProfileEditEmailModal } from './ProfileEditEmail'
import { ProfileEditMobileModal } from './ProfileEditMobile'
import {
  ActionButtons,
  CancelButton,
  ItemRow,
  MainButton,
  Row,
  Section,
  Title,
} from './Shared'

const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  background-size: 8px 1px;
`

const Container = styled(PaddingContent)``

const ItemTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemRowAddress = styled.div`
  flex-direction: column;
`

const ItemRowMultiAddress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.889rem;
  padding: 1.333rem 1.111rem;
  box-sizing: border-box;
  background-color: #fafafa;
  border-radius: 8px;
`

const ItemText = styled.div`
  font-size: 0.889rem;
`

const ItemButtons = styled.div`
  display: flex;
  align-items: center;
`

const ItemTextButton = styled.div`
  font-weight: 700;
  font-size: 0.778rem;
  cursor: pointer;
  text-decoration: underline;
`

const SetDefaultRow = styled(Row)`
  cursor: pointer;
`

const SetDefaultText = styled.div`
  font-size: 0.889rem;
  font-weight: 500;
`

const ItemEditButton = ({ exist, onClick }) => {
  const intl = useIntl()
  return (
    <ItemTextButton onClick={onClick}>
      {exist ? tt(intl, 'common.edit') : tt(intl, 'common.add')}
    </ItemTextButton>
  )
}

const ItemAddButton = ({ exist, onClick }) => {
  const intl = useIntl()
  return exist ? null : (
    <ItemTextButton onClick={onClick}>{tt(intl, 'common.add')}</ItemTextButton>
  )
}

const ProfilePersonal = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isShowEditEmailModal, setIsShowEditEmailModal] = useState(false)
  const [isShowEditMobileModal, setIsShowEditMobileModal] = useState(false)
  const [isShowAddressModal, setIsShowAddressModal] = useState(false)
  const [showDeliveryAddressModal, setShowDeliveryAddressModal] = useState({
    isOpen: false,
    address: null,
  })
  // const [defaultShippingAddressId, setDefaultShippingAddressId] = useState(0)

  const profile = useSelector(profileSelector)
  const billingAddress = profile?.address?.filter(
    (add) => add.addressType === 'billing'
  )[0]

  // const shippingAddresses = profile?.address?.filter(
  //   (add) => add.addressType === 'shipping'
  // )

  const { updateProfile, updateProfileAddress } = useSelector(
    (s) => s.updateProfile
  )
  const identity = useSelector(identitySelector)

  useEffect(() => () => dispatch({ type: 'updateProfile/reset' }), [])

  useEffect(() => {
    setFirstName(profile.firstName)
    setLastName(profile.lastName)

    // if (profile.customerID && !profile.nationalID) {
    //   dispatch({ type: 'fetchIdentity' })
    // }

    // const defaultShippingAddress = shippingAddresses.filter((s) => s.isDefault) || []
    // setDefaultShippingAddressId(defaultShippingAddress[0]?.ID || 0)
  }, [profile])

  useEffect(() => {
    if (updateProfile.isSuccess) {
      dispatch(ToastActions.addToast({ message: tt(intl, 'profile.updated') }))
    }
  }, [updateProfile])

  useEffect(() => {
    if (updateProfileAddress.isSuccess) {
      dispatch(ToastActions.addToast({ message: tt(intl, 'profile.updated') }))
    }
  }, [updateProfileAddress])

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfile' },
  })
  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const updateContact = () => {
    dispatch({
      type: 'updateProfile/updateProfile',
      payload: { firstName, lastName },
    })
  }

  const onClickEditEmail = async () => {
    const authResult = await dispatch(verifyIdentity({}))
    if (authResult.isSuccess) {
      setIsShowEditEmailModal(true)
    }
  }

  const onClickEditMobile = async () => {
    const authResult = await dispatch(verifyIdentity({}))
    if (authResult.isSuccess) {
      setIsShowEditMobileModal(true)
    }
  }

  const onClickEditIdCard = () => {
    // history.push('/profile/identity')
    history.push('/profile/kyc')
  }

  const onClickEditAddress = () => {
    setIsShowAddressModal(true)
  }

  // const onClickSetDefaultShippingAddress = (address) => {
  //   const isDefault = defaultShippingAddressId !== address.ID

  //   dispatch({
  //     type: 'updateProfile/updateProfileAddress',
  //     payload: {
  //       ...address,
  //       isDefault,
  //     },
  //   })
  // }

  const showDeliveryAddress = (isOpen, address) => {
    if (isOpen) {
      setShowDeliveryAddressModal({ isOpen, address })
    } else {
      setShowDeliveryAddressModal({ isOpen })
      setTimeout(() => {
        setShowDeliveryAddressModal({ isOpen, address: null })
      }, 250)
    }
  }

  const removeDeliveryAddress = async (address) => {
    const { ID } = address

    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'profile.shippingaddress.askremove'),
        okText: tt(intl, 'profile.shippingaddress.remove'),
      })
    )

    if (!isOk) return

    dispatch({
      type: 'updateProfile/removeProfileAddress',
      payload: { id: ID },
    })
  }

  const onClickBack = () => {
    dispatch(goBack())
  }

  const renderCountryName = (countryCode) => {
    switch (countryCode) {
      case 'HK':
        return tt(intl, 'profile.region.hongkong').toUpperCase()

      case 'TH':
        return tt(intl, 'profile.region.thailand').toUpperCase()

      case 'AU':
        return tt(intl, 'profile.region.australia').toUpperCase()

      case 'IN':
        return tt(intl, 'profile.region.india').toUpperCase()

      default:
        return tt(intl, 'profile.region.hongkong').toUpperCase()
    }
  }

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <Container>
          <Spacer height="2.222rem" />
          <Row>
            <BackButton onClick={onClickBack} />
            <Title>{tt(intl, 'profile.personalinformation')}</Title>
          </Row>
          <Spacer height="1.778rem" />
          <Input
            value={firstName}
            placeholder={tt(intl, 'common.firstname')}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Spacer height="0.889rem" />
          <Input
            value={lastName}
            placeholder={tt(intl, 'common.lastname')}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          <div>
            <Section>
              <ItemRow>
                <ItemTitle>{tt(intl, 'common.email')}</ItemTitle>
                <ItemEditButton
                  exist={!!profile.email}
                  onClick={onClickEditEmail}
                />
              </ItemRow>
              <ItemRow>
                <Row>
                  <ItemText>{profile.email}</ItemText>
                  <Spacer width="8px" />
                  {profile.email_validated && (
                    <Icon
                      renderImage={() => <VerifiedSvg />}
                      width="1.111rem"
                      height="1.111rem"
                    />
                  )}
                </Row>
              </ItemRow>
            </Section>
            <Section>
              <ItemRow>
                <ItemTitle>{tt(intl, 'common.mobilenumber')}</ItemTitle>
                <ItemEditButton
                  exist={!!profile.tel}
                  onClick={onClickEditMobile}
                />
              </ItemRow>
              <ItemRow>
                <Row>
                  <ItemText>{parsePhoneNumber(profile.tel).formatted}</ItemText>
                  <Spacer width="8px" />
                  {profile.tel_validated && (
                    <Icon
                      renderImage={() => <VerifiedSvg />}
                      width="1.111rem"
                      height="1.111rem"
                    />
                  )}
                </Row>
              </ItemRow>
            </Section>
            <Section>
              <ItemRow>
                <ItemTitle>{tt(intl, 'common.idcard')}</ItemTitle>
                <ItemAddButton
                  exist={
                    !!profile.nationalID ||
                    (identity?.customerID && identity?.statue !== 'processing')
                  }
                  onClick={onClickEditIdCard}
                />
              </ItemRow>
              <ItemRow>
                <Row>
                  <ItemText>{profile.nationalID || identity?.status}</ItemText>
                </Row>
              </ItemRow>
            </Section>
            <Section>
              <ItemRow>
                <ItemTitle>{tt(intl, 'profile.address')}</ItemTitle>
                <ItemEditButton
                  exist={
                    !!(
                      billingAddress?.address1 ||
                      billingAddress?.address2 ||
                      billingAddress?.district ||
                      billingAddress?.country
                    )
                  }
                  onClick={onClickEditAddress}
                />
              </ItemRow>
              <ItemRowAddress>
                <ItemText>
                  {joinAddress([
                    billingAddress?.address1,
                    billingAddress?.address2,
                    billingAddress?.district,
                    renderCountryName(billingAddress?.country),
                  ])}
                </ItemText>
              </ItemRowAddress>
            </Section>
            {/* <Section>
              <ItemRow>
                <ItemTitle>{tt(intl, 'profile.shippingaddress')}</ItemTitle>
                <ItemAddButton onClick={() => showDeliveryAddress(true)} />
              </ItemRow>
              {shippingAddresses.map((address) => (
                <ItemRowMultiAddress key={address.ID}>
                  <ItemRowAddress style={{ flex: 1 }}>
                    <ItemText>
                      {joinName([
                        address.contactFirstname,
                        address.contactLastname,
                      ])}
                    </ItemText>
                    <Spacer height="1.111rem" />
                    <ItemText>
                      {joinAddress([
                        address.address1,
                        address.address2,
                        address.district,
                        address.country,
                      ])}
                    </ItemText>
                    {address.contactNumber && (
                      <>
                        <Spacer height="1.111rem" />
                        <ItemText>
                          {tt(intl, 'common.phone')}:{' '}
                          {parsePhoneNumber(address.contactNumber).formatted}
                        </ItemText>
                      </>
                    )}
                    <Spacer height="1.111rem" />
                    <SetDefaultRow
                      onClick={() => onClickSetDefaultShippingAddress(address)}
                    >
                      <Checkbox
                        checked={defaultShippingAddressId === address.ID}
                      />
                      <Spacer width="0.889rem" />
                      <SetDefaultText>
                        {tt(intl, 'profile.shippingaddress.setdefault')}
                      </SetDefaultText>
                    </SetDefaultRow>
                    <Spacer height="1.111rem" />
                    <ItemButtons>
                      <ItemEditButton
                        exist
                        onClick={() => showDeliveryAddress(true, address)}
                      />
                      <Spacer width="0.889rem" />
                      <ItemTextButton
                        onClick={() => removeDeliveryAddress(address)}
                      >
                        {tt(intl, 'common.remove')}
                      </ItemTextButton>
                    </ItemButtons>
                  </ItemRowAddress>
                </ItemRowMultiAddress>
              ))}
            </Section> */}
          </div>
          <Spacer height="4.444rem" />
        </Container>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <Container>
          <ActionButtons>
            <CancelButton onClick={onClickBack}>
              {tt(intl, 'common.cancel')}
            </CancelButton>
            <MainButton onClick={updateContact}>
              {tt(intl, 'common.save')}
            </MainButton>
          </ActionButtons>
        </Container>
      </CSLayout.CSFooter>
      <ProfileEditEmailModal
        isOpen={isShowEditEmailModal}
        onClose={() => setIsShowEditEmailModal(false)}
      />
      <ProfileEditMobileModal
        isOpen={isShowEditMobileModal}
        onClose={() => setIsShowEditMobileModal(false)}
      />
      <ProfileAddressModal
        isOpen={isShowAddressModal}
        onClose={() => setIsShowAddressModal(false)}
      />
      <ProfileDeliveryAddressModal
        isOpen={showDeliveryAddressModal.isOpen}
        address={showDeliveryAddressModal.address}
        onClose={() => showDeliveryAddress(false)}
      />
    </CSLayout.CSContainer>
  )
}

export default ProfilePersonal
