import React, { useState } from 'react'
import { useIntl } from 'react-intl'
// import ReactSelect from 'react-select'
import styled from 'styled-components'

import BOCLogo from '@/assets/banks/boc-logo.png'
import CITILogo from '@/assets/banks/citi-logo.png'
import HSBLogo from '@/assets/banks/hsb-logo.png'
import HSBCLogo from '@/assets/banks/hsbc-logo.png'
import SCLogo from '@/assets/banks/sc-logo.png'
// import Icon from '@/components/Icon'
import SelectorTabs from '@/components/SelectorTabs2'
import Spacer from '@/components/Spacer'
import { FPS_ID_GUIDES, FPS_QR_GUIDES } from '@/constants/fpsBankGuides'
import PaymentSettings from '@/constants/payment'
import { useLocale } from '@/contexts/localeContext'
// import useBlurElement from '@/hooks/useBlurElement'
import useWindowSize from '@/hooks/useWindowSize'
import { tt } from '@/locales/format'

import FpsGuideSlideshow from './FpsGuideSlideshow'

// const FPS_GUIDE_KEY = 'divit:fps_guide'

const Container = styled.div`
  width: 100%;
  position: relative;
`

// const TitleContainer = styled.div`
//   text-align: center;

//   @media (min-width: 768px) {
//     max-width: var(--full-width-mode-max-width);
//     margin: 0 auto;
//     justify-content: flex-start;
//     text-align: left;
//   }
// `

// const TitleRow = styled.div`
//   position: relative;
//   display: inline-flex;
//   flex-flow: wrap;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   margin: 0 auto;
// `

// const PaymentGuideTitle = styled.div`
//   font-size: 1.111rem;
//   font-weight: 500;
// `

// const ExpandIcon = styled(Icon)`
//   ${(p) =>
//     p.isExpanded &&
//     css`
//       transform: rotate(180deg);
//     `}
// `

// const PaymentGuideBank = styled.div`
//   font-size: 1.111rem;
//   font-weight: 700;
//   margin-left: 0.222rem;
// `

const SlideshowContainer = styled.div`
  max-width: 768px;
`

const GridContainter = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 169px);
  justify-content: space-between;
  row-gap: 25px;
  column-gap: 4rem;

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.md}px`}) {
    column-gap: 2rem;
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.sm}px`}) {
    column-gap: calc(100% / 20);
  }

  @media (max-width: ${({ theme }) => `${theme?.breakpoints?.xs}px`}) {
    column-gap: calc(100% / 20);
  }
`

const GridItem = styled.div`
  text-align: center;
`

const GridItemImage = styled.img`
  width: 169px;
  height: auto;
`

const GridItemText = styled.div`
  width: 169px;
  font-size: 0.667rem;
`

const BANK_OPTIONS = [
  {
    label: 'Citi',
    value: 'CITI',
    en: 'Citibank',
    'zh-hk': '花旗銀行',
    'zh-cn': '花旗银行',
    Icon: CITILogo,
  },
  {
    label: 'HSBC',
    value: 'HSBC',
    en: 'HSBC',
    'zh-hk': '滙豐銀行',
    'zh-cn': '汇丰银行',
    Icon: HSBCLogo,
  },
  {
    label: 'Hang Seng Bank',
    value: 'HSB',
    en: 'Hang Seng Bank',
    'zh-hk': '恒生銀行',
    'zh-cn': '恒生银行',
    Icon: HSBLogo,
  },
  {
    label: 'Standard Chartered Bank',
    value: 'SC',
    en: 'Standard Chartered Bank',
    'zh-hk': '渣打銀行',
    'zh-cn': '渣打银行',
    Icon: SCLogo,
  },
  {
    label: 'BOC',
    value: 'BOC',
    en: 'Bank of China',
    'zh-hk': '中國銀行',
    'zh-cn': '中國銀行',
    Icon: BOCLogo,
  },
]

// const defaultSelectStyles = {
//   container: (provided) => ({
//     ...provided,
//     position: 'absolute',
//     width: '15rem',
//     marginTop: '1.333rem',
//   }),
//   menu: (provided) => ({
//     ...provided,
//     fontSize: '0.778rem',
//   }),
//   control: (provided) => ({
//     ...provided,
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     height: 48,
//     backgroundColor: state.isSelected ? '#FFFAEA' : 'white',
//     fontSize: '0.778rem',
//     fontWeight: 500,
//     color: '#1c1c1c',
//     textAlign: 'center',
//     padding: '12px 12px',
//     cursor: 'pointer',
//   }),
//   placeholder: (provided) => ({
//     ...provided,
//   }),
//   valueContainer: (provided) => ({
//     ...provided,
//     paddingLeft: '0.675rem',
//     cursor: 'pointer',
//   }),
//   singleValue: (provided, state) => ({
//     ...provided,
//     opacity: state.isDisabled ? 0.5 : 1,
//     transition: 'opacity 300ms',
//   }),
// }

// const getBankDefaultOption = () => {
//   const defaultOptionValue = localStorage.getItem(FPS_GUIDE_KEY)
//   const option = BANK_OPTIONS.find((opt) => opt.value === defaultOptionValue)
//   return option || BANK_OPTIONS[0]
// }

// const MethodTitleDropdown = ({ onChange }) => {
//   const intl = useIntl()
//   const { currentLocale } = useLocale()

//   const [bankOption, setBankOption] = useState(() => getBankDefaultOption())
//   const [isExpanded, setIsExpanded] = useState(false)

//   useBlurElement('.bank-guide-field', () => {
//     setIsExpanded(false)
//   })

//   const toggleExpanded = () => {
//     setIsExpanded(!isExpanded)
//   }

//   const onChangeSelect = (option) => {
//     localStorage.setItem(FPS_GUIDE_KEY, option.value)
//     setBankOption(option)
//     onChange(option.value)
//   }

//   const options = BANK_OPTIONS.map((option) => ({
//     ...option,
//     label: option[currentLocale] || option.label,
//   }))

//   return (
//     <TitleContainer>
//       <TitleRow className="bank-guide-field" onClick={toggleExpanded}>
//         <PaymentGuideTitle>{tt(intl, 'fps.guide.for')}</PaymentGuideTitle>
//         <PaymentGuideBank>{bankOption.en}</PaymentGuideBank>
//         <ExpandIcon
//           isExpanded={isExpanded}
//           renderImage={() => <DownArrowLargeSvg />}
//           width="0.889rem"
//           height="0.444rem"
//           wrapperWidth="1.778rem"
//           wrapperHeight="1.778rem"
//         />
//         <ReactSelect
//           components={{ Control: () => null }}
//           styles={defaultSelectStyles}
//           isLoading={false}
//           isSearchable={false}
//           cacheOptions
//           defaultOptions
//           onChange={onChangeSelect}
//           options={options}
//           menuIsOpen={isExpanded}
//         />
//       </TitleRow>
//     </TitleContainer>
//   )
// }

export const FpsQRPaymentGuideDesktop = ({ type, bank, locale }) => {
  const intl = useIntl()

  let guides = []
  if (type === PaymentSettings.PAYMENT_MODE_FPSMANUAL) {
    guides = FPS_ID_GUIDES[bank]
    guides = guides[locale] || guides.en
  } else {
    guides = FPS_QR_GUIDES[bank]
    guides = guides.desktop[locale] || guides.desktop.en
  }

  return (
    <GridContainter>
      {guides.map((guide) => (
        <GridItem key={guide.key}>
          <GridItemImage src={guide.img} />
          <Spacer height="8px" />
          <GridItemText>{tt(intl, guide.text)}</GridItemText>
        </GridItem>
      ))}
    </GridContainter>
  )
}

export const FpsQRPaymentGuideMobile = ({ type, bank, locale }) => {
  let guides = []
  if (type === PaymentSettings.PAYMENT_MODE_FPSMANUAL) {
    guides = FPS_ID_GUIDES[bank]
    guides = guides[locale] || guides.en
  } else {
    guides = FPS_QR_GUIDES[bank]
    guides = guides.mobile[locale] || guides.desktop.en
  }

  return (
    <SlideshowContainer>
      <FpsGuideSlideshow guides={guides} />
    </SlideshowContainer>
  )
}

const FpsPaymentGuide = ({ type, onClose }) => {
  const { currentLocale } = useLocale()
  const { width } = useWindowSize()
  const isDesktop = width >= 768

  const [bank, setBank] = useState(BANK_OPTIONS[0].value)

  return (
    <Container>
      <SelectorTabs
        defaultTab="CITI"
        tabs={BANK_OPTIONS}
        onChange={(b) => setBank(b.value)}
      />
      {isDesktop ? (
        <>
          <Spacer height="1.889rem" />
          <FpsQRPaymentGuideDesktop
            type={type}
            bank={bank}
            locale={currentLocale}
          />
        </>
      ) : (
        <>
          <Spacer height="1.333rem" />
          <FpsQRPaymentGuideMobile
            type={type}
            bank={bank}
            locale={currentLocale}
          />
        </>
      )}
    </Container>
  )
}

export default FpsPaymentGuide
