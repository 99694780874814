import React from 'react'
import styled from 'styled-components'

import { ReactComponent as FailIconSvg } from '@/assets/common/fail.svg'
import Icon from '@/components/Icon'
import PaddingContent from '@/components/layout/PaddingContent'

const MessageBox = styled(PaddingContent)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 0.778rem;
  color: #e6171a;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.889rem;
  background-color: rgba(230, 23, 26, 0.1);
  border-radius: 8px;
`

const Message = styled.span`
  flex: 1;
  margin-right: 0.889rem;
  font-size: 0.778rem;
  font-weight: 700;
`

const ListItem = styled.div`
  margin-bottom: 0.2rem;
`

const ErrorMessageBox = ({ errorMessage, ...props }) => {
  if (!errorMessage) return <></>

  const message = Array.isArray(errorMessage)
    ? errorMessage.map((e, i) => <ListItem key={i}>{e}</ListItem>)
    : errorMessage

  return (
    <MessageBox {...props}>
      <Message>{message}</Message>
      <Icon
        renderImage={() => <FailIconSvg />}
        width="1rem"
        height="0.874rem"
      />
    </MessageBox>
  )
}

export default ErrorMessageBox
