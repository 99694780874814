import { ReactComponent as AsiaMilesSvg } from '@/assets/miles/asiamiles.svg'
import AsiaMilesPng from '@/assets/miles/merchants/asiamiles.png'
import CathayHPng from '@/assets/miles/merchants/cathay-h-logo.png'
import CathayPng from '@/assets/miles/merchants/cathay-logo.png'
import YouairHPng from '@/assets/miles/merchants/youair-h-logo.png'
import YouairPng from '@/assets/miles/merchants/youair-logo.png'
import { ReactComponent as YouairSvg } from '@/assets/miles/youair.svg'
import { isDevEnv } from '@/utils/env'

const MilesPrograms = [
  {
    key: 'asiamiles',
    companyName: 'Cathay',
    companyHLogo: CathayHPng,
    companyLogo: CathayPng,
    name: 'Asia Miles',
    pointName: 'Asia Miles',
    currencyCode: 'ASM',
    Icon: AsiaMilesSvg,
    image: AsiaMilesPng,
  },
  isDevEnv
    ? {
        key: 'youair',
        companyName: 'YouAir',
        companyHLogo: YouairHPng,
        companyLogo: YouairPng,
        name: 'youair',
        pointName: 'youair point',
        currencyCode: 'YAM',
        Icon: YouairSvg,
        image: YouairPng,
      }
    : null,
].filter((m) => m)

export const getProgramImageUrl = (program) =>
  `${process.env.REACT_APP_STATIC_URL}/miles/logo-${program}.png`

export default MilesPrograms
