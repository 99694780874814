import moment from 'moment'
import { createSelector } from 'reselect'

import { getOutstandingInstalment } from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import * as OrderPaynow from '@/utils/OrderPaynow'

// helpers

// earliest due date first
const sortByDueDate = (orders) =>
  orders.sort((a, b) => {
    const ia = getOutstandingInstalment(a.instalments)
    const ib = getOutstandingInstalment(b.instalments)
    return moment.unix(ia.dueDate).isBefore(moment.unix(ib.dueDate)) ? -1 : 1
  })

// selectors

// current order / order details
export const orderSelector = (s) => s.order.order

export const paynowOrdersSelector = (s) => s.order.paynowOrders || []

export const ordersSelector = (s) => s.order.orders || []

export const hasPayLaterOrderSelector = createSelector(
  ordersSelector,
  (orders) =>
    orders.filter(
      (o) => !(Order.isNew(o) || Order.isExpired(o) || Order.isCancelled(o))
    ).length > 0
)

export const hasPayNowOrderSelector = createSelector(
  paynowOrdersSelector,
  (paynowOrders) => paynowOrders.length > 0
)

export const hasOrderSelector = createSelector(
  hasPayLaterOrderSelector,
  hasPayNowOrderSelector,
  (hasPayLaterOrder, hasPayNowOrder) => hasPayLaterOrder || hasPayNowOrder
)

export const payNowProcessOrderSelector = createSelector(
  paynowOrdersSelector,
  (paynowOrders) =>
    paynowOrders.filter((o) => o.milesBurned > 0 || OrderPaynow.isUnderPay(o))
)

export const scheduledOrdersSelector = createSelector(
  ordersSelector,
  (orders) => {
    const pendingRefundOrders = orders.filter((o) =>
      Order.hasRequestedRefund(o)
    )
    const activeOrders = orders.filter((order) => Order.isActive(order))
    const sortedActiveOrders = sortByDueDate(activeOrders)
    return sortedActiveOrders.concat(pendingRefundOrders)
  }
)

export const mainOrderSelector = createSelector(
  scheduledOrdersSelector,
  (orders) => orders[0]
)

export const paylaterActiveOrdersSelector = createSelector(
  ordersSelector,
  (orders) => {
    const activeOrders = orders.filter((order) => Order.isActive(order))
    return sortByDueDate(activeOrders)
  }
)

export const paylaterActiveOrdersShortSelector = createSelector(
  paylaterActiveOrdersSelector,
  (orders) => orders.slice(0, 3)
)

export const upcomingOrdersByMonthSelector = createSelector(
  paylaterActiveOrdersSelector,
  (orders) =>
    orders.reduce((obj, order) => {
      const instalment = getOutstandingInstalment(order.instalments)
      const due = moment.unix(instalment.dueDate)
      // all late payment should be included in current month
      const mark = due.isBefore(moment()) ? moment() : due
      const month = mark.format('YYYY-MM')
      obj[month] = obj[month] || []
      obj[month].push(order)
      return obj
    }, {})
)

export const historyOrdersSelector = createSelector(ordersSelector, (orders) =>
  orders.filter(
    (order) => !Order.isNewCreated(order) && !Order.isExpired(order)
  )
)

export const filteredHistoryOrdersSelector = (status) =>
  createSelector(historyOrdersSelector, (orders) => {
    const list = orders.filter((order) => {
      const hasPendingRefund = Order.hasRequestedRefund(order)
      const complete = Order.isCompleted(order) && !hasPendingRefund
      const cancelled = Order.isCancelled(order) && !hasPendingRefund
      const cancelledWithoutPaid = Order.isCancelledWithoutPaid(order)
      const expired = Order.isExpired(order)
      return (
        (status === 'all' && !cancelledWithoutPaid) ||
        (status === 'current' && !complete && !cancelled && !expired) ||
        (status === 'paid' && complete)
      )
    })
    // ordering: failed, processing, overdue, active, refund pending, others
    list.sort((a, b) =>
      Order.hasRequestedRefund(a) && !Order.hasRequestedRefund(b) ? -1 : 1
    )
    list.sort((a, b) => (Order.isActive(a) && !Order.isActive(b) ? -1 : 1))
    list.sort((a, b) => (Order.isOverdue(a) && !Order.isOverdue(b) ? -1 : 1))
    list.sort((a, b) =>
      Order.isProcessing(a) && !Order.isProcessing(b) ? -1 : 1
    )
    list.sort((a, b) => (Order.isFailed(a) && !Order.isFailed(b) ? -1 : 1))

    // for active order, sort by latest instalment due date
    list.sort((a, b) => {
      if (Order.isActive(a) && Order.isActive(b)) {
        const instalmentA = getOutstandingInstalment(a.instalments)
        const instalmentB = getOutstandingInstalment(b.instalments)
        return instalmentA.dueDate - instalmentB.dueDate
      }
      return 1
    })

    return list
  })

export const overdueOrdersSelector = createSelector(ordersSelector, (orders) =>
  orders.filter((order) => Order.isOverdue(order))
)

export const overdueFilteredHistoryOrdersSelector = (status) =>
  createSelector(filteredHistoryOrdersSelector(status), (orders) =>
    orders.filter((order) => Order.isOverdue(order))
  )

export const processingFilteredHistoryOrdersSelector = (status) =>
  createSelector(filteredHistoryOrdersSelector(status), (orders) =>
    orders.filter((order) => Order.isProcessing(order) || Order.isFailed(order))
  )

export const paymentsSelector = (state) => state.order.payments
