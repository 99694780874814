import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  position: relative;
  cursor: pointer;

  ${({ active, enableHightlight }) =>
    enableHightlight &&
    `
      & svg path {
        fill: ${active ? '#FFCC33' : '#979797'};
      }
    `}
`

const BgContainer = styled.div`
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const BgHighlight = styled.div`
  width: 2.667rem; /* 48px; */
  height: 3.611rem; /* 65px; */
  background-color: #fffaea;
  border-top: 4px solid #ffcc33;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 4.722rem; /* 85px; */
  }
`

const Content = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const Text = styled.div`
  font-weight: 700;
  font-size: 13px;
  margin-top: 8px;
  color: ${({ active }) => (active ? '#1C1C1C' : '#979797')};
`

const FooterItem = ({
  icon,
  label,
  labelStyle,
  onClick,
  active,
  enableHightlight = true,
  ...props
}) => (
  <Container
    active={active}
    enableHightlight={enableHightlight}
    onClick={onClick}
    {...props}
  >
    {active && enableHightlight && (
      <BgContainer>
        <BgHighlight />
      </BgContainer>
    )}
    <Content>
      {icon}
      <Text style={labelStyle} active={active}>
        {label}
      </Text>
    </Content>
  </Container>
)

FooterItem.defaultProps = {
  icon: <></>,
  label: '',
  onClick: () => {},
  active: false,
}

export default FooterItem
