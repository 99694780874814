import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Instalment1Svg } from '@/assets/paylater/instalment-1.svg'
import { ReactComponent as Instalment2Svg } from '@/assets/paylater/instalment-2.svg'
import { ReactComponent as Instalment3Svg } from '@/assets/paylater/instalment-3.svg'
import Icon from '@/components/Icon'

const IconWrapper = styled(Icon)`
  & svg path,
  & svg text {
    fill: ${(p) => (p.isActive ? '#1c1c1c' : '#DEDEDE')};
  }
  & svg g#Ellipse_177 {
    stroke: ${(p) => (p.isActive ? '#1c1c1c' : '#DEDEDE')};
  }
`

const seriesToIcons = {
  1: Instalment1Svg,
  2: Instalment2Svg,
  3: Instalment3Svg,
}

const InstalmentIcon = ({ className, series, isActive, width, height }) => {
  const SeriesIcon = seriesToIcons[series]
  return (
    <IconWrapper
      className={className}
      isActive={isActive}
      renderImage={() => <SeriesIcon />}
      width={width}
      height={height}
    />
  )
}

export default InstalmentIcon
