import React from 'react'
import { useIntl } from 'react-intl'

import ErrorScreen from '@/components/error/ErrorScreen'
import { tt } from '@/locales/format'

// it is outside all provider, if you need any provider,
// you need to add it to wrap this component
const Error = () => {
  const intl = useIntl()

  const goHome = (e) => {
    e.preventDefault()
    document.location.href = '/'
  }

  return (
    <ErrorScreen
      title={tt(intl, 'common.aiyah')}
      content={tt(intl, 'common.somethingwentwrong')}
      onOk={goHome}
    />
  )
}

export default Error
