import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import styled from 'styled-components'

import { useLocale } from '@/contexts/localeContext'
import { tt } from '@/locales/format'

import CompletedStatus from './CompletedStatus'

const Container = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eff0f1;
  cursor: pointer;
  gap: 1rem;

  @media (min-width: 480px) {
    padding: 1rem;
    gap: 1rem;
  }
`

const Content = styled.div`
  width: 78%;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
    width: 80%;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding-left: 0.2rem;
`

const SubTitle = styled.div`
  font-size: 16px;
  margin-top: 0.2rem;
`

const SelectStyles = {
  menu: (provided) => ({
    ...provided,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.16)',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '200px',
  }),
  option: (provided, state) => ({
    ...provided,
    height: 48,
    backgroundColor: state.isSelected ? '#FFFAEA' : 'white',
    color: '#1c1c1c',
    padding: '12px 12px',
    cursor: 'pointer',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#dddddd',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
}

const CountrySelect = styled(Select)`
  > div {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.3rem;
    background: white;
    border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
    border-radius: 0.44rem;
    box-shadow: none !important;

    &:hover {
      border-color: #f7ce55;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: #f7ce55;
    }
  }
`

const CountryOfBirthVerify = ({ form, countryList }) => {
  const intl = useIntl()
  const { currentLocale } = useLocale()
  const { watch, setValue } = form

  const onChangeSelect = (e) => {
    setValue('countryOfBirth', e.value)
  }

  const checkVerify = (email) => {
    if (email) {
      return 'verified'
    }
    return 'unverified'
  }

  const countryOptions = () => {
    const options = []
    _.forOwn(countryList, (value, key) =>
      options.push({
        value: value?.en,
        label:
          currentLocale === 'en'
            ? value?.[currentLocale]
            : `${value?.[currentLocale]} (${value?.en})`,
      })
    )
    return options
  }

  return (
    <>
      <Container>
        <Content>
          <Title>{tt(intl, 'setupPayment.countryOfBirth')}</Title>
          <SubTitle>
            <CountrySelect
              styles={SelectStyles}
              isLoading={false}
              isSearchable
              onChange={onChangeSelect}
              options={countryOptions()}
              menuPlacement="auto"
              placeholder={tt(intl, 'common.validation.required')}
            />
          </SubTitle>
        </Content>
        <CompletedStatus status={checkVerify(watch('countryOfBirth'))} />
      </Container>
    </>
  )
}

export default CountryOfBirthVerify
