/* eslint-disable */
/* add this style for frontend only

<style>
*{ line-height: 1.25; }
body { padding: 0 1rem; }
</style> 
*/

const html = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style>
      *{ line-height: 1.25; }
      body { padding: 0 1rem; }
   </style> 
  </head>
  <body class="c28" style="background-color: #ffffff;max-width: 500pt;padding: 20pt 30pt 30pt 30pt;">
    <div>
      <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: left;height: 11pt;">
        <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;"></span>
      </p>
    </div>
    <p class="c22" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">支付贷款协议：</span>
    </p>
    <p class="c22" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">条款和细则</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c5" style="font-weight: 700;">请仔细阅读本协议</span>
      <span>。本协议包含与</span>
      <span class="c5" style="font-weight: 700;">DIVIT LIMITED</span>
      <span>（在本协议中称为</span>
      <span class="c5" style="font-weight: 700;">"Divit</span>
      <span>" ） 的贷款协议的条款和细则。 Divit基于此条款和细则同意向您即借款人（在本文件中称为"您"或"您的"）购买的某些商品和/或服务（"</span>
      <span class="c5" style="font-weight: 700;">条款和细则</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"）贷出款项。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>在 Divit 贷出您要求的金额之前，您必须完全同意条款和细则。如果您于 Divit 应用程序中剔选表示同意条款和细则的方格，然后点击"</span>
      <span class="c24" style="font-style: italic;">下一步</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"以确认同意继续，即表示您通过电子方式签署本协议并确认条款和细则于您与 Divit 之间具有约束力。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本协议附有《放债人条例》（香港法例第 163 章）的撮要。在订立本协议前，请确保您已仔细阅读该撮要。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本协议记录了贷款条款和您的合约责任，因此它是一份重要文件。本协议签署版本的副本会在您确认同意接受条款和细则后供您下载。 Divit亦会发送一份本协议的副本至您提供的电邮地址，以供记录。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>1 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">日期</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>1.1 本协议由您和Divit订立于：</span>
      <span class="">{{(index (index .Instalments 0).PaidRecords 0).PaidDate}}</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>1.2 本协议的贷款的贷出日期：</span>
      <span class="">{{(index (index .Instalments 0).PaidRecords 0).PaidDate}}</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>2 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">放债人详情</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">2.1 Divit Limited为本协议的放债人。 Divit Limited是一间根据香港法律注册成立的有限公司（注册编号：2869832），并持有放债人牌照（编号：{{.LicenceNumber}}）。 Divit Limited的注册办事处位于香港西营盘威利麻街6号威华商业中心25楼2505室。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>3 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">借款人详情</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">3.1 您是本协议项下的借款人。您的姓名和地址如下：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(a) 姓名：</span>
      <span class="">{{.Recipient.FirstName}} {{.Recipient.LastName}}</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;"></span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(b) 地址：</span>
      <span class="">{{.Recipient.Address}}</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;"></span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>4 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">贷款详情</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>4.1 Divit同意以贷款的方式向您贷出款项，并且您同意向Divit借款，贷款本金金额是</span>
      <span class="">{{.Order.LoanAmountInWords}} ({{.Order.LoanAmount}})</span>
      <span>（ "</span>
      <span class="c5" style="font-weight: 700;">您的贷款金额</span>
      <span>" ）并在所有情况下均受本协议（ "</span>
      <span class="c5" style="font-weight: 700;">贷款</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">" ）的条款和细则约束。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">4.2 Divit在收到您的第一笔根据下文第6段的付款后向您提供您的贷款金额。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">4.3 您同意并在此确认：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(a) Divit不会直接向您支付您的贷款金额而会代表您支付您的购买款项予参与商户;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(b) 当Divit向参与商户支付您的贷款金额即代表Divit已履行 Divit根据本协议向您贷出您的贷款金额的义务。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>4.4 如果您的购买金额超过您的贷款金额，您要求Divit而且同意Divit代表您于支付您的贷款金额的同一天向参与商户转账您的购买金额与您的贷款金额之间的差额（ "</span>
      <span class="c5" style="font-weight: 700;">超额金额</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"）。您同意并确认：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(a) 超额金额不构成贷款的一部分;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(b) 您将在购买当日透过任何一种认可的付款方式（定义见下文第7.1段）向Divit转账与超额金额相等的金额;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(c) 您将在购买当日向Divit支付转账服务费</span>
      <span class="">{{.Order.TransferServiceFeeInWords}} ({{.Order.TransferServiceFee}})</span>
      <span> （ "</span>
      <span class="c5" style="font-weight: 700;">转账服务费</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">" ），以作为Divit代表您向参与商户转账超额金额的服务费。为免产生疑问，此转账服务费不会构成客户服务费（定义见下文第5.1段）或贷款利息的一部分。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">4.5 在本协议中：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(a) "</span>
      <span class="c5" style="font-weight: 700;">参与商户</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"指通过其网站向您提供向Divit 贷款购买的机会，并且为贷款事宜将您导向至Divit应用程序的商户;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(b) "</span>
      <span class="c5" style="font-weight: 700;">购买</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"指您同意向参与商户购买的商品和/或服务所支付的金额。该金额相等于您的贷款金额及超额金额总和;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(c) "</span>
      <span class="c5" style="font-weight: 700;">Divit应用程序</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"是指由Divit开发和运营的流动应用程序，以处理用户向Divit 贷款购买参与商户的产品和/或服务所需的金额;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(d) "港元"和"港币"是指香港的法定货币。</span>
    </p>
    <p class="c8 c13" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;height: 11pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;"></span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>5 .</span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">利息</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>5.1基于Divit与您订立的本协议，您同意向Divit支付"</span>
      <span class="c5" style="font-weight: 700;">客户服务费</span>
      <span>"。客户服务费为：</span>
      <span class="">{{.Order.LoanServiceFeeInWords}} ({{.Order.LoanServiceFee}})</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>5.2客户服务费代表贷款金额的利息，年息百分率为：</span>
      <span class="">{{.Order.APRateInWords}} ({{.Order.APRate}})</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">。该利率是根据《放债人条例》（香港法例第163章）附表2 及假设您根据下文第6.1段所列的拟议付款时间表付款作出计算。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>6 .</span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;"> 还款条款</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>6.1 您同意根据以下时间表（"</span>
      <span class="c5" style="font-weight: 700;">付款时间表</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"）向Divit支付三笔款项，以偿还贷款、支付客户服务费、（如适用）超额金额和转账服务费：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(a) 第1次付款：</span>
      <span class="">{{(index .Instalments 0).Amount}}，</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">于您订立本协议当日到期并应支付;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(b) 第2次付款：</span>
      <span class="">{{(index .Instalments 1).Amount}}，</span>
      <span>您已提议在</span>
      <span class="">{{(index .Instalments 1).ReminderDate}}</span>
      <span>支付，但在 </span>
      <span class="">{{(index .Instalments 1).DueDate}}</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;"> 到期并须在该天支付;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(c) 第3次付款：</span>
      <span class="">{{(index .Instalments 2).Amount}}，</span>
      <span>您已提议在</span>
      <span class="">{{(index .Instalments 2).ReminderDate}}</span>
      <span>支付，但在 </span>
      <span class="">{{(index .Instalments 2).DueDate}}</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;"> 到期并须在该天支付，</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>6.2 您提议支付每笔款项的日期在本协议中称为"</span>
      <span class="c5" style="font-weight: 700;">付款日</span>
      <span>"，及每笔款项指定到期付款日称为"</span>
      <span class="c5" style="font-weight: 700;">到期日</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">6.3 如果：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(a) 任何付款日为非营业日，该款项应在紧接的营业日支付;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(b) 任何到期日为非营业日该款项应在紧接的下一个营业日到期并支付。</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>就本协议而言，</span>
      <span class="c5" style="font-weight: 700;">"营业日"</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">是指除星期六、星期日或公众假期外，商业银行在香港营业的日子。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">6.4 您同意Divit可自行决定分配每笔款项中的本金和应付利息比例。您明白及确认每笔款项中的本金和应付利息比例可能不相等。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">6.5 除非是按照付款时间表进行的还款，Divit不接受部分贷款款项的还款。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">6.6 除非Divit行使酌情权并书面同意，Divit不接受任何更改到期日的要求。为免产生疑问，当 Divit书面同意您可以在到期日之前还款，您同意并确认：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(a) 此等自愿提前还款并不会影响贷款条款;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(b) 该贷款的利率计算仍将按照您在上文第6.1段中提出的付款时间表所决定，而在上文第5.1段列明的年息百分率将保持不变，不论您是否已在到期日之前偿还相关的未偿还款项。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>7 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">付款</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">7.1 除非Divit另有书面同意，您同意并承诺使用以下方式支付本协议内的所有款项（包括但不限于第6.1段内的每笔款项及第11段内的任何行政费）：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(a) 由香港银行同业结算有限公司营运的快速支付系统</span>
      <span class="c19" style="background-color: #ffffff;color: #4e4e4e;">（「转数快」）;</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">或</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(b) 由香港上海汇丰银行营运的PayMe。</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span>各种付款方式均称为"</span>
      <span class="c5" style="font-weight: 700;">认可的付款方式</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">"。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">7.2 除认可的付款方式外，Divit可不时但没有义务通知您有关其他可接受的付款方式。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>8 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">资格</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">8.1 您向Divit声明并保证：</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(a) 您是以个人名义而不是为或代表任何其他人订立本协议;</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(b) 您是年满18岁并居住于香港的香港居民;</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(c) 您拥有有效的电邮地址，和由香港电信运营商发出的有效手机号码;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(d) 您拥有可链接的认可付款方式及有效的帐户或信用卡，而您可以使用该付款方式根据协议向Divit付款;</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(e) 您心智健全，有能力订立本协议并履行您在本协议中的义务;</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(f) 您就本协议向Divit提供的所有信息在所有层面均为真实、完整和准确;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(g) 您未曾与除Divit以外的任何人就本协议中采购、相议、获取或申请贷款及/或担保或就确保任何还款达成或签署任何协议;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(h) 您有还款能力，目前没有破产、进行诉讼或行政程序，或据您所知，您没有与任何其他贷款人达成任何可能会影响您偿还本协议中所有款项的任何协议;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(i) 在您根据本协议的条款全额支付您的欠款之前，您不会自愿提交任何破产申请。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">8.2 您在上文第8.1段作出的每项声明和保证均由您在订立本协议当日作出，并在参考相关的事实和情况后，被视作在本协议期限内一直重复，直至您根据本协议向Divit全数并不可撤销地支付所有款项为止。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">8.3 您同意并确认：</span>
    </p>
    <p class="c8 c12" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;text-indent: 36pt;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(a) 您将向Divit提供Divit不时合理地要求与本协议相关的所有信息;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(b) 如果您之前提供给Divit的信息有任何更改，您将立即通知Divit，</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">对于因您未能提供任何此类信息或未将任何此类更改通知Divit而对您造成的任何损失或费用，Divit概不负责。 </span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">8.4 您授权Divit在符合适用的法律或法规要求下及在适当的情况下检查、验证和进行风险评估，并直接或通过第三方进行任何必要的查询，以验证您的身分及评估您在本协议中的付款能力。您进一步同意并授权Divit与任何参与商户及/或任何其他第三方分享与本协议的订立或履行有关及/或必要的信息。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>9 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">洽商和协议地点</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">9.1 您确认本协议的洽商和协议地点为Divit的注册办事处：香港西营盘威利麻街6号威华商业中心25楼2505室。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>10 . </span>
      <span class="c5 c16" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">放债人条例撮要</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">10.1 您确认您已阅读本协议附件所载的放债人条例撮要并了解其规定。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>11 . </span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">逾期付款</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">11.1 如果您未能按照付款时间表在于到期日前付款，在不影响Divit在下文第13 段的权利的情况下：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">(a) 在您未能付款后的至少五（ 5 ）的历日内，每天通知将发送到您已注册的电邮地址，并通过短讯发送到您提供给Divit的手机号码，以提醒您的付款义务;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span>(b) 如果任何款项超过五（ 5 ）的历日仍未支付（ "</span>
      <span class="c5" style="font-weight: 700;">逾期款项</span>
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">" ），Divit有权在逾期款项仍未偿还的情况下，就每笔逾期款项向您收取港币100元，作为安排收取逾期款项的行政费。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">11.2 您同意Divit可以在未通知您或未经您同意，及无论是否已发生违约事件的情况下，任命和雇用第三方收账公司就您欠Divit的任何未偿还款项采取和执行任何行动。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">12 .</span>
      <span class="c18 c0 c5" style="text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;font-weight: 700;">抵销</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">12.1 您同意Divit除了在法律上可能享有的任何一般留置权或类似权利外，Divit可能随时在</span>
      <span>没有提前通知的情况下，</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">合并您在债务上的利益或</span>
      <span class="c14" style="background-color: #ffffff;color: #4d5156;">整合</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">Divit持有的任何</span>
      <span>款项</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">，及</span>
      <span>抵销或</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">转移任何一笔或多于一笔的</span>
      <span>款项</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">以偿还您对Divit的任何负债。如果您对Divit的任何负债</span>
      <span class="c4" style="color: #202124;">涉及有待确定</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">或将来事件</span>
      <span class="c4" style="color: #202124;">，Divit对您支付</span>
      <span class="c20" style="background-color: #ffffff;color: #3c4043;">足以覆盖负债</span>
      <span class="c4" style="color: #202124;">的任何一笔或多于一笔的款项的责任</span>
      <span class="c20" style="background-color: #ffffff;color: #3c4043;">应暂缓直至到</span>
      <span class="c23" style="color: #3c4043;">有待确定</span>
      <span class="c9 c20" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #3c4043;">或未来事件发生为止。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">13 .</span>
      <span class="c16 c5" style="color: #000000;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">违约</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">13.1如果发生以下任何事件（均称为"</span>
      <span class="c0 c5" style="background-color: #ffffff;color: #202124;font-weight: 700;">违约事件</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">" ），Divit </span>
      <span class="c4" style="color: #202124;">可随时向您声明</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">您的贷款的任何和所有未偿还金额，加上所有应计但未支付利息、行政费、可报销费用和您根据本协议对Divit的其他债项立即到期并需支付：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 您未能在到期日按本协议规定的方式支付您应在相关到期日支付的任何款项;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(b) 您未能适当履行您在本协议除上文第6.1 </span>
      <span>段</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">中提及的您的付款义务外的任何义务并且无法补救此类未能履行</span>
      <span class="c4" style="color: #202124;">义务</span>
      <span>。</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">如果未能履行</span>
      <span class="c4" style="color: #202124;">义务可能被</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">补救，则在Divit向您发送未能履行义务通知后的七（7）个历日内未补救，或者Divit认为您违反了本协议的任何规定;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(c) 您在本协议中或以任何形式或文件提供给Divit的任何陈述、保证或声明，在作出或重复时在任何</span>
      <span class="c4" style="color: #202124;">层面上是不正确</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">或具有误导性;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(d) 您是或被宣布</span>
      <span class="c25" style="color: #4d5156;">无偿贷能力</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">或破产;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(e) 您履行本协议中的任何义务</span>
      <span class="c4" style="color: #202124;">为</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">非法或变得非法;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(f) 您就任何贷款协议、抵押透支、担保、赔偿或信用证或任何担保文件订立或作为其中一方或承担任何责任，而这些协议可能会禁止、阻碍或延迟您的还款或您履行本协议中的其他义务;或</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(g) 您的死亡或丧失能力。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">14 .</span>
      <span class="c0 c5 c18" style="text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;font-weight: 700;"> 赔偿</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">14.1 您应</span>
      <span>全数赔偿</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;"> Divit因执行或维护本协议中的任何权利而合理产生的所有</span>
      <span>费用及开支</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">，包括但不限于</span>
      <span class="c4" style="color: #202124;">因Divit聘请法律顾问及/或收账公司或其他代理收回根据本协议的应付款项而引致的有关费用及开支</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15 . </span>
      <span class="c18 c0 c5" style="text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;font-weight: 700;">对参与商户的行为不承担任何责任</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15.1 Divit没有也不会承担任何</span>
      <span class="c4" style="color: #202124;">因以下事项而产生</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">的责任，不论是就合同、侵权（包括疏忽）、违反法定义务或其他（即使可预见）方面</span>
      <span class="c4" style="color: #202124;">的任何损失和赔偿</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 参与商户向您提供的任何产品或服务符合其规格或适用性，或是否无缺陷或具有适销性;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 您与任何参与商户之间的任何交易或合同;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(c) 任何参与商户的</span>
      <span class="c14" style="background-color: #ffffff;color: #4d5156;">偿付能力</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">或任何参与商户能否遵守其向您提供的</span>
      <span class="c4" style="color: #202124;">条款及细则</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15.2参与商户</span>
      <span class="c4" style="color: #202124;">将负责</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 您为任何产品或服务所下的任何订单的可用性、供应、交付、退货、退款和履行情况，包括已交付的产品或服务是否符合参与商户网站的描述; 及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 其宣传的产品或服务的质量、安全性或合法性，产品或服务描述的准确性或参与商户销售和交付产品或服务的能力。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">15.3您向Divit承诺遵守您与参与商户达成的任何条款和</span>
      <span class="c4" style="color: #202124;">细则</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16 . </span>
      <span class="c18 c0 c5" style="text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;font-weight: 700;">退款</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16.1 您同意如果参与商户就任何购买向您全额或部分退款，包括任何机场费用或政府征</span>
      <span class="c0 c17" style="text-decoration-skip-ink: none;-webkit-text-decoration-skip: none;text-decoration: line-through;background-color: #ffffff;color: #202124;">税</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">，费用或收费（ "</span>
      <span class="c0 c5" style="background-color: #ffffff;color: #202124;font-weight: 700;">退款</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">" ），除非另有法律要求，退款将不会由参与商户直接向您支付。</span>
      <span class="c4" style="color: #202124;">参与商户将向Divit直接支付相关退款</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16.2 </span>
      <span>当</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;"> Divit 收到任何退款：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(a) 贷款的所有未偿还余额，加上所有应计但未付的利息、行政费、可报销费用和您根据本协议</span>
      <span>所</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">欠Divit的其他款项，将立即到期并需支付;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 除了Divit在法律上可能有权享有的任何一般留置权或类似权利外，Divit还有权将退款与您的债务合并，并将退款用于清还您对Divit的负债;</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(c) 如果退款金额超出您对Divit的任何负债及Divit因接收和处理退款产生的任何费用或收费的金额，Divit将在合理的时间内，并取决于您通知Divit您希望其</span>
      <span>使用的认可</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">的付款方式，使用相关的</span>
      <span>认可的</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">付款方式处理</span>
      <span class="c4" style="color: #202124;">方法</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">向您支付超出部分的金额，并按照付款处理</span>
      <span class="c4" style="color: #202124;">方法</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">的做法进行支付;及</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">(d) 您</span>
      <span class="c4" style="color: #202124;">确认</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">并同意使用</span>
      <span>认可的</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">付款方式支付的任何款项均受制于Divit无法控制的方式处理，并且同意不追究Divit任何对于由任何金融机构或其代理人</span>
      <span class="c4" style="color: #202124;">因</span>
      <span>认可的</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">付款方式而直接或间接造成任何延误的责任。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">16.3 在Divit处理退款之前，</span>
      <span class="c4" style="color: #202124;">您仍有责任按照第6段规定的付款时间表支付款项</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">17 . </span>
      <span class="c18 c0 c5" style="text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;font-weight: 700;">个人信息</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c4" style="color: #202124;">17.1 您同意您向Divit提供的所有信息均可根据本协议</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">用于</span>
      <span class="c9 c4" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;">相关的目的和向相关的人士披露。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">17.2 Divit可以在保密的基础上向任何提议与Divit订立与本协议有关的合同安排的人（ "</span>
      <span class="c0 c5" style="background-color: #ffffff;color: #202124;font-weight: 700;">获准披露者</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">" ），或向Divit或获准披露者的控股公司、附属公司、信用机构、</span>
      <span class="c4" style="color: #202124;">收账</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">公司或顾问或任何向Divit提供服务的其他人或获准披露者披露您的任何信息、贷款信息、本协议以及Divit根据其个人信息私隐条例不时生效的政策和做法下就有关使用和披露个人信息方面认为恰当的文件。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">17.3 您</span>
      <span class="c4" style="color: #202124;">确认</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">您已</span>
      <span class="c4" style="color: #202124;">通过</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;"> Divit的网站收到一份Divit个人信息</span>
      <span class="c4" style="color: #202124;">私隐</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">政策的副本并同意</span>
      <span class="c4" style="color: #202124;">该个人信息</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">私隐</span>
      <span class="c4" style="color: #202124;">政策的条款</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">18 . </span>
      <span class="c18 c0 c5" style="text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;font-weight: 700;">通知</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">18.1 Divit向您提供的通知和其他通讯</span>
      <span class="c4" style="color: #202124;">会经以下方式</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">以书面形式并以英语提供：</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(a) 通过电邮发送至您提供给Divit的电邮地址;及/或</span>
    </p>
    <p class="c7" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;margin-left: 36pt;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">(b) 通过短讯发送到您提供给Divit的手机号码。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">18.2 通过电邮及/或短讯发送的通知或其他通讯，将被视为在发送后的第二天收到。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">18.3 如果您更改了您的电邮地址或手机号码而未通知Divit此类更改，则Divit通过之前提供的电邮地址或手机号码向您发送的通知将被视为</span>
      <span class="c4" style="color: #202124;">符合</span>
      <span class="c0 c9" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;"> Divit在本条款下的通知义务。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">19. </span>
      <span class="c18 c4 c5" style="text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;font-weight: 700;">副本</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">19.1 本协议可签署任何数量的副本，当所有副本一并交付给Divit时，应构成一份相同的文书。任何一方均可通过签署任何此类副本来签订本协议。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">20 . </span>
      <span class="c4 c5" style="color: #202124;font-weight: 700;">其他事项</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">20.1 本协议的任何中文译本仅供参考。如中英文版本有任何不一致或歧义，一概以英文版本为准。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">20.2 如果本协议的任何条款或其中的任何部分被其所适用的任何法律认定为无效、非法或不可执行，则该条款仅在该范围内被认定为无效、非法或不可执行，并且不会以任何方式影响或损害该条款的其余部分或本协议的其他条款的可执行性。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">20.3 您明确</span>
      <span>确认</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">您已阅读本协议并理解其条款，并且您和Divit均同意本协议构成您与Divit之间关于贷款的完整协议。除本协议中明确规定的内容外</span>
      <span class="c4" style="color: #202124;">，Divit与您没有向对方</span>
      <span class="c0" style="background-color: #ffffff;color: #202124;">作出</span>
      <span class="c4" style="color: #202124;">其他</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">任何承诺、诱导、陈述或协议。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c0" style="background-color: #ffffff;color: #202124;">20.4 除非本协议另有明文规定，否则非本协议</span>
      <span>任何一方</span>
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">无权根据《合同（第三方权利）条例》（香港法例第623章）执行或享有本协议任何条款的利益。</span>
    </p>
    <p class="c8" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: justify;">
      <span class="c9 c0" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;background-color: #ffffff;color: #202124;">20.5 本协议受香港法律管辖并按其解释。您在此不可撤销地同意香港法院拥有专属管辖权。</span>
    </p>
    <p class="c6 c13" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;height: 11pt;">
      <span class="c9 c4" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;"></span>
    </p>
    <p class="c6 c13" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;height: 11pt;">
      <span class="c9 c4" style="font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;color: #202124;"></span>
    </p>
    <p class="c22" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c5 c11" style="-webkit-text-decoration-skip: none;color: #000000;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;font-weight: 700;">附件</span>
    </p>
    <p class="c22" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: center;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">放债人条例</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">以下所列的《放债人条例》条文撮要，对保障订立贷款协议的各方均至为重要，应小心阅读。该撮要并非法例的一部分，如有疑问，应参阅《放债人条例》有关条文。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c10" style="-webkit-text-decoration-skip: none;color: #000000;font-weight: 400;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">《放债人条例》第III部撮要——放债人进行的交易</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第18条列出关于放债人作出贷款的规定。每份贷款协议须以书面订立，并由借款人于该协议作出后的7天内及于该笔款项贷出之前签署。在签订协议时，须将已签署的一份协议摘记，连同本撮要一份给予借款人。该摘记须载有该宗贷款的详尽细则，包括还款条款、保证形式及利率。不符合上述规定的协议不得予以强制执行，除非法庭信纳不强制执行该协议并不公平。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第19条订定，如借款人提出书面要求及就有关开支而支付订明费用，则放债人须将该借款人在贷款协议下当时的债务情况（包括已还款项、到期或即将到期的款项及利率）的结算书正本及副本一份给予借款人。借款人须在该结算书的副本上签注文字，表示已经收到该结算书的正本，并将经如此签注的该结算书副本交回该放债人。放债人则须在与该结算书有关的协议持续期间保留该份已交回的结算书副本。如放债人不照办，即属犯罪。如借款人提出书面要求，放债人亦须供给与该宗贷款有关或与保证有关的任何文件的副本。但上述要求，不得在一个月内提出超过一次。放债人如无充分理由而没有遵照本段所述的要求办理，则不得收取在该等要求没有照办期间的利息。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第20条订定，除非保证人亦是借款人，否则须在协议作出后的7天内，给予保证人一份已签署的协议摘记、一份保证文书（如有的话）及详列须支付款项总额的结算书。如保证人在任何时间提出书面要求（不得在一个月内超过一次），放债人须给予他一份已签署并详列已支付款项总额及尚欠款项总额的结算书。放债人如无充分理由而没有遵照办理，则不得在该项要求没有照办期内强制执行该项保证。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第21条订定，借款人以书面通知后，可随时将贷款及计算至还款日期为止的利息偿还，放债人不得因借款人提早还款而征收较高利率。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">放债人如是财政司根据《放债人条例》第33A（4）条以宪报公告认可的放债人或认可的社团的成员，则上述条文不适用。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第22条述明，任何贷款协议如订定须支付复利或订定不准以分期方式偿还贷款，均属非法。此外，任何贷款协议如订定到期而未支付的款项须收取较高利率，亦属非法，但该协议可订定，未偿还的本金部分及利息须收取单利，但利率不得超过在没有拖欠的情况下须支付的利率;但如法庭信纳，该协议如因不符合本条规定而成为非法并不公平，则可宣布该份非法协议全部或部分合法。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第23条述明，如放债人在订立贷款协议时或接受贷款保证时并未领有牌照，则与该放债人订立的贷款协议及给予他的保证不得强制执行;但如法庭信纳，该协议或保证如因本条规定而不能强制执行并不公平，则可宣布该协议或保证的全部或部分可予强制执行。</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c10" style="-webkit-text-decoration-skip: none;color: #000000;font-weight: 400;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">《放债人条例》第IV部撮要——过高利率</span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第24条厘定任何贷款的最高实际利率为年息60%（"实际利率"须按照本条例附表2计算）任何贷款协议如订定更高的实际利率，则不得强制执行，而放债人亦可被检控。此最高利率可由立法会予以变更，但已存在的协议则不受影。对于向缴足款股本不少于$1,000,000的公司作出的贷款或作出如此贷款的人，本条并不适用。  </span>
    </p>
    <p class="c6" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-bottom: 12pt;line-height: 1.1500000000000001;orphans: 2;widows: 2;text-align: left;">
      <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;">本条例第25条订定，在强制执行贷款协议或强制执行贷款保证的法庭法律程序中，或在借款人本人或保证人本人向法庭申请济助时，法庭可查察该协议的条款，以视该等条款是否极之不公平或利率过高（实际利率如超逾年息48%或立法会所订的其他利率，即可单凭该理由而推定该利率过高），而法庭在顾及所有情况后，可将该协议的条款更改，使其对协议各方均公平。对于向缴足款股本不少于$1,000,000的公司作出的贷款或作出如此贷款的人，本条并不适用。 </span>
    </p>
    <div>
      <p class="c2" style="margin: 0;color: #000000;font-size: 11pt;font-family: &quot;Arial&quot;;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: left;height: 11pt;">
        <span class="c1" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Arial&quot;;font-style: normal;"></span>
      </p>
    </div>
  </body>
</html>
`

export default html
