import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import DivitBlackSvg from '@/assets/divit-black.svg'
import { ReactComponent as MenuDownArrow } from '@/assets/menu/menu-down-arrow.svg'
import { ReactComponent as EarnMilesSvg } from '@/assets/menu/menu-earn.svg'
import { ReactComponent as GiftcardSvg } from '@/assets/menu/menu-giftcards.svg'
import { ReactComponent as HomeSvg } from '@/assets/menu/menu-home.svg'
import { ReactComponent as LocaleSvg } from '@/assets/menu/menu-locale.svg'
import { ReactComponent as MerchantsSvg } from '@/assets/menu/menu-merchants.svg'
import { ReactComponent as ProfileSvg } from '@/assets/menu/menu-profile.svg'
import { ReactComponent as ShopSvg } from '@/assets/menu/menu-shopbydivit.svg'
import { ReactComponent as SignupSvg } from '@/assets/menu/menu-signup.svg'
import { ReactComponent as BankSvg } from '@/assets/payment/bank.svg'
import { ReactComponent as PayByFPSIDSvg } from '@/assets/payment/fps-manual.svg'
import { ReactComponent as PayByQRSvg } from '@/assets/payment/fps-qr.svg'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import Spacer from '@/components/Spacer'
import LOCALES from '@/constants/Locales'
import PaymentSettings from '@/constants/payment'
import urls from '@/constants/urls'
import { useAuth } from '@/contexts/authContext'
import { useLocale } from '@/contexts/localeContext'
import useQueryString from '@/hooks/useQueryString'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'
import PaymentUnderPay from '@/pages/PaymentUnderPay'
import { promptCustomerServiceModal } from '@/redux/modal/csModalActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import {
  contactPreferenceSelector,
  eDDAActivatedSelector,
  isFeatureEDDAEnabledSelector,
  profileSelector,
} from '@/redux/profile/profileSelector'
import * as EDDAUtils from '@/utils/eDDA'
import * as Instalments from '@/utils/Instalments'
import * as OrderPaynow from '@/utils/OrderPaynow'
import { IsPaylatering, IsPaymentScreen, matchExactPath } from '@/utils/Route'
import scrollToTop from '@/utils/scrollToTop'

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.5);
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 17rem;
  height: 100%;
  background-color: white;
  padding: 0;
  transition: margin 0.2s ease-in-out;
  overflow-y: auto;
`

const Content = styled.div`
  flex: 1;
  padding: 1.1rem;
`

const SidebarHeader = styled.div`
  display: flex;
  background: #ffcc33;
  padding: 0.8rem 0rem 0.8rem 1.2rem;
  justify-content: space-between;
  height: 3.2rem;
  box-sizing: border-box;
`

const DivitLogo = styled.img`
  width: 3.6rem;
  align-self: flex-start;
`

const BankIconSvg = styled(BankSvg)`
  color: #ffcc33;
`

const Menu = styled.div``

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.8rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  cursor: pointer;

  &:hover {
    color: #1c1c1c;
  }
`

const Subtitle = styled.div`
  font-size: 0.6rem;
  font-weight: 500;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
  color: #aaa;
`
const ItemSubtitle = styled.div`
  font-size: 0.6rem;
  font-weight: 500;
  margin-left: 1.7rem;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
  color: #aaa;
`

const MenuItemIcon = styled(Icon)`
  margin-right: 0.8rem;
`

const Footer = styled.div`
  background: #fafafa;
`

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
`

const ProfileDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const ProfileName = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
`

const Separator = styled.div`
  border-top: 1px solid #f0f0f0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 800;
  color: #cecece;
`

const MenuLink = styled.a`
  font-size: 0.7rem;
  color: #ffcc33;
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 0.5rem;
  display: block;
`

const LogoutButtonWrapper = styled.div`
  display: flex;
  margin-left: 1rem;
  align-self: flex-end;
`

const LogoutButton = styled(Button)`
  min-width: unset;
  font-size: 0.8rem;
  font-weight: 600;
  border: 2px solid #dedede;
  background: white;
  padding: 0.5rem 2rem;
  border-radius: 32px;
`

const LoginButton = styled(Button)`
  min-width: unset;
  font-size: 0.8rem;
  font-weight: 600;
  border: 2px solid #dedede;
  background: white;
  padding: 0.5rem 2rem;
  border-radius: 32px;
`
const ProfileMenuLocaleSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const ProfileMenuLocale = styled.div`
  display: flex;
  flex-direction: row;
`

const ProfileMenuLocaleIcon = styled(LocaleSvg)`
  margin-right: 0.3rem;
`

const ProfileMenuLocaleValue = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
`

const ProfileMenuLocaleAction = styled.div`
  width: 2.66rem;
  display: flex;
  justify-content: center;
`

const ProfileMenuLocaleDownArrow = styled(MenuDownArrow)`
  transform: ${(props) =>
    props.$isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const ProfileMenuLocaleChoiceSectionWrapper = styled.div`
  background: #fff7e8;
`

const ProfileMenuLocaleChoiceSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  text-align: right;
`

const ProfileMenuLocaleChoice = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(p) =>
    p.active ? 'rgba(255, 204, 51, 0.24)' : 'transparent'};
  border-radius: 6px;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const ProfileMenuLocaleChoiceValue = styled.div`
  font-size: 0.65rem;
  margin-left: 1rem;
`

const MENU_ITEMS = [
  {
    key: 'title-quick-access',
    title: 'menu.item_quickaccess',
    subtitle: '',
  },
  {
    key: 'home',
    title: 'common.homepage',
    subtitle: '',
    Icon: HomeSvg,
    path: '/',
  },
  {
    key: 'profile',
    title: 'profile.my',
    subtitle: '',
    Icon: ProfileSvg,
    path: '/profile',
  },
  {
    key: 'separator-a',
    title: '',
  },
  {
    key: 'title-0',
    title: 'menu.title_earn_miles',
  },
  {
    key: 'spend_divit_miles',
    title: 'menu.item_shop_by_divit',
    subtitle: 'menu.subitem_earn_miles',
    Icon: ShopSvg,
    path: urls.en.divitShopUrl,
  },
  {
    key: 'separator-0',
    title: '',
  },
  {
    key: 'title-1',
    title: 'menu.title_spend_miles',
    subtitle: 'menu.subtitle_spend_miles',
  },
  {
    key: 'spend_divit_miles_1',
    title: 'menu.item_shop_by_divit',
    subtitle: 'menu.subitem_spend_miles',
    Icon: ShopSvg,
    path: urls.en.divitShopUrl,
  },
  {
    key: 'divit_merchant_partners_2',
    title: 'menu.item_divit_merchants',
    subtitle: 'menu.subitem_divit_merchants_spend',
    Icon: MerchantsSvg,
    path: urls.en.merchantsUrl,
  },
  {
    key: 'giftcards',
    title: 'menu.item_giftcards',
    subtitle: 'menu.subitem_giftcards',
    Icon: GiftcardSvg,
    path: '/miles/spend',
  },
  {
    key: 'separator-1',
    title: '',
  },
]

const GUEST_MENU_ITEMS = [
  {
    key: 'title-0',
    title: 'menu.title_earn_miles',
  },
  {
    key: 'earn_miles_faster',
    title: 'menu.item_shop_by_divit',
    subtitle: 'menu.subitem_earn_miles',
    Icon: EarnMilesSvg,
    path: urls.en.divitShopUrl,
  },
  {
    key: 'separator-0',
    title: '',
  },
  {
    key: 'title-1',
    title: 'menu.title_signup',
  },
  {
    key: 'sign_up',
    title: 'menu.item_signup_now',
    subtitle: '',
    Icon: SignupSvg,
    path: '/signup',
  },
  {
    key: 'separator-1',
    title: '',
  },
]

const PAYMENT_METHOD_FPS_QR = 'paywithfpsqr'
const PAYMENT_METHOD_PROMPTPAY_QR = 'paywithpromptpayqr'
const PAYMENT_METHOD_SGPAYNOW_QR = 'paywithsgpaynowqr'
const PAYMENT_METHOD_ID = 'paywithfpsid'
const PAYMENT_METHOD_EDDA = 'paywithedda'
const PAYMENT_METHOD_MILES = 'paywithmiles'
const PAYMENT_METHOD_MILES_PAYLATER = 'paywithmilespaylater'
const CHECKOUT_MENU_ITEMS = [
  {
    key: 'title-0',
    title: 'fps.payment.options',
    subtitle: 'fps.payment.options.subtitle',
  },
  {
    key: PAYMENT_METHOD_FPS_QR,
    title: 'fps.paywithqr',
    Icon: PayByQRSvg,
    subtitle: 'fps.paywithqr.subtitle',
    path: 'onClickPayByFPSQR',
    currencies: [PaymentSettings.CURRENCY_HKD],
  },
  {
    key: PAYMENT_METHOD_PROMPTPAY_QR,
    title: 'promptpay.paywithqr',
    Icon: PayByQRSvg,
    subtitle: 'promptpay.paywithqr.subtitle',
    path: 'onClickPayByPromptPayQR',
    currencies: [PaymentSettings.CURRENCY_THB],
  },
  {
    key: PAYMENT_METHOD_SGPAYNOW_QR,
    title: 'sgpaynow.paywithqr',
    Icon: PayByQRSvg,
    subtitle: 'sgpaynow.paywithqr.subtitle',
    path: 'onClickPayBySgPayNowQR',
    currencies: [PaymentSettings.CURRENCY_SGD],
  },
  {
    key: PAYMENT_METHOD_MILES,
    title: 'fps.paywithdivitmiles',
    Icon: EarnMilesSvg,
    subtitle: 'fps.paywithdivitmiles.subtitle',
    path: '/paynow/:orderId/burn',
    currencies: [
      PaymentSettings.CURRENCY_HKD,
      PaymentSettings.CURRENCY_THB,
      PaymentSettings.CURRENCY_SGD,
    ],
  },
  {
    key: PAYMENT_METHOD_MILES_PAYLATER,
    title: 'fps.paywithdivitmiles',
    Icon: EarnMilesSvg,
    subtitle: 'fps.paywithdivitmiles.subtitle',
    path: '/paylater/:orderId/:instalmentId/burn',
    currencies: [PaymentSettings.CURRENCY_HKD],
  },
  {
    key: PAYMENT_METHOD_EDDA,
    title: 'fps.paywithedda',
    Icon: BankIconSvg,
    subtitle: 'fps.paywithedda.subtitle',
    path: 'onClickFastPayment',
    currencies: [PaymentSettings.CURRENCY_HKD],
  },
  {
    key: PAYMENT_METHOD_ID,
    title: 'fps.paywithid',
    Icon: PayByFPSIDSvg,
    subtitle: 'fps.paywithid.subtitle',
    path: 'onClickPayByID',
    currencies: [PaymentSettings.CURRENCY_HKD],
  },
  {
    key: 'separator-0',
    title: '',
  },
  {
    key: 'cancelpayment',
    title: 'fps.cancel',
    Icon: MerchantsSvg,
    subtitle: 'fps.cancel.subtitle',
    path: 'onClickCancel',
    instalmentNum: [1],
    currencies: [
      PaymentSettings.CURRENCY_HKD,
      PaymentSettings.CURRENCY_THB,
      PaymentSettings.CURRENCY_SGD,
    ],
  },
  {
    key: 'cancelpayment1',
    title: 'fps.cancel1',
    Icon: MerchantsSvg,
    subtitle: 'fps.cancel.subtitle1',
    path: 'onClickCancelPayment',
    instalmentNum: [2, 3],
    currencies: [PaymentSettings.CURRENCY_HKD],
  },
  {
    key: 'separator-1',
    title: '',
  },
]

const SideBar = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const { currentLocale, allLocales, changeLocale } = useLocale()
  const { isLoggedIn, logout } = useAuth()
  const [isShowLocaleSelection, setIsShowLocaleSelection] = useState(false)
  const { mode } = useQueryString()

  const {
    fpsMethod,
    disableManualFPS,
    orderID,
    instalmentID,
    paylater,
    instalmentNum,
    orderCurrency,
  } = useSelector((s) => s.payWithFps)
  const isPaylatering = IsPaylatering(pathname)
  const profile = useSelector(profileSelector)
  const contactPreference = useSelector(contactPreferenceSelector)
  const { order } = useSelector((s) => s.order)
  const { refreshOrder, generateFpsLink } = useSelector((s) => s.paynow)
  const isPaymentScreen = IsPaymentScreen(pathname)
  const isOpen = useSelector((s) => s.app.isShowSideBar)
  const isEDDAEnabled = useSelector(isFeatureEDDAEnabledSelector)
  const isUserEDDAActivated = useSelector(eDDAActivatedSelector)

  const isNoMilesSpent = useMemo(
    () =>
      order &&
      !isPaylatering &&
      (order.financialTransactions
        ? order.financialTransactions.filter((v) => v.paidBy === 'miles')
            .length < 1
        : true),
    [order]
  )
  const isPosMode = mode && mode.toLocaleLowerCase() === 'pos'
  const isDisplayMilesAdj =
    (!isLoggedIn || (isLoggedIn && isNoMilesSpent)) && !isPosMode

  const hasNoMilesSpentPayment = useMemo(() => {
    const currentInstalment =
      order &&
      order.instalments.find(
        (instalment) => instalment.instalmentID === instalmentID
      )
    return (
      order &&
      isPaylatering &&
      currentInstalment &&
      Instalments.getTotalMilesPaidAmount(currentInstalment).amount === 0
    )
  }, [order])

  const isDisplayMilesAdjPaylater =
    !isLoggedIn || (isLoggedIn && hasNoMilesSpentPayment)

  const isOrderSuccess = (data) => {
    if (!data) return false
    return OrderPaynow.isCompleted(data)
  }

  const isOrderInvalid = (data) => {
    if (!data) return false
    return OrderPaynow.isExpired(data) || OrderPaynow.isCancelled(data)
  }

  const confirmOrderSuccess =
    (order && isOrderSuccess(order)) || isOrderSuccess(refreshOrder.data)

  const confirmOrderFailure =
    isOrderInvalid(order) ||
    isOrderInvalid(refreshOrder.data) ||
    refreshOrder.isError ||
    generateFpsLink.isError

  const { firstName, lastName } = profile
  const name = `${firstName || ''} ${lastName || ''}`.trim()

  const onClickLogo = () => {
    if (!isPaylatering && !isPaymentScreen) {
      history.push('/')
    }
  }

  const onClickContactus = (e) => {
    e.preventDefault()
    dispatch({ type: 'app/hideSideBar' })
    dispatch(promptCustomerServiceModal())
  }

  const onClickProfileName = () => {
    if (!isPaylatering) {
      history.push('/profile')
    }
  }

  const onClickClose = () => {
    dispatch({ type: 'app/hideSideBar' })
  }
  const onClickLogout = () => {
    dispatch({ type: 'app/hideSideBar' })
    logout()
  }
  const onClickLogin = () => {
    dispatch({ type: 'app/hideSideBar' })
    history.push('/signin')
  }
  const hideMenuItem = (item) => {
    if (
      (item.key === PAYMENT_METHOD_PROMPTPAY_QR &&
        orderCurrency !== PaymentSettings.CURRENCY_THB) ||
      (item.key === PAYMENT_METHOD_SGPAYNOW_QR &&
        orderCurrency !== PaymentSettings.CURRENCY_SGD) ||
      (item.key === PAYMENT_METHOD_FPS_QR &&
        (fpsMethod === PaymentSettings.PAYMENT_MODE_FPSQRCODE ||
          orderCurrency !== PaymentSettings.CURRENCY_HKD)) ||
      (item.key === PAYMENT_METHOD_ID &&
        (fpsMethod === PaymentSettings.PAYMENT_MODE_FPSMANUAL ||
          disableManualFPS ||
          orderCurrency !== PaymentSettings.CURRENCY_HKD))
    )
      return true

    if (
      item.key === PAYMENT_METHOD_EDDA &&
      (!isUserEDDAActivated ||
        !isEDDAEnabled ||
        orderCurrency !== PaymentSettings.CURRENCY_HKD)
    )
      return true

    if (
      (item.key === PAYMENT_METHOD_MILES && isPaylatering) ||
      (item.key === PAYMENT_METHOD_MILES &&
        !isDisplayMilesAdj &&
        !isPaylatering) ||
      (item.key === PAYMENT_METHOD_MILES &&
        !isDisplayMilesAdjPaylater &&
        isPaylatering)
    )
      return true

    if (
      (item.key === PAYMENT_METHOD_MILES_PAYLATER &&
        (orderCurrency !== PaymentSettings.CURRENCY_HKD || !isPaylatering)) ||
      (item.key === PAYMENT_METHOD_MILES_PAYLATER &&
        !isDisplayMilesAdj &&
        (orderCurrency !== PaymentSettings.CURRENCY_HKD || !isPaylatering)) ||
      (item.key === PAYMENT_METHOD_MILES_PAYLATER &&
        !isDisplayMilesAdjPaylater &&
        (orderCurrency !== PaymentSettings.CURRENCY_HKD || isPaylatering))
    )
      return true

    if (
      item.instalmentNum &&
      item.instalmentNum.filter((i) => i === instalmentNum).length === 0
    ) {
      return true
    }

    return false
  }

  const onClickCancelPayment = async () => {
    await scrollToTop(true)
    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'common.leave.ask'),
        okText: tt(intl, 'common.leave.confirm'),
      })
    )
    if (isOk) {
      history.push('/history')
    }
  }

  const onClickCancel = async () => {
    await scrollToTop(true)
    const { isOk } = await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.heywait'),
        content: tt(intl, 'common.leave.ask'),
        okText: tt(intl, 'common.leave.confirm'),
      })
    )

    if (isOk) {
      if (paylater) {
        dispatch({
          type: 'paylater/cancelOrder',
          payload: { orderId: orderID },
        })
      } else {
        dispatch({
          type: 'paynow/cancelOrder',
          payload: { orderId: orderID },
        })
      }

      // window.location.href =
      //   (paylater ? order.webhook_failure : order.webhookFailure) || '/'
    }
  }
  const onClickFastPayment = async () => {
    const path = generatePath(
      paylater
        ? '/paylater/payment/:orderId/:instalmentId/review'
        : '/paynow/:orderId/payment',
      {
        orderId: orderID,
        instalmentId: instalmentID,
      }
    )
    history.push(path)
  }
  const onClickPayByFPSQR = async () => {
    dispatch({
      type: 'payWithFps/setFpsMethod',
      payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_FPSQRCODE },
    })
  }
  const onClickPayByPromptPayQR = async () => {
    dispatch({
      type: 'payWithFps/setFpsMethod',
      payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_PROMPTPAYQR },
    })
  }
  const onClickPayBySgPayNowQR = async () => {
    dispatch({
      type: 'payWithFps/setFpsMethod',
      payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_SGPAYNOWQR },
    })
  }
  const onClickPayByID = async () => {
    await scrollToTop(true)
    dispatch(
      promptConfirmModal({
        title: tt(intl, 'fps.manual.prompt.title'),
        content: tt(intl, 'fps.manual.prompt.note'),
        okText: tt(intl, 'common.understand'),
        closable: false,
      })
    )
    dispatch({
      type: 'payWithFps/setFpsMethod',
      payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_FPSMANUAL },
    })
  }

  const onClickItem = (item) => () => {
    dispatch({ type: 'app/hideSideBar' })
    if (item.path.startsWith('http')) {
      window.open(item.path, '_blank')
      return
    }
    if (item.path.startsWith('/')) {
      const path = order
        ? generatePath(item.path, {
            orderId: orderID,
            instalmentId: instalmentID,
          })
        : item.path
      history.push(path)
      return
    }
    switch (item.path) {
      case 'onClickCancel': {
        onClickCancel()
        break
      }
      case 'onClickCancelPayment': {
        onClickCancelPayment()
        break
      }
      case 'onClickPayByFPSQR': {
        onClickPayByFPSQR()
        break
      }
      case 'onClickPayByPromptPayQR': {
        onClickPayByPromptPayQR()
        break
      }
      case 'onClickPayBySgPayNowQR': {
        onClickPayBySgPayNowQR()
        break
      }
      case 'onClickPayByID': {
        onClickPayByID()
        break
      }
      case 'onClickFastPayment': {
        onClickFastPayment()
        break
      }
      default:
    }
  }

  const doChangeLocale = (locale) => {
    changeLocale(locale)
    if (isLoggedIn) {
      dispatch({
        type: 'updateProfile/updateContactMethod',
        payload: {
          language: locale,
          email: contactPreference.email,
          sms: contactPreference.sms,
          promotion: contactPreference.promotion,
        },
      })
    }
    setIsShowLocaleSelection(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 100,
        },
      }}
      openAnimationSelector=".sidebar"
      openAnimationClassName="sidebar--on-open"
    >
      <Background onClick={onClickClose} />
      <Container className="sidebar">
        <SidebarHeader>
          <DivitLogo
            src={DivitBlackSvg}
            alt="Divit Logo"
            onClick={onClickLogo}
          />
          <ProfileMenuLocaleSection
            onClick={() => setIsShowLocaleSelection((s) => !s)}
          >
            <ProfileMenuLocale>
              <ProfileMenuLocaleIcon />
              <ProfileMenuLocaleValue>
                {LOCALES[currentLocale]}
              </ProfileMenuLocaleValue>
            </ProfileMenuLocale>
            <ProfileMenuLocaleAction>
              <ProfileMenuLocaleDownArrow $isExpanded={isShowLocaleSelection} />
            </ProfileMenuLocaleAction>
          </ProfileMenuLocaleSection>
        </SidebarHeader>
        {isShowLocaleSelection && (
          <ProfileMenuLocaleChoiceSectionWrapper>
            <Spacer height="0.5rem" />
            {allLocales.map((locale) => (
              <ProfileMenuLocaleChoiceSection
                key={locale}
                onClick={() => doChangeLocale(locale)}
              >
                <ProfileMenuLocaleChoice active={locale === currentLocale}>
                  <ProfileMenuLocaleChoiceValue>
                    {LOCALES[locale]}
                  </ProfileMenuLocaleChoiceValue>
                </ProfileMenuLocaleChoice>
              </ProfileMenuLocaleChoiceSection>
            ))}
            <Spacer height="0.5rem" />
          </ProfileMenuLocaleChoiceSectionWrapper>
        )}
        <Content>
          {isPaymentScreen && !confirmOrderSuccess && !confirmOrderFailure && (
            <>
              <Menu>
                {CHECKOUT_MENU_ITEMS.map((item) =>
                  item.key.startsWith('separator') ? (
                    <Separator key={item.key} />
                  ) : item.key.startsWith('title') ? (
                    <div
                      key={item.key}
                      style={{ fontWeight: 700, fontSize: '1rem' }}
                    >
                      {intl.formatMessage({ id: item.title })}
                      {item.subtitle && (
                        <Subtitle>
                          {item.subtitle !== ''
                            ? intl.formatMessage({ id: item.subtitle })
                            : ''}
                        </Subtitle>
                      )}
                    </div>
                  ) : hideMenuItem(item) ? (
                    <span key={item.key} />
                  ) : (
                    <div key={item.key}>
                      <MenuItem onClick={onClickItem(item)}>
                        {item.Icon && (
                          <MenuItemIcon
                            renderImage={() => <item.Icon />}
                            width="0.88rem"
                            height="0.88rem"
                          />
                        )}
                        {item.title && intl.formatMessage({ id: item.title })}
                      </MenuItem>
                      <ItemSubtitle>
                        {item.subtitle !== ''
                          ? intl.formatMessage({ id: item.subtitle })
                          : ''}
                      </ItemSubtitle>
                    </div>
                  )
                )}
              </Menu>
              <MenuLink
                href="#"
                onClick={onClickItem({ path: urls.en.aboutusUrl })}
              >
                {intl.formatMessage({ id: 'menu.item_aboutus' })}
              </MenuLink>
              <Spacer height="0.5rem" />
              <MenuLink href="#" onClick={onClickContactus}>
                {intl.formatMessage({ id: 'menu.item_contactus' })}
              </MenuLink>
              <Spacer height="0.5rem" />
              <MenuLink
                href="#"
                onClick={onClickItem({ path: urls.en.faqUrl })}
              >
                {intl.formatMessage({ id: 'menu.item_faq' })}
              </MenuLink>
            </>
          )}
          {!isPaylatering && !isPaymentScreen && (
            <>
              <Menu>
                {(isLoggedIn ? MENU_ITEMS : GUEST_MENU_ITEMS).map((item) =>
                  item.key.startsWith('separator') ? (
                    <Separator key={item.key} />
                  ) : item.key.startsWith('title') ? (
                    <div
                      key={item.key}
                      style={{ fontWeight: 700, fontSize: '1rem' }}
                    >
                      {intl.formatMessage({ id: item.title })}
                      {item.subtitle && (
                        <Subtitle>
                          {item.subtitle !== ''
                            ? intl.formatMessage({ id: item.subtitle })
                            : ''}
                        </Subtitle>
                      )}
                    </div>
                  ) : (
                    <div key={item.key}>
                      <MenuItem
                        isActive={matchExactPath(pathname, item.path)}
                        onClick={onClickItem(item)}
                      >
                        {item.Icon && (
                          <MenuItemIcon
                            renderImage={() => <item.Icon />}
                            width="0.88rem"
                            height="0.88rem"
                          />
                        )}
                        {item.title && intl.formatMessage({ id: item.title })}
                      </MenuItem>
                      <ItemSubtitle>
                        {item.subtitle !== ''
                          ? intl.formatMessage({ id: item.subtitle })
                          : ''}
                      </ItemSubtitle>
                    </div>
                  )
                )}
              </Menu>
              <MenuLink
                href="#"
                onClick={onClickItem({ path: urls.en.aboutusUrl })}
              >
                {intl.formatMessage({ id: 'menu.item_aboutus' })}
              </MenuLink>
              <Spacer height="0.5rem" />
              <MenuLink href="#" onClick={onClickContactus}>
                {intl.formatMessage({ id: 'menu.item_contactus' })}
              </MenuLink>
              <Spacer height="0.5rem" />
              <MenuLink
                href="#"
                onClick={onClickItem({ path: urls.en.faqUrl })}
              >
                {intl.formatMessage({ id: 'menu.item_faq' })}
              </MenuLink>
            </>
          )}
        </Content>
        <Footer>
          {!isPaylatering && !isPaymentScreen && (
            <ProfileContainer>
              <ProfileDetails>
                {isLoggedIn ? (
                  <ProfileName onClick={onClickProfileName}>{name}</ProfileName>
                ) : (
                  <ProfileName onClick={onClickLogin}>
                    {intl.formatMessage({ id: 'menu.guest_name' })}
                  </ProfileName>
                )}
              </ProfileDetails>
              <LogoutButtonWrapper>
                {isLoggedIn ? (
                  <LogoutButton onClick={onClickLogout}>
                    {intl.formatMessage({ id: 'common.logout' })}
                  </LogoutButton>
                ) : (
                  <LoginButton onClick={onClickLogin}>
                    {intl.formatMessage({ id: 'common.login' })}
                  </LoginButton>
                )}
              </LogoutButtonWrapper>
            </ProfileContainer>
          )}
        </Footer>
      </Container>
    </Modal>
  )
}

export default SideBar
