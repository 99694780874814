const ImageSet = {
  create: ({ mobile, desktop }) => ({ mobile, desktop }),
  createLang: ({ en, zhHK, zhCN }) => ({
    en,
    'zh-hk': zhHK,
    'zh-cn': zhCN,
  }),
  get: (imageLangSet, lang, size) =>
    imageLangSet[lang]?.[size] || imageLangSet.en.desktop,
}

export default ImageSet
