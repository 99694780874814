import React from 'react'
import { useIntl } from 'react-intl'

import { ReactComponent as GoogleLoginIconSvg } from '@/assets/socials/signin/google-colored.svg'
import LoginButton from '@/components/buttons/LoginButton'
import LoginGoogle from '@/components/LoginGoogle'
import { tt } from '@/locales/format'
import { IS_FACEBOOK_APP } from '@/utils/platform'

const GoogleLoginButton = ({
  onClickGoogleLogin,
  onGoogleSuccess,
  onGoogleFailure,
}) => {
  const intl = useIntl()

  const doClickGoogleLogin = (onClick) => () => {
    if (IS_FACEBOOK_APP) {
      // eslint-disable-next-line
      window.alert(
        'please open in browser for google login if you are using facebook app'
      )
      return
    }
    const result = onClickGoogleLogin ? onClickGoogleLogin() : true
    if (result) {
      onClick()
    }
  }
  return (
    <LoginGoogle
      onSuccess={onGoogleSuccess}
      onFailure={onGoogleFailure}
      render={({ onClick }) => (
        <LoginButton
          style={{ backgroundColor: '#fff', color: '#4285F4', border: '1px solid #4285F4' }}
          onClick={doClickGoogleLogin(onClick)}
          text={tt(intl, 'login.google.continue')}
          iconProps={{
            renderImage: () => <GoogleLoginIconSvg />,
            width: '1.111rem',
            height: '1.111rem',
          }}
        />
      )}
    />
  )
}

export default GoogleLoginButton
